import { ArrowLeftOutlined, FormOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Divider, Image, Row, Typography } from 'antd';
import moment from 'moment';
import { FunctionComponent, SetStateAction, useEffect } from 'react';
import pdfIcon from '../../assets/images/pdf.png';
import RecordProperties from '../../core/records/components/RecordProperties';
import AssociationDataTable from '../../core/recordsAssociations/components/AssociationDataTable';
import StageNameTag from '../../shared/components/StageNameTag';
import './styles.scss';
import NoteFeed from '../../v2/shared/components/NoteFeed';

interface OwnProps {
  record: DbRecordEntityTransform;
  setIsViewingCase: SetStateAction<any>;
  setViewedCaseRecord: SetStateAction<any>;
}

type Props = OwnProps;
const { Title } = Typography;

const CaseViewer: FunctionComponent<Props> = (props) => {
  const { record, setIsViewingCase, setViewedCaseRecord } = props;

  const renderAssociatedFiles = (record: DbRecordEntityTransform) => {
    const files = getAllRelations(record, 'File');

    if (record && files) {
      return (
        <div style={{ padding: 8 }}>
          <Row>
            <Col span={24}>
              <Image.PreviewGroup>
                {files?.map((file: any, index: any) =>
                  getProperty(file, 'Mimetype') === 'image/jpeg' ? (
                    <div style={{ paddingRight: '4px', display: 'inline' }}>
                      <Image
                        style={{ paddingBottom: 2 }}
                        width={50}
                        height={50}
                        src={getProperty(file, 'Url')}
                      />
                    </div>
                  ) : (
                    <a href={getProperty(file, 'Url')} target="_blank">
                      <div style={{ paddingRight: '4px', display: 'inline' }}>
                        <Image
                          style={{
                            borderRadius: '5px',
                            border: '1px solid red',
                          }}
                          preview={false}
                          width={40}
                          height={40}
                          src={pdfIcon}
                        />
                      </div>
                    </a>
                  ),
                )}
              </Image.PreviewGroup>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (record) {
      setIsViewingCase(true);
    } else {
      setIsViewingCase(null);
    }
  }, [record]);

  const handleBackButton = () => {
    setIsViewingCase(false);
    setViewedCaseRecord(null);
  };

  return (
    <Row>
      {/* Back to Requests Header */}
      <Col span={24} onClick={handleBackButton} style={{ cursor: 'pointer', marginBottom: 10 }}>
        <ArrowLeftOutlined style={{ marginRight: 10 }} />
        <Title style={{ display: 'inline' }} level={4}>
          Back
        </Title>
      </Col>

      {/* Request Body */}
      <Col
        span={24}
        style={{
          padding: 10,
          border: '1px solid #e3e3e3',
          marginTop: 15,
          borderRadius: 5,
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              backgroundColor: '#e5f3ff',
              padding: '3px 10px',
              borderRadius: 3,
            }}
          >
            {/* Request Title */}
            <Title style={{ display: 'inline' }} level={5}>
              {record.title ? (
                <span style={{ textTransform: 'capitalize' }}>{record.title}</span>
              ) : (
                'No Title'
              )}
            </Title>
          </Col>

          {/* Record Properties */}
          <Col span={24} style={{ marginTop: 20 }}>
            <RecordProperties
              columns={1}
              record={props.record}
              visibleProperties={['Stage', 'Origin', 'Question']}
            />
          </Col>

          <Col>{renderAssociatedFiles(record)}</Col>
        </Row>

        <Divider style={{ margin: '10px 0' }} />

        {/* Stage & Created At */}
        <Row style={{ marginBottom: 5 }}>
          <Col span={10}>
            <span style={{ fontWeight: 600 }}>Stage:</span>
          </Col>
          <Col span={14} style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 600 }}>Created At:</span>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <StageNameTag
              record={record}
              text={record.stage ? record.stage.name : 'No Stage'}
              size="small"
            ></StageNameTag>
          </Col>
          <Col span={14} style={{ textAlign: 'right' }}>
            <span>{moment(record.createdAt).format('DD/MM/YYYY - HH:mm:ss')}</span>
          </Col>
        </Row>
      </Col>

      {/* Associated Files */}
      <Col
        span={24}
        style={{
          padding: 13,
          border: '1px solid #e3e3e3',
          marginTop: 20,
          borderRadius: 5,
        }}
      >
        <Row>
          <Col span={24}>
            <AssociationDataTable
              thumbnailSize={8}
              title="Files"
              record={record}
              moduleName="SchemaModule"
              entityName="File"
              location="sidebar"
            />
            ,
          </Col>
        </Row>
      </Col>

      {/* Associated Notes */}
      <Col
        span={24}
        style={{
          padding: 13,
          border: '1px solid #e3e3e3',
          marginTop: 20,
          borderRadius: 10,
        }}
      >
        <Row>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Title level={5} style={{ marginTop: 0 }}>
              <FormOutlined style={{ marginRight: 8 }} />
              Add Notes
            </Title>
          </Col>
          <Col span={24}>
            <NoteFeed record={record} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CaseViewer;
