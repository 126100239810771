import React from 'react';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../shared/utilities/recordHelpers';
import { Descriptions } from 'antd';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import TableCellStageTag from '../../../v2/shared/components/TableCellStageTag';

interface Props {
  result: DbRecordEntityTransform;
  onClose: any;
  globalCollapsed: boolean;
}

interface State {
  collapsed: boolean;
}

class AccountLayout extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { collapsed: false };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!prevProps.globalCollapsed && this.props.globalCollapsed) {
      this.setState({ collapsed: true });
    }
    if (prevProps.globalCollapsed && !this.props.globalCollapsed) {
      this.setState({ collapsed: false });
    }
  }

  renderCommaSeparatedRelatedEntitiesWithLinks = (
    result: DbRecordEntityTransform,
    entityName: string,
    propertyName: string,
  ) => {
    if (result[entityName] && result[entityName].dbRecords) {
      return result[entityName].dbRecords
        .map((elem: DbRecordEntityTransform) => (
          <Link to={getBrowserPath(elem)} onClick={() => this.props.onClose()} target="_blank">
            {elem[propertyName]}
          </Link>
        ))
        .reduce((prev: JSX.Element, current: JSX.Element): any => [prev, <span>, </span>, current]);
    } else return '-';
  };

  renderCommaSeparatedRelatedContactProperty = (
    result: DbRecordEntityTransform,
    propertyName: string,
  ) => {
    if (result.Contact && result.Contact.dbRecords) {
      return result.Contact.dbRecords
        .map((elem: DbRecordEntityTransform) =>
          getProperty(elem, propertyName, true) ? (
            <span>{getProperty(elem, propertyName, true)}</span>
          ) : (
            'None'
          ),
        )
        .reduce((prev: JSX.Element, current: JSX.Element): any => [prev, <span>, </span>, current]);
    } else return '-';
  };

  render() {
    const result = this.props.result;

    return (
      <Descriptions
        column={1}
        bordered
        labelStyle={{ color: 'black', fontWeight: 400, fontSize: 12, padding: '2px 7px' }}
        contentStyle={{ fontSize: 12, padding: '2px 7px' }}
      >
        {/* Address */}
        <Descriptions.Item label="Address">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'Address', 'title')}
          </span>
        </Descriptions.Item>

        {/* Contact */}
        <Descriptions.Item label="Contact">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'Contact', 'title')}
          </span>
        </Descriptions.Item>

        {/* E-mail */}
        <Descriptions.Item label="E-mail">
          <span>{this.renderCommaSeparatedRelatedContactProperty(result, 'EmailAddress')}</span>
        </Descriptions.Item>

        {/* Phone */}
        <Descriptions.Item label="Phone">
          <span>{this.renderCommaSeparatedRelatedContactProperty(result, 'Phone')}</span>
        </Descriptions.Item>

        {/* Previous Provider */}
        <Descriptions.Item label="Previous provider">
          <span>
            {this.renderCommaSeparatedRelatedContactProperty(result, 'Previous Provider')}
          </span>
        </Descriptions.Item>

        {/* Order # */}
        <Descriptions.Item label="Order #">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'Order', 'recordNumber')}
          </span>
        </Descriptions.Item>

        {/* Stage */}
        <Descriptions.Item label="Stage">
          <TableCellStageTag record={result} small />
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default AccountLayout;
