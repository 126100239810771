import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import { MyCasesContext } from '../..';
import { MY_CASES_ADD_MESSAGE } from '../../store/constants';

interface Props {
  userReducer: any;
}

const SOCKET_URL = `${import.meta.env.VITE_ODIN_WEBSOCKET_URL}/ChatModule/TwilioChatWebsocket/v1.0`;

const MyCasesWebSockets: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const { state, dispatch } = useContext(MyCasesContext);
  const [socket, setSocket] = useState<any>(undefined);

  // Refs that will be used inside socket event handlers
  let caseIdRef: any = useRef(undefined);
  let feedContainerRef: any = useRef(null);

  // Handle Selected Case Container Refs
  useEffect(() => {
    if (state.selectedCase?.id !== caseIdRef.current) {
      caseIdRef.current = state.selectedCase?.id;
    }
  }, [state.selectedCase, caseIdRef]);

  // Handle Feed Container Refs
  useEffect(() => {
    if (
      state.feedContainerRef?.current?.scrollHeight! !== feedContainerRef?.current?.scrollHeight
    ) {
      feedContainerRef.current = state.feedContainerRef?.current;
    } else {
    }
  }, [state.feedContainerRef?.current?.scrollHeight, feedContainerRef, state.selectedCase]);

  useEffect(() => {
    const s = io(SOCKET_URL, {
      path: '/ws/socket.io',
    });

    if (s) {
      setSocket(s);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () =>
        console.log('%cdebug: MyCasesV2: Connected to WebSockets service', 'color:limegreen'),
      );
      socket.on(`case-event-${userReducer.user.id}`, handleIncomingEvent);
    }

    return () => {
      socket?.off('message-sent', handleIncomingEvent);
      socket?.disconnect();
    };
  }, [socket]);

  const isMessageForThisConversation = (message: DbRecordEntityTransform) => {
    return message?.caseId === caseIdRef?.current;
  };

  const handleIncomingEvent = (message: any) => {
    if (isMessageForThisConversation(message) && message.type === 'MESSAGE_SENT') {
      console.log('%cdebug: MyCasesV2: MESSAGE_EVENT', 'color: #bada55', message);
      dispatch({ type: MY_CASES_ADD_MESSAGE, payload: message.data as DbRecordEntityTransform });

      // Check if own message by comparing from property with userReducer.user.email
      if (getProperty(message.data, 'From') === userReducer.user.email) {
        if (feedContainerRef) {
          feedContainerRef.current.scrollTo({
            top: feedContainerRef.current.scrollTop + feedContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
    } else {
      console.log('debug: Message not for this conversation', {
        message,
        conversation: state.selectedCaseConversation,
      });
    }
  };

  return <></>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesWebSockets);
