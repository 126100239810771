import { Icon } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useReducer, useRef, useState } from 'react';
import { getInitialsFromName } from '../../../../../../shared/helpers/UIHelpers';

import SchedulingUserTimeline from './SchedulingUserTimeline';
import './styles.scss';
import { SCHEDULING_DUMMY_DATA, TIME_FRAMES, TScheduleUser } from './types';
import {
  caseManagementSchedulingReducer,
  CaseManagementSchedulingReducerInitialState,
  ICaseManagementSchedulingReducer,
} from './store/reducer';
import SchedulingUserDialog from './SchedulingUserDialog';

interface Props {}

export type TCaseManagementSchedulingContext = {
  state: ICaseManagementSchedulingReducer;
  dispatch: React.Dispatch<any>;
};

export const CaseManagementSchedulingContext =
  React.createContext<TCaseManagementSchedulingContext>({
    state: CaseManagementSchedulingReducerInitialState,
    dispatch: () => {},
  });

const CaseManagementScheduling: React.FC<Props> = (props: Props) => {
  const {} = props;

  const [state, dispatch] = useReducer(
    caseManagementSchedulingReducer,
    CaseManagementSchedulingReducerInitialState,
  );

  const agentColumn = useRef<HTMLDivElement>(null);
  const contentRow = useRef<HTMLDivElement>(null);
  const timelineColumn = useRef<HTMLDivElement>(null);
  const timelineHeader = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const syncScroll = (
    sourceDiv: React.RefObject<HTMLDivElement>,
    targetDiv: React.RefObject<HTMLDivElement>,
  ) => {
    if (sourceDiv.current && targetDiv.current) {
      targetDiv.current.scrollLeft = sourceDiv.current.scrollLeft;
    }
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (timelineHeader.current && timelineColumn.current) {
      setIsDragging(true);
      setStartX(e.pageX - timelineColumn.current.offsetLeft);
      setScrollLeft(timelineColumn.current.scrollLeft);
      // Prevent text selection
      timelineHeader.current.style.cursor = 'move';
      timelineHeader.current.style.userSelect = 'none';
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !contentRow.current || !timelineColumn.current) return;
    e.preventDefault(); // Prevent default behavior like text selection
    const x = e.pageX - timelineColumn.current.offsetLeft;
    const walkX = (x - startX) * 2; // Adjust X scrolling speed
    timelineColumn.current.scrollLeft = scrollLeft - walkX;
  };

  const onMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  return (
    <CaseManagementSchedulingContext.Provider value={{ state, dispatch }}>
      <SchedulingUserDialog />

      {/* Heading Row */}
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Scheduling</h1>
        </Col>
      </Row>

      {/* Header Row */}
      <Row
        style={{
          marginTop: 30,
          background: '#fff',
        }}
      >
        {/* Agent Header */}
        <Col
          span={4}
          style={{
            height: '100%',
            zIndex: 9,
            boxShadow: 'rgba(0, 0, 0, 0.06) 5px 1px 5px -2px',
          }}
        >
          <Row className="schedulingLeftSidebar" justify="space-between">
            <Col>
              <span style={{ fontSize: '1.1em' }}>Agent</span>
            </Col>
            <Col>
              <Icon icon="search" />
            </Col>
          </Row>
        </Col>

        {/* Timeline Header */}
        <Col
          span={20}
          className="schedulingRightSidebar"
          style={{ overflowX: 'auto' }}
          ref={timelineHeader}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUpOrLeave}
          onMouseLeave={onMouseUpOrLeave}
          onScroll={() => syncScroll(timelineHeader, timelineColumn)}
        >
          <Row style={{ width: 2175, paddingLeft: 5 }}>
            {TIME_FRAMES.map((time, i) => (
              <Col
                key={time}
                style={{
                  width: 80,
                  fontWeight: 600,
                  padding: '5px 0',
                  margin: '10px 0',
                  paddingLeft: 2,
                  textAlign: 'center',
                  border: '1px solid transparent',
                  borderRight:
                    i === TIME_FRAMES.length - 1 ? '1px solid transparent' : '1px solid #D5D5D5',
                }}
              >
                {time}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {/* Content Row */}
      <Row
        ref={contentRow}
        style={{
          height: 'calc(100vh - 190px)',
          overflowY: 'scroll',
          borderBottom: '1px solid #F2F2F2',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        {/* Agent Column */}
        <Col span={4} ref={agentColumn} style={{ boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)' }}>
          {SCHEDULING_DUMMY_DATA.map((user: TScheduleUser, i: number) => (
            <Row
              key={i}
              style={{ padding: 8, border: '1px solid #F2F2F2', borderRight: 0, borderTop: 0 }}
              align="middle"
            >
              <Col span={22}>
                <Row align="middle">
                  <Col>
                    <div
                      style={{
                        background: '#F5F5F5',
                        padding: '6px 5px',
                        width: 30,
                        height: 30,
                        borderRadius: 50,
                        textAlign: 'center',
                      }}
                    >
                      {getInitialsFromName(`${user.firstName} ${user.lastName}`)}
                    </div>
                  </Col>
                  <Col
                    key={i}
                    style={{ paddingLeft: 8 }}
                  >{`${user.firstName} ${user.lastName}`}</Col>
                </Row>
              </Col>
              <Col span={2}>
                <span>
                  <i className="bi bi-three-dots-vertical" />
                </span>
              </Col>
            </Row>
          ))}
        </Col>

        {/* Agent Timeline */}
        <Col
          ref={timelineColumn}
          onScroll={() => syncScroll(timelineColumn, timelineHeader)}
          span={20}
          style={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            borderRight: '1px solid #F2F2F2',
          }}
        >
          {SCHEDULING_DUMMY_DATA.map((user: TScheduleUser, i: number) => (
            <Row
              key={i}
              align="middle"
              style={{
                width: 2175,
                overflowY: 'hidden',
                height: 47,
                borderBottom: '1px solid #F2F2F2',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col span={24} style={{ height: 47, paddingLeft: 5 }}>
                <SchedulingUserTimeline user={user} />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </CaseManagementSchedulingContext.Provider>
  );
};
export default CaseManagementScheduling;
