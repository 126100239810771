import { Card, Col, Descriptions, Row, Skeleton, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IUserAuditReducer } from '../../store/reducer';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  createUserAuditEventsRequest,
  getUsersAuditEventsByRecordRequest,
  ICreateUserAuditEvents,
  IGetUsersAuditEventsByRecord,
} from '../../store/actions';
import { LogsUserAuditEntity } from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.entity';
import { logRecordViewedAuditEvent } from '../../helpers/userAuditHelpers';
import dayjs from 'dayjs';
import { IdentityUserReducer } from '../../../identityUser/store/reducer';
import { hasPermissions } from '../../../../shared/permissions/rbacRules';
import moment from 'moment';

interface Props {
  record: DbRecordEntityTransform;
  userReducer: IdentityUserReducer;
  userAuditReducer: IUserAuditReducer;
  type?: 'CARD' | 'FLEX';
  getUsersAuditEventsByRecord: (
    params: IGetUsersAuditEventsByRecord,
    cb?: (resp: any) => void,
  ) => void;
  createUserAuditEvents: (params: ICreateUserAuditEvents, cb?: (resp: any) => void) => void;
}

interface State {
  event?: LogsUserAuditEntity | undefined;
  loadingEvents: boolean;
}

class RecordUserAudit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      event: undefined,
      loadingEvents: false,
    };
  }

  // On component mount, log user record and fetch all audits for the record
  componentDidMount() {
    const { record } = this.props;

    if (record) {
      this.logUserAuditByRecord(record);
      this.refreshUsersAuditByRecord();
    }
  }

  // Take the record id and log the user activity.
  logUserAuditByRecord = (record: DbRecordEntityTransform) => {
    const { userAuditReducer, createUserAuditEvents } = this.props;

    logRecordViewedAuditEvent(
      {
        recordId: record.id,
        data: {
          recordNumber: record.recordNumber,
          title: record.title,
        },
      },
      userAuditReducer,
      createUserAuditEvents,
    );
  };

  /**
   * Fetch audit by record id and set the latest event to the state
   */
  private refreshUsersAuditByRecord() {
    const { record, userReducer, getUsersAuditEventsByRecord } = this.props;

    this.setState({ loadingEvents: true });

    if (record && hasPermissions(userReducer, ['logs.user.audit.get'])) {
      getUsersAuditEventsByRecord({ recordId: record.id }, (res: any) => {
        if (
          res?.results?.events?.length > 0 &&
          dayjs().diff(dayjs(res?.respondedAt), 'minutes') < 5
        ) {
          this.setState({ loadingEvents: false });
          this.setState({
            event: res?.results?.events[0],
          });
        } else {
          this.setState({ loadingEvents: false });
        }
      });
    }
  }

  renderBody = () => {
    const { type } = this.props;

    if (!type || type === 'CARD') {
      return (
        <Card title="Audit Info" size="small">
          <Row>
            <Col span={24} style={{ fontWeight: 700 }}>
              Last viewed date:
            </Col>
            <Col span={24}>
              <Skeleton loading={this.state.loadingEvents}>
                {this.state.event
                  ? moment(this.state.event?.createdAt).format('DD/MM/YYYY | HH:mm:ss')
                  : '-'}
              </Skeleton>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col span={24} style={{ fontWeight: 700 }}>
              Last viewed by:
            </Col>
            <Col span={24}>
              <Skeleton loading={this.state.loadingEvents}>
                {this.state.event ? this.state.event.userName : '-'}
              </Skeleton>
            </Col>
          </Row>
        </Card>
      );
    } else {
      return this.state.loadingEvents ? (
        <Spin />
      ) : (
        <>
          {this.state.event ? this.state.event.userName : '-'}
          {
            <>
              <br />
              {moment(this.state.event?.createdAt).format('DD/MM/YYYY')}
              <br />
              {moment(this.state.event?.createdAt).format('HH:mm:ss')}
            </>
          }
        </>
      );
    }
  };

  render() {
    return this.renderBody();
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  userAuditReducer: state.userAuditReducer,
});

const mapDispatch = (dispatch: any) => ({
  getUsersAuditEventsByRecord: (params: IGetUsersAuditEventsByRecord, cb?: (resp: any) => void) =>
    dispatch(getUsersAuditEventsByRecordRequest(params, cb)),
  createUserAuditEvents: (params: ICreateUserAuditEvents, cb?: (resp: any) => void) =>
    dispatch(createUserAuditEventsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(RecordUserAudit);
