import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { RecordDataTable } from '../../../../../core/records/components/DataTable';
import { useSearch } from '../../../../../core/records/components/DataTable/helpers/search/useSearch';
import RecordCard from '../../../../../core/records/components/RecordCard';
import RecordQuickViewDrawer from '../../../../../core/records/components/RecordQuickViewDrawer';
import { useRecordAssociations } from '../../../../../core/records/hooks/useRecordAssociations';
import { hasPermissions } from '../../../../../shared/permissions/rbacRules';
import history from '../../../../../shared/utilities/browserHistory';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

interface Props {
  userReducer: any;
}

const ApplicationForPaymentDetailView: FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { record } = useContext(DetailViewContext);

  const [quickView, setQuickView] = useState<{
    id: string | undefined;
    showDrawer: boolean;
  }>({
    id: undefined,
    showDrawer: false,
  });

  const [workItems, setWorkItems] = useState<DbRecordEntityTransform[]>([]);
  const [workItemsAdjustments, setWorkItemsAdjustments] = useState<DbRecordEntityTransform[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getColumnSearchProps = useSearch();

  const getRowClassName = ({ rowRecord }: any) => {
    const isApproved = getProperty(rowRecord, 'IsApproved') ?? '-';
    return ['true', 'false'].includes(String(isApproved))
      ? `row-afp-status-${String(isApproved.replace(/\s/g, '')?.toLowerCase())}`
      : '';
  };

  useRecordAssociations(
    record,
    ['FeaturePriceEntry', 'Feature', 'WorkList', 'ApplicationForPayment'],
    ({ results }: any) => {
      const WI: DbRecordEntityTransform[] = [];
      const WIA: DbRecordEntityTransform[] = [];

      const fpes = getAllRelations(results, 'FeaturePriceEntry') ?? [];
      fpes.forEach((fpe: DbRecordEntityTransform) => {
        if (fpe.type === 'DEFAULT') WI.push(fpe);
        else WIA.push(fpe);
      });

      setWorkItems(WI);
      setWorkItemsAdjustments(WIA);
      setIsLoading(false);
    },
  );

  return (
    <Layout>
      <RecordQuickViewDrawer
        recordId={quickView?.id!}
        moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
        entityName={'Feature'}
        visible={quickView.showDrawer && !!quickView?.id}
        onClose={() =>
          setQuickView({
            id: undefined,
            showDrawer: false,
          })
        }
      />

      <DetailView
        showCollaborators
        headerExtras={[
          <Button
            key="AFP-Approval"
            disabled={
              !hasPermissions(userReducer, ['projectmodule.applicationforpayment.approval'])
            }
            onClick={() =>
              history.push(`/ProjectModule/ApplicationForPayment/${record.id}/approval`)
            }
          >
            Approve/Reject Items
          </Button>,
        ]}
        defaultTabKey="Summary"
        customTabs={[
          {
            key: 'Summary',
            tab: 'Summary',
          },
        ]}
        customTabBodies={{
          Summary: (
            <div>
              <div className="association-data-table-wrapper">
                <div className="application-for-payment" style={{ marginBottom: 16 }}>
                  <RecordDataTable
                    loading={isLoading}
                    title="Line Items"
                    dataSource={workItems}
                    rowClassName={getRowClassName}
                    customColumnAttributes={{
                      'properties.QGISFeatureId': getColumnSearchProps('properties.QGISFeatureId'),
                    }}
                  />
                </div>
                <br />
                {workItemsAdjustments.length > 0 && (
                  <div className="application-for-payment" style={{ marginBottom: 16 }}>
                    <RecordDataTable
                      loading={isLoading}
                      title="Adjustments"
                      dataSource={workItemsAdjustments}
                      rowClassName={getRowClassName}
                      customColumnAttributes={{
                        'properties.QGISFeatureId': getColumnSearchProps(
                          'properties.QGISFeatureId',
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.PROJECT}
            visibleProperties={[
              'Contractor',
              'PurchaseOrderNumber',
              'EstimatedStart',
              'EstimatedEnd',
            ]}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            showWithoutPagination
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={'WorkList'}
            visibleProperties={[]}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            showWithoutPagination
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={'PaymentNotice'}
            visibleProperties={[]}
          />,
        ]}
      />
    </Layout>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(ApplicationForPaymentDetailView);
