import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Empty, Row, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import ModuleEntityIcon from '../../../../core/theme/ModuleEntityIcon';
import { connect } from 'react-redux';
import { initializeRecordForm } from '../../../../core/records/components/Forms/store/actions';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '../../../../core/records/components/Forms/CoreForm';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { getAllSchemaAssociationSchemas } from '../../../../shared/utilities/recordHelpers';
import { schemaHasType } from '../../../../shared/utilities/schemaHelpers';
import { Button, Section } from '@blueprintjs/core';
import { isMobile } from 'react-device-detect';
import { getAllSchemaActionsForMultipleSchemaIds } from './api';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { getSchemaActionVersion } from '../../../../core/records/components/Forms/helpers';
import './styles.scss';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { getSchemaByIdRequest } from '../../../../core/schemas/store/actions';

interface Props {
  parentRecord: DbRecordEntityTransform;
  parentSchema: SchemaEntity;
  initializeForm: Function;
  columns?: number;
  getSchema: (payload: any, cb: any) => void;
}

const uuid = uuidv4();

const SchemaActionFormLauncher: FC<Props> = (props: Props) => {
  const { parentSchema, parentRecord, initializeForm, columns, getSchema } = props;
  const [schemasWithOutcomeForms, setSchemasWithOutcomeForms] = useState<SchemaEntity[]>([]);
  const [schemaActions, setSchemaActions] = useState<any[]>([]);
  const [isLoadingSchemaActions, setIsLoadingSchemaActions] = useState<boolean>(false);

  // When parent schema is available, get all schemas with outcome forms
  useEffect(() => {
    if (parentSchema) {
      const parentRecordType = parentRecord?.type;
      const relatedSchemas = getAllSchemaAssociationSchemas(parentSchema?.associations, []);
      const outcomeSchemas: SchemaEntity[] = relatedSchemas?.filter((relatedSchema: SchemaEntity) =>
        schemaHasType(relatedSchema, 'OUTCOME_FORM'),
      );

      if (outcomeSchemas.length > 0) {
        let filteredOutcomeSchemas = Object.assign(outcomeSchemas);

        // Exclude schemas with incompatible type constraints, leave schemas that have no type constraints defined.
        if (parentSchema && parentRecordType) {
          const associations = parentSchema.associations;

          associations.map((assoc: SchemaAssociationEntity) => {
            if (assoc.schemaTypesConstraints.length > 0) {
              const constraintTypes = assoc.schemaTypesConstraints.map(
                (constraint: SchemaAssociationSchemaTypesConstraintEntity) => {
                  return constraint.parentSchemaType?.name;
                },
              );

              if (!constraintTypes.includes(parentRecordType)) {
                filteredOutcomeSchemas = filteredOutcomeSchemas.filter(
                  (schema: SchemaEntity) => schema.id !== assoc.childSchemaId,
                );
              }
            }
          });
        }

        setSchemasWithOutcomeForms(filteredOutcomeSchemas);
      }
    }
  }, [parentSchema]);

  // Get all schema actions once schemas with outcome forms are extracted
  useEffect(() => {
    if (schemasWithOutcomeForms.length > 0) {
      const schemaIds = schemasWithOutcomeForms.map((schema: any) => schema.id);
      setIsLoadingSchemaActions(true);
      getAllSchemaActionsForMultipleSchemaIds(schemaIds).then((res: any) => {
        setSchemaActions(res);
        setIsLoadingSchemaActions(false);
      });
    }
  }, [schemasWithOutcomeForms]);

  const initializeOutcomeForm = (schemaAction: SchemaActionEntity) => {
    const OFSchema = schemasWithOutcomeForms?.find(
      (schema: SchemaEntity) => schema.id === schemaAction.schemaId,
    );

    if (OFSchema) {
      // D19-2917 - Schemas with outcome forms didn't have any associations on them,
      // so we needed to add an additional call to retrieve complete schema. Associations
      // were crucial for LOOKUP fields.
      getSchema({ schemaId: OFSchema?.id }, (res: any) => {
        initializeForm({
          formUUID: uuid,
          title: `Create ${schemaAction.name}`,
          showFormModal: true,
          isCreateReq: true,
          schema: res,
          selected: null,
          recordType: 'OUTCOME_FORM',
          sections: [{ name: res?.name, schema: res }],
          schemaActionId: schemaAction?.id,
          modified: [
            {
              schemaId: res!.id,
              associations: [
                {
                  entity: parentRecord.entity,
                  recordId: parentRecord.id,
                },
              ],
            },
          ],
        });
      });
    }
  };

  const truncate = (str: any) => {
    return str?.length > 30 ? str.substring(0, 33) + '...' : str;
  };

  const renderFormActions = () => {
    const getColumns = () => {
      if (columns && !isMobile) {
        return 24 / columns;
      } else {
        return 24;
      }
    };

    // Remove schema actions that are not CREATE type
    const filteredSchemaActions = schemaActions?.filter(
      (schemaAction: any) => schemaAction.isCreate,
    );

    return filteredSchemaActions.map((schemaAction: any, i: number) => {
      const version = getSchemaActionVersion(schemaAction);
      const Definition = schemaAction.definition;

      let actionLabel: string = '';

      if (version === 1 && Definition?.actionLabel) {
        actionLabel = Definition?.actionLabel;
      } else if (version === 2 && Definition?.settings?.actionLabel) {
        actionLabel = Definition?.settings?.actionLabel;
      } else {
        actionLabel = schemaAction.name;
      }

      return (
        <Col span={getColumns()} style={{ padding: 5 }}>
          <Button onClick={() => initializeOutcomeForm(schemaAction)} text={actionLabel} fill />
        </Col>
      );
    });
  };

  return (
    <Section
      style={{ marginBottom: 10 }}
      icon={
        <ModuleEntityIcon
          iconContainerStyle={{ padding: '5px 6px', fontSize: '1em', margin: 0 }}
          style={{ padding: 0 }}
          overrideIcon="ui-checks-grid"
          overrideIconColor="#1981f5"
          moduleName=""
          entityName=""
        />
      }
      className="outcomeFormLauncherSection"
      title="Outcome Forms"
    >
      <Row style={{ padding: 10 }}>
        {/* Loader */}
        {isLoadingSchemaActions && (
          <Col span={24} style={{ padding: 15, textAlign: 'center' }}>
            <Spin style={{ marginBottom: 10 }} />
            <br />
            <span>Loading...</span>
          </Col>
        )}

        {/* Show All Actions */}
        {schemaActions.length > 0 && !isLoadingSchemaActions && renderFormActions()}

        {/*  No Outcome Forms */}
        {schemaActions.length === 0 && !isLoadingSchemaActions && (
          <Col span={24} style={{ padding: 10 }}>
            <Empty description="There are no OUTCOME_FORM schemas." />
          </Col>
        )}
      </Row>
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          // Callback function after the record was created
        }}
      />
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(SchemaActionFormLauncher);
