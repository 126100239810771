import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogBody,
  DialogFooter,
  Divider,
  EntityTitle,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Section,
  Spinner,
  Switch,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateUserRolesAndPermissionsRequest } from '../../../../../../core/identity/store/actions';
import { EditUser, editUserRequest } from '../../../../../../core/identityUser/store/actions';
import { listSchemasRequest } from '../../../../../../core/schemas/store/actions';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../../../../../shared/http/requests';
import { isSystemAdmin } from '../../../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import history from '../../../../../../shared/utilities/browserHistory';
import { getErrorMessage } from '../../../../utils/errors';
import UpdateCompanySelect from '../../Companies/UpdateCompanySelect';
import UpdateDepartmentSelect from '../../Departments/UpdateDepartmentSelect';
import UpdateRegionSelect from '../../Regions/UpdateRegionSelect';
import UpdateRoleSelect from '../../Roles/UpdateRoleSelect';
import UpdateTeamSelect from '../../Teams/UpdateTeamSelect';
import UpdateTerritorySelect from '../../Territories/UpdateTerritorySelect';
import * as formFields from '../FormFields';
import ManageUserGroupsDialog from '../ManageUserGroupsDialog';
import UpdateReportsToSelect from '../UpdateReportsToSelect';
import UpdateSalesChannelSelect from '../UpdateSalesChannelSelect';

interface Props {
  user: any;

  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  updateUserRolesAndPermissions: (params: any, cb?: any) => void;
  listSchemas: (cb?: any) => void;
  userReducer: any;
}

const UserDetails: FC<Props> = (props: Props) => {
  const {
    user,
    onUpdate,
    initializeForm,
    alertMessage,
    onDelete,
    updateUserRolesAndPermissions,
    listSchemas,
    userReducer,
  } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isRemoveAllGroupsAlertVisible, setIsRemoveAllGroupsAlertVisible] =
    useState<boolean>(false);
  const [isDeleteUserAlertVisible, setIsDeleteUserAlertVisible] = useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);

  const [isLoginAsUserDialogVisible, setIsLoginAsUserDialogVisible] = useState<boolean>(false);
  const [isLoggingInAsUser, setIsLoggingInAsUser] = useState<boolean>(false);

  const [groups, setGroups] = useState<any[]>([]);
  const [role, setRole] = useState<any>(undefined);
  const [team, setTeam] = useState<any>(undefined);
  const [company, setCompany] = useState<any>(undefined);
  const [department, setDepartment] = useState<any>(undefined);
  const [region, setRegion] = useState<any>(undefined);
  const [territory, setTerritory] = useState<any>(undefined);
  const [reportsToUser, setReportsToUser] = useState<any>(undefined);

  const [isLoadingTeam, setIsLoadingTeam] = useState<boolean>(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [isLoadingRole, setIsLoadingRole] = useState<boolean>(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false);
  const [isLoadingDepartment, setIsLoadingDepartment] = useState<boolean>(false);
  const [isLoadingRegion, setIsLoadingRegion] = useState<boolean>(false);
  const [isLoadingTerritory, setIsLoadingTerritory] = useState<boolean>(false);

  const [isLoadingReportsToUser, setIsLoadingReportsToUser] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      getAssociatedGroups(user.id);
    }

    if (user?.roleId) {
      getAssociatedRole(user.roleId);
    } else {
      setRole(undefined);
    }

    if (user?.teamId) {
      getAssociatedTeam(user.teamId);
    } else {
      setTeam(undefined);
    }

    if (user?.companyId) {
      getAssociatedCompany(user.companyId);
    } else {
      setCompany(undefined);
    }

    if (user?.departmentId) {
      getAssociatedDepartment(user.departmentId);
    } else {
      setDepartment(undefined);
    }

    if (user?.regionId) {
      getAssociatedRegion(user.regionId);
    } else {
      setRegion(undefined);
    }

    if (user?.territoryId) {
      getAssociatedTerritory(user.territoryId);
    } else {
      setTerritory(undefined);
    }

    if (user?.reportsTo?.id) {
      getAssociatedReportsToUser(user.reportsTo?.id);
    } else {
      setReportsToUser(undefined);
    }
  }, [user]);

  const getAssociatedGroups = async (userId: string) => {
    setIsLoadingGroups(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/users/${userId}/groups`);
      const groups = res.data?.data;
      if (groups) {
        setGroups(groups);
      }
      setIsLoadingGroups(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated groups. ' + message, type: 'error' });
      setIsLoadingGroups(false);
    }
  };

  const getAssociatedRole = async (roleId: string) => {
    setIsLoadingRole(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/roles/${roleId}`);
      const role = res.data?.data;
      if (role) {
        setRole(role);
      }
      setIsLoadingRole(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated role. ' + message, type: 'error' });
      setIsLoadingRole(false);
      setRole(undefined);
    }
  };

  const getAssociatedTeam = async (teamId: string) => {
    setIsLoadingTeam(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/teams/${teamId}`);
      const team = res.data?.data;
      if (team) {
        setTeam(team);
      }
      setIsLoadingTeam(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated team. ' + message, type: 'error' });
      setIsLoadingTeam(false);
      setTeam(undefined);
    }
  };

  const getAssociatedCompany = async (companyId: string) => {
    setIsLoadingCompany(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/companies/${companyId}`);
      const company = res.data?.data;
      if (company) {
        setCompany(company);
      }
      setIsLoadingCompany(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated company. ' + message, type: 'error' });
      setIsLoadingCompany(false);
      setCompany(undefined);
    }
  };

  const getAssociatedDepartment = async (departmentId: string) => {
    setIsLoadingDepartment(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/departments/${departmentId}`);
      const department = res.data?.data;
      if (department) {
        setDepartment(department);
      }
      setIsLoadingDepartment(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated department. ' + message, type: 'error' });
      setIsLoadingDepartment(false);
      setDepartment(undefined);
    }
  };

  const getAssociatedRegion = async (regionId: string) => {
    setIsLoadingRegion(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/regions/${regionId}`);
      const region = res.data?.data;
      if (region) {
        setRegion(region);
      }
      setIsLoadingRegion(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated region. ' + message, type: 'error' });
      setIsLoadingRegion(false);
      setRegion(undefined);
    }
  };

  const getAssociatedTerritory = async (territoryId: string) => {
    setIsLoadingTerritory(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/territories/${territoryId}`);
      const territory = res.data?.data;
      if (territory) {
        setTerritory(territory);
      }
      setIsLoadingTerritory(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated territory. ' + message, type: 'error' });
      setIsLoadingTerritory(false);
      setTerritory(undefined);
    }
  };

  const getAssociatedReportsToUser = async (userId: string) => {
    setIsLoadingReportsToUser(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/users/${userId}`);
      const user = res.data?.data;
      if (user) {
        setReportsToUser(user);
      }
      setIsLoadingReportsToUser(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch Reports to user. ' + message, type: 'error' });
      setIsLoadingReportsToUser(false);
      setReportsToUser(undefined);
    }
  };

  const showEditUserForm = () => {
    const tempEl: any = user;
    let editForm: any[] = [];

    formFields.userEditForm.forEach((element: any) => {
      editForm.push({
        allowClear: element.allowClear,
        allowSearch: element.allowSearch,
        customValidation: element.customValidation,
        customValidationMessage: element.customValidationMessage,
        initialValue: tempEl[element.property],
        isDisabled: element.isDisabled,
        isHidden: element.isHidden,
        isRequired: element.isRequired,
        label: element.label,
        message: element.message,
        options: element.options,
        property: element.property,
        type: element.type,
        value: tempEl[element.property],
      });
    });

    initializeForm({
      formUUID: user.id,
      title: 'Edit User',
      recordId: user?.id,
      showModal: true,
      formFields: editForm,
      entityName: 'User',
      isUpdateReq: true,
      columns: 1,
    });
  };

  const editUser = async (body: any) => {
    try {
      await httpPut(`IdentityModule/v1.0/users/${user.id}`, body);
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'There was a problem updating the user. ' + message,
        type: 'error',
      });
    }
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit User') {
      editUser(params.data);
    } else if (params.title === 'Change Password') {
      updateUserPassword(params.data);
    }
  };

  const showChangePasswordForm = () => {
    const tempEl: any = user;
    let editForm: any[] = [];

    formFields.changePasswordForm.forEach((element: any) => {
      editForm.push({
        label: element.label,
        property: element.property,
        type: element.type,
        isRequired: element.isRequired,
        message: element.message,
        isHidden: element.isHidden,
        initialValue: tempEl[element.property],
        value: tempEl[element.property],
        options: element.options,
        isDisabled: element.isDisabled,
        allowClear: element.allowClear,
        customValidation: element.customValidation,
        customValidationMessage: element.customValidationMessage,
      });
    });

    initializeForm({
      formUUID: user.id,
      title: 'Change Password',
      recordId: user?.id,
      showModal: true,
      formFields: editForm,
      entityName: 'User',
      isUpdateReq: true,
    });
  };

  const toggleMFA = () => {
    const body = {
      enableMfa: !user?.enableMfa,
    };
    editUser(body).then(() => {
      alertMessage({ body: 'Updated user MFA status', type: 'success' });
    });
  };

  const toggleUserStatus = () => {
    const body = {
      status: user.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    };
    editUser(body).then(() => {
      alertMessage({ body: 'Updated user status', type: 'success' });
    });
  };

  const updateUserPassword = async (body: any) => {
    try {
      await httpPost(`IdentityModule/v1.0/users/change-password`, body);
      alertMessage({ body: 'Password updated', type: 'success' });
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'There was a problem updating the user. ' + message, type: 'error' });
    }
  };

  const removeAllGroupsFromUser = async () => {
    try {
      const groupIds = groups.map((group) => group.id);
      await httpDelete(`IdentityModule/v2.0/users/${user.id}/groups`, {
        groupIds,
      });

      alertMessage({ body: 'All groups removed from user', type: 'success' });
      onUpdate();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({
        body: 'There was a problem removing groups from the user. ' + message,
        type: 'error',
      });
    }
  };

  const deleteUser = async () => {
    try {
      await httpDelete(`IdentityModule/v1.0/users/${user.id}`);
      alertMessage({ body: 'User deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'There was a problem deleting the user. ' + message, type: 'error' });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  const logInAsUser = async () => {
    setIsLoggingInAsUser(true);
    setIsLoginAsUserDialogVisible(true);

    try {
      // 1. copy the current user's token into local storage
      const originalToken = localStorage.getItem('token');
      if (originalToken) {
        await localStorage.setItem('originalToken', originalToken);
      }

      // 2. Fetch the new token and save to local storage
      const res = await httpGet(`IdentityModule/v1.0/users/login-as/${user.id}`);
      const { token } = res?.data;
      if (token) {
        await localStorage.setItem('token', token);

        // 3. Fetch user /my endpoint to get the user details, this will save the user to shortlist
        updateUserRolesAndPermissions((res: any) => {
          if (res) {
            setTimeout(() => {
              setIsLoggingInAsUser(false);
            }, 1000);

            listSchemas();
          } else {
            setIsLoggingInAsUser(false);
            setIsLoginAsUserDialogVisible(true);
          }
        });
      }
    } catch (error: any) {
      setIsLoggingInAsUser(false);
      setIsLoginAsUserDialogVisible(true);
      alertMessage({ body: 'Could not log in as user. ' + error.message, type: 'error' });
    }
  };

  const ActionsMenu = () => {
    const previousToken = localStorage.getItem('originalToken');

    return (
      <Menu>
        {/* Change Password */}
        <MenuItem text="Change Password" onClick={showChangePasswordForm} />

        {/* Remove All Groups */}
        <MenuItem text="Remove All Groups" onClick={() => setIsRemoveAllGroupsAlertVisible(true)} />

        {/* Log in as User */}
        {isSystemAdmin(userReducer) && (
          <Tooltip
            disabled={!previousToken}
            content="You are already logged in as another user."
            hoverOpenDelay={500}
            fill
          >
            <MenuItem
              text="Log in as User"
              onClick={logInAsUser}
              disabled={
                !!previousToken || user.status !== 'ACTIVE' || user.id === userReducer.user?.id
              }
            />
          </Tooltip>
        )}

        <MenuDivider />

        {/* MFA */}
        <MenuItem
          text="MFA"
          onClick={(e: any) => e.stopPropagation()}
          labelElement={
            <Switch style={{ margin: 0 }} checked={user.enableMfa} onChange={toggleMFA} />
          }
        />

        <MenuDivider />

        {/* Change Status */}
        <MenuItem
          text="User Status"
          onClick={(e: any) => e.stopPropagation()}
          labelElement={
            <Switch
              className={`userStatusSwitch ${user.status === 'ACTIVE' ? 'active' : 'inactive'}`}
              checked={user.status === 'ACTIVE'}
              onChange={toggleUserStatus}
            />
          }
        />

        <MenuDivider />

        {/* Delete User */}
        <MenuItem
          text="Delete User"
          intent="danger"
          onClick={() => setIsDeleteUserAlertVisible(true)}
        />
      </Menu>
    );
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        <SharedFormModal
          formUUID={user.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0 }}>
                {user.firstname} {user.lastname}
              </h3>
              <a style={{ display: 'block', lineHeight: 2 }} href={`mailto:${user.email}`}>
                {user.email}
              </a>
            </Col>

            <Col>
              <Tag minimal round intent={user.status === 'ACTIVE' ? 'success' : 'warning'}>
                {user.status}
              </Tag>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Popover content={<ActionsMenu />} placement="bottom" fill>
                <Button rightIcon="caret-down" text="Actions" fill />
              </Popover>
            </Col>
            <Col span={12}>
              <Button
                text="Edit User"
                fill
                icon="annotation"
                onClick={(e: any) => {
                  showEditUserForm();
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Role */}
        <Col span={24} className="detailViewSection">
          <Section
            title={
              <>
                Role{' '}
                {role && (
                  <a
                    href={`#roles/${role.id}`}
                    style={{ fontSize: '0.8em' }}
                    onClick={(e) => {
                      e?.preventDefault();
                      window.location.hash = `#roles/${role.id}`;
                    }}
                  >
                    (View Role)
                  </a>
                )}
              </>
            }
          >
            <Row>
              <Col span={24}>
                <UpdateRoleSelect
                  entity="users"
                  defaultRole={role}
                  sourceRecord={user}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Company */}
        <Col span={24} className="detailViewSection">
          <Section
            title={
              <>
                Company{' '}
                {company && (
                  <a href={`#companies/${company.id}`} style={{ fontSize: '0.8em' }}>
                    (View Company)
                  </a>
                )}
              </>
            }
          >
            <Row>
              <Col span={24}>
                <UpdateCompanySelect
                  entity="users"
                  defaultCompany={company}
                  sourceRecord={user}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Department */}
        <Col span={24} className="detailViewSection">
          <Section
            title={
              <>
                Department{' '}
                {department && (
                  <a href={`#departments/${department.id}`} style={{ fontSize: '0.8em' }}>
                    (View Department)
                  </a>
                )}
              </>
            }
          >
            <Row>
              <Col span={24}>
                <UpdateDepartmentSelect
                  entity="users"
                  defaultDepartment={department}
                  sourceRecord={user}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Team */}
        <Col span={24} className="detailViewSection">
          <Section
            title={
              <>
                Team{' '}
                {team && (
                  <a href={`#teams/${team.id}`} style={{ fontSize: '0.8em' }}>
                    (View Team)
                  </a>
                )}
              </>
            }
          >
            <Row>
              <Col span={24}>
                <UpdateTeamSelect
                  entity="users"
                  defaultTeam={team}
                  sourceRecord={user}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Region */}
        <Col span={24} className="detailViewSection">
          <Section
            title={
              <>
                Region{' '}
                {region && (
                  <a href={`#regions/${region.id}`} style={{ fontSize: '0.8em' }}>
                    (View Region)
                  </a>
                )}
              </>
            }
          >
            <Row>
              <Col span={24}>
                <UpdateRegionSelect
                  entity="users"
                  defaultRegion={region}
                  sourceRecord={user}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Territory */}
        <Col span={24} className="detailViewSection">
          <Section
            title={
              <>
                Territory{' '}
                {territory && (
                  <a href={`#territories/${territory.id}`} style={{ fontSize: '0.8em' }}>
                    (View Territory)
                  </a>
                )}
              </>
            }
          >
            <Row>
              <Col span={24}>
                <UpdateTerritorySelect
                  entity="users"
                  defaultTerritory={territory}
                  sourceRecord={user}
                  onUpdate={onUpdate}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Reports to */}
        <Col span={24} className="detailViewSection">
          <Section title="Reports to">
            <Row>
              <Col span={24}>
                <UpdateReportsToSelect
                  entity="users"
                  defaultReportsToUser={reportsToUser}
                  sourceRecord={user}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Sales Channel */}
        <Col span={24} className="detailViewSection">
          <Section title="Sales Channel">
            <Row>
              <Col span={24}>
                <UpdateSalesChannelSelect
                  entity="users"
                  defaultSalesChannel={user.salesChannel}
                  sourceRecord={user}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Groups */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Groups ${groups?.length ? `(${groups?.length})` : ''}`}
            rightElement={
              !isLoadingGroups ? (
                <ManageUserGroupsDialog user={user} groups={groups} onUpdate={onUpdate} />
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Group list */}
              {groups.slice(0, 3)?.map((group: any) => (
                <Col span={24} key={group.id}>
                  <EntityTitle key={group.id} icon="people" title={group.name} />
                </Col>
              ))}
              {/* Show how many more Groups */}
              {groups.length > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${groups.length - 3} more`}</span>
                </Col>
              )}
              {!groups.length && (
                <Col span={24}>
                  {isLoadingGroups ? (
                    <Spinner size={15} style={{ justifyContent: 'start' }} />
                  ) : (
                    'None'
                  )}
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* User ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="User Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(user.id)}
              />
            }
          >
            <span>{user.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Organization */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Organization"
                        subtitle={<span>{user.organization?.name || 'None'}</span>}
                      />
                    </Col>

                    {/* Job Title */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        title="Job Title"
                        subtitle={<span>{user.jobTitle || 'None'}</span>}
                      />
                    </Col>

                    {/* Employment */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        title="Employment"
                        subtitle={<span>{user.employment || 'None'}</span>}
                      />
                    </Col>

                    {/* Sales Channel */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        title="Sales Channel"
                        subtitle={<span>{user.salesChannel || 'None'}</span>}
                      />
                    </Col>

                    {/* Sales Region */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        title="Sales Region"
                        subtitle={<span>{user.salesRegion || 'None'}</span>}
                      />
                    </Col>

                    {/* Sales Territory */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        title="Sales Territory"
                        subtitle={<span>{user.salesTerritory || 'None'}</span>}
                      />
                    </Col>

                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(user.updatedAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(user.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Log in as User dialog */}
      <Dialog
        icon="log-in"
        title={`Log in as ${user.firstname} ${user.lastname}`}
        isCloseButtonShown={false}
        isOpen={isLoginAsUserDialogVisible}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
      >
        <DialogBody>
          {isLoggingInAsUser ? (
            <Row style={{ textAlign: 'center' }}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <span>Logging in...</span>
              </Col>
              <Col span={24}>
                <Spinner size={32} />
              </Col>
            </Row>
          ) : (
            <Row style={{ textAlign: 'center' }}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <span>Successfully logged in</span>
              </Col>
              <Col span={24}>
                <Icon icon="tick-circle" size={32} intent="success" />
              </Col>
            </Row>
          )}
        </DialogBody>
        <DialogFooter
          actions={[
            <Button
              key="OK"
              intent="primary"
              disabled={isLoggingInAsUser}
              onClick={() => {
                setIsLoginAsUserDialogVisible(false);
                history.push('/');
              }}
            >
              OK
            </Button>,
          ]}
        />
      </Dialog>

      {/* Remove all Groups Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        isOpen={isRemoveAllGroupsAlertVisible}
        onCancel={() => setIsRemoveAllGroupsAlertVisible(false)}
        onClose={() => setIsRemoveAllGroupsAlertVisible(false)}
        onConfirm={removeAllGroupsFromUser}
      >
        <p>
          Are you sure you want to remove this user from all groups? This action cannot be undone.
        </p>
      </Alert>

      {/* Delete user Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteUserAlertVisible}
        onCancel={() => setIsDeleteUserAlertVisible(false)}
        onClose={() => setIsDeleteUserAlertVisible(false)}
        onConfirm={deleteUser}
      >
        <p>Are you sure you want to delete this user? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  editUser: (params: EditUser, cb: any) => dispatch(editUserRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateUserRolesAndPermissions: (cb: any) => dispatch(updateUserRolesAndPermissionsRequest(cb)),
  listSchemas: (cb: any) => dispatch(listSchemasRequest(cb)),
});

export default connect(mapState, mapDispatch)(UserDetails);
