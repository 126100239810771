import { Layout, Row, Col, Card, Button, Alert, Input } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import G6 from '@antv/g6';
import {
  QGISCableTypeEnum,
  QGISClosureTypeEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import {
  constructCableTitle,
  getPlanCableColor,
  getPlanClosureStyle,
} from '../../../../com.netomnia/modules/ProjectModule/Autosplicing/helpers';

type cableDiagramData = {
  nodes: any[];
  edges: any[];
};

interface IReactRouterParams {
  polygonId: string;
}

let graph: any = null;

const JSONGraphBrowser = (props: RouteComponentProps<IReactRouterParams>) => {
  const [cableData, setCableData] = useState<string | null>(null);
  const [cableDataValid, setCableDataValid] = useState(false);

  const ref = React.useRef(null);

  async function drawGraph(diagramData: cableDiagramData): Promise<any> {
    const graphConfig = {
      container: ref?.current!,
      modes: {
        default: [{ type: 'drag-canvas' }, 'zoom-canvas'],
      },
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesep: 15,
        ranksep: 270,
        controlPoints: true,
      },
      defaultNode: {
        type: 'node',
        size: 90,
        labelCfg: {
          style: {
            fontSize: 18,
            fontWeight: 600,
            padding: 15,
            cursor: 'pointer',
          },
        },
        style: {
          width: 150,
          stroke: '#72CC4A',
          cursor: 'pointer',
        },
      },
      defaultEdge: {
        type: 'polyline',
        style: {
          cursor: 'pointer',
        },
        labelCfg: {
          position: 'end',
          style: { fontSize: 20, cursor: 'pointer' },
        },
      },
    };

    if (graph !== null) {
      graph.data(diagramData);
      graph.render();
    } else {
      graph = new G6.Graph(graphConfig);
      graph.data(diagramData);
      graph.render();
    }
  }

  useEffect(() => {
    if (cableData) {
      if (IsJsonString(cableData)) setCableDataValid(true);
      else setCableDataValid(false);
    }
  }, [cableData]);

  const IsJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const generateCableDiagram = () => {
    if (cableData && cableDataValid) {
      let allClosures: Array<any> = [];
      let allLoopIds: Array<string> = [];
      let filteredLoopIds: Array<string> = [];
      let allEdges: Array<any> = [];

      /* Get all loop path ids */
      for (const elem of JSON.parse(cableData)) {
        if (elem.loopPathId) {
          allLoopIds.push(elem.loopPathId);
        }
      }

      // remove loop chains that are < 2
      allLoopIds?.map((loopId: string) => {
        const foundItem = allLoopIds.filter((loop: any) => loop === loopId);
        if (foundItem && foundItem.length > 1) {
          filteredLoopIds.push(foundItem[0]);
        }
      });

      const uniqueLoopIds = [...new Set([...filteredLoopIds])];

      for (const elem of JSON.parse(cableData)) {
        let loopNumber: string | undefined = undefined;
        if (uniqueLoopIds.includes(elem.loopPathId)) {
          loopNumber = String(uniqueLoopIds.indexOf(elem.loopPathId) + 1);
        }

        /* From Closure*/
        allClosures.push({
          loopChainId: loopNumber ? loopNumber : undefined,
          id: String(elem.fromClosure),
          entityId: String(elem.fromClosure),
          label: QGISClosureTypeEnum[elem.fromClosureType] + '\n' + String(elem.fromClosure),
          type: String(elem.fromClosureType),
          style: getPlanClosureStyle(QGISClosureTypeEnum[elem.fromClosureType]),
          labelCfg: {
            style: {
              fill: getPlanClosureStyle(QGISClosureTypeEnum[elem.fromClosureType]).color,
            },
          },
        });

        /* To Closure*/
        allClosures.push({
          loopChainId: loopNumber,
          id: String(elem.toClosure),
          entityId: String(elem.toClosure),
          label: QGISClosureTypeEnum[elem.toClosureType] + '\n' + String(elem.toClosure),
          type: String(elem.toClosureType),
          style: getPlanClosureStyle(QGISClosureTypeEnum[elem.toClosureType]),
          labelCfg: {
            style: {
              fill: getPlanClosureStyle(QGISClosureTypeEnum[elem.toClosureType]).color,
            },
          },
        });

        /* Cables */
        allEdges.push({
          id: String(elem.cable) + '-cable',
          entityId: String(elem.cable),
          loopChainId: loopNumber,
          source: String(elem.fromClosure),
          target: String(elem.toClosure),
          label: constructCableTitle(elem, loopNumber, 'PLAN'),
          size: 5,
          labelCfg: { autoRotate: true, refY: 18, refX: -25 },
          style: {
            lineWidth: loopNumber ? 8 : 4,
            stroke: getPlanCableColor(QGISCableTypeEnum[elem.cableType]),
            opacity: 1,
            fontSize: 7,
          },
        });
      }

      drawGraph({ nodes: allClosures, edges: allEdges });
    }
  };

  const renderJSONStatus = () => {
    if (cableDataValid) return <Alert message="Valid JSON data." type="success" showIcon />;
    else return <Alert message="Bad JSON data." type="error" showIcon />;
  };

  const onChange = (e: any) => {
    if (e.target.value) {
      setCableData(e.target.value);
    } else {
      setCableData(null);
    }
    setCableDataValid(false);
  };

  const { TextArea } = Input;
  const { Content } = Layout;

  return (
    <Layout style={{ padding: '20px' }}>
      <Content>
        <Row>
          {/* Graph */}
          <Col span={18}>
            <div
              style={{
                height: '89vh',
                width: '100%',
                backgroundColor: '#fff',
                padding: 10,
                borderRadius: 4,
                border: '1px solid #dddbda',
              }}
            >
              <div style={{ height: '87vh', width: '100%' }} ref={ref} />
            </div>
          </Col>

          {/* Sidebar */}
          <Col span={6}>
            <Card
              style={{ marginBottom: 20, marginLeft: 10, padding: 10, height: '89vh' }}
              title={
                <Row>
                  <Col span={16} style={{ paddingTop: 6, fontSize: '1.1em' }}>
                    <span>Autosplicing - Raw Data</span>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <Button
                      disabled={!cableData || !cableDataValid}
                      type="primary"
                      onClick={() => generateCableDiagram()}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              }
            >
              <Row>
                <Col span={24}>
                  {cableData ? renderJSONStatus() : <></>}
                  <TextArea
                    rows={30}
                    placeholder="Paste JSON data here"
                    onChange={onChange}
                    style={{
                      backgroundColor: 'aliceblue',
                      fontSize: '0.7em',
                      height: cableData ? '72vh' : '76vh',
                      fontFamily: 'monospace, monospace',
                      marginTop: 10,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default withRouter(JSONGraphBrowser);
