import React, { useEffect } from 'react';

import { useAFPApprovalService, useAFPApprovalState } from '..';
import { filterFpes, getSelectedRecords, getUniqueFeatureIds, groupFpesByStatusAndType } from '../helpers';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

export const RecordSelectionEffects: React.FC = () => {
  const state = useAFPApprovalState();
  const {
    records: { allFpes },
    fpeSelection: { filters }
  } = state;
  const selectedRecords = getSelectedRecords(state);
  const featureIds = getUniqueFeatureIds(selectedRecords);

  const { setSelectedRecords, loadFeature, loadFeatureResponse } = useAFPApprovalService();

  const { fpesByStatus } = groupFpesByStatusAndType(allFpes.data);
  const selectedFpe = selectedRecords[0];

  // Automatically Select first TODO item if
  // no selection was made
  useEffect(() => {
    if (filters.status !== 'todo') return;

    const { todo } = fpesByStatus;
    const fpe = todo.find(r => r.id === selectedFpe?.id);

    if (!fpe && todo.length) setSelectedRecords([todo[0]]);
  }, [filters.status, selectedFpe?.id, fpesByStatus.todo.map(r => r.id).join()]);

  // Select all FPEs matching the same feature
  useEffect(() => {
    if (featureIds.length !== 1) {
      // Clear feature from state if
      // it was previously loaded
      loadFeatureResponse();
      return;
    }

    const featureId = featureIds[0];
    const filteredFpes = filterFpes(fpesByStatus[filters.status], filters);

    const newSelectedRecords = [
      ...selectedRecords,
      ...filteredFpes.filter(fpe => getProperty(fpe, 'FeatureId') === featureId && !selectedRecords.includes(fpe))
    ];
    setSelectedRecords(newSelectedRecords);
    loadFeature(featureId);
  }, [
    filters.status,
    selectedRecords.length,
    featureIds.join()
  ]);

  return null;
}
