import { Button, InputGroup, Tag, Tooltip } from '@blueprintjs/core';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import {
  getDefaultFields,
  getSavedFilter,
  setSearchQuery,
  setSortQuery,
} from '../../../../shared/utilities/searchHelpers';
import { getPipelinesByModuleAndEntity } from '../../../pipelines/store/actions';
import { PipelineReducerState } from '../../../pipelines/store/reducer';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  ISearchRecords,
  resetRecordsList,
  searchRecordsDebounced,
  searchRecordsRequest,
  setDbRecordSearchQuery,
} from '../../store/actions';
import { IRecordReducer } from '../../store/reducer';
import TableFilterActions from '../DynamicTable/components/TableFilterActions';
import {
  QueryBuilderReducer,
  getQueryBuilderReducer,
} from '../DynamicTable/QueryBuilder/store/reducer';
import { TableReducer } from '../DynamicTable/store/reducer';

interface Props {
  entityName: string;
  getPipelines: any;
  hideActionButtons?: boolean;
  hideFilters?: boolean;
  moduleName: string;
  noReset?: boolean;
  pipelineReducer: PipelineReducerState;
  queryBuilderReducer: QueryBuilderReducer;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  removeFormField: (UUID: string) => {};
  resetRecordState: any;
  schema: SchemaEntity;
  schemaReducer: ISchemaReducer;
  searchRecords: any;
  searchRecordsDebounced: any;
  setSearchQuery: any;
  userReducer: any;
}

interface State {
  typeFilters: string[];
  pipelineFilters: string[];
  groupsFilters: string[];
  propertyFilters: string[];
}

class RecordSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      typeFilters: [],
      pipelineFilters: [],
      groupsFilters: [],
      propertyFilters: [],
    };
  }

  componentDidMount() {
    const { noReset } = this.props;
    if (!noReset) {
      this.props.resetRecordState();
    }
    this.loadInitialList();
    this.getSavedFilters();
    this.loadPipelineFilters();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    // If there was no previous schema and the new one came in -> Load initial list.
    if (!prevProps.schema && this.props.schema) {
      this.loadInitialList();
      this.getSavedFilters();
    }

    // If there was previous schema and the new came updated -> Load initial list.
    if (prevProps.schema && this.props.schema && prevProps.schema !== this.props.schema) {
      this.loadInitialList();
    }
    if (prevProps.entityName !== this.props.entityName) {
      this.getSavedFilters();
    }

    // Watch for schema type updates, and reload the pipeline filters
    const prevQbr = getQueryBuilderReducer(
      prevProps.queryBuilderReducer,
      this.props.schema?.moduleName,
      this.props.schema?.entityName,
    );
    const currentQbr = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      this.props.schema?.moduleName,
      this.props.schema?.entityName,
    );
    if (prevQbr?.formFields?.typeFilters !== currentQbr?.formFields?.typeFilters) {
      this.getSavedFilters();
    }
    if (prevQbr?.formFields?.pipelineFilters !== currentQbr?.formFields?.pipelineFilters) {
      this.getSavedFilters();
    }
    if (prevQbr?.formFields?.groupsFilters !== currentQbr?.formFields?.groupsFilters) {
      this.getSavedFilters();
    }
    if (prevQbr?.formFields?.propertyFilters !== currentQbr?.formFields?.propertyFilters) {
      this.getSavedFilters();
    }
  }

  loadInitialList() {
    const {
      schemaReducer,
      recordTableReducer,
      recordReducer,
      moduleName,
      entityName,
      searchRecords,
      schema,
    } = this.props;

    const savedFilter = getSavedFilter(schemaReducer, recordTableReducer, moduleName, entityName);

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: setSearchQuery(schemaReducer, recordReducer, moduleName, entityName),
          fields: getDefaultFields(schema, moduleName, entityName),
          schemas: schema.id,
          sort: setSortQuery(schemaReducer, recordReducer, moduleName, entityName),
          boolean: savedFilter?.queries,
        },
      });
    }
  }

  searchRecordOnChange(e: any) {
    const {
      schemaReducer,
      recordTableReducer,
      recordReducer,
      moduleName,
      entityName,
      searchRecordsDebounced,
    } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    const savedFilter = getSavedFilter(schemaReducer, recordTableReducer, moduleName, entityName);

    if (schema && e) {
      // We are dispatching debounced search action for typed in queries.
      return searchRecordsDebounced({
        schema: schema,
        searchQuery: {
          terms: e.target.value,
          fields: getDefaultFields(schema, moduleName, entityName),
          schemas: schema.id,
          sort: setSortQuery(schemaReducer, recordReducer, moduleName, entityName),
          boolean: savedFilter?.queries,
        },
      });
    }
  }

  getSavedFilters = () => {
    const { schemaReducer, userReducer, moduleName, entityName, hideActionButtons, hideFilters } =
      this.props;

    let pipelineFilters: string[] = [];
    let groupsFilters: string[] = [];
    let propertyFilters: string[] = [];

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );

    const schemaTypeFilters = queryBuilderReducer?.formFields?.typeFilters?.map((filter: any) => {
      return filter && filter.value[0];
    });
    queryBuilderReducer?.formFields?.pipelineFilters.map((filter: any) => {
      if (filter.value && !filter.value?.every((value: any) => value === undefined)) {
        filter.value.map((value: any) => {
          pipelineFilters.push(value);
        });
      }
    });

    queryBuilderReducer?.formFields?.groupsFilters?.map((filter: any) => {
      if (filter && !filter.valueAlias?.every((value: any) => value === undefined)) {
        filter.valueAlias.map((value: any) => {
          groupsFilters.push(value);
        });
      }
    });

    queryBuilderReducer?.formFields?.propertyFilters?.map((filter: any) => {
      if (filter && filter.value) {
        propertyFilters.push(`${filter.property} ${filter.operator} ${filter.value}`);
      }
    });

    this.setState({
      typeFilters: schemaTypeFilters,
      pipelineFilters: pipelineFilters,
      groupsFilters: groupsFilters,
      propertyFilters: propertyFilters,
    });
  };

  renderFilterTags() {
    return (
      <>
        {/* Type Tag */}
        {this.state.typeFilters?.map((filter: any) => {
          return (
            filter && (
              <Tooltip hoverOpenDelay={2000} content="Schema Type Filter">
                <Tag
                  key={filter}
                  minimal
                  intent="primary"
                  round
                  style={{ marginRight: 5, marginBottom: 4 }}
                >
                  {filter}
                </Tag>
              </Tooltip>
            )
          );
        })}
        {/* Pipeline Stage Tag */}
        {this.state.pipelineFilters?.map((filter: any) => {
          return (
            filter && (
              <Tooltip hoverOpenDelay={2000} content="Stage Filter">
                <Tag
                  key={filter}
                  minimal
                  intent="success"
                  round
                  style={{ marginRight: 5, marginTop: 5 }}
                >
                  {filter}
                </Tag>
              </Tooltip>
            )
          );
        })}
        {/* Group Tag */}
        {this.state.groupsFilters?.map((filter: any) => {
          return (
            filter && (
              <Tooltip hoverOpenDelay={2000} content="Group Filter">
                <Tag minimal round key={filter} style={{ marginRight: 5, marginTop: 4 }}>
                  {filter}
                </Tag>
              </Tooltip>
            )
          );
        })}
        {this.state.propertyFilters?.map((filter: any) => {
          return (
            filter && (
              <Tooltip hoverOpenDelay={2000} content="Property Filter">
                <Tag
                  minimal
                  intent="warning"
                  round
                  key={filter}
                  style={{ marginRight: 5, marginTop: 4 }}
                >
                  {filter}
                </Tag>
              </Tooltip>
            )
          );
        })}
      </>
    );
  }

  render() {
    const { schemaReducer, recordReducer, moduleName, entityName, hideActionButtons, hideFilters } =
      this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    const value: string =
      setSearchQuery(schemaReducer, recordReducer, moduleName, entityName) || '';

    return (
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        {/* Filter Tags */}
        <Col md={12} lg={16}>
          {this.renderFilterTags()}
        </Col>

        {/* Search Panel */}
        <Col md={12} lg={8} style={{ textAlign: 'right' }}>
          <Space>
            <InputGroup
              style={{ marginRight: 10 }}
              round
              id="text-input"
              placeholder="Search"
              leftIcon="search"
              value={value}
              onChange={(e: any) => this.searchRecordOnChange(e)}
              rightElement={
                value?.length! > 0 ? (
                  <Button
                    minimal
                    intent="danger"
                    icon="cross"
                    onClick={() => this.searchRecordOnChange({ target: { value: '' } })}
                  />
                ) : (
                  <></>
                )
              }
            />
            {hideFilters ? <></> : <TableFilterActions schema={schema} />}
          </Space>
        </Col>
      </Row>
    );
  }

  private loadPipelineFilters() {
    const { getPipelines, schemaReducer, moduleName, entityName } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (schema) {
      getPipelines({ schema: schema });
    }
  }
}

const mapState = (state: any) => ({
  recordTableReducer: state.recordTableReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  queryBuilderReducer: state.queryBuilderReducer,
  pipelineReducer: state.pipelineReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  setSearchQuery: (params: ISearchRecords) => dispatch(setDbRecordSearchQuery(params)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  searchRecordsDebounced: (params: ISearchRecords, cb: any) =>
    dispatch(searchRecordsDebounced(params, cb)),
  resetRecordState: () => dispatch(resetRecordsList()),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
});

export default connect(mapState, mapDispatch)(RecordSearch);
