import React from 'react';
import { Col, Row } from 'antd';
import { Menu, MenuItem } from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import SchemasListView from '../Schemas/SchemasListView';
import './styles.scss';

const ControlPanelView: React.FC<RouteComponentProps> = ({ history, location }) => {
  return (
    <Row>
      {/* Navbar */}
      <Col span={3} style={{ padding: 5 }}>
        <Menu style={{ background: 'transparent' }}>
          <MenuItem
            icon="settings"
            text="Control Panel"
          />
          <MenuItem
            text="Identity Manager"
            onClick={() => history.push('/ControlPanelModule/IdentityManager/')}
            active={
              location.pathname === '/ControlPanelModule/IdentityManager/' ||
              location.pathname === '/ControlPanelModule'
            }
          />
          <MenuItem
            text="Schema Manager"
            onClick={() => history.push('/ControlPanelModule/SchemaManager/')}
            active={location.pathname === '/ControlPanelModule/SchemaManager/'}
          />
        </Menu>
      </Col>

      {/* Content */}
      <Col span={21}>
        <SchemasListView onSchemaSelected={
          schemaId => history.push(`/ControlPanelModule/SchemaManager/${schemaId}`)
        } />
      </Col>
    </Row>
  );
};

export default withRouter(ControlPanelView);
