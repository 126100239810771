import { Button, Drawer } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { INavigationReducer } from '../../../../core/navigation/store/reducer';
import DetailViewContext from '../../../../core/records/components/DetailViewContext';
import { IRecordReducer } from '../../../../core/records/store/reducer';
import { closeRecordDrawer } from '../../../../core/userInterface/store/actions';
import { IUserInterfaceReducer } from '../../../../core/userInterface/store/types';
import {
  getBrowserPath,
  getRecordFromShortListById,
} from '../../../../shared/utilities/recordHelpers';
import Typography from '../../core/Typography';
import DetailViewRouter from '../DetailViewRouter';
interface Props {
  userInterfaceReducer: IUserInterfaceReducer;
  navigationReducer: INavigationReducer;
  recordReducer: IRecordReducer;
  closeDrawer: () => void;
}

const RecordDrawer: React.FC<Props> = (props: Props) => {
  const { userInterfaceReducer, closeDrawer, navigationReducer, recordReducer } = props;

  const isOpen = userInterfaceReducer.recordDrawerVisible;
  const moduleName = userInterfaceReducer.recordDrawerModuleName || '';
  const entityName = userInterfaceReducer.recordDrawerEntityName || '';
  const recordId = userInterfaceReducer.recordDrawerRecordId;

  const record = getRecordFromShortListById(recordReducer.shortList, recordId) || undefined;

  // If the Drawer is visible and navigation route changes (e.g. user clicks a record in the associations), close the drawer.
  useEffect(() => {
    if (isOpen) {
      closeDrawer();
    }
  }, [navigationReducer.previousPage]);

  return (
    <Drawer
      lazy
      canEscapeKeyClose
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <Typography stronger style={{ fontSize: 16 }}>
              Quick View
            </Typography>
          </Col>
          <Col>
            <Link to={getBrowserPath(record) || ''}>
              <Button
                disabled={!record}
                text="View Full Record"
                intent="primary"
                small
                style={{ marginRight: 10 }}
              />
            </Link>
          </Col>
        </Row>
      }
      isOpen={isOpen}
      onClose={closeDrawer}
      size="93%"
      className="recordDrawer"
    >
      <Row style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Col span={24}>
          <DetailViewContext
            moduleName={moduleName}
            entityName={entityName}
            overrideRecordId={recordId}
          >
            <DetailViewRouter />
          </DetailViewContext>
        </Col>
      </Row>
    </Drawer>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  navigationReducer: state.navigationReducer,
  recordReducer: state.recordReducer,
});
const mapDispatch = (dispatch: any) => ({
  closeDrawer: () => dispatch(closeRecordDrawer()),
});

export default connect(mapState, mapDispatch)(RecordDrawer);
