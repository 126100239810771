import { Tooltip } from '@blueprintjs/core';
import React from 'react';

interface Props {
  title: string;
  value: string | number;
  tooltip?: string;
}

const Stat: React.FC<Props> = (props: Props) => {
  const { tooltip, title, value } = props;
  return (
    <Tooltip content={tooltip} disabled={!tooltip} hoverOpenDelay={800}>
      <div>
        <span style={{ fontSize: 11, opacity: 0.6, lineHeight: '2px' }}>{title}</span>
        <br style={{ lineHeight: '18px' }} />
        <span style={{ fontWeight: 600, cursor: tooltip ? 'pointer' : 'auto' }}>{value}</span>
      </div>
    </Tooltip>
  );
};
export default Stat;
