import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import RecordCard from '../../../../../core/records/components/RecordCard';
import AssociationDataTable from '../../../../../core/recordsAssociations/components/AssociationDataTable';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

const ExchangeDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators
        defaultTabKey={'Programs'}
        customTabs={[
          {
            key: 'Programs',
            tab: 'Programs',
          },
        ]}
        customTabBodies={{
          Programs: (
            <AssociationDataTable
              title={'Programs'}
              record={record}
              moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
              entityName={SchemaModuleEntityTypeEnums.PROGRAM}
            />
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.REGION}
            visibleProperties={['Contractor']}
          />,
          <AssociationDataTable
            thumbnailSize={12}
            title="Files"
            record={record}
            showFileCategoryForType="DEFAULT"
            moduleName="SchemaModule"
            entityName="File"
            location="sidebar"
            collapsedByDefault
          />,
        ]}
      />
    </Layout>
  );
};

export default ExchangeDetailView;
