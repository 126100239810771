import {
  Button,
  Card,
  Checkbox,
  Classes,
  Elevation,
  FormGroup,
  HTMLTable,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getProperty,
  sortDbRecordsByDate,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../core/userInterface/store/actions';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import { useInterval } from '../../../../shared/utils';
import { ColumnFilter, getAssignedUserOptions, useMultiSelectFilter } from './Utils';

interface Props {
  userReducer: any;
  openDrawer: (payload: IOpenRecordDrawer) => void;
}

// Polling data
let timer: NodeJS.Timeout | undefined = undefined;
const interval = 5000; // 5 second

const AgentCaseDashboard: FC<Props> = (props: Props) => {
  const { userReducer, openDrawer } = props;

  const [queues, setQueues] = useState<DbRecordEntityTransform[]>([]);
  const [selectedQueue, setSelectedQueue] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [casesInQueue, setCasesInQueue] = useState<DbRecordEntityTransform[]>([]);
  const [queueCaseCounts, setQueueCaseCounts] = useState<
    {
      [key: string]: any;
    }[]
  >([]);
  const [assignedCaseCounts, setAssignedCaseCounts] = useState<
    {
      [key: string]: any;
    }[]
  >([]);
  const [caseListLoading, setCaseListLoading] = useState<boolean>(false);
  const [lastRefreshTimeStamp, setLastRefreshTimestamp] = useState<string | null>(null);

  useEffect(() => {
    setSelectedQueue({
      id: 'assigned-to-me',
      title: 'Assigned to Me',
      properties: { R_AssignedOrgUserId: userReducer?.user?.id },
    });

    fetchQueueStats();
    fetchQueues();
  }, []);

  useInterval(() => {
    fetchQueueStats();
    fetchQueues();
    fetchCases(true);
    setLastRefreshTimestamp(DateTime.now().toISO());
  }, 1000 * 5);

  useEffect(() => {
    if (selectedQueue) {
      fetchCases(false);
      setLastRefreshTimestamp(DateTime.now().toISO());
    }
  }, [selectedQueue]);

  const getDurationSinceLastRefresh = () => {
    if (lastRefreshTimeStamp) {
      return `Last refreshed at: ${DateTime.fromISO(String(lastRefreshTimeStamp)).toFormat(
        'dd/MM/yy hh:mm:ss',
      )}`;
    }
  };

  // Fetch queues and set the initial state
  // Replace this with your actual API call or data retrieval logic
  const fetchQueues = async () => {
    // Example fetch using the Fetch API
    const response = await httpPost('SupportModule/v2.0/records/search', {
      query: {
        entity: 'SupportModule:Queue',
        type: 'and',
        value: [],
        pageSize: 1000,
        pageNumber: 0,
      },
    });
    const data = await response.data.data.records;
    setQueues(data);
  };

  const fetchQueueStats = async () => {
    // Example fetch using the Fetch API
    const queueCaseCount = await httpGet('SupportModule/v1.0/queue/case-count');
    const caseCounts = await queueCaseCount.data.data;
    if (caseCounts) {
      setQueueCaseCounts(caseCounts);
    }

    const userAssignedCaseCount = await httpGet('SupportModule/v1.0/queue/user-case-count');
    const assignedCaseCounts = await userAssignedCaseCount.data.data;
    if (assignedCaseCounts) {
      setAssignedCaseCounts(assignedCaseCounts);
    }
  };

  // Fetch cases based on the selected queue
  // Replace this with your actual API call or data retrieval logic
  const fetchCases = async (loadInBackground: boolean) => {
    if (!loadInBackground) {
      setCaseListLoading(true);
    }

    if (caseListLoading) {
      return;
    }

    if (selectedQueue && selectedQueue?.id === 'assigned-to-me') {
      const response = await httpPost('SupportModule/v2.0/records/search', {
        query: {
          entity: 'SupportModule:Case',
          type: 'and',
          value: [
            {
              columnName: 'R_AssignedOrgUserId',
              operator: 'eq',
              value: selectedQueue?.properties?.R_AssignedOrgUserId,
            },
          ],
          sort: {
            createdAt: {
              order: 'desc',
            },
          },
          pageSize: 1000,
          pageNumber: 0,
        },
      });
      const data = await response.data.data.records;
      setCasesInQueue(data);
    } else if (selectedQueue && selectedQueue?.title != 'Unassigned') {
      const response = await httpPost('SupportModule/v2.0/records/search', {
        query: {
          entity: 'SupportModule:Case',
          type: 'and',
          value: [
            {
              columnName: 'StageName',
              operator: 'in',
              value: ['Open', 'In Progress'],
            },
            {
              columnName: 'R_QueueId',
              operator: 'eq',
              value: selectedQueue.id,
            },
          ],
          sort: {
            createdAt: {
              order: 'desc',
            },
          },
          pageSize: 1000,
          pageNumber: 0,
        },
      });
      const data = await response.data.data.records;
      setCasesInQueue(data);
    } else {
      // Load unassigned queue
      const response = await httpPost('SupportModule/v2.0/records/search', {
        query: {
          entity: 'SupportModule:Case',
          type: 'and',
          value: [
            {
              columnName: 'R_QueueId',
              operator: 'isNull',
              value: null,
            },
          ],
          sort: {
            createdAt: {
              order: 'desc',
            },
          },
          pageSize: 1000,
          pageNumber: 0,
        },
      });
      const data = await response.data.data.records;
      setCasesInQueue(data);
    }

    setCaseListLoading(false);
  };

  const calculateAgeSince = (date: Date) => {
    const ISODate = DateTime.fromISO(String(date));
    const currentDate = DateTime.now();

    // Calculate the difference in duration
    const duration = currentDate.diff(ISODate);

    const years = duration.as('years');
    const months = duration.as('months');
    const days = duration.as('days');
    const hours = duration.as('hours');
    const minutes = duration.as('minutes');

    // Determine the most significant unit
    let ageString;
    if (years > 1) {
      ageString = `${years.toFixed(1)} ${years > 1 ? 'years' : 'year'}`;
    } else if (months > 1) {
      ageString = `${months.toFixed(1)} ${months > 1 ? 'months' : 'month'}`;
    } else if (days > 1) {
      ageString = `${days.toFixed(1)} ${days > 1 ? 'days' : 'day'}`;
    } else if (hours > 1) {
      ageString = `${hours.toFixed(1)} ${hours > 1 ? 'hours' : 'hour'}`;
    } else if (minutes > 1) {
      ageString = `${hours.toFixed(1)} ${hours > 1 ? 'minutes' : 'minute'}`;
    }

    return ageString;
  };

  const getCaseIntent = (caseItem: DbRecordEntityTransform) => {
    switch (getProperty(caseItem, 'Impact')) {
      case 'HIGH':
        return 'danger';
      case 'MEDIUM':
        return 'warning';
      case 'LOW':
        return 'success';
      default:
        return 'none';
    }
  };

  const getCaseSLABreach = (date: Date) => {
    const ISODate = DateTime.fromISO(String(date));
    const currentDate = DateTime.now();
    // Calculate the SLA breach time and how long until it is breached
    const SLABreachTime = ISODate.plus({ hours: 1 });
    const SLABreachDuration = SLABreachTime.diff(currentDate);
    const SLABreachMinutes = SLABreachDuration.as('minutes');

    // SLA is breached
    if (SLABreachMinutes < 0) {
      return `${SLABreachMinutes.toFixed(0)}m`;
    }

    // SLA has not been breached yet
    if (SLABreachMinutes > 0) {
      return `${SLABreachMinutes.toFixed(0)}m`;
    }
  };

  const isSLABreached = (date: Date) => {
    const ISODate = DateTime.fromISO(String(date));
    const currentDate = DateTime.now();

    // Calculate the SLA breach time and how long until it is breached
    const SLABreachTime = ISODate.plus({ hours: 1 });
    const SLABreachDuration = SLABreachTime.diff(currentDate);
    const SLABreachMinutes = SLABreachDuration.as('minutes');

    // SLA is breached
    if (SLABreachMinutes < 0) {
      return true;
    }

    // SLA has not been breached yet
    if (SLABreachMinutes > 0) {
      return false;
    }
  };

  const handleViewCase = (caseRecord: DbRecordEntityTransform) => {
    // Implement logic to view the case
    openDrawer({
      moduleName: 'SupportModule',
      entityName: 'Case',
      recordId: caseRecord.id,
    });
  };

  const assignedUserOptions = getAssignedUserOptions(casesInQueue);

  const assignedUsersFilter = useMultiSelectFilter({ options: assignedUserOptions });

  const renderAssignedToFilterOptions = () => {
    return (
      <FormGroup>
        {assignedUserOptions.map((option) => {
          const checked = assignedUsersFilter.selectedValues.includes(option.value);

          const handleClick = (evt: any) => {
            if (evt.target.checked)
              assignedUsersFilter.setSelectedValues([
                ...assignedUsersFilter.selectedValues.filter((v) => v !== 'unassigned'),
                option.value,
              ]);
            else
              assignedUsersFilter.setSelectedValues(
                assignedUsersFilter.selectedValues.filter((val) => val !== option.value),
              );
          };

          return <Checkbox checked={checked} label={option.label} onClick={handleClick} />;
        })}
        <hr />
        <Checkbox
          checked={assignedUsersFilter.selectedValues[0] === 'unassigned'}
          label="Unassigned"
          onClick={(evt: any) =>
            assignedUsersFilter.setSelectedValues(evt.target.checked ? ['unassigned'] : [])
          }
        />
      </FormGroup>
    );
  };

  const applyFilters = (caseItem: DbRecordEntityTransform): boolean => {
    const { selectedValues } = assignedUsersFilter;
    if (selectedValues.length === 0) return true;

    if (selectedValues.length === 1 && selectedValues[0] === 'undefined') {
      const assignedUser = getProperty(caseItem, 'R_AssignedUserId');
      return assignedUser === undefined || assignedUser === null;
    }

    return assignedUsersFilter.selectedValues.includes(getProperty(caseItem, 'R_AssignedUserId'));
  };

  return (
    <div className="case-list-view">
      <Row>
        {/* Left Column (4 columns) */}
        <Col span={5}>
          <Card elevation={Elevation.ONE}>
            <HTMLTable className={Classes.HTML_TABLE}>
              <thead>
                <tr>
                  <th>Queues</th>
                </tr>
              </thead>
              <tbody>
                <tr key={'assigned-to-me'}>
                  <td>
                    <Button
                      minimal
                      onClick={() =>
                        setSelectedQueue({
                          id: 'assigned-to-me',
                          title: 'Assigned to Me',
                          properties: { R_AssignedOrgUserId: userReducer?.user?.id },
                        })
                      }
                      active={selectedQueue?.id === 'assigned-to-me'}
                    >
                      Assigned to Me{' '}
                      <Tag
                        intent={
                          Number(
                            assignedCaseCounts?.find(
                              (elem: any) => elem.user_id === userReducer?.user?.id,
                            )?.user_open_cases,
                          ) > 0
                            ? 'primary'
                            : 'none'
                        }
                        minimal
                        round
                      >
                        {assignedCaseCounts
                          ? assignedCaseCounts?.find(
                              (elem: any) => elem.user_id === userReducer?.user?.id,
                            )?.user_open_cases
                          : 0}{' '}
                      </Tag>
                    </Button>
                  </td>
                </tr>
                {queues
                  .sort((a: any, b: any) => {
                    if (a && a.title && b.title) {
                      return a.title.localeCompare(b.title);
                    }
                  })
                  .map((queue) => (
                    <tr key={queue.id}>
                      <td>
                        <Button
                          minimal
                          onClick={() => setSelectedQueue(queue)}
                          active={selectedQueue?.id === queue.id}
                          disabled={caseListLoading}
                        >
                          {queue.title}{' '}
                          <Tag
                            intent={
                              Number(
                                queueCaseCounts?.find((elem: any) => elem.id === queue.id)
                                  ?.open_cases_in_queue,
                              ) > 0 && !caseListLoading
                                ? 'primary'
                                : 'none'
                            }
                            minimal
                            round
                          >
                            {queueCaseCounts
                              ? queueCaseCounts?.find((elem: any) => elem.id === queue.id)
                                  ?.open_cases_in_queue
                              : 0}{' '}
                          </Tag>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </HTMLTable>
          </Card>
        </Col>

        {/* Right Column (20 columns) */}
        <Col span={19}>
          <Card elevation={Elevation.ONE}>
            <h2>{selectedQueue?.title}</h2>
            <p>{getDurationSinceLastRefresh()}</p>
            <HTMLTable className={'case-table'} striped style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Impact</th>
                  <th>Case ID</th>
                  <th>Title</th>
                  <th>Stage</th>
                  <th>Description</th>
                  <th>Age</th>
                  <th>Next SLA Breach</th>
                  <th>
                    <ColumnFilter
                      label="Assigned To"
                      renderOptions={renderAssignedToFilterOptions}
                      hasFilters={assignedUsersFilter.selectedValues.length > 0}
                    />
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {caseListLoading ? (
                  <tr>
                    <Spinner />
                  </tr>
                ) : (
                  sortDbRecordsByDate(casesInQueue, 'desc')
                    ?.filter(applyFilters)
                    ?.map((caseItem) => (
                      <tr key={caseItem.id}>
                        <td>
                          <Tag intent={getCaseIntent(caseItem)}>
                            {getProperty(caseItem, 'Impact')}
                          </Tag>
                        </td>
                        <td>{caseItem.recordNumber}</td>
                        <td>{caseItem.title}</td>
                        <td>{caseItem.stage?.name}</td>
                        <td>{getProperty(caseItem, 'Description')}</td>
                        <td>{calculateAgeSince(caseItem?.createdAt!)}</td>
                        <td>
                          <Tag intent={isSLABreached(caseItem?.createdAt!) ? 'danger' : 'none'}>
                            {getCaseSLABreach(caseItem?.createdAt!)}
                          </Tag>
                        </td>
                        <td>{getProperty(caseItem, 'R_AssignedUserTitle')}</td>
                        <td>
                          {<Button rightIcon="eye-open" onClick={() => handleViewCase(caseItem)} />}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </HTMLTable>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(AgentCaseDashboard);
