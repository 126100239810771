import { PlusOutlined, ZoomInOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Checkbox, Col, Divider, Empty, Row, Spin } from 'antd';
import { FC, useContext } from 'react';
import { DataSetBuilderContext } from '../..';
import RecordList from '../../../../../../../core/records/components/RecordList';
import { ADD_TO_SELECTED_RECORDS, REMOVE_FROM_SELECTED_RECORDS } from '../../store/constants';
interface Props {}

const DataSetSearchResults: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(DataSetBuilderContext);

  const isRecordSelected = (record: DbRecordEntityTransform) => {
    return state.selectedRecords.find((rec: DbRecordEntityTransform) => rec.id === record.id);
  };

  const isRecordAddedToDataSet = (record: DbRecordEntityTransform) => {
    return state.dataSetRecords.find((rec: DbRecordEntityTransform) => rec.id === record.id);
  };

  const addOrRemoveRecordFromSelectedList = (record: DbRecordEntityTransform) => {
    if (!isRecordAddedToDataSet(record)) {
      if (isRecordSelected(record)) {
        dispatch({ type: REMOVE_FROM_SELECTED_RECORDS, payload: record });
      } else {
        dispatch({ type: ADD_TO_SELECTED_RECORDS, payload: record });
      }
    }
  };

  return (
    <>
    {
      !state.elasticQuery ?
      <Row style={{height: '64vh', paddingTop: '22vh', opacity:0.3 }}>
        <Col span={24} style={{textAlign:'center'}}>
          <span>Add search filters to search for records.</span>
        </Col>
      </Row>
      : <></>
    }

      {state.isRecordSearching  ? (
        <Row style={{ height: '60vh', paddingTop: '22vh', display: state.elasticQuery ? 'block':'none', }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin size="large" style={{ marginBottom: 10 }} />
            <br />
            <span>Searching ...</span>
          </Col>
        </Row>
      ) : (
        <>
          <Row
            style={{
              display: state.elasticQuery ? 'block':'none',
              height: '60vh',
              overflowY: 'scroll',
              opacity: state.isRecordSearching ? 0.2 : 1,
            }}
          >
            <Col span={24} style={{paddingRight:15}}>
              {state.searchResults.length > 0  ? (
                <RecordList
                  multipleSelect
                  selectedRecords={state.selectedRecords}
                  disabledRecords={state.dataSetRecords}
                  allRecords={state.searchResults}
                  onRecordSelect={addOrRemoveRecordFromSelectedList}
                  recordDisabledLabel="Added"
                />
              ) : (
                <Empty style={{ marginTop: '20vh', display: !state.elasticQuery ? 'none':'block' }} description="No search results to show" />
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DataSetSearchResults;
