import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import CaseChangeQueueDialog from './CaseChangeQueueDialog';
import HeaderDetailView from '../../../../shared/components/HeaderDetailView';
import RecordStageStandardPipeline from '../../../../shared/components/RecordStageStandardPipeline';
import SchemaActionPageHeader from '../../../../shared/components/SchemaActions/SchemaActionPageHeader';
import MyCasesViewV2 from '../MyCasesViewV2';

const { CRM_MODULE, FIELD_SERVICE_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, ADDRESS, WORK_ORDER, ORDER } = SchemaModuleEntityTypeEnums;

interface Props {
  isQuickView?: boolean;
}

const CaseDetailView: FC<Props> = (props: Props) => {
  const { record, schema, pipeline } = useContext(DetailViewContext);
  const { isQuickView } = props;
  const [selectedMenuItem, setSelectedMenuItem] = useState<'Record Details' | 'Case View'>(
    'Record Details',
  );

  const getCaseIntent = (caseItem: DbRecordEntityTransform) => {
    switch (getProperty(caseItem, 'Impact')) {
      case 'HIGH':
        return 'danger';
      case 'MEDIUM':
        return 'warning';
      case 'LOW':
        return 'success';
      default:
        return 'none';
    }
  };

  const [isChangeQueueDialogOpen, setIsChangeQueueDialogOpen] = useState(false);

  return (
    <>
      <CaseChangeQueueDialog
        record={record}
        openDialog={isChangeQueueDialogOpen}
        onClose={() => setIsChangeQueueDialogOpen(false)}
      />

      <Row>
        {/* Header */}
        <Col span={24}>
          <HeaderDetailView
            isQuickView={isQuickView}
            record={record!}
            extra={[
              // Non-Sequential Pipeline
              !pipeline?.isSequential && record && schema && (
                <RecordStageStandardPipeline record={record} pipeline={pipeline} />
              ),
              record && schema && (
                <SchemaActionPageHeader
                  moduleName={schema.moduleName}
                  entityName={schema.entityName}
                  record={record}
                  launcherType="INLINE"
                />
              ),
              <Button outlined intent="primary" onClick={() => setIsChangeQueueDialogOpen(true)}>
                Change Queue
              </Button>,
            ]}
          />
        </Col>

        {/* Case View */}
        <Col span={24} style={{ padding: 15 }}>
          <MyCasesViewV2 defaultCase={record} />
        </Col>
      </Row>
    </>
  );
};

export default CaseDetailView;
