import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Badge, Card, Col, Divider, Row, Spin, Tabs, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { FC, useContext, useEffect, useState } from 'react';

import { DataSetWorkContext } from '..';
import { toSentenceCase } from '../../../../../../shared/utilities/stringHelpers';
import {
  SET_ACTIVE_STATUS_TAB,
  SET_ALL_RECORD_STATUSES,
  SET_DATASET_SEARCH_TERM,
} from '../store/constants';
import { DataSetApprovalStatusesENUM, DataSetTaskStatusesENUM } from '../types';
import DataSetSearchResults from './SearchResults';

interface Props {
  loading?: boolean;
}

const RecordSelectPanel: FC<Props> = (props: Props) => {
  const { loading } = props;
  const { state, dispatch } = useContext(DataSetWorkContext);

  // When DataSet record is available, construct statuses using the DS_Status (DataSet status) column.
  useEffect(() => {
    if (state.dataSetRecord && state.allRecordStatuses.length === 0) {
      const dataSetType = getProperty(state.dataSetRecord, 'Process');

      if (dataSetType) {
        switch (dataSetType) {
          case 'TASK':
            dispatch({
              type: SET_ALL_RECORD_STATUSES,
              payload: Object.keys(DataSetTaskStatusesENUM).map((key: any) => key),
            });
            dispatch({
              type: SET_ACTIVE_STATUS_TAB,
              payload: Object.keys(DataSetTaskStatusesENUM)[0],
            });
            return;
          case 'APPROVAL':
            dispatch({
              type: SET_ALL_RECORD_STATUSES,
              payload: Object.keys(DataSetApprovalStatusesENUM).map((key: any) => key),
            });
            dispatch({
              type: SET_ACTIVE_STATUS_TAB,
              payload: Object.keys(DataSetApprovalStatusesENUM)[0],
            });
            return;
          default:
            return;
        }
      }
    }
  }, [state.dataSetRecord]);

  const getRecordCountForStatus = (status: string) => {
    const records = state.associatedRecords?.filter(
      (rec: DbRecordEntityTransform) =>
        (status === 'TO_DO' && !getProperty(rec, 'DS_Status')) ||
        getProperty(rec, 'DS_Status') === status,
    );

    return records.length;
  };

  return (
    <Card
      size="small"
      className="DataSetBuilderPanelRow"
      title={
        <Typography.Title level={5} style={{ margin: 0 }}>
          DataSet Records
        </Typography.Title>
      }
    >
      <Row>
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            centered
            activeKey={state.activeStatusTab}
            onChange={(e: any) => dispatch({ type: SET_ACTIVE_STATUS_TAB, payload: e })}
          >
            {state.allRecordStatuses?.length > 0 ? (
              state.allRecordStatuses?.map((status: any) => (
                <Tabs.TabPane
                  tab={
                    <>
                      <Badge
                        count={getRecordCountForStatus(status)}
                        showZero
                        style={{
                          backgroundColor:
                            getRecordCountForStatus(status) > 0 ? '#ddeeff' : '#f0f0f0',
                          color: 'black',
                          marginBottom: 2,
                          marginRight: 5,
                        }}
                      />
                      {toSentenceCase(status)}
                    </>
                  }
                  key={String(status)}
                />
              ))
            ) : (
              <></>
            )}
          </Tabs>
        </Col>
        <Col span={24}>
          <Search
            allowClear
            value={state.dataSetSearchTerm}
            onChange={(e: any) => {
              dispatch({ type: SET_DATASET_SEARCH_TERM, payload: e?.target.value });
            }}
            onSearch={(e: any) => {
              dispatch({ type: SET_DATASET_SEARCH_TERM, payload: e?.target.value });
            }}
            placeholder={`Search records in DataSet`}
            disabled={
              state.associatedRecords.length === 0 ||
              getRecordCountForStatus(state.activeStatusTab) === 0
            }
          />
        </Col>
        <Col span={24}>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Col>
      </Row>
      {state.isSearchingDataSetAssociations ? (
        <Row style={{ height: '97vh', paddingTop: '12vh' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin style={{marginBottom:20}} /><br/><span>Loading DataSet records...</span>
          </Col>
        </Row>
      ) : (
        <DataSetSearchResults statusFilter={state.activeStatusTab} />
      )}
    </Card>
  );
};

export default RecordSelectPanel;
