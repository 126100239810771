import React, { FC } from 'react';
import { Col, Row } from 'antd';
import ReactApexChart from 'react-apexcharts';

const CaseManagementDashboardChart: FC = () => {
  const options: any = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: '#D5DBF4',
          startAngle: -135,
          endAngle: 140,
          strokeWidth: '50%',
        },
        hollow: {
          margin: 15,
          // size: '55%',
        },

        dataLabels: {
          showOn: 'always',
          name: {
            offsetY: 0,
            show: false,
            color: '#888',
            fontSize: '13px',
          },
          value: {
            offsetY: 10,
            color: '#000',
            fontSize: '1.4em',
            fontWeight: 600,
            show: true,
          },
        },
      },
    },
    fill: {
      colors: ['#123EE0'],
    },

    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  };

  return (
    <Col xs={24} md={12} lg={8} style={{ height: 500, padding: 8 }}>
      <Row
        align="top"
        style={{
          padding: 8,
          height: '100%',
          borderRadius: 8,
          background: '#F7F7F7',
          border: '1px solid #EBEBEB',
          alignContent: 'flex-start',
        }}
      >
        <Col span={24} style={{ textAlign: 'center' }}>
          <h3>SLA Score</h3>
        </Col>
        <Col span={24} style={{ paddingTop: 50 }}>
          <ReactApexChart options={options} series={[53.01]} type="radialBar" height={'350px'} />
        </Col>
      </Row>
    </Col>
  );
};

export default CaseManagementDashboardChart;
