import { OrganizationEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/organization.entity';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaColumnValidatorEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Col, Divider, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { hasAnyRoles, isSystemAdmin } from '../../../../../shared/permissions/rbacRules';
import { errorNotification } from '../../../../../shared/system/notifications/store/reducers';
import { checkRecordIsLocked } from '../../../../../shared/utilities/recordHelpers';
import {
  IUpdateRelatedRecordAssociation,
  updateRecordAssociationRequest,
} from '../../../../recordsAssociations/store/actions';
import { DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST } from '../../../../recordsAssociations/store/constants';
import { IRecordAssociationsReducer } from '../../../../recordsAssociations/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  getSingleSchemaAction,
  ISchemaByModuleAndEntity,
} from '../../../../schemas/store/actions';
import {
  bulkUpdateRecordsRequest,
  createRecordsRequest,
  IBulkUpdateRecords,
  ICreateRecords,
  ISearchRecords,
  IUpdateRecordById,
  searchRecordsRequest,
  updateRecordByIdRequest,
} from '../../../store/actions';
import {
  BULK_UPDATE_DB_RECORDS_REQUEST,
  CREATE_DB_RECORD_REQUEST,
  UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '../../../store/constants';
import { IRecordReducer } from '../../../store/reducer';
import { TableReducer } from '../../DynamicTable/store/reducer';
import renderFormField, { FormField, InputChangeParams } from '../FormFields';
import { closeRecordForm, updateFormInput, updateRecordFormState } from '../store/actions';
import { IFormReducer } from '../store/reducer';
import { Button, Intent } from '@blueprintjs/core';
import '../styles.scss';

const { Option } = Select;

interface FormSectionEntity {
  id?: string | null;
  organization?: OrganizationEntity;
  schema?: SchemaEntity;
  schemaColumns?: SchemaColumnEntity[];
  name?: string;
  description?: string;
  position?: number;
  columns?: number;
  associations?: {
    recordId: string;
    title: string;
    recordNumber: string;
    schemaAssociationId: string;
  }[];
}

export interface Props {
  bulkUpdateRecords: (params: IBulkUpdateRecords, cb?: any) => void;
  closeForm: any;
  createRecord: (params: ICreateRecords, cb?: any) => void;
  formDirection?: 'vertical' | 'horizontal';
  formUUID: string;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  getSchemaAction: (params: { schemaActionId: string }, cb: any) => void;
  hasColumnMappings?: boolean;
  hiddenFormFields?: Array<string>;
  hideCancelButton?: boolean;
  hideStageFormField?: boolean;
  initializeCb?: any;
  isBatchCreate?: boolean;
  isNextDisabled?: any;
  isCreateRecord?: boolean;
  notifyError: any;
  onCancelEvent?: Function;
  onCloseEvent?: Function;
  onSubmitEvent?: Function;
  record?: DbRecordEntityTransform;
  recordAssociationReducer: IRecordAssociationsReducer;
  recordFormReducer: any;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  relatedEntityName?: string;
  schemaReducer: any;
  searchRecords: Function;
  showFormActions?: boolean;
  showOnlyRequired?: boolean;
  submitButtonTitle?: string;
  updateFormProperties: any;
  updateFormState: any;
  updateRecord: (params: IUpdateRecordById, cb?: any) => void;
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) => void;
  userReducer: any;
}

interface State {
  isCreatingOrUpdating: boolean;
  formColumns: number | undefined;
  isLoadingSchemaAction: boolean;
  customRecordNumber: string;
  error:
    | {
        intent: Intent;
        message: string;
      }
    | undefined;
}

// This is a legacy form component, built before the schema actions were introduced.
// We use this form only for components that don't have schema actions, which means
// they are relying on legacy method, constructing forms via schema columns.
//
// New form component that we use is CoreForm, which is built on top of schema actions.
class CoreLegacyEmbeddedForm extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isCreatingOrUpdating: false,
      formColumns: undefined,
      isLoadingSchemaAction: false,
      error: undefined,
      customRecordNumber: '',
    };
  }

  componentDidMount = () => {
    if (this.props.initializeCb) {
      this.props.initializeCb();
    }
  };

  checkIfCurrentFormIsInReducer = (): boolean => {
    const { recordFormReducer, formUUID } = this.props;
    return recordFormReducer.formUUID == formUUID;
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    // Form is opened
    if (
      !prevProps.recordFormReducer?.showFormModal &&
      prevProps?.recordFormReducer.showFormModal &&
      this.checkIfCurrentFormIsInReducer()
    ) {
      this.setState({ error: undefined });
    }

    if (prevProps.recordFormReducer.formUUID !== this.props.recordFormReducer.formUUID) {
      this.initializeDefaultValues();
    }
  }

  componentWillUnmount() {
    this.props.closeForm();
  }

  initializeDefaultValues() {
    const { recordFormReducer, updateFormState } = this.props;
    const { isCreateReq, sections, selected, recordType } = recordFormReducer;

    if (sections && sections[0]) {
      for (const section of sections) {
        if (section.schema && section.schema.columns) {
          let columns = section.schema.columns;
          if (section.schema.types.length > 0) {
            // filter the columns by the record schemaTypeId if updating
            if (selected && selected.type) {
              const schemaType = section.schema?.types?.find(
                (type: { name: any }) => type?.name === selected.type,
              );
              columns = columns.filter(
                (col: SchemaColumnEntity) =>
                  col.schemaTypeId === schemaType?.id || !col.schemaTypeId,
              );
            }

            // filter by the selected schemaTypeId
            const schemaType = section.schema?.types?.find(
              (type: { name: any }) => type.name === recordType,
            );
            columns = columns.filter(
              (col: SchemaColumnEntity) => col.schemaTypeId === schemaType?.id || !col.schemaTypeId,
            );
          }
          // if the user is creating a new record set the default values
          if (isCreateReq) {
            let properties = {};

            for (const col of columns) {
              if (col.defaultValue) {
                properties = Object.assign({}, properties, {
                  [col.name]: col.defaultValue,
                });
              }
            }

            const modified = recordFormReducer.modified?.find(
              (elem: { schemaId: string }) => elem.schemaId === section?.schema?.id,
            );

            if (modified) {
              updateFormState({
                modified: [
                  ...recordFormReducer.modified.filter(
                    (elem: { schemaId: string }) => elem.schemaId !== section?.schema?.id,
                  ),
                  {
                    ...modified,
                    properties: {
                      ...properties,
                      ...modified.properties,
                    },
                  },
                ],
              });
            } else {
              updateFormState({
                modified: [
                  ...recordFormReducer.modified,
                  {
                    schemaId:
                      section.schema && section.schema.id
                        ? section.schema.id.toString()
                        : undefined,
                    properties: {
                      ...properties,
                    },
                  },
                ],
              });
            }
          }
        }
      }
    }
  }

  handleInputChange = (params: InputChangeParams) => {
    const { recordFormReducer, updateFormProperties } = this.props;

    const { selected, additionalInputChangeHandler, isBulkUpdateReq } = recordFormReducer;
    // add contact email address to the lead
    const leadFormFields = recordFormReducer.modified?.find(
      (elem: any) => elem.entity === 'CrmModule:Lead',
    );
    const contactFormFields = recordFormReducer.modified?.find(
      (elem: any) => elem.entity === 'CrmModule:Contact',
    );

    // ODN-1792 change null or '' value to undefined to prevent the bulk field clearing
    if (isBulkUpdateReq) {
      if (!params.value) {
        params.value = undefined;
      }
    }

    // Add contact email address to the lead form
    if (!!leadFormFields && !!contactFormFields) {
      if (params.id === `${contactFormFields.schemaId}_EmailAddress`) {
        updateFormProperties({
          targetId: `${leadFormFields.schemaId}_EmailAddress`,
          entity: leadFormFields.entity,
          targetValue: params.value,
          record: selected,
        });
      }
    }

    // exec additional injected handler
    if (additionalInputChangeHandler) {
      additionalInputChangeHandler(this.props, params);
    }

    if (this.props.isCreateRecord) {
      setTimeout(() => this.handleFormValidation(), 10);
    }

    updateFormProperties({
      targetId: params.id,
      entity: params.entity,
      targetValue: params.value,
      record: selected,
      association: params.association,
    });
  };

  handleFormValidation = async () => {
    const { isNextDisabled } = this.props;
    try {
      await this.formRef.current?.validateFields();
      const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
      const hasErrors = formErrors.filter(({ errors }) => errors.length).length > 0;

      if (isNextDisabled) {
        if (!hasErrors) {
          isNextDisabled(false);
        } else {
          isNextDisabled(true);
        }
      }
    } catch (e) {
      if (isNextDisabled) {
        isNextDisabled(true);
      }
      console.error(e);
    }
  };

  handleCancel = () => {
    const { closeForm, onCancelEvent, onCloseEvent } = this.props;
    onCancelEvent && onCancelEvent();
    onCloseEvent && onCloseEvent();
    closeForm();
  };

  closeTheForm = () => {
    const { closeForm, onCloseEvent } = this.props;
    onCloseEvent && onCloseEvent();
    closeForm();
  };

  handleSubmit = async () => {
    const {
      createRecord,
      updateRecord,
      bulkUpdateRecords,
      updateRecordAssociation,
      notifyError,
      recordReducer,
      recordTableReducer,
      onSubmitEvent,
      userReducer,
      recordFormReducer,
    } = this.props;

    const { selected, schema, isUpdateReq, isCreateReq, isBulkUpdateReq } = recordFormReducer;

    // Prevent editing if record is locked
    if (
      checkRecordIsLocked(selected) &&
      !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin')
    ) {
      notifyError({ message: 'the record is locked' });
      this.handleCancel();
      return;
    }

    try {
      if (!!this.formRef.current) {
        // Field Validation
        await this.formRef.current.validateFields();
        if (recordFormReducer.modified.length < 1) {
          return notifyError({
            message: 'no modified form values',
            validation: null,
            data: null,
          });
        } else {
          this.setState({ isCreatingOrUpdating: true });

          // 1. Update Request
          if (isUpdateReq) {
            // Update with Column Mappings
            if (
              (this.props.hasColumnMappings || recordFormReducer.hasColumnMappings) &&
              selected.dbRecordAssociation?.id &&
              recordFormReducer.relatedEntityName
            ) {
              return updateRecordAssociation(
                {
                  relatedEntityName: recordFormReducer.relatedEntityName,
                  parentSchema: schema,
                  recordId: selected.id,
                  dbRecordAssociationId: selected.dbRecordAssociation.id,
                  createUpdate: recordFormReducer.modified[0],
                },
                (res: DbRecordEntityTransform) => {
                  this.setState({ isCreatingOrUpdating: false });
                  if (res && onSubmitEvent) {
                    onSubmitEvent({
                      event: DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST,
                      results: res,
                    });
                    this.closeTheForm();
                  }
                },
              );
            }
            // Update without Column Mappings
            else {
              return updateRecord(
                {
                  schema: schema,
                  recordId: selected.id,
                  createUpdate: recordFormReducer.modified[0],
                },
                (res: DbRecordEntityTransform) => {
                  this.setState({ isCreatingOrUpdating: false });
                  if (res && onSubmitEvent) {
                    onSubmitEvent({
                      event: UPDATE_DB_RECORD_BY_ID_REQUEST,
                      results: res,
                    });
                    this.closeTheForm();
                  }
                },
              );
            }
          }
          // 2. Create Request
          else if (isCreateReq) {
            let modified: any[] = Object.assign(recordFormReducer.modified);
            if (modified.length > 0) {
              modified = modified.map((modified: any) => ({
                ...modified,
                type: recordFormReducer.recordType,
              }));
            }
            if (this.state.customRecordNumber) {
              modified[0].recordNumber = this.state.customRecordNumber;
            }

            return createRecord(
              {
                schema: recordFormReducer.schema,
                createUpdate: [...recordFormReducer.payload, ...modified],
              },
              (res: DbRecordEntityTransform) => {
                this.setState({ isCreatingOrUpdating: false });
                if (res && onSubmitEvent) {
                  onSubmitEvent({
                    event: CREATE_DB_RECORD_REQUEST,
                    results: res,
                  });
                  this.closeTheForm();
                }
              },
            );
          }
          // 3. Bulk Update Request
          else if (isBulkUpdateReq) {
            this.setState({ isCreatingOrUpdating: true });
            const bulkUpdateParams: IBulkUpdateRecords = {
              schema,
              createUpdate: recordFormReducer.modified[0],
            };
            // ODN-1988 bulk update selected records
            if (recordTableReducer?.selectedItems?.length > 0) {
              bulkUpdateParams.recordIds = recordTableReducer.selectedItems;
            }
            // bulk update records in current search
            else {
              const searchQuery = recordReducer.searchQuery[schema.id];
              const isNotEmptyQuery =
                !!searchQuery?.terms ||
                !!searchQuery?.boolean?.must?.length ||
                !!searchQuery?.boolean?.must_not?.length ||
                !!searchQuery?.boolean?.should?.length ||
                !!searchQuery?.boolean?.filter?.length;
              if (isNotEmptyQuery) {
                bulkUpdateParams.searchQuery = {
                  schemas: schema.id,
                  fields: searchQuery?.fields,
                  terms: searchQuery?.terms,
                  boolean: searchQuery?.boolean,
                  sort: searchQuery?.sort,
                  pageable: {
                    page: 1,
                  },
                };
              }
            }
            if (bulkUpdateParams?.searchQuery || bulkUpdateParams?.recordIds) {
              bulkUpdateRecords(bulkUpdateParams, (res: any) => {
                this.setState({ isCreatingOrUpdating: false });
                if (res && onSubmitEvent) {
                  onSubmitEvent({
                    event: BULK_UPDATE_DB_RECORDS_REQUEST,
                    results: res,
                  });
                  this.closeTheForm();
                }
              });
            } else {
              this.setState({ isCreatingOrUpdating: false });
              notifyError({
                message: 'searchQuery or selectedItems are not defined, bulk update is not allowed',
              });
              this.closeTheForm();
            }
          }
        }
      }
    } catch (e: any) {
      this.setState({ isCreatingOrUpdating: false });
      console.error('Debug: Form error', e);

      const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
      const errors = formErrors.filter(({ errors }) => errors.length);
      if (errors && errors.length > 0) {
        notifyError({
          message: `'${errors[0]['errors'][0]}'`,
          validation: errors[0]['errors'],
          data: null,
        });
      }
    }
  };

  private setInitialValueFromModified(col: SchemaColumnEntity) {
    const { recordFormReducer } = this.props;
    if (!!recordFormReducer.selected && col) {
      const { properties } = recordFormReducer.selected;
      return properties[col.name];
    } else {
      const obj = recordFormReducer.modified.find(
        (elem: any) => recordFormReducer.schema.id === elem.schemaId && elem.properties?.[col.name],
      );
      if (obj) {
        return obj.properties[col.name];
      }
      return null;
    }
  }

  // How we used to build forms before schema actions
  constructLegacyFormFields = (section: FormSectionEntity, colSpan: 12 | 24) => {
    const { recordFormReducer, userReducer } = this.props;
    const {
      disabledFields,
      visibleFieldOverride,
      customValidations,
      selected,
      recordType,
      isBulkUpdateReq,
    } = recordFormReducer;

    if (section.schema && section.schema.columns) {
      let columns = section.schema.columns;
      if (section.schema.types.length > 0) {
        // filter the columns by the record schemaTypeId if updating
        if (selected && selected.type) {
          const schemaType = section.schema?.types?.find(
            (type: any) => type.name === selected.type,
          );
          columns = columns.filter(
            (col: SchemaColumnEntity) => col.schemaTypeId === schemaType?.id || !col.schemaTypeId,
          );
        }

        // filter by the selected schemaTypeId
        const schemaType = section.schema?.types?.find((type: any) => type.name === recordType);
        columns = columns.filter(
          (col: SchemaColumnEntity) => col.schemaTypeId === schemaType?.id || !col.schemaTypeId,
        );
      }

      // Sort and Construct Form Fields
      return columns
        .sort((colA: SchemaColumnEntity, colB: SchemaColumnEntity) => colA.position - colB.position)
        .map((col: SchemaColumnEntity) => {
          // Bulk update is allowed if the isBulkUpdatable flag is defined and field is not unique
          if (isBulkUpdateReq) {
            if (
              !col.isBulkUpdatable ||
              col.validators?.some(
                (validator) => validator.type === SchemaColumnValidatorEnums.UNIQUE,
              )
            ) {
              return;
            }
          }

          const isRequired = col.validators?.find(
            (validation: any) => validation.type === 'REQUIRED',
          );

          const initialValue = this.setInitialValueFromModified(col);
          let isHidden = col.isHidden ? col.isHidden : false;
          //  Only show these fields if override
          if (visibleFieldOverride && visibleFieldOverride.length > 0) {
            isHidden = true;
            isHidden = !visibleFieldOverride.includes(col.name);
          }

          // Override the hidden field if user is system admin
          if (isSystemAdmin(userReducer)) {
            isHidden = false;
          }

          const linkedSchemaAssociation = section?.schema?.associations?.find(
            (s: SchemaAssociationEntity) => s.id === col.linkedSchemaAssociationId,
          );
          const linkedSchemaTypesConstraint = linkedSchemaAssociation?.schemaTypesConstraints?.find(
            (c: any) => c.id === col.linkedSchemaTypesConstraintId,
          );

          const field: FormField = {
            id: col.id ? col.id.toString() : col.name,
            schemaId:
              section.schema && section.schema.id ? section.schema.id.toString() : undefined,
            entity:
              section.schema && section.schema.moduleName
                ? `${section.schema.moduleName}:${section.schema.entityName}`
                : undefined,
            type: col.type,
            isHidden,
            name: col.name,
            label: col.label || col.name,
            description: col.description,
            defaultValue: !initialValue ? col.defaultValue : null,
            initialValue: !selected ? col.defaultValue ?? initialValue : initialValue,
            options: col.options,
            validators: col.validators || [],
            customValidation: customValidations ? customValidations[col.name] : undefined,
            isDisabled: disabledFields ? disabledFields.includes(col.name) : false,
            handleInputChange: this.handleInputChange,
            linkedSchemaAssociation,
            linkedSchemaTypesConstraint,
            selected: recordFormReducer.selected,
          };

          if (renderFormField(field)) {
            // If prop showOnlyRequired is passed, return only fields with REQUIRED validator.
            if (this.props.showOnlyRequired) {
              if (isRequired) {
                return <Col span={colSpan}>{renderFormField(field)}</Col>;
              }
            } else if (!this.props.showOnlyRequired && !field.isHidden) {
              return <Col span={colSpan}>{renderFormField(field)}</Col>;
            }
          }
        });
    }
  };

  renderTitleField(section: FormSectionEntity) {
    const { recordFormReducer } = this.props;
    const { selected, isCreateReq, isBulkUpdateReq } = recordFormReducer;

    if (section.schema?.hasTitle && !isBulkUpdateReq) {
      return (
        <Form.Item
          key="title"
          name="title"
          label="Record title"
          labelCol={{ span: 24 }}
          initialValue={selected ? selected.title : ''}
          rules={[{ required: isCreateReq && section.schema?.isTitleUnique }]}
        >
          <Input
            type="text"
            defaultValue={selected ? selected.title : ''}
            placeholder="add record title"
            onChange={(e) =>
              this.handleInputChange({
                id: `${section.schema?.id}#title`,
                entity: 'Record',
                value: e.target.value,
              })
            }
          />
        </Form.Item>
      );
    }
  }

  renderSchemaTypeField(section: FormSectionEntity) {
    const { recordFormReducer } = this.props;
    const { schema, selected, recordType, isCloning, isBulkUpdateReq, hideRecordTypeField } =
      recordFormReducer;

    const hasTypes = schema?.types?.length > 0;
    const hasDefaultType = schema?.types?.some(
      (schema: SchemaTypeEntity) => schema.name === 'DEFAULT',
    );

    const getInitialValueForSchemaType = () => {
      if (selected?.type) {
        return selected?.type;
      } else if (recordType) {
        return recordType;
      } else if (hasDefaultType) {
        return 'DEFAULT';
      } else {
        return '';
      }
    };

    if (schema && hasTypes && !isBulkUpdateReq && !hideRecordTypeField) {
      return (
        <Form.Item
          key="recordType"
          name="recordType"
          label="Record Type"
          labelCol={{ span: 24 }}
          initialValue={getInitialValueForSchemaType()}
          rules={[{ required: true }]}
        >
          <Select
            key={'recordType'}
            disabled={selected?.type && !isCloning}
            defaultValue={getInitialValueForSchemaType()}
            style={{ width: '100%' }}
            onChange={(val) => {
              this.handleInputChange({
                id: `${section.schema?.id}#recordType`,
                entity: 'Record',
                value: val,
              });
            }}
          >
            <Option key={selected ? selected.type : 1} value={selected ? selected.type : ''}>
              {selected ? selected.type : 'Select Type'}
            </Option>
            {schema.types &&
              schema.types.map((elem: SchemaTypeEntity) => (
                <Option key={elem?.id?.toString()} value={elem.name}>
                  {elem.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      );
    }
  }

  renderRecordNumberField = (definition: any) => {
    const { record, recordFormReducer } = this.props;
    const { isBulkUpdateReq, isUpdateReq } = recordFormReducer;

    if (definition && !isBulkUpdateReq && !isUpdateReq) {
      return (
        <Form.Item
          key="recordNumber"
          name="recordNumber"
          label="Record number"
          labelCol={{ span: 24 }}
          initialValue={record ? record.number : ''}
          rules={[{ required: true }]}
        >
          <Input
            type="text"
            defaultValue={record ? record.recordNumber : ''}
            placeholder="Add custom record number"
            onChange={(e) => this.setState({ customRecordNumber: e.target.value })}
          />
        </Form.Item>
      );
    } else {
      return <></>;
    }
  };

  renderSelectInputForAssociations(section: any) {
    return (
      section.associations && (
        <Form.Item
          key="related"
          name="related"
          label="related"
          labelCol={{ span: 24 }}
          rules={[{ required: false }]}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            defaultValue={section.associations.map((elem: any) => elem.title || elem.recordNumber)}
            placeholder="Please select"
          >
            {section.associations.map((elem: any) => (
              <Option key={elem.recordId} value={elem.recordId}>
                {elem.title || elem.recordNumber}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )
    );
  }

  renderRecordTitleField(schema: SchemaEntity | undefined) {
    const { record, recordFormReducer } = this.props;
    const { isCreateReq, isBulkUpdateReq } = recordFormReducer;

    if (schema?.hasTitle && !isBulkUpdateReq) {
      return (
        <Form.Item
          key="title"
          name="title"
          label="Record title"
          labelCol={{ span: 24 }}
          initialValue={record ? record.title : ''}
          rules={[{ required: isCreateReq && schema?.isTitleRequired }]}
        >
          <Input
            disabled={!isCreateReq}
            type="text"
            defaultValue={record ? record.title : ''}
            placeholder="Add record title"
            onChange={(e) =>
              this.handleInputChange({
                id: `${schema?.id}#title`,
                entity: 'Record',
                value: e.target.value,
              })
            }
          />
        </Form.Item>
      );
    } else {
      return <></>;
    }
  }

  render() {
    const { recordFormReducer, showFormActions } = this.props;
    const hideRecordFormFields = recordFormReducer?.hideRecordFormFields || undefined;
    const schema = recordFormReducer?.schema || undefined;

    return schema ? (
      <div>
        <Form
          style={{ width: '100%' }}
          ref={this.formRef}
          key={schema?.id ? schema?.id.toString() : schema?.name}
          name={schema?.id ? schema?.id.toString() : schema?.name}
          className="dynamic-form embedded-form"
          initialValues={{ remember: true }}
        >
          {/* Form Body */}
          <div style={{ padding: 10 }}>
            {!hideRecordFormFields ? (
              <Row>
                <Col span={24}>
                  {this.renderRecordTitleField(schema)}
                  {this.renderSchemaTypeField({
                    name: schema?.name,
                    schema: schema,
                    associations: [],
                  })}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row gutter={16}>
              {this.constructLegacyFormFields(
                { name: schema?.name, schema: schema, associations: [] },
                this.props.formDirection! === 'vertical' || isMobile || this.state.formColumns === 1
                  ? 24
                  : 12,
              )}
            </Row>
          </div>
        </Form>
        {showFormActions ? (
          <Row style={{ marginTop: 10, marginRight: 10, marginBottom: 5 }}>
            <Divider style={{ marginTop: 0, marginBottom: 12 }} />
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                disabled={this.state.isCreatingOrUpdating}
                onClick={this.handleCancel}
                style={{
                  marginRight: 10,
                  display: this.props.hideCancelButton ? 'none' : 'inline-block',
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.isCreatingOrUpdating}
                loading={this.state.isCreatingOrUpdating}
                intent="primary"
                onClick={this.handleSubmit}
              >
                {this.props.submitButtonTitle || 'Submit'}
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <></>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  pipelineReducer: state.pipelineReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchemaAction: (params: { schemaActionId: string }, cb: any) =>
    dispatch(getSingleSchemaAction(params, cb)),
  closeForm: () => dispatch(closeRecordForm()),
  updateFormProperties: (value: any) => dispatch(updateFormInput(value)),
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) =>
    dispatch(updateRecordAssociationRequest(params, cb)),
  bulkUpdateRecords: (params: IBulkUpdateRecords, cb?: any) =>
    dispatch(bulkUpdateRecordsRequest(params, cb)),
  notifyError: (params: any) => dispatch(errorNotification(params)),
  updateFormState: (params: IFormReducer) => dispatch(updateRecordFormState(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(CoreLegacyEmbeddedForm);
