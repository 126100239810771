import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { httpDelete, httpGet, httpPost, httpPut } from '../../../shared/http/requests';
import { DISPLAY_MESSAGE } from '../../../shared/system/messages/store/reducers';
import { ERROR_NOTIFICATION } from '../../../shared/system/notifications/store/reducers';
import history from '../../../shared/utilities/browserHistory';
import { USER_LOGOUT_REQUEST } from '../../identity/store/constants';
import {
  CreateSchema,
  CreateSchemaType,
  DeleteSchemaType,
  ISchemaActionCreate,
  ISchemaActionsList,
  ISchemaActionUpdate,
  ISchemaById,
  ISchemaByModule,
  ISchemaByModuleAndEntity,
} from './actions';
import {
  ADD_SCHEMA_TYPE_TO_SHORTLIST_SCHEMA,
  BATCH_CREATE_SCHEMA_PERMISSIONS_ERROR,
  BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST,
  BATCH_CREATE_SCHEMA_PERMISSIONS_SUCCESS,
  BATCH_DELETE_SCHEMA_PERMISSIONS_ERROR,
  BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST,
  BATCH_DELETE_SCHEMA_PERMISSIONS_SUCCESS,
  CREATE_SCHEMA_ACTION_ERROR,
  CREATE_SCHEMA_ACTION_REQUEST,
  CREATE_SCHEMA_ACTION_SUCCESS,
  CREATE_SCHEMA_ERROR,
  CREATE_SCHEMA_REQUEST,
  CREATE_SCHEMA_SUCCESS,
  CREATE_SCHEMA_TYPE_ERROR,
  CREATE_SCHEMA_TYPE_REQUEST,
  CREATE_SCHEMA_TYPE_SUCCESS,
  DELETE_SCHEMA_ACTION_ERROR,
  DELETE_SCHEMA_ACTION_REQUEST,
  DELETE_SCHEMA_ACTION_SUCCESS,
  DELETE_SCHEMA_BY_ID_ERROR,
  DELETE_SCHEMA_BY_ID_REQUEST,
  DELETE_SCHEMA_BY_ID_SUCCESS,
  DELETE_SCHEMA_TYPE_REQUEST,
  DELETE_SCHEMA_TYPE_SUCCESS,
  GET_SCHEMA_ACTION_LIST_ERROR,
  GET_SCHEMA_ACTION_LIST_REQUEST,
  GET_SCHEMA_ACTION_LIST_SUCCESS,
  GET_SCHEMA_BY_ID_ERROR,
  GET_SCHEMA_BY_ID_REQUEST,
  GET_SCHEMA_BY_ID_SUCCESS,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_ERROR,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_SUCCESS,
  GET_SCHEMAS_BY_MODULE_ERROR,
  GET_SCHEMAS_BY_MODULE_REQUEST,
  GET_SCHEMAS_BY_MODULE_SUCCESS,
  GET_SINGLE_SCHEMA_ACTION_ERROR,
  GET_SINGLE_SCHEMA_ACTION_REQUEST,
  GET_SINGLE_SCHEMA_ACTION_SUCCESS,
  LIST_SCHEMAS_ERROR,
  LIST_SCHEMAS_REQUEST,
  LIST_SCHEMAS_SUCCESS,
  REFRESH_SCHEMA_SHORTLIST_REQUEST,
  REFRESH_SCHEMA_SHORTLIST_SUCCESS,
  REMOVE_SCHEMA_TYPE_FROM_SHORTLIST_SCHEMA,
  UPDATE_SCHEMA_ACTION_ERROR,
  UPDATE_SCHEMA_ACTION_REQUEST,
  UPDATE_SCHEMA_ACTION_SUCCESS,
  UPDATE_SCHEMA_BY_ID_ERROR,
  UPDATE_SCHEMA_BY_ID_REQUEST,
} from './constants';

function* listSchemas(action: { type: any; cb: any }): any {
  try {
    const res = yield call(async () => await httpGet(`SchemaModule/v1.0/schemas`));
    yield put({ type: LIST_SCHEMAS_SUCCESS, results: res.data });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    yield put({ type: LIST_SCHEMAS_ERROR });

    const error = e.response ? e.response.data : undefined;

    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* refreshSchemaShortlist(action: { type: any; params: { schemaIds: string[] } }): any {
  try {
    const { schemaIds } = action.params;

    const res = yield call(
      async () => await httpGet(`SchemaModule/v1.0/schemas/many?ids=${schemaIds.join(',')}`),
    );

    yield put({ type: REFRESH_SCHEMA_SHORTLIST_SUCCESS, results: res.data?.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getSchemasByModule(action: { type: any; take: any; params: ISchemaByModule }): any {
  try {
    const { moduleName } = action.params;
    const res = yield call(
      async () => await httpGet(`SchemaModule/v1.0/schemas/bymodule?moduleName=${moduleName}`),
    );
    yield put({ type: GET_SCHEMAS_BY_MODULE_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_SCHEMAS_BY_MODULE_ERROR });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getByModuleAndEntity(action: {
  type: any;
  take: any;
  params: ISchemaByModuleAndEntity;
  cb: any;
}): any {
  try {
    const { moduleName, entityName, withAssociations } = action.params;

    if (!moduleName || !entityName) {
      console.error('moduleName and entityName are required');
      return;
    }

    const schemaReducer = yield select((state: any) => state.schemaReducer);

    const entityToIdMap = schemaReducer.list.reduce((acc: any, schema: any) => {
      acc[`${schema.moduleName}:${schema.entityName}`] = schema.id;
      return acc;
    }, {});

    const schemaId = entityToIdMap[`${moduleName}:${entityName}`];
    if (schemaReducer.shortList[schemaId]) {
      yield call(action.cb, schemaReducer.shortList[schemaId]);
      return;
    }

    let path = `SchemaModule/v1.0/schemas/bymodule?moduleName=${moduleName}&entityName=${entityName}`;
    if (withAssociations) {
      path = path.concat(`&withAssociations=${withAssociations}`);
    }
    const res = yield call(async () => await httpGet(path));
    yield put({
      type: GET_SCHEMA_BY_MODULE_AND_ENTITY_SUCCESS,
      results: res.data.data,
    });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({
      type: GET_SCHEMA_BY_MODULE_AND_ENTITY_ERROR,
      params: action.params,
    });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getSchemaById(action: { type: any; take: any; params: ISchemaById; cb: any }): any {
  try {
    const { schemaId } = action.params;

    const schemaReducer = yield select((state: any) => state.schemaReducer);

    if (schemaReducer.shortList[schemaId]) {
      yield call(action.cb, schemaReducer.shortList[schemaId]);
      return;
    }

    const res = yield call(async () => await httpGet(`SchemaModule/v1.0/schemas/${schemaId}`));

    yield put({ type: GET_SCHEMA_BY_ID_SUCCESS, results: res.data.data });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    yield put({ type: GET_SCHEMA_BY_ID_ERROR });
    const error = e.response ? e.response.data : undefined;

    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* createSchema(action: { type: any; take: any; params: CreateSchema; cb: any }): any {
  try {
    const url = `SchemaModule/v1.0/schemas`;

    const { body } = action.params;

    let res = yield call(async () => await httpPost(url, body));

    yield put({ type: CREATE_SCHEMA_SUCCESS, results: res.data.data });

    history.push(`/SchemaModule/Schema/${res.data.data.id}`);

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'Schema Created', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CREATE_SCHEMA_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* updateSchema(action: { type: any; take: any; params: any; cb: any }): any {
  try {
    const { schemaId } = action.params;

    const url = `SchemaModule/v1.0/schemas/${schemaId}`;

    const res = yield call(async () => await httpPut(url, action.params.data));

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully saved settings', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: UPDATE_SCHEMA_BY_ID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* deleteSchema(action: { type: any; take: any; params: any }): any {
  const url = `SchemaModule/v1.0/schemas/${action.params.schemaId}`;
  try {
    yield call(async () => await httpDelete(url));

    yield history.goBack();

    yield put({ type: DELETE_SCHEMA_BY_ID_SUCCESS });

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully deleted schema', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: DELETE_SCHEMA_BY_ID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* batchCreatePermissions(action: { type: any; take: any; params: any; cb: any }): any {
  try {
    const { schemaId } = action.params;

    const url = `IdentityModule/v1.0/rbac/permissions/schemas/batch/${schemaId}`;

    const res = yield call(async () => await httpPost(url, {}));

    const getRes = yield call(async () => await httpGet(`SchemaModule/v1.0/schemas/${schemaId}`));

    yield put({ type: GET_SCHEMA_BY_ID_SUCCESS, results: getRes.data.data });

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'Permissions successfuly activated.', type: 'success' },
    });

    yield put({ type: BATCH_CREATE_SCHEMA_PERMISSIONS_SUCCESS });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    yield put({ type: BATCH_CREATE_SCHEMA_PERMISSIONS_ERROR });
    const error = e.response ? e.response.data : undefined;
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* batchDeletePermissions(action: { type: any; take: any; params: any; cb: any }): any {
  const { schemaId } = action.params;

  const url = `IdentityModule/v1.0/rbac/permissions/schemas/batch/${schemaId}`;
  try {
    const res = yield call(async () => await httpDelete(url));

    const getRes = yield call(async () => await httpGet(`SchemaModule/v1.0/schemas/${schemaId}`));
    yield put({ type: GET_SCHEMA_BY_ID_SUCCESS, results: getRes.data.data });

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'Permissions successfuly deleted.', type: 'success' },
    });

    yield put({ type: BATCH_DELETE_SCHEMA_PERMISSIONS_SUCCESS });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    yield put({ type: BATCH_DELETE_SCHEMA_PERMISSIONS_ERROR });
    const error = e.response ? e.response.data : undefined;
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* createSchemaType(action: {
  type: any;
  take: any;
  params: CreateSchemaType;
  cb: any;
}): any {
  try {
    const { body, schemaId } = action.params;

    const url = `SchemaModule/v1.0/schemas/${schemaId}/types`;

    let typeResponse = yield call(async () => await httpPost(url, body));

    yield put({ type: CREATE_SCHEMA_TYPE_SUCCESS, results: typeResponse.data.data });

    yield put({
      type: ADD_SCHEMA_TYPE_TO_SHORTLIST_SCHEMA,
      results: { schemaId: schemaId, type: typeResponse.data.data },
    });

    if (action.cb) {
      yield call(action.cb, typeResponse.data.data);
    }

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully saved settings', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CREATE_SCHEMA_TYPE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* deleteSchemaType(action: { type: any; take: any; params: DeleteSchemaType }): any {
  try {
    const { schemaId, schemaTypeId } = action.params;

    const url = `SchemaModule/v1.0/schemas/${schemaId}/types/${schemaTypeId}`;

    yield call(async () => await httpDelete(url));
    yield put({ type: DELETE_SCHEMA_TYPE_SUCCESS });

    yield put({
      type: REMOVE_SCHEMA_TYPE_FROM_SHORTLIST_SCHEMA,
      results: { schemaId, schemaTypeId },
    });

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully deleted schema', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: DELETE_SCHEMA_BY_ID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

// Schema Actions ////////////////////////////////////////////////////////////////////

function* getSchemaActions(action: { type: any; params: ISchemaActionsList; cb: any }): any {
  try {
    const { schemaId } = action.params;

    let path = `SchemaModule/v1.0/schemas-actions${schemaId ? `/schema/${schemaId}` : ''}`;
    const res = yield call(async () => await httpGet(path));

    // Filter by schemaId
    let filteredResponse: SchemaActionEntity[] = Object.assign(res.data.data);
    if (filteredResponse.length > 0 && schemaId) {
      filteredResponse = filteredResponse.filter(
        (item: SchemaActionEntity) => item.schemaId === schemaId,
      );
    }

    yield put({
      type: GET_SCHEMA_ACTION_LIST_SUCCESS,
      results: filteredResponse,
    });

    if (action.cb) {
      yield call(action.cb, filteredResponse);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    if (action.cb) {
      yield call(action.cb, false);
    }
    yield put({
      type: GET_SCHEMA_ACTION_LIST_ERROR,
      params: action.params,
    });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getSingleSchemaAction(action: {
  type: any;
  params: { schemaActionId: string };
  cb: any;
}): any {
  try {
    const { schemaActionId } = action.params;

    let path = `SchemaModule/v1.0/schemas-actions/${schemaActionId}`;
    const res = yield call(async () => await httpGet(path));

    yield put({
      type: GET_SINGLE_SCHEMA_ACTION_SUCCESS,
      results: res.data.data,
    });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({
      type: GET_SINGLE_SCHEMA_ACTION_ERROR,
      params: action.params,
    });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* createSchemaAction(action: { type: any; params: ISchemaActionCreate; cb: any }): any {
  try {
    const {
      schemaId,
      name,
      description,
      schemaTypeId,
      schemaAssociationId,
      isCreate,
      isUpdate,
      isStepFlow,
      defaultForm,
      userAction,
      stages,
      targetStages,
    } = action.params;

    let path = 'SchemaModule/v1.0/schemas-actions';

    const payload = {
      description,
      isCreate,
      isUpdate,
      isStepFlow,
      name,
      schemaId,
      schemaAssociationId: schemaAssociationId || null,
      schemaTypeId: schemaTypeId || null,
      defaultForm: defaultForm || false,
      userAction: userAction || false,
      stages: stages || null,
      targetStages: targetStages || null,
    };

    const res = yield call(async () => await httpPost(path, payload));

    yield put({
      type: CREATE_SCHEMA_ACTION_SUCCESS,
      results: res.data.data,
    });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({
      type: CREATE_SCHEMA_ACTION_ERROR,
      params: action.params,
    });
    if (action.cb) {
      yield call(action.cb, false);
    }
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* deleteSchemaAction(action: {
  type: any;
  params: { schemaActionId: string };
  cb: any;
}): any {
  try {
    const { schemaActionId } = action.params;

    let path = `SchemaModule/v1.0/schemas-actions/${schemaActionId}`;
    const res = yield call(async () => await httpDelete(path));

    yield put({
      type: DELETE_SCHEMA_ACTION_SUCCESS,
      results: res.data.data,
    });

    if (action.cb) {
      yield call(action.cb, true);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({
      type: DELETE_SCHEMA_ACTION_ERROR,
      params: action.params,
    });
    if (action.cb) {
      yield call(action.cb, false);
    }
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* updateSchemaAction(action: { type: any; params: ISchemaActionUpdate; cb: any }): any {
  try {
    const {
      schemaActionId,
      schemaId,
      name,
      description,
      schemaTypeId,
      schemaAssociationId,
      isCreate,
      isUpdate,
      isStepFlow,
      definition,
      defaultForm,
      userAction,
      stages,
      targetStages,
    } = action.params;

    const payload = {
      description: description || null,
      definition: definition || null,
      isCreate: isCreate || null,
      isUpdate: isUpdate || null,
      isStepFlow: isStepFlow || null,
      name: name || null,
      schemaAssociationId: schemaAssociationId || null,
      schemaId: schemaId || null,
      schemaTypeId: schemaTypeId || null,
      defaultForm: defaultForm || null,
      userAction: userAction || null,
      stages: stages || null,
      targetStages: targetStages || null,
    };

    let path = `SchemaModule/v1.0/schemas-actions/${schemaActionId}`;
    const res = yield call(async () => await httpPut(path, payload));

    yield put({
      type: UPDATE_SCHEMA_ACTION_SUCCESS,
      results: res.data.data,
    });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({
      type: UPDATE_SCHEMA_ACTION_ERROR,
      params: action.params,
    });
    if (action.cb) {
      yield call(action.cb, false);
    }
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* rootSaga() {
  yield takeLatest(LIST_SCHEMAS_REQUEST, listSchemas);
  yield takeEvery(GET_SCHEMA_BY_ID_REQUEST, getSchemaById);
  yield takeEvery(GET_SCHEMAS_BY_MODULE_REQUEST, getSchemasByModule);
  yield takeEvery(GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST, getByModuleAndEntity);
  yield takeLatest(UPDATE_SCHEMA_BY_ID_REQUEST, updateSchema);
  yield takeLatest(DELETE_SCHEMA_BY_ID_REQUEST, deleteSchema);
  yield takeLatest(BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST, batchCreatePermissions);
  yield takeLatest(BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST, batchDeletePermissions);
  yield takeLatest(CREATE_SCHEMA_REQUEST, createSchema);
  yield takeLatest(CREATE_SCHEMA_TYPE_REQUEST, createSchemaType);
  yield takeLatest(DELETE_SCHEMA_TYPE_REQUEST, deleteSchemaType);
  yield takeEvery(GET_SCHEMA_ACTION_LIST_REQUEST, getSchemaActions);
  yield takeLatest(GET_SINGLE_SCHEMA_ACTION_REQUEST, getSingleSchemaAction);
  yield takeLatest(CREATE_SCHEMA_ACTION_REQUEST, createSchemaAction);
  yield takeLatest(DELETE_SCHEMA_ACTION_REQUEST, deleteSchemaAction);
  yield takeLatest(UPDATE_SCHEMA_ACTION_REQUEST, updateSchemaAction);
  yield takeLatest(REFRESH_SCHEMA_SHORTLIST_REQUEST, refreshSchemaShortlist);
}

export default rootSaga;
