import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { Button, Col, Row, Statistic } from 'antd';
import { isMobile } from 'react-device-detect';
import { useCalendarState } from './hooks/useCalendarState';
import { getScheduleConfigSummary } from './hooks/useCalendarUtilization';

type CalendarHeaderProps = {
  calendarState: ReturnType<typeof useCalendarState>['state'];
  calendarService: ReturnType<typeof useCalendarState>['service'];
  scheduleConfigSummary: ReturnType<typeof getScheduleConfigSummary>;
};

type SummaryField = {
  key: keyof CalendarHeaderProps['scheduleConfigSummary'];
  name: string;
};

const summaryFields: SummaryField[] = [
  {
    key: 'MaxAMCount',
    name: 'Total AM Slots',
  },
  {
    key: 'AMCount',
    name: 'Scheduled AM Slots',
  },
  {
    key: 'AMRemaining',
    name: 'Remaning AM Slots',
  },
  {
    key: 'MaxPMCount',
    name: 'Total PM Slots',
  },
  {
    key: 'PMCount',
    name: 'Scheduled PM Slots',
  },
  {
    key: 'PMRemaining',
    name: 'Remaning PM Slots',
  },
];

export const CalendarHeader = ({
  calendarState,
  calendarService,
  scheduleConfigSummary,
}: CalendarHeaderProps) => {
  return (
    <>
      <Row style={{ borderRadius: 10, padding: '10px 0 20px 0' }} align="middle">
        <Col xs={24} md={12} style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <span
            style={{
              fontWeight: '600',
              fontSize: isMobile ? '1.2rem' : '1.6rem',
              color: '#1777ff',
            }}
          >
            {calendarState.currentRange}
          </span>
        </Col>
        <Col
          xs={24}
          md={12}
          style={{
            textAlign: isMobile ? 'center' : 'right',
            marginTop: isMobile ? 15 : undefined,
          }}
        >
          <Button
            type="primary"
            onClick={calendarService.previous}
            ghost
            style={{
              borderRadius: '8px 0 0 8px',
              borderRight: 0,
              width: isMobile ? '50%' : 120,
            }}
          >
            <CaretLeftFilled />
            <span>Previous</span>
          </Button>
          <Button
            type="primary"
            onClick={calendarService.next}
            style={{ borderRadius: '0 8px 8px 0', width: isMobile ? '50%' : 120 }}
            ghost
          >
            <span>Next</span>
            <CaretRightFilled />
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        {summaryFields?.map((field) => (
          <Col key={field.key} style={{ textAlign: 'center' }}>
            <Statistic title={field.name} value={scheduleConfigSummary[field.key]} />
          </Col>
        ))}
      </Row>
    </>
  );
};
