import { useEffect, useState } from "react";
import { ItemRendererProps, MultiSelect as Select } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";
import { Card, Col, Empty, Row } from "antd"
import { isMobile } from "react-device-detect"

import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform";
import { SchemaEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity";
import { getProperty } from "@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers";
import { SchemaColumnOptionEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity";

import { getSchemaByModuleAndEntityRequest } from "../../../../../core/schemas/store/actions";

import { httpPost } from "../../../../../shared/http/requests";
import { PageHeader } from '../../../../../shared/components/PageHeader';

import { useRequest } from "../../../../../containers/ProjectModule/hooks/useRequest";


import { RecordSelectPanel } from "./RecordSelectPanel/Table";
import { RecordDetailsPanel } from "./RecordDetailsPanel";

type SortDirection = "asc" | "desc";

type GenericDataOption = {
  id: string;
  name: string;
};

type Filters = {
  selectedExchange?: DbRecordEntityTransform;
  selectedOwners: GenericDataOption[];
  selectedPolygons: GenericDataOption[];
  selectedRouteType?: SchemaColumnOptionEntity;
  selectedStructureType?: SchemaColumnOptionEntity;
};

const buildQueryPropertiesFromFilters = (filters: Filters, type: 'route' | 'structure') => {
  // Default filters
  const properties: { columnName: string; operator: string; value: string | string[]; }[] = [
    {
      columnName: 'Resolved',
      operator: '=',
      value: 'false',
    }
  ];

  // Selected Exchange Filter
  if (filters.selectedExchange)
    properties.push({
      columnName: 'ExPolygonId',
      operator: '=',
      value: getProperty(filters.selectedExchange, 'ExPolygonId'),
    });

  // Selected Owners Filter
  if (filters.selectedOwners.length)
    properties.push({
      columnName: 'OwnerId',
      operator: 'IN',
      value: filters.selectedOwners.map(o => o.id),
    });

  // Selected Polygons Filter
  if (filters.selectedPolygons.length) {
    const l1PolygonIds: string[] = [];
    const l2PolygonIds: string[] = [];

    filters.selectedPolygons.forEach(
      polygon => {
        const isL1 = polygon.name.startsWith('L1');
        if (isL1) l1PolygonIds.push(polygon.id);
        else l2PolygonIds.push(polygon.id);
      }
    );

    if (l1PolygonIds.length)
      properties.push({
        columnName: 'L1PolygonId',
        operator: 'IN',
        value: l1PolygonIds,
      });

    if (l2PolygonIds.length)
      properties.push({
        columnName: 'L2PolygonId',
        operator: 'IN',
        value: l2PolygonIds,
      });
  }

  // Selected Route Type Filter
  if (type === 'route' && filters.selectedRouteType)
    properties.push({
      columnName: 'RouteType',
      operator: '=',
      value: filters.selectedRouteType.value,
    });

  // Selected Structure Type Filter
  if (type === 'structure' && filters.selectedStructureType)
    properties.push({
      columnName: 'SurveyType',
      operator: '=',
      value: filters.selectedStructureType.value,
    });

  return properties;
};

export const RFCManagementView = () => {
  const [projectSchema, setProjectSchema] = useState<SchemaEntity | undefined>();
  const [featureSchema, setFeatureSchema] = useState<SchemaEntity | undefined>();

  const [loadingExchanges, setLoadingExchanges] = useState(false);
  const [exchangesList, setExchangesList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedExchange, setSelectedExchange] = useState<DbRecordEntityTransform | undefined>();

  const [loadingFeatures, setLoadingFeatures] = useState(false);
  const [featuresList, setFeaturesList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<DbRecordEntityTransform | undefined>();

  // Stage Filter
  const stagesList = [
    'Open',
    'In Review',
    'Additional Information Required',
    'To QC',
    'RBM Approval',
    'MI/UI Case raised'
  ];
  const [selectedStages, setSelectedStages] = useState(stagesList);
  const toggleStage = (stage: string) => {
    setSelectedStages(stages => {
      const found = stages.find(st => st === stage);
      if (found)
        return stages.filter(st => st !== stage)

      return [...stages, stage];
    })
  }

  // Survey Route Type Filter
  const [routeTypeList, setRouteTypeList] = useState<SchemaColumnOptionEntity[]>([]);
  const [selectedRouteType, setSelectedRouteType] = useState<SchemaColumnOptionEntity | undefined>();


  // Survey Structure Type Filter
  const [structureTypeList, setStructureTypeList] = useState<SchemaColumnOptionEntity[]>([]);
  const [selectedStructureType, setSelectedStructureType] = useState<SchemaColumnOptionEntity | undefined>();

  // Owner filter
  const [ownerList, setOwnerList] = useState<GenericDataOption[]>([]);
  const [selectedOwners, setSelectedOwners] = useState<GenericDataOption[]>([]);
  const toggleOwner = (owner: GenericDataOption) => {
    setSelectedOwners(owners => {
      const found = owners.find(o => o.id === owner.id);
      if (found)
        return owners.filter(o => o.id !== owner.id)

      return [...owners, owner];
    })
  }

  // Polygon filter
  const [polygonList, setPolygonList] = useState<GenericDataOption[]>([]);
  const [selectedPolygons, setSelectedPolygons] = useState<GenericDataOption[]>([]);
  const togglePolygon = (polygon: GenericDataOption) => {
    setSelectedPolygons(polygons => {
      const found = polygons.find(p => p.id === polygon.id);
      if (found)
        return polygons.filter(p => p.id !== polygon.id)

      return [...polygons, polygon];
    })
  }

  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);

  const fetchExchanges = async (loadInBackground: boolean) => {
    if (!loadInBackground) {
      setLoadingExchanges(true);
    }

    const response = await httpPost('ProjectModule/v1.0/db/dynamicQuery', {
      entity: 'ProjectModule:Exchange',
      pagination: {
        size: 1000,
        page: 1,
      },
      // sort: [{ title: { order: 'asc' } }]
      // sort: [{ 'properties.ExchangeName': { order: 'asc' } }]
      // sort: [{ 'properties.Borough': { order: 'asc' } }]
      // sort: [{ property: 'title', order: 'asc' }]
    });

    const data = response.data.data.records.filter(
      (record: DbRecordEntityTransform) => getProperty(record, 'ExPolygonId')
    );

    data.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
      return a.title?.localeCompare(b.title ?? '');
    })

    setExchangesList(data);
    setLoadingExchanges(false);
  };

  const fetchExchangeFeatures = async (loadInBackground: boolean) => {
    if (!projectSchema) return;

    if (!loadInBackground) {
      setLoadingFeatures(true);
    }

    const surveyRouteResponse = await httpPost('ProjectModule/v1.0/db/dynamicQuery', {
      entity: 'ProjectModule:Feature',
      type: 'SURVEY_ROUTE',
      stageNames: selectedStages,
      properties: buildQueryPropertiesFromFilters({
        selectedExchange,
        selectedOwners,
        selectedPolygons,
        selectedRouteType,
        selectedStructureType,
      }, 'route'),
      pagination: {
        size: 2000,
        page: 1,
      },
    });

    const surveyStructureResponse = await httpPost('ProjectModule/v1.0/db/dynamicQuery', {
      entity: 'ProjectModule:Feature',
      type: 'SURVEY_STRUCTURE',
      stageNames: selectedStages,
      properties:  buildQueryPropertiesFromFilters({
        selectedExchange,
        selectedOwners,
        selectedPolygons,
        selectedRouteType,
        selectedStructureType,
      }, 'structure'),
      pagination: {
        size: 2000,
        page: 1,
      },
    });

    const surveys = [...surveyRouteResponse.data.data.records, ...surveyStructureResponse.data.data.records].sort(
      (a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
        const sort: SortDirection = "desc";
        const aCreated = a.createdAt ?? '';
        const bCreated = b.createdAt ?? '';

        if (sort === "asc" as SortDirection)
          return aCreated < bCreated ? -1 : aCreated > bCreated ? 1 : 0;

        return bCreated < aCreated ? -1 : bCreated > aCreated ? 1 : 0;
      }
    );

    const polygonIds: string[] = [];
    const polygons: GenericDataOption[] = [];

    const ownerIds: string[] = [];
    const owners: GenericDataOption[] = [];

    surveys.forEach(survey => {
      const l1PolygonId = getProperty(survey, 'L1PolygonId');
      const l2PolygonId = getProperty(survey, 'L2PolygonId');
      const ownerId = getProperty(survey, 'OwnerId');
      const ownerName = getProperty(survey, 'OwnerName');

      if (l1PolygonId && !polygonIds.includes(l1PolygonId)) {
        polygonIds.push(l1PolygonId);
        polygons.push({ id: l1PolygonId, name: `L1 Polygon ${l1PolygonId}` });
      }

      if (l2PolygonId && !polygonIds.includes(l2PolygonId)) {
        polygonIds.push(l2PolygonId);
        polygons.push({ id: l2PolygonId, name: `L2 Polygon ${l2PolygonId}` });
      }

      if (ownerId && !ownerIds.includes(ownerId)) {
        ownerIds.push(ownerId);
        owners.push({ id: ownerId, name: ownerName });
      }
    });

    setFeaturesList(surveys);
    setSelectedFeature(undefined);
    setPolygonList(polygons);
    // setSelectedPolygons([]);
    setOwnerList(owners);
    // setSelectedOwners([]);
    setLoadingFeatures(false);
  }

  // Fetching schemas and exchanges on mount
  useEffect(() => {
    fetchExchanges(false);

    getSchema({ moduleName: 'ProjectModule', entityName: 'Project' })
      .then(schema => setProjectSchema(schema));

    getSchema({ moduleName: 'ProjectModule', entityName: 'Feature' })
      .then(schema => setFeatureSchema(schema));
  }, []);

  // Fetching features when Filters change
  useEffect(() => {
    fetchExchangeFeatures(false);
    setSelectedFeature(undefined);
  }, [
    selectedExchange,
    selectedStages.join(','),
    selectedOwners.map(o => o.id).join(','),
    selectedPolygons.map(p => p.id).join(','),
    selectedRouteType,
    selectedStructureType,
    projectSchema
  ]);

  // Building filters based on schema columns
  useEffect(() => {
    if (!featureSchema) return;

    const routeTypeColumn = featureSchema.columns.find(col => col.name === 'RouteType');
    if (routeTypeColumn && routeTypeColumn.options) {
      setRouteTypeList(routeTypeColumn.options);
      setSelectedRouteType(undefined);
    }

    const structureTypeColumn = featureSchema.columns.find(col => col.name === 'StructureType');
    if (structureTypeColumn && structureTypeColumn.options) {
      setStructureTypeList(structureTypeColumn.options);
      setSelectedStructureType(undefined);
    }

  }, [featureSchema]);

  // String helpers for Select
  const stringItemRenderer = (str: string, { handleClick, handleFocus, modifiers }: ItemRendererProps) => {
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={str}
        text={str}
        onClick={handleClick}
        onFocus={handleFocus}
      />
    );
  };

  const stringItemPredicate = (originalQuery: string, originalStage: string, _index: number | undefined, exactMatch: boolean | undefined) => {
    const stage = originalStage.toLowerCase()
    const query = originalQuery.toLowerCase();

    return exactMatch
      ? stage === query
      : stage.includes(query)
  };

  // Generic Data helpers for Select
  const dataItemRenderer = ({ id, name }: GenericDataOption, { handleClick, handleFocus, modifiers }: ItemRendererProps) => {
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={id}
        // label={option.value}
        text={name}
        onClick={handleClick}
        onFocus={handleFocus}
      />
    );
  };

  const dataItemPredicate = (originalQuery: string, data: GenericDataOption, _index: number | undefined, exatcMatch: boolean | undefined) => {
    const name = data.name.toLowerCase();
    const query = originalQuery.toLowerCase();

    return exatcMatch
      ? name === query
      : name.includes(query);
  };

  // ENUM SchemaColumn helpers for Select
  const enumItemRenderer = (option: SchemaColumnOptionEntity, { handleClick, handleFocus, modifiers }: ItemRendererProps) => {
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={option.id}
        label={option.value}
        text={option.label}
        onClick={handleClick}
        onFocus={handleFocus}
      />
    );
  };

  const enumItemPredicate = (originalQuery: string, option: SchemaColumnOptionEntity, _index: number | undefined, exactMatch: boolean | undefined) => {
    const optionStr = option.label.toLowerCase();
    const query = originalQuery.toLocaleLowerCase();

    return exactMatch
      ? optionStr === query
      : optionStr.includes(query);
  };
  return (
    <div className="rfc-management-view">
      <Row gutter={12}>
        <Col span={24}>
          {/* Page Header */}
          <PageHeader
            style={{ border: '1px solid #dddbda', background:'white' }}
            title="RFC Mangement"
          >
            <Row gutter={8}>
              <Col span={4}>
                <Select
                  placeholder="Select an Exchange"
                  disabled={loadingExchanges || loadingFeatures}
                  items={exchangesList}
                  selectedItems={selectedExchange ? [selectedExchange] : []}
                  itemRenderer={(exchange, { handleClick, handleFocus, modifiers }) => {
                    return (
                      <MenuItem
                        active={modifiers.active}
                        disabled={modifiers.disabled}
                        key={exchange.id}
                        label={getProperty(exchange, 'ExPolygonId')}
                        text={getProperty(exchange, 'ExchangeName') ?? exchange.title}
                        onClick={handleClick}
                        onFocus={handleFocus}
                      />
                    );
                  }}
                  itemPredicate={(originalQuery, exchange, _index, exactMatch) => {
                    const exchangeName = (getProperty(exchange, 'ExchangeName') ?? exchange.title).toLowerCase()
                    const query = originalQuery.toLowerCase();

                    return exactMatch
                      ? exchangeName === query
                      : exchangeName.includes(query)
                  }}
                  tagRenderer={exchange => getProperty(exchange, 'ExchangeName') ?? exchange.title}
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={() => setSelectedExchange(undefined)}
                  onItemSelect={setSelectedExchange}
                  fill
                />
              </Col>
              <Col span={8}>
                <Select<string>
                  placeholder="Select Stages"
                  disabled={loadingFeatures}
                  items={stagesList}
                  selectedItems={selectedStages}
                  itemRenderer={stringItemRenderer}
                  itemPredicate={stringItemPredicate}
                  tagRenderer={str => str}
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={stage => setSelectedStages(stages => stages.filter(st => st !== stage))}
                  onItemSelect={toggleStage}
                  fill
                />
              </Col>
              <Col span={3}>
                <Select<GenericDataOption>
                  placeholder="Select Owners"
                  disabled={loadingExchanges || ownerList.length === 0 || loadingFeatures}
                  items={ownerList}
                  selectedItems={selectedOwners}
                  itemRenderer={dataItemRenderer}
                  itemPredicate={dataItemPredicate}
                  tagRenderer={data => data.name}
                  onRemove={owner => setSelectedOwners(owners => owners.filter(o => o.id !== owner.id))}
                  onItemSelect={(toggleOwner)}
                />
              </Col>
              <Col span={3}>
                <Select<GenericDataOption>
                  placeholder="Select Polygons"
                  disabled={loadingExchanges || polygonList.length === 0 || loadingFeatures}
                  items={polygonList}
                  selectedItems={selectedPolygons}
                  itemRenderer={dataItemRenderer}
                  itemPredicate={dataItemPredicate}
                  tagRenderer={data => data.name}
                  onRemove={polygon => setSelectedPolygons(polygons => polygons.filter(p => p.id !== polygon.id))}
                  onItemSelect={(togglePolygon)}
                />
              </Col>
              <Col span={3}>
                <Select<SchemaColumnOptionEntity>
                  placeholder="Select Survey Route Types"
                  disabled={loadingExchanges || routeTypeList.length === 0 || loadingFeatures}
                  items={routeTypeList}
                  selectedItems={selectedRouteType ? [selectedRouteType] : []}
                  itemRenderer={enumItemRenderer}
                  itemPredicate={enumItemPredicate}
                  tagRenderer={option => option.label}
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={() => setSelectedRouteType(undefined)}
                  onItemSelect={setSelectedRouteType}
                />
              </Col>
              <Col span={3}>
                <Select<SchemaColumnOptionEntity>
                  placeholder="Select Survey Structure Types"
                  disabled={loadingExchanges || structureTypeList.length === 0 || loadingFeatures}
                  items={structureTypeList}
                  selectedItems={selectedStructureType ? [selectedStructureType] : []}
                  itemRenderer={enumItemRenderer}
                  itemPredicate={enumItemPredicate}
                  tagRenderer={option => option.label}
                  tagInputProps={{ tagProps: { minimal: true } }}
                  onRemove={() => setSelectedStructureType(undefined)}
                  onItemSelect={setSelectedStructureType}
                />
              </Col>
            </Row>
          </PageHeader>
        </Col>
        <Col span={24} style={{ marginTop: 15 }}>
          {/* Page Content */}
          <Row gutter={16}>
            {/* Middle Panel - List of Features type SURVEY_ROUTE and SURVEY_STRUCTURE */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <RecordSelectPanel
                title="RFCs"
                loading={loadingFeatures}
                records={featuresList}
                selectedRecords={selectedFeature ? [selectedFeature] : []}
                onRecordClicked={record => setSelectedFeature(record)}
                style={{ minHeight: '75vh', maxHeight: '75vh' }}
                recordListStyle={{ display: 'flex', minHeight: '60vh', maxHeight: '60vh', overflow: 'scroll', flexDirection: 'column', gap: '0.5rem' }}
              />
            </Col>

            {/* Right Panel - FeatureRecordBody */}
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginTop: isMobile ? 20 : 0 }}>
              {!featureSchema || !selectedFeature ? (
                <Card title="Survey Details" style={{ minHeight: '80vh' }}>
                  <Empty style={{ marginTop: '20vh' }} description="No RFC selected" />
                </Card>
              ) : (
                  !!selectedFeature &&
                  <RecordDetailsPanel
                    record={selectedFeature}
                    schema={featureSchema}
                  />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}