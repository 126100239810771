import {
  Callout,
  DialogStep,
  MultistepDialog,
  Section,
  SectionCard,
  Spinner,
} from '@blueprintjs/core';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Descriptions } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { parseDateToLocalFormat } from '../../../../../shared/utilities/dateHelpers';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';
import {
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';

const stepTwoFormUUID = uuidv4();

type PropsType = {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: any;
  recordFormReducer: any;
  initializeForm: any;
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) => void;
  updateRecord: (params: IUpdateRecordById, cb: any) => void;
  openDialog: boolean;
  onClose?: any;
  onConfirm?: any;
  alertMessage: (params: { body: string; type: string }) => void;
};

const SCHEMA_ACTION_NAME = 'ChangeQueue';

const CaseChangeQueueDialog: FunctionComponent<PropsType> = (props) => {
  const {
    record,
    getSchema,
    updateRecord,
    recordFormReducer,
    initializeForm,
    openDialog,
    onClose,
    onConfirm,
    alertMessage,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [queueChangeSchema, setQueueChangeSchema] = React.useState<SchemaEntity | undefined>(
    undefined,
  );
  const [dialogState, setDialogState] = React.useState<{
    stepTwo: {
      formData: DbRecordCreateUpdateDto | undefined;
    };
  }>({
    stepTwo: {
      formData: undefined,
    },
  });
  const [allQueues, setAllQueues] = React.useState<DbRecordEntityTransform[]>([]);
  const [queueChanges, setQueueChanges] = React.useState<DbRecordEntityTransform[]>([]);
  const [newQueueChange, setNewQueueChange] = React.useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [schemaActions, setSchemaActions] = React.useState<SchemaActionEntity[]>([]);
  const [stepError, setError] = React.useState<any>(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);

  const updateCaseQueue = (R_QueueId: string, ChangeQueueReason: string) =>
    new Promise((resolve, reject) => {
      getSchema(
        {
          moduleName: 'SupportModule',
          entityName: 'Case',
        },
        (schema: SchemaEntity) => {
          const caseUpdateDto = {
            schema: schema,
            recordId: record.id,
            createUpdate: {
              id: record.id,
              entity: record.entity,
              type: record.type,
              properties: {
                R_QueueId,
                ChangeQueueReason,
              },
            },
          };

          updateRecord(caseUpdateDto, (res: any) => {
            resolve(res);
          });
        },
      );
    });

  function resetState() {
    setError(undefined);
    setIsNextDisabled(true);
    setIsConfirmLoading(false);
    setQueueChanges([]);
    setNewQueueChange(undefined);
    setDialogState({
      stepTwo: {
        formData: undefined,
      },
    });
  }

  useEffect(() => {
    setIsDialogOpen(openDialog);
  }, [openDialog]);

  useEffect(() => {
    if (isDialogOpen) {
      resetState();
      fetchData();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    fetchData();
  }, [record]);

  // Step 2: initialize your form schemas
  function fetchData() {
    if (record) {
      // Load QueueChange schema
      getSchema(
        {
          moduleName: SchemaModuleTypeEnums.SUPPORT_MODULE,
          entityName: 'QueueChange',
        },
        (schema: SchemaEntity) => {
          setQueueChangeSchema(schema);
        },
      );

      // Get QueueChanges for the current Case record
      httpGet(
        `SupportModule/v1.0/db-associations/Case/${record.id}/one-relation?entity=QueueChange&withLinks=true`,
      )
        .then((res) => {
          setQueueChanges(res.data['QueueChange']?.dbRecords || []);
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    }

    // Load schema actions
    httpGet(`SchemaModule/v1.0/schemas-actions`)
      .then((res) => {
        setSchemaActions(res.data.data);
      })
      .catch((err) => {
        console.error('Error loading table data:', err);
      });

    // Load Queues
    httpPost(`SupportModule/v1.0/db/dynamicQuery`, {
      entity: 'SupportModule:Queue',
      pagination: { size: 1000, page: 1 },
    })
      .then((res) => {
        setAllQueues(res.data.data.records);
      })
      .catch((err) => {
        console.error('Error loading queues:', err);
      });
  }

  const queueChangeAction = schemaActions?.find((action: SchemaActionEntity) => {
    return action.name === SCHEMA_ACTION_NAME;
  });

  function setupStep(newStep: string) {
    let stepNumber = 0;
    if (newStep === 'step-one') {
      stepNumber = 0;
    } else if (newStep === 'step-two') {
      stepNumber = 1;
    } else if (newStep === 'confirm') {
      stepNumber = 2;
    }

    switch (stepNumber) {
      case 0:
        // We should not block moving forward
        // on the first step
        setIsNextDisabled(false);
        break;
      case 1:
        if (!queueChangeAction) {
          return;
        }
        // Save the modified data from step 1 locally
        initializeForm({
          formUUID: stepTwoFormUUID,
          showFormModal: true,
          hideRecordFormFields: true,
          schemaActionId: queueChangeAction?.id,
          showInitializing: false,
          isCreateReq: true,
          schema: queueChangeSchema,
          modified: dialogState.stepTwo?.formData ? [dialogState.stepTwo?.formData] : [],
          sections: [
            {
              name: queueChangeSchema?.name,
              schema: queueChangeSchema,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                },
              ],
            },
          ],
        });
        break;
      case 2:
        setDialogState({
          ...dialogState,
          stepTwo: {
            formData: {
              ...recordFormReducer.modified[0],
              entity: `${queueChangeSchema?.moduleName}:${queueChangeSchema?.entityName}`,
              associations: [
                ...recordFormReducer.modified[0]?.associations,
                ...recordFormReducer.sections[0]?.associations,
              ],
            },
          },
        });
        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    setError(undefined);

    const queueChangeDto = dialogState?.stepTwo?.formData;
    if (!queueChangeDto) {
      setError('Please fill the form.');
      return;
    }

    setIsConfirmLoading(true);
    try {
      // Set FromQueueId to the current record queue
      queueChangeDto.properties = {
        ...queueChangeDto?.properties,
        FromQueueId: getProperty(record, 'R_QueueId'),
      };

      // console.log('[DebugX]', { queueChangeDto, caseUpdateDto })
      // throw new Error('Uepa!');

      if (!newQueueChange) {
        const [queueChangeCreateRes, _caseUpdateRes] = await Promise.all([
          httpPost(`SupportModule/v1.0/db/bulk-upsert`, {
            recordsToUpsert: [queueChangeDto],
          }),
          updateCaseQueue(
            queueChangeDto?.properties?.ToQueueId,
            queueChangeDto?.properties?.Comment,
          ),
        ]);

        const createdQueueChange = queueChangeCreateRes.data?.data?.creates?.[0];

        if (createdQueueChange) {
          const getQueueChangeRequest = await httpGet(
            `SupportModule/v1.0/db/QueueChange/${createdQueueChange?.id}`,
          );
          setNewQueueChange(getQueueChangeRequest.data?.data);
          setIsConfirmLoading(false);

          alertMessage({
            body: `Queue Change was created successfully.`,
            type: 'success',
          });

          if (onConfirm) {
            onConfirm();
          }
        }
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      setError(err?.response?.data?.message);
      console.error('Error:', err);
    }
  }

  return (
    <>
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        className="multistep-dialog-pic-request"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          onClose && onClose();
          resetState();
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          setupStep(newStep);
          newStep !== 'step-one' && setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
          // tooltipContent: this.state.value === undefined ? "Select an option to continue" : undefined,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading || !!newQueueChange,
          onClick: () => handleFinalStepSubmit(),
        }}
        title={'Change Queue'}
      >
        <DialogStep
          id="step-one"
          title="Check existing Queue Changes"
          panel={
            <Section>
              <SectionCard>
                <Callout
                  intent={queueChanges?.length > 0 ? 'primary' : 'success'}
                  title={
                    queueChanges?.length > 0 ? 'Existing Queue Changes' : 'No Queue Changes found'
                  }
                >
                  <div>
                    {queueChanges?.map((rec) => (
                      <Link key={rec.id} target="_blank" to={getBrowserPath(rec)}>
                        <div>
                          <div>
                            From{' '}
                            <b>
                              {
                                rec.links?.find((l) => l.id === getProperty(rec, 'FromQueueId'))
                                  ?.title
                              }
                            </b>{' '}
                            To{' '}
                            <b>
                              {
                                rec.links?.find((l) => l.id === getProperty(rec, 'ToQueueId'))
                                  ?.title
                              }
                            </b>{' '}
                            on {parseDateToLocalFormat(String(rec.createdAt))} [
                            <b>{getProperty(rec, 'Comment')}</b>]
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </Callout>
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="step-two"
          title="New Queue Change"
          panel={
            <Section>
              <SectionCard>
                {!queueChangeAction ? (
                  <Callout intent="danger">
                    <p>Could not locate schema action: {SCHEMA_ACTION_NAME}</p>
                  </Callout>
                ) : (
                  <CoreForm
                    type="EMBEDDED"
                    formUUID={stepTwoFormUUID}
                    isCreateRecord={true}
                    showFormActions={false}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                  />
                )}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              {!newQueueChange && (
                <SectionCard>
                  {isConfirmLoading ? (
                    <Spinner size={50} />
                  ) : (
                    <SectionCard>
                      <p>Confirm changes before submitting:</p>
                      <Descriptions>
                        <Descriptions.Item label="From Queue">
                          {JSON.stringify(
                            allQueues.find((q) => q.id === getProperty(record, 'R_QueueId'))?.title,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="To Queue">
                          {JSON.stringify(
                            allQueues.find(
                              (q) => q.id === dialogState.stepTwo.formData?.properties?.ToQueueId,
                            )?.title,
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Comment">
                          {dialogState.stepTwo.formData?.properties?.Comment}
                        </Descriptions.Item>
                      </Descriptions>
                    </SectionCard>
                  )}
                </SectionCard>
              )}

              {stepError && (
                <SectionCard>
                  <Callout intent="danger">
                    <p>{stepError}</p>
                  </Callout>
                </SectionCard>
              )}

              {newQueueChange && (
                <>
                  <SectionCard>
                    <Descriptions>
                      <Descriptions.Item label="Comment">
                        {getProperty(newQueueChange, 'Comment')}
                      </Descriptions.Item>
                    </Descriptions>
                  </SectionCard>

                  <SectionCard>
                    <Link target="_blank" to={getBrowserPath(newQueueChange)}>
                      <div>View Queue Change</div>
                    </Link>
                  </SectionCard>
                </>
              )}
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseChangeQueueDialog);
