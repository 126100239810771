import G6 from '@antv/g6';
import { setClosureTypeName } from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/helpers/converters';
import {
  QGISCableTypeEnum,
  QGISClosureTypeEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  Card,
  Col,
  Form,
  Layout,
  Result,
  Row,
  Select,
  Skeleton,
  Spin,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';
import Search from 'antd/es/input/Search';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AwsS3BucketList from '../../../../core/aws/s3/buckets/List';
import JobsStatus from '../../../../core/jobs/components/JobsStatus';
import JobsListView from '../../../../core/jobs/components/ListView';
import {
  ISearchRecords,
  ISearchRecordsByExternalId,
  searchRecordsByExternalId,
  searchRecordsRequest,
  setDbRecordState,
} from '../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../core/records/store/reducer';
import AssociationDataTable from '../../../../core/recordsAssociations/components/AssociationDataTable';
import { getSchemaByModuleAndEntityRequest } from '../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../core/schemas/store/reducer';
import { displayMessage } from '../../../../shared/system/messages/store/reducers';
import { closureTypes } from '../Map/helpers';
import CosmosGraph from './components/AutosplicingGraph/CosmosGraph';
import OdinGraph from './components/AutosplicingGraph/OdinGraph';
import SplicingTooltip from './components/AutosplicingGraph/SplicingTooltip';
import AutosplicingHelpDrawer from './components/AutosplicingHelpDrawer';
import AutosplicingResetDrawer from './components/AutosplicingResetDrawer';
import AutosplicingSidebar from './components/AutosplicingSidebar';
import {
  shouldDisableCableConnections,
  shouldDisableFiberSplicing,
} from './components/AutosplicingSidebar/helpers';
import SplicingMatrices from './components/AutosplicingSplicingMatricesFiles';
import ClosureFiberSplicingTable from './components/ClosureFiberSplicingTable';
import {
  constructCableTitle,
  getBuildClosureStyle,
  getPlanCableColor,
  getPlanClosureStyle,
  highlightGraphChain,
  resetGraphChainsHighlight,
} from './helpers';
import {
  getAutosplicingTemplateByPathname,
  getAutosplicingTemplateDifference,
  getAutosplicingTemplates,
  updateAutosplicingLoadingIndicators,
  updateAutosplicingReducerState,
} from './store/actions';
import {
  FeatureSource,
  GraphDisplayMode,
  GraphEntity,
  IAutosplicingGraphRefs,
  IAutosplicingLoadingIndicators,
  IAutosplicingReducer,
  IGetAutosplicingTemplateByPathname,
  IGetAutosplicingTemplateDifference,
  IGetAutosplicingTemplates,
  TAutosplicingGraphEntity,
  TAutosplicingGraphNodeEdgeData,
  TUpdateAutosplicingLoadingIndicatorsAction,
  TUpdateAutosplicingReducerAction,
} from './store/types';
import './styles.scss';
import RecordQuickViewDrawer from '../../../../core/records/components/RecordQuickViewDrawer';

const { TabPane } = Tabs;
const { Content } = Layout;

interface Props {
  polygonId: string;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  searchRecords: any;
  match: any;
  history: any;
  alertMessage: Function;
  searchRecordsByExternalId: Function;
  autosplicingReducer: IAutosplicingReducer;
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  updateLoadingIndicators: TUpdateAutosplicingLoadingIndicatorsAction;
  getTemplates: any;
  getTemplateByPathname: any;
  getAutosplicingTemplateDifference: any;
  getSchemaByModuleAndEntity: Function;
  modifyDbRecordState: Function;
}

let cosmosGraphContainer: any = null;
let odinGraphContainer: any = null;

const Autosplicing = (props: Props) => {
  const {
    alertMessage,
    autosplicingReducer,
    getAutosplicingTemplateDifference,
    getTemplateByPathname,
    getTemplates,
    updateAutosplicingReducer,
    updateLoadingIndicators,
    getSchemaByModuleAndEntity,
    recordReducer,
    modifyDbRecordState,
  } = props;

  let graphRefs: IAutosplicingGraphRefs = {
    cosmosGraphRef: React.useRef(null),
    cosmosGraphToolbarRef: React.useRef(null),
    cosmosGraphMinimapRef: React.useRef(null),
    odinGraphRef: React.useRef(null),
    odinGraphMinimapRef: React.useRef(null),
    odinGraphToolbarRef: React.useRef(null),
  };

  const { PROJECT_MODULE } = SchemaModuleTypeEnums;
  const { loadingIndicators, allJobs, formData } = autosplicingReducer;
  let { cosmosGraphRef, odinGraphRef, cosmosGraphMinimapRef, odinGraphMinimapRef } = graphRefs;
  const [form] = Form.useForm();
  const [splicingTooltip, setSplicingTooltip] = useState<{
    source: FeatureSource;
    closureId: string;
    x: number;
    y: number;
  } | null>(null);

  // If Fiber splicing check returned cable ids with issues, re-render the graph
  // with corresponding cables highlighted for error.
  useEffect(() => {
    if (
      autosplicingReducer.cableIdsWithIssues?.length! > 0 &&
      autosplicingReducer.latestData?.length! > 0 &&
      !autosplicingReducer.showSplicingRequestForChangeForm
    ) {
      // Go through all edges, update ones that have issues

      const allEdges = cosmosGraphContainer?.getEdges();

      if (allEdges && allEdges?.length! > 0) {
        for (const edge of allEdges) {
          if (edge._cfg?.id?.indexOf('cable') > -1) {
            // Cables with issues
            if (
              autosplicingReducer.cableIdsWithIssues?.includes(String(edge._cfg?.model?.cableId))
            ) {
              cosmosGraphContainer?.updateItem(edge, {
                style: {
                  stroke: '#ff0000',
                  lineDash: [15],
                  lineWidth: 5,
                },
                labelCfg: {
                  style: {
                    fill: '#ff0000',
                  },
                },
              });
            }
            // Cables without issues
            else {
              cosmosGraphContainer?.updateItem(edge, {
                style: {
                  stroke: getPlanCableColor(QGISCableTypeEnum[edge._cfg?.model?.cableType]),
                  lineDash: false,
                  lineWidth: edge.loopChainId ? 8 : 4,
                },
                labelCfg: {
                  style: {
                    fill: '#000000',
                  },
                },
              });
            }
          }
        }
      }
    }
  }, [autosplicingReducer.cableIdsWithIssues]);

  // If historic data gets updated, render graph and alert the user
  useEffect(() => {
    const { alertMessage } = props;
    if (autosplicingReducer.historicData?.length! > 0) {
      generateGraphData(autosplicingReducer.historicData!, 'COSMOS', 'PLAN');
      window?.scrollTo(0, 0);
      alertMessage({ body: 'Historic Design Loaded', type: 'success' });
    }
  }, [autosplicingReducer.historicData]);

  // Find either CABLE or CLOSURE on the main graph and focus
  const findMainGraphEntityByIdAndFocus = (id: string) => {
    let mainNodeClosure, mainNodeCable;

    if (cosmosGraphContainer && autosplicingReducer.diagramLayout !== 'ODIN') {
      // Try to find closure or cable with the ID
      mainNodeClosure = cosmosGraphContainer?.findById(id);
      mainNodeCable = cosmosGraphContainer.findById(`${id}-cable`);

      if (mainNodeClosure) {
        cosmosGraphContainer.fitCenter();
        cosmosGraphContainer.focusItem(mainNodeClosure, false);
      } else if (mainNodeCable) {
        cosmosGraphContainer.fitCenter();
        cosmosGraphContainer.focusItem(mainNodeCable, false);
      } else {
        return false;
      }
    }
  };

  const focusGraphEntity = (id: string, type: GraphEntity) => {
    let cosmosNode, odinNode;

    if (cosmosGraphContainer && autosplicingReducer.diagramLayout !== 'ODIN') {
      cosmosNode = cosmosGraphContainer.findById(type === 'CLOSURE' ? id : `${id}-cable`);
      if (cosmosNode) {
        cosmosGraphContainer.fitCenter();
        cosmosGraphContainer.focusItem(cosmosNode, false);
      }
    }
    if (odinGraphContainer && autosplicingReducer.diagramLayout !== 'COSMOS') {
      odinNode = odinGraphContainer.findById(type === 'CLOSURE' ? id : `${id}-cable`);
      if (odinNode) {
        odinGraphContainer.fitCenter();
        odinGraphContainer.focusItem(odinNode, false);
      }
    }
  };

  // Update URL when closure/cable is searched, or flush on form reset.
  const updateURLParams = (removeURLParams?: boolean) => {
    let urlPath = `/${PROJECT_MODULE}/Connection/`;
    const { history } = props;

    if (formData && formData?.closure_id && !removeURLParams) {
      urlPath += `${formData?.closure_id}`;

      if (formData?.cable_id) {
        urlPath += `/${formData?.cable_id}`;
      }

      history.replace({
        pathname: urlPath,
        search: '',
      });
    } else {
      history.replace({
        pathname: urlPath,
        search: '',
      });
    }
  };

  // When focusedGraphEntity changes, move the viewport there and zoom.
  useEffect(() => {
    if (autosplicingReducer.focusedGraphEntity) {
      focusGraphEntity(
        autosplicingReducer.focusedGraphEntity?.id,
        autosplicingReducer.focusedGraphEntity?.type,
      );
    }
  }, [autosplicingReducer.focusedGraphEntity]);

  // When Plan/Build graph display mode is changed, change rendering style for the graph
  const changeGraphDisplayMode = (displayMode: GraphDisplayMode) => {
    updateAutosplicingReducer({
      graphDisplayMode: displayMode,
    });

    if (autosplicingReducer.latestData?.length) {
      generateGraphData(autosplicingReducer.latestData!, 'COSMOS', displayMode);
    }
  };

  // Dynamically resize Graph on container resize - when collapsing Action sidebar
  useEffect(() => {
    if (cosmosGraphContainer && autosplicingReducer.latestData?.length) {
      if (cosmosGraphRef && cosmosGraphRef.current) {
        let width = window.getComputedStyle(cosmosGraphRef.current!).width;
        let height = window.getComputedStyle(cosmosGraphRef.current!).height;
        cosmosGraphContainer.changeSize(
          Number(width.replace('px', '')),
          Number(height.replace('px', '')),
        );
      }
    }
  }, [autosplicingReducer.cableDiagramMaximized, autosplicingReducer.latestData]);

  // When Diagram Layout changes, we want to reset the focused node and focus the visible graph
  // to the starting closure.
  useEffect(() => {
    if (formData && formData.closure_id) {
      updateAutosplicingReducer({
        focusedGraphEntity: undefined,
      });
      focusGraphEntity(formData.closure_id, 'CLOSURE');
    }
  }, [autosplicingReducer.diagramLayout, autosplicingReducer.latestData]);

  /**
   * Find Odin Closure Record by ExternalRef number.
   * @param queryItem
   */
  const getOdinFeatureByExternalRef = (queryItem: any) => {
    const { searchRecordsByExternalId } = props;

    updateLoadingIndicators({ isLoadingSelectedGraphEntity: true });

    searchRecordsByExternalId(
      {
        featureIds: [queryItem.id],
        featureType: queryItem.type,
        entityName: 'Feature',
        associatedEntities: ['CableConnection'],
      },
      async (res: any) => {
        updateLoadingIndicators({ isLoadingSelectedGraphEntity: false });

        if (res && res.data.data.length) {
          res.data.data[0].properties.source = queryItem.source;

          updateAutosplicingReducer({
            selectedEntityOdinRecord: res.data.data[0],
          });
        } else {
          updateAutosplicingReducer({
            selectedEntityOdinRecord: undefined,
          });
        }
      },
    );
  };

  const graphEntityClickEvent = (item: any) => {
    const graphFeature = {
      id: String(item.item._cfg.model.entityId),
      type: item.item._cfg.type === 'node' ? 'CLOSURE' : 'CABLE',
      source: item.item._cfg.model.dataSource,
    } as TAutosplicingGraphEntity;

    getOdinFeatureByExternalRef(graphFeature);

    updateAutosplicingReducer({
      activeTab: '1',
      selectedEntity: graphFeature,
    });
  };

  const getTemplateDifference = () => {
    getAutosplicingTemplateDifference(
      { closureId: formData?.closure_id || '', cableId: formData?.cable_id || '' },
      async (res: any) => {
        generateGraphData(
          res?.data?.data?.latestFile?.data,
          'COSMOS',
          autosplicingReducer.graphDisplayMode!,
        );

        generateGraphData(
          res?.data?.data?.previousFile?.data || [],
          'ODIN',
          autosplicingReducer.graphDisplayMode!,
        );

        if (res?.data?.data?.previousFile?.data) {
          updateAutosplicingReducer({
            diagramLayout: 'COSMOS',
          });
        }
      },
    );
  };

  const generateGraphData = async (
    cableConnections: Array<any>,
    graphContainerName: FeatureSource,
    graphMode: GraphDisplayMode,
  ) => {
    if (cableConnections) {
      let allClosures: Array<any> = [];
      let allLoopIds: Array<string> = [];
      let filteredLoopIds: Array<string> = [];
      let allEdges: Array<any> = [];

      const cableHasIssues = (cableId: any) => {
        if (cableId && autosplicingReducer.cableIdsWithIssues!.includes(String(cableId))) {
          return true;
        } else {
          return false;
        }
      };

      /* Get all loop path ids */
      for (const elem of cableConnections) {
        if (elem.loopPathId) {
          allLoopIds.push(elem.loopPathId);
        }
      }

      // remove loop chains that are < 2
      allLoopIds?.map((loopId: string) => {
        const foundItem = allLoopIds.filter((loop: any) => loop === loopId);
        if (foundItem && foundItem.length > 1) {
          filteredLoopIds.push(foundItem[0]);
        }
      });

      const uniqueLoopIds = [...new Set([...filteredLoopIds])];

      for (const elem of cableConnections) {
        let loopNumber: string | undefined = undefined;
        if (uniqueLoopIds.includes(elem.loopPathId)) {
          loopNumber = String(uniqueLoopIds.indexOf(elem.loopPathId) + 1);
        }

        /* From Closure*/
        allClosures.push({
          loopChainId: loopNumber ? loopNumber : undefined,
          id: String(elem.fromClosure),
          entityId: String(elem.fromClosure),
          dataSource: graphContainerName,
          label:
            graphMode === 'PLAN'
              ? QGISClosureTypeEnum[elem.fromClosureType] + '\n' + String(elem.fromClosure)
              : QGISClosureTypeEnum[elem.fromClosureType] +
                '\n' +
                String(elem.fromClosure) +
                '\n' +
                'BS:' +
                (elem.fromClosureBuildStatus ? String(elem.fromClosureBuildStatus) : '-'),
          type: String(elem.fromClosureType),
          style:
            graphMode === 'PLAN'
              ? getPlanClosureStyle(QGISClosureTypeEnum[elem.fromClosureType])
              : getBuildClosureStyle(elem.fromClosureBuildStatus),
          labelCfg: {
            style: {
              fontFamily: 'Arial',
              fill:
                graphMode === 'PLAN'
                  ? getPlanClosureStyle(QGISClosureTypeEnum[elem.fromClosureType]).color
                  : getBuildClosureStyle(elem.fromClosureBuildStatus).color,
            },
          },
        });

        /* To Closure*/
        allClosures.push({
          loopChainId: loopNumber,
          id: String(elem.toClosure),
          entityId: String(elem.toClosure),
          dataSource: graphContainerName,
          label:
            graphMode === 'PLAN'
              ? QGISClosureTypeEnum[elem.toClosureType] + '\n' + String(elem.toClosure)
              : QGISClosureTypeEnum[elem.toClosureType] +
                '\n' +
                String(elem.toClosure) +
                '\n' +
                'BS:' +
                (elem.toClosureBuildStatus ? String(elem.toClosureBuildStatus) : '-'),
          type: String(elem.toClosureType),
          style:
            graphMode === 'PLAN'
              ? getPlanClosureStyle(QGISClosureTypeEnum[elem.toClosureType])
              : getBuildClosureStyle(elem.toClosureBuildStatus),
          labelCfg: {
            style: {
              fontFamily: 'Arial',
              fill:
                graphMode === 'PLAN'
                  ? getPlanClosureStyle(QGISClosureTypeEnum[elem.toClosureType]).color
                  : getBuildClosureStyle(elem.toClosureBuildStatus).color,
            },
          },
        });

        /* Cables */
        allEdges.push({
          id: String(elem.cable) + '-cable',
          cableId: elem.cable,
          entityId: String(elem.cable),
          loopChainId: loopNumber,
          source: String(elem.fromClosure),
          target: String(elem.toClosure),
          cableType: elem.cableType,
          dataSource: graphContainerName,
          label: constructCableTitle(elem, loopNumber, graphMode),
          size: 5,
          labelCfg: {
            autoRotate: true,
            refY: 18,
            refX: -25,
            style: {
              fill: '#000000',
              fontFamily: 'Arial',
            },
          },
          style: {
            lineWidth: loopNumber ? 8 : 4,
            stroke:
              graphMode === 'PLAN'
                ? getPlanCableColor(QGISCableTypeEnum[elem.cableType])
                : getBuildClosureStyle(elem.cableBuildStatus).fill,
            opacity: 1,
            fontSize: 7,
          },
        });
      }

      generateGraph({ nodes: allClosures, edges: allEdges }, graphContainerName);
    }
  };

  async function generateGraph(
    diagramData: TAutosplicingGraphNodeEdgeData,
    graphContainerName: FeatureSource,
  ): Promise<any> {
    const graphConfig = {
      container: cosmosGraphRef?.current!,
      modes: {
        default: [
          {
            type: 'drag-canvas',
          },
          'zoom-canvas',
        ],
      },
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesep: 15,
        ranksep: 270,
        controlPoints: true,
      },
      defaultNode: {
        type: 'node',
        size: 90,
        labelCfg: {
          style: {
            fontSize: 22,
            fontWeight: 600,
            padding: 15,
            cursor: 'pointer',
          },
        },
        style: {
          width: 150,
          stroke: '#72CC4A',
          cursor: 'pointer',
        },
      },
      defaultEdge: {
        type: 'polyline',
        style: {
          cursor: 'pointer',
        },
        labelCfg: {
          position: 'end',
          style: { fontSize: 20, cursor: 'pointer' },
        },
      },
      plugins: [
        new G6.Minimap({
          container: cosmosGraphMinimapRef?.current!,
          size: [130, 85],
        }),
      ],
    };

    if (graphContainerName === 'COSMOS') {
      if (cosmosGraphContainer !== null) {
        cosmosGraphContainer.destroy();
        cosmosGraphContainer = new G6.Graph(graphConfig);
        cosmosGraphContainer?.data(diagramData);
        G6.Util.processParallelEdges(diagramData.edges, 120, 'polyline');
        cosmosGraphContainer?.clear();
        cosmosGraphContainer?.render();
      } else {
        cosmosGraphContainer = new G6.Graph(graphConfig);
        cosmosGraphContainer?.data(diagramData);
        G6.Util.processParallelEdges(diagramData.edges, 120, 'polyline');
        cosmosGraphContainer?.render();
      }

      const debouncedCosmosNodeOnMouseEnter = debounce((e: any) => {
        const model = e.item.getModel();
        const { x, y } = model;
        const point = cosmosGraphContainer.getClientByPoint(x, y);
        const zoom = cosmosGraphContainer.getZoom();

        setSplicingTooltip({
          source: 'COSMOS',
          x: point.x + zoom * 80,
          y: point.y,
          closureId: model?.id,
        });
      }, 500);

      cosmosGraphContainer.on('edge:mouseenter', (e: any) => {
        const model = e.item.getModel();
        highlightGraphChain(model, cosmosGraphContainer);
      });

      cosmosGraphContainer.on('edge:mouseleave', (e: any) => {
        resetGraphChainsHighlight(cosmosGraphContainer);
      });

      cosmosGraphContainer.on('node:mouseenter', (e: any) => {
        debouncedCosmosNodeOnMouseEnter(e);
      });

      cosmosGraphContainer.on('node:mouseleave', (e: any) => {
        debouncedCosmosNodeOnMouseEnter.cancel();
        setSplicingTooltip(null);
      });

      cosmosGraphContainer?.on('click', (e: any) => {
        if (e.item) {
          graphEntityClickEvent(e);
        }
      });

      cosmosGraphContainer?.on('wheelzoom', (e: any) => {
        if (autosplicingReducer.focusedGraphEntity !== undefined) {
          updateAutosplicingReducer({
            focusedGraphEntity: undefined,
          });
        }
      });

      // When loading graph, zoom into the searched closure id
      cosmosGraphContainer?.on('afterrender', () => {
        if (formData && formData?.closure_id && cosmosGraphContainer) {
          cosmosGraphContainer?.zoom(0.3);
          const node = cosmosGraphContainer?.findById(formData?.closure_id);
          if (node) {
            cosmosGraphContainer?.fitCenter();
            cosmosGraphContainer?.focusItem(node, false);
          }
        }
      });
    }

    if (graphContainerName === 'COSMOS' && cosmosGraphMinimapRef?.current?.childNodes.length > 1) {
      cosmosGraphMinimapRef?.current?.removeChild(cosmosGraphMinimapRef?.current?.lastChild);
    }
  }

  async function fetchSplicingTemplates(formData: any): Promise<any> {
    const { searchRecordsByExternalId } = props;
    updateURLParams();

    updateAutosplicingReducer({
      selectedEntity: undefined,
      focusedGraphEntity: undefined,
      cosmosGraphTooltipVisible: false,
      historicData: [],
    });

    const closureId = formData.closure_id;
    const cableId = formData.cable_id;

    if (closureId) {
      /* 1. Get Closure type ******************************************/
      searchRecordsByExternalId(
        {
          featureIds: [closureId],
          featureType: 'CLOSURE',
          entityName: 'Feature',
          associatedEntities: ['CableConnection'],
        },
        async (res: any) => {
          if (res && res.data.data) {
            let closureType = getProperty(res.data.data[0], 'ClosureType');
            closureType = closureTypes[closureType];

            updateAutosplicingReducer({
              closureType: closureType,
              tracedClosureRecordId: res.data?.data[0]?.id,
            });

            getOdinFeatureByExternalRef({
              id: closureId,
              type: 'CLOSURE',
              source: 'COSMOS',
            });

            const payload = {
              cableTypes: formData.cable_types
                ? formData.cable_types.map((elem: string) => Number(elem))
                : [],
              qgisFilters: {
                includeOnHold: !['L0', 'L1'].includes(closureType),
              },
            };

            // If closureType is L0 or L1 don't include On hold features.
            if (['L0', 'L1'].includes(closureType)) {
              form.setFieldsValue({ onhold_features: false });
            }
            // For other closure types, we show On hold features.
            else {
              form.setFieldsValue({ onhold_features: true });
            }

            /* 2. Get Templates ******************************************/
            getTemplates(
              { cableId: cableId, closureId: closureId, payload: payload },
              async (res: any) => {
                if (['L0'].includes(closureType) && !cableId) {
                  const pathName = res?.data?.data?.cableConnectionTemplate?.pathName;

                  /* 3. If closure is L0 and no cables, get Single template by pathname **********/
                  getTemplateByPathname({ pathname: pathName }, (res: any) => {
                    generateGraphData(
                      res?.data?.data?.data,
                      'COSMOS',
                      autosplicingReducer.graphDisplayMode!,
                    );
                  });
                } else {
                  /* 3. Otherwise get Template difference ***********************/
                  getTemplateDifference();
                }
              },
            );
          }
        },
      );
    }
  }

  useEffect(() => {
    if (formData) {
      fetchSplicingTemplates(formData);
      updateAutosplicingReducer({
        focusedGraphEntity: {
          id: formData.closure_id,
          type: 'CLOSURE',
        },
      });
    }
  }, [formData]);

  function isProcessingRequests() {
    if (allJobs) {
      return allJobs?.length > 0;
    }

    return autosplicingReducer.resetProcessing;
  }

  return (
    <Layout style={{ padding: '20px' }}>
      <Content>
        <AutosplicingHelpDrawer />
        <RecordQuickViewDrawer
          recordId={recordReducer.currentRecordId!}
          visible={recordReducer.showPreview}
          onClose={() => modifyDbRecordState({ showPreview: false })}
        />
        <SplicingTooltip data={splicingTooltip!} />
        <AutosplicingResetDrawer />

        {autosplicingReducer.enteredClosureId ? (
          <JobsStatus
            filters={{
              metadataOr: autosplicingReducer.enteredClosureId
                ? [
                    {
                      closureId: Number(autosplicingReducer.enteredClosureId!),
                    },
                    {
                      rootClosureId: Number(autosplicingReducer.enteredClosureId!),
                    },
                  ]
                : [],
            }}
          />
        ) : (
          <></>
        )}
        <Row>
          {/* DIAGRAMS */}
          <Col span={autosplicingReducer.cableDiagramMaximized ? 23 : 17}>
            <Card
              title={
                <Row>
                  <Col span={14}>
                    <Tag
                      color={autosplicingReducer.historicData?.length! > 0 ? '' : 'green'}
                      style={{ fontSize: '1.1em', fontWeight: 'normal' }}
                    >
                      {autosplicingReducer.historicData?.length! > 0
                        ? 'Historic Design'
                        : 'Latest Design'}
                    </Tag>
                  </Col>

                  <Col span={10} style={{ textAlign: 'right' }}>
                    <Search
                      size="small"
                      placeholder="Quick Search"
                      allowClear
                      disabled={
                        !autosplicingReducer.latestData?.length ||
                        !formData?.closure_id ||
                        isProcessingRequests()
                      }
                      onSearch={(e: any) => findMainGraphEntityByIdAndFocus(e)}
                      style={{ marginRight: 10, width: 170 }}
                    />
                    <Tooltip title="Graph Mode">
                      <Select
                        size="small"
                        defaultValue="PLAN"
                        disabled={!autosplicingReducer.latestData?.length || isProcessingRequests()}
                        onChange={(e: any) => changeGraphDisplayMode(e)}
                      >
                        <Select.Option value="PLAN">Plan</Select.Option>
                        <Select.Option value="BUILD">Build</Select.Option>
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              }
            >
              <div
                style={{
                  height: '82vh',
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: 0,
                  borderRadius: 5,
                }}
              >
                {loadingIndicators?.isLoadingTemplates && allJobs!.length < 1 ? (
                  <Row style={{ paddingTop: 280, textAlign: 'center' }}>
                    <Col span={24}>
                      <Spin size="large" />
                    </Col>
                    <Col span={24} style={{ marginTop: 20 }}>
                      <span style={{ fontSize: '1.5em' }}>Loading data ...</span>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}

                {!autosplicingReducer.latestData?.length &&
                !loadingIndicators?.isLoadingTemplatesError &&
                allJobs!.length < 1 &&
                !loadingIndicators?.isLoadingTemplates ? (
                  <Result
                    style={{ paddingTop: 200 }}
                    status="info"
                    title="Trace to generate cable diagram."
                    subTitle={
                      <div style={{ marginTop: 20 }}>
                        <span>Use Trace dialog on the right to generate graph</span>
                        <br />
                        <span>by providing closure id and / or cable id.</span>
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
                {loadingIndicators?.isLoadingTemplatesError &&
                !loadingIndicators?.isLoadingTemplates ? (
                  <Result
                    style={{ paddingTop: 200 }}
                    status="error"
                    title="Missing cable connections, check designs in QGIS."
                  />
                ) : (
                  <></>
                )}

                {isProcessingRequests() ? (
                  <Result
                    style={{ paddingTop: 200 }}
                    status="warning"
                    title="There are jobs running for this closure."
                  />
                ) : (
                  <></>
                )}

                <Row
                  gutter={[16, 16]}
                  style={{
                    opacity: loadingIndicators?.isLoadingTemplates ? 0 : 1,
                  }}
                >
                  {/* COSMOS GRAPH ************************************/}
                  <CosmosGraph
                    autosplicingReducer={autosplicingReducer}
                    updateAutosplicingReducer={updateAutosplicingReducer}
                    graphRefs={graphRefs}
                  />

                  {/* ODIN GRAPH ************************************/}
                  <OdinGraph
                    autosplicingReducer={autosplicingReducer}
                    updateAutosplicingReducer={updateAutosplicingReducer}
                    graphRefs={graphRefs}
                  />
                </Row>
              </div>
            </Card>
          </Col>

          {/* ACTION SIDEBAR */}
          <AutosplicingSidebar
            fetchSplicingTemplates={fetchSplicingTemplates}
            getTemplateDifference={getTemplateDifference}
            updateURLParams={updateURLParams}
            alertMessage={alertMessage}
            form={form}
          />

          {/* CableConnectionTable */}
          <Col span={24} style={{ marginBottom: 20 }}>
            {!autosplicingReducer.selectedEntityOdinRecord ||
            loadingIndicators?.isLoadingTemplates ||
            autosplicingReducer.resetProcessing ? (
              <div>
                {loadingIndicators?.isLoadingTemplates || autosplicingReducer.resetProcessing ? (
                  <Card>
                    <Skeleton active />
                  </Card>
                ) : (
                  <Card>
                    <Result title="Select a closure from the graph" />
                  </Card>
                )}
              </div>
            ) : (
              <Card>
                <Tabs>
                  <TabPane
                    tab="Cable Connections"
                    key="1"
                    disabled={shouldDisableCableConnections(autosplicingReducer)}
                    destroyInactiveTabPane
                  >
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#fff',
                        padding: 10,
                        borderRadius: 5,
                      }}
                    >
                      <AssociationDataTable
                        title={'Cable connections'}
                        record={autosplicingReducer.selectedEntityOdinRecord!}
                        moduleName={'ProjectModule'}
                        entityName={'CableConnection'}
                      />
                    </div>
                  </TabPane>
                  <TabPane
                    tab="Fiber Connections"
                    key="2"
                    disabled={shouldDisableCableConnections(autosplicingReducer)}
                    destroyInactiveTabPane
                  >
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: '#fff',
                        padding: 10,
                        borderRadius: 5,
                      }}
                    >
                      <ClosureFiberSplicingTable
                        externalRef={Number(autosplicingReducer.selectedEntity?.id!)}
                        record={autosplicingReducer.selectedEntityOdinRecord!}
                        enableSplicingUploader
                      />
                      {/*<FiberSplicingMatrixGridView*/}
                      {/*  featureId={autosplicingReducer.selectedEntity?.id!}*/}
                      {/*  featureType={autosplicingReducer.selectedEntity?.type!}*/}
                      {/*  record={autosplicingReducer.selectedEntityOdinRecord!}*/}
                      {/*/>*/}
                    </div>
                  </TabPane>

                  <TabPane tab="File Uploads" key="3" destroyInactiveTabPane>
                    <AssociationDataTable
                      title="File Uploads"
                      hideViewOptions={true}
                      thumbnailSize={2}
                      record={autosplicingReducer.selectedEntityOdinRecord!}
                      moduleName="SchemaModule"
                      entityName="File"
                      // showFileCategoryForType={'AB_SCHEMATIC'}
                    />
                  </TabPane>

                  <TabPane
                    tab="Splicing Versions"
                    key="4"
                    disabled={!autosplicingReducer.selectedEntityOdinRecord}
                    destroyInactiveTabPane
                  >
                    <AwsS3BucketList
                      title="Splicing Versions"
                      listAll={true}
                      fileType={'csv'}
                      // pathNames={[
                      //   `odin-test-org-files-71231b3e-7996-474e-a66b-2dc2ba907799?pathName=auto-connect/splicing-matrices/${setClosureTypeName(
                      //     getProperty(
                      //       autosplicingReducer.selectedEntityOdinRecord,
                      //       'ClosureType',
                      //     ),
                      //   )}-${
                      //     autosplicingReducer.selectedEntityOdinRecord
                      //       ?.externalId
                      //   }-fiber-connections.csv`,
                      // ]}

                      pathNames={[
                        `odin-org-files-8c96572c-eee6-4e78-9e3f-8c56c5bb9242?listVersions=true&pathName=auto-connect/splicing-matrices/${setClosureTypeName(
                          getProperty(autosplicingReducer.selectedEntityOdinRecord, 'ClosureType'),
                        )}-${
                          autosplicingReducer.selectedEntityOdinRecord?.externalId
                        }-fiber-connections.csv`,
                        `odin-org-files-8c96572c-eee6-4e78-9e3f-8c56c5bb9242?listVersions=true&pathName=auto-connect/splicing-matrices/
                       ${autosplicingReducer.selectedEntityOdinRecord?.externalId}-fiber-connections.csv`,
                      ]}
                    />
                  </TabPane>

                  <TabPane
                    key={5}
                    tab="Design Changes"
                    disabled={!autosplicingReducer.selectedEntityOdinRecord}
                    destroyInactiveTabPane
                  >
                    <AwsS3BucketList
                      title={'Design Changes'}
                      fileType={'json'}
                      // pathNames={[
                      //   `odin-test-org-files-71231b3e-7996-474e-a66b-2dc2ba907799?pathName=odin-api-projects/network/connections/${autosplicingReducer.selectedEntityOdinRecord?.externalId}`,
                      // ]}
                      pathNames={[
                        `odin-org-files-8c96572c-eee6-4e78-9e3f-8c56c5bb9242?pathName=odin-api-projects/network/connections/${autosplicingReducer.selectedEntityOdinRecord?.externalId}`,
                      ]}
                    />
                  </TabPane>

                  <TabPane
                    tab="All splicing files"
                    key="6"
                    disabled={shouldDisableFiberSplicing(autosplicingReducer)}
                    destroyInactiveTabPane
                  >
                    <SplicingMatrices />
                  </TabPane>

                  <TabPane
                    tab="Splicing Requests"
                    key="7"
                    disabled={!autosplicingReducer?.selectedEntityOdinRecord?.id}
                    destroyInactiveTabPane
                  >
                    <AssociationDataTable
                      title={'Splicing Request for Change'}
                      record={autosplicingReducer.selectedEntityOdinRecord!}
                      moduleName={'ProjectModule'}
                      entityName={'SplicingRequestForChange'}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            )}
          </Col>

          <Col span={24} style={{ marginBottom: 20 }}>
            {autosplicingReducer.enteredClosureId ? (
              <JobsListView
                summaryView
                filters={{
                  metadataOr: [
                    { closureId: Number(autosplicingReducer.enteredClosureId) },
                    {
                      rootClosureId: Number(autosplicingReducer.enteredClosureId),
                    },
                  ],
                }}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  searchRecordsByExternalId: (params: ISearchRecordsByExternalId, cb: any) =>
    dispatch(searchRecordsByExternalId(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  updateLoadingIndicators: (params: IAutosplicingLoadingIndicators) =>
    dispatch(updateAutosplicingLoadingIndicators(params)),
  getTemplates: (params: IGetAutosplicingTemplates, cb: any) =>
    dispatch(getAutosplicingTemplates(params, cb)),
  getTemplateByPathname: (params: IGetAutosplicingTemplateByPathname, cb: any) =>
    dispatch(getAutosplicingTemplateByPathname(params, cb)),
  getAutosplicingTemplateDifference: (params: IGetAutosplicingTemplateDifference, cb: any) =>
    dispatch(getAutosplicingTemplateDifference(params, cb)),
  getSchemaByModuleAndEntity: (params: any, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  modifyDbRecordState: (params: any) => dispatch(setDbRecordState(params)),
});

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  autosplicingReducer: state.autosplicingReducer,
});

export default withRouter(connect(mapState, mapDispatch)(Autosplicing));
