import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Badge, Col, Modal, Row, Select, Skeleton } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import history from '../../../../shared/utilities/browserHistory';
import { getSchemaFromShortListBySchemaId } from '../../../../shared/utilities/schemaHelpers';
import { listUsers } from '../../../identity/store/actions';
import {
  getPipelineByStageIdRequest,
  getPipelinesByModuleAndEntity,
  IPipelineByStageId,
} from '../../../pipelines/store/actions';
import { PipelineReducerState } from '../../../pipelines/store/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../../recordsAssociations/store/reducer';
import {
  getSchemaActionsList,
  getSchemaByIdRequest,
  ISchemaActionsList,
} from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { createOrderFromLeadVisible } from '../../../workflow/store/actions';
import { getRecordByIdRequest, IGetRecordById } from '../../store/actions';
import { UPDATE_DB_RECORD_BY_ID_REQUEST } from '../../store/constants';
import { IRecordReducer } from '../../store/reducer';
import CoreForm, { Props as OdinFormModalProps } from '../Forms/CoreForm';
import { InputChangeParams } from '../Forms/FormFields';
import { initializeRecordForm } from '../Forms/store/actions';
import { renderDisabledFields } from './disableFields';
import { renderVisibleFormFields } from './filterVisibleFields';
import './styles.scss';

const uuid = uuidv4();

interface Props {
  recordReducer: IRecordReducer;
  pipelineReducer: PipelineReducerState;
  schemaReducer: ISchemaReducer;
  className?: string;
  record: DbRecordEntityTransform;
  initializeForm: any;
  getPipeline: any;
  getRecord: any;
  getPipelines: any;
  getUsers: any;
  redirectRules?: {
    [key: string]: { redirectUrl: string; redirectMessage?: string };
  };
  stageKey?: string;
  overrideInitializeForm?: (stage: PipelineStageEntity, stageName: string) => any;
  overrideInitializeFormForStageNames?: Array<string>;
  createOrderFromLeadVisible: any;
  recordAssociationReducer: IRecordAssociationsReducer;
  updateFormAdditionalInputChangeHandler?: (
    updateFormProps: OdinFormModalProps,
    params: InputChangeParams,
  ) => void;
  getAssociations: Function;
  onSuccess?: Function;
  overrideStageNameTruncation?: boolean; // Override stage name truncation
  flat?: boolean;
  small?: boolean;
  getSchemaById: Function;
  getActions: (payload: ISchemaActionsList, cb: any) => void;
}

interface State {
  pipeline: PipelineEntity | undefined;
  confirmRouteChange: boolean;
  redirectUrl: string;
  redirectMessage: string | undefined;
  addressRecord: DbRecordEntityTransform | undefined;
  contactRecord: DbRecordEntityTransform | undefined;
  hoveredStage: string | undefined;
  schemaActions: SchemaActionEntity[];
  loadingSchemaAction: boolean;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { ADDRESS, CONTACT, LEAD } = SchemaModuleEntityTypeEnums;

class Pipeline extends React.Component<Props, State> {
  private pipelineContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      pipeline: undefined,
      confirmRouteChange: false,
      redirectUrl: '',
      redirectMessage: undefined,
      addressRecord: undefined,
      contactRecord: undefined,
      hoveredStage: undefined,
      schemaActions: [],
      loadingSchemaAction: true,
    };
  }

  componentDidMount(): void {
    this.fetchSchemas();
  }

  // Highly suspect lifecycle hook, investigate later
  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.recordReducer.isRequesting !== this.props.recordReducer.isRequesting) {
      this.fetchSchemas();
    }
  }

  // Fetch schema from shortlist or fallback to API
  private fetchSchemas() {
    const { record, schemaReducer, pipelineReducer, getSchemaById } = this.props;
    if (record && !pipelineReducer.isRequesting) {
      const shortlistSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );

      if (shortlistSchema) {
        this.fetchData(shortlistSchema);
        this.getSchemaActions(shortlistSchema);
      } else {
        getSchemaById({ schemaId: record?.schemaId }, (res: SchemaEntity) => {
          if (res) {
            this.fetchData(res);
            this.getSchemaActions(res);
          }
        });
      }
    }
  }

  // Fetch pipeline and related records
  private fetchData = (schema: SchemaEntity) => {
    const { record, getPipeline, getAssociations, getActions } = this.props;

    if (schema && record && record?.stage) {
      getPipeline({ schema, stageId: record.stage.id }, (res: PipelineEntity) => {
        this.setState({
          pipeline: res,
        });
      });

      // In case of LEAD record get back the related ADDRESS & CONTACT records
      if (record.entity === `${CRM_MODULE}:${LEAD}`) {
        getAssociations(
          {
            recordId: record?.id,
            schema: schema,
            entities: [ADDRESS, CONTACT],
          },
          (res: any) => {
            if (res && res.results?.[ADDRESS]?.dbRecords?.length > 0) {
              const addressRecord = getFirstRelation(res.results, ADDRESS);
              if (addressRecord) {
                this.setState({ addressRecord: addressRecord });
              }
            }

            if (res && res.results?.[CONTACT]?.dbRecords?.length > 0) {
              const contactRecord = getFirstRelation(res.results, CONTACT);
              if (contactRecord) {
                this.setState({ contactRecord: contactRecord });
              }
            }
          },
        );
      }
    }
  };

  private getSchemaActions = (schema: SchemaEntity) => {
    const { getActions } = this.props;
    getActions({ schemaId: schema.id }, (res: any) => {
      this.setState({ loadingSchemaAction: false });
      if (res && res?.length > 0) {
        this.setState({ schemaActions: res });
      }
    });
  };

  private getPipelineState(elem: PipelineStageEntity) {
    const { record } = this.props;
    if (record && record.stage?.id === elem?.id && !elem.isFail) {
      return 'active';
    } else if (record && record.stage?.id === elem?.id && elem.isFail) {
      return 'failed';
    } else if (record && record.stage && record.stage.position > elem.position) {
      return 'complete';
    } else {
      return 'inactive';
    }
  }

  private handleStageSelected(elem: PipelineStageEntity) {
    const {
      record,
      schemaReducer,
      getUsers,
      getPipelines,
      overrideInitializeForm,
      overrideInitializeFormForStageNames,
    } = this.props;

    if (
      record &&
      !this.isPipelineDisabled(record, elem) &&
      this.getPipelineState(elem) !== 'active'
    ) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

      if (!!record && schema) {
        // Don't show Update modal if we override it and if stage.name is passed in overridden
        // stage names. Sometimes we want to prevent modal for more than one stage name.
        if (
          overrideInitializeForm &&
          overrideInitializeFormForStageNames &&
          overrideInitializeFormForStageNames?.includes(elem.name)
        ) {
          return overrideInitializeForm(elem, elem.name);
        }

        if (
          record?.entity === 'FieldServiceModule:WorkOrder' &&
          record.stage?.isFail &&
          elem.isFail
        ) {
          return Modal.confirm({
            content: 'Work Order is already cancelled',
            cancelButtonProps: { style: { display: 'none' } },
          });
        }

        getUsers();
        getPipelines({ schema: schema });

        this.initializePipelineChangeForm(schema, elem, true);
      }
    }
  }

  private initializePipelineChangeForm(
    schema: SchemaEntity,
    elem: PipelineStageEntity,
    isModalVisible: boolean,
  ) {
    const { initializeForm, record, updateFormAdditionalInputChangeHandler } = this.props;

    // @ts-ignore
    const stageUpdateAction = this.state.schemaActions.find((action) => action.stageId === elem.id);

    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: stageUpdateAction ? (
          stageUpdateAction.name
        ) : (
          <span>{`Update ${schema.entityName} stage to "${elem.name}"`}</span>
        ),
        showFormModal: isModalVisible,
        isUpdateReq: true,
        schema: schema,
        hideRecordStageField: true,
        selected: record,
        recordType: record?.type,
        sections: [{ name: schema.name, schema: schema }],
        visibleFieldOverride: renderVisibleFormFields(elem, record?.stage?.key),
        disabledFields: renderDisabledFields(elem),
        additionalInputChangeHandler: updateFormAdditionalInputChangeHandler,
        nextStageId: elem.id,
        schemaActionId: stageUpdateAction ? stageUpdateAction?.id : undefined,
        modified: [
          {
            entity: `${schema.moduleName}:${schema.entityName}`,
            schemaId: schema.id,
            stageId: elem.id,
          },
        ],
      });
    }
  }

  private handleFormSubmit(params: { event: string; results: any }) {
    const { getRecord, record, schemaReducer, redirectRules } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    switch (params.event) {
      case UPDATE_DB_RECORD_BY_ID_REQUEST:
        if (redirectRules && params.results && redirectRules[params.results.stage.key]) {
          const redirectUrl = redirectRules[params.results.stage.key]['redirectUrl'];
          const redirectMessage = redirectRules[params.results.stage.key]['redirectMessage'];
          this.setState({
            confirmRouteChange: true,
            redirectUrl,
            redirectMessage,
          });
        } else {
          getRecord({ schema, recordId: params.results.id });
          if (this.props.onSuccess) {
            this.props.onSuccess(params.results);
          }
        }
    }
  }

  private sortColumns(stage1: PipelineStageEntity, stage2: PipelineStageEntity) {
    if (stage1.position && stage2.position) {
      return stage1.position - stage2.position;
    } else {
      return 0;
    }
  }

  // If pipeline is WON and Record is LEAD and we have a related ADDRESS record
  isPipelineDisabled(record: DbRecordEntityTransform, elem: PipelineStageEntity) {
    if (
      record?.entity === `${CRM_MODULE}:${LEAD}` &&
      elem?.name === 'Won' &&
      this.state.addressRecord
    ) {
      const addrSalesStatus = getProperty(this.state.addressRecord, 'SalesStatus');

      if (
        !['ORDER', 'PRE_ORDER'].includes(addrSalesStatus) ||
        record.stage?.name === 'Won' ||
        !this.state.contactRecord
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  handleSelectBoxChange = (id: string) => {
    const { pipeline } = this.state;
    const stage = pipeline?.stages?.find((elem: any) => elem.id === id);
    if (stage) {
      this.handleStageSelected(stage);
    }
  };

  // Render Badge or Icon in the mobile Pipeline Select component
  renderBadgeOrIconInSelectBox = (elem: any) => {
    const status = this.getPipelineState(elem);

    switch (status) {
      case 'active':
        return <Badge color="blue" className="compactPipelineBadge" />;
      case 'failed':
        return <CloseOutlined style={{ color: 'red', marginRight: 5 }} />;
      case 'complete':
        return <CheckOutlined style={{ color: 'green', marginRight: 5 }} />;
      case 'inactive':
        return <Badge color="grey" className="compactPipelineBadge" />;
      default:
        return <Badge color="grey" className="compactPipelineBadge" />;
    }
  };

  render() {
    const { small = false } = this.props;
    const { pipeline, confirmRouteChange, redirectUrl, redirectMessage } = this.state;
    return (
      <>
        {/*Confirm redirecting */}
        <Modal
          title="Redirect Confirmation"
          open={confirmRouteChange}
          onOk={() => history.push(redirectUrl)}
          onCancel={() => this.setState({ confirmRouteChange: false, redirectUrl: '' })}
          okText="Yes"
          cancelText="No"
        >
          <p>
            {redirectMessage ||
              'You are about to be redirected to a new page,would you would like to proceed?'}
          </p>
        </Modal>

        {/* Pipeline Loader */}
        {!pipeline && !isMobile && !this.state.loadingSchemaAction ? (
          <Row style={{ height: 32, maxHeight: 32, padding: 0, margin: 0 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: -12 }} active />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {pipeline && (
          <Row
            style={{ marginTop: 12, marginLeft: 12, marginRight: 12 }}
            className={`PipelineContainer ${this.props.flat ? 'Flat' : ''}`}
          >
            <Col
              span={24}
              style={{
                backgroundColor: 'white',
                borderRadius: 8,
                padding: this.props.flat ? 0 : 7,
              }}
            >
              {/* DESKTOP Pipeline */}
              <div
                className="nav-progress"
                ref={this.pipelineContainerRef}
                style={{ display: isMobile || small ? 'none' : 'block' }}
              >
                {pipeline && pipeline?.stages ? (
                  pipeline.stages
                    .sort((stage1: PipelineStageEntity, stage2: PipelineStageEntity) =>
                      this.sortColumns(stage1, stage2),
                    )
                    .map((elem: PipelineStageEntity, index: number) => (
                      <div
                        onMouseEnter={() => this.setState({ hoveredStage: elem.name })}
                        onMouseLeave={() => this.setState({ hoveredStage: undefined })}
                        onClick={() => this.handleStageSelected(elem)}
                        className={`${this.getPipelineState(elem)} 
                      ${pipeline?.stages?.length! > 5 ? 'hoverable' : ''}
                      ${this.isPipelineDisabled(this.props.record!, elem) ? 'disabled' : ''}`}
                        style={{
                          opacity: this.pipelineContainerRef?.current?.clientWidth ? 1 : 0,
                          width:
                            this.pipelineContainerRef?.current?.clientWidth! /
                            pipeline?.stages?.length!,
                          paddingLeft:
                            (isMobile || small || pipeline?.stages?.length! > 5) &&
                            this.getPipelineState(elem) !== 'active' &&
                            this.getPipelineState(elem) !== 'inactive'
                              ? 10
                              : 30,
                        }}
                      >
                        {(isMobile || small || pipeline?.stages?.length! > 5) &&
                        this.getPipelineState(elem) !== 'active' &&
                        this.state.hoveredStage !== elem.name &&
                        this.getPipelineState(elem) !== 'inactive' ? (
                          <CheckOutlined style={{ marginLeft: 20 }} />
                        ) : (
                          <div
                            className="stageTitle"
                            style={{ fontSize: pipeline?.stages?.length! > 5 ? '0.9em' : '1em' }}
                          >
                            {
                              elem?.name?.length < 12 ||
                              this.state.hoveredStage === elem.name ||
                              this.props.overrideStageNameTruncation
                                ? elem?.name
                                : '...' //: elem?.name?.substring(0, 1) + '...'}
                            }
                          </div>
                        )}
                        {pipeline?.stages?.length && index < pipeline?.stages?.length ? (
                          <div className="arrow-wrapper">
                            <div className="arrow-cover">
                              <div className="arrow"></div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>

              {/* MOBILE Pipeline */}
              <div style={{ display: (isMobile || small) && pipeline ? 'block' : 'none' }}>
                <span style={{ margin: 5, fontWeight: 500 }}>Record Stage</span>
                <br />

                <Select
                  size="large"
                  style={{
                    width: '98%',
                    margin: 5,
                    display: pipeline?.stages?.length === 0 ? 'none' : 'block',
                  }}
                  defaultValue={this.props.record?.stage?.id}
                  disabled={pipeline?.stages?.length === 0}
                  onChange={(e: any) => this.handleSelectBoxChange(e)}
                >
                  {pipeline?.stages ? (
                    pipeline?.stages
                      .sort((stage1: PipelineStageEntity, stage2: PipelineStageEntity) =>
                        this.sortColumns(stage1, stage2),
                      )
                      .map((elem: PipelineStageEntity, index: number) => (
                        <Select.Option value={elem.id}>
                          {this.renderBadgeOrIconInSelectBox(elem)}
                          <span style={{ verticalAlign: 'top' }}>{elem.name}</span>
                        </Select.Option>
                      ))
                  ) : (
                    <></>
                  )}
                </Select>
              </div>
            </Col>
            <CoreForm
              type="MODAL"
              formUUID={uuid}
              onSubmitEvent={(params: { event: string; results: any }) =>
                this.handleFormSubmit(params)
              }
            />
          </Row>
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  pipelineReducer: state.pipelineReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getPipeline: (params: IPipelineByStageId, cb: any) =>
    dispatch(getPipelineByStageIdRequest(params, cb)),
  getRecord: (payload: IGetRecordById) => dispatch(getRecordByIdRequest(payload)),
  createOrderFromLeadVisible: () => dispatch(createOrderFromLeadVisible()),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  getActions: (payload: ISchemaActionsList, cb: any) => dispatch(getSchemaActionsList(payload, cb)),
});

export default connect(mapState, mapDispatch)(Pipeline);
