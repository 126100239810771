import { Card, CardList, Classes, Icon, Tag } from "@blueprintjs/core"
import { LinksViewProps } from "./types";

export const ListView = ({ links, onLinkClick }: LinksViewProps) => {
  return (
    <CardList bordered={false}>
      {links.map((link) => {
        const linkedSchema = link.childSchema || link.parentSchema;
        return (
          <Card key={link.id} interactive>
            <span>{linkedSchema?.moduleName} {linkedSchema?.entityName}</span>
            <Tag fill={false}>{link.type}</Tag>
            <span className="actions">
              <Icon
                icon="edit"
                className={Classes.TEXT_MUTED}
                onClick={() => onLinkClick(link)}
              />
            </span>
          </Card>
        );
      })}
    </CardList>
  )
}