import { Col, Row } from 'antd';
import React from 'react';
import Typography from '../../core/Typography';
import './styles.scss';
import { Tooltip } from '@blueprintjs/core';

interface Props {
  record: any;
  small?: boolean;
  truncate?: number;
  borderless?: boolean;
}

const TableCellStageTag: React.FC<Props> = (props: Props) => {
  const { record, small, borderless } = props;
  const truncateIfNecessary = (text: string | undefined) => {
    if (props.truncate && text && text.length > props.truncate) {
      return (
        <Tooltip hoverOpenDelay={1500} fill content={text}>
          {text.slice(0, props.truncate) + '...'}
        </Tooltip>
      );
    }
    return text;
  };

  return (
    <Row
      className={`TableCellStageTag ${record?.stage?.isFail ? 'isFail' : ''} ${
        record?.stage?.isSuccess ? 'isSuccess' : ''
      } ${record?.stage?.isTriage ? 'isTriage' : ''} ${
        record?.stage?.isDefault ? 'isDefault' : ''
      } ${small ? 'small' : ''} ${borderless ? 'borderless' : ''}`}
      align="middle"
    >
      <Col span={24}>
        <Typography ellipsis style={{ fontSize: small ? 12 : 'auto' }}>
          {truncateIfNecessary(record?.stage?.name) || 'Unknown'}
        </Typography>
      </Col>
    </Row>
  );
};
export default TableCellStageTag;
