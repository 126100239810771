import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import './styles.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { getOdinSchemaByEntity } from '../../../shared/utilities/schemaHelpers';

type HEX = `#${string}`;

interface Props {
  moduleName: string;
  entityName: string;
  overrideIcon?: string;
  overrideIconColor?: string;
  inverted?: boolean;
  style?: any;
  iconContainerStyle?: any;
}

const defaultIconColor: HEX = '#8f9490';

const ModuleEntityIcon: React.FC<Props> = (props: Props) => {
  const { moduleName, entityName, inverted, overrideIcon, overrideIconColor } = props;

  const [icon, setIcon] = useState<string | undefined>(undefined);
  const [iconColor, setIconColor] = useState<string | undefined>(undefined);

  useEffect(() => {
    // User can override the icon and color, like on schema settings where we show interactive styling
    if (overrideIcon && overrideIconColor) {
      setIcon(overrideIcon);
      setIconColor(overrideIconColor);
    }
    // Otherwise, fetch the schema from shortlist or API and set the icon name and color
    else if (moduleName && entityName) {
      getSchema(moduleName, entityName);
    }
  }, [moduleName, entityName]);

  const getSchema = async (moduleName: string, entityName: string) => {
    if (moduleName && entityName) {
      const schema = await getOdinSchemaByEntity(moduleName, entityName);
      if (schema) {
        setIcon(schema.icon);
        setIconColor(schema.iconColor);
      }
    }
  };

  // On every override update, update the local component state
  useEffect(() => {
    if (overrideIcon) {
      setIcon(overrideIcon);
    }
    if (overrideIconColor) {
      setIconColor(overrideIconColor);
    }
  }, [overrideIcon, overrideIconColor]);

  const renderModuleIcon = () => {
    if (icon) {
      return (
        <i
          className={`bi bi-${icon}`}
          style={{ color: inverted ? iconColor : 'white', ...props.style }}
        />
      );
    } else {
      // Default icon
      return (
        <i
          className="bi bi-app"
          style={{ color: inverted ? iconColor : 'white', ...props.style }}
        />
      );
    }
  };

  return (
    <Tooltip mouseEnterDelay={1} title={entityName}>
      <div
        className={`moduleEntityIcon ${inverted ? 'compact' : ''}`}
        style={{
          backgroundColor: inverted ? 'white' : iconColor || defaultIconColor,
          color: inverted ? iconColor : 'white',
          border: inverted ? `1px solid ${iconColor}` : 'none',
          ...props.iconContainerStyle,
        }}
      >
        {renderModuleIcon()}
      </div>
    </Tooltip>
  );
};

export default ModuleEntityIcon;
