import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { MapReducerUpdate, updateMapState } from '../../../../../../core/gis/store/actions';
import { MapReducer } from '../../../../../../core/gis/store/reducer';
import { ISchemaReducer } from '../../../../../../core/schemas/store/reducer';
import { canUserCreateRecord } from '../../../../../../shared/permissions/rbacRules';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../shared/utilities/schemaHelpers';

interface Props {
  mapReducer: MapReducer;
  userReducer: any;
  schemaReducer: ISchemaReducer;
  updateMap: (params: MapReducerUpdate) => {};
}

interface State {}

class AddHazardInterface extends React.Component<Props, State> {
  handleSelection(type: 'line' | 'point') {
    const { updateMap } = this.props;

    if (type === 'point') {
      updateMap({
        addPointFlow: false,
        addEnabled: true,
      });
    } else {
      updateMap({
        addLineFlow: false,
        addEnabled: false,
        addLineEnabled: true,
      });
    }
  }

  render() {
    const { updateMap, mapReducer, userReducer, schemaReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      'ProjectModule',
      'Feature',
    );
    const { addEnabled } = mapReducer;

    return (
      <div>
        {/* Add Hazard Floating Button */}
        {addEnabled ? (
          <></>
        ) : (
          <Button
            icon={<i className="bi bi-cone-striped" />}
            shape="circle"
            size="large"
            type="primary"
            className="floatingButton floatingAddButton floatingAddHazardButton"
            disabled={!canUserCreateRecord(userReducer, schema) || mapReducer.drawEnabled}
            onClick={() =>
              updateMap({
                isAddingHazard: true,
                infoPopupVisible: false,
                addPointConfirmVisible: false,
                addEnabled: true,
                isCreatingRFC: false,
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

export default connect(mapState, mapDispatch)(AddHazardInterface);
