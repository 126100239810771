import { Button, Section } from '@blueprintjs/core';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import {
  ISchemaActionUpdate,
  updateSchemaAction,
} from '../../../../../../core/schemas/store/actions';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import SchemaActionHelpDrawer from '../../SchemaActionHelpDrawer';
import SchemaActionBuilder from './SchemaActionBuilder';
import SchemaActionPermissions from './SchemaActionPermissions';

interface Props {
  schema: SchemaEntity | undefined;
  schemaAction: SchemaActionEntity | undefined;
  updateAction: (payload: ISchemaActionUpdate, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const SchemaActionConfiguration: FC<Props> = (props: Props) => {
  const { schema, schemaAction, updateAction, alertMessage } = props;

  const [activeTab, setActiveTab] = useState<'ACTION_BUILDER' | 'PERMISSIONS'>('ACTION_BUILDER');
  const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState<boolean>(false);

  const closeHelpDrawer = () => {
    setIsHelpDrawerOpen(false);
  };

  return (
    <>
      <SchemaActionHelpDrawer isOpen={isHelpDrawerOpen} onClose={closeHelpDrawer} />
      <Section
        style={{ height: 'calc(100vh - 100px)' }}
        rightElement={
          <Button
            minimal
            intent="primary"
            style={{ marginLeft: 10 }}
            text="Help"
            icon="help"
            onClick={() => setIsHelpDrawerOpen(true)}
          />
        }
        title={
          <Row justify="center">
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                intent="primary"
                style={{ borderRadius: '7px 0 0 7px', borderRight: 0 }}
                outlined={activeTab !== 'ACTION_BUILDER'}
                onClick={() => setActiveTab('ACTION_BUILDER')}
              >
                Action Definition
              </Button>
              <Button
                style={{ borderRadius: '0 7px 7px 0' }}
                intent="primary"
                outlined={activeTab !== 'PERMISSIONS'}
                onClick={() => setActiveTab('PERMISSIONS')}
              >
                Access Control
              </Button>
            </Col>
          </Row>
        }
      >
        {/* Action Builder */}
        <div style={{ display: activeTab === 'ACTION_BUILDER' ? 'block' : 'none' }}>
          <SchemaActionBuilder schema={schema} schemaAction={schemaAction} />
        </div>

        {/* Permissions */}
        <div style={{ display: activeTab === 'PERMISSIONS' ? 'block' : 'none' }}>
          <SchemaActionPermissions schema={schema} schemaAction={schemaAction} />
        </div>
      </Section>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionConfiguration);
