import { Icon, Section } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  getSchemaByIdRequest,
  getSingleSchemaAction,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import SchemaActionConfiguration from './SchemaActionConfiguration';
import SchemaActionLeftPanel from './SchemaActionLeftPanel';

type Props = {
  match: any;
  schemaReducer: ISchemaReducer;
  getSchemaById: (payload: any, cb?: any) => void;
  getSchemaAction: (params: { schemaActionId: string }, cb?: any) => void;
};

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

const SchemaActionDetailView: FC<Props> = (props: Props) => {
  const { schemaReducer, getSchemaById, getSchemaAction } = props;
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [schemaAction, setSchemaAction] = useState<SchemaActionEntity | undefined>(undefined);

  useEffect(() => {
    // Fetch Schema
    if (props.match.params.schemaId) {
      getSchema(props.match.params.schemaId);
    }

    // Fetch Schema Action
    if (props.match.params.schemaActionId) {
      fetchAction(props.match.params.schemaActionId);
    }
  }, []);

  const fetchAction = (schemaActionId: string) => {
    getSchemaAction({ schemaActionId: schemaActionId }, (schemaAction: SchemaActionEntity) => {
      if (schemaAction) {
        setSchemaAction(schemaAction);
      }
    });
  };

  const getSchema = (schemaId: string) => {
    const shortListSchema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);
    if (shortListSchema) {
      setSchema(shortListSchema);
    } else {
      getSchemaById({ schemaId: schemaId }, (schema: SchemaEntity) => {
        setSchema(schema);
      });
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Section
          title={
            <>
              <Link to={`/${SCHEMA_MODULE}/Schema`}>
                <span>Schemas</span>
              </Link>
              <Icon icon="caret-right" />
              <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}`}>{schema?.entityName || ''}</Link>
              <Icon icon="caret-right" />
              <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}#Actions`}>
                <span>Actions</span>
              </Link>
              <Icon icon="caret-right" />
              <span>{schemaAction?.name || '...'}</span>
            </>
          }
          subtitle="Schema Manager"
          style={{ marginTop: 1 }}
          icon={
            <ModuleEntityIcon
              moduleName="SchemaModule"
              entityName="Schema"
              overrideIcon="diagram-3-fill"
              overrideIconColor="#1777ff"
              iconContainerStyle={{ padding: '7px 10px' }}
            />
          }
        />
      </Col>

      <Col span={6}>
        <SchemaActionLeftPanel
          schema={schema}
          schemaAction={schemaAction}
          refreshAction={fetchAction}
        />
      </Col>

      <Col span={18}>
        <SchemaActionConfiguration schema={schema} schemaAction={schemaAction} />
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  getSchemaAction: (params: { schemaActionId: string }, cb: any) =>
    dispatch(getSingleSchemaAction(params, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

export default withRouter(connect(mapState, mapDispatch)(SchemaActionDetailView));
