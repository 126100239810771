import { Button } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import { connect } from 'react-redux';
import MandateUpdate from '../../../../../containers/CrmModule/containers/ContactIdentity/MandateUpdate';
import OrderList from '../../../../../containers/OrderModule/containers/Order/OrdersList';
import { setInviteNewUserModalVisible } from '../../../../../core/identityUser/store/actions';
import SendDynamicEmail from '../../../../../core/notifications/components/SendDynamicEmail';
import RecordCard from '../../../../../core/records/components/RecordCard';
import AssociationDataTable from '../../../../../core/recordsAssociations/components/AssociationDataTable';
import { isBetaTester } from '../../../../../shared/permissions/rbacRules';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';
import CreateGocardlessMandate from '../../../BillingModule/components/CreateGocardlessMandate';
import InvoicePendingCallout from '../../../BillingModule/components/InvoicePendingCallout';
import PaymentMethodRefund from '../../../BillingModule/components/PaymentMethodRefund';
import PaymentMethodRefundPendingCallout from '../../../BillingModule/components/PaymentMethodRefundPendingCallout';
import WorkOrderPendingCallout from '../../../FieldServiceModule/components/WorkOrderPendingCallout';
import CasesPendingCallout from '../../../SupportModule/components/CasesPendingCallout';

interface Props {
  userReducer: any;
  setInviteNewUserModalVisible: any;
}

const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';

const { ACCOUNT, ADDRESS, PAYMENT_METHOD, BILLING_REQUEST, INVOICE } = SchemaModuleEntityTypeEnums;
const { CRM_MODULE, BILLING_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;

const ContactDetailView: FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { record, setInviteNewUserModalVisible } = useContext(DetailViewContext);

  return (
    <>
      <MandateUpdate />

      <DetailView
        showCollaborators
        showCommunicationHistory
        defaultTabKey="Summary"
        customTabs={[
          {
            key: 'Summary',
            tab: 'Summary',
          },
          {
            key: 'Billing',
            tab: 'Billing',
          },
        ]}
        headerExtras={[
          <CreateGocardlessMandate record={record} />,
          <PaymentMethodRefund record={record} />,
          <SendDynamicEmail
            buttonText="Setup Mandate"
            email={{
              to: getProperty(record, 'EmailAddress'),
              templateLabel: 'SENDGRID_DD_MISSING',
              dynamicTemplateData: {
                id: record?.id,
                recordId: record?.id,
                description: 'Setup Direct Debit - Payment info missing',
                properties: {
                  FirstName: getProperty(record, 'FirstName'),
                },
              },
            }}
          />,
          isBetaTester(userReducer) && (
            <div>
              <Button
                style={{ width: 170 }}
                intent="primary"
                outlined
                onClick={() => setInviteNewUserModalVisible(true)}
              >
                Send Registration Link
              </Button>
            </div>
          ),
        ]}
        customTabBodies={{
          Summary: (
            <div>
              <CasesPendingCallout record={record} hideEmpty={true} />
              <div style={{ marginTop: 15 }}>
                <WorkOrderPendingCallout record={record} />
              </div>
              <div style={{ marginTop: 15 }}>
                <PaymentMethodRefundPendingCallout record={record} />
              </div>
              <div style={{ marginTop: 15, marginBottom: 5 }}>
                <InvoicePendingCallout record={record} />
              </div>
              <div style={{ marginTop: 10 }}>
                <OrderList record={record} />
              </div>
            </div>
          ),
          Billing: (
            <div>
              {/* BA Association Table */}
              <AssociationDataTable
                title={PAYMENT_METHOD}
                record={record}
                moduleName={ORDER_MODULE}
                entityName={PAYMENT_METHOD}
                collapsedByDefault
                showAllRecords
              />

              {/* BA Association Table */}
              <AssociationDataTable
                title={BILLING_REQUEST}
                record={record}
                moduleName={ORDER_MODULE}
                entityName={BILLING_REQUEST}
                collapsedByDefault
                showAllRecords
              />

              {/* BA Association Table */}
              <AssociationDataTable
                title={PAYMENT_METHOD_REFUND}
                record={record}
                moduleName={ORDER_MODULE}
                entityName={PAYMENT_METHOD_REFUND}
                collapsedByDefault
                showAllRecords
              />

              {/* Invoice Association Table */}
              <AssociationDataTable
                title={INVOICE}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={INVOICE}
                collapsedByDefault
                showAllRecords
              />
            </div>
          ),
        }}
        leftColumn={[
          <RecordCard
            borderless
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ACCOUNT}
            visibleProperties={['Status', 'Type']}
          />,
          <RecordCard
            showActionMenu
            borderless
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={BILLING_MODULE}
            associatedRecordEntityName={PAYMENT_METHOD}
            visibleProperties={['BankName', 'Status']}
          />,
          <RecordCard
            borderless
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ADDRESS}
            visibleProperties={['Type', 'SalesStatus']}
          />,

          <RecordCard
            borderless
            title="Appointments"
            record={record}
            visibleProperties={['Date', 'Address', 'Contact']}
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName="Appointment"
            displayQuickView
            showCreatedBy
            hideRecordNumber
          />,
        ]}
      />
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  setInviteNewUserModalVisible: (visible: boolean) =>
    dispatch(setInviteNewUserModalVisible(visible)),
});

export default connect(mapState, mapDispatch)(ContactDetailView);
