import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { OrganizationUserCompanyEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/company/organization.user.company.entity';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { PageHeader } from '../../../../../../shared/components/PageHeader';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../../shared/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { searchString } from '../../../../../../shared/utilities/searchHelpers';
import { BlueprintNavigation } from '../../../../../../v2/shared/components/BlueprintPagination';
import { getRecordIdFromHash } from '../../../../utils/hashNavigation';
import { getErrorMessage } from '../../../../utils/errors';

import GroupDetails from '../CompanyDetails';
import { formFields } from '../FormFields';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
}

const uuid = uuidv4();

const CompanyListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<OrganizationUserCompanyEntity | undefined>(
    undefined,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [companyList, setCompanyList] = useState<OrganizationUserCompanyEntity[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  const onSelect = (e: any) => {
    setSelectedRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    const id = getRecordIdFromHash(window.location.hash);
    if (id) getCompanyById(id);
  }, [window.location.hash]);

  useEffect(() => {
    getAllCompaniesList();
  }, []);

  useEffect(() => {
    setSelectedRegions([]);
    setSelectedCompany(undefined);
  }, [currentPage]);

  useEffect(() => {
    if (companyList.length > 0) {
      let tableData: ITableData[] = Object.assign([], companyList);

      tableData = companyList.map((c: any) => ({
        key: c.id,
        name: c.name,
        description: c.description,
      }));

      // Apply search filter
      tableData = tableData.filter((c: any) => {
        return searchString(c.name, searchKey) || searchString(c.description, searchKey);
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [companyList, currentPage, searchKey, pageSize]);

  // Get selected company details
  useEffect(() => {
    if (selectedRegions.length > 0 && tableSlice.length > 0) {
      const user: any = tableSlice[selectedRegions[0].rows[0]];
      if (user && !isCompanyLoading) {
        getCompanyById(user.key);
      }
    }
  }, [selectedRegions, tableSlice]);

  const getCompanyById = (id: string) => {
    setIsCompanyLoading(true);
    httpGet(`IdentityModule/v2.0/companies/${id}`)
      .then((res) => {
        setIsCompanyLoading(false);
        setSelectedCompany(res.data.data);
        console.log('%cdebug: Selected company', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({ body: 'Could not retrieve company data. ' + message, type: 'error' });
        setIsCompanyLoading(false);
        setSelectedCompany(undefined);
        setSelectedRegions([]);
      });
  };

  const getAllCompaniesList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v2.0/companies?size=10000`);
      const companies: OrganizationUserCompanyEntity[] = res.data.data || [];
      setCompanyList(companies);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve companies list. ' + message, type: 'error' });
      setCompanyList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedCompany, tableRef, tableSlice]);

  const showCreateCompanyForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Company',
      formFields: formFields,
      entityName: 'Company',
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
      };
      createCompany({ body });
    }
  };

  const createCompany = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/companies', body);
      alertMessage({ body: 'Company created', type: 'success' });

      const newCompany = res.data.data;
      setCompanyList([...companyList, newCompany]);
      getCompanyById(newCompany.id);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create company. ' + message,
        type: 'error',
      });
    }
  };

  const onSearch = (e: any) => {
    setSelectedRegions([]);
    setSearchKey(e.target.value);
  };

  const onSelectedCompanyChange = () => {
    if (selectedCompany) {
      getCompanyById(selectedCompany.id);
    }
  };

  const onCompanyDelete = () => {
    setSelectedRegions([]);

    const id = selectedCompany?.id;
    if (id) {
      setCompanyList(companyList.filter((c) => c.id !== id));
      setSelectedCompany(undefined);
    }
  };

  const renderGroups = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Companies"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreateCompanyForm}>
                  Create Company
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedCompany ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[getColumnWidthByPercentage(30), getColumnWidthByPercentage(70)]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Company Quick View */}
          {selectedCompany && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isCompanyLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                className="detailViewParentSection"
                style={{ height: 'inherit', overflowY: 'auto' }}
                title="Company Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedRegions([]);
                      setSelectedCompany(undefined);
                    }}
                  />
                }
              >
                <GroupDetails
                  company={selectedCompany}
                  onUpdate={onSelectedCompanyChange}
                  onDelete={onCompanyDelete}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={companyList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderGroups()}</div>;
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CompanyListView);
