import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActivityCenter from '../../../core/records/components/ActivityCenter';
import DetailPanelLeft from '../../../core/records/components/DetailPanelLeft';
import RawDataRecordProperties from '../../../core/records/components/RawData/RawDataRecordProperties';
import RecordProperties from '../../../core/records/components/RecordProperties';
import { IRecordReducer } from '../../../core/records/store/reducer';
import { renderDynamicAssociationTables } from '../../../core/recordsAssociations/helpers/component-helpers';
import { ISchemaReducer } from '../../../core/schemas/store/reducer';
import CardWithTabs from '../../../shared/components/CardWithTabs';
import { renderCreateUpdateDetails } from '../../../shared/components/RecordCreateUpdateDetails';
import {
  getAllSchemaAssociationSchemas,
  getRecordFromShortListById,
} from '../../../shared/utilities/recordHelpers';
import {
  getJSONColumnsFromSchema,
  getSchemaFromShortListBySchemaId,
} from '../../../shared/utilities/schemaHelpers';

interface Props {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  match: any;
}

class PriceBookDetailView extends React.Component<Props> {
  render() {
    const { schemaReducer, recordReducer, match } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, ['Note']);

    const JSONColumns = getJSONColumnsFromSchema(schema);

    return (
      <Layout className="record-detail-view">
        <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
          <Col xs={24} sm={24} md={24} lg={6}>
            <div className="record-detail-left-panel">
              <DetailPanelLeft record={record}>
                <RecordProperties columns={1} record={record} size="small" />
                {renderCreateUpdateDetails(record)}
              </DetailPanelLeft>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={18}>
            <div className="record-detail-left-panel">
              <CardWithTabs
                title="Options"
                defaultTabKey="Activity"
                tabList={[
                  {
                    key: 'Activity',
                    tab: 'Activity',
                  },
                  {
                    key: JSONColumns?.length > 0 ? 'JSON' : '',
                    tab: JSONColumns?.length > 0 ? 'JSON' : '',
                  },
                  // ...relatedSchemas.map((elem) => ({
                  //   key: elem.entityName,
                  //   tab: elem.entityName,
                  // })),
                ]}
                tabContents={{
                  //...renderDynamicAssociations(record, relatedSchemas),
                  Activity: <ActivityCenter record={record!} schema={schema!} hideEmailTab />,
                  JSON: <RawDataRecordProperties record={record!} schema={schema!} />,
                }}
                relatedAssociations={renderDynamicAssociationTables(record, relatedSchemas)}
              />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

export default withRouter(connect(mapState)(PriceBookDetailView));
