import { AimOutlined, DownOutlined, EyeOutlined, RadiusSettingOutlined } from '@ant-design/icons';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Card, Col, Dropdown, Image, Menu, Row, Spin, Tooltip } from 'antd';
import { constantCase } from 'change-case';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import pdfIcon from '../../../../../../../assets/images/pdf.png';
import {
  IMapSetWorkItemQuickView,
  IMapUpdateWorkItems,
  MapReducerUpdate,
  resetWorkItemForm,
  setWorkItemQuickView,
  updateMapState,
  updateWorkItems,
} from '../../../../../../../core/gis/store/actions';
import { MapReducer } from '../../../../../../../core/gis/store/reducer';
import {
  getRecordByIdRequest,
  IGetRecordById,
} from '../../../../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../../../../core/records/store/reducer';
import { ISchemaReducer } from '../../../../../../../core/schemas/store/reducer';
import { httpGet } from '../../../../../../../shared/http/requests';
import { canUserGetRecord } from '../../../../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../../shared/utilities/schemaHelpers';
import FeatureBuildDoneModal from '../../../components/FeatureBuildDoneModal';
import { FEATURE_NAMES } from '../../../constants';
import { getFeatureByIdAndZoom } from '../../../helpers';
import { allowedFeatureTypesForLinkage } from '../../constants';
import '../styles.scss';
import RenderGisFeature from './RenderGisFeature';
import RenderOdinFeature from './RenderOdinFeature';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;

interface Props {
  mapReducer: MapReducer;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  updateMap: (params: MapReducerUpdate) => {};
  updateWI: (params: IMapUpdateWorkItems) => void;
  resetWIForm: Function;
  alertMessage: any;
  userReducer: any;
  visible: boolean;
  setNewQuickView: (payload: IMapSetWorkItemQuickView) => void;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
}

interface State {
  collapsed: boolean;
}

type featureSource = 'ODIN' | 'GIS';

class MapSidebarSearchFeatureResults extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { collapsed: true };
  }

  shouldComponentUpdate(nextProps: any, prevProps: any) {
    if (nextProps.mapReducer.mapSidebarVisible) {
      return true;
    }
    return false;
  }

  getFeatureType = (feature: any) => {
    // Gis feature
    if (feature?.id?.indexOf('.') > -1) {
      return feature.id?.split('.')[0].toLowerCase();
    }
    // Odin feature
    else {
      return feature.type?.toLowerCase();
    }
  };

  canFeatureCreateLinked = (feature: any) => {
    return allowedFeatureTypesForLinkage.includes(this.getFeatureType(feature)) ? true : false;
  };

  setConditionsToCreateBlockage = (fromFeature: any) => {
    const { updateMap, updateWI, resetWIForm, mapReducer } = this.props;

    let featureId: any;
    let featureType: FEATURE_NAMES;

    featureType = this.getFeatureType(fromFeature);

    if (fromFeature?.properties?.id) {
      featureId = Number(fromFeature?.properties.id);
    } else if (getProperty(fromFeature, 'ExternalRef')) {
      featureId = Number(getProperty(fromFeature, 'ExternalRef'));
    }

    updateMap({
      createLinked: {
        fromType: featureType,
        fromId: featureId,
        toType: FEATURE_NAMES.BLOCKAGE,
      },
      drawEnabled: false,
      addEnabled: true,
    });

    // Clean WI Form for clean separation between new and legacy flows
    resetWIForm();

    // Set association after
    updateWI({
      creatingBlockageFromId: fromFeature?.id,
    });
  };

  async handleViewRecord(feat: any, featureSource: featureSource) {
    const { alertMessage, updateMap, mapReducer, schemaReducer, setNewQuickView, getRecordById } =
      this.props;

    updateMap({
      isLoadingView: true,
    });

    const query =
      featureSource === 'ODIN'
        ? `ProjectModule/v1.0/db/Feature/${feat.id}`
        : `ProjectModule/v1.0/cst/Feature/${mapReducer.queryLayer}/${feat.id.split('.')[1]}`;

    await httpGet(query)
      .then((res) => {
        // GIS FEATURES -> We first retrieve record imported in Odin
        if (featureSource === 'GIS') {
          const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
            schemaReducer.shortList,
            PROJECT_MODULE,
            FEATURE,
          );

          if (shortlistSchema) {
            getRecordById({ schema: shortlistSchema, recordId: res.data?.data?.id }, (res: any) => {
              if (localStorage.getItem('ProjectModuleV2QuickView')) {
                setNewQuickView({
                  record: res,
                  visible: true,
                });
              }
              updateMap({
                recordId: res.id,
                isLoadingView: false,
                entityName: 'Feature',
              });
            });
          }
        }
        // ODIN FEATURES
        else {
          if (localStorage.getItem('ProjectModuleV2QuickView')) {
            setNewQuickView({
              record: res.data.data,
              visible: true,
            });
          }
          updateMap({
            recordId: res.data.data.id,
            isLoadingView: false,
            entityName: 'Feature',
          });
        }
      })
      .catch((err) => {
        updateMap({
          isLoadingView: false,
        });
        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: (error && error.message) || 'error loading feature',
          type: 'error',
        });
      });
  }

  renderAssociatedMedia(feature: any) {
    const files = getAllRelations(feature, 'File');

    if (feature && files) {
      return (
        <div style={{ padding: 8 }}>
          <Row>
            <Col span={24}>
              <Image.PreviewGroup>
                {files?.map((file: any, index: any) =>
                  getProperty(file, 'Mimetype') === 'image/jpeg' ? (
                    <div style={{ paddingRight: '4px', display: 'inline' }}>
                      <Image
                        style={{ paddingBottom: 2 }}
                        width={50}
                        height={50}
                        src={getProperty(file, 'Url')}
                      />
                    </div>
                  ) : (
                    <a href={getProperty(file, 'Url')} target="_blank">
                      <div style={{ paddingRight: '4px', display: 'inline' }}>
                        <Image
                          style={{
                            borderRadius: '5px',
                            border: '1px solid red',
                          }}
                          preview={false}
                          width={40}
                          height={40}
                          src={pdfIcon}
                        />
                      </div>
                    </a>
                  ),
                )}
              </Image.PreviewGroup>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  }

  renderLastModifiedFields(feature: any, featureSource: featureSource) {
    const renderFields = (updatedBy: string, updatedAt: string) => {
      return (
        <Row style={{ marginBottom: '5px' }}>
          <Col span={24} style={{ marginBottom: '5px' }}>
            <span>
              <strong>Updated by: </strong>
              {updatedBy}
            </span>
          </Col>
          <Col span={24}>
            <span>
              <strong>Updated at: </strong>
              {moment(updatedAt).format('MM/DD/YYYY hh:mm a').toUpperCase()}
            </span>
          </Col>
        </Row>
      );
    };

    if (feature && featureSource === 'ODIN') {
      if (feature.lastModifiedBy && feature.lastModifiedBy.fullName && feature.updatedAt) {
        return renderFields(feature.lastModifiedBy.fullName, feature.updatedAt);
      }
    } else if (feature && featureSource === 'GIS') {
      if (feature.properties && feature.properties.modified_at && feature.properties.modified_by) {
        return renderFields(feature.properties.modified_by, feature.properties.modified_at);
      }
    } else {
      return <></>;
    }
  }

  renderFeature(feature: any, featureSource: featureSource) {
    const { mapReducer, updateMap, userReducer, schemaReducer, alertMessage } = this.props;
    const { queryLayer } = mapReducer;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      FEATURE,
    );

    if (schema && mapReducer.queryLayer && feature) {
      /* GIS */
      let featureProperties: { [x: string]: any }[] = [];
      let featureType: string = '';

      const schemaType = schema.types.find(
        (elem) => elem.name === constantCase(mapReducer.queryLayer!),
      );

      const filteredCols = schema.columns.filter(
        (elem) => elem.schemaTypeId === schemaType!.id || !elem.schemaTypeId,
      );

      featureType = schemaType ? schemaType.name : 'UNKNOWN TYPE';

      for (const key of Object.keys(feature)) {
        const col = filteredCols.find((elem) => elem.mapping === key);
        if (col) {
          let value = feature[key];
          if (col.name === 'ExternalRef') {
            value = feature[key].split('.')[1];
          }

          if (col?.type === SchemaColumnTypes.ENUM) {
            // For enum values we want to show the label instead of the value
            const option = col.options.find((opt: SchemaColumnOptionEntity) => opt.value === value);
            if (option) {
              featureProperties.push({ [key]: option.label });
            } else {
              featureProperties.push({ [key]: value });
            }
          } else {
            featureProperties.push({ [key]: value });
          }
        }
      }

      if (feature && feature.properties) {
        if (getProperty(feature, 'noi_ref')) {
          featureProperties.push({ noi_ref: getProperty(feature, 'noi_ref') });
        }
      }

      return (
        <Col
          span={24}
          className={`sidebarFeatureSearchResult`}
          key={feature.id}
          style={{ marginBottom: 20 }}
        >
          {/* Feature Header */}
          <Card
            style={{ padding: '3px' }}
            size="small"
            title={
              <span>
                {featureSource === 'ODIN' ? feature.type : feature.id.split('.')[0].toUpperCase()}
              </span>
            }
            extra={
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '5px' }}>
                  <FeatureBuildDoneModal record={feature} />
                </div>
                <Tooltip placement="top" title="Locate Feature">
                  <Button
                    style={{ marginRight: '5px' }}
                    icon={<AimOutlined />}
                    onClick={() => {
                      const query = `type=${queryLayer}&featureId=${
                        featureSource === 'ODIN'
                          ? feature.properties.ExternalRef
                          : feature.id.split('.')[1]
                      }`;

                      updateMap({
                        mapSidebarVisible: !isMobile,
                      });
                      getFeatureByIdAndZoom(mapReducer, updateMap, alertMessage, query);
                    }}
                  />
                </Tooltip>
                <Button
                  icon={<EyeOutlined />}
                  loading={mapReducer.isLoadingView}
                  disabled={mapReducer.isLoadingView}
                  onClick={() =>
                    this.handleViewRecord(feature, featureSource) ||
                    !canUserGetRecord(userReducer, schema)
                  }
                ></Button>

                {/* Additional Actions */}
                {this.canFeatureCreateLinked(feature) ? (
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="2"
                          onClick={() => this.setConditionsToCreateBlockage(feature)}
                        >
                          Create Blockage
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button icon={<DownOutlined />} style={{ marginLeft: 5 }} />
                  </Dropdown>
                ) : (
                  <></>
                )}
              </div>
            }
          >
            <Row>
              {/* Feature Descriptions */}
              <Col span={24}>
                <Row>
                  {featureSource === 'ODIN' ? (
                    <RenderOdinFeature feature={feature} />
                  ) : (
                    <RenderGisFeature feature={feature} />
                  )}
                  <Col span={24} style={{ padding: 3 }}>
                    {this.renderAssociatedMedia(feature)}
                  </Col>
                </Row>
              </Col>
              {/* Feature Media */}
            </Row>
          </Card>
        </Col>
      );
    } else {
      return <></>;
    }
  }

  getSearchResults() {
    const { schemaReducer, recordReducer, mapReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      FEATURE,
    );

    let dataSet: any[] = [];
    let GISRecordsWithoutOdinDuplicates: any[] = [];

    /* Search Results coming back from Odin */
    if (schema && recordReducer && recordReducer?.list[schema.id]) {
      recordReducer.list[schema.id].map((feature: any) => {
        dataSet.push({
          feature,
          featureSource: 'ODIN',
        });
      });
    }

    /* Search Results coming back from GIS / Cosmos */
    if (mapReducer.features && mapReducer?.features?.length > 0) {
      GISRecordsWithoutOdinDuplicates = mapReducer.features;

      /* If there are records in Odin, filter out GIS duplicates. */
      if (schema && recordReducer && recordReducer?.list[schema.id]) {
        GISRecordsWithoutOdinDuplicates = mapReducer.features?.filter(
          (QGISfeature: any) =>
            !recordReducer?.list[schema.id].find(
              (OdinRecords: any) =>
                getProperty(OdinRecords, 'ExternalRef') === String(QGISfeature.properties.id),
            ),
        );
      }

      // Push GIS features to Dataset
      GISRecordsWithoutOdinDuplicates.map((feature: any) => {
        // Exclude polygons because we render those in another component
        if (feature.id.indexOf('polygon') === -1) {
          dataSet.push({
            feature,
            featureSource: 'GIS',
          });
        }
      });
    }

    if (dataSet?.length > 0) {
      return dataSet.map((elem) => this.renderFeature(elem.feature, elem.featureSource));
    } else {
      return (
        <Col span={24} style={{ textAlign: 'center' }}>
          <div style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
            <div style={{ marginTop: '15px' }}>
              <p>
                <h3 style={{ color: 'rgba(0, 0, 0, 0.25)' }}>No features selected</h3>
              </p>
              <p>
                Use Feature selection
                <br />
                button on map
              </p>
              <p>
                <RadiusSettingOutlined
                  style={{
                    border: '1px solid',
                    marginTop: '10px',
                    padding: '10px',
                    fontSize: '1.3em',
                    borderRadius: '50px',
                  }}
                />
              </p>
            </div>
          </div>
        </Col>
      );
    }
  }

  render() {
    const { recordReducer } = this.props;

    return (
      <Row style={{ display: this.props.visible ? 'block' : 'none' }}>
        <div
          style={{
            // height: isMobile ? '55vh' : '60vh',
            // overflowY: 'auto',
            marginTop: '15px',
          }}
        >
          <Row>
            <Col span={24}>
              <Row>
                {recordReducer.isSearching ? (
                  <Col span={24} style={{ textAlign: 'center', padding: '20px' }}>
                    <Spin size="large" />
                  </Col>
                ) : (
                  this.getSearchResults()
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  mapReducer: state.mapReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
  resetWIForm: () => dispatch(resetWorkItemForm()),
  setNewQuickView: (params: IMapSetWorkItemQuickView) => dispatch(setWorkItemQuickView(params)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(MapSidebarSearchFeatureResults);
