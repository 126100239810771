import { call, put, takeLatest } from 'redux-saga/effects';
import { USER_LOGOUT_REQUEST } from '../../../../../core/identity/store/constants';
import { httpDelete, httpGet, httpPost } from '../../../../../shared/http/requests';
import { ERROR_NOTIFICATION } from '../../../../../shared/system/notifications/store/reducers';
import {
  CHECK_AUTOSPLICING_ACCESS_ERROR,
  CHECK_AUTOSPLICING_ACCESS_REQUEST,
  CHECK_AUTOSPLICING_ACCESS_SUCCESS,
  CHECK_AUTOSPLICING_DISTRIBUTION_ERROR,
  CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST,
  CHECK_AUTOSPLICING_DISTRIBUTION_SUCCESS,
  CHECK_AUTOSPLICING_RESULTS_ERROR,
  CHECK_AUTOSPLICING_RESULTS_REQUEST,
  CHECK_AUTOSPLICING_RESULTS_SUCCESS,
  CHECK_AUTOSPLICING_SPINE_ERROR,
  CHECK_AUTOSPLICING_SPINE_REQUEST,
  CHECK_AUTOSPLICING_SPINE_SUCCESS,
  CLEAN_AUTOSPLICING_ISSUES_REQUEST,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_ERROR,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_SUCCESS,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_ERROR,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_SUCCESS,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_ERROR,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_SUCCESS,
  GET_AUTOSPLICING_TEMPLATES_ERROR,
  GET_AUTOSPLICING_TEMPLATES_REQUEST,
  GET_AUTOSPLICING_TEMPLATES_SUCCESS,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_ERROR,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_SUCCESS,
  RESET_AUTOSPLICING_BY_CABLE_ERROR,
  RESET_AUTOSPLICING_BY_CABLE_REQUEST,
  RESET_AUTOSPLICING_BY_CABLE_SUCCESS,
  RESET_AUTOSPLICING_BY_TEMPLATE_ERROR,
  RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST,
  RESET_AUTOSPLICING_BY_TEMPLATE_SUCCESS,
  RESET_AUTOSPLICING_POLYGON_ERROR,
  RESET_AUTOSPLICING_POLYGON_REQUEST,
  RESET_AUTOSPLICING_POLYGON_SUCCESS,
} from './constants';
import {
  ICheckAutosplicingAccess,
  ICheckAutosplicingDistribution,
  ICheckAutosplicingResults,
  ICheckAutosplicingSpine,
  ICleanAutosplicingIssues,
  IGetAutosplicingMatricesByClosureId,
  IGetAutosplicingTemplateByPathname,
  IGetAutosplicingTemplateDifference,
  IGetAutosplicingTemplates,
  IGetSplicingShorthandByClosureId,
  IResetPolygonPayload,
} from './types';
import { IResetFibersByCableRequest, IResetFibersByTemplateRequest } from './actions';

function* getSplicingShorthandByClosureId(action: {
  params: IGetSplicingShorthandByClosureId;
  cb: any;
}): any {
  try {
    const { closureId } = action.params;
    const path = `ConnectModule/connections/connections-agg/${closureId}`;
    const response = yield call(async () => await httpGet(path));
    yield put({
      type: GET_SPLICING_SHORTHAND_BY_CLOSUREID_SUCCESS,
      results: response,
    });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_SPLICING_SHORTHAND_BY_CLOSUREID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getAutosplicingMatricesByClosureId(action: {
  params: IGetAutosplicingMatricesByClosureId;
  cb: any;
}): any {
  try {
    const { closureId } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/splicing-matrix/${closureId}`;
    const response = yield call(async () => await httpGet(path));
    yield put({
      type: GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_SUCCESS,
      results: response,
    });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* resetPolygon(action: { params: IResetPolygonPayload; cb: any }): any {
  try {
    const { closureId, cableId, polygonId, cableTypes } = action.params;
    const payload = {
      closureId: Number(closureId),
      cableId: cableId ? Number(cableId) : null,
      cableTypes: cableTypes,
    };
    const path = `ConnectModule/connections/fiber-connections/${polygonId}`;
    const response = yield call(async () => await httpDelete(path, payload));
    yield put({ type: RESET_AUTOSPLICING_POLYGON_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: RESET_AUTOSPLICING_POLYGON_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* resetConnectionsByTemplate(action: {
  params: IResetFibersByTemplateRequest;
  cb: any;
}): any {
  try {
    const { closureId, cableId } = action.params;

    let path = `ProjectModule/v1.0/network/changemanager/templates/${closureId}`;
    if (cableId) {
      path = path.concat(`?cableId=${cableId}`);
    }
    const response = yield call(async () => await httpDelete(path));
    yield put({
      type: RESET_AUTOSPLICING_BY_TEMPLATE_SUCCESS,
      results: response,
    });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: RESET_AUTOSPLICING_BY_TEMPLATE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* resetConnectionsByCable(action: { params: IResetFibersByCableRequest; cb: any }): any {
  try {
    const { cableId, rootClosureId, rootCableId } = action.params;
    const path = `ProjectModule/v1.0/network/changemanager/fibersByCable/${cableId}`;
    // @ts-ignore
    const response = yield call(
      async () =>
        await httpDelete(path, {
          rootClosureId,
          rootCableId,
          splittersOnly: false,
        }),
    );
    yield put({ type: RESET_AUTOSPLICING_BY_CABLE_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: RESET_AUTOSPLICING_BY_CABLE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getAutosplicingTemplateDifference(action: {
  params: IGetAutosplicingTemplateDifference;
  cb: any;
}): any {
  try {
    const { closureId, cableId } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/templates/${closureId}/difference${
      cableId ? `?cableId=${cableId}` : ''
    }`;
    const response = yield call(async () => await httpGet(path));
    yield put({
      type: GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_SUCCESS,
      results: response,
    });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getAutosplicingTemplateByPathname(action: {
  params: IGetAutosplicingTemplateByPathname;
  cb: any;
}): any {
  try {
    const { pathname } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/templates/${encodeURIComponent(
      pathname,
    )}`;
    const response = yield call(async () => await httpGet(path));
    yield put({
      type: GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_SUCCESS,
      results: response,
    });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* getAutosplicingTemplates(action: { params: IGetAutosplicingTemplates; cb: any }): any {
  try {
    const { closureId, cableId, payload } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/templates/${closureId}?cableId=${
      cableId ? cableId : ''
    }`;
    const response = yield call(async () => await httpPost(path, payload));
    yield put({ type: GET_AUTOSPLICING_TEMPLATES_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_AUTOSPLICING_TEMPLATES_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* checkAutosplicingSpine(action: { params: ICheckAutosplicingSpine; cb: any }): any {
  try {
    const { l0ClosureId, spineCableId } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/fibercheck/checkSpine/${l0ClosureId}/${spineCableId}`;
    const response = yield call(async () => await httpGet(path));
    yield put({ type: CHECK_AUTOSPLICING_SPINE_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CHECK_AUTOSPLICING_SPINE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* checkAutosplicingDistribution(action: {
  params: ICheckAutosplicingDistribution;
  cb: any;
}): any {
  try {
    const { l1ClosureId } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/fibercheck/checkDistribution/${l1ClosureId}`;
    const response = yield call(async () => await httpGet(path));
    yield put({
      type: CHECK_AUTOSPLICING_DISTRIBUTION_SUCCESS,
      results: response,
    });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CHECK_AUTOSPLICING_DISTRIBUTION_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* checkAutosplicingAccess(action: { params: ICheckAutosplicingAccess; cb: any }): any {
  try {
    const { l2ClosureId } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/fibercheck/checkAccess/${l2ClosureId}`;
    const response = yield call(async () => await httpGet(path));
    yield put({ type: CHECK_AUTOSPLICING_ACCESS_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CHECK_AUTOSPLICING_ACCESS_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* checkAutosplicingResults(action: { params: ICheckAutosplicingResults; cb: any }): any {
  try {
    const { polygonId, cableTypes } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/fibercheck/checkResults/${polygonId}?cableTypes=${cableTypes.join(
      ',',
    )}`;
    const response = yield call(async () => await httpGet(path));
    yield put({ type: CHECK_AUTOSPLICING_RESULTS_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CHECK_AUTOSPLICING_RESULTS_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* cleanAutosplicingIssues(action: { params: ICleanAutosplicingIssues; cb: any }): any {
  try {
    const { polygonId, cableTypes } = action.params;
    const path = `ProjectModule/v1.0/network/autosplicing/fibercheck/cleanIssues/${polygonId}?cableTypes=${cableTypes.join(
      ',',
    )}`;
    const response = yield call(async () => await httpPost(path, {}));
    yield put({ type: CHECK_AUTOSPLICING_RESULTS_SUCCESS, results: response });
    if (action.cb) {
      yield call(action.cb, response);
    }
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CHECK_AUTOSPLICING_RESULTS_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error: !!error ? error : e });
    }
  }
}

function* rootSaga() {
  yield takeLatest<any>(CHECK_AUTOSPLICING_SPINE_REQUEST, checkAutosplicingSpine);
  yield takeLatest<any>(CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST, checkAutosplicingDistribution);
  yield takeLatest<any>(CHECK_AUTOSPLICING_ACCESS_REQUEST, checkAutosplicingAccess);
  yield takeLatest<any>(CHECK_AUTOSPLICING_RESULTS_REQUEST, checkAutosplicingResults);
  yield takeLatest<any>(CLEAN_AUTOSPLICING_ISSUES_REQUEST, cleanAutosplicingIssues);
  yield takeLatest<any>(
    GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST,
    getAutosplicingMatricesByClosureId,
  );
  yield takeLatest<any>(
    GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST,
    getSplicingShorthandByClosureId,
  );
  yield takeLatest<any>(RESET_AUTOSPLICING_POLYGON_REQUEST, resetPolygon);
  yield takeLatest<any>(RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST, resetConnectionsByTemplate);
  yield takeLatest<any>(RESET_AUTOSPLICING_BY_CABLE_REQUEST, resetConnectionsByCable);
  yield takeLatest<any>(GET_AUTOSPLICING_TEMPLATES_REQUEST, getAutosplicingTemplates);
  yield takeLatest<any>(
    GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST,
    getAutosplicingTemplateByPathname,
  );
  yield takeLatest<any>(
    GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST,
    getAutosplicingTemplateDifference,
  );
}

export default rootSaga;
