import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

const { CRM_MODULE, BILLING_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, PAYMENT_METHOD } = SchemaModuleEntityTypeEnums;

const PaymentMethodRefundDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      leftColumn={[
        <RecordCard
          showActionMenu
          borderless
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={CONTACT}
          visibleProperties={['EmailAddress', 'Phone']}
        />,
        <RecordCard
          showActionMenu
          borderless
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={BILLING_MODULE}
          associatedRecordEntityName={PAYMENT_METHOD}
          visibleProperties={['BankName', 'Status']}
        />,
      ]}
    />
  );
};

export default PaymentMethodRefundDetailView;
