import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Alert, Button, Col, Menu, Modal, Row, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getSchemaFromShortListByModuleAndEntity } from '../../shared/utilities/schemaHelpers';
import { initializeRecordForm } from '../records/components/Forms/store/actions';
import LookUpCreate from '../records/components/LookUpCreate';
import { getRecordByIdRequest, IGetRecordById } from '../records/store/actions';
import DataTable from '../recordsAssociations/components/AssociationDataTable';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../schemas/store/actions';
import './styles.scss';
import { IFlowStep, TFlowTypes, TLaunchInterface } from './types';
import CoreForm from '../records/components/Forms/CoreForm';
import CoreLegacyEmbeddedForm from '../records/components/Forms/CoreLegacyEmbeddedForm';

export interface Props {
  flowSteps: Array<IFlowStep>;
  initializeForm: Function;
  getRecordById: Function;
  launchInterface: TLaunchInterface;
  launchInterfaceTitle: string;
  launchInterfaceStyle?: Object;
  launchInterfaceProps?: any;
  flowVisible?: boolean;
  toggleFlowVisibility?: Function;
  schemaReducer: any;
  getSchema: Function;
  onFlowSuccess?: Function;
}

const Flow: React.FC<Props> = (props) => {
  const {
    initializeForm,
    flowSteps,
    getRecordById,
    launchInterface,
    launchInterfaceTitle,
    getSchema,
  } = props;
  const [currentStep, setCurrentStep] = useState<IFlowStep | null>(null);
  const [associations, setAssociations] = useState<
    { pos: number; record: DbRecordEntityTransform }[] | null
  >(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // Configure first step when Flow is shown
  useEffect(() => {
    if (isVisible) {
      configureStep(1);
    } else {
      setCurrentStep(null);
      setAssociations(null);
    }
  }, [isVisible]);

  useEffect(() => {
    if (props.flowVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [props.flowVisible]);

  const renderNextOrSubmitButtonTitle = () => {
    if (currentStep?.nextOrSubmitButtonTitle?.length) {
      return currentStep?.nextOrSubmitButtonTitle;
    } else {
      if (flowSteps.length > currentStep?.stepNum!) {
        return 'Next';
      } else {
        return 'Finish';
      }
    }
  };

  const closeTheFlow = () => {
    if (currentStep?.flowType === 'ASSOCIATION' && currentStep?.runOnSubmit) {
      currentStep?.runOnSubmit();
    }

    if (props.toggleFlowVisibility) {
      props.toggleFlowVisibility();
    } else {
      setIsVisible(false);
    }
  };

  // For each step - get schema and form UUID
  const configureStep = (step: number) => {
    const flowStep: IFlowStep = flowSteps[step - 1];

    const schema = getSchemaFromShortListByModuleAndEntity(
      props.schemaReducer.shortList,
      flowStep.moduleName,
      flowStep.entityName,
    );

    if (schema) {
      setCurrentStep({
        ...flowStep,
        stepNum: step,
        schema: schema,
        uuid: uuidv4(),
      });
    } else {
      getSchema(
        { moduleName: flowStep.moduleName, entityName: flowStep.entityName },
        (response: SchemaEntity) => {
          if (response) {
            setCurrentStep({
              ...flowStep,
              stepNum: step,
              schema: response,
              uuid: uuidv4(),
            });
          }
        },
      );
    }
  };

  // Every time we create a record, get the record by id and save to associations with the flow step indicator
  const stepSuccessful = (res: any) => {
    // TODO: Sort out and comment this mess.
    let currentAssociations = associations;

    if (currentStep?.flowType === 'ASSOCIATION' && currentStep?.runOnSubmit) {
      currentStep?.runOnSubmit();
    }

    if (res && res?.results?.id) {
      // Get the created record and push into associations array to be reused during the flow
      getRecordById({ schema: currentStep?.schema, recordId: res?.results?.id }, (record: any) => {
        if (associations && associations.length > 0) {
          currentAssociations?.push({
            pos: currentStep?.stepNum!,
            record: record,
          });
          setAssociations(currentAssociations);
        } else {
          setAssociations([{ pos: currentStep?.stepNum!, record: record }]);
        }

        // Run on submit for RECORD flow types
        if (currentStep?.flowType === 'RECORD' && currentStep?.runOnSubmit) {
          currentStep?.runOnSubmit(res?.results?.id);
        }

        if (
          currentStep?.flowType === 'LOOKUP' ||
          currentStep?.flowType === 'CREATE' ||
          currentStep?.flowType === 'LOOKUP_AND_CREATE' ||
          currentStep?.flowType === 'ASSOCIATION'
        ) {
          if (currentStep?.stepNum && currentStep?.stepNum < flowSteps.length) {
            configureStep(currentStep?.stepNum! + 1);
          } else {
            closeTheFlow();

            if (props.onFlowSuccess) {
              props.onFlowSuccess(currentAssociations);
            }
          }
        } else {
          configureStep(currentStep?.stepNum! + 1);
        }
      });
    }
  };

  // Initialize and show Form for the record/schema pair
  const renderRecordForm = () => {
    // Get associations for create form
    const getAssociations = () => {
      if (
        currentStep?.schema &&
        currentStep?.relateRecordFromStep &&
        currentStep?.relateRecordFromStep!.length > 0
      ) {
        let associationsDto: Array<{ entity?: string; recordId: string }> = [];

        for (const association of currentStep?.relateRecordFromStep) {
          const targetedAssociationRecord = associations?.find(
            (assoc: any) => assoc.pos === association,
          );
          if (targetedAssociationRecord) {
            associationsDto.push({
              entity: targetedAssociationRecord?.record?.entity,
              recordId: targetedAssociationRecord?.record?.id,
            });
          }
        }

        return [associationsDto];
      } else if (currentStep?.associationId) {
        return [
          {
            entity: currentStep?.associationEntity,
            recordId: currentStep?.associationId,
          },
        ];
      } else {
        return [];
      }
    };

    return (
      <CoreLegacyEmbeddedForm
        submitButtonTitle={renderNextOrSubmitButtonTitle()}
        formDirection="horizontal"
        showFormActions
        formUUID={currentStep?.uuid!}
        hideStageFormField
        onCancelEvent={() => closeTheFlow()}
        onSubmitEvent={(res: any) => stepSuccessful(res)}
        initializeCb={() => {
          initializeForm({
            hideRecordTypeField: true,
            formUUID: currentStep?.uuid!,
            showFormModal: false,
            disabledFields: currentStep?.disabledFormFields,
            type: currentStep?.recordType,
            showInitializing: false,
            hideRecordFormFields: currentStep?.hideRecordFormFields,
            isBatchCreateReq: false,
            isCreateReq: true,
            recordType: currentStep?.recordType ? currentStep?.recordType : null,
            schema: currentStep?.schema,
            modified: [
              {
                ...currentStep?.modified,
                associations: [
                  ...(currentStep?.additionalAssociations || []),
                  ...getAssociations(),
                ],
                schemaId: currentStep?.schema?.id,
              },
            ],
            sections: [
              {
                name: currentStep?.schema?.name,
                schema: currentStep?.schema,
              },
            ],
          });
        }}
      />
    );
  };

  // This will return either file viewer / uploader or Association data table
  function renderFileOrAssociationForm() {
    if (
      currentStep?.schema &&
      currentStep?.relateRecordFromStep &&
      currentStep?.relateRecordFromStep!.length > 0
    ) {
      // For every asked record association, find the associated record in the flow and render DataTable
      return currentStep?.relateRecordFromStep!.map((relationNum: number) => {
        const targetedAssociationRecord = associations?.find(
          (assoc: any) => assoc.pos === relationNum,
        );

        if (targetedAssociationRecord) {
          return (
            <div style={{ padding: 10 }}>
              <DataTable
                collapsedByDefault
                thumbnailSize={12}
                record={targetedAssociationRecord.record!}
                moduleName={currentStep?.moduleName}
                entityName={currentStep?.entityName!}
                location="association"
                displayType="thumbnails"
                editableFileFields={currentStep?.editableFileFields}
                showFileCategoryForType={currentStep?.showFileCategoryForType}
              />
            </div>
          );
        } else {
          return (
            <Alert
              message="Error"
              description={`For this flow step you specified record association in step ${relationNum}. This related record does not exist.`}
              type="error"
            />
          );
        }
      });
    } else {
      return (
        <Alert
          message="Error"
          description="You initialized association step without relation to record in previous steps."
          type="error"
        />
      );
    }
  }

  // Render UI element that will launch the Flow
  const renderLaunchInterface = () => {
    if (launchInterface === 'BUTTON') {
      return (
        <Button
          {...props.launchInterfaceProps}
          style={props.launchInterfaceStyle || {}}
          onClick={() => setIsVisible(true)}
        >
          {launchInterfaceTitle}
        </Button>
      );
    } else if (launchInterface === 'MENU_ITEM') {
      return (
        <Menu.Item
          {...props.launchInterfaceProps}
          style={props.launchInterfaceStyle || {}}
          onClick={() => setIsVisible(true)}
        >
          {launchInterfaceTitle}
        </Menu.Item>
      );
    } else if (launchInterface === 'LINK') {
      return (
        <span style={props.launchInterfaceStyle || {}} onClick={() => setIsVisible(true)}>
          {launchInterfaceTitle}
        </span>
      );
    } else if (launchInterface === 'NONE') {
      return <></>;
    } else {
      return <span>No launch Interface specified</span>;
    }
  };

  // Each flow step can relate to previous flow steps. This is why we pass
  // array of related steps, find the record in the associations and pass
  // down the array of records to Lookup create component.
  const getRelatedRecordsForLookupCreate = () => {
    let relatedRecords: any[] = [];

    if (
      currentStep?.schema &&
      currentStep?.relateRecordFromStep &&
      currentStep?.relateRecordFromStep?.length > 0
    ) {
      for (const relatedStep of currentStep.relateRecordFromStep) {
        const targetedAssociationRecord = associations?.find(
          (assoc: any) => assoc.pos === relatedStep,
        );
        if (targetedAssociationRecord) {
          relatedRecords.push(targetedAssociationRecord?.record);
        }
      }

      return relatedRecords;
    }
  };

  const renderLookupComponent = (type: 'LOOKUP' | 'LOOKUP_AND_CREATE' | 'CREATE') => {
    if (type === 'LOOKUP_AND_CREATE' || type === 'LOOKUP' || type === 'CREATE') {
      return (
        <div style={{ padding: 10 }}>
          <LookUpCreate
            customLookupQuery={currentStep?.customLookupQuery}
            entityName={currentStep?.entityName!}
            finishButtonTitle={renderNextOrSubmitButtonTitle()}
            lookupColumn={currentStep?.lookupColumn!}
            lookupDefaultRecord={currentStep?.lookupDefaultRecord}
            lookupOperator={currentStep?.lookupOperator!}
            lookupSearchPlaceholder={currentStep?.lookupSearchPlaceholder!}
            mode="FLOW"
            moduleName={currentStep?.moduleName!}
            onCancelCb={closeTheFlow}
            onSuccessCb={stepSuccessful}
            relatedRecords={getRelatedRecordsForLookupCreate()}
            type={type}
          />
        </div>
      );
    }
  };

  const getFlowStepForType = (type: TFlowTypes) => {
    switch (type) {
      case 'RECORD':
        return renderRecordForm();
      case 'ASSOCIATION':
        return renderFileOrAssociationForm();
      case 'LOOKUP':
        return renderLookupComponent(type);
      case 'LOOKUP_AND_CREATE':
        return renderLookupComponent(type);
      case 'CREATE':
        return renderLookupComponent(type);
      default:
        return <></>;
    }
  };

  // Get step and initialize either RECORD or ASSOCIATION view.
  const initializeStep = () => {
    return (
      <Modal
        width={isMobile ? '90%' : '750px'}
        closable={false}
        className={`flowModal ${
          currentStep?.flowType === 'RECORD' ||
          currentStep?.flowType === 'LOOKUP' ||
          currentStep?.flowType === 'CREATE' ||
          currentStep?.flowType === 'LOOKUP_AND_CREATE'
            ? 'withoutFooter'
            : 'withFooter'
        }`}
        footer={
          <Row style={{ paddingBottom: 10, paddingRight: 15 }}>
            <Col span={24}>
              <Button onClick={() => closeTheFlow()}>Cancel</Button>
              <Button
                type="primary"
                onClick={() =>
                  flowSteps.length > currentStep?.stepNum!
                    ? configureStep(currentStep?.stepNum! + 1)
                    : closeTheFlow()
                }
              >
                {renderNextOrSubmitButtonTitle()}
              </Button>
            </Col>
          </Row>
        }
        open={isVisible}
        title={
          // Step Counter
          <Row style={{ margin: '5px 10px 5px 5px' }}>
            <Col
              span={24}
              style={{
                borderRadius: 5,
                padding: 10,
              }}
            >
              <Steps current={currentStep?.stepNum! - 1} size="small">
                {flowSteps?.map((step: IFlowStep, i: number) => (
                  <Steps.Step
                    icon={step.customIcon || undefined}
                    key={i + 1}
                    title={`Step ${i + 1}`}
                    description={step.title}
                  />
                ))}
              </Steps>
            </Col>
          </Row>
        }
      >
        {getFlowStepForType(currentStep?.flowType!)}
      </Modal>
    );
  };

  return flowSteps?.length > 0 && isVisible && currentStep?.stepNum
    ? initializeStep()
    : renderLaunchInterface();
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(Flow);
