import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../..';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { MY_CASES_SET_SELECTED_CASE } from '../../../store/constants';
import CommunicationsBadge from '../../CommunicationsBadge';
import CommunicationsIcon from '../../CommunicationsIcon';
import CommunicationsStatusTag from '../../CommunicationsStatusTag';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  Case: DbRecordEntityTransform;
  index: number;
}

const MyCasesListItem: React.FC<Props> = (props: Props) => {
  const { index, Case } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCase } = state;

  const handleCaseClick = () => {
    dispatch({ type: MY_CASES_SET_SELECTED_CASE, payload: Case });
  };

  const isCaseSelected = () => {
    return selectedCase?.id === Case.id;
  };

  return (
    <Col span={24} key={`Col1_${Case.id}`}>
      <Row
        key={`Row1_${Case.id}`}
        onClick={handleCaseClick}
        style={{
          cursor: 'pointer',
          padding: '15px 8px',
          borderLeft: isCaseSelected() ? '3px solid blue' : '3px solid #fff',
          borderTop: index > 0 ? '1px solid #D9DADA' : 'auto',
        }}
      >
        <Col span={1} key={`Col2_${Case.id}`}>
          <CommunicationsBadge unread={Case.unread} />
        </Col>
        <Col span={4} style={{ paddingLeft: 5 }} key={`Col3_${Case.id}`}>
          <CommunicationsIcon type={Case.stage?.name || 'Unknown'} />
        </Col>
        <Col span={10} key={`Col4_${Case.id}`}>
          <Row key={`Row2_${Case.id}`}>
            <Col span={24} key={`Col5_${Case.id}`}>
              <span key={`Span1_${Case.id}`} style={{ fontWeight: 600 }}>
                Unknown
              </span>
            </Col>
            <Col span={24} key={`Col6_${Case.id}`}>
              <span key={`Span2_${Case.id}`} style={{ color: '#444E56' }}>
                Unknown Contact
              </span>
            </Col>

            <Col span={24} key={`Col7_${Case.id}`}>
              <span key={`Span3_${Case.id}`} style={{ color: '#444E56', fontSize: '0.9em' }}>
                Category: {getProperty(Case, 'Category') || 'Unknown'}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={9} style={{ textAlign: 'right' }} key={`Col8_${Case.id}`}>
          <Row key={`Row3_${Case.id}`}>
            <Col span={24} key={`Col9_${Case.id}`}>
              <CommunicationsStatusTag key={`CommTag1_${Case.id}`} status={Case?.stage?.name} />
            </Col>
            <Col span={24} style={{ paddingRight: 4, marginTop: 10 }} key={`Col10_${Case.id}`}>
              <span key={`Span4_${Case.id}`} style={{ fontSize: '0.9em', color: '#545A5D' }}>
                {dayjs(Case.createdAt).format('d MMM HH:mm')}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default MyCasesListItem;
