import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { connect } from 'react-redux';
import { canUserUpdateRecord } from '../../../../shared/permissions/rbacRules';
import { IdentityUserReducer } from '../../../identityUser/store/reducer';
import LookUpCreateLegacy from '../../../records/components/LookUpCreateLegacy';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { createOrderVisible } from '../../../workflow/store/actions';
import { updateOrCreateRecordAssociations } from '../../store/actions';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { getSchemaFromShortListBySchemaId } from '../../../../shared/utilities/schemaHelpers';

interface Props {
  record: DbRecordEntityTransform;
  relation: DbRecordAssociationRecordsTransform;
  schemaReducer: ISchemaReducer;
  userReducer: IdentityUserReducer;
  createOrderVisible: any;
  createAssociations: any;
  onLookupSuccess?: Function;
}

interface State {
  modalVisible: boolean;
  selected: any;
  isLoading: boolean;
}

const { ADDRESS } = SchemaModuleEntityTypeEnums;

class LookUpCreateModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    modalVisible: false,
    selected: [],
    isLoading: false,
  });

  openModal() {
    const { createOrderVisible } = this.props;
    createOrderVisible();
    this.setState({
      modalVisible: true,
    });
  }

  handleCancel = () => {
    const { createOrderVisible } = this.props;
    createOrderVisible();
    this.setState({
      modalVisible: false,
      isLoading: false,
    });
  };

  onLookupSuccess() {
    if (this.props.onLookupSuccess) {
      this.props.onLookupSuccess();
    }
  }

  handleOk = () => {
    const { record, relation, createAssociations, schemaReducer } = this.props;
    const { schemaAssociation } = relation;
    this.setState({
      isLoading: true,
    });
    if (schemaAssociation && record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
      if (relation.schema.entityName === ADDRESS) {
        createAssociations(
          {
            recordId: record.id,
            schema,
            schemaAssociation,
            createUpdate: [
              {
                entity: this.state.selected.entity,
                recordId: this.state.selected.id,
              },
            ],
          },
          () => {
            this.handleCancel();
            this.onLookupSuccess();
          },
        );
      } else {
        const body: DbRecordAssociationCreateUpdateDto[] = [];
        if (Array.isArray(this.state.selected)) {
          body.push(
            ...this.state.selected.map((elem: any) => ({
              entity: elem.entity,
              recordId: elem.recordId,
            })),
          );
        } else {
          body.push({
            entity: this.state.selected.entity,
            recordId: this.state.selected.id,
          });
        }
        createAssociations(
          {
            recordId: record.id,
            schema,
            schemaAssociation,
            createUpdate: body,
          },
          () => {
            this.handleCancel();
            this.onLookupSuccess();
          },
        );
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { userReducer, record, relation } = this.props;
    const { schema } = relation;

    return (
      <div>
        <Button
          style={{ padding: 0, margin: 0 }}
          type="text"
          disabled={!canUserUpdateRecord(userReducer, schema)}
          onClick={() => this.openModal()}
        >
          Lookup
        </Button>
        <Modal
          title={`Add ${schema.entityName}`}
          open={this.state.modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1000}
          confirmLoading={this.state.isLoading}
        >
          <LookUpCreateLegacy
            record={record}
            entityName={schema.entityName !== undefined ? schema.entityName : ''}
            moduleName={schema.moduleName !== undefined ? schema.moduleName : ''}
            checkboxItemSelect={(e) => this.setState({ selected: e })}
          />
        </Modal>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  createOrderVisible: () => dispatch(createOrderVisible()),
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
});

export default connect(mapState, mapDispatch)(LookUpCreateModal);
