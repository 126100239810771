import React, { useEffect, useReducer, useState } from 'react';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Popconfirm,
  Result,
  Row,
  Typography,
} from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  IGetRecordById,
  ISearchRecords,
  searchRecordsRequest,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import {
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
} from '../../../../../core/recordsAssociations/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { httpPost } from '../../../../../shared/http/requests';
import { getSchemaFromShortListByModuleAndEntityOrAPI } from '../../../../../shared/utilities/schemaHelpers';
import InvoiceItemsList from './InvoiceItemsList';
import ProductSelection from './ProductSelection';
import {
  SET_ACCOUNT,
  SET_ADDRESS,
  SET_BILLING_ADJUSTMENT,
  SET_BILLING_PERIOD_END_DATE,
  SET_BILLING_PERIOD_START_DATE,
  SET_BILLING_START_DATE,
  SET_BILLING_TERMS,
  SET_CONTACT,
  SET_DISCOUNT,
  SET_DUE_DATE,
  SET_INVOICE_CREATED,
  SET_INVOICE_ID,
  SET_INVOICE_ITEMS,
  SET_INVOICE_TYPE,
  SET_IS_BILLING_ADJUSTMENT_APPLIED,
  SET_IS_DISCOUNT_APPLIED,
  SET_IS_INVOICE_CREATING,
  SET_LOADING_OFFERS,
  SET_LOADING_PARENT_RECORD_ASSOCIATIONS,
  SET_LOADING_PRODUCTS,
  SET_OFFER_LIST,
  SET_OFFER_PRODUCT_LIST,
  SET_ORDER_RECORD,
  SET_PARENT_RECORD,
} from './store/constants';
import { invoiceBuilderInitialState, invoiceBuilderReducer } from './store/reducer';
import { connect } from 'react-redux';
import './styles.scss';
import InvoiceDetails from './InvoiceDetails';
import {
  getAllInvoiceItemsAsProducts,
  getNextAvailableAdjustment,
  isRecordOrder,
  isRecordWorkOrder,
} from './helpers';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { calculateInvoiceTotals } from './billingHelpers';
import { TInvoiceSummary } from './types';
import { LoaderModal } from '../../../../../shared/components/LoaderModal';
import dayjs from 'dayjs';
import { PageHeader } from '../../../../../shared/components/PageHeader';
import { Button } from '@blueprintjs/core';

type Props = RouteComponentProps<any> & {
  match: any;
  history: any;
  navigationReducer: any;
  recordReducer: any;
  schemaReducer: any;
  getRecordById: any;
  searchRecords: any;
  getAssociations: Function;
  getNestedAssociations: Function;
  getSchema: Function;
  moduleName: string;
  entityName: any;
  recordAssociationReducer: any;
  createRecord: Function;
  updateRecord: Function;
};

const {
  ORDER_ITEM,
  BILLING_ADJUSTMENT,
  OFFER,
  PRODUCT,
  DISCOUNT,
  CONTACT,
  INVOICE,
  ADDRESS,
  ACCOUNT,
  ORDER,
  WORK_ORDER,
} = SchemaModuleEntityTypeEnums;
const { PRODUCT_MODULE, BILLING_MODULE, CRM_MODULE, ORDER_MODULE, FIELD_SERVICE_MODULE } =
  SchemaModuleTypeEnums;

export const invoiceBuilderContext = React.createContext<any>({});

const InvoiceBuilder: React.FC<Props> = (props: Props) => {
  const {
    match,
    navigationReducer,
    history,
    getRecordById,
    schemaReducer,
    searchRecords,
    getAssociations,
    getSchema,
    getNestedAssociations,
    createRecord,
    updateRecord,
  } = props;

  const [state, dispatch] = useReducer(invoiceBuilderReducer, invoiceBuilderInitialState);
  const [parentRecordSchema, setParentRecordSchema] = useState<SchemaEntity | undefined>(undefined);
  const [offerSchema, setOfferSchema] = useState<SchemaEntity | undefined>(undefined);
  const [invoiceSchema, setInvoiceSchema] = useState<SchemaEntity | undefined>(undefined);
  const [form] = Form.useForm();

  // When schema shortlist updates, try to fetch all necessary schemas.
  useEffect(() => {
    if (schemaReducer.shortList) getSchemas();
  }, [schemaReducer.shortList]);

  const getSchemas = async () => {
    try {
      const parentSchema = await getSchemaFromShortListByModuleAndEntityOrAPI(
        schemaReducer.shortList,
        props.moduleName!,
        props.entityName!,
        getSchema,
      );
      const offerSchema = await getSchemaFromShortListByModuleAndEntityOrAPI(
        schemaReducer.shortList,
        PRODUCT_MODULE,
        OFFER,
        getSchema,
      );
      const invoiceSchema = await getSchemaFromShortListByModuleAndEntityOrAPI(
        schemaReducer.shortList,
        BILLING_MODULE,
        INVOICE,
        getSchema,
      );
      setParentRecordSchema(parentSchema);
      setOfferSchema(offerSchema);
      setInvoiceSchema(invoiceSchema);
    } catch (error) {}
  };

  // If the parent record schema and recordId are available, fetch the parent record
  useEffect(() => {
    if (match.params?.recordId && !state.parentRecord && parentRecordSchema) {
      getRecordById(
        {
          schema: parentRecordSchema,
          recordId: match.params?.recordId,
        },
        (res: any) => {
          if (isRecordOrder(res)) {
            dispatch({ type: SET_PARENT_RECORD, payload: res });
            dispatch({ type: SET_ORDER_RECORD, payload: res });
          } else if (isRecordWorkOrder(res)) {
            dispatch({ type: SET_PARENT_RECORD, payload: res });
          }
        },
      );
    }
  }, [parentRecordSchema]);

  const updateContact = () => {
    getAssociations(
      {
        recordId: state.parentRecord?.id,
        schema: parentRecordSchema,
        entities: [CONTACT],
      },
      (res: any) => {
        if (res && res.results?.[CONTACT]?.dbRecords?.length > 0) {
          const contact = getFirstRelation(res.results, CONTACT);
          dispatch({ type: SET_CONTACT, payload: contact });
        }
      },
    );
  };

  const updateAddress = () => {
    getAssociations(
      {
        recordId: state.parentRecord?.id,
        schema: parentRecordSchema,
        entities: [ADDRESS],
      },
      (res: any) => {
        if (res && res.results?.[ADDRESS]?.dbRecords?.length > 0) {
          const address = getFirstRelation(res.results, ADDRESS);
          dispatch({ type: SET_ADDRESS, payload: address });
        }
      },
    );
  };

  // Once the parent record and parent schema are available, get the associated records
  useEffect(() => {
    if (state.parentRecord && parentRecordSchema) {
      dispatch({ type: SET_LOADING_PARENT_RECORD_ASSOCIATIONS, payload: true });
      let invoiceItems: DbRecordEntityTransform[] = [];

      getAssociations(
        {
          recordId: state.parentRecord?.id,
          schema: parentRecordSchema,
          entities: [BILLING_ADJUSTMENT, DISCOUNT, CONTACT, ADDRESS, ORDER, ACCOUNT],
        },
        (res: any) => {
          const relatedOrder = getFirstRelation(res.results, ORDER);
          if (relatedOrder && !state.orderRecord && !isRecordOrder(state.parentRecord)) {
            dispatch({ type: SET_ORDER_RECORD, payload: relatedOrder });
          }

          const relatedDiscount = getFirstRelation(res.results, DISCOUNT);
          if (relatedDiscount) {
            dispatch({ type: SET_IS_DISCOUNT_APPLIED, payload: true });
            dispatch({ type: SET_DISCOUNT, payload: relatedDiscount });
          }

          // Get associated Contact and use the first one for now
          const relatedContact = getFirstRelation(res.results, CONTACT);
          if (relatedContact) {
            dispatch({ type: SET_CONTACT, payload: relatedContact });
          }

          // Get associated Account and use the first one for now
          const relatedAccount = getFirstRelation(res.results, ACCOUNT);
          if (relatedAccount) {
            dispatch({ type: SET_ACCOUNT, payload: relatedAccount });
          }

          // Get associated Address and use the first one for now
          const relatedAddress = getFirstRelation(res.results, ADDRESS);
          if (relatedAddress) {
            dispatch({ type: SET_ADDRESS, payload: relatedAddress });
          }

          // Get associated Order Items with nested Products
          getNestedAssociations(
            {
              recordId: state.parentRecord?.id,
              key: ORDER_ITEM,
              schema: parentRecordSchema,
              entity: ORDER_ITEM,
              nestedEntities: [PRODUCT],
            },
            (response: any) => {
              if (
                response &&
                response.results?.[ORDER_ITEM]?.dbRecords?.length > 0 &&
                isRecordOrder(state.parentRecord)
              ) {
                const orderItems = response.results?.[ORDER_ITEM]?.dbRecords;
                for (let i = 0; i < orderItems.length; i++) {
                  invoiceItems.push(orderItems[i]);
                }
                dispatch({ type: SET_LOADING_PARENT_RECORD_ASSOCIATIONS, payload: false });
                dispatch({ type: SET_INVOICE_ITEMS, payload: invoiceItems });
                getAssociatedBillingAdjustments();
              } else {
                dispatch({ type: SET_LOADING_PARENT_RECORD_ASSOCIATIONS, payload: false });
              }
            },
          );
        },
      );
    }
  }, [state.parentRecord, parentRecordSchema]);

  // Get associated billing adjustments with nested Invoice records
  const getAssociatedBillingAdjustments = () => {
    getNestedAssociations(
      {
        recordId: state.parentRecord?.id,
        key: BILLING_ADJUSTMENT,
        schema: parentRecordSchema,
        entity: BILLING_ADJUSTMENT,
        nestedEntities: [INVOICE],
      },
      (response: any) => {
        // Get all Billing adjustments
        const adjustments = response.results?.[BILLING_ADJUSTMENT]?.dbRecords;

        if (adjustments?.length > 0) {
          const adjustment = getNextAvailableAdjustment(adjustments);

          if (adjustment) {
            dispatch({ type: SET_BILLING_ADJUSTMENT, payload: adjustment });
            dispatch({ type: SET_IS_BILLING_ADJUSTMENT_APPLIED, payload: true });
          }
        }
      },
    );
  };

  // Once order record and offer schema are available, fetch the list of offer records in the system.
  useEffect(() => {
    if (state.parentRecord && offerSchema) {
      searchRecords(
        {
          schema: offerSchema,
          searchQuery: {
            terms: '*',
            sort: [
              {
                createdAt: {
                  order: 'desc',
                },
              },
            ],
            schemas: offerSchema?.id,
            pageable: {
              page: 1,
              size: 200,
            },
          },
        },
        (res: any) => {
          if (res.data?.data) {
            dispatch({ type: SET_OFFER_LIST, payload: res.data?.data });
          }
          dispatch({ type: SET_LOADING_OFFERS, payload: false });
        },
      );
    }
  }, [state.parentRecord, offerSchema]);

  // When offer is selected, fetch the list of offer products
  useEffect(() => {
    if (state.selectedOffer && offerSchema) {
      dispatch({ type: SET_LOADING_PRODUCTS, payload: true });
      getAssociations(
        {
          recordId: state.selectedOffer?.id,
          key: OFFER,
          schema: offerSchema,
          entities: [PRODUCT],
        },
        (res: any) => {
          dispatch({ type: SET_LOADING_PRODUCTS, payload: false });
          if (res && res.results?.[SchemaModuleEntityTypeEnums.PRODUCT]?.dbRecords?.length > 0) {
            const products = res.results?.[SchemaModuleEntityTypeEnums.PRODUCT]?.dbRecords;
            dispatch({ type: SET_OFFER_PRODUCT_LIST, payload: products });
            dispatch({ type: SET_LOADING_PRODUCTS, payload: false });
          }
        },
      );
    }
  }, [state.selectedOffer]);

  // Every time invoice items are updated, try to find business products and set the invoice to business type
  useEffect(() => {
    if (state.invoiceItems?.length > 0) {
      if (
        state.invoiceItems.some(
          (invoiceItem: DbRecordEntityTransform) =>
            getProperty(invoiceItem, 'ProductCustomerType') === 'BUSINESS' ||
            getProperty(invoiceItem, 'CustomerType') === 'BUSINESS',
        )
      ) {
        dispatch({ type: SET_INVOICE_TYPE, payload: 'BUSINESS' });
      }
    }
  }, [state.invoiceItems]);

  const renderInvoiceBuilderHeader = () => {
    let invoiceSummary: TInvoiceSummary | undefined = undefined;

    if (state.orderRecord) {
      invoiceSummary = calculateInvoiceTotals(state.orderRecord, state.invoiceItems);
    }

    return (
      <>
        <Row>
          <Col span={24}>
            {/* Parent record, Contact and Address */}
            <InvoiceDetails updateContact={updateContact} updateAddress={updateAddress} />
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          {/* Custom Billing Start Date */}
          <Col xs={12} lg={3}>
            <span>Billing Start</span>
            <br />
            <DatePicker
              value={dayjs(state.billingStartDate)}
              onChange={(e: any) => {
                dispatch({ type: SET_BILLING_START_DATE, payload: dayjs(e).format('YYYY-MM-DD') });
              }}
              style={{ marginTop: 7, width: '90%' }}
              showTime={false}
              format="DD/MM/YYYY"
            />
          </Col>

          {/* Custom Billing Period Start Date */}
          <Col xs={12} lg={3}>
            <span>Billing Period Start</span>
            <br />
            <DatePicker
              value={dayjs(state.billingPeriodStartDate)}
              style={{ marginTop: 7, width: '90%' }}
              showTime={false}
              format="DD/MM/YYYY"
              onChange={(e: any) => {
                dispatch({
                  type: SET_BILLING_PERIOD_START_DATE,
                  payload: dayjs(e).format('YYYY-MM-DD'),
                });
              }}
            />
          </Col>

          {/* Custom Billing Period End Date */}
          <Col xs={12} lg={3}>
            <span>Billing Period End</span>
            <br />
            <DatePicker
              value={dayjs(state.billingPeriodEndDate)}
              onChange={(e: any) => {
                dispatch({
                  type: SET_BILLING_PERIOD_END_DATE,
                  payload: dayjs(e).format('YYYY-MM-DD'),
                });
              }}
              style={{ marginTop: 7, width: '90%' }}
              showTime={false}
              format="DD/MM/YYYY"
            />
          </Col>

          {/* Custom Due Date */}
          <Col xs={12} lg={3}>
            <span>Due Date</span>
            <br />
            <DatePicker
              value={dayjs(state.dueDate)}
              onChange={(e: any) => {
                dispatch({ type: SET_DUE_DATE, payload: dayjs(e).format('YYYY-MM-DD') });
              }}
              style={{ marginTop: 7, width: '90%' }}
              showTime={false}
              format="DD/MM/YYYY"
            />
          </Col>

          {/* Custom Billing Terms */}
          <Col xs={12} lg={3}>
            <span>Billing Terms</span>
            <br />
            <Input
              placeholder="Billing Terms"
              value={state.billingTerms}
              style={{ marginTop: 7, width: '90%' }}
              onChange={(e: any) => {
                dispatch({ type: SET_BILLING_TERMS, payload: e.target.value });
              }}
            />
          </Col>

          {/* Quick Summary */}
          <Col xs={24} lg={9} style={{ textAlign: 'right' }}>
            <Typography.Title level={3} style={{ marginTop: 25 }}>
              Total: {String(invoiceSummary?.TotalPrice || 0.0)}
            </Typography.Title>
          </Col>
        </Row>
      </>
    );
  };

  const generateInvoice = async () => {
    dispatch({ type: SET_IS_INVOICE_CREATING, payload: true });
    let createdInvoiceId: string = '';
    let addProductsRequestBody: any[] = [];

    let modifiedOrderRecord: DbRecordEntityTransform = JSON.parse(
      JSON.stringify(state.orderRecord),
    );

    // Strip parent record of discounts if the discount is toggled off
    if (!state.isDiscountApplied && state.discount) {
      modifiedOrderRecord.properties.DiscountValue = '0.00';
    }

    // Get Invoice summary, in order to calculate total amount that we pass to API
    const invoiceSummary = calculateInvoiceTotals(state.orderRecord!, state.invoiceItems);

    // Create empty invoice DTO with all associated records
    let createRecordBody: any[] = [
      {
        title: state.address?.title || 'Address not available',
        schemaId: invoiceSchema?.id,
        associations: [],
        properties: {
          BillingPeriodEnd: state.billingPeriodEndDate,
          BillingPeriodStart: state.billingPeriodStartDate,
          BillingStartDate: state.billingStartDate,
          BillingTerms: state.billingTerms,
          CurrencyCode: 'GBP',
          DueDate: state.dueDate,
          OrderRef: state.parentRecord?.id,
          Status: 'DRAFT',
          TotalDue: invoiceSummary?.TotalPrice || 0.0,
        },
      },
    ];

    // 2. Attach necessary associations
    if (state.parentRecord) {
      const parentRecordEntity = isRecordOrder(state.parentRecord)
        ? `${ORDER_MODULE}:${ORDER}`
        : `${FIELD_SERVICE_MODULE}:${WORK_ORDER}`;

      createRecordBody[0].associations.push({
        entity: parentRecordEntity,
        recordId: state.parentRecord?.id,
      });
    }
    if (state.contact) {
      createRecordBody[0].associations.push({
        entity: `${CRM_MODULE}:${CONTACT}`,
        recordId: state.contact?.id,
      });
    }
    if (state.account) {
      createRecordBody[0].associations.push({
        entity: `${CRM_MODULE}:${ACCOUNT}`,
        recordId: state.account?.id,
      });
    }
    if (state.address) {
      createRecordBody[0].associations.push({
        entity: `${CRM_MODULE}:${ADDRESS}`,
        recordId: state.address?.id,
      });
    }

    if (state.discount && state.isDiscountApplied) {
      createRecordBody[0].associations.push({
        entity: `${PRODUCT_MODULE}:${DISCOUNT}`,
        recordId: state.discount?.id,
      });
    }

    // 3. Create fresh invoice record
    createRecord(
      {
        schema: invoiceSchema,
        entity: `${BILLING_MODULE}:${INVOICE}`,
        createUpdate: createRecordBody,
      },
      (res: DbRecordEntityTransform) => {
        if (res?.id) {
          createdInvoiceId = res?.id;

          // 4. Flatten all invoice items into products and attach to the new invoice record
          const allProducts = getAllInvoiceItemsAsProducts(state.invoiceItems);
          allProducts.forEach((record: DbRecordEntityTransform) => {
            addProductsRequestBody.push({
              entity: record.entity,
              recordId: record.id,
              relatedAssociationId: record.dbRecordAssociation?.relatedAssociationId,
              properties: {
                UnitPrice: getProperty(record, 'UnitPrice'),
                Quantity: getProperty(record, 'Quantity'),
              },
            });
          });

          httpPost(
            `BillingModule/v1.0/invoices/${createdInvoiceId}/items`,
            addProductsRequestBody,
          ).then((result: any) => {
            dispatch({ type: SET_IS_INVOICE_CREATING, payload: false });

            // 2.1 Attach Billing adjustment if available and enabled
            if (state.billingAdjustment && state.isBillingAdjustmentApplied) {
              updateRecord({
                schema: invoiceSchema,
                recordId: createdInvoiceId,
                createUpdate: {
                  entity: `${BILLING_MODULE}:${INVOICE}`,
                  associations: [
                    {
                      entity: `${ORDER_MODULE}:${BILLING_ADJUSTMENT}`,
                      recordId: state.billingAdjustment?.id,
                    },
                  ],
                },
              });

              dispatch({ type: SET_INVOICE_CREATED, payload: true });
              dispatch({ type: SET_INVOICE_ID, payload: createdInvoiceId });
            } else {
              dispatch({ type: SET_INVOICE_CREATED, payload: true });
              dispatch({ type: SET_INVOICE_ID, payload: createdInvoiceId });
            }
          });
        }
      },
    );
  };

  const canGenerateInvoice = () => {
    if (
      state.invoiceItems.length > 0 &&
      !state.isInvoiceCreated &&
      state.contact &&
      state.address &&
      state.parentRecord
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <invoiceBuilderContext.Provider value={{ state, dispatch }}>
      <Layout style={{ padding: 15, marginTop: 5 }}>
        <Row gutter={12}>
          <Col span={24} style={{ minHeight: !state.isInvoiceCreated ? 'auto' : '90vh' }}>
            {/* Page Header */}
            <PageHeader
              style={{
                minHeight: !state.isInvoiceCreated ? 'auto' : '85vh',
                border: '1px solid #dddbda',
                background: 'white',
              }}
              title="Invoice Builder"
              className="invoiceBuilderHeader"
              onBack={() => {
                history.push(
                  `/${navigationReducer?.selectedModule}/${navigationReducer?.selectedEntity}/${state.parentRecord?.id}`,
                );
              }}
              ghost={false}
              extra={
                <>
                  {/* Help Button */}
                  <Button large style={{ marginRight: 5 }}>
                    Help
                  </Button>

                  {/* Refresh Button */}
                  <Popconfirm
                    placement="topLeft"
                    title={
                      'Are you sure you want to restart the invoice builder? You will lose all product configuration you created in the meantime.'
                    }
                    onConfirm={() => window.location.reload()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button large style={{ marginRight: 5 }} icon={<ReloadOutlined />} />
                  </Popconfirm>

                  {/* Generate Invoice Button */}
                  <Button
                    large
                    intent="primary"
                    disabled={!canGenerateInvoice() || state.isInvoiceCreating}
                    loading={state.isInvoiceCreating}
                    onClick={() => generateInvoice()}
                  >
                    Generate Invoice
                  </Button>
                </>
              }
            >
              {state.parentRecord && !state.isInvoiceCreated ? renderInvoiceBuilderHeader() : <></>}

              {state.isInvoiceCreated ? (
                <>
                  <Row style={{ marginTop: 100, marginBottom: 20 }} justify="center">
                    <Col xs={24} lg={10}>
                      <Result
                        style={{ padding: 10 }}
                        status="success"
                        title="Invoice Successfully Created"
                        subTitle="Invoice is successfully created. Click on the button below to navigate to the order."
                        extra={[
                          <Link
                            to={`/${BILLING_MODULE}/${INVOICE}/${state.invoiceId}`}
                            target="_blank"
                          >
                            <Button intent="primary" large>
                              View Invoice
                            </Button>
                          </Link>,
                        ]}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </PageHeader>
          </Col>

          {/* Left panel - Product Selection */}
          {!state.isInvoiceCreated ? (
            <Col xs={24} lg={9} className="invoiceBuilderPane">
              <Card title="Product Selection" style={{ minHeight: '100%' }}>
                <ProductSelection form={form} />
              </Card>
            </Col>
          ) : (
            <></>
          )}

          {/* Invoice Items */}
          {!state.isInvoiceCreated ? (
            <Col xs={24} lg={15} className="invoiceBuilderPane">
              <Row>
                <Col span={24}>
                  <Card title="Invoice Items" size="default" style={{ minHeight: 300 }}>
                    <InvoiceItemsList />
                  </Card>
                </Col>
              </Row>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Layout>
      <LoaderModal visible={state.isInvoiceCreating} loaderMessage="Generating Invoice..." />
    </invoiceBuilderContext.Provider>
  );
};

const mapState = (state: any) => ({
  navigationReducer: state.navigationReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: any, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
  updateRecord: (params: any, cb: any) => dispatch(updateRecordByIdRequest(params, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(InvoiceBuilder));
