import { Tag, Popover, Divider, Button, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import React, { useContext, useEffect } from 'react';
import NoteFeedEmoji from '../NoteFeedEmoji';
import { TReaction } from '../types';
import { connect } from 'react-redux';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { NoteFeedContext } from '..';
import dayjs from 'dayjs';

interface Props {
  noteRecord: DbRecordEntityTransform;
  reactions: TReaction[] | null;
  userReducer: any;
  onReactionRemoved: () => void;
}

const NoteReactionsList: React.FC<Props> = (props: Props) => {
  const { noteRecord, userReducer, reactions, onReactionRemoved } = props;
  const { state } = useContext(NoteFeedContext);

  useEffect(() => {
    init({ data });
  }, []);

  const getUserNameById = (userId: string) => {
    if (state.users) {
      const user: any = state.users.find((user: any) => user.id === userId);
      if (user && userReducer.user) {
        return `${user.firstname} ${user.lastname}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const groupedListByEmoji = () => {
    let groupedList: any = {};
    if (reactions) {
      reactions.forEach((reaction: TReaction) => {
        if (groupedList[reaction.emojiId]) {
          groupedList[reaction.emojiId].push(reaction);
        } else {
          groupedList[reaction.emojiId] = [reaction];
        }
      });
    }

    // If reaction.userId === userReducer.user.id, then it's the user's own reaction, move it to the front of the list
    Object.keys(groupedList).forEach((key) => {
      groupedList[key].sort((a: TReaction, b: TReaction) => {
        if (a.userId === userReducer.user?.id) {
          return -1;
        } else if (b.userId === userReducer.user?.id) {
          return 1;
        } else {
          return 0;
        }
      });
    });

    // Sort the groupedList by object key alphabetically
    const sortedGroupedList: any = {};
    Object.keys(groupedList)
      .sort()
      .forEach((key) => {
        sortedGroupedList[key] = groupedList[key];
      });

    return sortedGroupedList;
  };

  const groupedList = groupedListByEmoji() || [];

  const noteReactions = () => {
    if (groupedList) {
      return Object.keys(groupedList).map((key: any, i: number) => {
        return (
          <Col
            key={i}
            style={{
              cursor: 'pointer',
              marginLeft: 5,
            }}
          >
            <Popover
              usePortal={true}
              canEscapeKeyClose
              hoverOpenDelay={500}
              position="top"
              interactionKind="hover"
              content={
                <Row style={{ padding: 10, width: 280 }} align="middle">
                  <Col span={3} style={{ fontSize: '1.5em' }}>
                    <NoteFeedEmoji emojiId={key} />
                  </Col>
                  <Col span={21} style={{ paddingLeft: 10 }}>
                    {groupedList[key].map((reaction: TReaction, i: number) => {
                      if (reaction.userId === userReducer.user?.id) {
                        return (
                          <Row align="middle">
                            <Col span={16} style={{ paddingTop: 2 }}>
                              <Tooltip
                                fill
                                hoverOpenDelay={1000}
                                content={dayjs(reaction.date).format('DD/MM/YYYY HH:mm:ss')}
                                key={i}
                              >
                                <span style={{ fontWeight: 500 }}>You</span>
                              </Tooltip>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <Button
                                small
                                minimal
                                text="Remove"
                                intent="primary"
                                onClick={onReactionRemoved}
                              />
                            </Col>
                          </Row>
                        );
                      } else {
                        return (
                          <Row>
                            <Col span={24} key={i} style={{ marginBottom: 3 }}>
                              <Row>
                                <Tooltip
                                  hoverOpenDelay={1000}
                                  content={dayjs(reaction.date).format('DD/MM/YYYY HH:mm:ss')}
                                  key={i}
                                  fill
                                >
                                  <span>{getUserNameById(reaction.userId)}</span>
                                </Tooltip>
                              </Row>
                            </Col>
                          </Row>
                        );
                      }
                    })}
                  </Col>
                </Row>
              }
            >
              <Tag round minimal style={{ background: '#fdfdfd' }} key={i}>
                <span style={{ marginRight: 5, fontSize: '1.1em' }} key={i}>
                  <NoteFeedEmoji emojiId={key} key={i} />
                </span>
                <span style={{ fontSize: '0.9em' }}>{groupedList[key].length}</span>
              </Tag>
            </Popover>
          </Col>
        );
      });
    } else {
      <></>;
    }
  };

  return <>{noteReactions()}</>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(NoteReactionsList);
