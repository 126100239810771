import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Checkbox, List, Modal, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
  updateOrCreateRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { httpPost } from '../../../../../shared/http/requests';
import { canUserCreateRecord } from '../../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';

const { Option } = Select;
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER, ORDER, ORDER_ITEM, ADDRESS } = SchemaModuleEntityTypeEnums;

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  hidden?: string[];
  getAssociations: any;
  alertMessage: any;
  userReducer: any;
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb?: any) => void;
  createAssociations: (params: any, cb?: any) => void;
}

interface State {
  addressRecord: DbRecordEntityTransform | undefined;
  CMCompletelyDown: boolean;
  isLoading: boolean;
  isLoadingAssociations: boolean;
  orderItems: DbRecordEntityTransform[];
  orderRecord: DbRecordEntityTransform | undefined;
  selected: any[];
  serviceCallReason: any;
  subType: string;
  type: 'INSTALL' | 'SERVICE' | 'INSPECTION';
  visible: boolean;
}

class GenerateWorkOrderFromWorkOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addressRecord: undefined,
      CMCompletelyDown: false,
      isLoading: false,
      isLoadingAssociations: false,
      orderItems: [],
      orderRecord: undefined,
      selected: [] as { entity?: string; recordId: string }[],
      serviceCallReason: undefined,
      subType: '',
      type: 'INSTALL',
      visible: false,
    };
  }

  // Show Modal and fetch Associations
  showModal = () => {
    this.setState({
      visible: true,
      isLoading: false,
      selected: [],
      type: 'INSTALL',
      subType: '',
      serviceCallReason: undefined,
      CMCompletelyDown: false,
    });
    this.getAllAssociations();
  };

  addRemoveItem = (item: DbRecordEntityTransform) => {
    if (this.state.selected.find((elem) => elem.recordId === item.id)) {
      // remove the item
      this.setState({
        selected: this.state.selected.filter((elem) => elem.recordId !== item.id),
      });
    } else {
      this.setState((prevState: any) => ({
        selected: [
          ...prevState.selected,
          {
            entity: item.entity,
            recordId: item.id,
          },
        ],
      }));
    }
  };

  handleOk = async (e: any) => {
    const { schemaReducer, record, alertMessage, createAssociations } = this.props;

    const WOSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (this.state.selected && this.state.selected.length < 1) {
      alertMessage({
        body: 'please select items for the work order',
        type: 'error',
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    if (record && WOSchema) {
      const orderItems = this.state.selected.map((elem) => ({
        entity: elem.entity,
        recordId: elem.recordId,
      }));

      await httpPost(
        `${FIELD_SERVICE_MODULE}/v1.0/${WORK_ORDER}/order/${this.state.orderRecord?.id}`,
        {
          properties: {
            Type: this.state.type,
            SubType: this.state.subType,
            ServiceCallReason: this.state.serviceCallReason,
            CMCompletelyDown: this.state.CMCompletelyDown,
          },
          orderItems,
        },
      )
        .then((res: any) => {
          // Create Association between the initial and new Work Order
          const newWorkOrder = res?.data?.data[0];
          const modelAssociation = WOSchema?.associations?.find(
            (elem: any) => elem?.label === `${WORK_ORDER}__${WORK_ORDER}`,
          );

          createAssociations({
            recordId: this.props.record?.id,
            schema: WOSchema,
            schemaAssociation: modelAssociation,
            createUpdate: [
              {
                entity: `${FIELD_SERVICE_MODULE}:${WORK_ORDER}`,
                recordId: newWorkOrder?.id,
                relationType: RelationTypeEnum.CHILD,
              },
            ],
          });

          alertMessage({
            body: 'Work order successfully created',
            type: 'success',
          });
        })
        .catch((err) => {
          const error = err.response ? err.response.data : undefined;
          alertMessage({
            body: (error && error.message) || 'error generating work order',
            type: 'error',
          });
        });

      this.setState({
        visible: false,
        isLoading: false,
        selected: [],
        type: 'INSTALL',
        subType: '',
      });
    }
  };

  handleCancel = (e: any) => {
    this.setState({
      visible: false,
      isLoading: false,
      selected: [],
      type: 'INSTALL',
      subType: '',
    });
  };

  private getAllAssociations() {
    const { getAssociations, record, schemaReducer, getNestedAssociations } = this.props;

    const WOSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (record && WOSchema) {
      this.setState({ isLoadingAssociations: true });

      // Fetch Address
      getAssociations(
        {
          recordId: record.id,
          key: ADDRESS,
          schema: WOSchema,
          entities: [ADDRESS],
        },
        (response: any) => {
          const address = getFirstRelation(response.results, ADDRESS);
          if (address) {
            this.setState({
              addressRecord: address,
            });
          }
        },
      );

      // Fetch Order and Order Items
      getNestedAssociations(
        {
          recordId: record.id,
          key: ORDER,
          schema: WOSchema,
          entity: ORDER,
          nestedEntities: [ORDER_ITEM],
        },
        (response: any) => {
          this.setState({ isLoadingAssociations: false });

          const order = getFirstRelation(response.results, ORDER);
          if (order) {
            this.setState({
              orderRecord: order,
            });
            const orderItems = order[ORDER_ITEM].dbRecords;

            if (orderItems && orderItems.length > 0) {
              this.setState({
                orderItems: orderItems,
              });
            }
          }
        },
      );
    }

    return;
  }

  isSelected = (item: any) => {
    if (item && this.state.selected.find((stateItem: any) => stateItem.recordId === item.id)) {
      return true;
    } else return false;
  };

  render() {
    const { schemaReducer, userReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    return (
      <div style={{ padding: '10px 0' }}>
        <Button
          style={{ padding: 0 }}
          size="small"
          type="text"
          onClick={this.showModal}
          disabled={!schema || !canUserCreateRecord(userReducer, schema)}
        >
          Create New
        </Button>
        <Modal
          title="Create Work Order"
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          confirmLoading={this.state.isLoading}
          okButtonProps={{
            disabled:
              (this.state.type === 'SERVICE' && !this.state.serviceCallReason) ||
              this.state.selected.length < 1 ||
              !this.state.addressRecord ||
              !this.state.orderRecord,
          }}
        >
          <div>
            <Select
              style={{ width: '100%' }}
              defaultValue={this.state.type}
              placeholder="Select Type"
              onSelect={(val: any) => {
                this.setState({ type: val, serviceCallReason: undefined });
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="INSTALL">Install</Option>
              <Option value="SERVICE">Service</Option>
              <Option value="INSPECTION">Inspection</Option>
              <Option value="COLLECTION">Collection</Option>
            </Select>

            {this.state.type === 'INSTALL' && (
              <Select
                style={{ width: '100%', marginTop: 16 }}
                defaultValue={this.state.subType}
                placeholder="Sub Type"
                allowClear
                onSelect={(val: any) => {
                  this.setState({
                    subType: !!val ? val : null,
                    serviceCallReason: undefined,
                  });
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="">Default</Option>
              </Select>
            )}

            {this.state.type === 'SERVICE' && (
              <Select
                style={{ width: '100%', paddingTop: 16 }}
                defaultValue={this.state.serviceCallReason}
                placeholder="Select Reason"
                onSelect={(val: any) => {
                  this.setState({ serviceCallReason: val });
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {schema?.columns
                  ?.find((col) => col['name'] === 'ServiceCallReason')
                  ?.options?.map((opt) => (
                    <Option value={opt.value}>{opt.label}</Option>
                  ))}
              </Select>
            )}

            {/* Customer Completely Down */}
            {this.state.type === 'SERVICE' ? (
              <Checkbox
                style={{ paddingTop: 20 }}
                checked={this.state.CMCompletelyDown}
                onChange={(e) => this.setState({ CMCompletelyDown: !this.state.CMCompletelyDown })}
              >
                Customer Completely Down
              </Checkbox>
            ) : (
              <></>
            )}

            <List
              loading={this.state.isLoadingAssociations}
              style={{ marginTop: 20 }}
              size="small"
              header={<div>Items</div>}
              bordered
              dataSource={this.state.orderItems}
              renderItem={(item: DbRecordEntityTransform) => (
                <List.Item
                  actions={[
                    <Checkbox
                      checked={this.isSelected(item)}
                      onChange={() => this.addRemoveItem(item)}
                    >
                      Add
                    </Checkbox>,
                  ]}
                >
                  <List.Item.Meta
                    title={item.title}
                    description={getProperty(item, 'Description')}
                  />
                  <div>{getProperty(item, 'TotalPrice')}</div>
                </List.Item>
              )}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  createAssociations: (params: any, cb: any) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
});

export default connect(mapState, mapDispatch)(GenerateWorkOrderFromWorkOrder);
