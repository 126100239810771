import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, message, Upload } from 'antd';
import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../../core/recordsAssociations/store/actions';
import { getHostName } from '../../../../../../shared/http/helpers';
import { hasAnyRoles } from '../../../../../../shared/permissions/rbacRules';

interface Props {
  record: DbRecordEntityTransform | undefined;
  userReducer: any;
  getAssociations: any;
}

const SplicingMatrixUpload: FC<Props> = (props: Props) => {
  const { record, userReducer } = props;
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const beforeUpload = async (file: any) => {
    const isLt25M = file.size / 1024 / 1024 < 25;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isLt25M) {
      message.error('File must be smaller than 25MB!');
    }

    try {
      if (!isJpgOrPng) {
        const newFile = file
          ? new File([file], file.name.split('.').pop(), {
              type: file.type,
              lastModified: file.lastModified,
            })
          : false;
        return isLt25M && newFile;
      }
    } catch (err) {
      return isLt25M && file;
    }
  };

  function fileUploadProps() {
    return {
      name: 'file',
      multiple: false,
      data: {
        coordinates: undefined,
        category: undefined,
        device: isMobile ? 'MOBILE' : 'DESKTOP',
        entity: record?.entity,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem(`token`),
      },
      action: `${getHostName()}/ProjectModule/v1.0/network/manualsplicing/${record?.id}/upload`,
      onChange(info: any) {
        const { status } = info.file;
        if (status === 'uploading' && !isUploading) {
          setIsUploading(true);
        }
        if (status !== 'uploading' && isUploading) {
          setIsUploading(false);
        }
        if (status === 'done') {
          // const fileDbRecord: DbRecordEntityTransform = info.file.response.data;
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
  }

  return (
    <Upload {...fileUploadProps()} beforeUpload={beforeUpload}>
      <Button
        loading={isUploading}
        style={{ width: 150 }}
        disabled={
          isUploading ||
          !hasAnyRoles(
            userReducer,
            'NetworkPlanningLead',
            'RegionalPlanningLead',
            'ConnectionAdmin',
          )
        }
      >
        {isUploading ? 'Uploading...' : 'Upload'}
      </Button>
    </Upload>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
});

export default connect(mapState, mapDispatch)(SplicingMatrixUpload);
