import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet } from '../../../../shared/http/requests';
import { TMention } from './store/types';

export const getMentionsForInbox = async (userId: string): Promise<TMention[]> => {
  return httpGet(`SupportModule/v1.0/Notes/mentions/${userId}`)
    .then((res: any) => res?.data?.data || [])
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};

export const getAssignmentsForInbox = async (
  userId: string,
): Promise<DbRecordEntityTransform[]> => {
  return httpGet(`SupportModule/v1.0/Assignments/user/${userId}`)
    .then((res: any) => res?.data?.data || [])
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};
