import React from 'react';
import { Badge, Card, Checkbox, Col, Divider, Row, Select, Spin, Tabs, Typography } from 'antd';
import Search from 'antd/lib/input/Search';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { toSentenceCase } from '../../../../../shared/utilities/stringHelpers';

import { useAFPApprovalService, useAFPApprovalState } from '../context';
import {
  filterFpes,
  getFeatureTypeOptionsFromFpes,
  getSelectedRecords,
  groupFpesByStatusAndType
} from '../context/helpers';
import { FPEStatusFilter } from '../context/hooks/useAFPApprovalReducer/types';

import { SearchResults } from './SearchResults';

const allStatuses: FPEStatusFilter[] = ['todo', 'approved', 'rejected'];

export const FPESelectPanel: React.FC = () => {
  const afpApprovalState = useAFPApprovalState();

  const {
    records: { allFpes },
    fpeSelection: { filters, selectAll }
  } = afpApprovalState;

  const selectedRecords = getSelectedRecords(afpApprovalState);

  const {
    setStatusFilter,
    setTextFilter,
    setFeatureTypeFilter,
    setSelectAll,
    setSelectedRecords
  } = useAFPApprovalService();

  const { fpesByStatus } = groupFpesByStatusAndType(allFpes.data);

  // FeatureType filter data
  const featureTypeOptions = getFeatureTypeOptionsFromFpes(allFpes.data);

  // SelectAll
  const shouldSelectAll = fpesByStatus[filters.status].length;

  // Toggle Record Selection
  const toggleRecordSelection = (record: DbRecordEntityTransform) => {
    if (selectedRecords.includes(record)) {
      if (selectAll) setSelectAll(false);
      setSelectedRecords(selectedRecords.filter(r => r.id !== record.id));
    } else
      setSelectedRecords([...selectedRecords, record]);
  }

  // Select Record
  const selectRecord = (record: DbRecordEntityTransform) => {
    if (selectedRecords[0] === record) setSelectedRecords([]);
    else setSelectedRecords([record]);
  }

  const onRecordClicked = (record: DbRecordEntityTransform) => {
    // toggleRecordSelection(record);
    selectRecord(record);
  }

  const filteredFpes = filterFpes(fpesByStatus[filters.status], filters);

  const getRecordCountForStatus = (status: FPEStatusFilter) =>
    fpesByStatus[status] ? fpesByStatus[status].length : 0;
  
  return (
    <Card
      className="DataSetBuilderPanelRow"
      title={<Typography.Title level={5}>Feature Price Entries</Typography.Title>}
    >
      <Row>
        <Col span={24}>
          <Tabs
            defaultActiveKey="to_do"
            centered
            activeKey={filters.status}
            onChange={(e: string) => {
              setStatusFilter(e)
            }}
          >
            {allStatuses?.length > 0 ? (
              allStatuses?.map((status: any) => (
                <Tabs.TabPane
                  tab={
                    <>
                      <Badge
                        count={getRecordCountForStatus(status)}
                        showZero
                        style={{
                          backgroundColor:
                            getRecordCountForStatus(status) > 0 ? '#ddeeff' : '#f0f0f0',
                          color: 'black',
                          marginBottom: 2,
                          marginRight: 5,
                        }}
                      />
                      {toSentenceCase(status)}
                    </>
                  }
                  key={String(status)}
                />
              ))
            ) : (
              <></>
            )}
          </Tabs>
        </Col>
        <Col span={24}>
          <Select
            mode="multiple"
            placeholder="Filter by Feature Type"
            options={featureTypeOptions}
            disabled={featureTypeOptions?.length === 0}
            onChange={value => {
              setFeatureTypeFilter(value);
            }}
            style={{ width: '100%', marginBottom: '5px' }}
          />
          <Search
            allowClear
            value={filters.text}
            onChange={(e: any) => {
              setTextFilter(e?.target.value ?? '');
            }}
            onSearch={(value: string) => {
              setTextFilter(value);
            }}
            placeholder={`Search by Title`}
            disabled={getRecordCountForStatus(filters.status as FPEStatusFilter) === 0}
            style={{ width: '100%', marginBottom: '5px' }}
          />
          <Checkbox
            disabled={!shouldSelectAll}
            checked={selectAll}
            onChange={e => setSelectAll(e.target.checked)}
          >
            Select All
          </Checkbox>
        </Col>
        <Col span={24}>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Col>
      </Row>
      <Spin spinning={allFpes.status === 'loading'}>
        <SearchResults
          records={filteredFpes}
          selectedRecords={selectedRecords}
          totalRecords={allFpes.data.length}
          onRecordClicked={onRecordClicked}
        />
      </Spin>
    </Card>
  )
}
