import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import createSagaMiddleware from 'redux-saga';
import monitorReducerEnhancer from '../enhancers/monitorReducer';
import authCheckMiddleware from '../middleware/authCheck';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// Persisted state configuration
const DB_VERSION = 2;
const DB_NAME = 'OdinDB';
const PERSISTED_REDUCERS = [
  'navigationReducer',
  'recordTableReducer',
  'queryBuilderReducer',
  'userReducer',
];

export default function configureStore() {
  const enableDevMode = ['development', 'staging'].includes(import.meta.env.VITE_ENV);

  const sagaMiddleware = createSagaMiddleware();
  let middleware = [authCheckMiddleware, sagaMiddleware];

  // Add redux-logger on development and staging environments
  if (enableDevMode) {
    const logger = createLogger({
      collapsed: (logEntry) => !logEntry.error,
    });
    middleware.push(logger);
  }
  const middlewareEnhancer = applyMiddleware(...middleware);
  const enhancers = [middlewareEnhancer, monitorReducerEnhancer];
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  }
  const composedEnhancers = compose(...enhancers);

  // Add redux state persistence strategy
  const persistConfig = {
    key: 'root',
    storage: storage(DB_NAME),
    whitelist: PERSISTED_REDUCERS,
    version: DB_VERSION,
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, composedEnhancers);
  let persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  // if (import.meta.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  // }

  return { store, persistor };
}

export const { store, persistor } = configureStore();
