import { Alert, Col, Modal, Row } from 'antd';
import { FC, useContext } from 'react';
import { OrderNetworkDevicesContext } from '..';
import { SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE } from '../store/constants';
import { httpPost } from '../../../../../../shared/http/requests';
interface Props {
  onUpdate: () => void;
}

const AddNetworkDeviceModal: FC<Props> = (props: Props) => {
  const { onUpdate } = props;
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);

  const data = state.addDeviceModalData;
  const open = state.addDeviceModalVisible;

  const closeModal = () => {
    dispatch({ type: SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE, payload: false });
  };

  const hasApplyAction = () => {
    return data?.actions?.apply;
  };

  const hasCancelAction = () => {
    return data?.actions?.cancel;
  };

  const applyAction = () => {
    if (hasApplyAction()) {
      const request = data?.actions?.apply?.request;
      const body = request?.body;
      const method = request?.method;
      const url = request?.url;

      if (method === 'POST') {
        httpPost(url, body)
          .then((res: any) => {
            closeModal();
            onUpdate();
          })
          .catch((err: any) => {
            closeModal();
          });
      }
    }
  };

  return (
    <Modal
      title="Action needed"
      open={open}
      onCancel={closeModal}
      onOk={applyAction}
      okText="Apply"
      okButtonProps={{ disabled: !hasApplyAction() }}
    >
      <Row style={{ marginTop: 15, marginBottom: 20 }}>
        {/* Show Apply Info */}
        {hasApplyAction() && (
          <Col span={24}>
            <Alert type="info" message={data?.actions?.apply?.message} />
          </Col>
        )}

        {/* Show Cancel Info */}
        {hasCancelAction() && (
          <Col span={24}>
            <Alert type="warning" message={data?.actions?.cancel?.message} />
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default AddNetworkDeviceModal;
