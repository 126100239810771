import { Col, Layout, Row, Statistic, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPipelinesOverviewRequest } from '../../../../core/reporting/store/actions';
import { ReportReducerState } from '../../../../core/reporting/store/reducer';
import { httpPost } from '../../../../shared/http/requests';
import dayjs from 'dayjs';
import { Card, Elevation, HTMLTable, Spinner } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Link } from 'react-router-dom';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  reportReducer: ReportReducerState;
  getPipelinesOverview: any;
}

const Dashboard: FC<Props> = (props: Props) => {
  const [orderData, setOrderDate] = useState<DbRecordEntityTransform[]>([]);
  const [orderListLoading, setLoadingOrders] = useState<boolean>(false);

  useEffect(() => {
    getPipelines();
    getOrdersFromToday();
  }, []);

  function getPipelines() {
    const { getPipelinesOverview } = props;
    getPipelinesOverview();
  }

  function renderOrderPipeline() {
    const { reportReducer } = props;
    const { pipelinesOverview } = reportReducer;
    const orderPipeline = pipelinesOverview.filter(
      (elem: any) => elem.module_name === 'OrderModule' && elem.entity_name === 'Order',
    );
    return (
      <Row style={{ marginBottom: 8, display: 'flex' }}>
        {orderPipeline.map((elem: any, index: number) => (
          <Col key={index} flex={1} style={{ padding: 5 }}>
            <Card>
              {reportReducer.isPipelinesRequesting ? (
                <Spinner />
              ) : (
                <Statistic title={elem.stage_name} value={elem.records} />
              )}
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  async function getOrdersFromToday() {
    setLoadingOrders(true);

    const response = await httpPost('OrderModule/v2.0/records/search', {
      query: {
        entity: 'OrderModule:Order',
        type: 'and',
        value: [
          {
            columnName: 'CreatedAt',
            operator: 'gte',
            value: dayjs().format('YYYY-MM-DD'),
          },
        ],
        sort: {
          createdAt: {
            order: 'desc',
          },
        },
        returnProperties: [
          'id',
          'title',
          'recordNumber',
          'createdAt',
          'properties.*',
          'stage',
          'createdBy',
        ],
        pageSize: 1000,
        pageNumber: 0,
      },
    });
    const data = await response.data.data.records;
    setOrderDate(data);
    setLoadingOrders(false);
  }

  const calculateAgeSince = (date: Date) => {
    const ISODate = DateTime.fromISO(String(date));
    const currentDate = DateTime.now();

    // Calculate the difference in duration
    const duration = currentDate.diff(ISODate);

    const years = duration.as('years');
    const months = duration.as('months');
    const days = duration.as('days');
    const hours = duration.as('hours');
    const minutes = duration.as('minutes');

    // Determine the most significant unit
    let ageString;
    if (years > 1) {
      ageString = `${years.toFixed(1)} ${years > 1 ? 'years' : 'year'}`;
    } else if (months > 1) {
      ageString = `${months.toFixed(1)} ${months > 1 ? 'months' : 'month'}`;
    } else if (days > 1) {
      ageString = `${days.toFixed(1)} ${days > 1 ? 'days' : 'day'}`;
    } else if (hours > 1) {
      ageString = `${hours.toFixed(1)} ${hours > 1 ? 'hours' : 'hour'}`;
    } else if (minutes > 1) {
      ageString = `${hours.toFixed(1)} ${hours > 1 ? 'minutes' : 'minute'}`;
    }

    return ageString;
  };

  return (
    <Layout style={{ padding: 12, marginTop: 1 }}>
      <div style={{ marginTop: 24 }}>
        <Typography.Title level={3}>Orders</Typography.Title>
        {renderOrderPipeline()}
      </div>
      <Row gutter={16}>
        <Col span={24}>
          <Card elevation={Elevation.ONE}>
            <Typography.Title level={4}>
              {dayjs().format('MMMM D')} ({orderData?.length})
            </Typography.Title>
            <HTMLTable className={'order-table'} striped style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Record No.</th>
                  <th>Title</th>
                  <th>Stage</th>
                  <th>Source</th>
                  <th>Created By</th>
                  <th>Age</th>
                </tr>
              </thead>
              <tbody>
                {orderListLoading ? (
                  <tr>
                    <Spinner />
                  </tr>
                ) : (
                  orderData?.map((order) => (
                    <tr key={order.id}>
                      <td>
                        <Link to={`/OrderModule/Order/${order.id}`}>{order.recordNumber}</Link>
                      </td>
                      <td>{order.title}</td>
                      <td>{order.stage?.name}</td>
                      <td>{getProperty(order, 'Source')}</td>
                      <td>{order.createdBy?.fullName}</td>
                      <td>{calculateAgeSince(order?.createdAt!)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </HTMLTable>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = (state: any) => ({
  reportReducer: state.reportReducer,
});

const mapDispatch = (dispatch: any) => ({
  getPipelinesOverview: (params: { moduleName?: string; entityName?: string }) =>
    dispatch(getPipelinesOverviewRequest(params)),
});

export default connect(mapState, mapDispatch)(Dashboard);
