import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { ISchemaReducer } from '../../../../../schemas/store/reducer';
import { TableReducer } from '../../store/reducer';
import { resetQueryBuilderState, setQueryBuilderState, setSearchQuery } from '../store/actions';
import {
  getQueryBuilderReducer,
  IQueryBuilderByModuleAndEntityReducer,
  QueryBuilderReducer,
} from '../store/reducer';
import { parsePipelineFilterForQuery } from '../helpers/pipelineFilterParsers';
import {
  generateModuleAndEntityKeyFromProps,
  getSavedFilter,
} from '../../../../../../shared/utilities/searchHelpers';
import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { IdentityGroupsReducer } from '../../../../../identityGroups/store/reducer';
import { getGroupsDataRequest } from '../../../../../identityGroups/store/actions';
import { parseGroupsFilterForQuery } from '../helpers/groupsFilterParsers';
import { httpGet } from '../../../../../../shared/http/requests';
import { parseTypeFilterForQuery } from '../helpers/typeFilterParsers';

const { Option } = Select;

interface Props {
  schema: SchemaEntity | undefined;
  schemaReducer: ISchemaReducer;
  identityGroupsReducer: IdentityGroupsReducer;
  userReducer: any;
  queryBuilderReducer: QueryBuilderReducer;
  recordTableReducer: TableReducer;
  setBuilderState: (params: any) => {};
  configure: (params: any) => {};
  getGroupsList: () => {};
}

class GroupsFilterDropdown extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.loadSavedQueries();
    this.loadGroups();
  }

  componentDidUpdate(prevProps: Readonly<Props>, snapshot?: any): void {
    if (
      prevProps.identityGroupsReducer.isRequesting !== this.props.identityGroupsReducer.isRequesting
    ) {
      this.loadSavedQueries();
    }
  }

  loadGroups = async () => {
    this.setState({ isLoadingGroups: true });
    httpGet('IdentityModule/v2.0/rbac/groups?size=10000').then((res: any) => {
      const groups = res?.data?.data || [];
      this.setState({ groups });
      this.setState({ isLoadingGroups: false });
    });
  };

  private loadSavedQueries() {
    const { setBuilderState, schema, schemaReducer, recordTableReducer } = this.props;
    const savedFilter = getSavedFilter(
      schemaReducer,
      recordTableReducer,
      schema?.moduleName ?? '',
      schema?.entityName ?? '',
    );

    if (!!savedFilter) {
      setBuilderState({
        formFields: savedFilter?.formFields,
      });
      const filter = savedFilter?.formFields?.groupsFilters?.[0];
      if (filter) {
        this.applyFilters(filter.value, filter.valueAlias);
      }
    }
  }

  private renderGroupsFilterOptions() {
    const { userReducer, schema } = this.props;
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );

    let groups: OrganizationUserGroupEntity[] = Object.assign([], userReducer.groups);

    // Sort groups by name
    if (groups.length > 0) {
      groups = groups.sort((a: OrganizationUserGroupEntity, b: OrganizationUserGroupEntity) =>
        (a.name ?? '').localeCompare(b.name ?? ''),
      );
    }

    return (
      <Select
        key="groups"
        mode="multiple"
        style={{ width: '100%' }}
        disabled={!userReducer.groups?.length}
        value={queryBuilderReducer.formFields.groupsFilters?.[0]?.value}
        filterOption={(input: string, option: any) =>
          option.label?.toLowerCase().indexOf(input?.toLowerCase()) > -1
        }
        onChange={(val, option) => {
          this.applyFilters(
            val,
            option?.map((op: any) => op?.children),
          );
        }}
      >
        {groups.map((elem: OrganizationUserGroupEntity) => (
          <Option key={elem.id} value={elem.id ? elem.id.toString() : ''} label={elem.name}>
            {elem.name}
          </Option>
        ))}
      </Select>
    );
  }

  private applyFilters(
    values: string[] | null | undefined,
    valuesAliases: string[] | null | undefined,
  ) {
    const { configure, schema, setBuilderState } = this.props;
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );
    const formFields = queryBuilderReducer.formFields;
    let queries = [];

    if ((values && typeof values === 'string') || (Array.isArray(values) && values.length > 0)) {
      // add groups filter to query
      const groupsFilterForQuery = parseGroupsFilterForQuery(values);
      if (groupsFilterForQuery) {
        queries.push(groupsFilterForQuery);
      }

      // add groups filter to the query builder state
      const groupsFilter = {
        esPropPath: 'groups.id.keyword',
        condition: 'filter',
        value: values,
        valueAlias: valuesAliases,
      };
      setBuilderState({
        formFields: { ...formFields, groupsFilters: [groupsFilter] },
      });
    } else {
      // remove groups filters from the query builder state
      setBuilderState({ formFields: { ...formFields, groupsFilters: [] } });
    }

    // 1. Schema type filter
    if (formFields?.typeFilters?.length! > 0) {
      const typesFilterForQuery = parseTypeFilterForQuery(formFields?.typeFilters[0]?.value);
      if (typesFilterForQuery) {
        queries.push(typesFilterForQuery);
      }
    }

    // 2. Pipeline filter
    if (formFields.pipelineFilters?.length! > 0) {
      const pipelineFilterForQuery = parsePipelineFilterForQuery(
        formFields.pipelineFilters[0]?.value,
      );
      if (pipelineFilterForQuery) {
        queries.push(pipelineFilterForQuery);
      }
    }

    // 3. Property filters
    formFields.propertyFilters.map((elem) => queries.push(elem));

    // set search query
    configure({ schema: schema, query: queries, queryType: 'query_string' });
  }

  render() {
    return <div style={{ width: '100%' }}>{this.renderGroupsFilterOptions()}</div>;
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  queryBuilderReducer: state.queryBuilderReducer,
  identityGroupsReducer: state.identityGroupsReducer,
  userReducer: state.userReducer,
  recordTableReducer: state.recordTableReducer,
});
const mapDispatch = (dispatch: any, ownProps: any) => ({
  reset: () => dispatch(resetQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps))),
  setBuilderState: (params: IQueryBuilderByModuleAndEntityReducer) =>
    dispatch(setQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps), params)),
  configure: (params: any) =>
    dispatch(setSearchQuery(generateModuleAndEntityKeyFromProps(ownProps), params)),
  getGroupsList: () => dispatch(getGroupsDataRequest()),
});
export default connect(mapState, mapDispatch)(GroupsFilterDropdown);
