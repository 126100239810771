import React, { useEffect, useRef, useState } from 'react';
import { Cell, Column, RenderMode, Table2 } from '@blueprintjs/table';
import { Popover, Spinner } from '@blueprintjs/core';
import dayjs from 'dayjs';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getFirstRelation, getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import { httpGet } from '../../../../../../shared/http/requests';
import TableCellStageTag from '../../../../../shared/components/TableCellStageTag';

interface ITableData {
  key?: string;
  stage?: DbRecordEntityTransform["stage"];
  woNumber?: string;
  type?: string;
  stageName?: string;
  updatedAt?: Date;
  latestProcessActivityDate?: string;
  latestProcessActivityComment?: string;
};

const LAST_ACTIVITY_COMMENT_TRUNC_SIZE = 10;
interface WorkOrderHistoryProps {
  workOrderId: string;
};

const truncateComment = (comment: string) => {
  return comment.length > LAST_ACTIVITY_COMMENT_TRUNC_SIZE
    ? `${comment.slice(0, LAST_ACTIVITY_COMMENT_TRUNC_SIZE)}...`
    : comment;
};

export const WorkOrderHistory = ({ workOrderId }: WorkOrderHistoryProps) => {
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [workOrderHistory, setWorkOrderHistory] = useState<DbRecordEntityTransform[]>([]);

  const [addressId, setAddressId] = useState<string | undefined>();

  const tableRef = useRef<any>();

  useEffect(() => {
    getWorkOrderAddress(workOrderId);
  }, [workOrderId]);
  
  useEffect(() => {
    if (!addressId) return;

    getWorkOrderHistoryForAddress(addressId);
  }, [addressId]);


  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [tableRef, tableSlice]);

  useEffect(() => {
    if (workOrderHistory.length > 0) {
      let tableData: ITableData[] = Object.assign([], workOrderHistory);

      tableData = workOrderHistory.map((t: DbRecordEntityTransform) => ({
        key: t.id,
        woNumber: t.recordNumber,
        type: t.type,
        stage: t.stage,
        stageName: t.stage?.name,
        updatedAt: t.updatedAt,
        latestProcessActivityComment: getProperty(t, 'LatestProcessActivityComment') || '',
        latestProcessActivityDate: getProperty(t, 'LatestProcessActivityDate') || '',
      }));

      setTableSlice(tableData);
    }
  }, [workOrderHistory, currentPage]);

  const getWorkOrderAddress = async (workOrderId: string) => {
    try {
      setIsLoadingHistory(true);
      const res = await httpGet(`FieldServiceModule/v1.0/db-associations/WorkOrder/${workOrderId}/relations?withSchema=true&entities[]=Address`);
      const relations = res.data.data;
      const address = getFirstRelation(relations, 'Address');
      setAddressId(address?.id);
    } catch (error: any) {
      // alertMessage({ body: 'Could not retrieve work order history. ' + error.message, type: 'error' });
      setAddressId(undefined);
      setWorkOrderHistory([]);
      setIsLoadingHistory(false);
    }
    setIsLoadingHistory(false);
  }

  const getWorkOrderHistoryForAddress = async (addressId: string) => {
    try {
      setIsLoadingHistory(true);
      const res = await httpGet(`FieldServiceModule/v1.0/WorkOrder/address/${addressId}/work-order-history`);
      const woHistory: any[] = res.data.data || [];
      setWorkOrderHistory(woHistory);
    } catch (error: any) {
      // alertMessage({ body: 'Could not retrieve work order history. ' + error.message, type: 'error' });
      setWorkOrderHistory([]);
    }
    setIsLoadingHistory(false);
  };
  if (isLoadingHistory) return <Spinner size={24} style={{ justifyContent: 'center' }} />;

  return (
    <Table2
      ref={tableRef}
      numRows={tableSlice.length}
      defaultRowHeight={40}
      enableMultipleSelection={false}
      enableRowHeader={false}
      renderMode={RenderMode.NONE}
      forceRerenderOnSelectionChange={false}
      cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
      selectionModes={[]}
      columnWidths={[
        getColumnWidthByPercentage(16),
        getColumnWidthByPercentage(16),
        getColumnWidthByPercentage(16),
        getColumnWidthByPercentage(16),
        getColumnWidthByPercentage(16),
        getColumnWidthByPercentage(20),
      ]}
    >
      <Column
        key="woNumber"
        name="WO#"
        cellRenderer={(rowIndex: number) => (
          <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].woNumber}</Cell>
        )}
      />
      <Column
        key="type"
        name="Type"
        cellRenderer={(rowIndex: number) => (
          <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].type}</Cell>
        )}
      />
      <Column
        key="stageName"
        name="Stage"
        cellRenderer={(rowIndex: number) => {
          const elem = tableSlice[rowIndex];
          return (
            <Cell key={tableSlice[rowIndex].key} style={{ paddingTop: 3 }}>
              <TableCellStageTag record={elem} small={false}>
                {elem.stageName}
              </TableCellStageTag>
            </Cell>
          );
        }}
      />
      <Column
        key="updatedAt"
        name="Updated At"
        cellRenderer={(rowIndex: number) => {
          const { updatedAt } = tableSlice[rowIndex];
          return (
            <Cell key={tableSlice[rowIndex].key}>
              {updatedAt ? dayjs(updatedAt).format('DD/MM/YYYY') : 'None'}
            </Cell>
          )}
        }
      />
      <Column
        key="latestProcessActivityComment"
        name="Comment"
        cellRenderer={(rowIndex: number) => {
          const { latestProcessActivityComment} = tableSlice[rowIndex];
          return (
            <Cell key={tableSlice[rowIndex].key}>
              {latestProcessActivityComment && (
                <Popover
                  disabled={
                    latestProcessActivityComment.length! <
                    LAST_ACTIVITY_COMMENT_TRUNC_SIZE
                  }
                  content={
                    <div style={{ padding: 3, maxWidth: 300 }}>
                      {latestProcessActivityComment}
                    </div>
                  }
                  position="left"
                  fill
                >
                  <div
                    className={`lastProcessComment ${
                      latestProcessActivityComment.length! >=
                      LAST_ACTIVITY_COMMENT_TRUNC_SIZE
                        ? 'clickable'
                        : ''
                    }`}
                    style={{
                      marginTop: 3,
                      cursor:
                        latestProcessActivityComment.length! >=
                        LAST_ACTIVITY_COMMENT_TRUNC_SIZE
                          ? 'pointer'
                          : 'auto',
                    }}
                  >
                    <i
                      className="bi bi-chat-left-text"
                      style={{ marginRight: 3, color: '#184A90', width: 3 }}
                    />

                    {truncateComment(latestProcessActivityComment)}
                  </div>
                </Popover>
              )}
            </Cell>
          )}}
      />
      <Column
        key="latestProcessActivityDate"
        name="Comment Date"
        cellRenderer={
          (rowIndex: number) => {
            const { latestProcessActivityDate } = tableSlice[rowIndex];
            return (
              <Cell key={tableSlice[rowIndex].key}>
                {latestProcessActivityDate ? dayjs(latestProcessActivityDate).format('DD/MM/YYYY HH:mm:ss') : 'None'}
              </Cell>
            );
          }
        }
      />
    </Table2>          
  );
}
