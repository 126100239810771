import { Switch } from 'react-router-dom';
import RoleBasedProtectedRoute from '../../core/navigation/RoleBasedProtectedRoute';
import IdentityManagerView from './containers/IdentityManagerView';

const IDENTITY_MANAGER_MODULE = 'IdentityManagerModule';

export const IdentityManagerModuleRoutes = () => {
  return (
    <Switch>
      <RoleBasedProtectedRoute
        exact
        path={`/${IDENTITY_MANAGER_MODULE}`}
        moduleName={IDENTITY_MANAGER_MODULE}
        component={<IdentityManagerView />}
      />
    </Switch>
  );
};
