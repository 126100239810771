import { UploadOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getHostName } from '../../../../../shared/http/helpers';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';

interface Props {
  moduleName: string;
  entityName: string;
  userReducer: any;
  alertMessage: Function;
  modalIsVisible: boolean;
  toggleModalVisibility: any;
}

const ListActionMenuUploaderModal: React.FC<Props> = (props: Props) => {
  const {
    entityName,
    moduleName,
    alertMessage,
    toggleModalVisibility,
    modalIsVisible,
  } = props;

  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

  const beforeUpload = async (file: any) => {
    return new Promise((resolve: any) => {
      if (file.name.indexOf('.csv') > -1) {
        resolve(file as any);
      } else {
        alertMessage({
          body: 'Wrong file type, please upload only CSV files',
          type: 'error',
        });
      }
    });
  };

  const fileUploadProps = () => {
    return {
      name: 'file',
      headers: { Authorization: 'Bearer ' + localStorage.getItem(`token`) },
      action: `${getHostName()}/${moduleName}/v1.0/db/${entityName}/upload`,

      onChange(info: any) {
        const { status } = info.file;

        // Uploading ...
        if (status === 'uploading' && !uploadInProgress) {
          setUploadInProgress(true);
        }

        // Done !
        if (status === 'done') {
          alertMessage({
            body: 'CSV file successfully uploaded',
            type: 'success',
          });
          setUploadInProgress(false);
          toggleModalVisibility();
        } else if (status === 'error') {
          setUploadInProgress(false);
          toggleModalVisibility();
          alertMessage({
            body: 'There was an error uploading the CSV file',
            type: 'error',
          });
        }
      },
      progress: {
        strokeWidth: 3,
        strokeColor: { '0%': '#108ee9', '100%': '#87d068' },
        format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
      },
    };
  };

  return (
    <Modal
      okButtonProps={{ style: { display: 'none' } }}
      open={modalIsVisible}
      onCancel={() => toggleModalVisibility()}
      title="Upload CSV file"
      closable={false}
    >
      <Row>
        <Col span={24}>
          <Dragger
            multiple={false}
            {...fileUploadProps()}
            beforeUpload={beforeUpload as any}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click here or drag CSV file to this area to upload
            </p>
          </Dragger>
        </Col>
      </Row>
    </Modal>
  );
};

const mapState = (state: any) => ({
    userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
    alertMessage: (params: { body: string; type: string }) =>
        dispatch(displayMessage(params)),
})

export default connect(mapState, mapDispatch)(ListActionMenuUploaderModal);
