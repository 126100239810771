import { PartitionOutlined } from '@ant-design/icons';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  List,
  Modal,
  Result,
  Row,
  Switch,
  Tag,
  Typography,
} from 'antd';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPost } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { copyTextToClipboard } from '../../helpers';
import AutosplicingJobListener from '../../listeners/AutosplicingJobListener';
import { updateAutosplicingReducerState } from '../../store/actions';
import { IAutosplicingReducer } from '../../store/types';
import '../../styles.scss';
import ConnectionWarning from './ConnectionWarning';
import './styles.scss';

const { Panel } = Collapse;

interface OwnProps {
  autosplicingReducer: IAutosplicingReducer;
  alertMessage: Function;
  updateAutosplicingReducer: Function;
}

enum QGISClosureTypeEnum {
  'L0' = 1,
  'L1' = 2,
  'L2' = 3,
  'L3' = 4,
  'L4' = 5,
  'LX' = 6,
  'LC' = 7,
  'LM' = 11,
  'LT' = 12,
  'ODF' = 9,
  'OLT' = 10,
}

type Props = OwnProps;

const AutosplicingFiberConnections: FunctionComponent<Props> = (props) => {
  const { Panel } = Collapse;
  const [fiberConnectionConfig, setFiberConnectionConfig] = useState<any>({
    rootClosureType: null,
    closureType: null,
    childClosureType: null,
    spliceSpineAndDistribution: null,
    spliceWithEstimation: false,
  });

  const { autosplicingReducer, updateAutosplicingReducer } = props;
  const { differenceList, formData, closureType } = autosplicingReducer;
  const [loading, setLoading] = useState<boolean>(false);

  const [isModalVisible, setIsModalVisible] = useState<{
    isVisible: boolean;
    action?: 'FIBER' | 'LOOP';
  }>({ isVisible: false, action: 'FIBER' });
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadOutcome, setUploadOutcome] = useState<null | {
    status: 'success' | 'error';
    message: string;
    output: string;
    rawOutput: any;
  }>(null);
  const [fiberStatus, setFiberStatusData] = useState<any>(null);
  const [shouldSkip, goToStep] = useState<number>(0);

  const [fiberConflict, setFiberConflict] = useState<any>({
    cable: null,
    childFibers: [],
    isProcessing: false,
  });

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      goToStep(0);
      setFiberStatusData(null);
      getFiberConnectionStatus();
      firstUpdate.current = false;
      return;
    }
  }, []);

  // Refresh data every time closure is updated
  useEffect(() => {
    if (!uploading) {
      getFiberConnectionStatus();
    }
  }, [autosplicingReducer.enteredClosureId]);

  const createLoopFiberConnections = async () => {
    setUploading(true);
    return await httpPost(`ProjectModule/v1.0/network/autosplicing/fibers/loop-connections`, {
      rootClosureType: fiberConnectionConfig.rootClosureType,
      closureType: fiberConnectionConfig.closureType,
      childClosureType: fiberConnectionConfig.childClosureType,
      closureId: Number(fiberConnectionConfig.closureId),
      cableId: Number(fiberConnectionConfig.cableId),
    })
      .then(async (res) => {
        setUploading(false);
        setUploadOutcome({
          status: 'success',
          message: 'job is successfully processing.',
          output: JSON.stringify(res.data.data),
          rawOutput: {},
        });
      })
      .catch((err) => {
        setUploading(false);
        setUploadOutcome({
          status: 'error',
          message: 'There was an error creating fiber connections.',
          output: JSON.stringify(err.message),
          rawOutput: {},
        });
      });
  };

  const createFiberConnections = async () => {
    setUploading(true);
    return await httpPost(`ProjectModule/v1.0/network/autosplicing/fibers`, {
      rootClosureType: fiberConnectionConfig.rootClosureType,
      closureType: fiberConnectionConfig.closureType,
      childClosureType: fiberConnectionConfig.childClosureType,
      closureId: Number(fiberConnectionConfig.closureId),
      cableId: Number(fiberConnectionConfig.cableId),
      spliceSpineAndDistribution: fiberConnectionConfig.spliceSpineAndDistribution,
      spliceWithEstimation: fiberConnectionConfig.spliceWithEstimation,
    })
      .then(async (res) => {
        setUploading(false);
        setUploadOutcome({
          status: 'success',
          message: 'job is successfully processing.',
          output: JSON.stringify(res.data.data),
          rawOutput: {},
        });
      })
      .catch((err) => {
        setUploading(false);
        let errMessage = 'There was an error creating fiber connections.';
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(err.response.data);
          errMessage = err.response.data?.message;
        }
        setUploadOutcome({
          status: 'error',
          message: errMessage,
          output: JSON.stringify(err.message),
          rawOutput: {},
        });
      });
  };

  const getFiberConnectionStatus = async () => {
    setUploading(true);
    goToStep(0);
    setFiberStatusData(null);
    if (formData?.closure_id) {
      let path = `ProjectModule/v1.0/network/autosplicing/fibers/status/${formData.closure_id}`;
      // we need the option to pass in a cableId for a selected closure + selected cable
      // so that users can be in an L0 and search L2 closures + the cables.
      // we should be able to get this from the cable connection data.
      if (formData?.cable_id) {
        path = path.concat(`?cableId=${formData.cable_id}`);
      }
      return await httpGet(path)
        .then(async (res) => {
          setUploading(false);
          setFiberStatusData(res.data.data);

          if (res.data?.data) {
            updateAutosplicingReducer({ cableIdsWithIssues: Object.keys(res.data?.data?.diffResolution) });
          }

          const differences: boolean =
            (res.data?.data &&
              res.data?.data?.differences &&
              Object.keys(res.data?.data?.differences).length > 0) ||
            (res.data?.data?.splicingActions &&
              Object.keys(res.data?.data?.splicingActions).length > 0)
              ? true
              : false;
          updateAutosplicingReducer({ fiberDifferences: differences });
        })
        .catch((err) => {
          setUploading(false);
          console.error(err);
        });
    }
  };

  const handleCreateFiberConnections = () => {
    createFiberConnections();
    setIsModalVisible({ isVisible: false });
  };

  const handleCreateLoopConnections = () => {
    createLoopFiberConnections();
    setIsModalVisible({ isVisible: false });
  };

  const hasUnsavedDifferences = () => {
    return (
      uploading || differenceList!.data?.length > 0 || differenceList!.validationErrors?.length > 0
    );
  };

  const shouldEnableL4ActionPanel = () => {
    if (fiberStatus) {
      return (
        !!fiberStatus.splicingActions['L4'] ||
        !!fiberStatus.splicingActions['LM'] ||
        !!fiberStatus.splicingActions['LC']
      );
    }
    return false;
  };

  const shouldEnableL3ActionPanel = () => {
    if (fiberStatus) {
      return !!fiberStatus.splicingActions['L3'];
    }
    return false;
  };

  // do not show this panel if we are viewing an L1 or L0 closure
  const shouldEnableL2ActionPanel = () => {
    if (fiberStatus) {
      return !!fiberStatus.splicingActions['L2'];
    }
    return false;
  };

  const shouldEnableL1ActionPanel = () => {
    if (fiberStatus) {
      return !!fiberStatus.splicingActions['L1'];
    }
    return false;
  };

  const shouldEnableL0ActionPanel = () => {
    if (fiberStatus) {
      return !!fiberStatus.splicingActions['L0'];
    }
    return false;
  };

  const shouldEnableL2ActionPanelForSnD = () => {
    if (fiberStatus) {
      return fiberStatus.splicingActions['L2'];
    }
    return false;
  };

  const shouldEnableL1ActionPanelForSnD = () => {
    if (fiberStatus) {
      return fiberStatus.splicingActions['L1'] && !fiberStatus.splicingActions['L2'];
    }
    return false;
  };

  const shouldEnableL0ActionPanelForSnD = () => {
    if (fiberStatus) {
      return (
        fiberStatus.splicingActions['L0'] &&
        !fiberStatus.splicingActions['L1'] &&
        !fiberStatus.splicingActions['L2']
      );
    }
    return false;
  };

  const inConnectionsAvailable = () => {
    const record = autosplicingReducer.selectedEntityOdinRecord;

    if (record && record.CableConnection?.dbRecords?.length > 0) {
      const inConnection = record.CableConnection?.dbRecords?.find(
        (connection: any) => getProperty(connection, 'Direction') === 'IN',
      );

      return inConnection ? true : false;
    } else {
      return false;
    }
  };

  const renderCreateSplicingActionPanel = () => {
    if (
      closureType === 'L0' &&
      fiberStatus &&
      fiberStatus.splicingActions &&
      Object.keys(fiberStatus.splicingActions).length > 0
    ) {
      // for Spine and Distribution splicing we will want to
      // override default conditions below if the selected closure is an L0
      return (
        <>
          <Row>
            <Col span={24}>
              <Alert
                style={{ fontSize: '0.9em', borderRadius: 4 }}
                message="Select an L0 closure to splice S&D"
                type="warning"
              />
            </Col>
          </Row>
          {shouldEnableL2ActionPanelForSnD() && (
            <div>
              <Row>
                <Col span={24} style={{ marginTop: 15 }}>
                  <Typography.Text strong>Closures missing fiber connections</Typography.Text>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  {fiberStatus.splicingActions['L2'].map((closure: number) => (
                    <Tag
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        copyTextToClipboard(closure, `Id copied to clipboard.`, props.alertMessage);
                      }}
                    >
                      {closure}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Divider style={{ margin: '14px 0' }} />
              <Row>
                <Col span={24}>
                  <Button
                    loading={uploading}
                    type="primary"
                    disabled={!shouldEnableL2ActionPanel()}
                    onClick={() => {
                      setIsModalVisible({ isVisible: true, action: 'FIBER' });
                      setFiberConnectionConfig({
                        closureId: formData.closure_id!,
                        cableId: formData.cable_id!,
                        rootClosureType: QGISClosureTypeEnum.L1,
                        closureType: QGISClosureTypeEnum.L2,
                        childClosureType: QGISClosureTypeEnum.L3,
                        spliceSpineAndDistribution: true,
                        spliceWithEstimation: !fiberConnectionConfig.spliceWithEstimation,
                      });
                    }}
                  >
                    Splice L2 Closures
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {shouldEnableL1ActionPanelForSnD() && (
            <div>
              <Row>
                <Col span={24} style={{ marginTop: 15 }}>
                  <Typography.Text strong>Closures missing fiber connections</Typography.Text>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  {fiberStatus.splicingActions['L1'].length > 0 ? (
                    fiberStatus.splicingActions['L1'].map((closure: number) => (
                      <Tag
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          copyTextToClipboard(
                            closure,
                            `Id copied to clipboard.`,
                            props.alertMessage,
                          );
                        }}
                      >
                        {closure}
                      </Tag>
                    ))
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Divider style={{ margin: '14px 0' }} />
              <Row style={{ marginTop: 16 }}>
                <Col span={24}>
                  <Button
                    loading={uploading}
                    type="primary"
                    disabled={!shouldEnableL1ActionPanel()}
                    onClick={() => {
                      setIsModalVisible({ isVisible: true, action: 'FIBER' });
                      setFiberConnectionConfig({
                        closureId: formData.closure_id,
                        cableId: formData.cable_id,
                        rootClosureType: QGISClosureTypeEnum.L0,
                        closureType: QGISClosureTypeEnum.L1,
                        childClosureType: QGISClosureTypeEnum.L2,
                        spliceSpineAndDistribution: true,
                      });
                    }}
                  >
                    Splice L1 Closures
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          {shouldEnableL0ActionPanelForSnD() && (
            <div>
              <Row>
                <Col span={24} style={{ marginTop: 15 }}>
                  <Typography.Text strong>Closures missing fiber connections</Typography.Text>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  {fiberStatus.splicingActions['L0'].length > 0 ? (
                    fiberStatus.splicingActions['L0'].map((closure: number) => (
                      <Tag
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          copyTextToClipboard(
                            closure,
                            `Id copied to clipboard.`,
                            props.alertMessage,
                          );
                        }}
                      >
                        {closure}
                      </Tag>
                    ))
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>

              <Divider style={{ margin: '14px 0' }} />

              <Row style={{ marginTop: 16 }}>
                <Col span={24}>
                  <Button
                    loading={uploading}
                    type="primary"
                    disabled={!shouldEnableL0ActionPanel()}
                    onClick={() => {
                      setIsModalVisible({ isVisible: true, action: 'FIBER' });
                      setFiberConnectionConfig({
                        closureId: formData.closure_id,
                        cableId: formData.cable_id,
                        rootClosureType: QGISClosureTypeEnum.ODF,
                        closureType: QGISClosureTypeEnum.L0,
                        childClosureType: QGISClosureTypeEnum.L1,
                      });
                    }}
                  >
                    Splice L0 Closures
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </>
      );
    } else if (shouldEnableL4ActionPanel() && shouldSkip < 1) {
      return (
        <>
          <Row>
            <Col span={24} style={{ marginTop: 15 }}>
              <Typography.Text strong>Closures missing fiber connections</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              {[
                ...(fiberStatus?.splicingActions['L4']?.length > 0
                  ? fiberStatus.splicingActions['L4']
                  : []),
                ...(fiberStatus?.splicingActions['LM']?.length > 0
                  ? fiberStatus.splicingActions['LM']
                  : []),
                ...(fiberStatus?.splicingActions['LC']?.length > 0
                  ? fiberStatus.splicingActions['LC']
                  : []),
              ].map((closure: number) => (
                <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    copyTextToClipboard(closure, `Id copied to clipboard.`, props.alertMessage);
                  }}
                >
                  {closure}
                </Tag>
              ))}
            </Col>
          </Row>
          <Divider style={{ margin: '14px 0' }} />
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                loading={uploading}
                disabled={hasUnsavedDifferences()}
                onClick={() => {
                  setIsModalVisible({ isVisible: true, action: 'FIBER' });
                  setFiberConnectionConfig({
                    closureId: formData.closure_id,
                    cableId: formData.cable_id,
                    rootClosureType: QGISClosureTypeEnum.L3,
                    closureType: QGISClosureTypeEnum.L4,
                    childClosureType: null,
                  });
                }}
              >
                Splice L4/LM/LC Closures
              </Button>
            </Col>
          </Row>
        </>
      );
    } else if (shouldEnableL3ActionPanel() && shouldSkip < 2) {
      return (
        <>
          <Row>
            <Col span={24} style={{ marginTop: 15 }}>
              <Typography.Text strong>Closures missing fiber connections</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              {fiberStatus.splicingActions['L3'].length > 0 ? (
                fiberStatus.splicingActions['L3'].map((closure: number) => (
                  <Tag
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      copyTextToClipboard(closure, `Id copied to clipboard.`, props.alertMessage);
                    }}
                  >
                    {closure}
                  </Tag>
                ))
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Divider style={{ margin: '14px 0' }} />
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                loading={uploading}
                disabled={hasUnsavedDifferences()}
                onClick={() => {
                  setIsModalVisible({ isVisible: true, action: 'FIBER' });
                  setFiberConnectionConfig({
                    closureId: formData.closure_id,
                    cableId: formData.cable_id,
                    rootClosureType: QGISClosureTypeEnum.L2,
                    closureType: QGISClosureTypeEnum.L3,
                    childClosureType: QGISClosureTypeEnum.L4,
                  });
                }}
              >
                Splice L3 Closures
              </Button>
            </Col>
          </Row>
        </>
      );
    } else if (shouldEnableL2ActionPanel() && shouldSkip < 3) {
      return (
        <>
          <Row>
            <div>
              <Form.Item label="Estimation">
                <Switch
                  defaultChecked={
                    fiberConnectionConfig.spliceWithEstimation ||
                    shouldEnableL0ActionPanel() ||
                    shouldEnableL1ActionPanel()
                  }
                  onChange={() =>
                    setFiberConnectionConfig({
                      spliceWithEstimation: !fiberConnectionConfig.spliceWithEstimation,
                    })
                  }
                />
              </Form.Item>
            </div>
          </Row>
          <Row>
            <Col span={24}>
              <Typography.Text strong>Closures missing fiber connections</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              {fiberStatus.splicingActions['L2'].length > 0 ? (
                fiberStatus.splicingActions['L2'].map((closure: number) => (
                  <Tag
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      copyTextToClipboard(closure, `Id copied to clipboard.`, props.alertMessage);
                    }}
                  >
                    {closure}
                  </Tag>
                ))
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Divider style={{ margin: '14px 0' }} />
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                loading={uploading}
                disabled={hasUnsavedDifferences()}
                onClick={() => {
                  setIsModalVisible({ isVisible: true, action: 'FIBER' });
                  if (shouldEnableL1ActionPanel()) {
                    setFiberConnectionConfig({
                      closureId: formData.closure_id,
                      cableId: null,
                      rootClosureType: QGISClosureTypeEnum.L1,
                      closureType: QGISClosureTypeEnum.L2,
                      childClosureType: QGISClosureTypeEnum.L2,
                      spliceWithEstimation: true,
                    });
                  } else if (shouldEnableL0ActionPanel()) {
                    // from the L0 trace view/ a user should be able to estimate the L2
                    setFiberConnectionConfig({
                      closureId: formData.closure_id,
                      cableId: null,
                      rootClosureType: QGISClosureTypeEnum.L1,
                      closureType: QGISClosureTypeEnum.L2,
                      childClosureType: QGISClosureTypeEnum.L2,
                      spliceWithEstimation: true,
                    });
                  } else {
                    setFiberConnectionConfig({
                      closureId: formData.closure_id,
                      cableId: formData.cable_id,
                      rootClosureType: QGISClosureTypeEnum.L1,
                      closureType: QGISClosureTypeEnum.L2,
                      childClosureType: QGISClosureTypeEnum.L3,
                      spliceWithEstimation: fiberConnectionConfig.spliceWithEstimation,
                    });
                  }
                }}
              >
                Splice L2 Closure
              </Button>
            </Col>
          </Row>
        </>
      );
    } else if (shouldEnableL1ActionPanel()) {
      return (
        <>
          <Row>
            <Typography.Text strong>
              Select an L1 closure from the cable diagram to splice
            </Typography.Text>
          </Row>

          <Row>
            <Col span={24} style={{ marginTop: 15 }}>
              <Typography.Text strong>Closures missing fiber connections</Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              {fiberStatus.splicingActions['L1'].length > 0 ? (
                fiberStatus.splicingActions['L1'].map((closure: number) => (
                  <Tag
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      copyTextToClipboard(closure, `Id copied to clipboard.`, props.alertMessage);
                    }}
                  >
                    {closure}
                  </Tag>
                ))
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Divider style={{ margin: '14px 0' }} />

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                loading={uploading}
                disabled={hasUnsavedDifferences()}
                onClick={() => {
                  setIsModalVisible({ isVisible: true, action: 'FIBER' });
                  setFiberConnectionConfig({
                    closureId: formData.closure_id,
                    cableId: formData.cable_id,
                    rootClosureType: QGISClosureTypeEnum.L0,
                    closureType: QGISClosureTypeEnum.L1,
                    childClosureType: QGISClosureTypeEnum.L2,
                  });
                }}
              >
                Splice L1 Closures
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return <Result status="success" title="Fiber splicing complete" />;
    }
  };

  function renderFiberConnectionConflictResolutionPanel() {
    // Call API to create fiber connections
    async function createConnections(cable: string, url: string, body: any) {
      setFiberConflict({
        cable,
        body,
        isProcessing: true,
      });

      setLoading(true);

      console.log('cable', cable);
      console.log('url', url);
      console.log('body', body);

      // fiberConflict
      return await httpPost(url, body)
        .then(async (res) => {
          console.log('res', res);
          await getFiberConnectionStatus();
          setFiberConflict({
            cable: null,
            childFibers: [],
            isProcessing: false,
          });
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log('err.response.data', err.response.data);
            alert(err.response.data?.message);
          } else {
            alert('error processing your request.');
          }
          setFiberConflict({
            cable: null,
            childFibers: [],
            isProcessing: false,
          });
          setLoading(false);
        });
    }

    // Take a diffResolutionObject, map object keys to array and group by fromClosure property
    const groupByFromClosure = (diffResolutionArr: any) => {
      // let newArray: any[] = diffResolutionArr;
      // newArray.sort((a: any, b: any) => a.fromClosure - b.fromClosure);
      return diffResolutionArr.reduce((acc: any, obj: any) => {
        if (acc[`${obj.fromClosureSequence}_${obj.fromClosure}`]) {
          acc[`${obj.fromClosureSequence}_${obj.fromClosure}`].push(obj);
        } else {
          acc[`${obj.fromClosureSequence}_${obj.fromClosure}`] = [obj];
        }
        return acc;
      }, {});
    };

    const formatConnectionString = (val: string) => {
      const split = val.split('-');
      return `${split[1]} - ${split[2]}`;
    };

    if (fiberStatus?.diffResolution && fiberStatus.diffActions?.length > 0) {
      const differenceGroups = groupByFromClosure(fiberStatus.diffActions);

      // 313975: Array(8) 0: {title: 'Feed (564211)', url: 'Project...

      return Object.keys(differenceGroups).map((group: any, outrIndex: number) => (
        <>
          {/* Group Title */}
          <Divider
            orientation="left"
            orientationMargin={0}
            style={{ fontSize: '1em', marginTop: 8, marginBottom: 10 }}
          >
            {`(${outrIndex + 1}) ${group.split('_')[1]}`}
          </Divider>
          <Collapse>
            {differenceGroups[group]
              .map((action: any, inrIndex: number) => (
                <Panel
                  disabled={outrIndex > 0 || inrIndex > 0}
                  header={`(${inrIndex + 1}) ${formatConnectionString(action.connection)}`}
                  key={action.connection}
                >
                  <List
                    dataSource={action?.body || []}
                    header={
                      <Button
                        loading={loading}
                        disabled={loading}
                        size={'small'}
                        key="list-loadmore-edit"
                        onClick={() => createConnections(action.cable, action.url, action.body)}
                      >
                        Apply Changes
                      </Button>
                    }
                    renderItem={(bodyElem: any) => (
                      <List.Item>
                        <Typography.Text>
                          {`Cable ${bodyElem?.cable_ext_ref} T${bodyElem?.tube_number}:F${bodyElem?.fiber_number}`}
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                </Panel>
              ))}
          </Collapse>
        </>
      ));

      // <Collapse>
      //   {diffResolution?.map((resolution:any) => (
      //     <Panel header={resolution.connection} key={resolution.connection}>
      //       <List
      //         dataSource={resolution.body}
      //         header={
      //           <Button
      //             size={'small'}
      //             key="list-loadmore-edit"
      //             onClick={() =>
      //               createConnections(
      //                 resolution.cable,
      //                 fiberStatus.resolution.url,
      //                 fiberStatus.resolution.body,
      //               )
      //             }
      //           >
      //             Apply Changes
      //           </Button>
      //         }
      //         renderItem={(bodyElem: any) => (
      //           <List.Item>
      //             <Typography.Text>
      //               {`Cable ${bodyElem?.cable_ext_ref} T${bodyElem?.tube_number}:F${bodyElem?.fiber_number}`}
      //             </Typography.Text>
      //           </List.Item>
      //         )}
      //       />
      //     </Panel>
      //   ))}
      // </Collapse>
    }
    return <Result status="success" title="No conflicts" />;
  }

  return (
    <>
      <AutosplicingJobListener
        callbackFn={getFiberConnectionStatus}
        allJobs={autosplicingReducer.allJobs!}
      />
      <Modal
        title="Apply Changes"
        open={isModalVisible.isVisible}
        onOk={() => {
          if (isModalVisible.action === 'FIBER') {
            handleCreateFiberConnections();
          } else if (isModalVisible.action === 'LOOP') {
            handleCreateLoopConnections();
          }
        }}
        onCancel={() => setIsModalVisible({ isVisible: false })}
      >
        <p>Are you sure you want to Apply the changes?</p>
      </Modal>

      {uploadOutcome ? (
        <Row>
          <Col
            span={24}
            style={{
              padding: 10,
              borderRadius: 8,
              backgroundColor: uploadOutcome?.status === 'success' ? '#f6ffed' : '#fff2f0',
              border:
                uploadOutcome?.status === 'success' ? '1px solid #b7eb8f' : '1px solid #ffccc7',
            }}
          >
            <span>{uploadOutcome?.message}</span>
            <Collapse style={{ fontSize: '0.9em', marginTop: 10 }}>
              <Panel header="More Details" key={2} style={{ overflow: 'hidden' }}>
                <pre>{uploadOutcome.output}</pre>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {!inConnectionsAvailable() ? (
        ConnectionWarning(
          QGISClosureTypeEnum[
            getProperty(autosplicingReducer.selectedEntityOdinRecord!, 'ClosureType')
          ],
          getProperty(autosplicingReducer.selectedEntityOdinRecord, 'ExternalRef'),
        )
      ) : (
        <>
          <Card
            title={
              <>
                <PartitionOutlined style={{ marginRight: 7 }} />
                <span>Fiber Splicing</span>
              </>
            }
            size="small"
            style={{ marginBottom: 20 }}
            loading={uploading}
            extra={[
              <Button
                type="link"
                size="small"
                disabled={uploading}
                onClick={() => getFiberConnectionStatus()}
              >
                Refresh
              </Button>,
            ]}
          >
            {Object.keys(fiberStatus?.diffResolution || {})?.length < 1 ? (
              renderCreateSplicingActionPanel()
            ) : (
              <Row>
                <Col span={24}>
                  <Alert
                    style={{ fontSize: '0.9em', borderRadius: 4 }}
                    message="Fix splicing conflicts below before auto-splicing"
                    type="warning"
                  />
                </Col>
              </Row>
            )}
          </Card>

          <Card
            className="FiberConnectionConflictsCard"
            title={
              <>
                <PartitionOutlined style={{ marginRight: 7 }} />
                <span>Fiber Connection Conflicts</span>
              </>
            }
            size="small"
            style={{ marginBottom: 20 }}
            loading={uploading}
          >
            {renderFiberConnectionConflictResolutionPanel()}
          </Card>

          <Card
            title={
              <>
                <PartitionOutlined style={{ marginRight: 7 }} />
                <span>Fiber Connection Details </span>
              </>
            }
            size="small"
            style={{ marginBottom: 20 }}
            loading={uploading}
          >
            <List
              dataSource={fiberStatus?.diffDetails || []}
              renderItem={(bodyElem: any) => (
                <List.Item>
                  <div>
                    <Typography.Text>{bodyElem.connection}</Typography.Text>
                    <Typography.Text>{bodyElem.description}</Typography.Text>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </>
      )}
    </>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingFiberConnections);
