import { H5, Spinner } from '@blueprintjs/core';
import { CaseApi } from '@d19n/sandbox-odin-sdk/dist/api-sdk-v1/CaseApi';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useState } from 'react';
import { AppToaster } from '../../../../shared/components/Toaster';
import PersonPlus from '../../../../shared/components/Icons/PersonPlus';
import ConversationDetailsDialog from '../../../../shared/components/ConversationDetailsDialog';

interface Props {
  conversationInformation: any;
  conversationParticipants: any[];
  isLoading: boolean;
  caseClient: CaseApi;
  conversation: any;
  fetchConversationInformation: (conversation: any[]) => void;
  setOpenParticipantDrawer: (value: boolean) => void;
}

const ConversationDetailsCard = ({
  conversationInformation,
  conversationParticipants,
  isLoading,
  caseClient,
  conversation,
  fetchConversationInformation,
  setOpenParticipantDrawer,
}: Props) => {
  const [participantName, setParticipantName] = useState('');
  const [participantId, setParticipantId] = useState('');
  const [showDeleteParticipantAlert, setShowDeleteParticipantAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteParticipant = (participantSid: string, participantName: string) => {
    setParticipantName(participantName);
    setParticipantId(participantSid);
    setShowDeleteParticipantAlert(true);
  };

  const handleConfirmDeleteParticipant = async () => {
    try {
      if (participantId && conversation) {
        setIsDeleting(true);
        const deleteParticipant = await caseClient.customApi<any>({
          path: `ChatModule/v1.0/gateway/conversation/${conversation.id}/participant/${participantId}`,
          method: 'delete',
        });
        if (deleteParticipant) {
          console.log('deleteParticipant -> ', deleteParticipant);
          setShowDeleteParticipantAlert(false);
          setIsDeleting(false);
          AppToaster({ toasterPosition: 'bottom' }).show(
            {
              message: `Participant ${participantName} deleted successfuly!`,
              intent: 'success',
            },
            'case-detail-view',
          );
          await fetchConversationInformation([conversation]);
        }
      }
    } catch (error) {
      setShowDeleteParticipantAlert(false);
      setIsDeleting(false);
      AppToaster({ toasterPosition: 'bottom' }).show(
        {
          message: `We had an issue deleting participant ${participantName}`,
          intent: 'danger',
        },
        'case-detail-view',
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        conversationInformation &&
        conversationParticipants && (
          <div className="chat-top-bar-container">
            <div className="chat-top-bar-items-container">
              <div className="chat-top-bar-left-side">
                <p style={{ fontWeight: 500 }}>Participants </p>
                <p>({conversationParticipants.length})</p>
              </div>
              <div className="chat-top-bar-right-side">
                <span className="chat-button mr-20" onClick={() => setOpenParticipantDrawer(true)}>
                  <PersonPlus />
                </span>
              </div>
            </div>
          </div>
        )
      )}

      <div className="participant-card">
        {isLoading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : conversationParticipants.length > 0 ? (
          conversationParticipants.map((participant: any) => (
            <div
              key={participant.sid}
              style={{ margin: '20px 10px 0 20px' }}
              className="participant-card-container"
            >
              <div className="participant-card-content">
                <span className="participant-card-image-container">
                  <img
                    src={`https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png`}
                  />
                </span>
                <div className="participant-card-text-content">
                  <p className="participant-card-name">{participant?.name}</p>
                  <p className="participant-card-source">Source: {participant.source}</p>
                </div>
              </div>
              <button
                className="chat-button rounded-full animate"
                onClick={() => handleDeleteParticipant(participant.sid, participant.name)}
              >
                <svg
                  data-id="10"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
          ))
        ) : (
          <div style={{ flex: 1 }}>
            <H5>Info</H5>
            <p>Select a conversation</p>
          </div>
        )}
      </div>
      <ConversationDetailsDialog
        handleConfirmDeleteParticipant={handleConfirmDeleteParticipant}
        isDeleting={isDeleting}
        participantName={participantName}
        setShowDeleteParticipantAlert={setShowDeleteParticipantAlert}
        showDeleteParticipantAlert={showDeleteParticipantAlert}
      />
    </>
  );
};

export default ConversationDetailsCard;
