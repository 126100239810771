import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import './styles.scss';
import CaseManagementScheduling from './views/CaseManagementScheduling';
import CaseManagementTemplates from './views/CaseManagementTemplates';
import CaseManagementCaseListView from './views/CaseManagementCaseListView';
import CaseManagementDashboard from './views/CaseManagementDashboard';
import MyCasesViewV2 from '../MyCasesViewV2';

interface Props {}

type TMenuItem =
  | 'Dashboard'
  | 'Teams'
  | 'Performance'
  | 'CaseLoad'
  | 'Cases'
  | 'Scheduling'
  | 'Employees'
  | 'ShiftTemplates'
  | 'MyCases';

const CaseManagementV2: React.FC<Props> = (props: Props) => {
  const [activeMenuItem, setActiveMenuItem] = useState<TMenuItem>('Dashboard');
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);

  const renderContent = () => {
    switch (activeMenuItem) {
      case 'Dashboard':
        return <CaseManagementDashboard />;
      case 'Scheduling':
        return <CaseManagementScheduling />;
      case 'ShiftTemplates':
        return <CaseManagementTemplates />;
      case 'Cases':
        return <CaseManagementCaseListView />;
      case 'MyCases':
        return <MyCasesViewV2 />;
      default:
        return <></>;
    }
  };

  const handleMenuClick = (menuItem: TMenuItem) => {
    setActiveMenuItem(menuItem);
    setIsMenuCollapsed(true);
  };

  return (
    <Row style={{ height: 'calc(100vh - 38px)' }}>
      <Col span={isMenuCollapsed ? 1 : 3} className="CaseManagementLeftSidebar">
        <Menu className={`caseManagementSidebarMenu ${isMenuCollapsed ? 'closed' : 'open'}`}>
          {/* Closed Menu -> Open */}
          {isMenuCollapsed && (
            <>
              <MenuItem
                text={isMenuCollapsed ? null : 'Menu'}
                icon={<i className="bi bi-list" />}
                onClick={() => setIsMenuCollapsed(false)}
              />
              <MenuDivider />
            </>
          )}

          {/* Open Menu -> Close */}
          {!isMenuCollapsed && (
            <>
              <MenuItem
                style={{ color: 'black', fontWeight: 500 }}
                icon={
                  <i
                    className="bi bi-list"
                    style={{ fontWeight: 600, marginTop: 2, marginRight: 4 }}
                  />
                }
                text="Support Module"
                onClick={() => setIsMenuCollapsed(true)}
              />

              <MenuDivider />
            </>
          )}

          {/* Menu Items */}
          <MenuItem
            text={isMenuCollapsed ? null : 'Dashboard'}
            icon={<i className="bi bi-columns" />}
            active={activeMenuItem === 'Dashboard'}
            onClick={() => handleMenuClick('Dashboard')}
          />
          <MenuItem
            text={isMenuCollapsed ? null : 'Teams'}
            icon={<i className="bi bi-people-fill" />}
            active={activeMenuItem === 'Teams'}
            disabled={true}
            onClick={() => setActiveMenuItem('Teams')}
          />
          <MenuItem
            text={isMenuCollapsed ? null : 'Performance'}
            icon={<i className="bi bi-speedometer2" />}
            active={activeMenuItem === 'Performance'}
            disabled={true}
            onClick={() => setActiveMenuItem('Performance')}
          />
          <MenuItem
            text={isMenuCollapsed ? null : 'Case Load'}
            icon={<i className="bi bi-bar-chart" />}
            active={activeMenuItem === 'CaseLoad'}
            disabled={true}
            onClick={() => setActiveMenuItem('CaseLoad')}
          />
          <MenuItem
            text={isMenuCollapsed ? null : 'Cases'}
            icon={<i className="bi bi-view-list" />}
            active={activeMenuItem === 'Cases'}
            onClick={() => handleMenuClick('Cases')}
          />
          <MenuItem
            text={isMenuCollapsed ? null : 'My Cases'}
            icon={<i className="bi bi-headphones" />}
            active={activeMenuItem === 'MyCases'}
            onClick={() => handleMenuClick('MyCases')}
          />
          <MenuItem
            text={isMenuCollapsed ? null : 'Scheduling'}
            icon={<i className="bi bi-calendar" />}
            disabled={true}
            active={activeMenuItem === 'Scheduling'}
            onClick={() => setActiveMenuItem('Scheduling')}
          />
          {/*<MenuItem*/}
          {/*  className="subMenuItem"*/}
          {/*  text="Templates"*/}
          {/*  active={activeMenuItem === 'ShiftTemplates'}*/}
          {/*  onClick={() => setActiveMenuItem('ShiftTemplates')}*/}
          {/*/>*/}
          {/*<MenuItem*/}
          {/*  icon="person"*/}
          {/*  text="Employees"*/}
          {/*  active={activeMenuItem === 'Employees'}*/}
          {/*  onClick={() => setActiveMenuItem('Employees')}*/}
          {/*/>*/}
        </Menu>
      </Col>
      <Col span={isMenuCollapsed ? 23 : 21} style={{ background: 'white', padding: 15 }}>
        {renderContent()}
      </Col>
    </Row>
  );
};
export default CaseManagementV2;
