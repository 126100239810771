import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getAllRelations } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Descriptions } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../shared/utilities/recordHelpers';
import '../index.scss';
import { Tag } from '@blueprintjs/core';
import TableCellStageTag from '../../../v2/shared/components/TableCellStageTag';

interface Props {
  result: DbRecordEntityTransform;
  onClose: any;
  globalCollapsed: boolean;
}

interface State {
  collapsed: boolean;
}

class OrderLayout extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { collapsed: false };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!prevProps.globalCollapsed && this.props.globalCollapsed) {
      this.setState({ collapsed: true });
    }
    if (prevProps.globalCollapsed && !this.props.globalCollapsed) {
      this.setState({ collapsed: false });
    }
  }

  renderOrderItemsInLinkableTags = (result: DbRecordEntityTransform) => {
    if (result.OrderItem && result.OrderItem.dbRecords) {
      return result.OrderItem.dbRecords.map((elem: DbRecordEntityTransform) => {
        return (
          <Link
            key={elem.title}
            to={getBrowserPath(elem)}
            onClick={() => this.props.onClose()}
            target="_blank"
          >
            <Tag color="blue">{elem.title}</Tag>
          </Link>
        );
      });
    } else return '-';
  };

  renderCommaSeparatedRelatedEntitiesWithLinks = (
    result: DbRecordEntityTransform,
    entityName: string,
    propertyName: string,
  ) => {
    const relatedRecords = getAllRelations(result, entityName);
    if (relatedRecords) {
      return relatedRecords
        .map((elem: DbRecordEntityTransform) => (
          <Link to={getBrowserPath(elem)} onClick={() => this.props.onClose()} target="_blank">
            {elem[propertyName]}
          </Link>
        ))
        .reduce((prev: JSX.Element, current: JSX.Element): any => [prev, <span>, </span>, current]);
    } else return '-';
  };

  render() {
    const result = this.props.result;

    return (
      <Descriptions
        column={1}
        bordered
        labelStyle={{ color: 'black', fontWeight: 400, fontSize: 12, padding: '2px 7px' }}
        contentStyle={{ fontSize: 12, padding: '2px 7px' }}
      >
        {/* Address */}
        <Descriptions.Item label="Address">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'Address', 'title')}
          </span>
        </Descriptions.Item>

        {/* Order Items */}
        <Descriptions.Item label="Order items">
          <span>{this.renderOrderItemsInLinkableTags(result)}</span>
        </Descriptions.Item>

        {/* Contact Title */}
        <Descriptions.Item label="Contact">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'Contact', 'title')}
          </span>
        </Descriptions.Item>

        {/* Work Order # */}
        <Descriptions.Item label="Work Order">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'WorkOrder', 'recordNumber')}
          </span>
        </Descriptions.Item>

        {/* Stage */}
        <Descriptions.Item label="Stage">
          <TableCellStageTag record={result} small />
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default OrderLayout;
