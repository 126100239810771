import axios from 'axios';
import {boundingExtent} from 'ol/extent';
import ImageLayer from 'ol/layer/Image';
import {transform} from 'ol/proj';
import ImageWMS from 'ol/source/ImageWMS';
import {changeToCapitalCase, chunkArray,} from '../../../../shared/utilities/dataTransformationHelpers';
import * as Sentry from '@sentry/react';

/**
 * Get feature from QGIS, calculate bounding extent and zoom in.
 * Pass additional overrideQuery param to bypass query from the reducer.
 *
 * @param mapReducer
 * @param updateMap
 * @param alertMessage
 * @param overrideQuery
 */
export const getFeatureByIdAndZoom = async (
  mapReducer: any,
  updateMap: any,
  alertMessage: any,
  overrideQuery?: string,
  withoutAnimation?: boolean,
) => {
  const VITE_QGIS_SERVER_URL =
    'https://api.odin.prod.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';
  const { map } = mapReducer;
  const { query } = mapReducer;

  // Remove all previous features
  removeMapLayersByClassName(['feature_by_id_layer'], map);

  let qgisQuery = overrideQuery ? overrideQuery : query;

  if (qgisQuery) {
    let WFSURL: string = '';
    let maxZoom: number = 19.5;
    let featureType, coords: any;
    const split = qgisQuery.split('&');
    const splitType = split[0].split('type=');
    const splitFeatId = split[1]?.split('featureId=');
    const splitNoiRef = split[2]?.split('noiRef=');

    if (splitType[1] === 'cable') {
      const ids = splitFeatId[1].split(',').map((elem: any) => `cable.${elem}`);
      WFSURL = `${VITE_QGIS_SERVER_URL}&SERVICE=WFS&REQUEST=GetFeature&VERSION=1.1.0&TYPENAME=${splitType[1]}&MAXFEATURES=1&OUTPUTFORMAT=GeoJSON&FEATUREID=${ids}`;
    }
    // pia_duct
    else {
      WFSURL = `${VITE_QGIS_SERVER_URL}&SERVICE=WFS&REQUEST=GetFeature&VERSION=1.1.0&TYPENAME=${splitType[1]}&MAXFEATURES=1&OUTPUTFORMAT=GeoJSON&FEATUREID=${splitType[1]}.${splitFeatId[1]}`;
    }

    try {
      const data = await axios.get(WFSURL);
      if (data.data.features && data.data.features[0]) {
        const geom = data.data.features[0] ? data.data.features[0].geometry : undefined;
        coords = geom ? geom.coordinates : [];

        // Closure
        if (geom && geom.type === 'Point') {
          maxZoom = 21;
          featureType = 'closure';
        }

        // Cable
        if (geom && geom.type === 'LineString') {
          maxZoom = 23;
          featureType = 'cable';
        }

        // Polygon
        if (geom && geom.type === 'Polygon') {
          coords = coords[0];
          maxZoom = 19.5;
          featureType = 'polygon';
        }
      } else {
        if (alertMessage) {
          return alertMessage({
            body: 'no data found, verify that the feature and id exist',
            type: 'info',
          });
        }
      }
    } catch (error: any) {
      alertMessage({ body: 'QGIS Search failed.', type: 'error' });
      Sentry.captureException(error);
    }
    // Set feature coordinates and define max zoom level depending on the feature type

    // Add ImageWMS Layers
    if (splitNoiRef) {
      map?.addLayer(
        new ImageLayer({
          className: 'feature_by_id_layer',
          zIndex: 50000,
          source: new ImageWMS({
            url: VITE_QGIS_SERVER_URL,
            params: {
              LAYERS: [splitType[1], 'closure'],
              FILTER: `${splitType[1]}:"noi_ref" = '${splitNoiRef[1]}';closure:"type_id" IN ( 1 , 2 , 3 , 4 , 5 , 7 , 11 ) AND "eco" = 'true'`,
            },
            ratio: 1,
            serverType: 'qgis',
          }),
          opacity: 1,
          visible: true,
        }),
      );
    } else {
      // PIA_DUCT and PIA_STRUCTURE don't have regular id columns
      let column: string = '';
      let value: string = '';
      if (splitType.includes('pia_duct') || splitType.includes('pia_structure')) {
        column = 'objectid';
        value = splitFeatId[1];
      } else {
        column = 'id';
        value = splitFeatId[1];
      }

      map?.addLayer(
        new ImageLayer({
          className: 'feature_by_id_layer',
          zIndex: 50000,
          source: new ImageWMS({
            url: VITE_QGIS_SERVER_URL,
            params: {
              LAYERS: [splitType[1]],
              FILTER: `${splitType[1]}:"${column}" = ${value}`,
            },
            ratio: 1,
            serverType: 'qgis',
          }),
          opacity: 1,
          visible: true,
        }),
      );
    }

    // If there are coordinates, convert them to EPSG:3857, get bounding extent and zoom in.
    if (coords) {
      const convertedCoordinates =
        featureType === 'closure'
          ? transform(coords, 'EPSG:4326', 'EPSG:3857')
          : coords.map((coordinate: any) => {
              return transform(coordinate, 'EPSG:4326', 'EPSG:3857');
            });

      map
        ?.getView()
        .fit(
          boundingExtent(featureType === 'closure' ? [convertedCoordinates] : convertedCoordinates),
          {
            maxZoom: maxZoom,
            padding: [50, 50, 50, 50],
            duration: withoutAnimation ? 1 : 700,
          },
        );
    }
  }
};

/**
 * Remove Layers from Openlayers map by providing an array of layer class names.
 * @param layersToRemove
 * @param map
 */
export const removeMapLayersByClassName = (layersToRemove: Array<string>, map: any) => {
  for (const layerName of layersToRemove) {
    map?.getLayers().forEach((layer: any) => {
      if (layer) {
        const isVisible = layer.getVisible();
        if (isVisible) {
          if (layer.className_ === layerName) {
            map?.removeLayer(layer);
          }
        }
      }
    });
  }
};

export const createFeatures = ['blockage', 'hazard'];

export const updateFeatures = [
  'blockage',
  'chamber',
  'closure',
  'rope',
  'cable',
  'duct',
  'pole',
  'hazard',
  'polygon',
];

export const deleteFeatures = [
  'blockage',
  'chamber',
  'closure',
  'rope',
  'cable',
  'duct',
  'pole',
  'hazard',
  'surveyroute', // survey_route
  'surveystructure', // survey_structure
];

/**
 *
 * @param feature
 */
export function getLayerFeatureName(feature: any) {
  let featureName;

  if (feature && feature.id) {
    const split = feature.id.split('.');
    featureName = split[0];
  }

  return featureName ? featureName.toLowerCase() : undefined;
}

/**
 * we pass in the feature type in the map url
 * this function parses that to conform to our internal feature names
 * @param feature
 */
export function transformFeatureNameFromUrl(feature: any) {
  let featureName;

  if (feature) {
    featureName = feature;

    // exceptions
    // if(feature.includes('survey_route') ||
    //   feature.includes('survey_structure') ||
    //   feature.includes('pia_structure') ||
    //   feature.includes('pia_duct')) {
    //   // we remove the _
    //   // surveystructure
    //   // surveyroute
    //   // const name = feature.replace('_', '');
    //   featureName = name;
    //
    // } else {
    //   // const split = feature.split('_');
    //
    //   // featureName = split[split.length - 1];
    //   featureName = feature;
    // }
  }

  return featureName ? featureName.toLowerCase() : undefined;
}

export function getLayerType(feature: any) {
  const split = feature.id.split('.');
  const featureName = split[0];

  return featureName;
}

export function getCardTitleFromFeatureId(feature: any) {
  const split = feature.id.split('.');
  const featureName = split[0];

  return changeToCapitalCase(featureName);
}

export const getLayersAndCoordinates = (poly: any, map: any) => {
  const polygonCoordinates = chunkArray(poly.flatCoordinates, 2);
  const flatCoordinateQuery = parsePolygonFlatCoordinatesToWMSQuery(polygonCoordinates);

  return {
    coordinates: flatCoordinateQuery,
  };
};

/**
 * Helper method to check if elem is a function
 * @param functionToCheck
 */
function isFunction(functionToCheck: any) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

/**
 *
 * @param query
 */
export function parseMapQuery(
  query: string | undefined,
): { type: string; featureId?: string; postcode?: string; noiRef?: string } | undefined {
  if (query) {
    const split = query.split('&');
    const splitType = split[0].split('type=');
    const splitFeatId = split[1]?.split('featureId=');
    const splitPostcode = split[1]?.split('postcode=');
    const splitNoiRef = split[1]?.split('noiRef=');

    return {
      type: splitType[1],
      featureId: splitFeatId ? splitFeatId[1] : undefined,
      postcode: splitPostcode ? splitPostcode[1] : undefined,
      noiRef: splitNoiRef ? splitNoiRef[1] : undefined,
    };
  }
}

/**
 * Helper if
 * @param poly
 */
function parsePolygonFlatCoordinatesToWMSQuery(poly: any[]) {
  let base = 'POLYGON ((#coordinates#))';

  let stringOfCoordinates = '';

  poly.forEach((el: any, i: number) => {
    if (poly.length !== i + 1) {
      stringOfCoordinates += `${el[0]} ${el[1]},`;
    } else {
      stringOfCoordinates += `${el[0]} ${el[1]}`;
    }
  });

  return base.replace('#coordinates#', stringOfCoordinates);
}

// Temporary key: value for id columns

export const blockageModels: { [key: number]: string } = {
  1: 'First',
  2: 'Next',
  3: 'Desilt',
};

export const chamberModels: { [key: number]: string } = {
  1: 'fw4',
  7: 'fw2',
  2: 'fw6',
  10: 'fw10',
  3: 'cw1',
  4: 'cw2',
  8: 'Toby',
  9: 'Openreach',
};
export const chamberSurfaceTypes: { [key: number]: string } = {
  0: '1- Null',
  1: '2- Soft',
  2: '3- FW',
  3: '4- CW',
  4: '5- Special',
};

export const closureTypes: { [key: number]: string } = {
  1: 'L0',
  2: 'L1',
  3: 'L2',
  4: 'L3',
  5: 'L4',
  6: 'LX',
  9: 'ODF',
  10: 'OLT',
  11: 'LM',
};

export const ductTypes: { [key: number]: string } = {
  1: '1-Duct',
  2: '2-Sduct',
  3: '3-Lead-in',
  4: 'Openreach',
};

export const cableTypes: { [key: number]: string } = {
  1: 'Spine',
  2: 'Distribution',
  3: 'Access',
  4: 'Feed',
  5: 'Drop',
  6: 'Temp',
  7: 'CableLink',
};

export const poleModels: { [key: number]: string } = {
  1: 'Generic',
  3: 'Medium',
  4: 'Light',
  5: 'Stout',
  6: 'Openreach',
};

export const buildStatuses: { [key: number]: string } = {
  1: '1-Plan',
  2: '2-Survey',
  3: '3-Design',
  4: '4-Plan Done',
  5: '5-Done',
  6: '6-In Progress',
  7: '7-Build Done',
  8: '8-RFS',
  9: '0-Backlog',
};
