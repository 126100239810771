import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Tooltip } from 'antd';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

import CoreLegacyEmbeddedForm from '../../../../../../../core/records/components/Forms/CoreLegacyEmbeddedForm';
import { initializeRecordForm } from '../../../../../../../core/records/components/Forms/store/actions';
import ModuleEntityIcon from '../../../../../../../core/theme/ModuleEntityIcon';
import { PageHeader } from '../../../../../../../shared/components/PageHeader';
import { getBrowserPath } from '../../../../../../../shared/utilities/recordHelpers';
import RecordStageSequentialPipeline from '../../../../../../shared/components/RecordStageSequentialPipeline';
import SchemaActionPageHeader from '../../../../../../shared/components/SchemaActions/SchemaActionPageHeader';

import { useAction } from '../../../../../../../containers/ProjectModule/hooks/useAction';

import { FeatureRecordDetails } from './FeatureRecordDetails';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;
const uuid = uuidv4();

type RecordDetailHeaderProps = {
  schema?: SchemaEntity;
  record?: DbRecordEntityTransform;
};

export const RecordDetailsCard = ({ record, schema }: RecordDetailHeaderProps) => {
  // State
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Actions
  const initializeForm = useAction(initializeRecordForm);

  // Events
  const onEditSuccess = () => {
    setIsEditing(false);
  };

  return (
    <PageHeader
      style={{ borderRadius: 6, border: '1px solid #dddbda', padding: '8px 10px' }}
      className="workItemQuickViewHeader"
      extra={[
        schema && record ? (
          <SchemaActionPageHeader
            moduleName={schema.moduleName}
            entityName={schema.entityName}
            record={record}
            launcherType="INLINE"
          />
        ) : (
          <></>
        ),
      ]}
    >
      {/* Header */}
      <Row justify={isMobile ? 'start' : 'space-around'}>
        {/* Record Icon */}
        <Col span={isMobile ? 4 : 2} style={{ marginTop: 8 }}>
          <ModuleEntityIcon
            moduleName={record?.entity?.split(':')[0] || PROJECT_MODULE}
            entityName={record?.entity?.split(':')[1] || FEATURE}
            style={{ padding: '4px 2px', fontSize: '1.3em' }}
          />
        </Col>
        {/* Record name and ID */}
        <Col span={isMobile ? 20 : 17} style={{ paddingTop: 2 }}>
          <Row>
            <Col span={24} style={{ fontSize: '1.2em', fontWeight: 600 }}>
              <span>
                {record?.entity === `${PROJECT_MODULE}:${FEATURE}`
                  ? `${record?.type} (${record?.recordNumber || '-'})`
                  : record?.recordNumber}
              </span>
            </Col>
            <Col span={24} style={{ lineHeight: '13px', margin: 0 }}>
              <span style={{ fontSize: '0.9em', color: '#636363', fontWeight: 'normal' }}>
                ID:{' '}
                {record?.entity === `${PROJECT_MODULE}:${FEATURE}`
                  ? getProperty(record, 'ExternalRef') || '-'
                  : record?.id}
              </span>
            </Col>
          </Row>
        </Col>

        {/* Record Actions - Desktop */}
        {!isMobile ? (
          <Col span={4} style={{ textAlign: 'right' }}>
            <Tooltip title="Go to Record" mouseEnterDelay={1.5}>
              <Link to={getBrowserPath(record!)} target="_blank">
                <Button
                  ghost
                  size="large"
                  type="link"
                  disabled={!record}
                  icon={<ExportOutlined />}
                />
              </Link>
            </Tooltip>
            <Tooltip title="Edit Feature" mouseEnterDelay={1.5}>
              <Button
                ghost
                size="large"
                type="link"
                disabled={
                  !record || isEditing || true // !hasPermissions(userReducer, ['master.record.editor'])
                }
                onClick={() => {
                  setIsEditing(true);
                }}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      {isEditing ? (
        // Edit Form
        <Row>
          <Col span={24}>
            <CoreLegacyEmbeddedForm
              formUUID={uuid}
              record={record}
              showFormActions
              onCancelEvent={() => setIsEditing(false)}
              onSubmitEvent={() => onEditSuccess()}
              initializeCb={() => {
                initializeForm({
                  title: `Edit ${schema?.entityName}`,
                  formUUID: uuid,
                  selected: record,
                  recordType: record?.type,
                  showFormModal: true,
                  showInitializing: false,
                  isCreateReq: false,
                  isUpdateReq: true,
                  schema: schema,
                  sections: [
                    {
                      name: schema?.name,
                      schema: schema,
                      associations: [],
                    },
                  ],
                });
              }}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row justify="space-between">
            {/* Mobile Only Actions */}
            {isMobile ? (
              <>
                <Col span={24}>
                  <Divider style={{ marginTop: 18, marginBottom: 18 }} />
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                  <Link to={getBrowserPath(record!)} target="_blank">
                    <Button disabled={!record} type="link" icon={<ExportOutlined />}>
                      Go to Record
                    </Button>
                  </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                  <Button
                    disabled={!record}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                    type="link"
                    icon={<EditOutlined />}
                  >
                    Edit Record
                  </Button>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </>
      )}

      <Col span={24}>
        <Divider style={{ marginTop: 18, marginBottom: isMobile ? 10 : 18 }} />
      </Col>

      {/* Record Pipeline */}
      {record?.stage && (
        <Col span={22}>
          <RecordStageSequentialPipeline record={record} flat />
        </Col>
      )}

      <Col span={24}>
        <Divider style={{ marginTop: 18, marginBottom: 18 }} />
      </Col>

      {!!record && !!schema && (
        <Col span={24}>
          <FeatureRecordDetails record={record} schema={schema} />
        </Col>
      )}
    </PageHeader>
  );
};
