import { RadiusSettingOutlined } from '@ant-design/icons';
import {
  QGISCableTypeEnum,
  QGISClosureTypeEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Card, Col, Modal, Row, Select } from 'antd';
import axios from 'axios';
import { always } from 'ol/events/condition';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  MapReducerUpdate,
  MapSearch,
  setMapSearchQuery,
  updateMapState,
} from '../../../../../core/gis/store/actions';
import { MapReducer } from '../../../../../core/gis/store/reducer';
import RecordQuickViewDrawer from '../../../../../core/records/components/RecordQuickViewDrawer';
import { resetRecordsList } from '../../../../../core/records/store/actions';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import { LAYER_NAMES } from '../constants';
import { getLayersAndCoordinates, removeMapLayersByClassName } from '../helpers';
import './styles.scss';

interface Props {
  mapReducer: MapReducer;
  schemaReducer: any;
  recordReducer: any;
  navigationReducer: any;
  updateMap: (params: MapReducerUpdate) => {};
  setSearchQuery: (params: MapSearch) => {};
  resetSearchMap: any;
}

interface State {
  drawInteraction: any;
  schema: any;
  bulkUpdateableRecords: number;
}

class FeatureSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      drawInteraction: undefined,
      schema: undefined,
      bulkUpdateableRecords: 0,
    };
  }

  componentDidMount() {
    const { schemaReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      'ProjectModule',
      'Feature',
    );

    if (schema) {
      this.setState({ schema: schema });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { mapReducer, updateMap } = this.props;
    const { mapSelectionFiltering, queryLayer } = mapReducer;

    // if drawEnabled is update from outside of component
    if (mapReducer.map && prevProps.mapReducer.drawEnabled !== this.props.mapReducer.drawEnabled) {
      if (this.props.mapReducer.drawEnabled === false) {
        this.disableDraw();
      }
    }

    // Once the records update, run though, check if any matches our select filtering and handle bulk update
    if (
      prevProps.recordReducer.list[this.state.schema?.id] !==
      this.props.recordReducer.list[this.state.schema?.id]
    ) {
      const features = this.props.recordReducer.list[this.state.schema?.id];

      // If all features are CABLES that match our filter
      if (
        mapSelectionFiltering !== 'ALL' &&
        queryLayer === LAYER_NAMES.CABLE &&
        features.length > 0 &&
        features?.every(
          (feature: any) =>
            getProperty(feature, 'CableType') ===
            String(QGISCableTypeEnum[mapSelectionFiltering as any]),
        )
      ) {
        updateMap({ bulkUpdateEnabled: true });
        this.setState({ bulkUpdateableRecords: features.length });
      }
      // If all features are CLOSURES that match our filter
      else if (
        mapSelectionFiltering !== 'ALL' &&
        queryLayer === LAYER_NAMES.CLOSURE &&
        features.length > 0 &&
        features?.every(
          (feature: any) =>
            getProperty(feature, 'ClosureType') ===
            String(QGISClosureTypeEnum[mapSelectionFiltering as any]),
        )
      ) {
        updateMap({ bulkUpdateEnabled: true });
        this.setState({ bulkUpdateableRecords: features.length });
      }
      // For all other scenarios disable bulk update
      else {
        updateMap({ bulkUpdateEnabled: false });
        this.setState({ bulkUpdateableRecords: 0 });
      }
    }
  }

  clearSearch() {
    const { updateMap, resetSearchMap, mapReducer } = this.props;
    const { map } = mapReducer;

    // Remove all features that were searched on map but only on Mobile devices.
    if (map && isMobile) {
      removeMapLayersByClassName(['feature_by_id_layer'], map);
    }

    resetSearchMap();

    updateMap({
      map,
      features: [],
      drawEnabled: false,
      inputFieldFeatureID: '',
      inputFieldPostcode: '',
      inputFieldNOIRef: '',
      mapSelectionFiltering: 'ALL',
      bulkUpdateEnabled: false,
      isCreatingRFC: false,
      creatingRFCFromIds: [],
    });
    this.setState({ bulkUpdateableRecords: 0 });

    // Reset the form fully on Mobile Devices.
    if (isMobile) {
      updateMap({
        query: undefined,
        queryLayer: undefined,
        mapSelectionFiltering: 'ALL',
        bulkUpdateEnabled: false,
      });
      this.setState({ bulkUpdateableRecords: 0 });
    }
  }

  /**
   * Select features on the map using the draw feature
   * hold down shift + then click and craw the points to select
   */
  enableDraw() {
    const { mapReducer, updateMap } = this.props;
    const { map } = mapReducer;
    const drawingSource = new VectorSource();

    // we want to disable add feature if the user wants to draw
    // conflicts with rendering the component
    updateMap({ addEnabled: false });

    // Drawing interaction
    const draw = new Draw({
      source: drawingSource,
      type: GeometryType.POLYGON,
      freehand: true,
      condition: always,
    });

    updateMap({
      drawEnabled: true,
    });

    this.setState({
      drawInteraction: draw,
    });

    map?.addInteraction(draw);

    draw.on('drawstart', function (e: DrawEvent) {
      drawingSource.clear();
    });

    draw.on('drawend', async (e: DrawEvent) => {
      const polygon: Geometry | undefined = e.feature.getGeometry();
      updateMap({
        drawEnabled: true,
      });
      const { coordinates } = getLayersAndCoordinates(polygon, mapReducer.map);
      await this.getFeaturesData(coordinates);
    });
  }

  disableDraw() {
    const { mapReducer, updateMap } = this.props;
    const { map } = mapReducer;

    if (map) {
      map?.removeInteraction(this.state.drawInteraction);

      updateMap({
        map,
        drawEnabled: false,
        mapSelectionFiltering: 'ALL',
        bulkUpdateEnabled: false,
      });

      this.setState({ bulkUpdateableRecords: 0 });
    }
  }

  // If additional filtering is asked for, filter out WMS data to match the query
  applyFeatureTypeFiltering(data: any[]) {
    const { mapReducer } = this.props;
    const { mapSelectionFiltering, queryLayer } = mapReducer;
    let filteredData = [];

    if (mapSelectionFiltering !== 'ALL' && data?.length > 0) {
      if (queryLayer === LAYER_NAMES.CABLE) {
        filteredData = data.filter(
          (feature: any) =>
            feature.properties.type === QGISCableTypeEnum[mapSelectionFiltering as any],
        );
      } else if (queryLayer === LAYER_NAMES.CLOSURE) {
        filteredData = data.filter(
          (feature: any) =>
            feature.properties.type === QGISClosureTypeEnum[mapSelectionFiltering as any],
        );
      } else {
        filteredData = data;
      }
    } else {
      filteredData = data;
    }

    return filteredData;
  }

  async getFeaturesData(coordinates: any) {
    const { updateMap, mapReducer, setSearchQuery } = this.props;
    const { queryLayer } = mapReducer;

    const VITE_QGIS_SERVER_URL =
      'https://api.odin.prod.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';

    if (queryLayer) {
      const url = `${VITE_QGIS_SERVER_URL}&SERVICE=WMS&REQUEST=GetFeatureInfo&LAYERS=${queryLayer}&QUERYVISIBLE=true&QUERY_LAYERS=${queryLayer}&CRS=EPSG:3857&FILTER_GEOM=${coordinates}&FEATURE_COUNT=50&INFO_FORMAT=application/json`;

      const response = await axios.get(url);

      if (response.data.features && response.data.features.length) {
        // Raw selection data
        let parsed = response.data.features;

        // Raw selection data + optional filtering
        let filteredData = this.applyFeatureTypeFiltering(parsed);

        setSearchQuery({
          featureIds: filteredData.map((feature: any) => feature.properties.id).join(),
        });

        updateMap({
          features: filteredData,
          mapSidebarSection: 'FEATURES',
          mapSidebarVisible: true,
        });
      }
    }
  }

  handleChooseFeature(key: string) {
    const { updateMap } = this.props;

    updateMap({
      drawEnabled: true,
      queryLayer: key,
      features: [],
      featureSelectModal: false,
      mapSidebarVisible: false,
      mapSelectionFiltering: 'ALL',
      bulkUpdateEnabled: false,
    });

    this.enableDraw();
  }

  handleFloatingButtonClick() {
    const { updateMap, mapReducer } = this.props;
    if (mapReducer.drawEnabled) {
      this.disableDraw();
      updateMap({ featureSelectModal: false });
    } else {
      this.clearSearch();
      updateMap({ featureSelectModal: true, infoPopupVisible: false, isCreatingRFC: false });
    }
  }

  renderFilterOptionsForQueryLayer = () => {
    const { mapReducer } = this.props;
    const { Option } = Select;

    if (mapReducer.queryLayer === LAYER_NAMES.CLOSURE) {
      return (
        <>
          <Option value="ALL">Select All</Option>
          <Option value="L3">L3 Closures</Option>
          <Option value="L4">L4 Closures</Option>
        </>
      );
    } else if (mapReducer.queryLayer === LAYER_NAMES.CABLE) {
      return (
        <>
          <Option value="ALL">Select All</Option>
          <Option value="Access">Access Cables</Option>
          <Option value="Feed">Feed Cables</Option>
        </>
      );
    }
  };

  render() {
    const { mapReducer, updateMap, navigationReducer } = this.props;
    const { drawEnabled, recordId, isLoadingView } = mapReducer;
    const tabsInViewport = navigationReducer.tabHistory ? navigationReducer.tabHistory.length : 0;

    const SelectFeatureLayers = [
      LAYER_NAMES.BLOCKAGE,
      LAYER_NAMES.CABLE,
      LAYER_NAMES.CHAMBER,
      LAYER_NAMES.CLOSURE,
      LAYER_NAMES.DUCT,
      LAYER_NAMES.HAZARD,
      LAYER_NAMES.PIA_DUCT,
      LAYER_NAMES.PIA_STRUCTURE,
      LAYER_NAMES.POLE,
      LAYER_NAMES.ROPE,
      LAYER_NAMES.SURVEY_ROUTE,
      LAYER_NAMES.SURVEY_STRUCTURE,
    ];

    const buttonLayout = isMobile
      ? [0, 6, 1, 7, 2, 8, 3, 9, 4, 10, 5, 11]
      : [0, 4, 8, 1, 5, 9, 2, 6, 10, 3, 7, 11];

    const isV2QuickViewApplied = () => {
      return localStorage.getItem('ProjectModuleV2QuickView');
    };

    return (
      <>
        {!isV2QuickViewApplied() ? (
          <RecordQuickViewDrawer
            recordId={recordId!}
            visible={!isLoadingView && !!recordId}
            onClose={() => updateMap({ recordId: undefined, entityName: undefined })}
            entityName={mapReducer.entityName}
            moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            allowV2Upgrade
          />
        ) : (
          <></>
        )}

        <Row
          className={`${
            tabsInViewport ? 'floatingSidebarButtonTabs' : 'floatingSidebarButtonNoTabs'
          } floatingButton floatingSelectFilter ${
            drawEnabled &&
            (mapReducer?.queryLayer === LAYER_NAMES.CLOSURE ||
              mapReducer?.queryLayer === LAYER_NAMES.CABLE)
              ? 'visible'
              : 'hidden'
          }`}
        >
          <Col span={24}>
            {/* Feature filter Floating Select */}
            <Select
              defaultValue="ALL"
              value={mapReducer.mapSelectionFiltering}
              style={{ width: 120 }}
              onSelect={(e: any) => {
                updateMap({
                  mapSelectionFiltering: e,
                  bulkUpdateEnabled: false,
                });
                this.setState({ bulkUpdateableRecords: 0 });
              }}
            >
              {this.renderFilterOptionsForQueryLayer()}
            </Select>
          </Col>

          {/* Bulk update floating button */}
          <Col span={24}>
            <Button
              type="primary"
              size="small"
              onClick={() => updateMap({ bulkUpdateDrawerVisible: true })}
              style={{
                width: '100%',
                marginTop: 5,
                borderRadius: 4,
                display:
                  !mapReducer.bulkUpdateEnabled || mapReducer.mapSelectionFiltering === 'ALL'
                    ? 'none'
                    : 'block',
              }}
            >
              Bulk Update ({this.state.bulkUpdateableRecords})
            </Button>
          </Col>
        </Row>

        {/* Feature Select Floating button */}
        <Button
          type="primary"
          shape="circle"
          size="large"
          disabled={mapReducer.addEnabled || mapReducer.addLineEnabled}
          className={`floatingButton floatingSelectFeatureButton ${
            drawEnabled ? 'activeFloatingButton' : ''
          }`}
          icon={<RadiusSettingOutlined />}
          onClick={() => this.handleFloatingButtonClick()}
        />

        {/* Feature Select Type Modal */}
        <Modal
          centered
          className="featureSelectModal"
          title={
            <>
              <RadiusSettingOutlined style={{ marginRight: 8 }} />
              <span>Choose Feature Type</span>
            </>
          }
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          open={mapReducer.featureSelectModal}
          onCancel={() => {
            updateMap({ featureSelectModal: false });
          }}
        >
          <Row>
            {buttonLayout.map((pos: number) => (
              <Col span={isMobile ? 12 : 8} key={SelectFeatureLayers[pos]}>
                <Card
                  className="selectFeatureButton"
                  key={'card-' + pos}
                  style={{
                    margin: '5px',
                  }}
                  onClick={() => this.handleChooseFeature(SelectFeatureLayers[pos])}
                >
                  <Row key={'row-' + pos}>
                    <Col key={'layerName-' + pos} span={24} style={{ textTransform: 'capitalize' }}>
                      {SelectFeatureLayers[pos].replace('_', ' ')}
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal>
      </>
    );
  }
}

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  setSearchQuery: (params: MapSearch) => dispatch(setMapSearchQuery(params)),
  resetSearchMap: () => dispatch(resetRecordsList()),
});

export default connect(mapState, mapDispatch)(FeatureSelect);
