import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Checkbox, Col, Dropdown, Form, Input, Menu, Row, Select } from 'antd';
import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  updateAutosplicingLoadingIndicators,
  updateAutosplicingReducerState,
} from '../../store/actions';
import {
  IAutosplicingLoadingIndicators,
  IAutosplicingReducer,
  TUpdateAutosplicingLoadingIndicatorsAction,
  TUpdateAutosplicingReducerAction,
} from '../../store/types';
import { ISearchRecords, searchRecordsRequest } from '../../../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../../../core/records/store/reducer';
import { ISchemaReducer } from '../../../../../../core/schemas/store/reducer';
import { httpGet } from '../../../../../../shared/http/requests';
import { getDefaultFields } from '../../../../../../shared/utilities/searchHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../shared/utilities/schemaHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../../core/schemas/store/actions';
import { Button } from '@blueprintjs/core';

type Props = {
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  searchRecords: any;
  match: any;
  updateURLParams: any;
  form: any;
  updateLoadingIndicators: TUpdateAutosplicingLoadingIndicatorsAction;
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  autosplicingReducer: IAutosplicingReducer;
  getSchema: Function;
};

const { Option } = Select;
const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { EXCHANGE } = SchemaModuleEntityTypeEnums;

const AutosplicingSearch: FunctionComponent<Props> = (props) => {
  const {
    form,
    updateLoadingIndicators,
    updateAutosplicingReducer,
    autosplicingReducer,
    getSchema,
  } = props;
  const { loadingIndicators } = autosplicingReducer;

  const [cableList, setCableList] = useState<Array<any>>([]);
  const [loadingCables, setLoadingCables] = useState<boolean>(false);
  const [loadingExchanges, setLoadingExchanges] = useState<boolean>(false);
  const [loadingClosureForExchange, setLoadingClosureForExchange] = useState<boolean>(false);
  const [exchangeSchema, setExchangeSchema] = useState<SchemaEntity | undefined>(undefined);
  const [exchangeList, setExchangeList] = useState<Array<any>>([]);

  const traceButtonRef = React.useRef(null);

  // Pass mandatory closureId or additional cableId that will be prefilled in the form.
  const fetchCablesForClosure = async (closureId: string, cableId?: string) => {
    setLoadingCables(true);

    return await httpGet(`ProjectModule/v2.0/ftth/closures/${closureId}/cables`)
      .then(async (res) => {
        if (res.data.data) {
          const closureType = res.data.data.closure_type;
          let cables = [];

          // Filter cables by closure type.
          if (closureType === 'L0') {
            cables = res.data.data.cables.filter((cable: any) => cable.type === 'Spine');
          } else if (closureType === 'L1') {
            cables = res.data.data.cables.filter((cable: any) => cable.type === 'Distribution');
          } else if (closureType === 'L2') {
            cables = res.data.data.cables.filter((cable: any) => cable.type === 'Access');
          }

          setCableList(cables);
          setLoadingCables(false);

          // If cableId is passed, try to match in the list and prefill the form.
          const findCableFromURL = cables.find((cable: any) => cable.id === Number(cableId));
          if (findCableFromURL) {
            updateAutosplicingReducer({
              enteredCableId: String(findCableFromURL.id),
            });
            form.setFieldsValue({ cable_id: String(findCableFromURL.id) });
          }
          // Else, we want to know if only one cable is coming back, and in that case - select it.
          // else {
          //   if (cables.length === 1) {
          //     form.setFieldsValue({ cable_id: String(cables[0].id) });
          //   }
          // }
        } else {
          setCableList([]);
          setLoadingCables(false);
          form.setFieldsValue({ cable_id: null });
        }
      })
      .catch((err) => {
        setLoadingCables(false);
        form.setFieldsValue({ cable_id: null });
      });
  };

  // On component mount, check if params are passed through URL, prefill them in the form and Trace.
  useEffect(() => {
    const { match, updateAutosplicingReducer } = props;
    const params = match.params;

    // Load Exchange Schema
    getSchema({ moduleName: PROJECT_MODULE, entityName: EXCHANGE }, (response: SchemaEntity) => {
      if (response) {
        setExchangeSchema(response);
      }
    });

    if (params.closureId) {
      form.setFieldsValue({
        closure_id: params.closureId,
      });

      if (params.cableId) {
        updateAutosplicingReducer({
          enteredCableId: params.cableId,
        });
      }

      updateAutosplicingReducer({
        enteredClosureId: params.closureId,
      });

      fetchCablesForClosure(params.closureId, params.cableId ? params.cableId : null).then(() => {
        // Trace!
        updateAutosplicingReducer({
          formData: {
            closure_id: params.closureId,
            cable_id: params.cableId ? params.cableId : null,
          },
        });
      });
    }
  }, []);

  useEffect(() => {
    if (autosplicingReducer.enteredClosureId) {
      form.setFieldsValue({
        closure_id: autosplicingReducer.enteredClosureId,
      });
      fetchCablesForClosure(autosplicingReducer.enteredClosureId);
    }
  }, [autosplicingReducer.enteredClosureId]);

  useEffect(() => {
    if (!autosplicingReducer.enteredCableId) {
      form.setFieldsValue({
        cable_id: null,
      });
    }
  }, [autosplicingReducer.enteredCableId]);

  const flushCableData = () => {
    updateAutosplicingReducer({
      latestData: [],
      previousData: [],
    });
  };

  const onCableChange = async (cableId: any) => {
    updateAutosplicingReducer({
      enteredClosureId: form.getFieldValue(['closure_id']),
      enteredCableId: cableId,
    });
    flushCableData();
  };

  const onClosureChange = async (closureId: string) => {
    flushCableData();
    form.setFieldsValue({ exchange_id: null });
    setExchangeList([]);

    updateAutosplicingReducer({
      enteredClosureId: closureId,
      enteredCableId: undefined,
    });

    if (closureId.length > 3) {
      form.setFieldsValue({ cable_id: null });
      fetchCablesForClosure(closureId);
    } else {
      form.setFieldsValue({ cable_id: null });
    }
  };

  const resetForm = () => {
    const { updateURLParams } = props;

    updateAutosplicingReducer({
      allJobs: [],
      latestData: [],
      previousData: [],
      selectedEntityOdinRecord: undefined,
      enteredClosureId: undefined,
      enteredCableId: undefined,
      formData: undefined,
      showSplicingRequestForChangeForm: false,
      closureType: '',
      fiberDifferences: false,
      cableIdsWithIssues: [],
    });

    setExchangeList([]);
    setCableList([]);
    updateURLParams(true);
    form.resetFields();

    updateLoadingIndicators({
      isLoadingTemplates: false,
    });
  };

  const onFinish = (values: any) => {
    updateAutosplicingReducer({
      formData: values,
      cableIdsWithIssues: [],
    });
  };

  const onFinishFailed = (errorInfo: any) =>
    console.log('AutosplicingSearch: Submit failed.', errorInfo);

  const onExchangeSearch = (e: any) => {
    const { schemaReducer, searchRecords } = props;

    if (e.length > 2) {
      setLoadingExchanges(true);

      if (exchangeSchema) {
        searchRecords(
          {
            schema: exchangeSchema,
            searchQuery: {
              terms: e,
              fields: getDefaultFields(exchangeSchema, PROJECT_MODULE, 'Exchange'),
              schemas: exchangeSchema?.id,
            },
          },
          (searchResults: any) => {
            if (searchResults.data.data.length) {
              setLoadingExchanges(false);
              setExchangeList(
                searchResults.data.data.map((exchange: DbRecordEntityTransform) => ({
                  id: getProperty(exchange, 'ExternalRef'),
                  name: getProperty(exchange, 'ExchangeName'),
                })),
              );
            } else {
              setLoadingExchanges(false);
              setExchangeList([]);
            }
          },
        );
      }
    } else {
      setLoadingExchanges(false);
      setExchangeList([]);
    }
  };

  const onExchangeSelect = async (exchangeId: string) => {
    if (exchangeId) {
      setLoadingClosureForExchange(true);

      return await httpGet(`ProjectModule/v1.0/ftth/exchanges/${exchangeId}`)
        .then(async (res) => {
          const closure = res.data.data.closures[0];

          setLoadingClosureForExchange(false);
          fetchCablesForClosure(closure.id);

          updateAutosplicingReducer({
            enteredClosureId: closure.id,
            enteredCableId: undefined,
          });

          form.setFieldsValue({
            closure_id: String(closure.id),
          });
        })
        .catch((err) => {
          setLoadingClosureForExchange(false);
        });
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div
        style={{
          borderRadius: 7,
          padding: '13px 20px 1px 20px',
          backgroundColor: '#f7f7f7',
        }}
      >
        <div
          style={{
            display: autosplicingReducer.allJobs!.length > 0 ? 'none' : 'block',
          }}
        >
          <Row>
            {/* EXCHANGE */}
            <Col span={24}>
              <Form.Item
                label="Exchange name"
                name="exchange_id"
                rules={[{ required: false, message: 'Please enter closure id' }]}
              >
                <Select
                  showSearch
                  disabled={!exchangeSchema}
                  loading={loadingExchanges}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={onExchangeSearch}
                  onChange={onExchangeSelect}
                  notFoundContent={null}
                >
                  {exchangeList.map((exchange: any) => (
                    <Select.Option value={exchange?.id} key={`exchange-${exchange?.id}`}>
                      {exchange?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {/*  CLOSURE ID */}
            <Col span={8} style={{ paddingRight: 5 }}>
              <Form.Item
                label="Closure Id"
                name="closure_id"
                rules={[{ required: true, message: 'Please enter closure id' }]}
              >
                <Input
                  disabled={loadingIndicators?.isLoadingTemplates}
                  placeholder="216704"
                  type="string"
                  onChange={(e: any) => onClosureChange(e.target.value)}
                />
              </Form.Item>
            </Col>

            {/* CABLE ID */}
            <Col span={16} style={{ paddingLeft: 5 }}>
              <Form.Item label="Cable Id" name="cable_id">
                <Select
                  disabled={!cableList.length || loadingIndicators?.isLoadingTemplates}
                  loading={loadingCables}
                  allowClear
                  onChange={onCableChange}
                >
                  {cableList.map((cable: any) => (
                    <Select.Option value={cable?.id} key={`cable-${cable?.id}`}>
                      {cable?.type} - {cable?.id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* CABLE TYPE */}
          <Form.Item
            name="cable_types"
            label="Cable Type"
            rules={[{ required: false }]}
            style={{ display: 'none' }}
            initialValue={['1', '2', '3', '4']}
          >
            <Select
              placeholder=""
              mode="multiple"
              disabled={loadingIndicators?.isLoadingTemplates}
              size="small"
            >
              <Option value="1">Spine</Option>
              <Option value="2">Distribution</Option>
              <Option value="3">Access</Option>
              <Option value="4">Feed</Option>
            </Select>
          </Form.Item>

          {/* ON HOLD */}
          <Form.Item
            name="onhold_features"
            valuePropName="checked"
            wrapperCol={{ offset: 6, span: 24 }}
          >
            <Checkbox disabled>Include on-hold features</Checkbox>
          </Form.Item>

          <Form.Item>
            <Row gutter={16}>
              <Col sm={24} lg={12}>
                <Dropdown
                  overlay={
                    <Menu>
                      {/* Trace in New Tab */}
                      <Menu.Item
                        key="1"
                        disabled={
                          !autosplicingReducer.enteredClosureId ||
                          autosplicingReducer.allJobs!.length > 0
                        }
                      >
                        <Link
                          to={`/ProjectModule/Connection/${autosplicingReducer.enteredClosureId}${
                            autosplicingReducer.enteredCableId
                              ? `/${autosplicingReducer.enteredCableId}`
                              : ''
                          }`}
                          target="_blank"
                        >
                          Trace in New Tab
                        </Link>
                      </Menu.Item>

                      {/* Trace from L0 */}
                      <Menu.Item
                        key="2"
                        disabled={!autosplicingReducer.differenceList?.l0ClosureId}
                      >
                        <Link
                          to={`/ProjectModule/Connection/${
                            autosplicingReducer.differenceList?.l0ClosureId
                          }/${autosplicingReducer.differenceList?.spineCableId || ''}`}
                          target="_blank"
                        >
                          Trace from L0
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    style={{ width: '100%' }}
                    loading={loadingIndicators?.isLoadingTemplates}
                    disabled={
                      !autosplicingReducer.enteredClosureId ||
                      autosplicingReducer.allJobs!.length > 0
                    }
                    intent="primary"
                    type="submit"
                  >
                    {loadingIndicators?.isLoadingTemplates ? 'Tracing...' : 'Trace'}
                  </Button>
                </Dropdown>
              </Col>

              <Col sm={24} lg={12} style={{ textAlign: 'right' }}>
                <Button intent="primary" outlined style={{ width: '100%' }} onClick={resetForm}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </div>

        <div
          style={{
            display: autosplicingReducer.allJobs!.length === 0 ? 'none' : 'block',
          }}
        >
          <Form.Item wrapperCol={{ offset: 7, span: 12 }} style={{ marginTop: 8 }}>
            <Button onClick={resetForm} style={{ width: '100%' }}>
              New Search
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  updateLoadingIndicators: (params: IAutosplicingLoadingIndicators) =>
    dispatch(updateAutosplicingLoadingIndicators(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  autosplicingReducer: state.autosplicingReducer,
});

export default withRouter(connect(mapState, mapDispatch)(AutosplicingSearch));
