import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ISchemaActionUpdate, updateSchemaAction } from '../../../../../core/schemas/store/actions';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { Card, Col, Skeleton } from 'antd';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import Editor from '@monaco-editor/react';
import { Button, Section, Tag } from '@blueprintjs/core';
import { updateViewConfiguration } from '../ViewConfigurations/api';

interface Props {
  viewConfiguration: SchemaActionEntity | undefined;
  schema: SchemaEntity | undefined;
  updateAction: (payload: ISchemaActionUpdate, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ViewConfigurationBuilder: FC<Props> = (props: Props) => {
  const { viewConfiguration, schema, updateAction, alertMessage } = props;

  const [JSONEditorValue, setJSONEditorValue] = useState<any>();
  const [JSONInvalid, setJSONInvalid] = useState<boolean>(false);
  const [isSavingDefinition, setIsSavingDefinition] = useState<boolean>(false);

  const isJsonValid = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (viewConfiguration) {
      setJSONEditorValue(JSON.stringify(viewConfiguration.definition, null, '\t'));
    }
  }, [viewConfiguration]);

  useEffect(() => {
    if (JSONEditorValue?.length > 0 && isJsonValid(JSONEditorValue)) {
      setJSONInvalid(false);
    } else if (JSONEditorValue?.length !== 0 && !isJsonValid(JSONEditorValue)) {
      setJSONInvalid(true);
    } else {
      setJSONInvalid(false);
    }
  }, [JSONEditorValue]);

  const saveDefinition = () => {
    if (viewConfiguration && JSONEditorValue) {
      setIsSavingDefinition(true);
      updateViewConfiguration({
        ...viewConfiguration,
        viewConfigId: viewConfiguration.id,
        definition: JSON.parse(JSONEditorValue),
      }).then((res: any) => {
        alertMessage({ body: 'Definition Updated', type: 'success' });
        setIsSavingDefinition(false);
      });
    }
  };

  function setJSONEditorTheme(monaco: any) {
    monaco.editor.defineTheme('odinstyle', {
      base: 'vs',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic',
        },
      ],
      colors: {
        'editor.background': '#f5f5f5',
      },
    });
  }

  return (
    <Section
      title="View Definition"
      rightElement={
        <>
          {JSONInvalid && (
            <Tag intent="danger" style={{ padding: '8px 12px', marginRight: 8 }} minimal>
              Invalid JSON
            </Tag>
          )}
          <Button
            outlined
            intent="primary"
            disabled={JSONInvalid || isSavingDefinition}
            loading={isSavingDefinition}
            onClick={saveDefinition}
            icon="floppy-disk"
          >
            Save Definition
          </Button>
        </>
      }
    >
      <Col span={24} style={{ height: '100%', overflow: 'hidden' }}>
        <Skeleton loading={!schema || !viewConfiguration}>
          {viewConfiguration && (
            <Editor
              height="700px"
              theme="odinstyle"
              width="100%"
              defaultLanguage="json"
              defaultValue={JSON.stringify(viewConfiguration?.definition, null, '\t')}
              beforeMount={setJSONEditorTheme}
              onChange={(value: any) => setJSONEditorValue(value)}
              options={{
                wordWrap: 'on',
                minimap: { enabled: false },
              }}
            />
          )}
        </Skeleton>
      </Col>
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ViewConfigurationBuilder);
