import { AxiosError } from "axios";

export const getErrorMessage = (error: Error | AxiosError): string => {
  if (error && (error as AxiosError).response) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      return axiosError.response.data.message;
    }
  }

  return error.message;
};