import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { Checkbox, Col, Collapse } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { getPipelineFromShortListBySchemaId } from '../../../../../../shared/utilities/pipelineHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../shared/utilities/schemaHelpers';
import { PipelineReducerState } from '../../../../../pipelines/store/reducer';
import { ISchemaReducer } from '../../../../../schemas/store/reducer';
import { IRecordReducer } from '../../../../store/reducer';
import { addColumnToTable, IAddColumnToTable, removeColumnFromTable } from '../../store/actions';
import { getDataIndexForRecord, getDataIndexForRecordField } from '../helpers/recordFilterParsers';
import {
  getDataIndexForRelatedRecord,
  getDataIndexForRelatedRecordField,
} from '../helpers/relatedRecordFilterParsers';
import { Divider } from '@blueprintjs/core';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { getQueryBuilderReducer } from '../store/reducer';
import { parseTypeFilterForQuery } from '../helpers/typeFilterParsers';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';

interface Props {
  moduleName: string | undefined;
  entityName: string | undefined;
  recordReducer: IRecordReducer;
  recordTableReducer: any;
  schemaReducer: ISchemaReducer;
  pipelineReducer: PipelineReducerState;
  removeColumn: any;
  addColumn: any;
  queryBuilderReducer: any;
}

interface State {
  showFilters: boolean;
}

class VisibilityFilters extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showFilters: false,
    };
  }

  toggleDrawer = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  isColumnVisible = (colName: string) => {
    const { recordTableReducer } = this.props;
    return (
      !!recordTableReducer.columns &&
      recordTableReducer.columns.find((col: { [key: string]: any }) => col.dataIndex == colName)
    );
  };

  renderRecordFilterableColumns = () => {
    const { moduleName, entityName, schemaReducer } = this.props;

    if (moduleName && entityName) {
      const schema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );

      let columns: SchemaColumnEntity[] = schema?.columns || [];

      const queryBuilderReducer = getQueryBuilderReducer(
        this.props.queryBuilderReducer,
        schema?.moduleName,
        schema?.entityName,
      );
      const formFields = queryBuilderReducer.formFields;
      let typesFilterForQuery: any = undefined;

      if (formFields?.typeFilters?.length! > 0) {
        typesFilterForQuery = parseTypeFilterForQuery(formFields?.typeFilters[0]?.value);
      }

      // If there is a type selected, filter the columns by that type
      if (typesFilterForQuery) {
        let value = typesFilterForQuery.value;
        value = value.replace('(', '');
        value = value.replace(')', '');
        const typeId = schema?.types?.find((type: SchemaTypeEntity) => type.name === value)?.id;
        if (typeId) {
          columns = columns.filter((column: SchemaColumnEntity) => {
            if (column.schemaTypeId && column.schemaTypeId !== typeId) {
              return false;
            } else {
              return true;
            }
          });
        }
      }
      // If there are no types selected, remove all columns that have a type constraint
      else {
        columns = columns.filter((column: SchemaColumnEntity) => {
          if (column.schemaTypeId) {
            return false;
          } else {
            return true;
          }
        });
      }

      if (columns.length > 0) {
        columns = columns.sort((a: SchemaColumnEntity, b: SchemaColumnEntity) =>
          a.name.localeCompare(b.name),
        );
      }

      if (schema) {
        return (
          <Collapse.Panel header={schema.entityName} key="1">
            <Col key="1" span={'24'}>
              {this.renderRecordFieldCheckBox('groups')}
            </Col>
            <Col key="1" span={'24'}>
              {this.renderRecordFieldCheckBox('lastModifiedBy')}
            </Col>
            <Col key="2" span={'24'}>
              {this.renderRecordFieldCheckBox('createdBy')}
            </Col>
            <Col key="3" span={'24'}>
              {this.renderRecordFieldCheckBox('ownedBy')}
            </Col>
            <Col key="4" span={'24'}>
              {this.renderRecordFieldCheckBox('createdAt', SchemaColumnTypes.DATE)}
            </Col>
            <Col key="5" span={'24'}>
              {this.renderRecordFieldCheckBox('updatedAt', SchemaColumnTypes.DATE)}
            </Col>
            <Divider />
            {columns.map((col: SchemaColumnEntity) => (
              <Col key={col.name} span={'24'}>
                {this.renderRecordPropertyCheckBox(col)}
              </Col>
            ))}
          </Collapse.Panel>
        );
      }
    }
  };

  private renderRecordFieldCheckBox(colName: string, colType?: string) {
    return (
      <Checkbox
        key={colName}
        checked={this.isColumnVisible(getDataIndexForRecordField(colName))}
        onChange={(e) =>
          !e.target.checked
            ? this.removeRecordFieldColumn(colName)
            : this.addRecordFieldColumn(colName, colType)
        }
      >
        {colName}
      </Checkbox>
    );
  }

  private removeRecordFieldColumn(colName: string) {
    const { removeColumn } = this.props;
    return removeColumn(getDataIndexForRecordField(colName));
  }

  private addRecordFieldColumn(colName: string, colType?: string) {
    const { addColumn } = this.props;
    addColumn({
      title: colName,
      dataIndex: getDataIndexForRecordField(colName),
      columnType: colType,
    });
  }

  private renderRecordPropertyCheckBox(col: SchemaColumnEntity) {
    const colName = col.name;

    return (
      <Checkbox
        key={colName}
        checked={this.isColumnVisible(getDataIndexForRecord(colName))}
        onChange={(e) =>
          !e.target.checked ? this.removeRecordColumn(col) : this.addRecordColumn(col)
        }
      >
        {colName}
      </Checkbox>
    );
  }

  private removeRecordColumn(col: SchemaColumnEntity) {
    const { removeColumn } = this.props;
    return removeColumn(getDataIndexForRecord(col.name));
  }

  private addRecordColumn(col: SchemaColumnEntity) {
    const { addColumn } = this.props;
    addColumn({
      title: col.name,
      dataIndex: getDataIndexForRecord(col.name),
      columnType: col.type,
    });
  }

  /**
   * Renders the stage checkbox if child entity has a pipeline
   * @param schemaAssociation
   * @returns
   */
  private renderRelatedRecordStageCheckBox(schemaAssociation: SchemaAssociationEntity) {
    const { pipelineReducer } = this.props;

    // ODN-1524 load child entity pipeline if exists
    const pipeline = getPipelineFromShortListBySchemaId(
      pipelineReducer.shortList,
      schemaAssociation?.childSchema?.id,
    );

    if (pipeline) {
      return (
        <Col key={`${schemaAssociation.childSchema.entityName}_stage`} span={'24'}>
          {this.renderRelatedRecordFieldCheckBox('stage', schemaAssociation.childSchema.entityName)}
        </Col>
      );
    }
  }

  renderRelatedRecordFilterableColumns = () => {
    const { moduleName, entityName, schemaReducer } = this.props;

    if (moduleName && entityName) {
      const schema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );

      if (schema && schema.associations) {
        const childAssociations = schema.associations.filter(
          (elem) => elem.childSchema && elem.searchable,
        );

        return childAssociations?.map((association) => (
          <Collapse.Panel
            header={association.childSchema.entityName}
            key={association.childSchema.entityName}
          >
            <Col key={association.childSchema.entityName} span={'24'}>
              {this.renderRelatedRecordFieldCheckBox('title', association.childSchema.entityName)}
            </Col>

            {this.renderRelatedRecordStageCheckBox(association)}
            {association.childSchema.columns &&
              association.childSchema.columns
                ?.sort((a: SchemaColumnEntity, b: SchemaColumnEntity) =>
                  a.name.localeCompare(b.name),
                )
                .map((col: SchemaColumnEntity) => (
                  <Col key={col.name} span={'24'}>
                    {this.renderRelatedRecordPropertyCheckBox(
                      col.name,
                      association.childSchema.entityName,
                      col.type,
                    )}
                  </Col>
                ))}
          </Collapse.Panel>
        ));
      }
    }
  };

  private renderRelatedRecordFieldCheckBox(colName: string, entityName: string) {
    return (
      <Checkbox
        key={colName}
        checked={this.isColumnVisible(getDataIndexForRelatedRecordField(colName, entityName))}
        onChange={(e) =>
          !e.target.checked
            ? this.removeRelatedRecordFieldColumn(colName, entityName)
            : this.addRelatedRecordFieldColumn(colName, entityName)
        }
      >
        {colName}
      </Checkbox>
    );
  }

  private removeRelatedRecordFieldColumn(colName: string, entityName: string) {
    const { removeColumn } = this.props;
    return removeColumn(getDataIndexForRelatedRecordField(colName, entityName));
  }

  private addRelatedRecordFieldColumn(colName: string, entityName: string) {
    const { addColumn } = this.props;
    addColumn({
      title: `${entityName} ${colName}`,
      dataIndex: getDataIndexForRelatedRecordField(colName, entityName),
    });
  }

  private renderRelatedRecordPropertyCheckBox(
    colName: string,
    entityName: string,
    colType: string,
  ) {
    return (
      <Checkbox
        key={colName}
        checked={this.isColumnVisible(getDataIndexForRelatedRecord(colName, entityName))}
        onChange={(e) =>
          !e.target.checked
            ? this.removeRelatedColumn(colName, entityName)
            : this.addRelatedColumn(colName, entityName, colType)
        }
      >
        {colName}
      </Checkbox>
    );
  }

  private removeRelatedColumn(colName: string, entityName: string) {
    const { removeColumn } = this.props;
    return removeColumn(getDataIndexForRelatedRecord(colName, entityName));
  }

  private addRelatedColumn(colName: string, entityName: string, colType: string) {
    const { addColumn } = this.props;
    addColumn({
      title: colName,
      dataIndex: getDataIndexForRelatedRecord(colName, entityName),
      columnType: colType,
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Collapse defaultActiveKey={['1']} ghost>
          {this.renderRecordFilterableColumns()}
          {this.renderRelatedRecordFilterableColumns()}
        </Collapse>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
  schemaReducer: state.schemaReducer,
  pipelineReducer: state.pipelineReducer,
  queryBuilderReducer: state.queryBuilderReducer,
});

const mapDispatch = (dispatch: any) => ({
  addColumn: (params: IAddColumnToTable) => dispatch(addColumnToTable(params)),
  removeColumn: (column: any) => dispatch(removeColumnFromTable(column)),
});

export default connect(mapState, mapDispatch)(VisibilityFilters);
