import React from 'react';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../shared/utilities/recordHelpers';
import './index.scss';
import AccountLayout from './SearchResultLayouts/AccountLayout';
import OrderLayout from './SearchResultLayouts/OrderLayout';
import WorkOrderLayout from './SearchResultLayouts/WorkOrderLayout';
import ContactLayout from './SearchResultLayouts/ContactLayout';
import { Button, Section } from '@blueprintjs/core';
import ModuleEntityIcon from '../../core/theme/ModuleEntityIcon';

interface Props {
  entityName: string;
  searchResult: any;
  isLoading: boolean;
  onClose: any;
  globalCollapsed: boolean;
  onNumberClick?: Function;
}

interface State {
  opened: boolean;
}

class SearchResult extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { opened: false };
  }

  renderSearchResultBody = (entityName: string, result: any) => {
    switch (entityName) {
      case 'WorkOrder':
        return (
          <WorkOrderLayout
            result={result}
            onClose={this.props.onClose}
            globalCollapsed={this.props.globalCollapsed}
          />
        );
      case 'Order':
        return (
          <OrderLayout
            result={result}
            onClose={this.props.onClose}
            globalCollapsed={this.props.globalCollapsed}
          />
        );
      case 'Account':
        return (
          <AccountLayout
            result={result}
            onClose={this.props.onClose}
            globalCollapsed={this.props.globalCollapsed}
          />
        );
      case 'Contact':
        return (
          <ContactLayout
            result={result}
            onClose={this.props.onClose}
            globalCollapsed={this.props.globalCollapsed}
            onNumberClick={this.props.onNumberClick}
          />
        );
      default:
        return result.title;
    }
  };

  render() {
    const { searchResult, entityName, isLoading } = this.props;

    return (
      <Section
        collapsible
        key={searchResult.id}
        icon={
          <ModuleEntityIcon
            entityName={entityName}
            moduleName={searchResult.entity.split(':')[0]}
            iconContainerStyle={{ marginRight: 0, padding: '8px 10px' }}
          />
        }
        rightElement={
          <Link
            to={getBrowserPath(searchResult)}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            <Button text="View" intent="primary" minimal />
          </Link>
        }
        title={searchResult.entity.split(':')[1]}
        subtitle={
          <Link
            to={getBrowserPath(searchResult)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            target="_blank"
          >
            {searchResult.recordNumber ? `#${searchResult.recordNumber}` : searchResult.title}
          </Link>
        }
      >
        <div style={{ padding: 10 }}>{this.renderSearchResultBody(entityName, searchResult)}</div>
      </Section>
    );
  }
}

export default SearchResult;
