import { FeaturePriceEntryOverride } from '../../../../containers/ProjectModule/components/ProjectModuleWorkListDetailView/AFPDetails';
import { DashboardData, DashboardLineItem } from './hooks/types';

export const applyFilters = (
  items: DashboardLineItem[],
  filters: Record<keyof DashboardLineItem, string[]>,
) => {
  const filterKeys = Object.keys(filters);
  if (filterKeys.length === 0) return items;

  return items.filter((item) => {
    const appliedFilters = Object.entries(filters)
      .map(([field, values]) => {
        if (values.length === 0) return true;
        return values.includes(String(item[field as keyof DashboardLineItem]));
      })
      .reduce((a, b) => a && b, true);

    return appliedFilters;
  });
};

export const getUniqueValues = (items: DashboardLineItem[], attribute: keyof DashboardLineItem) => {
  const uniqueValues = new Set<string>();
  items.forEach((item) => uniqueValues.add(item[attribute] as string));

  return [...uniqueValues];
};

export const isLineItemRejected = (lineItem: DashboardLineItem) =>
  lineItem.is_approved === 'false' && !lineItem.rejection_response_comment;

export const isLineItemUnbilled = (lineItem: DashboardLineItem) => lineItem.is_approved === null;

type DashboardLineItemStatus =
  | 'Not Submitted'
  | 'Submitted'
  | 'Approved'
  | 'Rejected'
  | 'Resubmitted';

const getAdjustmentStatus = (
  adjustment: DashboardLineItem,
  otherLineItems: DashboardLineItem[],
): DashboardLineItemStatus => {
  const otherAdjustments = otherLineItems.filter((li) => li.fpe_id !== adjustment.fpe_id);

  // Checking single adjustment
  if (otherAdjustments.length === 0) {
    if (adjustment.is_approved === 'true') return 'Approved';
    if (adjustment.is_approved === 'false') {
      if (adjustment.rejection_response_comment) return 'Not Submitted';
      return 'Rejected';
    }

    return adjustment.afp_id ? 'Submitted' : 'Not Submitted';
  }

  // Checking multiple adjustments
  const resubmittedAdjustment = otherAdjustments.find(
    (li) => !li.is_approved || li.is_approved === 'true',
  );
  if (resubmittedAdjustment) {
    if (adjustment.is_approved === 'false' && !adjustment.rejection_response_comment)
      return 'Rejected';
    if (
      (adjustment.is_approved === 'false' && adjustment.rejection_response_comment) ||
      (!adjustment.is_approved && adjustment.afp_id)
    )
      return 'Resubmitted';
    if (adjustment.is_approved === 'true') return 'Approved';
  }

  return 'Not Submitted';
};

export const getLineItemStatus = (
  lineItem: DashboardLineItem,
  otherLineItems: DashboardLineItem[] = [],
): DashboardLineItemStatus => {
  if (lineItem.fpe_type === 'ADJUSTMENT') return getAdjustmentStatus(lineItem, otherLineItems);

  if (lineItem.is_approved === 'true') return 'Approved';
  if (lineItem.is_approved === 'false') {
    const approvedFpe = otherLineItems.find((li) => li.is_approved === 'true');
    if (approvedFpe) return 'Rejected';

    const resubmittedFpe = otherLineItems.find(
      (li) => li.parent_fpe_id && lineItem.fpe_id && li.parent_fpe_id === lineItem.fpe_id,
    );
    if (resubmittedFpe) return 'Resubmitted';

    return !lineItem.rejection_response_comment ? 'Rejected' : 'Not Submitted';
  }
  if (!lineItem.afp_id) return 'Not Submitted';

  return 'Submitted';
};

export const getLineItemRowKey = (lineItem: DashboardLineItem) =>
  lineItem.fpe_id
    ? `fpe_${lineItem.fpe_id}_${lineItem.fpe_title}_${lineItem.fpe_type}`
    : `feature_${lineItem.feature_id}_${lineItem.fpe_title}_${lineItem.fpe_type}`;

export const getFilteredLineItems = (
  lineItems: DashboardLineItem[],
  filters: Record<keyof DashboardLineItem, string[]>,
) => {
  const filteredLineItems = applyFilters(lineItems ?? ([] as DashboardLineItem[]), filters);
  return filteredLineItems;
};

export const groupLineItemsByWorkList = (lineItems: DashboardLineItem[]) => {
  const groupedLineItems: Record<string, DashboardLineItem[]> = {};

  lineItems.forEach((lineItem) => {
    if (lineItem.work_list_id in groupedLineItems)
      groupedLineItems[lineItem.work_list_id].push(lineItem);
    else groupedLineItems[lineItem.work_list_id] = [lineItem];
  });

  return groupedLineItems;
};

export const groupLineItemsByAttribute = (
  lineItems: DashboardLineItem[],
  attribute: keyof DashboardLineItem,
) => {
  const groupedLineItems: Record<string, DashboardLineItem[]> = {};

  lineItems.forEach((lineItem) => {
    const value = lineItem[attribute] as string;
    if (value in groupedLineItems) groupedLineItems[value].push(lineItem);
    else groupedLineItems[value] = [lineItem];
  });

  return groupedLineItems;
};

const getLineItemTitleAndType = (lineItem: DashboardLineItem) =>
  `${lineItem.fpe_title}-${lineItem.fpe_type}`;

const groupLineItemsByTitleAndType = (lineItems: DashboardLineItem[]) => {
  const result: { [key: string]: DashboardLineItem[] } = {};

  lineItems.forEach((lineItem) => {
    const value = getLineItemTitleAndType(lineItem);
    if (value in result) result[value].push(lineItem);
    else result[value] = [lineItem];
  });

  return result;
};

export const getFpeOverrideFromLineItem = (
  lineItem: DashboardLineItem,
): FeaturePriceEntryOverride => ({
  poly_id: lineItem.poly_id,
  polytype: lineItem.polygon_type,
  feature_type: lineItem.feature_type,
  feature_id: lineItem.qgis_feature_id,
  rate_code: lineItem.rate_code,
  description: lineItem.description,
  unit: lineItem.rate_unit,
  price: lineItem.price,
  quantity: lineItem.quantity,
  total: lineItem.total,
  parent_fpe_id: lineItem.fpe_id,
});

const addStatusToLineItem =
  (lineItemsPerTitle: Record<string, DashboardLineItem[]>) =>
  (lineItem: DashboardLineItem): DashboardLineItem => ({
    ...lineItem,
    status: getLineItemStatus(lineItem, lineItemsPerTitle[getLineItemTitleAndType(lineItem)]),
  });

const addParentFpeToLineItem =
  (lineItemsPerTitle: Record<string, DashboardLineItem[]>) =>
  (lineItem: DashboardLineItem): DashboardLineItem => {
    // Don't touch lineItems that are submitted (have FPE id)
    if (lineItem.fpe_id) return lineItem;

    const lineItemsToCheck = lineItemsPerTitle[getLineItemTitleAndType(lineItem)] ?? [];
    const parentIds = lineItemsToCheck
      .filter((li) => li.parent_fpe_id)
      .map((li) => li.parent_fpe_id);
    const rejectedIds = lineItemsToCheck
      .filter(
        (li) =>
          li.is_approved === 'false' &&
          li.rejection_response_comment &&
          !parentIds.includes(li.fpe_id),
      )
      .map((li) => li.fpe_id);

    return {
      ...lineItem,
      parent_fpe_id: rejectedIds[0],
    };
  };

export const preprocessData = (data: DashboardData): DashboardData => {
  const lineItemsPerTitle = groupLineItemsByTitleAndType(data.lineItems ?? []);

  return {
    ...data,
    lineItems: (data.lineItems ?? [])
      .map(addStatusToLineItem(lineItemsPerTitle))
      .map(addParentFpeToLineItem(lineItemsPerTitle)),
  };
};
