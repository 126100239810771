import { ButtonGroup } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { initializeRecordForm } from '../../../../../../../core/records/components/Forms/store/actions';
import CaseBlockedButton from './CaseBlockedButton';
import RequestReviewButton from './RequestReviewButton';
import SubmitCaseSolvedButton from './SubmitCaseSolvedButton';

interface Props {}

const MyCasesBottomRowControl: React.FC<Props> = (props: Props) => {
  const {} = props;
  return (
    <Row align="middle" style={{ height: '100%', padding: '0 15px' }}>
      <Col span={12}>
        {/* Empty left column, probably will be used as the design progresses. */}
      </Col>

      <Col span={12} style={{ textAlign: 'right' }}>
        <ButtonGroup>
          <CaseBlockedButton />
          <RequestReviewButton />
          <SubmitCaseSolvedButton />
        </ButtonGroup>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(MyCasesBottomRowControl);
