import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultTerritory: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITerritory {
  name: string;
  id: string;
  disabled: boolean;
}

const UpdateTerritorySelect: FC<Props> = (props: Props) => {
  const { entity, defaultTerritory, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedTerritory, setSelectedTerritory] = useState<ITerritory | undefined>();
  const [isLoadingTerritories, setIsLoadingTerritories] = useState<boolean>(false);
  const [allTerritories, setAllTerritories] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (defaultTerritory) {
      setSelectedTerritory({
        name: defaultTerritory?.name,
        id: defaultTerritory?.id,
        disabled: false,
      });
    } else {
      setSelectedTerritory(undefined);
    }
  }, [defaultTerritory, sourceRecord]);

  useEffect(() => {
    loadAllTerritories();
  }, []);

  const loadAllTerritories = async () => {
    setIsLoadingTerritories(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/territories?size=10000`);
      const territories = res.data?.data || [];
      setAllTerritories(territories);
      setIsLoadingTerritories(false);
    } catch (error) {
      setIsLoadingTerritories(false);
    }
  };

  // Update Territory if different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultTerritory?.id) {
      updateEntityWithTerritory(item);
    }
  };

  const updateEntityWithTerritory = async (selectedTerritory: ITerritory) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        territoryId: selectedTerritory.id === 'no-territory' ? null : selectedTerritory.id,
      });

      if (selectedTerritory.id === 'no-territory') {
        setSelectedTerritory(undefined);
      } else {
        setSelectedTerritory(selectedTerritory);
      }

      setSelectedTerritory(selectedTerritory);
      setIsUpdating(false);
      onUpdate && onUpdate();
      alertMessage({
        body: `Territory updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update territory. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderTerritory: ItemRenderer<ITerritory> = (
    territory,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (territory.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more territories..."
        />
      );
    } else
      return (
        <MenuItem
          active={territory.id === selectedTerritory?.id}
          disabled={modifiers.disabled}
          key={territory.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={territory.name}
        />
      );
  };

  let TERRITORIES: ITerritory[] = allTerritories.map((f, index) => ({
    id: f.id,
    name: f.name,
    key: f.id,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    TERRITORIES = TERRITORIES.filter((territory) => {
      return territory.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  TERRITORIES = TERRITORIES.slice(0, 100);

  // Append the Search information if there are more than 100 items
  if (TERRITORIES.length === 100) {
    TERRITORIES.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Territory" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    TERRITORIES.unshift({
      id: 'no-territory',
      name: '(No Territory)',
      disabled: false,
    });
  }

  return (
    <Select<ITerritory>
      items={TERRITORIES}
      disabled={isLoadingTerritories || allTerritories.length === 0}
      itemRenderer={renderTerritory}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedTerritory ? 'area-of-interest' : null}
        alignText="left"
        disabled={isLoadingTerritories || allTerritories.length === 0 || isUpdating}
        loading={isLoadingTerritories}
        text={selectedTerritory?.name || 'Select territory'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateTerritorySelect);
