import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { OrganizationUserRbacPermissionEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/rbac/permission/organization.user.rbac.permission.entity';
import { FC, useState } from 'react';
import { DualPanelAssignment } from '../../../../../../v2/shared/components/DualPanelAssignment';
import './styles.scss';

interface Props {
  permission: OrganizationUserRbacPermissionEntity;
  roles: any[];
}

type TRole = {
  id: string;
  name: string;
  description: string;
};

export const PermissionViewRolesDialog: FC<Props> = (props: Props) => {
  const { permission } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [roles, setRoles] = useState<TRole[]>([]);

  const closeModal = () => {
    setIsDialogOpen(false);
    setRoles([]);
  };

  const openModal = () => {
    if (props.roles.length > 0) {
      const roles = props.roles.map((role) => ({
        id: role.id,
        name: role.name,
        description: role.description,
      }));
      setRoles(roles as TRole[]);
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Button small minimal intent="primary" text="View" onClick={openModal} />
      <Dialog
        title="View Roles with this Permission"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        style={{ width: '50%' }}
      >
        <DialogBody className="assignRolesDialog">
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Groups"
            leftPanelIcon="people"
            leftPanelSubtitle="All available groups in the system"
            leftPanelData={undefined}
            onLeftPanelChange={() => {}}
            // Right Panel
            rightPanelTitle={permission.name}
            rightPanelIcon="person"
            rightPanelSubtitle="All Roles containing this Permission"
            rightPanelData={roles}
            onRightPanelChange={() => {}}
          />
        </DialogBody>
        <DialogFooter actions={[<Button key="Close" text="Close" onClick={closeModal} />]}>
          {/* Dialog Footer */}
          {
            // <>
            //   <span>Total: </span>
            //   <span>{roles.length}</span>
            // </>
          }
        </DialogFooter>
      </Dialog>
    </>
  );
};
