import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Descriptions, Layout, Modal, Row, Spin } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { OutcomeFormBody } from '../../../../containers/FieldServiceModule/containers/OutcomeFormDetailView/OutcomeFormBody';
import ProjectModuleIntersectingPIA from '../../../../containers/ProjectModule/components/ProjectModuleIntersectingPIA';
import PIAAssociationBuilder from '../../../../containers/ProjectModule/containers/PIAAssociationBuilder';
import PTAListAndCreateCard from '../../../../containers/ProjectModule/containers/PTAListAndCreateCard';
import CardWithTabs from '../../../../shared/components/CardWithTabs';
import { renderCreateUpdateDetails } from '../../../../shared/components/RecordCreateUpdateDetails';
import {
  getJSONColumnsFromSchema,
  schemaHasType,
} from '../../../../shared/utilities/schemaHelpers';
import AssociationDataTable from '../../../recordsAssociations/components/AssociationDataTable';
import UploadFileFromAssociation from '../../../recordsAssociations/components/UploadFileFromAssociation';
import { renderDynamicAssociationTables } from '../../../recordsAssociations/helpers/component-helpers';
import { IRecordReducer } from '../../store/reducer';
import ActivityCenter from '../ActivityCenter';
import Pipeline from '../Pipeline/Pipeline';
import RawDataRecordProperties from '../RawData/RawDataRecordProperties';
import RecordHeader from '../RecordHeader';
import RecordProperties from '../RecordProperties';
import './styles.scss';

import { Section } from '@blueprintjs/core';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import ClosureFiberSplicingTable from '../../../../com.netomnia/modules/ProjectModule/Autosplicing/components/ClosureFiberSplicingTable';
import CableDiagram from '../../../../containers/ProjectModule/containers/CableDiagram';
import WorkList from '../../../../containers/ProjectModule/containers/WorkList';
import GroupDetails from '../../../../shared/components/GroupDetails';
import { getAllSchemaAssociationSchemas } from '../../../../shared/utilities/recordHelpers';
import NoteFeed from '../../../../v2/shared/components/NoteFeed';
import OutcomeFormLauncher from '../../../../v2/shared/components/OutcomeFormLauncher';

interface Props {
  record: DbRecordEntityTransform;
  schema?: SchemaEntity;
  relatedSchemas: SchemaEntity[];
  hasColumnMappings?: boolean;
  disableBreadcrumbs?: boolean;
  disableClone?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  hideRecordDetails?: boolean;
  hideActivityCenter?: boolean;
  hideFileManager?: boolean;
  visibleProperties?: string[];
  recordReducer: IRecordReducer;
  defaultTabKey?: string;
  customTabs?: { key: string; tab: string }[];
  customContent?: { [key: string]: any };
  customContentRightPanel?: React.ReactElement;
  toggleColumnState?: any;
  detailsColumnExpanded?: boolean;
  extra?: any;
  children?: any;
  quickView?: boolean;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { PROJECT } = SchemaModuleEntityTypeEnums;

class RecordMainContentWide extends React.Component<Props> {
  render() {
    const {
      record,
      recordReducer,
      hasColumnMappings,
      relatedSchemas,
      visibleProperties,
      customTabs,
      customContent,
      customContentRightPanel,
      defaultTabKey,
      detailsColumnExpanded,
      extra,
      schema,
    } = this.props;

    const JSONColumns = getJSONColumnsFromSchema(schema);

    const isOutcomeForm = schemaHasType(schema!, 'OUTCOME_FORM') || undefined;

    const getDefaultTabKey = () => {
      if (isOutcomeForm) {
        return 'Form';
      } else {
        return defaultTabKey || 'RecordDetails';
      }
    };

    // Certain schemas have OUTCOME_FORM type, find those schemas and filter them out from relatedSchemas
    let schemasWithOutcomeForm: SchemaEntity[] =
      getAllSchemaAssociationSchemas(schema?.associations)?.filter((relatedSchema: SchemaEntity) =>
        schemaHasType(relatedSchema, 'OUTCOME_FORM'),
      ) || [];

    return (
      <Layout className="record-detail-view">
        <UploadFileFromAssociation />
        <Modal open={recordReducer.isExportingAssociations} centered={true} footer={null}>
          <Spin spinning={recordReducer.isExportingAssociations}>data exporting...</Spin>
        </Modal>
        <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <RecordHeader
              hasColumnMappings={hasColumnMappings}
              visibleProperties={visibleProperties}
              record={record}
            >
              <RecordProperties columns={1} record={record} />
              {renderCreateUpdateDetails(record)}
            </RecordHeader>
          </Col>

          {record?.stage && (
            <Col xs={24} sm={24} md={24} lg={24} style={{ marginTop: isMobile ? 0 : '15px' }}>
              <div className="record-detail-left-panel">
                <Pipeline record={record} />
              </div>
            </Col>
          )}

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={detailsColumnExpanded ? 24 : 18}
            style={{ marginTop: record?.stage ? 0 : '10px' }}
            className="detailsColumn"
          >
            <Row>
              {/* Card with tabs */}
              <Col span={24}>
                <CardWithTabs
                  moduleName={schema?.moduleName}
                  entityName={schema?.entityName}
                  recordId={record?.id}
                  extra={extra}
                  title={
                    <Row>
                      <Col span={20}>
                        <span>Details & Associated Records</span>
                      </Col>
                    </Row>
                  }
                  defaultTabKey={getDefaultTabKey()}
                  tabList={[
                    // If Schema has OUTCOME_FORM type, show Form Viewer tab
                    isOutcomeForm
                      ? {
                          key: 'Form',
                          tab: 'Form',
                        }
                      : { key: '', tab: '' },
                    this.props.hideRecordDetails
                      ? { key: '', tab: '' }
                      : {
                          key: 'RecordDetails',
                          tab: 'Record Details',
                        },
                    // For BLOCKAGE type features, show association builder tab
                    record?.type === 'BLOCKAGE'
                      ? {
                          key: 'PIAFeatures',
                          tab: 'PIA Features',
                        }
                      : { key: '', tab: '' },
                    // For CLOSURE type features, show FiberSplicing tab
                    record?.type === 'CLOSURE'
                      ? {
                          key: 'FiberSplicing',
                          tab: 'Fiber Splicing',
                        }
                      : { key: '', tab: '' },
                    // QUICK VIEW
                    //
                    // For ProjectModule:Project record quick views, show these tabs additionally
                    record?.entity === `${PROJECT_MODULE}:${PROJECT}` && this.props.quickView
                      ? {
                          key: 'WorkItems',
                          tab: 'Work Items',
                        }
                      : { key: '', tab: '' },
                    record?.entity === `${PROJECT_MODULE}:${PROJECT}` && this.props.quickView
                      ? {
                          key: 'WorkLists',
                          tab: 'Work Lists',
                        }
                      : { key: '', tab: '' },
                    record?.entity === `${PROJECT_MODULE}:${PROJECT}` && this.props.quickView
                      ? {
                          key: 'CableDiagram',
                          tab: 'Cable Diagram',
                        }
                      : { key: '', tab: '' },
                    {
                      key: JSONColumns?.length > 0 ? 'JSON' : '',
                      tab: JSONColumns?.length > 0 ? 'JSON' : '',
                    },
                    {
                      key: 'Activity',
                      tab: 'Activity',
                    },
                    ...(schemasWithOutcomeForm.length > 0
                      ? [
                          {
                            key: 'OutcomeForm',
                            tab: 'Outcome Form',
                          },
                        ]
                      : []),
                    ...(customTabs ? customTabs : []),
                  ]}
                  tabContents={{
                    Activity: <ActivityCenter record={record} schema={schema!} />,
                    Form: <OutcomeFormBody schema={schema!} record={record!} />,
                    PIAFeatures: <PIAAssociationBuilder record={record} />,
                    RecordDetails: [
                      <RecordProperties record={record} />,
                      <Col span={24}>
                        <Descriptions
                          style={{ marginTop: 12 }}
                          bordered
                          size="small"
                          contentStyle={{ fontSize: '0.9em', padding: '4px 6px', width: '80%' }}
                          labelStyle={{
                            fontSize: '0.9em',
                            padding: '4px 6px',
                            fontWeight: 500,
                            width: '20%',
                          }}
                          column={2}
                        >
                          <Descriptions.Item key="Groups" label="Groups" span={1}>
                            <GroupDetails record={record} />
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>,
                    ],
                    FiberSplicing: (
                      <ClosureFiberSplicingTable
                        record={record}
                        externalRef={getProperty(record, 'ExternalRef')}
                        // featureType={record?.type!}
                      />
                    ),
                    WorkLists: (
                      <AssociationDataTable
                        title={'Work Lists'}
                        record={record!}
                        moduleName={'ProjectModule'}
                        entityName={'WorkList'}
                      />
                    ),
                    CableDiagram: (
                      <CableDiagram
                        recordId={record?.id}
                        polygonId={getProperty(record, 'L2PolygonId')}
                        columnFullscreen={true}
                      />
                    ),
                    WorkItems: <WorkList projectRecord={record} projectId={record?.id} />,
                    JSON: <RawDataRecordProperties record={record!} schema={schema!} />,
                    OutcomeForm:
                      schemasWithOutcomeForm.length > 0 ? (
                        <>
                          <OutcomeFormLauncher
                            parentRecord={record!}
                            parentSchema={schema!}
                            columns={2}
                          />
                          {schemasWithOutcomeForm?.map((schema) => (
                            <AssociationDataTable
                              collapsedByDefault
                              showAllRecords
                              hideIcon
                              title={schema?.entityName}
                              record={record}
                              moduleName={schema?.moduleName}
                              entityName={schema?.entityName}
                            />
                          ))}
                        </>
                      ) : (
                        <></>
                      ),
                    ...(customContent ? customContent : {}),
                  }}
                  relatedAssociations={renderDynamicAssociationTables(record, relatedSchemas)}
                />
              </Col>

              {this.props.children}

              {/* If record type is CABLE show PIA Image Uploader  */}
              {record?.type === 'CABLE' && (
                <Col span={24} style={{ marginBottom: 30 }}>
                  <ProjectModuleIntersectingPIA record={record} />
                </Col>
              )}

              {/* If record type is DUCT (Type 2-SDuct) show PIA Image Uploader  */}
              {record?.type === 'DUCT' && getProperty(record, 'DuctType') === '2' && (
                <Col span={24} style={{ marginBottom: 30 }}>
                  <ProjectModuleIntersectingPIA record={record} />
                </Col>
              )}

              {/* Note Feed */}
              <Col span={24} style={{ marginBottom: 30 }}>
                <Section title="Notes">
                  <div style={{ padding: 15 }}>
                    <NoteFeed record={record!} />
                  </div>
                </Section>
              </Col>
            </Row>
          </Col>

          {/* Right column */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={detailsColumnExpanded ? 24 : 6}
            style={{ marginTop: isMobile || record?.stage ? 0 : 10 }}
            className="updatesColumn"
          >
            <div className="record-detail-right-panel">
              {/* Closures in Build Status 8 should show Permit to Access Card */}
              {record?.type === 'CLOSURE' && getProperty(record, 'BuildStatus') === '8' ? (
                <PTAListAndCreateCard parentRecord={record!} />
              ) : (
                <></>
              )}

              {customContentRightPanel}
              {!this.props.hideFileManager && (
                <AssociationDataTable
                  thumbnailSize={12}
                  title="Files"
                  record={record}
                  showFileCategoryForType="DEFAULT"
                  moduleName="SchemaModule"
                  entityName="File"
                  location="sidebar"
                  collapsedByDefault
                />
              )}
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

export default connect(mapState)(RecordMainContentWide);
