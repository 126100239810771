import React from 'react';
import { connect } from 'react-redux';
import { Button, Result } from 'antd';
import history from '../utilities/browserHistory';
import { withRouter } from 'react-router-dom';
import { logoutRequest } from '../../core/identity/store/actions';

interface Props {
  logout: Function
}

const AppUnavailablePage = (props: Props) => {

  const { logout } = props

  return (
    <Result
      style={{ marginTop: 70 }}
      status="403"
      title="Access denied"
      subTitle="You don't have permissions to access Desktop application."
      extra={
        <Button
          size="large" type="primary"
          style={{ marginTop: 15 }}
          onClick={() => {
            history.push('/login');
            logout();
          }}>
          Sign out
        </Button>
      }
    />
  );
};

const mapDispatch = (dispatch: any) => ({
  logout: () => dispatch(logoutRequest()),
});

const mapState = (state: any) => ({});

export default withRouter(connect(mapState, mapDispatch)(AppUnavailablePage));
