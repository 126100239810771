import { useContext, useState } from "react";
import { Button } from "@blueprintjs/core";

import { DetailViewContext } from '../../components/DetailViewContextProvider';
import { httpGet } from "../../../../shared/http/requests";
import { getEntityNameFromRecord } from "../../../../shared/utilities/recordHelpers";

export const DownloadRecordPdf = () => {
  const { record } = useContext(DetailViewContext);
  const entityName = getEntityNameFromRecord(record);
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = async () => {
    setIsLoading(true);
    try {
      const { data: report} = await httpGet(`SchemaModule/v1.0/s3/files/${entityName}/${record?.id}/pdf`, {}, { responseType: 'blob' });
      const reportBlob = new Blob([report], { 'type': 'application/pdf' });
      const reportUrl = URL.createObjectURL(reportBlob);
      const reportLink = document.createElement('a');
      reportLink.setAttribute('target', '_blank');
      reportLink.setAttribute('download', `${entityName}-${record.id}.pdf`);
      reportLink.href = reportUrl;
      document.body.appendChild(reportLink);
      reportLink.click();
      document.body.removeChild(reportLink);
      URL.revokeObjectURL(reportUrl);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  if (!record || record.type !== 'OUTCOME_FORM') return null;

  return (
    <Button onClick={clickHandler} disabled={isLoading} loading={isLoading}>Download PDF</Button>
  )
};
