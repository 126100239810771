import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PageHeader } from '../../../../../shared/components/PageHeader';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../shared/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { searchString } from '../../../../../shared/utilities/searchHelpers';
import { BlueprintNavigation } from '../../../../../v2/shared/components/BlueprintPagination';
import * as formFields from '../FormFields';
import TemplateDetails from '../TemplateDetails';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string | undefined;
  name: string | undefined;
  description: string | undefined;
}

const uuid = uuidv4();

const TemplateListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  useEffect(() => {
    getAllTemplatesList();
  }, []);

  useEffect(() => {
    setSelectedRegions([]);
    setSelectedTemplate(undefined);
  }, [currentPage]);

  const onSelect = (e: any) => {
    setSelectedRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (templateList.length > 0) {
      let tableData: ITableData[] = Object.assign([], templateList);

      tableData = templateList.map((t: any) => ({
        key: t.id,
        name: t.name,
        description: t.description,
      }));

      // Apply Search
      tableData = tableData.filter((t: any) => {
        return searchString(t.name, searchKey) || searchString(t.description, searchKey);
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [templateList, currentPage, searchKey, pageSize]);

  // Get selected template details
  useEffect(() => {
    if (selectedRegions.length > 0 && tableSlice.length > 0) {
      const template: any = tableSlice[selectedRegions[0].rows[0]];
      if (template && !isTemplateLoading) {
        getTemplateById(template.key);
      }
    }
  }, [selectedRegions, tableSlice]);

  const getTemplateById = (id: string) => {
    setIsTemplateLoading(true);
    httpGet(`IdentityModule/v2.0/onboarding-templates/${id}`)
      .then((res) => {
        setIsTemplateLoading(false);
        setSelectedTemplate(res.data.data);
        console.log('%cdebug: Selected template', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({ body: 'Could not retrieve template. ' + message, type: 'error' });
        setIsTemplateLoading(false);
        setSelectedTemplate(undefined);
        setSelectedRegions([]);
      });
  };

  const getAllTemplatesList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v1.0/users/onboarding-templates`);
      const templates: any[] = res.data.data || [];
      setTemplateList(templates);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve template list. ' + message, type: 'error' });
      setTemplateList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedTemplate, selectedRegions, tableRef]);

  const showCreateTemplateForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Template',
      formFields: formFields.createTemplateFormFields,
      entityName: 'Template',
    });
  };

  const createNewTemplate = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/onboarding-templates', body);
      const newTemplate = res.data.data;
      setTemplateList([...templateList, newTemplate]);
      getTemplateById(newTemplate.id);

      alertMessage({ body: 'Template Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create template. ' + message,
        type: 'error',
      });
    }
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
      };
      createNewTemplate({ body });
    }
  };

  const onSearch = (e: any) => {
    setSelectedRegions([]);
    setSearchKey(e.target.value);
  };

  const onTemplateUpdate = () => {
    if (selectedTemplate) {
      getTemplateById(selectedTemplate.id);
    }
  };

  const onTemplateDelete = () => {
    setSelectedRegions([]);

    const id = selectedTemplate?.id;
    if (id) {
      setTemplateList(templateList.filter((t) => t.id !== id));
      setSelectedTemplate(undefined);
    }
  };

  const renderTemplates = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Templates"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreateTemplateForm}>
                  Create Template
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col
            span={selectedTemplate ? 17 : 24}
            style={{ height: '100%', width: '100%', padding: 1 }}
          >
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[getColumnWidthByPercentage(30), getColumnWidthByPercentage(70)]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Template Details */}
          {selectedTemplate && (
            <Col className="listViewDetailsColumn" span={7}>
              <Section
                title="Template Details"
                compact
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedRegions([]);
                      setSelectedTemplate(undefined);
                    }}
                  />
                }
              >
                <TemplateDetails
                  template={selectedTemplate}
                  onUpdate={onTemplateUpdate}
                  onDelete={onTemplateDelete}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={templateList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return (
    <Col span={24} style={{ background: 'white', padding: '0 15px' }}>
      {renderTemplates()}
    </Col>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(TemplateListView);
