import { Row, Col, Skeleton } from 'antd';
import React, { useContext } from 'react';
import { invoiceBuilderContext } from '..';
import RecordCard from '../../../../../../core/records/components/RecordCard';
import { isRecordOrder } from '../helpers';

type Props = {
    updateContact: Function
    updateAddress: Function
};

const InvoiceDetails: React.FC<Props> = (props: Props) => {

    const { state } = useContext(invoiceBuilderContext)
    const { updateAddress, updateContact } = props

    const parentRecordProperties = isRecordOrder(state.parentRecord) 
    ? ['ActiveDate', 'BillingDay', 'ContractStartDate', 'ContractEndDate']
    : ['Type', 'ConfirmationStatus', 'Provider', 'Description']

    return (
        <Row gutter={12}>

            <Col xs={24} lg={8} style={{ padding: 5 }}>
                <Skeleton loading={state.isLoadingParentRecord}>
                    <RecordCard
                        openTitleLinkInNewTab
                        onListActionSuccess={updateContact}
                        showActionMenu
                        hideIcon
                        record={state.parentRecord}
                        visibleProperties={parentRecordProperties}
                    />
                </Skeleton>
            </Col>

            <Col xs={24} lg={8} style={{ padding: 5 }}>
                <Skeleton loading={state.isLoadingParentRecordAssociations}>
                    <RecordCard
                        openTitleLinkInNewTab
                        onListActionSuccess={updateAddress}
                        showActionMenu={!state.contact}
                        showItemActionMenu
                        hideIcon
                        record={state.parentRecord}
                        associatedRecordModuleName="CrmModule"
                        associatedRecordEntityName="Contact"
                        visibleProperties={['FirstName', 'LastName', 'EmailAddress', 'Phone']}
                    />
                </Skeleton>
            </Col>

            <Col xs={24} lg={8} style={{ padding: 5 }}>
                <Skeleton loading={state.isLoadingParentRecordAssociations}>
                    <RecordCard
                        openTitleLinkInNewTab
                        showActionMenu={!state.address}
                        showItemActionMenu
                        hideIcon
                        record={state.parentRecord}
                        associatedRecordModuleName="CrmModule"
                        associatedRecordEntityName="Address"
                        visibleProperties={['PostalCode', 'Region', 'SalesStatus', 'Type']}
                    />
                </Skeleton>
            </Col>

        </Row>
    );
};

export default InvoiceDetails;
