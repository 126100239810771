import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultDepartment: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface IDepartment {
  name: string;
  id: string;
  disabled: boolean;
}

const UpdateDepartmentSelect: FC<Props> = (props: Props) => {
  const { entity, defaultDepartment, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedDepartment, setSelectedDepartment] = useState<IDepartment | undefined>();
  const [isLoadingDepartments, setIsLoadingDepartments] = useState<boolean>(false);
  const [allDepartments, setAllDepartments] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (defaultDepartment) {
      setSelectedDepartment({
        name: defaultDepartment?.name,
        id: defaultDepartment?.id,
        disabled: false,
      });
    } else {
      setSelectedDepartment(undefined);
    }
  }, [defaultDepartment, sourceRecord]);

  useEffect(() => {
    loadAllDepartments();
  }, []);

  const loadAllDepartments = async () => {
    setIsLoadingDepartments(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/departments?size=10000`);
      const departments = res.data?.data || [];
      setAllDepartments(departments);
      setIsLoadingDepartments(false);
    } catch (error) {
      setIsLoadingDepartments(false);
    }
  };

  // Update Department when a different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultDepartment?.id) {
      updateEntityWithDepartment(item);
    }
  };

  const updateEntityWithDepartment = async (selectedDepartment: IDepartment) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        departmentId: selectedDepartment.id === 'no-department' ? null : selectedDepartment.id,
      });

      if (selectedDepartment.id === 'no-department') {
        setSelectedDepartment(undefined);
      } else {
        setSelectedDepartment(selectedDepartment);
      }

      setIsUpdating(false);
      onUpdate && onUpdate();
      alertMessage({
        body: `Department updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update department. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderDepartment: ItemRenderer<IDepartment> = (
    department,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (department.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more departments..."
        />
      );
    } else
      return (
        <MenuItem
          active={department.id === selectedDepartment?.id}
          disabled={modifiers.disabled}
          key={department.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={department.name}
        />
      );
  };

  let DEPARTMENTS: IDepartment[] = allDepartments.map((d, index) => ({
    id: d.id,
    name: d.name,
    key: d.id,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    DEPARTMENTS = DEPARTMENTS.filter((r) => {
      return r.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  DEPARTMENTS = DEPARTMENTS.slice(0, 100);

  // Append the Search information if there are more than 100 items
  if (DEPARTMENTS.length === 100) {
    DEPARTMENTS.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Department" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    DEPARTMENTS.unshift({
      id: 'no-department',
      name: '(No Department)',
      disabled: false,
    });
  }

  return (
    <Select<IDepartment>
      items={DEPARTMENTS}
      disabled={isLoadingDepartments || allDepartments.length === 0}
      itemRenderer={renderDepartment}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedDepartment ? 'briefcase' : null}
        alignText="left"
        disabled={isLoadingDepartments || allDepartments.length === 0 || isUpdating}
        loading={isLoadingDepartments}
        text={selectedDepartment?.name || 'Select department'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateDepartmentSelect);
