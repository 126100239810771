import { Button } from '@blueprintjs/core';
import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getFirstRelation,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Divider, Drawer, Modal, Row, Select, Spin, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import { TableReducer } from '../../../../../core/records/components/DynamicTable/store/reducer';
import {
  getRecordByIdRequest,
  IGetRecordById,
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  deleteRecordAssociationById,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociations,
  updateOrCreateRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { openAddProductDrawer } from '../../../../../core/userInterface/store/actions';
import { IOpenAddProductDrawer } from '../../../../../core/userInterface/store/types';
import { canUserUpdateRecord } from '../../../../../shared/permissions/rbacRules';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../../shared/utilities/schemaHelpers';
import { httpGet } from '../../../../../shared/http/requests';
import { splitModuleAndEntityName } from '../../../../../shared/utilities/recordHelpers';

const { Option } = Select;

interface Props {
  record: DbRecordEntityTransform;
  relation: DbRecordAssociationRecordsTransform;
  hidden?: string[];
  userReducer: any;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  pipelinesEnabled?: boolean;
  createAssociations: (params: any, cb: any) => {};
  getAssociations: any;
  getRecordById: any;
  searchRecords: any;
  relatedProductUpdate?: boolean;
  sendConfirmation: any;
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb?: any) => any;
  getSchema: Function;
  onSuccess?: Function;
  buttonType?: 'link' | 'text' | 'primary' | 'default' | 'dashed';
  openAddProduct: Function;
}

const { PRODUCT, WORK_ORDER, OFFER, LEAD } = SchemaModuleEntityTypeEnums;
const { FIELD_SERVICE_MODULE, PRODUCT_MODULE } = SchemaModuleTypeEnums;

interface State {
  addOrderItemToWorkOrder: boolean;
  customerType: string | undefined;
  entityName: string;
  initialBaseProduct: boolean;
  isLoading: boolean;
  isLoadingLists: boolean;
  offerArray: any;
  preselectedAddOnItems: any;
  preselectedItems: any;
  productsList: any;
  selected: any[];
  selectedAddOnProducts: any;
  selectedBaseProductRowKeys: any;
  selectedBaseProducts: any;
  selectedContractType: string | undefined;
  selectedOfferId: string | undefined;
  selectedRowKeys: any;
  visible: boolean;
}

const inactiveWorkOrderStages = ['WorkOrderStageDone', 'WorkOrderStageCancelled'];

class OfferProductSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    visible: false,
    selected: [],
    offerArray: [],
    selectedOfferId: undefined,
    productsList: [],
    selectedContractType: undefined,
    preselectedItems: [],
    selectedBaseProductRowKeys: [],
    selectedRowKeys: [],
    selectedBaseProducts: [],
    selectedAddOnProducts: [],
    initialBaseProduct: false,
    isLoading: false,
    customerType: undefined,
    preselectedAddOnItems: [],
    addOrderItemToWorkOrder: false,
    entityName: OFFER,
    isLoadingLists: false,
  });

  private constructProductKey(record: DbRecordEntityTransform) {
    return `${record?.id}#${record?.dbRecordAssociation?.relatedAssociationId}`;
  }

  componentDidMount() {
    this.loadSchemas();
  }

  private openDrawer() {
    this.setState({ visible: true });
    this.loadLists();
  }

  private onOfferProductSuccess = () => {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
  };

  loadSchemas() {
    const { relation, schemaReducer, getSchema } = this.props;

    // Fetch Offer, Product and Work Order schemas if not available in the shortlist
    const offerShortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      OFFER,
    );
    const productShortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRODUCT,
    );
    const workOrderShortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (!offerShortlistSchema) {
      getSchema({ moduleName: PRODUCT_MODULE, entityName: OFFER }, (result: SchemaEntity) => {
        this.getProductAssociations(result);
      });
    } else {
      this.getProductAssociations(offerShortlistSchema);
    }

    if (!productShortlistSchema) getSchema({ moduleName: PRODUCT_MODULE, entityName: PRODUCT });

    if (!workOrderShortlistSchema)
      getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER });

    this.loadLists();

    this.setState({
      preselectedItems: relation?.dbRecords !== undefined ? relation?.dbRecords : [],
    });
  }

  getProductAssociations = (offerSchema: SchemaEntity) => {
    const { relatedProductUpdate, relation, getAssociations } = this.props;

    if (relatedProductUpdate) {
      relation?.dbRecords?.map((elem: any) => {
        this.setState({
          customerType: elem.properties.CustomerType,
        });
        if (elem?.properties.Type === 'ADD_ON_PRODUCT') {
          this.setState((prevState) => ({
            selectedRowKeys: [...prevState.selectedRowKeys, this.constructProductKey(elem)],
            selectedAddOnProducts: [...prevState.selectedAddOnProducts, elem],
          }));
        } else if (elem?.properties.Type === 'BASE_PRODUCT') {
          this.setState({
            initialBaseProduct: true,
          });
          this.setState((prevState) => ({
            selectedBaseProductRowKeys: [
              ...prevState.selectedBaseProductRowKeys,
              this.constructProductKey(elem),
            ],
            selectedBaseProducts: [...prevState.selectedBaseProducts, elem],
          }));
        }
      });
    } else {
      relation?.dbRecords?.map((elem: any) => {
        this.setState({
          customerType: elem.properties.ProductCustomerType,
        });
        elem.key = this.constructProductKey(elem);
        if (
          elem?.properties.ProductType === 'ADD_ON_PRODUCT' ||
          elem?.properties.Type === 'ADD_ON_PRODUCT'
        ) {
          getAssociations(
            {
              recordId: elem.id,
              key: PRODUCT,
              schema: offerSchema,
              entities: [PRODUCT],
            },
            (result: any) => {
              this.setState((prevState) => ({
                selectedRowKeys: [
                  ...prevState.selectedRowKeys,
                  this.constructProductKey(result?.results?.Product?.dbRecords?.[0]),
                ],
                selectedAddOnProducts: [
                  ...prevState.selectedAddOnProducts,
                  result?.results?.Product?.dbRecords?.[0],
                ],
                preselectedAddOnItems: [
                  ...prevState.selectedAddOnProducts,
                  result?.results?.Product?.dbRecords?.[0],
                ],
              }));
            },
          );
        } else if (
          elem?.properties.ProductType === 'BASE_PRODUCT' ||
          elem?.properties.Type === 'BASE_PRODUCT'
        ) {
          this.setState({
            initialBaseProduct: true,
          });

          getAssociations(
            {
              recordId: elem.id,
              key: PRODUCT,
              schema: offerSchema,
              entities: [PRODUCT],
            },
            (result: any) => {
              this.setState((prevState) => ({
                selectedBaseProductRowKeys: [
                  ...prevState.selectedBaseProductRowKeys,
                  this.constructProductKey(result?.results?.Product?.dbRecords?.[0]),
                ],
                selectedBaseProducts: [
                  ...prevState.selectedBaseProducts,
                  result?.results?.Product?.dbRecords?.[0],
                ],
              }));
            },
          );
        }
      });
    }
  };

  loadLists() {
    const { searchRecords, recordReducer, schemaReducer } = this.props;

    this.setState({ isLoadingLists: true });

    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      this.state.entityName,
    );

    if (shortlistSchema) {
      searchRecords(
        {
          schema: shortlistSchema,
          searchQuery: {
            terms: '*',
            sort: [
              {
                createdAt: {
                  order: 'desc',
                },
              },
            ],
            schemas: shortlistSchema?.id,
            pageable: {
              page: 1,
              size: 200,
            },
          },
        },
        (res: any) => {
          this.setState({ isLoadingLists: false });

          if (res.data?.data?.length > 0) {
            this.setState({
              offerArray: res.data?.data?.map((result: DbRecordEntityTransform) => result),
            });
          }
        },
      );
    }
  }

  public async handleOk(addOrderItemsToWorkOrder: boolean) {
    const { record, relation, recordAssociationReducer } = this.props;
    const { schema, schemaAssociation } = relation;
    if (schemaAssociation && record && schema && recordAssociationReducer.shortList) {
      this.setState({
        isLoading: true,
        addOrderItemToWorkOrder: false,
      });

      const associationKey = `${this.state.selectedOfferId}_${PRODUCT}`;
      const associationObj: any = recordAssociationReducer.shortList[associationKey];
      const data = associationObj[PRODUCT]?.dbRecords;

      const body: DbRecordAssociationCreateUpdateDto[] = [];

      for (const product of [
        ...this.state.selectedBaseProducts,
        ...this.state.selectedAddOnProducts,
      ]) {
        const matchingProduct = data.find(
          (elem: DbRecordEntityTransform) =>
            this.constructProductKey(elem) === this.constructProductKey(product),
        );
        if (matchingProduct) {
          body.push({
            entity: `${SchemaModuleTypeEnums.PRODUCT_MODULE}:${SchemaModuleEntityTypeEnums.PRODUCT}`,
            recordId: matchingProduct?.id,
            relatedAssociationId: matchingProduct?.dbRecordAssociation?.relatedAssociationId,
            additionalParams: { offerId: this.state.selectedOfferId },
          });
        }
      }

      if (
        record?.entity === 'CrmModule:Lead' &&
        !record.links?.find((elem: any) => elem.entity === 'ProductModule:Product')
      ) {
        this.handleOfferAssociation(body, addOrderItemsToWorkOrder);
      } else {
        this.finishProductSelection(body, addOrderItemsToWorkOrder);
      }
    }
  }

  finishProductSelection(body: any, addOrderItemsToWorkOrder: boolean) {
    const { createAssociations, record, relation } = this.props;
    const { schemaAssociation } = relation;

    const schema = getSchemaFromShortListBySchemaId(
      this.props.schemaReducer.shortList,
      record?.schemaId,
    );

    createAssociations(
      {
        recordId: record?.id,
        schema,
        schemaAssociation,
        createUpdate: body,
      },
      (res: any) => {
        if (addOrderItemsToWorkOrder && res) {
          // create association between OrderItems and WorkOrder
          this.createAssociationOrderItemWorkOrder(res.result);
        }
        if (
          !['OrderStageActive', 'OrderStageCancelled'].includes(record.stage?.key as string) &&
          record?.entity === 'OrderModule:Order'
        ) {
          this.confirmMailSend();
        }
        this.handleCancel();
        this.onOfferProductSuccess();
      },
    );
  }

  public async handleOfferAssociation(body: any, addOrderItemsToWorkOrder: boolean) {
    const { record, schemaReducer, deleteRecordAssociation, createAssociations } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const schemaAssociation = schema?.associations.find(
      (a: SchemaAssociationEntity) => a.label === 'Lead__Offer',
    );

    const res = await httpGet(
      `${schema?.moduleName}/v1.0/db-associations/${schema?.entityName}/${record?.id}/one-relation?entity=${OFFER}`,
    );

    const associatedOffer = getFirstRelation(res.data, OFFER);
    const existingOfferAssociationId = associatedOffer?.id || undefined;

    if (existingOfferAssociationId) {
      deleteRecordAssociation(
        {
          schema: schema as SchemaEntity,
          schemaAssociation: schemaAssociation as SchemaAssociationEntity,
          dbRecordAssociationId: existingOfferAssociationId as string,
        },
        () => {
          createAssociations(
            {
              recordId: record?.id,
              schema: schema as SchemaEntity,
              schemaAssociation: schemaAssociation as SchemaAssociationEntity,
              createUpdate: [
                {
                  entity: `${SchemaModuleTypeEnums.PRODUCT_MODULE}:${SchemaModuleEntityTypeEnums.OFFER}`,
                  recordId: this.state.selectedOfferId,
                },
              ],
            },
            () => {
              this.finishProductSelection(body, addOrderItemsToWorkOrder);
            },
          );
        },
      );
    } else {
      createAssociations(
        {
          recordId: record?.id,
          schema: schema as SchemaEntity,
          schemaAssociation: schemaAssociation as SchemaAssociationEntity,
          createUpdate: [
            {
              entity: `${SchemaModuleTypeEnums.PRODUCT_MODULE}:${SchemaModuleEntityTypeEnums.OFFER}`,
              recordId: this.state.selectedOfferId,
            },
          ],
        },
        () => {
          this.finishProductSelection(body, addOrderItemsToWorkOrder);
        },
      );
    }
  }

  confirmMailSend() {
    const { sendConfirmation, record } = this.props;
    Modal.confirm({
      title: 'Confirm',
      content: 'Do you want to send new order confirmation to the customer?',
      onOk: () => {
        sendConfirmation(
          `OrderModule/v1.0/orders/${
            record ? record?.id : null
          }/email/SENDGRID_ORDER_CONFIRMATION_V2`,
        );
        Modal.destroyAll();
      },
    });
  }

  // If user chosses create associaiton between OrderItems and WorkOrder
  createAssociationOrderItemWorkOrder(orderItems: any) {
    const { createAssociations, recordAssociationReducer, record, schemaReducer } = this.props;
    const associationKey = `${record?.id}_${WORK_ORDER}`;
    const associationObj: any = recordAssociationReducer.shortList?.[associationKey];
    const workOrderRocords = getAllRelations(associationObj, WORK_ORDER);

    let body: any = [];
    orderItems.forEach((element: any) => {
      body.push({
        entity: `${SchemaModuleTypeEnums.ORDER_MODULE}:${SchemaModuleEntityTypeEnums.ORDER_ITEM}`,
        recordId: element.id,
      });
    });

    const activeWorkOrder = workOrderRocords?.find(
      (elem: any) => !inactiveWorkOrderStages.includes(elem.stage?.key as string),
    );
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );
    const modelAssociation = schema?.associations?.find(
      (elem: any) => elem?.label === 'WorkOrder__OrderItem',
    );

    if (activeWorkOrder) {
      createAssociations(
        {
          recordId: activeWorkOrder.id,
          schema: schema,
          schemaAssociation: modelAssociation,
          createUpdate: body,
        },
        () => {},
      );
    }
  }

  handleCancel = () => {
    const { getRecordById, record, schemaReducer } = this.props;
    this.setState(this.getInitialState());
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    getRecordById({ schema: schema, recordId: record?.id });
  };

  private optionSelected(val: any) {
    const { schemaReducer, getAssociations } = this.props;
    if (
      val !== this.state.selectedOfferId &&
      this.state.selectedOfferId !== undefined &&
      !this.state.preselectedItems
    ) {
      this.setState({
        selectedRowKeys: [],
        selectedBaseProductRowKeys: [],
        selectedBaseProducts: [],
        selectedAddOnProducts: [],
      });
    }
    this.setState({
      selectedOfferId: val,
      productsList: [],
      selectedContractType: undefined,
    });

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      this.state.entityName,
    );
    if (schema) {
      getAssociations({
        recordId: val,
        key: 'Product',
        schema: schema,
        entities: ['Product'],
      });
    }
  }

  private renderOfferOptions() {
    const { schemaReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      this.state.entityName,
    );
    if (schema) {
      if (this.state.offerArray) {
        return this.state.offerArray.map((elem: DbRecordEntityTransform) => (
          <Option key={`${elem?.id}_OfferRecord`} value={elem?.id}>
            {elem?.title}
          </Option>
        ));
      } else {
        return;
      }
    }
  }

  contractTypeSelect(val: any) {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${this.state.selectedOfferId}_Product`;
    const associationObj: any = recordAssociationReducer.shortList?.[associationKey];
    let productsList = associationObj?.Product?.dbRecords?.filter(
      (elem: any) => elem?.properties.ContractType === val,
    );
    productsList.map((elem: any) => (elem.key = this.constructProductKey(elem)));
    this.setState({
      productsList: productsList,
      selectedContractType: val,
    });
  }

  renderContractTypeOptions() {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${this.state.selectedOfferId}_Product`;
    const associationObj: any = recordAssociationReducer.shortList?.[associationKey];

    if (associationObj) {
      return this.getUniqueValues(associationObj?.Product?.dbRecords, 'ContractType').map(
        (elem: any) => (
          // @ts-ignore
          <Option key={elem} value={elem}>
            {elem}
          </Option>
        ),
      );
    } else {
      return;
    }
  }

  getUniqueValues(array: any, key: any) {
    var result = new Set();
    array?.forEach(function (item: any) {
      if (item.properties.hasOwnProperty(key)) {
        result.add(item.properties[key]);
      }
    });
    return Array.from(result);
  }

  renderAddOnProductList() {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${this.state.selectedOfferId}_Product`;
    const associationObj: any = recordAssociationReducer.shortList?.[associationKey];
    const productsList = associationObj?.Product?.dbRecords;
    productsList?.map((elem: any) => (elem.key = this.constructProductKey(elem)));
    const columns = [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Category',
        dataIndex: 'Category',
        key: 'Category',
        render: (text: any, record: any) => <>{record.properties.Category}</>,
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        render: (text: any, record: any) => <>{record.properties.Type}</>,
      },
      {
        title: 'ContractType',
        dataIndex: 'ContractType',
        key: 'ContractType',
        render: (text: any, record: any) => <>{record.properties.ContractType}</>,
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
        render: (text: any, record: any) => <>{record.properties?.UnitPrice}</>,
      },
    ];
    return (
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: React.Key[], selectedRows: DbRecordEntityTransform[]) => {
            this.setState({
              selectedRowKeys: selectedRowKeys,
              selectedAddOnProducts: selectedRows.filter((el: any) => el),
            });
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: this.state.selectedRowKeys,
          getCheckboxProps: (record: DbRecordEntityTransform) => ({
            disabled: !this.state.selectedBaseProductRowKeys.length,
          }),
        }}
        loading={recordAssociationReducer?.isSearching}
        scroll={{ y: 'calc(100vh - 315px)' }}
        style={{ width: '100%' }}
        size="small"
        dataSource={productsList?.filter((elem: any) => elem?.properties.Type === 'ADD_ON_PRODUCT')}
        columns={columns}
      />
    );
  }

  setDisabledAddOnProducts(record: DbRecordEntityTransform) {
    // set disabled AddOn products
    if (this.state.selectedBaseProductRowKeys.length) {
      if (
        this.state.preselectedAddOnItems?.find(
          (elem: DbRecordEntityTransform) =>
            this.constructProductKey(elem) === this.constructProductKey(record),
        )
      ) {
        // disable clicking on preselected item - item that is already associated to the order
        return true;
      } else if (
        this.state.selectedAddOnProducts.find(
          (elem: any) => this.constructProductKey(elem) === this.constructProductKey(record),
        )
      ) {
        // allow uncheck of the item you previously clicked
        // but that item is not preselected
        return false;
      } else {
        if (
          record.properties.Category === 'VOICE' &&
          this.state.selectedBaseProducts?.[0]?.properties.Category === 'VOICE'
        ) {
          // if base product Category is VOICE don't allow the user to select VOICE add on product
          return true;
        } else if (
          record.properties.Category === 'VOICE' &&
          this.state.selectedAddOnProducts.find(
            (elem: any) => elem?.properties.Category === 'VOICE',
          )
        ) {
          // if user already selected VOICE add on don't allow the user to add another one
          return true;
        }
      }
    } else return false;
  }

  renderBaseProductList() {
    const { recordAssociationReducer } = this.props;
    const columns = [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Category',
        dataIndex: 'Category',
        key: 'Category',
        render: (text: any, record: any) => <>{record.properties.Category}</>,
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        render: (text: any, record: any) => <>{record.properties.Type}</>,
      },
      {
        title: 'ContractType',
        dataIndex: 'ContractType',
        key: 'ContractType',
        render: (text: any, record: any) => <>{record.properties.ContractType}</>,
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
        render: (text: any, record: any) => <>{record.properties?.UnitPrice}</>,
      },
    ];
    return (
      <Table
        rowSelection={{
          type: 'radio',
          preserveSelectedRowKeys: true,
          selectedRowKeys: this.state.selectedBaseProductRowKeys,
          onChange: (selectedRowKeys: React.Key[], selectedRows: DbRecordEntityTransform[]) => {
            this.setState({
              selectedBaseProductRowKeys: selectedRowKeys,
              selectedBaseProducts: selectedRows,
            });
          },
          getCheckboxProps: (record: DbRecordEntityTransform) => ({
            disabled: this.baseProductDisabledState(record),
          }),
        }}
        loading={recordAssociationReducer?.isSearching}
        scroll={{ y: 'calc(100vh - 315px)' }}
        style={{ width: '100%' }}
        size="small"
        dataSource={this.state.productsList?.filter(
          (elem: any) => elem?.properties.Type === 'BASE_PRODUCT',
        )}
        columns={columns}
      />
    );
  }

  baseProductDisabledState(record: DbRecordEntityTransform) {
    if (this.state.initialBaseProduct) {
      return true;
    } else {
      return false;
    }
  }

  // Check if there is active WorkOrder associated to the Order.
  // If there is then ask user if he wants to associate OrderItems to the WorkOrder.
  checkWorkOrder() {
    const { getAssociations, record, schemaReducer } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    getAssociations(
      {
        recordId: record?.id,
        key: WORK_ORDER,
        schema: schema,
        entities: [WORK_ORDER],
      },
      (res: any) => {
        // check if there is an active WorkOrder
        // if there is, show modal and allow user to select to add OrderItems to WorkOrder
        // if not just associate OrderItems to the Order
        const relatedRecords = getAllRelations(res.results, WORK_ORDER);
        if (relatedRecords) {
          if (
            relatedRecords.find((elem: DbRecordEntityTransform) =>
              inactiveWorkOrderStages.includes(elem.stage?.key as string),
            )
          ) {
            this.handleOk(false);
          } else {
            this.setState({ addOrderItemToWorkOrder: true });
          }
        } else {
          this.handleOk(false);
        }
      },
    );
  }

  // If User has role ProductModuleAdmin let him choose between Offer and PriceBook entity for Product selection
  renderEntitySelect() {
    const { userReducer } = this.props;
    if (userReducer?.roles?.includes('ProductModuleAdmin')) {
      return (
        <Select
          style={{ width: '100%', marginBottom: '1rem' }}
          placeholder="Select Entity"
          onSelect={(val: any) => this.setState({ entityName: String(val) })}
          onClick={(e) => this.loadLists()}
          value={this.state.entityName || undefined}
        >
          <Option key={'Offer'} value={'Offer'}>
            Offer
          </Option>
          <Option key={'PriceBook'} value={'PriceBook'}>
            Price Book
          </Option>
        </Select>
      );
    } else {
      return false;
    }
  }

  isRecordLead = (record: DbRecordEntityTransform): boolean => {
    const { entityName } = splitModuleAndEntityName(record.entity!);
    return entityName === LEAD;
  };

  render() {
    const { recordReducer, record, userReducer, schemaReducer } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <div>
        {/*Add OrderItem to WorkOrder*/}
        <Modal
          title="Add Order Items to Work Order"
          open={this.state.addOrderItemToWorkOrder}
          onOk={() => this.handleOk(true)}
          onCancel={() => this.handleOk(false)}
          okText="Yes"
          cancelText="No"
        >
          <p>Do you want do add selected Order Items to the Work Order?</p>
        </Modal>

        <Button
          onClick={() =>
            this.isRecordLead(record)
              ? this.setState({ visible: true })
              : this.props.openAddProduct({
                  parentRecord: record,
                })
          }
          minimal
          intent="primary"
          disabled={schema ? !canUserUpdateRecord(userReducer, schema) : false}
        >
          Add Product
        </Button>

        <Drawer
          title={`Add Products`}
          open={this.state.visible}
          onClose={() => this.handleCancel()}
          width={1000}
        >
          <Spin spinning={recordReducer.isRequesting} tip="Saving changes...">
            <Row>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  {this.renderEntitySelect()}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '450px',
                    }}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      loading={this.state.isLoadingLists}
                      disabled={this.state.isLoadingLists}
                      style={{ width: '100%' }}
                      placeholder={
                        this.state.isLoadingLists ? 'Loading...' : `Select ${this.state.entityName}`
                      }
                      onSelect={(val: any) => this.optionSelected(val)}
                      value={this.state.selectedOfferId || undefined}
                    >
                      {this.renderOfferOptions()}
                    </Select>
                    <Select
                      style={{ width: '100%', marginTop: '1rem' }}
                      placeholder="Select Contract Type"
                      onSelect={(val: any) => this.contractTypeSelect(val)}
                      disabled={this.state.selectedOfferId === undefined}
                      value={this.state.selectedContractType || undefined}
                    >
                      {this.renderContractTypeOptions()}
                    </Select>
                  </div>
                  <Divider />
                  {this.renderAddOnProductList()}
                  {this.renderBaseProductList()}
                </div>
                <Divider />
              </div>
            </Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 16,
              }}
            >
              <Button
                intent="primary"
                disabled={!this.state.selectedRowKeys && !this.state.selectedBaseProductRowKeys}
                onClick={(e) => this.checkWorkOrder()}
                loading={this.state.isLoading}
              >
                Save Changes
              </Button>
            </div>
          </Spin>
        </Drawer>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordTableReducer: state.recordTableReducer,
});

const mapDispatch = (dispatch: any) => ({
  openAddProduct: (payload: IOpenAddProductDrawer) => dispatch(openAddProductDrawer(payload)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  createAssociations: (params: any, cb: any) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
});

export default connect(mapState, mapDispatch)(OfferProductSelector);
