import React from 'react';
import {
  DownloadOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  MobileOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  Modal,
  Row,
  Spin,
  Tag,
  Typography,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { Image as ImageLayer } from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import NetomniaIcon from '../../../../assets/icons/netomnia-icon.png';
import {
  MapReducerUpdate,
  MapSearch,
  setMapSearchQuery,
  updateMapState,
} from '../../../../core/gis/store/actions';
import { MapReducer } from '../../../../core/gis/store/reducer';
import {
  getRecordByIdRequest,
  IGetRecordById,
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../core/records/store/actions';
import PDFModalViewer from '../../../../shared/components/PDFModalViewer';
import { getHostName } from '../../../../shared/http/helpers';
import { httpGet } from '../../../../shared/http/requests';
import { displayMessage } from '../../../../shared/system/messages/store/reducers';
import { getUserNameAndEmailAddress } from '../../../../shared/utilities/identityHelpers';
import { renderBuildPackDesignErrorPanel, returnMixedOnHoldErrorMessages } from './BuildPackQC';
import {
  generateBase64ImageFromMap,
  generateBase64ImageFromMapStatic,
  getWFSFeatureDetails,
} from './helpers';
import { cleanMapLayers, getOverviewMapForPolygonType, initializeBuildPackMap } from './mapHelpers';
import BuildPackGraph from './BuildPackGraph';
import { QGIS_SERVER_BUILDPACK } from './types';
import FormData from 'form-data';
import './styles.scss';
import { PDFWorkerData } from './BuildPackPDF/BuildPackWorker';
import { getRecordFromShortListById } from '../../../../shared/utilities/recordHelpers';
import { transform } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import hash from 'object-hash';

export interface IBuildPackData {
  overview: any[];
  sections: any[];
  edges: any[];
  nodes: any[];
  features: {
    cables: any[];
    ducts: any[];
    poles: any[];
    chambers: any[];
    ropes: any[];
    closures: any[];
  };
}

type Props = RouteComponentProps & {
  mapReducer: MapReducer;
  recordReducer: any;
  userReducer: any;
  navigationReducer: any;
  alertMessage: any;
  updateMap: (params: MapReducerUpdate) => {};
  match: any;
  searchRecords: any;
  searchMap: (params: MapSearch) => {};
  withSplicing?: boolean;
};

interface State {
  associatedRecordId: string | undefined;
  buildPackData: any;
  buildPackDataLoading: boolean;
  buildPackRunning: boolean;
  cableMaps: any[];
  chainOverviewMaps: any[];
  diagramRunning: boolean;
  errors: string[];
  exPolygonName: string | undefined;
  featuresInPolygon: any;
  graph: any;
  networkDiagramFinished: boolean;
  overviewMap: string | undefined;
  overviewMapDataLoaded: boolean;
  overviewSLDImage: any[];
  PDFerror: string | null;
  PDFFile: any;
  PDFFileBlob: any;
  PDFFileName: string;
  PDFisComplete: boolean;
  PDFisGenerating: boolean;
  PDFViewerModalIsVisible: boolean;
  piaMaps: any[];
  polygonCoordinates: any[];
  polygonId: number | undefined;
  polygonType: 'L1' | 'L2' | undefined;
  SLDWorkChainImages: any[];
  splicingData: any[];
  aggregatedSplicingData: any[];
  splicingDataError: boolean;
  splicingDataLoading: boolean;
  usingOverviewAsChain: boolean; // we sometimes use overview map as chain map, when there are no work chains
}

class BuildPackV2 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      associatedRecordId: undefined,
      buildPackData: undefined,
      buildPackDataLoading: true,
      buildPackRunning: false,
      cableMaps: [],
      diagramRunning: false,
      errors: [],
      chainOverviewMaps: [],
      exPolygonName: undefined,
      featuresInPolygon: undefined,
      graph: undefined,
      networkDiagramFinished: false,
      overviewMap: '',
      overviewMapDataLoaded: false,
      overviewSLDImage: [],
      PDFerror: null,
      PDFFile: undefined,
      PDFFileBlob: undefined,
      PDFFileName: '',
      PDFisComplete: false,
      PDFisGenerating: false,
      PDFViewerModalIsVisible: false,
      piaMaps: [],
      polygonCoordinates: [],
      polygonId: undefined,
      polygonType: undefined,
      SLDWorkChainImages: [],
      splicingData: [],
      aggregatedSplicingData: [],
      splicingDataError: false,
      splicingDataLoading: false,
      usingOverviewAsChain: false,
    };
  }

  componentDidMount() {
    initializeBuildPackMap(this.props.updateMap);
    this.loadBuildPackData();
  }

  /**
   * Load overview polygon into the map, and zoom the map to the bounding extent.
   */
  async loadOverviewPolygonMap(
    polygonId: any,
    polygonType: any,
    polygonCoordinates: any,
    buildPackData: any,
  ) {
    const { mapReducer } = this.props;

    if (polygonId && polygonCoordinates) {
      mapReducer?.map?.addLayer(
        new ImageLayer({
          zIndex: 1000,
          className: 'buildPack_overviewPolygon',
          source: new ImageWMS({
            url: QGIS_SERVER_BUILDPACK,
            crossOrigin: 'anonymous',
            params: {
              LAYERS: ['polygon_no_scale'],
              FILTER: `polygon_no_scale:"id" = '${polygonId}'`,
            },
            ratio: 1,
            serverType: 'qgis',
          }),
          visible: true,
          opacity: 0.8,
        }),
      );

      // Convert coordinates and zoom map into the polygon.
      const convertedCoordinates = polygonCoordinates?.map((coordinate: any) => {
        return transform(coordinate, 'EPSG:27700', 'EPSG:3857');
      });
      mapReducer?.map?.getView().fit(boundingExtent(convertedCoordinates), {
        maxZoom: 18.8,
        padding: [20, 20, 20, 20],
      });

      // Load appropriate overview features for the polygon type.
      if (polygonType && buildPackData) {
        getOverviewMapForPolygonType(mapReducer.map, polygonType, buildPackData).then(() => {
          this.setState({ overviewMapDataLoaded: true });
        });
      }
    }
  }

  async loadAllSplicingData(allClosureIds: number[]) {
    console.log('%cLoading all splicing data', 'color:yellow');
    this.setState({ splicingDataLoading: true });
    await Promise.allSettled([
      this.loadSplicingData(allClosureIds),
      this.loadAggregatedSplicingData(allClosureIds),
    ]);
  }

  async loadSplicingData(closureIds: any[]) {
    await httpGet(`ConnectModule/connections/connections/batch/${closureIds.join(',')}`)
      .then((res) => {
        if (res.data?.data?.length > 0) {
          this.setState({
            splicingData: res.data.data,
          });
          console.log('%cSplicing data loaded.', 'color:limegreen', res.data.data);
        } else {
          this.setState({
            splicingData: [],
            splicingDataError: true,
            splicingDataLoading: false,
          });
          console.log('%cSplicing data error.', 'color:red');
        }
      })
      .catch((e: any) =>
        this.setState({
          splicingDataError: true,
          splicingDataLoading: false,
        }),
      );
  }

  async loadAggregatedSplicingData(closureIds: any[]) {
    /* Load aggregated splicing data for an array of closures */
    await httpGet(`ConnectModule/connections/connections-agg/batch/${closureIds.join(',')}`)
      .then((res) => {
        if (res.data?.data?.length > 0) {
          console.log('%cAggregated splicing data loaded.', 'color:limegreen', res.data.data);
          this.setState({
            aggregatedSplicingData: res.data.data,
            splicingDataLoading: false,
            splicingDataError: false,
          });
        } else {
          this.setState({
            aggregatedSplicingData: [],
            splicingDataError: true,
            splicingDataLoading: false,
          });
          console.log('%cAggregated splicing data error.', 'color:red');
        }
      })
      .catch((e: any) =>
        this.setState({
          aggregatedSplicingData: [],
          splicingDataError: true,
          splicingDataLoading: false,
        }),
      );
  }

  async loadBuildPackData() {
    const { match, updateMap } = this.props;

    if (match.params.recordId) {
      await httpGet(`ProjectModule/v1.0/Buildpack/${match.params.recordId}`)
        .then((res: any) => {
          const polygon = res.data.data.polygon;

          // Filter data so that we remove on-hold nodes and edges with on-hold cables.
          // const filteredData = removeOnHoldNodesAndEdges(res.data.data.edges, res.data.data.nodes);
          const filteredData = res.data.data;

          let filteredResponse = {
            overview: res.data.data.overview,
            sections: res.data.data.sections,
            edges: res.data.data.edges,
            nodes: res.data.data.nodes,
            features: res.data.data.features,
          };

          // Console out data stats
          console.log('%cAPI Data BEFORE on-hold filtering', 'color:orange', res.data.data);
          console.log('%cAPI Data AFTER on-hold filtering', 'color:lime', filteredResponse);

          // 18-Jul-2023
          // If we see a Polygon without work chains, we should still generate a Build Pack
          // with at least one work chain, an overview one.
          if (filteredResponse?.sections?.length === 0 && filteredResponse?.overview?.length > 0) {
            filteredResponse.sections = filteredResponse.overview;
            this.setState({ usingOverviewAsChain: true });
            console.log(
              '%cPolygon without sections, using Overview as chain',
              'color:orange',
              filteredResponse,
            );
          }

          // If on-hold filtering returns no sections, throw an error because all features are on hold.
          if (filteredResponse?.sections?.length === 0) {
            this.setState({
              errors: [...this.state.errors, 'All features are on-hold, nothing to generate.'],
              buildPackDataLoading: false,
            });
          }

          // Set the state and map.
          this.setState({
            buildPackData: filteredResponse,
            buildPackDataLoading: false,
            polygonId: polygon?.id,
            polygonType: polygon?.name,
            exPolygonName: polygon?.ex_name,
            polygonCoordinates: polygon?.coordinates[0],
            associatedRecordId: match.params.recordId,
          });

          updateMap({
            query: `type=polygon&featureId=${polygon.id}`,
            queryLayer: 'polygon',
          });

          this.loadOverviewPolygonMap(
            polygon?.id,
            polygon?.name,
            polygon?.coordinates[0],
            filteredResponse,
          );

          // Return mixed on-hold cable/closure connections and push to error stack.
          this.setState({
            errors: [...this.state.errors, ...returnMixedOnHoldErrorMessages(res.data.data.edges)],
            buildPackDataLoading: false,
          });

          // Load splicing data
          if (this.props.withSplicing) {
            const splicingClosureIds = filteredData?.nodes?.map((node: any) => node.id);
            this.loadAllSplicingData(splicingClosureIds);
          }
        })

        .catch((err: any) => {
          console.log(
            '%cThere was an error loading Build pack data.',
            'color:red',
            JSON.stringify(err),
          );
          this.setState({
            errors: [
              ...this.state.errors,
              `There was an error loading Build pack data. ${
                err.response?.data?.message ? err.response?.data?.message : err
              }`,
            ],
            buildPackDataLoading: false,
          });
        });
    } else {
      this.setState({
        errors: ['Project record id missing in the URL.'],
        buildPackDataLoading: false,
      });
    }
  }

  generateAndExportWorkChainMaps = async () => {
    const { buildPackData, polygonType } = this.state;
    const { mapReducer } = this.props;
    const { map } = mapReducer;

    let cableMaps: any[] = [];
    let piaMaps: any[] = [];
    let chainOverviewMaps: any[] = [];

    // Generate overview map for each chain, with the polygon in the view
    // leave only polygon on the map
    cleanMapLayers(map, ['buildPack_overviewFeatures']);

    for (const section of buildPackData.sections) {
      // delete work chain overview before drawing a new one
      cleanMapLayers(map, ['buildPack_workChainOverview']);

      let allCableIds: Array<number> = [];
      let allClosureIds: Array<number> = [];

      const parentNode = section.parentNode;
      const childNodes = section.childNodes;

      /* TODO: Get all Closure and cable IDs for this chain */
      allClosureIds.push(parentNode.id);
      childNodes.forEach((child: any) => {
        allClosureIds.push(child.id);
        buildPackData.edges
          .filter((edge: any) => edge.source === child.id || edge.target === child.id)
          .map((edge: any) => {
            allCableIds.push(edge.id);
          });
      });

      let WMSFilterQuery = '';
      WMSFilterQuery += `cable_no_scale:"id" IN ( ${allCableIds.join(' , ')} )`;
      WMSFilterQuery += `;closure_no_scale:"id" IN ( ${allClosureIds.join(' , ')} )`;

      if (allCableIds?.length > 0) {
        map?.addLayer(
          new ImageLayer({
            zIndex: 2000,
            className: 'buildPack_workChainOverview',
            source: new ImageWMS({
              url: QGIS_SERVER_BUILDPACK,
              crossOrigin: 'anonymous',
              params: {
                LAYERS: ['cable_no_scale', 'closure_no_scale'],
                FILTER: WMSFilterQuery,
              },
              ratio: 1,
              serverType: 'qgis',
            }),
            visible: true,
          }),
        );

        await generateBase64ImageFromMap(map).then((image: any) => {
          chainOverviewMaps.push(image);
        });
      }
    }

    if (buildPackData) {
      const edges = buildPackData.edges;

      cleanMapLayers(map, ['buildPack_overviewPolygon', 'buildPack_workChainOverview']);

      /* TODO: Create maps for each cable chain */
      for (let j = 0; j < buildPackData.sections?.length; j++) {
        cleanMapLayers(map, ['buildPack_workChain']);

        let allCableIds: Array<number> = [];
        let allClosureIds: Array<number> = [];

        const parentNode = buildPackData.sections[j].parentNode;
        const childNodes = buildPackData.sections[j].childNodes;

        /* TODO: Get all Closure and cable IDs for this chain */
        allClosureIds.push(parentNode.id);
        childNodes.forEach((child: any) => {
          allClosureIds.push(child.id);
          edges
            .filter((edge: any) => edge.source === child.id || edge.target === child.id)
            .map((edge: any) => {
              allCableIds.push(edge.id);
            });
        });

        /* Get the bounding box zoom into it */
        let allFeatureIds: Array<string> = [];
        allCableIds.forEach((cableId: any) => allFeatureIds.push(`cable.${cableId}`));
        allClosureIds.forEach((closureId: any) => allFeatureIds.push(`closure.${closureId}`));
        const res = await getWFSFeatureDetails(['cable', 'closure'], allFeatureIds);

        /* Get Bounding box and zoom to it*/
        if (res && res.features.length) {
          let coordinates = [],
            convertedCoordinates = [];

          for (let feature of res.features) {
            if (feature.geometry.coordinates.length) {
              coordinates.push(feature.geometry.coordinates);
            }
          }

          for (let coordinate of coordinates) {
            convertedCoordinates.push(transform(coordinate, 'EPSG:4326', 'EPSG:3857'));
          }

          map?.getView().fit(boundingExtent(convertedCoordinates), {
            maxZoom: 18.8,
            padding: [120, 120, 120, 120],
          });
        }

        // Create map
        const hasCables = allCableIds?.length > 0;
        const hasClosures = allClosureIds?.length > 0;

        let WMSFilterQuery = hasCables || hasClosures ? '' : null;
        let WMSLayers = ['polygon_no_scale'];

        if (hasClosures) {
          WMSFilterQuery = `closure_no_scale:"id" IN ( ${allClosureIds.join(' , ')} )`;
          WMSLayers.push('closure_no_scale');
        }

        if (hasCables) {
          WMSFilterQuery += `;cable_no_scale:"id" IN ( ${allCableIds.join(' , ')} )`;
          WMSLayers.push('cable_no_scale');
        }

        WMSFilterQuery += `;polygon_no_scale:"name" = '${polygonType === 'L1' ? 'L2' : 'L4'}'`;

        if (WMSFilterQuery) {
          map?.addLayer(
            new ImageLayer({
              zIndex: 2000,
              className: 'buildPack_workChain',
              source: new ImageWMS({
                url: QGIS_SERVER_BUILDPACK,
                crossOrigin: 'anonymous',
                params: {
                  LAYERS: WMSLayers,
                  FILTER: WMSFilterQuery,
                },
                ratio: 1,
                serverType: 'qgis',
              }),
              visible: true,
            }),
          );
        }

        await generateBase64ImageFromMap(map)
          .then((image) => {
            cableMaps.push(image);
          })
          .then(async () => {
            map?.addLayer(
              new ImageLayer({
                zIndex: 100,
                className: 'buildPack_workChain',
                source: new ImageWMS({
                  url: QGIS_SERVER_BUILDPACK,
                  crossOrigin: 'anonymous',
                  params: {
                    LAYERS: ['L1_ov_pia_structure', 'L1_ov_pia_duct'],
                  },
                  ratio: 1,
                  serverType: 'qgis',
                }),
                visible: true,
              }),
            );

            await generateBase64ImageFromMap(map)
              .then((image) => {
                piaMaps.push(image);
              })
              .then(() => {
                cleanMapLayers(map, ['buildPack_workChain']);
              });
          });
      } // for loop end
    }

    this.setState({
      cableMaps: cableMaps,
      piaMaps: piaMaps,
      chainOverviewMaps: chainOverviewMaps,
    });
  };

  startBuildPack() {
    const { mapReducer } = this.props;
    const { map } = mapReducer;

    /* 1. Set the build pack running and export the overview map */
    this.setState(
      {
        buildPackRunning: true,
        overviewMap: generateBase64ImageFromMapStatic(map),
      },
      () => {
        /* 2. Export construction, cable access and cable feed maps */
        this.generateAndExportWorkChainMaps().then(() => {
          /* 3. Export Cable diagram overview and individual diagrams */
          this.generateAndExportCableDiagrams().then(() => {});
        });
      },
    );
  }

  handleNetworkDiagramBuild = () => {
    this.setState(
      {
        networkDiagramFinished: true,
        buildPackRunning: false,
      },
      () => {
        this.renderPDF();
      },
    );
  };

  generateAndExportCableDiagrams = async () => {
    this.setState({ diagramRunning: true }, () => {
      console.log('%cDiagram running...', 'color:limegreen');
    });
  };

  /* Very dirty method to force browser into downloading PDF blob. */
  downloadPDFFile = () => {
    if (this.state.PDFFileBlob && this.state.PDFFileName.length) {
      //let file = new Blob([ this.state.PDFFileBlob ], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(this.state.PDFFileBlob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = this.state.PDFFileName;
      link.click();
    }
  };

  renderPDF = async () => {
    const { userReducer } = this.props;

    const pdfWorker = new Worker(new URL('./BuildPackPDF/BuildPackWorker.ts', import.meta.url), {
      type: 'module',
    });

    const userInformation = getUserNameAndEmailAddress(userReducer);

    const pdfData: PDFWorkerData = {
      chainOverviewMaps: this.state.chainOverviewMaps!,
      overviewMap: this.state.overviewMap!,
      overviewSLDImage: this.state.overviewSLDImage!,
      cableMaps: this.state.cableMaps!,
      piaMaps: this.state.piaMaps!,
      SLDWorkChainImages: this.state.SLDWorkChainImages!,
      features: this.state.buildPackData?.features!,
      buildPackData: this.state.buildPackData!,
      buildPackDataChains: this.state.buildPackData?.sections!,
      documentInformation: {
        Author: `${userInformation.firstName} ${userInformation.lastName}`,
        Contact: userInformation.emailAddress,
        Date: moment().format('DD/MM/YYYY'),
        Timestamp: moment().format('DD/MM/YYYY HH:mm:ss'),
        PolygonId: this.state.polygonId! ? String(this.state.polygonId) : '-',
        PolygonType: this.state.polygonType! || '-',
        exPolygonName: this.state.exPolygonName! || '-',
      },
      splicingData: this.state.splicingData,
      aggregatedSplicingData: this.state.aggregatedSplicingData,
      withSplicing: this.props.withSplicing ? true : false,
      usingOverviewAsChain: this.state.usingOverviewAsChain,
    };

    this.setState({ PDFisGenerating: true }, () => {
      pdfWorker.postMessage(pdfData);
    });

    pdfWorker.addEventListener('message', async (e: any) => {
      if (e.data?.status === 'success') {
        const fileHash = hash(this.state.buildPackData);
        let filename = `BUILDPACK_${this.state.polygonType}-${this.state.polygonId}`.toUpperCase();
        filename = `${filename}-${fileHash}.pdf`;

        const file = new File([e.data?.file], filename, {
          type: 'application/pdf',
        });

        const { recordReducer } = this.props;
        const record = getRecordFromShortListById(
          recordReducer.shortList,
          this.state.associatedRecordId,
        );

        const formData = new FormData();
        formData.append('file', file);
        formData.append('hashId', fileHash);

        this.setState({
          PDFFile: file,
          PDFFileBlob: e.data?.file,
          PDFFileName: filename,
        });

        const token = localStorage.getItem(`token`);
        pdfWorker.terminate();

        await axios({
          method: 'POST',
          url: `${getHostName()}/SchemaModule/v1.0/s3/files/${
            record?.entity || 'ProjectModule:Project'
          }/${this.state.associatedRecordId!}/upload`,
          data: formData,
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(async (res) => {
            console.log('%cFile uploaded!', 'color:limegreen', res.data.data);
            this.setState({
              PDFisComplete: true,
            });
          })
          .catch((e: any) => {
            this.setState({
              PDFisComplete: true,
            });
          });
      } else {
        console.log('%cPDF Worker reported issues', 'color:red', e.data?.outcome.message);
        this.setState({
          PDFerror: e.data?.outcome.message,
          PDFisComplete: false,
          PDFViewerModalIsVisible: false,
        });
      }
    });
  };

  renderPDFCompleteModal = () => {
    if (this.state.PDFisComplete && !this.state.PDFerror) {
      return (
        <Row>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Alert
              style={{ textAlign: 'justify' }}
              icon={<FileDoneOutlined />}
              message="Your PDF file is created!"
              description={
                <span>
                  You can manage your PDF file by heading back to the Project and looking up in the
                  Files section. If you want to view your PDF file immediately, you can do that from
                  here. Take note that if you access your PDF straight away, it will still be
                  available in the Project record.
                </span>
              }
              type="success"
              showIcon
            />
          </Col>

          <Col span={24} style={{ marginTop: 10 }}>
            <Row>
              <Col xs={24} sm={24} lg={8} style={{ marginBottom: isMobile ? 10 : 0 }}>
                <Link to={`/ProjectModule/Project/${this.state.associatedRecordId}`}>
                  <Button
                    ghost
                    type="primary"
                    style={{
                      width: isMobile ? '100%' : '97%',
                    }}
                    icon={<RollbackOutlined />}
                  >
                    Back to Project
                  </Button>
                </Link>
              </Col>

              <Col
                xs={24}
                sm={24}
                lg={8}
                style={{ textAlign: 'center', marginBottom: isMobile ? 10 : 0 }}
              >
                <Button
                  ghost
                  type="primary"
                  style={{ width: isMobile ? '100%' : '98%' }}
                  icon={<FilePdfOutlined />}
                  onClick={() =>
                    this.setState({
                      PDFViewerModalIsVisible: true,
                    })
                  }
                >
                  View PDF
                </Button>
              </Col>

              <Col xs={24} sm={24} lg={8} style={{ textAlign: 'right' }}>
                <Button
                  ghost
                  type="primary"
                  disabled={!this.state.PDFFileBlob}
                  icon={<DownloadOutlined />}
                  onClick={() => this.downloadPDFFile()}
                  style={{ width: isMobile ? '100%' : '97%' }}
                >
                  Download PDF
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else if (!this.state.PDFisComplete && !this.state.PDFerror) {
      return (
        <Row>
          <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <span>PDF file is being generated...</span>
              <br />
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}
                style={{ marginTop: 30, marginBottom: 30 }}
              />
            </div>
          </Col>
        </Row>
      );
    } else if (!this.state.PDFisComplete && this.state.PDFerror !== null) {
      return (
        <Row>
          <Col span={24}>
            <Alert
              style={{ textAlign: 'justify' }}
              message={
                <span style={{ fontWeight: 600 }}>There was an error generating the PDF File.</span>
              }
              description={
                <pre style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>{this.state.PDFerror}</pre>
              }
              type="error"
              showIcon
            />
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <span>
              We encountered an error while exporting the PDF file. Please copy the error message
              from above, and contact our support team.
            </span>
          </Col>
          <Col span={24} style={{ textAlign: 'right', marginTop: 25 }}>
            <Link to={`/ProjectModule/Project/${this.state.associatedRecordId}`}>
              <Button type="primary" icon={<RollbackOutlined />}>
                Back to Project
              </Button>
            </Link>
          </Col>
        </Row>
      );
    }
  };

  togglePDFModal = () => {
    this.setState({
      PDFViewerModalIsVisible: !this.state.PDFViewerModalIsVisible,
    });
  };

  checkIfCanRun = () => {
    return !(
      !this.state.overviewMapDataLoaded ||
      this.state.buildPackDataLoading ||
      this.state.errors?.length > 0 ||
      this.state.splicingDataError ||
      this.state.PDFerror !== null ||
      (this.props.withSplicing && this.state.splicingDataLoading)
    );
  };

  render() {
    const { match } = this.props;
    const { Content } = Layout;

    return (
      <Layout
        style={{
          padding: isMobile ? 10 : 20,
          overflowY: isMobile ? 'hidden' : 'auto',
        }}
      >
        <Content>
          {/* PDF Viewer Modal */}
          <PDFModalViewer
            togglePDFModal={this.togglePDFModal}
            isModalVisible={this.state.PDFViewerModalIsVisible}
            file={this.state.PDFFile}
          />

          {/* PDF Generator Modal */}
          <Modal
            title="Build pack"
            className="BuildPackModal"
            open={
              (this.state.PDFisGenerating || this.state.PDFisComplete) &&
              !this.state.PDFViewerModalIsVisible
            }
            centered
            closable={false}
          >
            {this.renderPDFCompleteModal()}
          </Modal>

          {/* Map Export Modal */}
          <Modal
            title="Build pack"
            className="BuildPackModal"
            centered
            okText="Close"
            closable={false}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ disabled: !this.state.PDFisComplete }}
            open={this.state.buildPackRunning}
            footer={[
              <Button
                key="dismiss"
                type="primary"
                size="large"
                disabled={!this.state.PDFisComplete}
                onClick={(e) => this.setState({ buildPackRunning: false })}
                ghost
              >
                Close
              </Button>,
            ]}
          >
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <span>Maps and Diagrams are being exported...</span>
                <br />
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}
                  style={{ marginTop: 30, marginBottom: 30 }}
                />
              </Col>
            </Row>
          </Modal>

          <Row>
            {/* LEFT SIDE */}
            <Col xs={24} sm={24} md={24} lg={18} order={isMobile ? 1 : 0}>
              {/* Build pack data loading indicator */}
              {this.state.buildPackDataLoading ? (
                <div className="loaderPanel" style={{ backgroundColor: 'white' }}>
                  <Row style={{ textAlign: 'center', marginTop: 300 }}>
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <Typography.Title style={{ fontWeight: 'normal' }} level={3}>
                        Build pack data is loading...
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 38 }} spin />}
                        style={{ marginTop: 30, marginBottom: 30 }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                <></>
              )}

              {/* Map container */}
              <div
                id="buildpack_map"
                style={{
                  borderRadius: 7,
                  display:
                    this.state.diagramRunning || this.state.errors.length > 0 ? 'none' : 'block',
                }}
              />

              {/* QC Design Error Panel */}
              {this.state.errors.length > 0 ? (
                renderBuildPackDesignErrorPanel(this.state.errors)
              ) : (
                <></>
              )}

              {this.state.diagramRunning ? (
                <BuildPackGraph
                  buildPackData={this.state.buildPackData!}
                  features={this.state.buildPackData.features!}
                  graphContainer={this.state.graph}
                  handleNetworkDiagramBuild={this.handleNetworkDiagramBuild!}
                  SLDWorkChainImages={this.state.SLDWorkChainImages!}
                  overviewSLDImage={this.state.overviewSLDImage!}
                />
              ) : (
                <></>
              )}
            </Col>

            {/* RIGHT SIDE - SIDEBAR */}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              order={isMobile ? 0 : 1}
              style={{ marginBottom: isMobile ? 30 : 0 }}
            >
              <Card
                className="buildPackSidebar"
                title={
                  <Row>
                    <Col span={12} style={{ paddingTop: 6, fontSize: '1.2em' }}>
                      <img
                        src={NetomniaIcon}
                        style={{
                          width: '24px',
                          marginRight: 5,
                          verticalAlign: 'top',
                        }}
                        alt="netomnia-icon"
                      />
                      <span style={{ fontWeight: 600 }}>Build pack</span>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        textAlign: 'right',
                        paddingTop: 8,
                        fontSize: '1.2em',
                      }}
                    >
                      {isMobile ? (
                        <Tag>
                          <MobileOutlined style={{ marginRight: 5 }} />
                          Mobile
                        </Tag>
                      ) : (
                        <></>
                      )}
                      <Tag color="blue">v2.0</Tag>
                    </Col>
                  </Row>
                }
              >
                <Row style={{ textAlign: 'center', padding: 10 }}>
                  <Col span={24}>
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      size="large"
                      loading={this.state.buildPackDataLoading || !this.state.overviewMapDataLoaded}
                      disabled={!this.checkIfCanRun()}
                      onClick={(e) => this.startBuildPack()}
                    >
                      {this.state.buildPackDataLoading || !this.state.overviewMapDataLoaded
                        ? 'Loading data ...'
                        : 'Generate PDF'}
                    </Button>
                  </Col>
                </Row>
                <Row style={{ textAlign: 'center', padding: '0 10px' }}>
                  <Col span={24}>
                    <Descriptions
                      column={1}
                      bordered
                      size="small"
                      style={{
                        textAlign: 'left',
                        marginTop: '10px',
                        fontSize: '0.6em',
                      }}
                      labelStyle={{ fontWeight: 500 }}
                    >
                      {/* Sidebar - Polygon Type  */}
                      <Descriptions.Item label="Polygon Type">
                        {this.state.polygonType ? (
                          this.state.polygonType
                        ) : (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                        )}
                      </Descriptions.Item>

                      {/* Sidebar - Polygon Id */}
                      <Descriptions.Item label="Polygon Id">
                        {this.state.polygonId ? (
                          this.state.polygonId
                        ) : (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                        )}
                      </Descriptions.Item>

                      {/* Sidebar - EX Polygon Name */}
                      <Descriptions.Item label="EX Name">
                        {this.state.exPolygonName ? (
                          this.state.exPolygonName
                        ) : (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                        )}
                      </Descriptions.Item>

                      {/* Sidebar - BP Data */}
                      <Descriptions.Item label="Build Pack Data">
                        {this.state.buildPackData ? (
                          <Tag color="green">LOADED</Tag>
                        ) : (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                        )}
                      </Descriptions.Item>

                      {/* Sidebar - Splicing */}
                      {this.props.withSplicing ? (
                        <Descriptions.Item label="Splicing data">
                          {this.state.splicingDataLoading || this.state.buildPackDataLoading ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                          ) : this.state.splicingDataError ? (
                            <Tag color="red">UNAVAILABLE</Tag>
                          ) : (
                            <Tag color="green">LOADED</Tag>
                          )}
                        </Descriptions.Item>
                      ) : (
                        <></>
                      )}

                      {/* Sidebar - Work Chains */}
                      <Descriptions.Item label="Work Chains">
                        {this.state.buildPackData?.sections ? (
                          this.state.buildPackData?.sections?.length
                        ) : (
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    {this.state.splicingDataError ? (
                      <Alert
                        style={{ marginTop: 20 }}
                        type="error"
                        description="You requested a Build pack with splicing, but we could not find any splicing information for this project. Please use the Autosplicing module to complete the splicing and then try to generate Build pack again."
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  recordReducer: state.recordReducer,
  navigationReducer: state.navigationReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  searchMap: (params: MapSearch) => dispatch(setMapSearchQuery(params)),
});

export default withRouter(connect(mapState, mapDispatch)(BuildPackV2));
