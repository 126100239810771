import { useEffect, useRef, useState } from 'react';
import { H5, Spinner } from '@blueprintjs/core';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import dayjs from 'dayjs';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { httpGet, httpPost } from '../../../../shared/http/requests';
import PersonPlus from '../Icons/PersonPlus';
import InformationIcon from '../Icons/InformationIcon';
import SendMessageIcon from '../Icons/SendMessageIcon';
import ThumbsUpIcon from '../Icons/ThumbsUpIcon';

const isCustomerEmail = (email: DbRecordEntityTransform) => {
  return email?.properties?.SenderType !== 'AGENT';
};

type EmailFeedProps = {
  parentEmailId: string;
  messagesLoading: boolean;
  userReducer: any;
};

export const EmailFeed = ({ parentEmailId, messagesLoading, userReducer }: EmailFeedProps) => {
  // const parentEmailId = '2eb64e8b-5fbe-4169-b15a-e1c9050a43a2';
  const [isLoading, setIsLoading] = useState(false);
  const [emailThread, setEmailThread] = useState<DbRecordEntityTransform[]>([]);
  const [message, setMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const clientEmail = emailThread.find(e => e.id === parentEmailId);

  // TODO: Check
  const setOpenParticipantDrawer = (_val: boolean) => undefined;
  const handleSendThumbsUp = () => undefined;

  const variants: Variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  // Email service functions
  const fetchEmailThread = async (parentEmailId: string): Promise<DbRecordEntityTransform[]> => {
    const response = await httpGet<DbRecordEntityTransform[]>(
      `NotificationModule/v1.0/email/${parentEmailId}/threads`,
    );
    const results = response.data?.data ?? [];

    results.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
      const bCreated = b.createdAt ? new Date(b.createdAt).getTime() : Date.now();
      const aCreated = a.createdAt ? new Date(a.createdAt).getTime() : Date.now();
      return aCreated - bCreated;
    });

    return results;
  };

  const sendEmail = async (
    textMessage: string,
  ): Promise<void> => {
    if (clientEmail) {
      // Reply to e-mail
      await httpPost('NotificationModule/v1.0/email/send', {
        parentEmailId,
        textMessage,
        // htmlMessage,
      });
    } else {
      // Send e-mail
      console.log('Should send e-mail');
    }
  };

  // Handlers to update states and use services
  const sendEmailHandler = async (
    textMessage: string,
  ) => {
    setIsSendingMessage(true);
    if (textareaRef.current)
      textareaRef.current.value = '';

    try {
      await sendEmail(textMessage);
    } catch (err) {
      setIsSendingMessage(false);
    }
    // Optimistic UI update
    const lastMessage = {
      id: '',
      properties: {
        To: clientEmail?.properties.from,
        From: `${userReducer.user.firstname} ${userReducer.user.lastname} <${userReducer.user.email}>`,
        Subject: clientEmail?.properties.Subject,
        Message: message,
        FormattedMessage: message,
        SenderType: 'AGENT',
      },
      createdAt: new Date().toISOString(),
    } as unknown as DbRecordEntityTransform;

    setEmailThread([...emailThread, lastMessage]);

    setIsSendingMessage(false);
    setMessage('');
  };

  // Effect to initially fetch e-mails
  useEffect(() => {
    let mounted = true;
    if (!parentEmailId) return;
    setEmailThread([]);

    fetchEmailThread(parentEmailId)
      .then((thread) => {
        if (!mounted) return;
        setEmailThread(thread);
        setIsLoading(false);
      })
      .catch(() => {
        setEmailThread([]);
        setIsLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [parentEmailId]);

  // Effect with interval to update e-mail list in background
  useEffect(() => {
    if (!parentEmailId) return;
    let interval: NodeJS.Timeout | null = setInterval(() => {
      fetchEmailThread(parentEmailId).then((thread) => setEmailThread(thread));
    }, 60000);

    return () => {
      if (!interval) return;
      clearInterval(interval);
      interval = null;
    };
  }, [parentEmailId]);

  useEffect(() => {
    if (emailThread.length) {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }
  }, [emailThread.length, messagesContainerRef.current]);


  return (
    <div className="chat-panel">
      <H5>Email</H5>
      {isLoading || messagesLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : emailThread.length ? (
        <>
          <div className="chat-top-bar-container">
            <div className="chat-top-bar-items-container">
              <div className="chat-top-bar-left-side">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
                </svg>
                <span style={{ fontWeight: 500 }}>
                  {emailThread.at(-1)?.properties?.Subject || '-'}
                </span>
              </div>
              <div className="chat-top-bar-right-side">
                <span className="chat-button mr-20" onClick={() => setOpenParticipantDrawer(true)}>
                  <PersonPlus />
                </span>
                <span className="chat-button">
                  <InformationIcon />
                </span>
              </div>
            </div>
          </div>
          <div className="chat-container" ref={messagesContainerRef}>
            <AnimatePresence>
              {emailThread.map((email, index) => (
                <motion.div
                  key={index}
                  className="chat-messages-container"
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  transition={{
                    duration: 0.3,
                    delay: 0.2,
                  }}
                >
                  <div
                    className="chat-messages-card"
                    style={{
                      flex: 1,
                      flexDirection: isCustomerEmail(email)
                        ? 'row-reverse'
                        : 'row',
                    }}
                  >
                    <span className="chat-messages-card-avatar-wrapper">
                      <img
                        src={`https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png`}
                        alt="avatar"
                      />
                    </span>
                    <div
                      className="chat-messages-content"
                      style={{
                        backgroundColor: isCustomerEmail(email)
                          ? 'hsl(193.62deg 100% 31.96%)'
                          : '#C5CBD3',
                        color: isCustomerEmail(email) ? '#fff' : 'inherit',
                        flex: 0.7,
                      }}
                    >
                      <div className="chat-messages-content-header">
                        <p className="chat-messages-content-from">{email.properties.From}</p>
                        <p className="chat-messages-content-at">
                          {dayjs(email.createdAt).format('DD/MM/YYYY')}
                        </p>
                      </div>
                      <p
                        className="chat-messages-content-body"
                        style={{
                          color: isCustomerEmail(email)
                            ? 'rgba(255, 255, 255, 0.78)'
                            : 'inherit',
                          maxWidth: "100%",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {email.properties.Message ?? email.properties.FormattedMessage}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          <div className="chat-bottom-bar">
            <div className="chat-bottom-bar-input-wrapper">
              <motion.div
                key="input"
                layout
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1 }}
                transition={{
                  opacity: { duration: 0.05 },
                  layout: {
                    type: 'spring',
                    bounce: 0.15,
                  },
                }}
                style={{ width: '100%', position: 'relative' }}
              >
                <textarea
                  ref={textareaRef}
                  disabled={isLoading}
                  placeholder="Aa"
                  readOnly={isLoading}
                  className="chat-bottom-bar-input-field"
                  onChange={(e) => {
                    setMessage(e.currentTarget.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.ctrlKey) {
                      e.preventDefault();
                      sendEmailHandler(e.currentTarget.value);
                      e.currentTarget.value = '';
                    }
                  }}
                />
              </motion.div>
            </div>
            {message.trim() && !isSendingMessage ? (
              <button
                type="button"
                className="chat-button"
                onClick={() => sendEmailHandler(message)}
              >
                <SendMessageIcon />
              </button>
            ) : !isSendingMessage ? (
              <button type="button" className="chat-button" onClick={handleSendThumbsUp}>
                <ThumbsUpIcon />
              </button>
            ) : (
              <Spinner size={10} style={{ marginLeft: '10px' }} />
            )}
          </div>
        </>
      ) : (
        <div style={{ flex: 1 }}>
          <p>Select a case</p>
        </div>
      )}
    </div>
  );
};
