import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../..';
import { MY_CASES_SET_SELECTED_ACCOUNT } from '../../../store/constants';

interface IAccount {
  title: string;
  id: string;
}
interface Props {}

const MyCasesAccountSelect: React.FC<Props> = (props: Props) => {
  const {} = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const items: IAccount[] = state.selectedCaseContact_Accounts.map(
    (a: DbRecordEntityTransform) => ({
      title: a.title || '',
      id: a.id || '',
    }),
  );

  const setSelectedAccount = (account: IAccount) => {
    const selectedAccount = state.selectedCaseContact_Accounts.find(
      (a: DbRecordEntityTransform) => a.id === account.id,
    );

    if (selectedAccount) {
      dispatch({
        type: MY_CASES_SET_SELECTED_ACCOUNT,
        payload: selectedAccount,
      });
    }
  };

  const renderAccount: ItemRenderer<IAccount> = (
    account,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={account.id === state.selectedCaseContact_SelectedAccount?.id}
        disabled={modifiers.disabled}
        key={account.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={`${account.title}`}
      />
    );
  };

  return (
    <Row>
      <Col span={24}>
        <span style={{ opacity: 0.5 }}>Account</span>
      </Col>
      <Col span={24} style={{ marginTop: 5 }}>
        <Select<IAccount>
          items={items}
          fill
          filterable={false}
          itemRenderer={renderAccount}
          noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
          onItemSelect={setSelectedAccount}
        >
          <Button
            fill
            text={state.selectedCaseContact_SelectedAccount?.title}
            rightIcon="double-caret-vertical"
            placeholder="Select an Account"
            ellipsizeText
          />
        </Select>
      </Col>
    </Row>
  );
};
export default MyCasesAccountSelect;
