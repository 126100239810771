import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Alert, Button, Col, Row, Typography } from 'antd';
import { FunctionComponent, SetStateAction, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  closeRecordForm,
  initializeRecordForm,
} from '../../core/records/components/Forms/store/actions';
import { createRecordsRequest, ICreateRecords } from '../../core/records/store/actions';
import CoreForm from '../../core/records/components/Forms/CoreForm';
import CoreLegacyEmbeddedForm from '../../core/records/components/Forms/CoreLegacyEmbeddedForm';

const { Title } = Typography;

interface OwnProps {
  selectedArticleType: any;
  schema: SchemaEntity;
  initializeForm: any;
  closeForm: any;
  createRecord: any;
  setIsEditing: SetStateAction<any>;
  recordFormReducer: any;
  userReducer: any;
}

type Props = OwnProps;

const uuid = uuidv4();

const CaseEditor: FunctionComponent<Props> = (props) => {
  const { schema, selectedArticleType, initializeForm, setIsEditing, closeForm, userReducer } =
    props;

  const [recordCreated, setRecordCreated] = useState<boolean>(false);

  const cancelForm = () => {
    // closeForm()
    setIsEditing(false);
    setRecordCreated(false);
  };

  const formIsSubmitted = () => {
    setRecordCreated(true);
  };

  const getBrowserURL = () => {
    if (window?.location) {
      return window.location.href;
    } else {
      return '-';
    }
  };

  return (
    <Row>
      {recordCreated ? (
        <Alert
          message="Request Submitted"
          description={
            <Row>
              <Col span={24}>
                Thank you for reaching out to us, we will get in contact with you soon.
              </Col>
              <Col span={24} style={{ marginTop: 20 }}>
                <Button onClick={() => cancelForm()}>Go Back</Button>
              </Col>
            </Row>
          }
          type="success"
          showIcon
        />
      ) : (
        <>
          <Col span={24} style={{ marginBottom: 15 }}>
            <Title level={4}>New Request</Title>
          </Col>
          <Col span={24} style={{ marginBottom: 15 }}>
            <Alert
              style={{ borderRadius: 5 }}
              message="If you want to add files to your request, complete this form first and submit your data. Then, attach files in request view dialog."
              type="info"
            />
          </Col>
          <Col span={24}>
            <CoreLegacyEmbeddedForm
              formUUID={uuid}
              formDirection="vertical"
              showFormActions
              hideStageFormField
              onCancelEvent={() => cancelForm()}
              onCloseEvent={() => cancelForm()}
              onSubmitEvent={() => formIsSubmitted()}
              initializeCb={() => {
                initializeForm({
                  formUUID: uuid,
                  showFormModal: false,
                  showInitializing: false,
                  isCreateReq: true,
                  modified: [
                    {
                      schemaId: schema.id,
                      properties: {
                        Origin: 'ODIN_DESKTOP',
                        RecordURL: getBrowserURL(),
                      },
                    },
                    {
                      entity: 'SupportModule:Note',
                      properties: {
                        Body: `${userReducer.user.firstname} ${userReducer.user.lastname} added request from ODIN_DESKTOP application.`,
                      },
                    },
                  ],
                  recordType: selectedArticleType,
                  schema: schema,
                  sections: [{ name: schema?.name, schema: schema, associations: [] }],
                });
              }}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  closeForm: () => dispatch(closeRecordForm()),
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(CaseEditor);
