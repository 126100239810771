import { EyeOutlined, RadiusSettingOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Card, Col, Divider, Row, Spin, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  IMapSetWorkItemQuickView,
  MapReducerUpdate,
  setWorkItemQuickView,
  updateMapState,
} from '../../../../../../../core/gis/store/actions';
import { MapReducer } from '../../../../../../../core/gis/store/reducer';
import { IRecordReducer } from '../../../../../../../core/records/store/reducer';
import { ISchemaReducer } from '../../../../../../../core/schemas/store/reducer';
import { httpGet } from '../../../../../../../shared/http/requests';
import { canUserGetRecord } from '../../../../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../../shared/utilities/schemaHelpers';
import '../styles.scss';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { PROJECT } = SchemaModuleEntityTypeEnums;

interface Props {
  mapReducer: MapReducer;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  updateMap: (params: MapReducerUpdate) => {};
  alertMessage: any;
  userReducer: any;
  visible: boolean;
  setNewQuickView: (payload: IMapSetWorkItemQuickView) => void;
}

interface State {
  collapsed: boolean;
}

type featureSource = 'ODIN' | 'GIS';

class MapSidebarSearchProjectResults extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { collapsed: true };
  }

  renderLastModifiedFields(feature: any, featureSource: featureSource) {
    const renderFields = (updatedBy: string, updatedAt: string) => {
      return (
        <Row style={{ marginBottom: '5px' }}>
          <Col span={24} style={{ marginBottom: '5px' }}>
            <span>
              <strong>Updated by: </strong>
              {updatedBy}
            </span>
          </Col>
          <Col span={24}>
            <span>
              <strong>Updated at: </strong>
              {moment(updatedAt).format('MM/DD/YYYY hh:mm a').toUpperCase()}
            </span>
          </Col>
        </Row>
      );
    };

    return renderFields(feature.lastModifiedBy.fullName, feature.updatedAt);
  }

  async handleViewRecord(feature: any) {
    const { alertMessage, updateMap, mapReducer, setNewQuickView } = this.props;

    updateMap({
      isLoadingView: true,
    });

    const query = `ProjectModule/v1.0/db/Project/${feature.id}`;

    await httpGet(query)
      .then((res) => {
        if (localStorage.getItem('ProjectModuleV2QuickView')) {
          setNewQuickView({
            record: res.data.data,
            visible: true,
          });
        }
        updateMap({
          recordId: res.data.data.id,
          isLoadingView: false,
          entityName: 'Project',
        });
      })
      .catch((err) => {
        updateMap({
          isLoadingView: false,
        });

        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: (error && error.message) || 'error loading feature',
          type: 'error',
        });
      });
  }

  renderGisProjectDetails = (record: any) => {
    return (
      <>
        <Row>
          <Col span={24}>
            <span style={{ fontWeight: 600 }}>External Ref: </span>
            <span>{record.id ? record?.properties?.id : '-'}</span>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <span style={{ fontWeight: 600 }}>Type: </span>
            <span>{record?.properties?.name ? record?.properties?.name : '-'}</span>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <span style={{ fontWeight: 600 }}>Build status: </span>
            <span>
              {record?.properties?.build_status_id ? record?.properties?.build_status_id : '-'}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  renderOdinProjectDetails = (record: DbRecordEntityTransform) => {
    return (
      <>
        <Row>
          <Col span={24}>
            <span style={{ fontWeight: 600 }}>Title: </span>
            <span>{record.title ? record.title : '-'}</span>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <span style={{ fontWeight: 600 }}>External Ref: </span>
            <span>
              {getProperty(record, 'ExternalRef') ? getProperty(record, 'ExternalRef') : '-'}
            </span>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <span style={{ fontWeight: 600 }}>Stage: </span>
            <span>{record.stage?.name ? record?.stage?.name : '-'}</span>
          </Col>
        </Row>
      </>
    );
  };

  renderFeature(feature: any, featureSource: featureSource) {
    const { mapReducer, userReducer, schemaReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      PROJECT,
    );

    if (schema && mapReducer.queryLayer && feature) {
      return (
        <Col span={24} className={`sidebarFeatureSearchResult`} key={feature.id}>
          {/* Feature Header */}
          <Card
            className="sidebarSearchResultCard"
            title={
              <Row>
                <Col span={8}>
                  <span>{featureSource === 'ODIN' ? 'PROJECT' : 'POLYGON'}</span>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  {featureSource === 'ODIN' ? (
                    <Button
                      icon={<EyeOutlined />}
                      loading={mapReducer.isLoadingView}
                      disabled={!canUserGetRecord(userReducer, schema) || mapReducer.isLoadingView}
                      onClick={() =>
                        this.handleViewRecord(feature) || !canUserGetRecord(userReducer, schema)
                      }
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            }
          >
            <div style={{ padding: 5 }}>
              {featureSource === 'ODIN'
                ? this.renderOdinProjectDetails(feature)
                : this.renderGisProjectDetails(feature)}
              <Row>
                <Col span={24}>
                  <Divider style={{ margin: '4px' }} />
                </Col>
                {/* Source: ODIN | GIS */}
                <Col span={24} style={{ marginTop: '5px' }}>
                  <strong style={{ marginRight: '3px' }}>Source: </strong>
                  <Tag color={featureSource === 'GIS' ? 'green' : 'blue'}>{featureSource}</Tag>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      );
    } else {
      return <></>;
    }
  }

  getSearchResults() {
    const { schemaReducer, recordReducer, mapReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      PROJECT,
    );

    let odinExternalRefs: number[] = [];
    let dataSet: any[] = [];

    /* Records are searching */
    if (recordReducer.isSearching) {
      return (
        <Col span={24} style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </Col>
      );
    } else {
      /* Any data coming back from GIS ? */
      if (mapReducer.features && mapReducer.features.length > 0) {
        mapReducer.features.map((feature: any) => {
          dataSet.push({ feature, featureSource: 'GIS' });
        });
      }

      /* Results coming back from Odin */
      if (schema && recordReducer && recordReducer.list[schema.id]) {
        recordReducer.list[schema.id].map((feature: any) => {
          // return this.renderFeature(feature, 'ODIN')
          odinExternalRefs.push(Number(getProperty(feature, 'ExternalRef')));
          dataSet.push({ feature, featureSource: 'ODIN' });
        });
      }

      if (dataSet.length > 0) {
        return dataSet.map((elem) => {
          // if GIS check that we do not have an odin record
          if (elem.featureSource === 'GIS') {
            if (
              elem.feature.properties.id &&
              !odinExternalRefs.includes(elem.feature.properties.id)
            ) {
              return this.renderFeature(elem.feature, elem.featureSource);
            } else if (
              elem.feature.properties.objectid &&
              !odinExternalRefs.includes(elem.feature.properties.objectid)
            ) {
              return this.renderFeature(elem.feature, elem.featureSource);
            }
          } else if (elem.featureSource === 'ODIN') {
            return this.renderFeature(elem.feature, elem.featureSource);
          }
        });
      } else {
        /* Return Feature selection placeholder */
        return (
          <Col span={24} style={{ textAlign: 'center' }}>
            <div style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
              <div style={{ marginTop: '15px' }}>
                <p>
                  <h3 style={{ color: 'rgba(0, 0, 0, 0.25)' }}>No features selected</h3>
                </p>
                <p>
                  Use Feature selection
                  <br />
                  button on map
                </p>
                <p>
                  <RadiusSettingOutlined
                    style={{
                      border: '1px solid',
                      marginTop: '10px',
                      padding: '10px',
                      fontSize: '1.3em',
                      borderRadius: '50px',
                    }}
                  />
                </p>
              </div>
            </div>
          </Col>
        );
      }
    }
  }

  render() {
    return (
      <Row style={{ display: this.props.visible ? 'block' : 'none' }}>
        <div
          style={{
            // height: isMobile ? '55vh' : '60vh',
            // overflowY: 'auto',
            marginTop: '15px',
          }}
        >
          <Row>
            <Col span={24} style={{ paddingRight: '15px' }}>
              <Row>{this.getSearchResults()}</Row>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  mapReducer: state.mapReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  setNewQuickView: (params: IMapSetWorkItemQuickView) => dispatch(setWorkItemQuickView(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
});

export default connect(mapState, mapDispatch)(MapSidebarSearchProjectResults);
