import React from 'react';
import { httpGet } from '../../../../../shared/http/requests';
import { Table, Tag } from 'antd';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

interface Props {
  record: DbRecordEntityTransform;
}

interface State {
  data: any;
  isLoading: boolean;
}

class PaymentScheduleList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchPaymentSummary();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (prevProps.record !== this.props.record) {
      this.fetchPaymentSummary();
    }
  }

  private async fetchPaymentSummary() {
    const { record } = this.props;

    if (record) {
      this.setState({ isLoading: true });

      await httpGet(`OrderModule/v1.0/orders/${record.id}/payments`)
        .then((res) => {
          this.setState({ data: res.data.data, isLoading: false });
        })
        .catch((err) => {
          console.error('Error while fetching Payment summary: ', err);
          this.setState({ isLoading: false });
        });
    }
  }

  getTableColumns() {
    return [
      {
        title: 'Billing Date',
        dataIndex: 'billing_date',
        key: 'billing_date',
      },
      {
        title: 'Order items',
        dataIndex: 'order_items',
        key: 'order_items',
      },
      {
        title: 'Subtotal',
        dataIndex: 'subtotal_price',
        key: 'subtotal_price',
      },
      {
        title: 'Discounts',
        dataIndex: 'total_discounts',
        key: 'total_discounts'
      },
      {
        title: 'Total',
        dataIndex: 'total_price',
        key: 'total_price'
      },
      {
        title: 'Recognized period',
        dataIndex: 'recognized_period',
        key: 'recognized_period'
      },
      {
        title: 'Recognized amount',
        dataIndex: 'recognized_amount',
        key: 'recognized_amount'
      },
      {

        title: 'Deferred period',
        dataIndex: 'deferred_period',
        key: 'deferred_period'
      },
      {
        title: 'Deferred amount',
        dataIndex: 'deferred_amount',
        key: 'deferred_amount'
      }
    ];
  }

  getTableData(data: { [key: string]: any }[]) {
    if (data && data.length > 0) {
      return data.map((month) => {
        return {
          billing_date: month.Date,
          order_items: month.OrderItems.map((orderItem: string) => (
            <Tag>{orderItem}</Tag>
          )),
          total_discounts: month.TotalDiscounts,
          subtotal_price: month.Subtotal,
          total_price: month.TotalPrice,
          recognized_period: month.RecognizedPeriod,
          recognized_amount: month.RecognizedAmount,
          deferred_period: month.DeferredPeriod,
          deferred_amount: month.DeferredAmount
        };
      });
    }
  }

  render() {
    const { data, isLoading } = this.state;

    return (
      <div>
        <div style={{ marginTop: '10px' }}>
          <Table
            size="small"
            loading={isLoading}
            columns={this.getTableColumns()}
            dataSource={this.getTableData(data)}
          />
        </div>
      </div>
    );
  }
}

export default PaymentScheduleList;
