import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  Result,
  Table,
  Tag,
} from 'antd';

import { httpPost } from '../../../../shared/http/requests';
import { useAction } from '../../hooks/useAction';
import { DISPLAY_MESSAGE } from '../../../../shared/system/messages/store/reducers';
import { Link } from 'react-router-dom';
import { capitalCase } from 'change-case';
import { getBuildClosureStyle } from '../../../../com.netomnia/modules/ProjectModule/Autosplicing/helpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

type CreateWorkListProps = {
  projectId: string;
  selectedRowRecords: any[];
  onFeaturesAdded?: (featureIds: string[], workList?: DbRecordEntityTransform) => void;
  disabled?: boolean;
}

export const CreateWorkList = ({
  projectId,
  selectedRowRecords,
  onFeaturesAdded = () => undefined,
  disabled = false
}: CreateWorkListProps) => {
  const displayMessage = useAction((message: { body: string, type: 'error' | 'success' }) => ({
    type: DISPLAY_MESSAGE,
    message,
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [workListData, setWorkListData] = useState<{
    id: string | undefined;
    isSuccess: boolean;
  }>({
    id: undefined,
    isSuccess: false,
  });

  // Reset state when projectId or selectedRowRecords change
  useEffect(() => {
    setIsModalVisible(false);
    setIsModalLoading(false);
    setWorkListData({
      id: undefined,
      isSuccess: false
    });
  }, [
    projectId,
    selectedRowRecords.map(r => r.key).join()
  ]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalLoading(true);
    const featureIds = selectedRowRecords.map((row) => row.key);

    await httpPost(`ProjectModule/v1.0/WorkList`, {
      projectId,
      featureIds,
    })
      .then((res) => {
        setIsModalLoading(false);
        setWorkListData({
          id: res.data.data.id,
          isSuccess: true,
        });
        onFeaturesAdded(featureIds, res.data.data);
      })
      .catch((err) => {
        setIsModalLoading(false);
        displayMessage({ body: err.response?.data?.message ?? err.message, type: 'error' });
        setWorkListData({
          id: undefined,
          isSuccess: false,
        });
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setWorkListData({
      id: undefined,
      isSuccess: false,
    });
  };

  function renderStatusTag(record: any) {
    const StatusTag = ({ text, styleCode }: { text: string; styleCode: number }) => {
      const { fill: color, color: fontColor } = getBuildClosureStyle(styleCode);
      return (
        <Tag color={color} style={{ width: '100%', color: fontColor, textAlign: 'center' }}>
          {text}
        </Tag>
      );
    };

    switch (record.build_status) {
      case '0-Backlog':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={9}
            key={record.build_status}
          />
        );
      case '1-Plan':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={1}
            key={record.build_status}
          />
        );
      case '2-Survey':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={2}
            key={record.build_status}
          />
        );
      case '3-Design':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={3}
            key={record.build_status}
          />
        );
      case '4-Plan Done':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={4}
            key={record.build_status}
          />
        );
      case '5-ToDo':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={5}
            key={record.build_status}
          />
        );
      case '6-In Progress':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={6}
            key={record.build_status}
          />
        );
      case '7-Build Done':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={7}
            key={record.build_status}
          />
        );
      case '8-RFS':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={8}
            key={record.build_status}
          />
        );
      default:
        return <StatusTag text="NA" styleCode={9} key="NA" />;
    }
  }

  function setColumnPosition(key: string) {
    switch (key) {
      case 'build_status':
        return -100;
      case 'feature_type':
        return -99;
      case 'ratecode':
      case 'rate_code':
        return -98;
      case 'id':
        return -97;
      case 'description':
        return -96;
      case 'unit':
        return -95;
      case 'quantity':
        return -94;
      case 'price':
        return -93;
      case 'total':
        return -91;
      case 'out_of_hours':
        return -92;
      default:
        return 0;
    }
  }

  const getTableColumns = (data: any[]) => {
    let columns: any[] = [];
    if (data?.length > 0) {
      const uniqueKeys: string[] = [];
      for (const item of data) {
        for (const key of Object.keys(item)) {
          if (key !== 'key' && !uniqueKeys.includes(key)) {
            uniqueKeys.push(key);
          }
        }
      }
  
      columns = uniqueKeys.map((key) => ({
        title: capitalCase(key),
        dataIndex: key,
        ellipsis: false,
        position: setColumnPosition(key),
        onFilter: (value: string, record: any) => {
          return record[key]?.includes(value);
        },
        render: (text: string, record: any) => {
          if (key === 'has_image') {
            if (record.has_image) {
              return (
                <Tag color={'green'} key={'complete'}>
                  {'has files'.toUpperCase()}
                </Tag>
              );
            } else {
              return (
                <Tag color={'red'} key={'complete'}>
                  {'missing files'.toUpperCase()}
                </Tag>
              );
            }
          } else if (key === 'build_status') {
            return renderStatusTag(record);
          } else if (key === 'WorkListId') {
            return <>{text}</>;
          } else {
            return text ? String(text) : null;
          }
        },
      }));
    }
  
    return columns;
  };
  
  const renderWorkListConfirmTable = (data: any[]) => {
    const columns = getTableColumns(data);

    return (
      <Table
        bordered
        tableLayout={'auto'}
        size="small"
        dataSource={selectedRowRecords}
        rowKey={(record) => `${record.feature_type}_${record.rate_code}_${record.id}`}
        // scroll={{ y: 600 }}
        // @ts-ignore
        columns={columns.sort((A, B) => A.position - B.position)}
      />
    );
  };

  return (
    <>
      <Button
        onClick={showModal}
        style={{ marginRight: '8px' }}
        disabled={disabled}
      >
        Create WorkList ({selectedRowRecords?.length})
      </Button>
      <Modal
        title={`Confirm items for WorkList (${selectedRowRecords?.length})`}
        width={900}
        open={isModalVisible}
        confirmLoading={isModalLoading}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {workListData?.isSuccess ? (
          <Result
            status="success"
            title="Successfully created WorkList!"
            subTitle="click below to view your WorkList"
            extra={[
              <Link
                target="_blank"
                to={`/ProjectModule/WorkList/${workListData?.id}`}
              >
                View WorkList
              </Link>,
            ]}
          />
        ) : (
          renderWorkListConfirmTable(selectedRowRecords)
        )}
      </Modal>
    </>
  )
}