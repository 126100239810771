import React, { FunctionComponent, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Input,
  InputRef,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import { httpGet } from '../../../../shared/http/requests';
import type { FilterValue } from 'antd/lib/table/interface';
import { capitalCase } from 'change-case';
import RecordQuickViewDrawer from '../../../../core/records/components/RecordQuickViewDrawer';
import { getBuildClosureStyle } from '../../../../com.netomnia/modules/ProjectModule/Autosplicing/helpers';
import { DISPLAY_MESSAGE } from '../../../../shared/system/messages/store/reducers';
import { Link } from 'react-router-dom';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { useRecordAssociations } from '../../../../core/records/hooks/useRecordAssociations';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { connect } from 'react-redux';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table/interface';
import { CreateWorkList } from './CreateWorkList';
import { AddToWorkList } from './AddToWorkList';

const { Title } = Typography;

type IWorkItem = {
  key: string;
  id: string;
  WorkListId: string | JSX.Element;
  build_status: string;
  rate_code: string;
  has_data_issue: boolean;
  data_issue: string;
  feature_type: string | undefined;
  description: string;
  out_of_hours: string;
  // worklist_status: string | undefined;
  worklist_number: string | undefined;
  payment_status: null | 'SUBMITTED' | 'PAID';
};

const getBuildStatusName = (buildStatus: number) => {
  const names = [
    '0-Backlog',
    '1-Plan',
    '2-Survey',
    '3-Design',
    '4-Plan Done',
    '5-ToDo',
    '6-In Progress',
    '7-Build Done',
    '8-RFS',
  ];

  return names[buildStatus];
};

const mapRecordProperties = (record: DbRecordEntityTransform): IWorkItem => {
  // return record.properties;
  const workListId = getProperty(record, 'WorkListId') || '';
  const workListIds = workListId.split(',');
  const linkedWorkLists =
    record.links?.filter(
      (link) =>
        link.entity === `${SchemaModuleTypeEnums.PROJECT_MODULE}:WorkList` &&
        workListIds.includes(link.id),
    ) || [];
  const WorkListId = (
    <ul style={{ listStyleType: 'none' }}>
      {linkedWorkLists.map((linkedWorkList) => (
        <li key={linkedWorkList.id}>
          <Link to={`/ProjectModule/WorkList/${linkedWorkList.id}`} target="_blank">
            {linkedWorkList.recordNumber} - {linkedWorkList.title}
          </Link>
        </li>
      ))}
    </ul>
  );

  return {
    key: record.id,
    id: getProperty(record, 'ExternalRef'),
    WorkListId: linkedWorkLists.length ? WorkListId : '',
    build_status: getBuildStatusName(Number(getProperty(record, 'BuildStatus'))),
    has_data_issue:
      !!getProperty(record, 'DataIssueDate') || !!getProperty(record, 'DataIssueDescription'),
    data_issue: getProperty(record, 'DataIssueDescription'),
    rate_code: getProperty(record, 'RateCode_D'),
    feature_type: record.type,
    description: getProperty(record, 'Description'),
    out_of_hours: getProperty(record, 'OutOfHours'),
    // worklist_status: worklist?.stage?.name,
    worklist_number: linkedWorkLists
      .map((linkedWorkList) => linkedWorkList.recordNumber)
      .join(', '),
    // all_fpes_rejected: !some_fpes_approved,
    payment_status: getProperty(record, 'PaymentStatus'),
  };
};
interface OwnProps {
  projectId: string;
  projectRecord: DbRecordEntityTransform;
  displayMessage: (message: { body: string; type: 'success' | 'error' }) => any;
}

type Props = OwnProps;

const WorkList: FunctionComponent<Props> = (props) => {
  const { projectId, projectRecord, displayMessage } = props;
  const [quickView, setQuickView] = useState<{
    id: string | undefined;
    showDrawer: boolean;
  }>({
    id: undefined,
    showDrawer: false,
  });
  const [data, setData] = useState<null | Array<any>>(null);
  const [filteredData, setFilteredData] = useState<null | Array<any>>(null);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowRecords, setSelectedRowRecords] = useState<any[]>([]);
  console.log('[DebugX] rendering...', projectRecord);

  useRecordAssociations(
    projectRecord,
    [SchemaModuleEntityTypeEnums.FEATURE, 'WorkList', 'FeaturePriceEntry'],
    ({ results }: any) => {
      console.log('[DebugX] results', results);
      const features = getAllRelations(results, SchemaModuleEntityTypeEnums.FEATURE) || [];
      const mappedFeatures = features.map(mapRecordProperties);

      setData(mappedFeatures);
      setFilteredData(mappedFeatures);
      setLoadingData(false);
    },
  );

  const featureIdSearchInput = useRef<InputRef>(null);

  const onSelectRow = (record: any, selected: any, selectedRows: any) => {
    console.log('record: ', record, 'selected:', selected, 'selectedRows: ', selectedRows);
    if (filteredData && filteredData?.length > 0) {
      console.log('selectedRowKeys', selectedRowKeys);
      const key = `${record.feature_type}_${record.rate_code}_${record.id}`;
      if (!selectedRowKeys.includes(key)) {
        setSelectedRowRecords([
          ...selectedRowRecords,
          data?.find((record) => `${record.feature_type}_${record.rate_code}_${record.id}` === key),
        ]);
        setSelectedRowKeys([...selectedRowKeys, key]);
      } else {
        console.log('key', key);
        console.log('selectedRowKeys', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys.filter((elem) => elem !== key));
        setSelectedRowRecords(
          selectedRowRecords?.filter(
            (record) => `${record.feature_type}_${record.rate_code}_${record.id}` !== key,
          ),
        );
      }
    }
  };

  const onSelectAllIssues = (selected: boolean, selectedRows: any, changeRows: any) => {
    console.log('selected: ', selected, 'selectedRows:', selectedRows, 'changeRows: ', changeRows);

    if (selected && filteredData && filteredData?.length > 0) {
      const filtered = [
        ...selectedRowRecords,
        ...filteredData.filter((record) => record.has_data_issue),
      ];
      setSelectedRowRecords(filtered);
      setSelectedRowKeys(
        filtered.map((record: any) => `${record.feature_type}_${record.rate_code}_${record.id}`),
      );
    } else {
      setSelectedRowRecords([]);
      setSelectedRowKeys([]);
    }
  };

  const onSelectAllWorkItems = (selected: boolean, selectedRows: any, changeRows: any) => {
    console.log('selected: ', selected, 'selectedRows:', selectedRows, 'changeRows: ', changeRows);

    if (selected && filteredData && filteredData.length > 0) {
      const appliedFilters = Object.entries(filteredInfo)
        .filter(([_key, value]) => value)
        .map(([key]) => key);
      const filteredItems =
        appliedFilters.length === 0
          ? filteredData.slice()
          : (data || []).reduce((acc, obj) => {
              const matchCount = Object.keys(filteredInfo)?.filter(
                (key) => filteredInfo[key],
              )?.length;
              const matches: { [key: string]: number } = {};
              const objKey = `${obj.feature_type}_${obj.rate_code}_${obj.id}`;
              // console.log('-------------------------------------');
              for (const [key, values] of Object.entries(filteredInfo)) {
                if (values?.includes(obj[key])) {
                  matches[objKey] = matches[objKey] > 0 ? matches[objKey] + 1 : 1;
                  if (matches[objKey] === matchCount) {
                    acc.push(obj);
                  }
                }
              }
              // console.log('-------------------------------------');
              return acc;
            }, []);

      const filtered = [
        ...selectedRowRecords,
        ...filteredItems.filter(
          (record: IWorkItem) =>
            !record.has_data_issue &&
            !record.worklist_number &&
            !selectedRowRecords.find((item) => item.key === record.key),
        ),
      ];
      setSelectedRowRecords(filtered);
      setSelectedRowKeys(
        filtered.map((record: any) => `${record.feature_type}_${record.rate_code}_${record.id}`),
      );
    } else {
      setSelectedRowRecords([]);
      setSelectedRowKeys([]);
    }
  };

  const viewRecord = async (featureType: string, featureId: string) => {
    const query = `ProjectModule/v1.0/cst/Feature/${featureType.toUpperCase()}/${featureId}`;

    await httpGet(query)
      .then((res) => {
        setQuickView({
          id: res.data.data.id,
          showDrawer: true,
        });
      })
      .catch((err) => {
        setQuickView({
          id: undefined,
          showDrawer: false,
        });
      });
  };

  const applyFilters = (item: IWorkItem) => {
    const filters = Object.keys(filteredInfo).filter((key) => !!filteredInfo[key]);
    if (filters.length === 0) return true;

    return filters.some((field) => {
      const value = filteredInfo[field];
      return value?.includes((item as any)[field]);
    });
  };

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    if (Object.keys(filters)?.length === 0) {
      clearFilters();
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setFilteredData(data);
  };

  const getFilters = (data: any[], key: string) => {
    if (
      ['ratecode', 'table_name', 'out_of_hours', 'feature_type', 'build_status'].includes(
        key.toLowerCase(),
      )
    ) {
      console.log('[DebugX]', { key });
      const uniqueValues: string[] = [];
      for (const item of data) {
        if (!uniqueValues.includes(item[key])) {
          uniqueValues.push(item[key]);
        }
      }

      if (key.toLocaleLowerCase() === 'payment_status')
        console.log('[DebugX] PaymentStatus', { uniqueValues });
      return uniqueValues?.map((val) => ({
        text: String(val),
        value: String(val),
      }));
    }

    if (key.toLocaleLowerCase() === 'worklistid') {
      return [
        {
          text: 'Empty',
          value: '',
        },
      ];
    }

    if (key.toLocaleLowerCase() === 'payment_status') {
      return [
        {
          text: 'Empty',
          value: '',
        },
        {
          text: 'Submitted',
          value: 'SUBMITTED',
        },
        {
          text: 'Paid',
          value: 'PAID',
        },
      ];
    }
  };

  function renderStatusTag(record: any) {
    const StatusTag = ({ text, styleCode }: { text: string; styleCode: number }) => {
      const { fill: color, color: fontColor } = getBuildClosureStyle(styleCode);
      return (
        <Tag color={color} style={{ width: '100%', color: fontColor, textAlign: 'center' }}>
          {text}
        </Tag>
      );
    };

    switch (record.build_status) {
      case '0-Backlog':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={9}
            key={record.build_status}
          />
        );
      case '1-Plan':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={1}
            key={record.build_status}
          />
        );
      case '2-Survey':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={2}
            key={record.build_status}
          />
        );
      case '3-Design':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={3}
            key={record.build_status}
          />
        );
      case '4-Plan Done':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={4}
            key={record.build_status}
          />
        );
      case '5-ToDo':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={5}
            key={record.build_status}
          />
        );
      case '6-In Progress':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={6}
            key={record.build_status}
          />
        );
      case '7-Build Done':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={7}
            key={record.build_status}
          />
        );
      case '8-RFS':
        return (
          <StatusTag
            text={record.build_status.toUpperCase()}
            styleCode={8}
            key={record.build_status}
          />
        );
      default:
        return <StatusTag text="NA" styleCode={9} key="NA" />;
    }
  }

  function setColumnPosition(key: string) {
    switch (key) {
      case 'build_status':
        return -100;
      case 'feature_type':
        return -99;
      case 'ratecode':
      case 'rate_code':
        return -98;
      case 'id':
      case 'payment_status':
        return -97;
      case 'description':
        return -96;
      case 'unit':
        return -95;
      case 'quantity':
        return -94;
      case 'price':
        return -93;
      case 'total':
        return -91;
      case 'out_of_hours':
        return -92;
      default:
        return 0;
    }
  }

  const getTableColumns = (data: any[]) => {
    let columns: any[] = [];
    if (data?.length > 0) {
      const uniqueKeys: string[] = [];
      for (const item of data) {
        for (const key of Object.keys(item)) {
          if (key !== 'key' && !uniqueKeys.includes(key)) {
            uniqueKeys.push(key);
          }
        }
      }

      const applyFeatureIdFilter = (value: string | null) => {
        const currentFilters = filteredInfo ? filteredInfo : {};
        setFilteredInfo({
          ...currentFilters,
          id: value ? [value] : null,
        });
      };

      const featureIdSearchFilterProps: ColumnType<'id'> = {
        filterDropdown: ({ selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={featureIdSearchInput}
              placeholder={`Search Feature Id`}
              defaultValue={selectedKeys[0]}
              onPressEnter={() => {
                const filter = featureIdSearchInput?.current?.input?.value || null;
                applyFeatureIdFilter(filter);
                if (!filter && clearFilters) clearFilters();
                confirm();
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  const filter = featureIdSearchInput?.current?.input?.value || null;
                  applyFeatureIdFilter(filter);
                  if (!filter && clearFilters) clearFilters();
                  confirm();
                }}
                size="small"
                style={{ width: 90 }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
      };

      const getOnFilter = (key: string) => {
        const onWorkListFilter = (value: string, record: any) => {
          if (value === '') return record.WorkListId === value;
          return true;
        };

        const onPaymentStatusFilter = (value: string, record: any) => {
          if (value === '')
            return (
              record.payment_status === null ||
              record.payment_status === '' ||
              record.payment_status === '-'
            );

          console.log('[DebugX]', { paymentStatus: record.payment_status, value });
          return record.payment_status === value;
        };

        const defaultOnFilter = (value: string, record: any) => {
          return record[key]?.includes(value);
        };

        if (key.toLocaleLowerCase() === 'worklistid') return onWorkListFilter;

        if (key.toLocaleLowerCase() === 'payment_status') return onPaymentStatusFilter;

        return defaultOnFilter;
      };

      columns = uniqueKeys.map((key) => ({
        title: capitalCase(key),
        dataIndex: key,
        ellipsis: false,
        filteredValue: filteredInfo[key] || undefined,
        filters: getFilters(data, key),
        position: setColumnPosition(key),
        onFilter: getOnFilter(key),
        render: (text: string, record: any) => {
          if (key === 'has_image') {
            if (record.has_image) {
              return (
                <Tag color={'green'} key={'complete'}>
                  {'has files'.toUpperCase()}
                </Tag>
              );
            } else {
              return (
                <Tag color={'red'} key={'complete'}>
                  {'missing files'.toUpperCase()}
                </Tag>
              );
            }
          } else if (key === 'build_status') {
            return renderStatusTag(record);
          } else if (key === 'WorkListId') {
            return <>{text}</>;
          } else {
            return text ? String(text) : null;
          }
        },
        ...(key === 'id' ? featureIdSearchFilterProps : {}),
      }));

      columns.push({
        title: 'Action',
        dataIndex: 'actions',
        key: 'x',
        fixed: 'right',
        columnType: 'TEXT',
        ellipsis: true,
        align: 'center',
        render: (text: string, record: any) => (
          <Space size="middle">
            <Button
              type="primary"
              ghost
              style={{
                color: '#1890FF',
              }}
              icon={<EyeOutlined />}
              onClick={() => {
                viewRecord(record.feature_type, record.id);
              }}
            />
          </Space>
        ),
      });
    }

    return columns;
  };

  const renderIssues = (data: Array<any>) => {
    const columns = getTableColumns(data);
    const filtered = filteredData?.filter((elem) => elem.has_data_issue);

    return (
      <div style={{ marginBottom: 16 }}>
        <div className="association-data-table-wrapper">
          <Card
            // className="association-table-card"
            size="small"
            title={`Issues (${filtered?.filter(applyFilters)?.length})`}
            extra={[<Button onClick={clearFilters}>Clear filters</Button>]}
          >
            <Table
              scroll={{ x: 1300 }}
              bordered
              tableLayout={'auto'}
              size="small"
              dataSource={filtered}
              rowKey={(record) => `${record.feature_type}_${record.rate_code}_${record.id}`}
              rowClassName={(record: IWorkItem) =>
                !!record.worklist_number ? 'row-status-disabled' : ''
              }
              rowSelection={{
                type: 'checkbox',
                onSelect: (record: any, selected: any, selectedRows: any) =>
                  onSelectRow(record, selected, selectedRows),
                onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) =>
                  onSelectAllIssues(selected, selectedRows, changeRows),
                selectedRowKeys,
              }}
              columns={columns.sort((A, B) => A.position - B.position)}
              onChange={handleChange}
            />
          </Card>
        </div>
      </div>
    );
  };

  const renderTable = (data: Array<any>) => {
    const columns = getTableColumns(data);
    const filtered = filteredData?.filter((elem) => !elem.has_data_issue);

    return (
      <div style={{ marginBottom: 16 }}>
        <div className="association-data-table-wrapper">
          <Card
            // className="association-table-card"
            size="small"
            title={`Work items (${filtered?.filter(applyFilters)?.length})`}
            extra={[
              <Button style={{ marginRight: 8 }} onClick={clearFilters}>
                Clear filters
              </Button>,
              selectedRowRecords && selectedRowRecords?.length > 0 && (
                <>
                  <AddToWorkList projectId={projectId} selectedRowRecords={selectedRowRecords} />
                  <CreateWorkList selectedRowRecords={selectedRowRecords} projectId={projectId} />
                </>
              ),
            ]}
          >
            <Table
              bordered
              tableLayout={'auto'}
              size="small"
              // pagination={false}
              dataSource={filtered}
              rowKey={(record) => `${record.feature_type}_${record.rate_code}_${record.id}`}
              rowClassName={(record: IWorkItem) =>
                !!record.worklist_number ? 'row-status-disabled' : ''
              }
              rowSelection={{
                type: 'checkbox',
                // onChange: (selectedRowKeys: any) => this.onSelectChange(selectedRowKeys),
                onSelect: (record: any, selected: any, selectedRows: any) =>
                  onSelectRow(record, selected, selectedRows),
                onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) =>
                  onSelectAllWorkItems(selected, selectedRows, changeRows),
                selectedRowKeys,
              }}
              // scroll={{ y: 600 }}
              // @ts-ignore
              columns={columns.sort((A, B) => A.position - B.position)}
              onChange={handleChange}
            />
          </Card>
        </div>
      </div>
    );
  };

  return loadingData ? (
    <Row style={{ textAlign: 'center', margin: 25 }}>
      <Col span={24}>
        <Spin size="large" />
      </Col>
      <Col span={24} style={{ paddingTop: 25 }}>
        <Title level={5} style={{ fontWeight: 400 }}>
          Loading data...
        </Title>
      </Col>
    </Row>
  ) : (
    <>
      <RecordQuickViewDrawer
        recordId={quickView?.id!}
        moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
        entityName={SchemaModuleEntityTypeEnums.FEATURE}
        visible={quickView.showDrawer && !!quickView?.id}
        onClose={() =>
          setQuickView({
            id: undefined,
            showDrawer: false,
          })
        }
      />
      <Row style={{ margin: 10 }} className="worklist">
        <Col span={24}>
          {filteredData && filteredData?.filter((elem) => elem.has_data_issue)?.length > 0 ? (
            renderIssues(filteredData)
          ) : (
            <></>
          )}
        </Col>
        <Col span={24}>{filteredData ? renderTable(filteredData) : <></>}</Col>
      </Row>
    </>
  );
};

const mapState = () => ({});

const mapDispatch = (dispatch: any) => ({
  displayMessage: (message: { body: string; type: 'success' | 'error' }) =>
    dispatch({
      type: DISPLAY_MESSAGE,
      message,
    }),
});

export default connect(mapState, mapDispatch)(WorkList);
