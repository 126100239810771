import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  toggleSchemaActionCreateEditDrawer,
  toggleViewConfigurationCreateEditDrawer,
} from '../../../../../../core/userInterface/store/actions';
import { connect } from 'react-redux';
import { IUserInterfaceReducer } from '../../../../../../core/userInterface/store/types';
import {
  ISchemaActionCreate,
  createSchemaAction,
  getSchemaByIdRequest,
} from '../../../../../../core/schemas/store/actions';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListBySchemaId } from '../../../../../../shared/utilities/schemaHelpers';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { createViewConfiguration, updateViewConfiguration } from '../api';
interface Props {
  schemaId: string | undefined;
  schemaAction?: SchemaActionEntity;
  userInterfaceReducer: IUserInterfaceReducer;
  isCreate: boolean;
  configToUpdate?: SchemaActionEntity | undefined;
  toggleDrawer: () => void;
  createAction: (payload: ISchemaActionCreate, cb?: any) => void;
  onSuccess?: any;
  onCancel?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  schemaReducer: any;
  getSchema: (payload: any, cb: any) => void;
  pipeline: PipelineEntity | undefined;
}

export const ViewConfigurationUpdateModal: FC<Props> = (props) => {
  const [form] = Form.useForm();
  const {
    userInterfaceReducer,
    toggleDrawer,
    isCreate,
    createAction,
    schemaId,
    onSuccess,
    onCancel,
    configToUpdate,
    alertMessage,
    schemaReducer,
    getSchema,
    pipeline,
  } = props;
  const { viewConfigurationCreateEditDrawerVisible } = userInterfaceReducer;

  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    if (schemaId) {
      const shortListSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.schemaShortList,
        schemaId,
      );
      if (shortListSchema) {
        setSchema(shortListSchema);
      } else {
        getSchema({ schemaId }, (res: any) => {
          if (res) {
            setSchema(res);
          }
        });
      }
    }
  }, [schemaId]);

  const onFinish = (values: any) => {
    setIsCreatingOrUpdating(true);

    // Create Request
    if (isCreate && schemaId) {
      createViewConfiguration({
        schemaId: schemaId,
        name: values.configName,
        description: values.configDescription,
        // stageId: values.configStageId,
        schemaTypeId: values.configSchemaTypeId || null,
      }).then((res: any) => {
        if (res) {
          setIsCreatingOrUpdating(false);
          form.resetFields();
          toggleDrawer();
          alertMessage({ body: 'View Configuration Created', type: 'success' });
          if (onSuccess) onSuccess(res.id);
        } else {
          alertMessage({ body: 'View Configuration could not be Created', type: 'error' });
          setIsCreatingOrUpdating(false);
          form.resetFields();
          toggleDrawer();
        }
      });
    } else if (!isCreate && configToUpdate && schemaId) {
      updateViewConfiguration({
        viewConfigId: configToUpdate.id,
        name: values.configName,
        description: values.configDescription,
        // schemaId: configToUpdate.schemaId,
        stageId: values.configStageId,
        schemaTypeId: values.configSchemaTypeId || null,
        definition: configToUpdate.definition as any,
      }).then((res: any) => {
        if (res) {
          alertMessage({ body: 'View Configuration Updated', type: 'success' });
          setIsCreatingOrUpdating(false);
          form.resetFields();
          toggleDrawer();
          if (onSuccess) onSuccess(res.id);
        } else {
          alertMessage({ body: 'View Configuration could not be Updated', type: 'error' });
          setIsCreatingOrUpdating(false);
          form.resetFields();
          toggleDrawer();
        }
      });
    }
  };

  useEffect(() => {
    if (
      userInterfaceReducer.viewConfigurationCreateEditDrawerVisible &&
      !isCreate &&
      configToUpdate
    ) {
      form.setFieldsValue({
        configName: configToUpdate.name,
        configDescription: configToUpdate.description,
        // configStageId: configToUpdate.stageId || '',
        configSchemaTypeId: configToUpdate.schemaTypeId || '',
      });
    }
  }, [userInterfaceReducer.viewConfigurationCreateEditDrawerVisible]);

  const onClose = () => {
    setIsCreatingOrUpdating(false);
    toggleDrawer();
    if (onCancel) onCancel();
    // wait one second and then reset fields
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  return (
    <Modal
      title={isCreate ? 'Create Config' : 'Update Config'}
      onOk={form.submit}
      okButtonProps={{
        loading: isCreatingOrUpdating,
        disabled:
          form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
          isCreatingOrUpdating,
      }}
      open={viewConfigurationCreateEditDrawerVisible}
      onCancel={onClose}
    >
      <Row>
        <Col span={24} style={{ marginTop: 20 }}>
          <Form
            disabled={isCreatingOrUpdating}
            form={form}
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={() => {}}
            autoComplete="off"
          >
            {/* Action Name */}
            <Form.Item
              label="Config Name"
              name="configName"
              rules={[{ required: true, message: 'Please enter Config name' }]}
            >
              <Input />
            </Form.Item>

            {/* Action Description */}
            <Form.Item
              label="Description"
              name="configDescription"
              rules={[{ required: true, message: 'Please enter Description' }]}
            >
              <Input />
            </Form.Item>

            {/* Stage */}
            <Form.Item label="Stage" name="configStageId">
              <Select
                allowClear
                disabled={!pipeline}
                placeholder={
                  pipeline?.stages?.length! > 0 ? 'Select Stage' : 'Schema has no stages'
                }
              >
                {pipeline &&
                  pipeline?.stages?.map((stage: any) => (
                    <Select.Option value={stage.id}>{stage.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>

            {/* Schema Type */}
            <Form.Item label="Schema Type" name="configSchemaTypeId">
              <Select
                allowClear
                disabled={!schema || !schema.types.length}
                placeholder={
                  schema?.types?.length! > 0 ? 'Select Schema Type' : 'Schema has no types'
                }
              >
                {schema?.types.map((type: any) => (
                  <Select.Option value={type.id}>{type.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleViewConfigurationCreateEditDrawer()),
  createAction: (payload: ISchemaActionCreate, cb: any) =>
    dispatch(createSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(ViewConfigurationUpdateModal);
