import { Button, Dialog, DialogBody, DialogFooter, Icon } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { HourlyViewContext } from '..';
import { Col, Row } from 'antd';
import HourlyViewEngineerCard from '../HourlyViewEngineerCard';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { WorkOrderBundle } from '../types';
import HourlyViewUnassignedWorkOrder from '../HourlyViewUnassignedWorkOrder';
import {
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../../../core/records/store/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import {
  ADD_WORK_ORDERS_TO_ENGINEER,
  SET_ASSIGN_MODAL_VISIBLE,
  SET_ASSIGNING_TO_ENGINEER_ID,
  SET_WORK_ORDERS_TO_ASSIGN,
} from '../store/constants';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import HourlyViewAssignedWorkOrder from '../HourlyViewAssignedWorkOrder';
import { httpPut } from '../../../../../../../shared/http/requests';

interface Props {
  schemaReducer: any;
  updateRecord: (params: IUpdateRecordById, cb?: any) => void;
  WOSchema: SchemaEntity | undefined;
  alertMessage: (params: { body: string; type: string }) => void;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

const HourlyViewAssignWorkOrderModal: React.FC<Props> = (props: Props) => {
  const { WOSchema, alertMessage } = props;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { state, dispatch } = useContext(HourlyViewContext);

  const onClose = () => {
    dispatch({ type: SET_ASSIGNING_TO_ENGINEER_ID, payload: undefined });
    dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
    dispatch({ type: SET_ASSIGN_MODAL_VISIBLE, payload: false });
  };

  let fromEngineer: any = undefined;
  let toEngineer: any = undefined;
  let toEngineerCompletionSummary: any = undefined;

  // Find the engineer(s) for the work orders being assigned
  if (state.assignModalVisible && state.workOrdersToAssign.length > 0) {
    // To Engineer
    const matchingToEngineerWOBundle: WorkOrderBundle | undefined = state.allWorkOrders.find(
      (wo: WorkOrderBundle) => wo.engineer?.id === state.assigningToEngineerId,
    );
    if (matchingToEngineerWOBundle) {
      toEngineerCompletionSummary = matchingToEngineerWOBundle?.completionSummary;
      toEngineer = matchingToEngineerWOBundle;
    }

    // From Engineer
    const matchingFromEngineerBundle: WorkOrderBundle | undefined = state.allWorkOrders.find(
      (WOBundle: WorkOrderBundle) =>
        WOBundle.workOrders.some(
          (wo: DbRecordEntityTransform) => wo.id === state.workOrdersToAssign[0].id,
        ),
    );
    if (matchingFromEngineerBundle) {
      fromEngineer = matchingFromEngineerBundle;
    }
  }

  const updateWorkOrder = async () => {
    if (WOSchema && state.workOrdersToAssign.length > 0 && toEngineer) {
      setIsUpdating(true);

      let updatePayload: any[] = [];

      updatePayload = state.workOrdersToAssign.map((WO: DbRecordEntityTransform) => {
        return {
          id: WO.id,
          entity: `${FIELD_SERVICE_MODULE}:${WORK_ORDER}`,
          type: WO.type,
          properties: {
            EngineerId: toEngineer.engineer?.id,
          },
        };
      });

      httpPut(`${WOSchema.moduleName}/v1.0/db/bulk-update`, {
        recordsToUpdate: updatePayload,
      })
        .then(() => {
          // Update changes in the reducer. This will add the WO to engineer's list of WOs,
          // remove from the previous engineer's list of WOs if found there, and/or remove the
          // WO from the list of unassigned WOs if found there.
          dispatch({
            type: ADD_WORK_ORDERS_TO_ENGINEER,
            payload: {
              workOrdersToAssign: state.workOrdersToAssign,
              engineerId: toEngineer?.engineer?.id,
            },
          });

          setIsUpdating(false);
          onClose();

          alertMessage({
            body: 'Work Order(s) assigned successfully!',
            type: 'success',
          });
        })
        .catch((err: any) => {
          alertMessage({
            body: `Failed to assign Work Order(s): ${err?.message}`,
            type: 'error',
          });
          setIsUpdating(false);
        });
    }
  };

  return (
    <Dialog
      title="Assign Work Orders"
      isOpen={state.assignModalVisible}
      canEscapeKeyClose={!isUpdating}
      canOutsideClickClose={!isUpdating}
      onClose={onClose}
      style={{ width: '38%' }}
    >
      <DialogBody>
        <Row align="middle" justify="space-between" style={{ padding: '20px 0' }}>
          <Col span={10}>
            {/* Single WO - Unassigned */}
            {state.workOrdersToAssign.length === 1 && !fromEngineer && (
              <HourlyViewUnassignedWorkOrder shownInModal workOrder={state.workOrdersToAssign[0]} />
            )}

            {/* Single WO - Assigned */}
            {state.workOrdersToAssign.length === 1 && fromEngineer && (
              <HourlyViewAssignedWorkOrder
                shownInModal
                workOrder={state.workOrdersToAssign[0]}
                isHighlighted={false}
              />
            )}

            {/* Multiple WOs */}
            {state.workOrdersToAssign.length > 1 && (
              <Row
                style={{
                  background: 'white',
                  padding: '23px 0 25px 0',
                  border: '1px solid #c4c4c4',
                  borderRadius: 4,
                }}
              >
                <Col span={24} style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 500 }}>
                    {state.workOrdersToAssign.length} Work Orders
                  </span>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <Icon icon="arrow-right" style={{ opacity: 0.8 }} />
          </Col>
          <Col span={12}>
            <Row>
              {toEngineer && (
                <Col
                  span={24}
                  style={{
                    background: 'white',
                    padding: '12px 0 20px 0',
                    border: '1px solid #c4c4c4',
                    borderRadius: 4,
                  }}
                >
                  <HourlyViewEngineerCard
                    engineer={toEngineer?.engineer}
                    percentageCompleted={toEngineerCompletionSummary?.percentageComplete}
                    hideTopBorder
                    hideBottomBorder
                    fullWidth
                    shownInModal
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </DialogBody>
      <DialogFooter
        actions={[
          <Button disabled={isUpdating} text="Cancel" onClick={() => onClose()} key="Cancel" />,
          <Button
            intent="primary"
            onClick={() => updateWorkOrder()}
            text="OK"
            key="OK"
            disabled={isUpdating || !WOSchema}
            loading={isUpdating}
          />,
        ]}
      />
    </Dialog>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(HourlyViewAssignWorkOrderModal);
