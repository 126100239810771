import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { TIME_FRAMES, TScheduleUser } from '../types';
import { CaseManagementSchedulingContext } from '../index';
import {
  CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER,
  CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG,
} from '../store/constants';
import { Col, Row } from 'antd';
import './styles.scss';

interface Props {
  user: TScheduleUser;
}

const SchedulingUserTimeline: React.FC<Props> = (props: Props) => {
  const { user } = props;
  let schedules: any[] = user.schedules || [];
  const { state, dispatch } = useContext(CaseManagementSchedulingContext);

  const TimeSlot = (props: { time: string; schedule: any }) => {
    const { time, schedule } = props;

    // Check how many 30 min blocks the schedule takes by calculating schedule.startTime & schedule.endTime
    const startTime = dayjs(props.schedule.startTime, 'HH:mm');
    const endTime = dayjs(props.schedule.endTime, 'HH:mm');
    const duration = endTime.diff(startTime, 'minute') / 30;

    return (
      <div
        key={user.id + 'div1'}
        style={{
          height: 40,
          width: 80 * duration,
        }}
      >
        <div
          className={`schedulingTimeSlot ${schedule.type}`}
          onClick={() => {
            dispatch({ type: CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER, payload: user });
            dispatch({ type: CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG, payload: true });
          }}
          key={user.id + 'div2'}
        >
          <Row justify="space-between" key={user.id + 'row1'}>
            <Col key={user.id}></Col>
            <Col key={user.id}>
              <span key={user.id + time + 3}>
                {schedule.type}{' '}
                {schedule.type === 'B' && (
                  <i style={{ marginLeft: 3 }} className="bi bi-clock-history" />
                )}
              </span>
            </Col>
            <Col key={user.id} style={{ paddingRight: 10 }}>
              <i key={user.id} className={'editIcon bi bi-pencil'} />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const EmptySlot = (props: { time: string }) => {
    const { time } = props;

    return (
      <div
        style={{
          width: 80,
          border: '1px solid transparent',
          borderRight: '1px solid #efefef',
          height: 34,
        }}
        key={user.id + time + 1}
        onClick={() => {
          dispatch({ type: CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER, payload: user });
          dispatch({ type: CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG, payload: true });
        }}
      >
        <div
          className="schedulingEmptySlot"
          key={user.id + time + 2}
          style={{
            background: '#F2F2F2',
            verticalAlign: 'middle',
            borderRadius: 4,
            margin: '0 5px',
            border: '1px dashed #D1DAF9',
            paddingTop: 7,
          }}
        >
          <span key={user.id + time + 3}>
            <i style={{ marginLeft: 3 }} className="bi bi-plus-lg" />
          </span>
        </div>
      </div>
    );
  };

  const renderSlot = (timeFrame: string) => {
    if (schedules.length === 0) {
      return <EmptySlot key={user.id + timeFrame} time={timeFrame} />;
    }

    const time = dayjs(timeFrame, 'HH:mm');

    // Using dayjs, find a schedule where a start time happens between the time frame + 30 minutes
    const startSchedule = schedules.find((schedule) => {
      const startTime = dayjs(schedule.startTime, 'HH:mm');
      return (
        time.isSame(startTime) ||
        (time.isAfter(startTime) && time.isBefore(startTime.add(30, 'minute')))
      );
    });

    const betweenSchedule = schedules.find((schedule) => {
      const startTime = dayjs(schedule.startTime, 'HH:mm');
      const endTime = dayjs(schedule.endTime, 'HH:mm');
      return time.isBetween(startTime, endTime);
    });

    const pastSchedule = schedules.find((schedule) => {
      const endTime = dayjs(schedule.endTime, 'HH:mm');
      return time.isAfter(endTime) && time.isBefore(endTime.add(30, 'minute'));
    });

    if (startSchedule && time.isSame(dayjs(startSchedule.startTime, 'HH:mm'))) {
      return <TimeSlot time={timeFrame} schedule={startSchedule} />;
    } else if (betweenSchedule) {
      return <></>;
    } else {
      return <EmptySlot time={timeFrame} />;
    }
  };

  const renderTimeFrames = () => {
    return TIME_FRAMES.map((time: string, index) => (
      <div
        key={user.id + time + index}
        style={{
          paddingTop: 6,
          height: 47,
          textAlign: 'center',
          display: 'inline-block',
          verticalAlign: 'top',
        }}
      >
        {renderSlot(time)}
      </div>
    ));
  };

  return <>{renderTimeFrames()}</>;
};
export default SchedulingUserTimeline;
