import { Button, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../..';
import {
  getRecordByIdRequest,
  IGetRecordById,
} from '../../../../../../../../core/records/store/actions';
import { getOdinSchemaByRecord } from '../../../../../../../../shared/utilities/schemaHelpers';
import RecordStageChangeDialog from '../../../../../../../shared/components/SchemaActions/RecordStageChangeDialog';
import { MY_CASES_UPDATE_CASE } from '../../../store/constants';

interface Props {
  getRecordById: (params: IGetRecordById, cb: any) => void;
}

const SubmitCaseSolvedButton: React.FC<Props> = (props: Props) => {
  const { getRecordById } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const isActionDisabled = () => {
    return ['CaseDefaultStageSolved'].includes(state.selectedCase?.stage?.key!);
  };

  const refreshTheSourceRecord = async () => {
    try {
      if (state.selectedCase) {
        const schema = await getOdinSchemaByRecord(state.selectedCase!);

        getRecordById(
          { recordId: state.selectedCase.id, schema },
          (res: DbRecordEntityTransform) => {
            if (res) {
              dispatch({ type: MY_CASES_UPDATE_CASE, payload: res });
              setIsDialogOpen(false);
            }
          },
        );
      }
    } catch (error) {
      console.error(error);
      setIsDialogOpen(false);
    }
  };

  return (
    <>
      <RecordStageChangeDialog
        openDialog={isDialogOpen}
        record={state.selectedCase!}
        targetStage={'CaseDefaultStageSolved'}
        onClose={refreshTheSourceRecord}
        onConfirm={() => {
          setIsDialogOpen(false);
        }}
      />
      <Tooltip fill disabled={!isActionDisabled()} content="This Case is already Solved">
        <Button
          intent="primary"
          text="Submit Case Solved"
          style={{ borderRadius: 5 }}
          disabled={isDialogOpen || isActionDisabled() || !state.selectedCase}
          onClick={() => setIsDialogOpen(true)}
        />
      </Tooltip>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (params: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(SubmitCaseSolvedButton);
