import { Button, Callout, Dialog, DialogBody, DialogFooter, Section } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { Col, Empty, Row } from 'antd';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import HourlyViewUnassignedWorkOrder from '../HourlyViewUnassignedWorkOrder';
import { HourlyViewContext } from '..';
import { connect } from 'react-redux';
import { INavigationReducer } from '../../../../../../../core/navigation/store/reducer';
import { useCalendarState } from '../../../context';
import { SET_WORK_ORDERS_TO_ASSIGN } from '../store/constants';

interface Props {
  unassignedWorkOrders: DbRecordEntityTransform[];
  navigationReducer: INavigationReducer;
}

const HourlyViewUnassignedWorkOrderDrawer: React.FC<Props> = (props: Props) => {
  const { unassignedWorkOrders, navigationReducer } = props;
  const { state, dispatch } = useContext(HourlyViewContext);
  const [isHelpVisible, setIsHelpVisible] = useState<boolean>(false);
  const { schedules } = useCalendarState();

  const getViewportHeight = () => {
    if (navigationReducer?.tabHistory?.length > 0) {
      return '66vh';
    } else {
      return '70vh';
    }
  };

  const selectAll = () => {
    dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: unassignedWorkOrders });
  };

  const deselectAll = () => {
    dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: [] });
  };

  const isEverythingSelected = () => {
    return state.unassignedWorkOrders.every((wo: any) =>
      state.workOrdersToAssign.some((assigningWO: any) => assigningWO.id === wo.id),
    );
  };

  // create a simple component with a span
  const AssigningHelp = () => {
    return (
      <Row>
        <Col span={24}>
          <p>
            <span>
              <strong>Assigning an unassigned Work Order to an Engineer</strong>
            </span>
          </p>
          <p>
            In order to show a list of unassigned work orders, make sure that you first select{' '}
            <strong>Region</strong> or <strong>Schedules</strong> in the top right corner of the
            screen. Once the list of work orders appears on the left, here's how to assign them to
            an engineer:
          </p>
          <ol>
            <li>Click on one or many unassigned work orders that you want to select.</li>
            <li>Click on an engineer name on the right side.</li>
            <li>Confirm the assignment.</li>
          </ol>
        </Col>

        <Col span={24} style={{ marginTop: 15 }}>
          <p>
            <span>
              <strong>Moving Work Orders</strong>
            </span>
          </p>
          <p>
            Moving Work Orders from one engineer to another, happens the same way. Select one or
            many Work Orders from the engineer's list and click on another engineer's name. Confirm
            the assignment and you're done.
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <Section
      title={
        state.workOrdersToAssign.length === 0
          ? 'Unassigned Work Orders'
          : `Selected (${state.workOrdersToAssign.length})`
      }
      rightElement={
        <Button
          small
          minimal
          disabled={schedules.selectedIds?.length === 0}
          intent="none"
          onClick={() => setIsHelpVisible(!isHelpVisible)}
          icon="help"
        />
      }
    >
      <Row style={{ padding: '10px 8px', overflowY: 'auto' }}>
        <Col span={24} style={{ height: getViewportHeight() }}>
          {/* No Selected Ids warning */}
          {schedules.selectedIds?.length === 0 && (
            <Row>
              <Col span={24} style={{ marginBottom: 15 }}>
                <Callout intent="primary" icon={null}>
                  <AssigningHelp />
                </Callout>
              </Col>
            </Row>
          )}

          {/* No WOs Indicator */}
          {unassignedWorkOrders.length === 0 && schedules.selectedIds?.length > 0 && (
            <Empty style={{ marginTop: 20 }} description="No unassigned Work Orders found" />
          )}

          {/* Select All / Deselect All */}
          {schedules.selectedIds?.length > 0 && unassignedWorkOrders.length > 0 && (
            <Row>
              <Col span={24}>
                <Row style={{ marginBottom: 10 }} justify="space-around">
                  <Col span={11}>
                    <Button
                      small
                      icon="confirm"
                      disabled={isEverythingSelected()}
                      text="Select All"
                      intent="primary"
                      minimal
                      fill
                      onClick={selectAll}
                    />
                  </Col>
                  <Col span={11}>
                    <Button
                      small
                      icon="circle"
                      text="Deselect All"
                      intent="primary"
                      onClick={deselectAll}
                      fill
                      minimal
                      disabled={state.workOrdersToAssign.length === 0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {/* Wo List */}
          {schedules.selectedIds?.length > 0 &&
            unassignedWorkOrders.length > 0 &&
            unassignedWorkOrders.map((WO: any, i: number) => (
              <div
                style={{
                  marginBottom: i + 1 <= unassignedWorkOrders.length ? 10 : 0,
                }}
                key={WO.id}
              >
                <HourlyViewUnassignedWorkOrder workOrder={WO} />
              </div>
            ))}
        </Col>
      </Row>

      {/* Help Dialog */}
      <Dialog
        title="Help"
        icon="help"
        usePortal
        isOpen={isHelpVisible}
        onClose={() => setIsHelpVisible(false)}
        canEscapeKeyClose
        canOutsideClickClose
      >
        <DialogBody>
          <Col span={24}>
            <AssigningHelp />
          </Col>
        </DialogBody>
        <DialogFooter actions={[<Button text="Close" onClick={() => setIsHelpVisible(false)} />]} />
      </Dialog>
    </Section>
  );
};

const mapState = (state: any) => ({
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(HourlyViewUnassignedWorkOrderDrawer);
