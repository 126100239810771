import React from 'react';
import { Switch } from 'react-router-dom';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import ProtectedRoute from '../../core/navigation/ProtectedRoute';
import RecordDetailView from '../../core/records/components/DetailViewContext';
import RecordListView from '../../core/records/components/ListView';
import DefaultDetailView from '../../v2/shared/views/DetailView';
import DetailViewContext from '../../core/records/components/DetailViewContext';
import UserDetailView from '../../v2/modules/IdentityModule/views/UserDetailView';

const { IDENTITY_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;

export const IdentityModuleRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={`/${IDENTITY_MODULE}/User/:recordId`}
        moduleName={IDENTITY_MODULE}
        entityName="User"
        component={
          <DetailViewContext moduleName={IDENTITY_MODULE} entityName={'User'}>
            <UserDetailView />
          </DetailViewContext>
        }
      />
      <ProtectedRoute
        exact
        path={`/${IDENTITY_MODULE}/:entityName`}
        moduleName={IDENTITY_MODULE}
        component={<RecordListView moduleName={IDENTITY_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${IDENTITY_MODULE}/:entityName/:recordId`}
        moduleName={IDENTITY_MODULE}
        component={
          <RecordDetailView moduleName={IDENTITY_MODULE}>
            <DefaultDetailView />
          </RecordDetailView>
        }
      />
    </Switch>
  );
};
