import { Tag, Tooltip } from '@blueprintjs/core';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  UpdateSchemaColumn,
  updateSchemaColumnRequest,
} from '../../../../../core/schemasColumns/store/actions';
import { connect } from 'react-redux';
import { SCHEMA_COLUMN_TYPE_KEYS } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { renderBooleanValue } from '../../../../../v2/shared/helpers/UIHelpers';
import { SchemaColumnValidatorTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.types';

interface Props {
  schema: SchemaEntity | undefined;
  columnId: string;
  property: string;
  value: any;
  onUpdate: () => void;
  updateColumn: (params: UpdateSchemaColumn, cb: any) => void;
}

const editableProperties = [
  'label',
  'description',
  'isHidden',
  'isVisibleInTables',
  'isTitle',
  'position',
  'type',
  'schemaTypeId',
  'defaultValue',
  'validators',
];

const EditableSchemaProperty: React.FC<Props> = (props: Props) => {
  const { value, columnId, schema, updateColumn, property } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [updatedValue, setUpdatedValue] = useState<any>(undefined);
  const [error, setError] = useState<boolean>(false);

  const isColumnEditable = () => {
    const column: any = schema!.columns.find((c: any) => c.id === columnId);
    if (schema && column) {
      if (column.isShared) return false;
      else return true;
    } else {
      return editableProperties.includes(property);
    }
  };

  useEffect(() => {
    if (property === 'position' && value === 0) {
      setUpdatedValue(0);
    } else if (property === 'validators' && value) {
      setUpdatedValue(value.map((v: any) => v.type));
    } else if (value) {
      setUpdatedValue(value);
    } else {
      setUpdatedValue(undefined);
    }
  }, [value]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      setUpdatedValue(undefined);
      setIsEditing(false);
      setError(false);
      setUpdatedValue(value);
    } else if (e.key === 'Enter') {
      onBlur();
    }
  };

  const handleOnChange = (value: string) => {
    setUpdatedValue(value);
    setError(false);
  };

  const update = (newValue: any) => {
    const column: any = schema!.columns.find((c: SchemaColumnEntity) => c.id === columnId);

    if (schema && column) {
      setIsUpdating(true);

      let validatorsList: string[] = [];

      // WATCH FOR PROPERTY, IF VALIDATORS, THEN MAP TO VALIDATOR TYPES
      if (property === 'validators') {
        validatorsList = newValue;
      } else {
        validatorsList = column.validators.map((validator: any) => validator.type) || [];
      }

      let body: any = {
        ...column,
        validators: validatorsList,
        [property]: newValue,
      };

      updateColumn({ schemaId: schema.id, schemaColumnId: columnId, body }, () => {
        setIsUpdating(false);
        setIsEditing(false);
        setError(false);
        props.onUpdate();
      });
    }
  };

  const onBlur = () => {
    if (property === 'position') {
      if (value !== updatedValue) {
        update(updatedValue);
      } else {
        setIsEditing(false);
      }
    } else if (property === 'validators') {
      const oldValue = value.map((v: any) => v.type);
      if (!oldValue.every((v: any) => updatedValue.indexOf(v) > -1)) {
        update(updatedValue);
      } else {
        setIsEditing(false);
      }
    } else {
      if (updatedValue !== value && updatedValue !== undefined && updatedValue.length) {
        update(updatedValue);
      } else if (
        updatedValue === undefined ||
        (!updatedValue.length && ['name', 'label', 'description', 'type'].includes(property))
      ) {
        setError(true);
      } else if (updatedValue === value) {
        setIsEditing(false);
      }
    }
  };

  const renderTEXTField = () => {
    return (
      <Tooltip fill content="Field cannot be empty" isOpen={error}>
        <Input
          onKeyDown={handleKeyDown}
          autoFocus
          onBlur={onBlur}
          disabled={isUpdating}
          size="small"
          value={updatedValue}
          onChange={(e) => handleOnChange(e.target.value)}
          status={error ? 'error' : undefined}
        />
      </Tooltip>
    );
  };

  const renderNumberField = () => {
    return (
      <InputNumber
        onKeyDown={handleKeyDown}
        autoFocus
        onBlur={onBlur}
        disabled={isUpdating}
        size="small"
        value={updatedValue}
        onChange={(e) => handleOnChange(e)}
        status={error ? 'error' : undefined}
      />
    );
  };

  const renderENUMField = () => {
    return (
      <Select
        value={updatedValue}
        autoFocus
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        size="small"
        disabled={isUpdating}
        style={{ width: '100%' }}
        onChange={(e: any) => {
          setUpdatedValue(String(e));
          update(e);
        }}
      >
        <Select.Option value={true}>true</Select.Option>
        <Select.Option value={false}>false</Select.Option>
      </Select>
    );
  };

  const renderColumnTypeField = () => {
    return (
      <Select
        autoFocus
        placeholder="Select type"
        value={updatedValue}
        size="small"
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onChange={(e) => {
          update(e);
        }}
      >
        {SCHEMA_COLUMN_TYPE_KEYS.map((option: any) => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderSchemaTypeField = () => {
    return (
      <Select
        autoFocus
        placeholder="Select Schema type"
        value={updatedValue}
        size="small"
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onChange={(e) => {
          update(e);
        }}
      >
        {schema?.types.map((type: any) => (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderValidatorField = () => {
    let allowedValidators: string[] = [];
    const column = schema!.columns.find((c: SchemaColumnEntity) => c.id === columnId);

    if (column) {
      Object.keys(SchemaColumnValidatorTypes).map((key: any) => {
        if (!!SchemaColumnValidatorTypes[key].columnTypes.find((t) => t === column.type)) {
          const option: any = SchemaColumnValidatorTypes[key].name;
          allowedValidators.push(option);
        }
      });
    }

    return (
      <Select
        onKeyDown={handleKeyDown}
        style={{ width: '100%' }}
        autoFocus
        size="small"
        mode="multiple"
        value={updatedValue}
        onBlur={onBlur}
        onChange={(e: any) => update(e)}
        maxTagCount={1}
      >
        {allowedValidators.map((validator: string) => {
          return (
            <Select.Option key={validator} value={validator} label={validator}>
              {String(validator)}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const showFormField = () => {
    switch (property) {
      case 'label':
        return renderTEXTField();
      case 'description':
        return renderTEXTField();
      case 'isHidden':
        return renderENUMField();
      case 'isVisibleInTables':
        return renderENUMField();
      case 'isTitle':
        return renderENUMField();
      case 'isSearchable':
        return renderENUMField();
      case 'position':
        return renderNumberField();
      case 'type':
        return renderColumnTypeField();
      case 'schemaTypeId':
        return renderSchemaTypeField();
      case 'defaultValue':
        return renderTEXTField();
      case 'validators':
        return renderValidatorField();
      default:
        return <div>{value}</div>;
    }
  };

  const renderValidatorTagIntent = (validator: any) => {
    if (validator === 'REQUIRED') {
      return 'danger';
    } else if (validator === 'UNIQUE') {
      return 'primary';
    } else {
      return 'none';
    }
  };

  const renderUpdatedValue = (value: any) => {
    if (
      value === undefined ||
      value === null ||
      value === '' ||
      (property === 'validators' && value.length === 0)
    ) {
      return <div style={{ width: '100%', height: 23 }}> </div>;
    }
    if (property === 'validators' && value?.length! > 0) {
      return value.map((validator: any, i: number) => (
        <Tag
          intent={renderValidatorTagIntent(validator.type || validator)}
          minimal
          key={validator.type || validator}
          style={{ marginRight: i === value.length - 1 ? 0 : 5 }}
        >
          {validator.type || validator}
        </Tag>
      ));
    }
    if (['isHidden', 'isVisibleInTables', 'isTitle', 'isSearchable'].includes(property)) {
      return (
        <Tooltip content={value} fill placement="top" hoverOpenDelay={1000}>
          {renderBooleanValue(value)}
        </Tooltip>
      );
    } else {
      return value;
    }
  };

  return isColumnEditable() ? (
    <div
      style={{
        cursor: 'pointer',
        minWidth: '100%',
        color: updatedValue === undefined ? 'white' : 'black',
      }}
      onDoubleClick={() => setIsEditing(true)}
    >
      {isEditing ? showFormField() : renderUpdatedValue(updatedValue)}
    </div>
  ) : (
    <div>{renderUpdatedValue(value)}</div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateColumn: (params: UpdateSchemaColumn, cb: any) =>
    dispatch(updateSchemaColumnRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(EditableSchemaProperty);
