import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Divider, Empty, Row, Skeleton, Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getEntityNameFromRecord } from '../../../../shared/utilities/recordHelpers';
import { sendConfirmationEmail } from '../../../notifications/email/store/actions';
import CoreForm from '../Forms/CoreForm';
import ActivityItem from './ActivityItem';
import { getActivityEntity, sortActivitiesByDate } from './helpers';
import { eventTypesFmtENUM } from './types';
import { v4 as uuidv4 } from 'uuid';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { initializeRecordForm } from '../Forms/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getRecordAuditLogs, IGetRecordAuditLogs } from '../../auditLogs/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import './styles.scss';
import { createRecordsRequest, ICreateRecords } from '../../store/actions';

type Props = {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  sendConfirmation: Function;
  schemaReducer: any;
  formReducer: any;
  initializeForm: Function;
  hideEmailTab?: boolean;
  hideNoteTab?: boolean;
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => void;
  getSchema: Function;
  compact?: boolean;
  includeRelatedEntitiesInNotes?: string[];
  maxHeight?: number;
  createRecord: (params: ICreateRecords, cb: any) => void;
};

const ActivityCenter: React.FC<Props> = (props: Props) => {
  const uuid = uuidv4();
  const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
  const {
    record,
    sendConfirmation,
    hideNoteTab,
    includeRelatedEntitiesInNotes,
    maxHeight,
    compact,
    createRecord,
  } = props;
  const [isLoadingActivities, setIsLoadingActivities] = useState<boolean>(false);
  const [isUpdatingActivities, setIsUpdatingActivities] = useState<boolean>(false);
  const [activityList, setActivityList] = useState<DbRecordEntityTransform[]>([]);
  const [hiddenDaysList, setHiddenDaysList] = useState<string[]>([]);
  const [activityTypeFilter, setActivityTypeFilter] = useState<string>('All Events');
  const [recordTypeFilter, setRecordTypeFilter] = useState<string>('All Types');
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const [recordId, setRecordId] = useState<string | undefined>(undefined);
  const previousRecordId = usePrevious(recordId);

  // Custom hook to leverage previous state monitoring in react hooks
  function usePrevious<T>(value: T): T {
    const ref: any = useRef<T>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  // Fetch Activity schema on component mount
  useEffect(() => {
    const shortlistActivitySchema = getSchemaFromShortListByModuleAndEntity(
      props.schemaReducer.shortList,
      SCHEMA_MODULE,
      'Activity',
    );
    if (!shortlistActivitySchema) {
      props.getSchema({ moduleName: SCHEMA_MODULE, entityName: 'Activity' });
    }
  }, []);

  // If the previous record id is not the same as the current one, fetch the associations again.
  // We introduced this because when switching the in-app tabs, component stays mounted but the
  // record changes. This way we can monitor the record prop and fetch associations.
  useEffect(() => {
    if (previousRecordId && recordId && recordId !== previousRecordId) {
      setIsUpdatingActivities(true);
      getActivity(recordId);
    }
  }, [recordId]);

  useEffect(() => {
    if (recordId) {
      setIsUpdatingActivities(true);
      getActivity(recordId);
    }
  }, [activityTypeFilter, recordTypeFilter]);

  useEffect(() => {
    if (expandAll) {
      expandOrCollapseActivityPanels('EXPAND');
      setHiddenDaysList([]);
    } else {
      expandOrCollapseActivityPanels('COLLAPSE');
    }
  }, [expandAll]);

  // Get activity list on init
  useEffect(() => {
    if (!activityList.length && record) {
      setIsLoadingActivities(true);
      getActivity(record.id);
    }

    // If new parent record is passed, store the id in the local state
    if (props.record && props.record?.id !== recordId) {
      setRecordId(props.record?.id);
    }
  }, [props.record]);

  const initializeNewActivityForm = () => {
    const { schemaReducer, initializeForm, record } = props;

    const activitySchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SCHEMA_MODULE,
      'Activity',
    );

    if (activitySchema && record) {
      initializeForm({
        formUUID: uuid,
        title: 'Create Activity',
        showFormModal: true,
        isCreateReq: true,
        isBatchCreateReq: false,
        schema: activitySchema,
        sections: [{ name: activitySchema.name, schema: activitySchema }],
        modified: [
          {
            associations: [
              {
                entity: record.entity,
                recordId: record.id,
              },
            ],
            schemaId: activitySchema.id,
          },
        ],
      });
    }
  };

  // Collapse or Expand activities for a single day
  const toggleDayVisibility = (key: string) => {
    if (hiddenDaysList.includes(key)) {
      setHiddenDaysList(hiddenDaysList.filter((keyi: string) => keyi !== key));
    } else {
      setHiddenDaysList([...hiddenDaysList, key]);
    }
  };

  // Toggle Activity panel visibility on click
  const toggleActivityPanel = (id: any) => {
    const activityListModified: DbRecordEntityTransform[] = activityList.map((activity: any) => ({
      ...activity,
      open: activity.id === id ? !activity.open : activity.open,
    }));
    setActivityList(activityListModified);
  };

  // Expand or Collapse all Activity panels
  const expandOrCollapseActivityPanels = (action: 'EXPAND' | 'COLLAPSE') => {
    const activityListModified: DbRecordEntityTransform[] = activityList.map((activity: any) => ({
      ...activity,
      open: action === 'EXPAND' ? true : false,
    }));
    setActivityList(activityListModified);
  };

  // Render list of activities
  const renderActivities = () => {
    if (activityList.length > 0) {
      let filteredActivityList: any[] = activityList;
      let dayList: any = {};

      // Sort filtered activities by date
      dayList = sortActivitiesByDate(filteredActivityList);

      if (Object.keys(dayList).length > 0) {
        return Object.keys(dayList).map((key: string, i: number) => {
          return (
            <Row key={key + 1}>
              <Col span={24} key={key + 1}>
                <Divider
                  plain
                  style={{
                    fontWeight: hiddenDaysList.includes(key) ? 'bold' : 'normal',
                  }}
                >
                  {
                    <span
                      style={{ cursor: 'pointer', fontSize: '0.9em' }}
                      onClick={() => {
                        toggleDayVisibility(key);
                      }}
                    >
                      {moment(key).format('dddd DD/MM/YYYY')}
                    </span>
                  }
                </Divider>
              </Col>
              <Col
                span={24}
                style={{
                  display: hiddenDaysList.includes(key) ? 'none' : 'block',
                }}
              >
                {dayList[key].map((activity: DbRecordEntityTransform) => {
                  return (
                    <ActivityItem
                      compact={props.compact}
                      toggleActivityPanel={toggleActivityPanel}
                      activityItem={activity}
                      recordEntityName={getEntityNameFromRecord(record)}
                    />
                  );
                })}
              </Col>
            </Row>
          );
        });
      } else {
        return <Empty style={{ margin: 30 }} description="No Activities found" />;
      }
    } else {
      return <Empty style={{ margin: 30 }} description="No Activities found" />;
    }
  };

  // Fetch activities from the UserActivity API
  const getActivity = async (parentRecordId: string) => {
    const { getAuditLogs, schema } = props;

    if (parentRecordId) {
      getAuditLogs(
        {
          schema: schema,
          recordId: parentRecordId,
          entities:
            recordTypeFilter !== 'All Types'
              ? [recordTypeFilter]
              : ['Activity', 'File', 'Note', 'SmsMessage', 'ActivityLog'],
          sort: { createdAt: 'DESC' },
          whereQuery:
            activityTypeFilter !== 'All Events' ? { type: activityTypeFilter } : undefined,
        },
        (res: any) => {
          if (res && res?.results?.length > 0) {
            let activityListModified = res?.results?.map((activity: any) => ({
              ...activity,
              open: getActivityEntity(activity) === 'Note' ? true : false,
            }));

            activityListModified = _.orderBy(
              activityListModified,
              (o: any) => {
                return moment(o.createdAt);
              },
              ['asc'],
            );

            setActivityList(activityListModified);
            setIsLoadingActivities(false);
            setIsUpdatingActivities(false);
          } else {
            setActivityList([]);
            setIsLoadingActivities(false);
            setIsUpdatingActivities(false);
            console.error('Activity center: Error while fetching Activities.');
          }
        },
      );
    }
  };

  const renderActivityTypeFilter = () => {
    let ACTIVITY_TYPES: any[] = Object.keys(eventTypesFmtENUM)
      .map((key: string) => ({
        key: key,
        label: key,
      }))
      .map((f: any, index: number) => ({ ...f, rank: index + 2 }));
    ACTIVITY_TYPES.unshift({ key: 'All Events', label: 'All Events', rank: 0 });

    const renderEventType: ItemRenderer<any> = (
      event,
      { handleClick, handleFocus, modifiers, query },
    ) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={event.rank}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="listoption"
          text={event.label}
        />
      );
    };

    return (
      <Select
        items={ACTIVITY_TYPES}
        itemRenderer={renderEventType}
        filterable={false}
        activeItem={ACTIVITY_TYPES.find((f) => f.key === activityTypeFilter)}
        onItemSelect={(e: any) => setActivityTypeFilter(e.key)}
      >
        <Button
          text={truncateSelectBoxText(activityTypeFilter, compact ? 8 : 12)}
          rightIcon="caret-down"
          placeholder="Select a film"
          style={{ width: compact ? 110 : 190 }}
        />
      </Select>
    );
  };

  const renderRecordTypeFilter = () => {
    let RECORD_TYPES: any[] = [
      {
        key: 'Note',
        label: 'Note',
      },
      {
        key: 'File',
        label: 'File',
      },
      {
        key: 'Email',
        label: 'Email',
      },
      {
        key: 'SMS',
        label: 'SMS',
      },
    ].map((f: any, index: number) => ({ ...f, rank: index + 2 }));
    RECORD_TYPES.unshift({ key: 'All Types', label: 'All Types', rank: 0 });

    const renderEventType: ItemRenderer<any> = (
      event,
      { handleClick, handleFocus, modifiers, query },
    ) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={event.rank}
          // label={event.label.toString()}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="listoption"
          text={event.label}
        />
      );
    };

    return (
      <Select
        items={RECORD_TYPES}
        itemRenderer={renderEventType}
        filterable={false}
        onItemSelect={(e: any) => setRecordTypeFilter(e.key)}
      >
        <Button
          text={recordTypeFilter || 'Record type'}
          rightIcon="caret-down"
          placeholder="Select a film"
          style={{ width: compact ? 110 : 140 }}
        />
      </Select>
    );
  };

  const truncateSelectBoxText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const menuItems = () => {
    return (
      <>
        <MenuItem
          icon={expandAll ? 'minimize' : 'maximize'}
          text={expandAll ? 'Collapse All' : 'Expand All'}
          onClick={() => setExpandAll(!expandAll)}
          key={1}
        />
        <MenuItem
          icon="plus"
          text="Create Activity"
          onClick={() => initializeNewActivityForm()}
          key={2}
          disabled={!recordId}
        />
      </>
    );
  };

  return isLoadingActivities ? (
    <Skeleton active />
  ) : (
    <Row
      style={{
        maxHeight: isMobile ? 'auto' : maxHeight || 1000,
        overflowY: 'auto',
      }}
    >
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Space>
              <>
                {renderActivityTypeFilter()}
                {renderRecordTypeFilter()}
              </>
            </Space>
          </Col>
          <Col>
            <Popover content={<Menu>{menuItems()}</Menu>} placement="bottom">
              <Button alignText="left" rightIcon="caret-down" />
            </Popover>
          </Col>
        </Row>

        <div style={{ opacity: isUpdatingActivities ? 0.3 : 1 }}>{renderActivities()}</div>
        <CoreForm type="MODAL" formUUID={uuid} onSubmitEvent={() => getActivity(recordId!)} />
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => dispatch(getRecordAuditLogs(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  formReducer: state.formReducer,
});

export default connect(mapState, mapDispatch)(ActivityCenter);
