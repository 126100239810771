import G6 from '@antv/g6';
import React, { useEffect } from 'react';
import { filterCableDiagramData } from './helpers';
import { IBuildPackData } from '..';

let graph: any = null;

interface Props {
  buildPackData: IBuildPackData;
  features: any;
  graphContainer: any;
  handleNetworkDiagramBuild: any;
  SLDWorkChainImages: any[];
  overviewSLDImage: any[];
}

const BuildPackGraph = (props: Props) => {
  const ref = React.useRef(null);

  const drawGraph = async (diagramData: any, direction: 'TB' | 'LR') => {
    return await new Promise((resolve) => {
      const graphConfig = {
        container: ref?.current!,
        layout: {
          type: 'dagre',
          rankdir: 'LR',
          nodesep: 40,
          ranksep: 270,
          controlPoints: true,
        },
        defaultNode: {
          type: 'node',
          size: 90,
          labelCfg: {
            style: {
              fontSize: 28,
              fontWeight: 600,
              padding: 15,
              fontFamily: 'sans-serif',
            },
          },
          style: {
            stroke: '#72CC4A',
            width: 150,
            fontFamily: 'sans-serif',
          },
        },
        defaultEdge: {
          type: 'polyline',
          labelCfg: {
            position: 'end',
            style: {
              fontSize: 20,
              fontFamily: 'sans-serif',
            },
          },
        },
      };

      if (graph !== null) {
        graph.destroy();
        graph = new G6.Graph(graphConfig);
        graph.data(diagramData);
        G6.Util.processParallelEdges(diagramData.edges, 120, 'polyline');
        graph.render();
      } else {
        graph = new G6.Graph(graphConfig);
        G6.Util.processParallelEdges(diagramData.edges, 120, 'polyline');
        graph.data(diagramData);
        graph.render();
      }

      return resolve(
        graph.on('afterrender', () => {
          return true;
        }),
      );
    }).catch((err) => {
      console.error(err);
    });
  };

  const generateGraphImages = async (
    scope: 'OVERVIEW' | 'SINGLE_WORKCHAIN',
  ) => {
    let {
      buildPackData,
      features,
      overviewSLDImage: SLDOverviewImage,
      SLDWorkChainImages,
    } = props;

    // Extract unique loop chain ids
    let allLoopIds: Array<string> = [];
    let filteredLoopIds: Array<string> = [];
    for (const edge of buildPackData.edges) {
      if (edge.loopChainId) {
        allLoopIds.push(edge.loopChainId);
      }
    }
    allLoopIds?.map((loopId: string) => {
      const foundItem = allLoopIds.filter((loop: any) => loop === loopId);
      if (foundItem && foundItem.length > 1) {
        filteredLoopIds.push(foundItem[0]);
      }
    });

    const uniqueLoopIds = [...new Set([...filteredLoopIds])];

    /* RENDER OVERVIEW SLD */
    if (scope === 'OVERVIEW' && buildPackData) {
      let allEdges: any[] = [],
        allNodes: any[] = [],
        allClosureIds: any[] = [];
      const overview = buildPackData.overview[0];

      // Overview closures
      allClosureIds.push(overview?.parentNode?.id);
      overview?.childNodes.forEach((child: any) => {
        allClosureIds.push(child.id);
        buildPackData?.edges
          .filter(
            (edge: any) => edge.source === child.id || edge.target === child.id,
          )
          .map((edge: any) => {
            allEdges.push(edge);
          });
      });

      // Overview nodes
      allNodes = buildPackData?.nodes.filter((node: any) =>
        allClosureIds.includes(node.id),
      );
      await drawGraph(
        filterCableDiagramData(
          { nodes: allNodes, edges: allEdges, cables: features?.cables },
          uniqueLoopIds,
        ),
        'LR',
      ).then(async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
          graph.toFullDataURL((res: any) => {
            SLDOverviewImage.push(res);
          });
        });
      });
    } else if (scope === 'SINGLE_WORKCHAIN' && buildPackData) {

    /* RENDER SINGLE WORK CHAIN */
      for (const workChain of buildPackData?.sections) {
        let allEdges: any[] = [];
        let allNodes: any[] = [];
        let allClosureIds: any[] = [];

        // Single chain closures
        allClosureIds.push(workChain.parentNode.id);
        workChain.childNodes.forEach((child: any) => {
          allClosureIds.push(child.id);
          buildPackData.edges
            .filter(
              (edge: any) =>
                edge.source === child.id || edge.target === child.id,
            )
            .map((edge: any) => {
              allEdges.push(edge);
            });
        });

        // Single chain nodes
        allNodes = buildPackData.nodes.filter((node: any) =>
          allClosureIds.includes(node.id),
        );

        await drawGraph(
          filterCableDiagramData(
            { nodes: allNodes, edges: allEdges, cables: features?.cables },
            uniqueLoopIds,
          ),
          'LR',
        ).then(async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
            graph.toFullDataURL((res: any) => {
              SLDWorkChainImages.push(res);

              if (
                SLDWorkChainImages.length === buildPackData?.sections?.length
              ) {
                props.handleNetworkDiagramBuild();
              }
            });
          });
        });
      }
    }
  };

  useEffect(() => {
    const { buildPackData, handleNetworkDiagramBuild } = props;

    if (buildPackData && buildPackData.sections?.length) {
      generateGraphImages('OVERVIEW').then(() => {
        generateGraphImages('SINGLE_WORKCHAIN').then(() => {});
      });
    }
  }, [props.buildPackData]);

  return <div id="networkdiagram_map" ref={ref} />;
};

export default BuildPackGraph;
