import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import AssociationDataTable from '../../../../../core/recordsAssociations/components/AssociationDataTable';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

const ProgramDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators
        defaultTabKey={'Exchanges'}
        customTabs={[
          {
            key: 'Exchanges',
            tab: 'Exchanges',
          },
        ]}
        customTabBodies={{
          Exchanges: (
            <AssociationDataTable
              title={'Exchanges'}
              record={record}
              moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
              entityName={SchemaModuleEntityTypeEnums.EXCHANGE}
            />
          ),
        }}
      />
    </Layout>
  );
};

export default ProgramDetailView;
