import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Popover, Row, Select, Statistic, Table } from 'antd';
import ModuleEntityIcon from '../../../../core/theme/ModuleEntityIcon';
import { PageHeader } from '../../../../shared/components/PageHeader';

import { DashboardData, DashboardLineItem, FetchState } from './hooks/types';
import { VendorDashboardView } from './hooks';

type VendorDashboardPageHeaderProps = {
  exchanges: FetchState<DbRecordEntityTransform[]>;
  selectedLineItems: DashboardLineItem[];
  totals: Omit<DashboardData, "lineItems">;
  exportProps: {
    canExport: boolean;
    onExportClick: () => void;
  }
  selectedView: VendorDashboardView;
  loading: boolean;
  service: {
    selectExchange: (id: string) => void;
    updateUnsubmittedLineItemByTitle: (title: string, properties: Partial<Record<keyof DashboardLineItem, string>>) => void;
    setSelectedLineItems: (lineItems: DashboardLineItem[]) => void;
    startSubmission: () => void;
    setSelectedView: (value: VendorDashboardView) => void;
  }
};

const getColumns = () => [
  {
    title: 'Title',
    key: 'fpe_title',
    dataIndex: 'fpe_title',
  },
  {
    title: 'Rate Code',
    key: 'rate_code',
    dataIndex: 'rate_code',
  },
  {
    title: 'QTY',
    key: 'quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Price',
    key: 'price',
    dataIndex: 'price',
  },
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
  },
]

export const VendorDashboardPageHeader = ({
  exchanges,
  totals,
  selectedLineItems,
  exportProps,
  service,
  selectedView,
}: VendorDashboardPageHeaderProps) => {

  return (
    <PageHeader
      style={{ borderRadius: 8, border: '1px solid #dddbda', background:'white' }}
      title={
        <>
          <Row>
            <Col style={{ marginTop: 8 }}>
              <ModuleEntityIcon
                moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
                entityName="ApplicationForPayment"
                style={{ padding: '0px 2px' }}
              />
            </Col>
            <Col style={{ paddingLeft: 8 }}>
              <Row>
                <Col span={24}>
                  <h3 style={{ marginTop: 10 }}>
                    AFP Manager
                  </h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '300px' }} >
              <Select
                placeholder={exchanges.status === 'loading' ? 'Loading Exchanges...' : 'Select an Exchange'}
                value={exchanges.selected ? exchanges.selected : undefined}
                style={{ width: '100%' }}
                onChange={(val) => {
                  service.selectExchange(val);
                }}
                options={exchanges.data.map(exchange => ({ label: exchange.title, value: getProperty(exchange, 'ExPolygonId') }))}
                showSearch
                filterOption={(input, option) => {
                  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
              />
            </Col>
          </Row>
        </>
      }
      subTitle={
        <Row style={{ width: '650px' }} justify="space-around">
          {[''].includes(selectedView) && <Col style={{ marginTop: 8 }}>
            <Statistic
              title={`Total Value`}
              value={totals.totalValue}
              precision={2}
              valueStyle={{ color: '#000' }}
              prefix="£"
              style={{ textAlign: 'center' }}
            />
          </Col>}
          {['all_fpes', 'pending_submissions'].includes(selectedView) && <Col style={{ marginTop: 8 }}>
            <Statistic
              title={`Total ${selectedView === 'all_fpes' ? 'Unbilled' : 'Pending'}`}
              value={totals.totalUnBilled}
              precision={2}
              valueStyle={{ color: "#000" }}
              prefix="£"
              style={{ textAlign: 'center'}}
            />
          </Col>}
          {['all_fpes', 'pending_rejections'].includes(selectedView) && <Col style={{ marginTop: 8 }}>
            <Statistic
              title={`Total ${selectedView === 'all_fpes' ? 'Rejected' : 'Pending'}`}
              value={totals.totalRejected}
              precision={2}
              valueStyle={{ color: "#000" }}
              prefix="£"
              style={{ textAlign: 'center'}}
            />
          </Col>}
          {['all_fpes', 'pending_review'].includes(selectedView) && <Col style={{ marginTop: 8 }}>
            <Statistic
              title={`Total Pending`}
              value={totals.totalSubmitted}
              precision={2}
              valueStyle={{ color: '#000' }}
              prefix="£"
              style={{ textAlign: 'center' }}
            />
          </Col>}
          {['all_fpes'].includes(selectedView) && <Col style={{ marginTop: 8 }}>
            <Statistic
              title="Total Approved"
              value={totals.totalApproved}
              precision={2}
              valueStyle={{ color: "#000" }}
              prefix="£"
              style={{ textAlign: 'center'}}
            />
          </Col>}
          {false && <Col style={{ marginTop: 8 }}>
            <Popover content={<Table dataSource={selectedLineItems} columns={getColumns()} />} trigger="click">
              <div>
                <Statistic
                  title={`Not Submitted`}
                  value={selectedLineItems.length}
                  precision={0}
                  valueStyle={{ color: "#000" }}
                  prefix=""
                  style={{ textAlign: 'center'}}
                />
              </div>
            </Popover>
          </Col>}
        </Row>
      }
      extra={
        <>
          <Button
           disabled={selectedLineItems.length === 0 || ['all_fpes', 'pending_review', 'pending_rejections'].includes(selectedView)}
           onClick={() => service.startSubmission()}>
            Add to AFP
          </Button>
          <Button disabled={!exportProps.canExport} onClick={exportProps.onExportClick}>
            <DownloadOutlined style={{ marginRight: 5 }} />
            Export
          </Button>
        </>
      }
      className="dataSetBuilderHeader"
    >
    </PageHeader>
  )
}
