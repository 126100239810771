import { Col, Row } from 'antd';
import { FC, useContext } from 'react';
import { uploadFileFromAssociationContext } from '..';
import { SET_SELECTED_RECORD } from '../store/constants';
import folderImg from '../../../../../assets/images/png/folder-3.png';

import './styles.scss';
interface Props {}

const DirectoryBreadcrumbs: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(uploadFileFromAssociationContext);

  return (
    <Row>
      {/* Parent Record */}
      {/* <Col className="breadcrumbItem clickable"
        onClick={() => {
          dispatch({ type: SET_SELECTED_RECORD, payload: undefined });
        }}
      >
        <img src={folderImg} style={{ width: 16, height: 16 }} />
      </Col> */}
      <Col
        className={`breadcrumbItem clickable`}
        style={{ paddingTop: 1, paddingLeft: 4 }}
        onClick={() => {
          dispatch({ type: SET_SELECTED_RECORD, payload: undefined });
        }}
      >
        <span>{state.parentRecord?.recordNumber}</span>
      </Col>

      {/* First slash */}
      <Col style={{ paddingTop: 0, paddingLeft: 3 }}>
        <span>/</span>
      </Col>

      {state.selectedRecord ? (
        <>
          <Col style={{ paddingTop: 1, paddingLeft: 4 }}>
            <span>{state.selectedRecord?.recordNumber}</span>
          </Col>
        </>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default DirectoryBreadcrumbs;
