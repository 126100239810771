import { Button, Section } from '@blueprintjs/core';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Table } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpPost } from '../../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';

interface Props {
  schemaAction: SchemaActionEntity | undefined;
  schema: SchemaEntity | undefined;
  alertMessage: any;
}

const SchemaActionPermissions: FC<Props> = (props: Props) => {
  const { schemaAction, alertMessage } = props;
  const [isRequestingAccessControl, setIsRequestingAccessControl] = useState<boolean>(false);

  const tableColumns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Description', dataIndex: 'description' },
  ];

  const requestAccessControl = () => {
    setIsRequestingAccessControl(true);
    httpPost(
      `IdentityModule/v1.0/rbac/permissions/schemas-actions/batch/${schemaAction?.id}`,
      {},
    ).then((res: any) => {
      alertMessage({
        body: `Access Control Enabled`,
        type: 'success',
      });

      setIsRequestingAccessControl(false);
    });
  };

  return (
    <Section
      style={{ overflowY: 'scroll', height: '100%' }}
      title="Access Control"
      rightElement={
        <Button
          icon="lock"
          onClick={requestAccessControl}
          disabled={!schemaAction}
          loading={isRequestingAccessControl}
          text="Enable Access Control"
        />
      }
    >
      <Table
        size="small"
        columns={tableColumns as any}
        dataSource={schemaAction?.permissions || ([] as any)}
      />
    </Section>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(SchemaActionPermissions);
