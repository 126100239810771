import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform"
import { Card, Divider } from "antd";
import AssociationDataTable from "../../../../../core/recordsAssociations/components/AssociationDataTable";

type RelationsDetailsProps = {
  record: DbRecordEntityTransform;
}

export const RelationsDetails = (props: RelationsDetailsProps) => {
  const { record } = props;

  return (
    <>
      <Card title="Files" className="filesAndNotesCard">
        <AssociationDataTable
          thumbnailSize={6}
          title="Files"
          record={record}
          showFileCategoryForType="DEFAULT"
          moduleName="SchemaModule"
          entityName="File"
          location="sidebar"
        />
      </Card>
      <Divider />
      <Card title="Files" className="filesAndNotesCard">
        <AssociationDataTable
          title="Feature Price Entries"
          record={record}
          moduleName="ProjectModule"
          entityName="FeaturePriceEntry"
        />
      </Card>
    </>
  )
}