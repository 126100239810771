// Create react functional component for RemoveONTDeviceFlow with Props

import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { FC, useEffect, useState } from 'react';
import SchemaUserActionFlow from '../../../../../../v2/shared/components/SchemaActions/SchemaUserActionFlow';
import { httpGet } from '../../../../../../shared/http/requests';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const SCHEMA_ACTION_FLOW = 'RequestToReplaceOnt';

interface Props {
  isVisible: boolean;
  record: DbRecordEntityTransform;
  onSuccess?: () => void;
  onClose?: () => void;
}

const RemoveONTDeviceFlow: FC<Props> = (props: Props) => {
  const { isVisible, onSuccess, record } = props;
  const [schemaAction, setSchemaAction] = useState<SchemaActionEntity | undefined>(undefined);

  useEffect(() => {
    if (isVisible && !schemaAction) {
      fetchSchemaAction();
    }
  }, [isVisible]);

  const onClose = () => {
    setSchemaAction(undefined);
    if (props.onClose) {
      props.onClose();
    }
  };

  const fetchSchemaAction = () => {
    httpGet(`${SCHEMA_MODULE}/v1.0/schemas-actions`).then((response: any) => {
      const SA = response.data?.data.find(
        (action: SchemaActionEntity) => action.name === SCHEMA_ACTION_FLOW,
      );
      if (SA) {
        setSchemaAction(SA);
      }
    });
  };

  const onConfirm = () => {
    setSchemaAction(undefined);
    if (onSuccess) {
      onSuccess();
    }
  };

  return schemaAction ? (
    <SchemaUserActionFlow
      schemaActionId={schemaAction.id}
      record={record}
      onClose={onClose}
      onConfirm={onConfirm}
      hideLauncher
    />
  ) : (
    <></>
  );
};

export default RemoveONTDeviceFlow;
