import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import RecordCard from '../../../../../core/records/components/RecordCard';
import DetailView from '../../../../shared/views/DetailView';
import BillingRequestSend from '../../components/BillingRequestSend';
import BillingRequestCancel from '../../components/BillingRequestCancel';
import GoCardlessActivityFeed from '../../../../../core/billing/GocardlessActivityFeed';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { CONTACT } = SchemaModuleEntityTypeEnums;

const BillingRequestDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      customTabs={[
        {
          key: 'GoCardless',
          tab: 'GoCardless',
        },
      ]}
      customTabBodies={{
        GoCardless: <GoCardlessActivityFeed record={record} filterBy="BILLING_REQUEST" />,
      }}
      headerExtras={[
        <BillingRequestSend record={record!} />,
        <BillingRequestCancel record={record!} />,
      ]}
      showCommunicationHistory
      leftColumn={[
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={CONTACT}
          visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
        />,
      ]}
    />
  );
};

export default BillingRequestDetailView;
