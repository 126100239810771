export type TScheduleUser = {
  id: string;
  firstName: string;
  lastName: string;
  schedules?: {
    startTime: string;
    endTime: string;
    type: 'TR' | 'SM' | 'T' | 'RF' | 'B' | 'C' | 'FREE';
  }[];
};

export const SCHEDULING_DUMMY_DATA: TScheduleUser[] = [
  {
    id: '1',
    firstName: 'Liam',
    lastName: 'Johnson',
    schedules: [
      { startTime: '09:00', endTime: '10:00', type: 'TR' },
      { startTime: '10:30', endTime: '11:00', type: 'B' },
      { startTime: '11:00', endTime: '13:00', type: 'SM' },
      { startTime: '13:00', endTime: '17:00', type: 'T' },
      { startTime: '19:30', endTime: '20:00', type: 'B' },
    ],
  },
  {
    id: '2',
    firstName: 'Olivia',
    lastName: 'Brown',
    schedules: [
      { startTime: '08:30', endTime: '09:30', type: 'RF' },
      { startTime: '10:00', endTime: '13:30', type: 'TR' },
    ],
  },
  {
    id: '3',
    firstName: 'Noah',
    lastName: 'Williams',
    schedules: [
      { startTime: '08:00', endTime: '08:30', type: 'B' },
      { startTime: '09:00', endTime: '13:00', type: 'C' },
    ],
  },
  {
    id: '4',
    firstName: 'Ava',
    lastName: 'Jones',
    schedules: [
      { startTime: '08:30', endTime: '10:30', type: 'TR' },
      { startTime: '10:30', endTime: '11:00', type: 'B' },
      { startTime: '11:00', endTime: '13:30', type: 'TR' },
      { startTime: '13:30', endTime: '14:30', type: 'C' },
      { startTime: '14:00', endTime: '16:00', type: 'RF' },
    ],
  },
  {
    id: '5',
    firstName: 'Elijah',
    lastName: 'Garcia',
  },
  {
    id: '6',
    firstName: 'Sophia',
    lastName: 'Miller',
    schedules: [
      { startTime: '09:30', endTime: '11:30', type: 'TR' },
      { startTime: '12:00', endTime: '12:30', type: 'B' },
      { startTime: '12:30', endTime: '14:00', type: 'SM' },
    ],
  },
  {
    id: '7',
    firstName: 'James',
    lastName: 'Davis',
    schedules: [
      { startTime: '08:00', endTime: '08:30', type: 'B' },
      { startTime: '13:00', endTime: '19:00', type: 'C' },
    ],
  },
  { id: '8', firstName: 'Isabella', lastName: 'Rodriguez' },
  { id: '9', firstName: 'Lucas', lastName: 'Martinez' },
  { id: '10', firstName: 'Mia', lastName: 'Hernandez' },
  { id: '11', firstName: 'Benjamin', lastName: 'Lopez' },
  { id: '12', firstName: 'Charlotte', lastName: 'Gonzalez' },
  { id: '13', firstName: 'Henry', lastName: 'Wilson' },
  { id: '14', firstName: 'Amelia', lastName: 'Anderson' },
  { id: '15', firstName: 'Alexander', lastName: 'Thomas' },
  { id: '16', firstName: 'Daniel', lastName: 'Walker' },
  { id: '17', firstName: 'Emma', lastName: 'Hall' },
  { id: '18', firstName: 'Jacob', lastName: 'King' },
  { id: '19', firstName: 'Emily', lastName: 'Wright' },
  { id: '20', firstName: 'Matthew', lastName: 'Scott' },
  { id: '21', firstName: 'Ella', lastName: 'Young' },
  { id: '22', firstName: 'David', lastName: 'Green' },
  { id: '23', firstName: 'Chloe', lastName: 'Baker' },
  { id: '24', firstName: 'Samuel', lastName: 'Nelson' },
  { id: '25', firstName: 'Harper', lastName: 'Carter' },
];

export const TIME_FRAMES: string[] = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
];
