import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultRegion: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface IRegion {
  name: string;
  id: string;
  disabled: boolean;
}

const UpdateRegionSelect: FC<Props> = (props: Props) => {
  const { entity, defaultRegion, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedRegion, setSelectedRegion] = useState<IRegion | undefined>();
  const [isLoadingRegions, setIsLoadingRegions] = useState<boolean>(false);
  const [allRegions, setAllRegions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (defaultRegion) {
      setSelectedRegion({
        name: defaultRegion?.name,
        id: defaultRegion?.id,
        disabled: false,
      });
    } else {
      setSelectedRegion(undefined);
    }
  }, [defaultRegion, sourceRecord]);

  useEffect(() => {
    loadAllRegions();
  }, []);

  const loadAllRegions = async () => {
    setIsLoadingRegions(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/regions?size=10000`);
      const regions = res.data?.data || [];
      setAllRegions(regions);
      setIsLoadingRegions(false);
    } catch (error) {
      setIsLoadingRegions(false);
    }
  };

  // Update Region when a different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultRegion?.id) {
      updateEntityWithRegion(item);
    }
  };

  const updateEntityWithRegion = async (selectedRegion: IRegion) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        regionId: selectedRegion.id === 'no-region' ? null : selectedRegion.id,
      });

      if (selectedRegion.id === 'no-region') {
        setSelectedRegion(undefined);
      } else {
        setSelectedRegion(selectedRegion);
      }

      setIsUpdating(false);
      onUpdate && onUpdate();
      alertMessage({
        body: `Region updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update region. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderRegion: ItemRenderer<IRegion> = (
    region,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (region.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more regions..."
        />
      );
    } else
      return (
        <MenuItem
          active={region.id === selectedRegion?.id}
          disabled={modifiers.disabled}
          key={region.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={region.name}
        />
      );
  };

  let REGIONS: IRegion[] = allRegions.map((f, index) => ({
    id: f.id,
    name: f.name,
    key: f.id,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    REGIONS = REGIONS.filter((r) => {
      return r.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  REGIONS = REGIONS.slice(0, 100);

  // Append the Search information if there are more than 100 items
  if (REGIONS.length === 100) {
    REGIONS.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Region" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    REGIONS.unshift({
      id: 'no-region',
      name: '(No Region)',
      disabled: false,
    });
  }

  return (
    <Select<IRegion>
      items={REGIONS}
      disabled={isLoadingRegions || allRegions.length === 0}
      itemRenderer={renderRegion}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedRegion ? 'globe' : null}
        alignText="left"
        disabled={isLoadingRegions || allRegions.length === 0 || isUpdating}
        loading={isLoadingRegions}
        text={selectedRegion?.name || 'Select region'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateRegionSelect);
