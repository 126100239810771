import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../core/navigation/ProtectedRoute';
import DetailView from '../../core/records/components/DetailViewContext';
import RecordListView from '../../core/records/components/ListView';
import DefaultDetailView from '../../v2/shared/views/DetailView';
import OutageList from './Outage/OutageList';

const { SERVICE_MODULE } = SchemaModuleTypeEnums;

export const ServiceModuleRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}`}
        moduleName={SERVICE_MODULE}
        entityName="Service"
        component={<RecordListView moduleName={SERVICE_MODULE} entityName="Service" />}
      />
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}/Outage`}
        moduleName={SERVICE_MODULE}
        entityName="Outage"
        component={<OutageList />}
      />
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}/:entityName`}
        moduleName={SERVICE_MODULE}
        component={<RecordListView moduleName={SERVICE_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${SERVICE_MODULE}/:entityName/:recordId`}
        moduleName={SERVICE_MODULE}
        component={
          <DetailView moduleName={SERVICE_MODULE}>
            <DefaultDetailView showCollaborators />
          </DetailView>
        }
      />
      ]
    </Switch>
  );
};
