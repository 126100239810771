import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Geolocation } from 'ol';
import { Coordinate } from 'ol/coordinate';
import Map from 'ol/Map';

import {
  RESET_MAP_SEARCH_QUERY,
  RESET_WORK_ITEM_FORM,
  RUN_WFS_QUERY_REQUEST,
  SEARCH_BY_NOI_REF_REQUEST,
  SEARCH_BY_POSTCODE_REQUEST,
  SET_MAP_SEARCH_QUERY,
  SET_WORK_ITEM_QUICKVIEW,
  UPDATE_MAP_COORDINATES_STATE,
  UPDATE_MAP_STATE,
  UPDATE_WORK_ITEMS,
} from './constants';
import { activeSidebarTab, selectionFiltering } from './reducer';
import { FEATURE_NAMES } from '../../../com.netomnia/modules/ProjectModule/Map/constants';
import { Feature } from '../../../com.netomnia/modules/ProjectModule/Map/interfaces/feature.interface';

export interface MapReducerUpdate {
  map?: Map | undefined;
  mapSidebarFilteringActive?: boolean;
  mapSidebarVisible?: boolean;
  mapSidebarSection?: activeSidebarTab;
  isFilter?: boolean;
  selectedFeature?: any;
  isRequesting?: boolean;
  mapCenter?: Coordinate | undefined;
  coordinates?: number[][];
  query?: string | undefined;
  drawEnabled?: boolean;
  addEnabled?: boolean;
  isAddingHazard?: boolean;
  addPointFlow?: boolean;
  addLineFlow?: boolean;
  featureSelectModal?: boolean;
  featureId?: number;
  buildStatusId?: number;
  featureName?: FEATURE_NAMES;
  shouldUpdate?: boolean;
  showCreateModal?: boolean;
  showUpdateModal?: boolean;
  isLoadingView?: boolean;
  isSearchingMap?: boolean;
  recordId?: string | undefined;
  features?: Feature[];
  feature?: Feature | undefined;
  entityName?: 'Feature' | 'Project' | undefined;
  isLocating?: boolean;
  geoEnabled?: boolean;
  geolocation?: Geolocation | undefined;
  record?: DbRecordEntityTransform | undefined;
  queryLayer?: string | undefined;
  addLineEnabled?: boolean;
  zoomLevel?: number | undefined;
  infoPopupVisible?: boolean;
  addPointConfirmVisible?: boolean;
  createLinked?: {
    fromType: FEATURE_NAMES | undefined;
    toType: FEATURE_NAMES | undefined;
    fromId: number | undefined;
  };
  inputFieldFeatureID?: string;
  inputFieldPostcode?: string;
  inputFieldNOIRef?: string;
  showOnHoldItems?: boolean;
  greyscaleMap?: boolean;
  mapSelectionFiltering?: selectionFiltering;
  bulkUpdateEnabled?: boolean;
  bulkUpdateDrawerVisible?: boolean;
  isCreatingRFC?: boolean;
  isRFCModalVisible?: boolean;
  creatingRFCFromIds?: string[];
  isRFCSelectFeatureTypeModalVisible?: boolean;
}

export interface MapSearch {
  featureIds: string;
}

export interface IMapSetWorkItemQuickView {
  record: DbRecordEntityTransform | undefined;
  visible: boolean;
}

export interface IMapUpdateWorkItems {
  quickViewRecord?: DbRecordEntityTransform | undefined;
  quickViewVisible?: boolean;
  workLists?: DbRecordEntityTransform[];
  selectedWorkList?: DbRecordEntityTransform | undefined;
  selectedProject?: DbRecordEntityTransform | undefined;
  workItems?: DbRecordEntityTransform[];
  workItemsForm?: any;
  creatingBlockageFromId?: string | undefined;
}

export interface IMapWFSQuery {
  typeName: string;
  featureId: string;
}

export function updateMapState(params: MapReducerUpdate) {
  return {
    type: UPDATE_MAP_STATE,
    params,
  };
}

export function updateMapCoordinatesState(params: MapReducerUpdate) {
  return {
    type: UPDATE_MAP_COORDINATES_STATE,
    params,
  };
}

export function setMapSearchQuery(params: MapSearch) {
  return {
    type: SET_MAP_SEARCH_QUERY,
    params,
  };
}

export function resetMapSearchQuery() {
  return {
    type: RESET_MAP_SEARCH_QUERY,
  };
}

export function setWorkItemQuickView(params: IMapSetWorkItemQuickView) {
  return {
    type: SET_WORK_ITEM_QUICKVIEW,
    params,
  };
}

export function updateWorkItems(params: IMapUpdateWorkItems) {
  return {
    type: UPDATE_WORK_ITEMS,
    params,
  };
}

export function resetWorkItemForm() {
  return {
    type: RESET_WORK_ITEM_FORM,
  };
}

export function WFSRequest(params: IMapWFSQuery, cb: any) {
  return {
    type: RUN_WFS_QUERY_REQUEST,
    params,
    cb,
  };
}

export function searchByPostcode(params: { searchQuery: string }, cb: any) {
  return {
    type: SEARCH_BY_POSTCODE_REQUEST,
    params,
    cb,
  };
}

export function searchByNOIRef(params: { searchQuery: string }, cb: any) {
  return {
    type: SEARCH_BY_NOI_REF_REQUEST,
    params,
    cb,
  };
}
