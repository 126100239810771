import { Button, InputGroup, Section, Tag } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../../shared/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { searchString } from '../../../../../../shared/utilities/searchHelpers';
import { BlueprintNavigation } from '../../../../../../v2/shared/components/BlueprintPagination';
import * as formFields from '../FormFields';
import UserDetails from '../UserDetails';
import { getRecordIdFromHash } from '../../../../utils/hashNavigation';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string;
  firstname: string;
  lastname: string;
  email: string;
  status: any;
}

const uuid = uuidv4();

const UserListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<OrganizationUserEntity | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [usersList, setUsersList] = useState<OrganizationUserEntity[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = useRef<any>();

  useEffect(() => {
    const id = getRecordIdFromHash(window.location.hash);
    if (id) getUserById(id);
  }, [window.location.hash]);

  const onSelect = (e: any) => {
    setSelectedRegions([
      {
        cols: [0, 3],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    getAllUsersList();
  }, []);

  useEffect(() => {
    setSelectedRegions([]);
    setSelectedUser(undefined);
  }, [currentPage]);

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedUser, tableRef, tableSlice]);

  useEffect(() => {
    if (usersList.length > 0) {
      let tableData: ITableData[] = Object.assign([], usersList);

      tableData = usersList.map((user: any) => ({
        key: user.id,
        firstname: user.firstname,
        lastname: String(user.lastname),
        email: String(user.email),
        status: String(user.status),
      }));

      // Apply search
      tableData = tableData.filter((user: any) => {
        return (
          searchString(`${user.firstname} ${user.lastname}`, searchKey) ||
          searchString(user.email, searchKey)
        );
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [usersList, currentPage, searchKey, pageSize]);

  // Get selected user details
  useEffect(() => {
    if (selectedRegions.length > 0 && tableSlice.length > 0) {
      const user: any = tableSlice[selectedRegions[0].rows[0]];
      if (user && !isUserLoading) {
        getUserById(user.key);
      }
    }
  }, [selectedRegions, tableSlice]);

  const getUserById = (id: string) => {
    setIsUserLoading(true);
    httpGet(`IdentityModule/v2.0/users/${id}`)
      .then((res) => {
        setIsUserLoading(false);
        setSelectedUser(res.data.data);
        console.log('%cdebug: Selected user', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({ body: 'Could not retrieve user. ' + message, type: 'error' });
        setIsUserLoading(false);
        setSelectedUser(undefined);
        setSelectedRegions([]);
      });
  };

  const getAllUsersList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v2.0/users/?size=10000`);
      const users: OrganizationUserEntity[] = res.data.data || [];
      setUsersList(users);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve users list. ' + message, type: 'error' });
      setUsersList([]);
    }
  };

  const showCreateUserForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create User',
      formFields: formFields.formFields,
      entityName: 'User',
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        firstname: params.data.firstname,
        lastname: params.data.lastname,
        email: params.data.email,
        password: params.data.password,
      };
      createUser({ body });
    }
  };

  const createUser = async (params: any) => {
    const { body } = params;
    try {
      let res = await httpPost('IdentityModule/v1.0/users', body);
      const newUser = res.data.data;
      setUsersList([...usersList, newUser]);
      getUserById(newUser.id);

      alertMessage({ body: 'User Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not create user. ' + message, type: 'error' });
    }
  };

  const onSearch = (e: any) => {
    setSelectedRegions([]);
    setSearchKey(e.target.value);
  };

  const onSelectedUserChange = () => {
    if (selectedUser) {
      getUserById(selectedUser.id);
    }
  };

  const onUserDelete = () => {
    setSelectedRegions([]);

    const id = selectedUser?.id;
    if (id) {
      setUsersList(usersList.filter((u) => u.id !== id));
      setSelectedUser(undefined);
    }
  };

  const renderUsers = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Users"
                  onChange={onSearch}
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreateUserForm}>
                  Create User
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedUser ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(20),
                getColumnWidthByPercentage(40),
                getColumnWidthByPercentage(20),
              ]}
            >
              <Column
                key="firstName"
                name="First Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].firstname}</Cell>
                )}
              />
              <Column
                key="lastName"
                name="Last name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].lastname}</Cell>
                )}
              />
              <Column
                key="email"
                name="Email"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].email}</Cell>
                )}
              />
              <Column
                key="status"
                name="Status"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>
                    {tableSlice[rowIndex].status === 'ACTIVE' ? (
                      <Tag
                        key={tableSlice[rowIndex].key}
                        intent="success"
                        round
                        minimal
                        style={{ verticalAlign: 'middle' }}
                      >
                        ACTIVE
                      </Tag>
                    ) : (
                      <Tag
                        key={tableSlice[rowIndex].key}
                        intent="warning"
                        round
                        minimal
                        style={{ verticalAlign: 'middle' }}
                      >
                        INACTIVE
                      </Tag>
                    )}
                  </Cell>
                )}
              />
            </Table2>
          </Col>

          {/* User Details */}
          {selectedUser && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isUserLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="User Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedRegions([]);
                      setSelectedUser(undefined);
                    }}
                  />
                }
              >
                <UserDetails
                  user={selectedUser}
                  onUpdate={onSelectedUserChange}
                  onDelete={onUserDelete}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={usersList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderUsers()}</div>;
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UserListView);
