import React from 'react';
import { connect } from 'react-redux';
import { Layout, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { logoutRequest } from '../../core/identity/store/actions';

const { Content, Header } = Layout;

interface Props {
  logout: Function;
}

const AppLoadingPage: React.FC = () => {
  return (
    <div className="loading-page">
      <Spin size="large" />
    </div>
  );
};

const mapDispatch = (dispatch: any) => ({
  logout: () => dispatch(logoutRequest()),
});

const mapState = (state: any) => ({});

export default withRouter(connect(mapState, mapDispatch)(AppLoadingPage));
