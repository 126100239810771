import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { useAction } from '../../../../../containers/ProjectModule/hooks/useAction';
import { useRequest } from '../../../../../containers/ProjectModule/hooks/useRequest';
import { setWorkItemQuickView } from '../../../../../core/gis/store/actions';
import {
  getRecordByIdRequest,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import { getSchemaByModuleAndEntityRequest } from '../../../../../core/schemas/store/actions';
import { httpGet } from '../../../../../shared/http/requests';
import { preprocessData } from '../utils';
import { FetchOneActions } from './fetchStates';

import {
  DashboardData,
  DashboardLineItem,
  FetchManyActions,
  SubmissionActions,
  SubmissionStatus,
  TableActions,
} from './types';
import { VendorDashboardView } from '.';

export const useVendorDashboardService = ({
  exchangesActions,
  lineItemsActions,
  tableActions,
  submissionActions,
}: {
  exchangesActions: FetchManyActions;
  lineItemsActions: FetchOneActions<DashboardData>;
  tableActions: TableActions;
  submissionActions: SubmissionActions;
}) => {
  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);
  const getRecordById = useRequest(getRecordByIdRequest);
  const updateRecord = useRequest(updateRecordByIdRequest);
  const setFeatureQuickView = useAction(setWorkItemQuickView);

  const fetchExchanges = async () => {
    try {
      exchangesActions.setStatus('loading');
      const response = await httpGet(`ProjectModule/v1.0/ApplicationForPayment/exchanges`);
      const data: DbRecordEntityTransform[] = response.data.data;
      data.sort((a, b) => {
        const exchangeNameA = getProperty(a, 'ExchangeName') ?? '';
        const exchangeNameB = getProperty(b, 'ExchangeName') ?? '';

        return exchangeNameA.localeCompare(exchangeNameB);
      });
      exchangesActions.setData(data);
      exchangesActions.setStatus('loaded');
      return data;
    } catch (error) {
      exchangesActions.setStatus('error');
      exchangesActions.setData([]);
    }
  };

  const fetchExchangeLineItems = async (exchangeId: string, filter: VendorDashboardView) => {
    try {
      lineItemsActions.setStatus('loading');
      const response = await httpGet(
        `ProjectModule/v1.0/ApplicationForPayment/billing-overview?exPolygonId=${exchangeId}&filter=${filter}`,
      );
      const data = preprocessData(response.data.data);

      // Automatically selecting resubmittable entries
      const notSubmitted = data.lineItems.filter((li) => {
        return (
          li.status === 'Not Submitted' &&
          li.is_approved === 'false' &&
          li.rejection_response_comment
        );
      });

      if (notSubmitted.length) {
        lineItemsActions.setSelectedLineItems((selectedLineItems) => {
          const currentlySelectedTitles = selectedLineItems.map((li) => li.fpe_title);
          const newLineItemsToSelect = notSubmitted.filter(
            (li) => !currentlySelectedTitles.includes(li.fpe_title),
          );
          return [...selectedLineItems, ...newLineItemsToSelect];
        });
      }

      lineItemsActions.setData(data);
      lineItemsActions.setStatus('loaded');
      return data;
    } catch (error) {
      lineItemsActions.setStatus('error');
      lineItemsActions.setData(undefined);
    }
  };

  const updateRejectionResponseCommentToResubmit = async (
    fpeId: string,
    rejection_response_comment: string,
  ) => {
    try {
      tableActions.setTableLoading(true);
      const schema = await getSchema({
        moduleName: 'ProjectModule',
        entityName: 'FeaturePriceEntry',
      });
      const updated = await updateRecord({
        schema,
        recordId: fpeId,
        createUpdate: {
          entity: 'ProjectModule:FeaturePriceEntry',
          type: 'DEFAULT',
          properties: {
            RejectionResponseComment: rejection_response_comment,
            RejectionResponseDate: dayjs().format('YYYY-MM-DD'),
          },
        },
      });

      // Update the rejected line item with the rejection_response_comment
      // and add a new unsubmitted line with the same data
      lineItemsActions.setData((data) => {
        if (!data) return undefined;

        const updatedLineItems = data.lineItems.map((li) =>
          // adding parent_fpe_id to the editted line item so that when resubmitting,
          // the newly created one will reference the right parent FPE
          li.fpe_id === fpeId && li.is_approved === 'false'
            ? { ...li, rejection_response_comment, parent_fpe_id: fpeId, status: 'Not Submitted' }
            : li,
        );

        const updatedLineItem = data.lineItems.find((li) => li.fpe_id === fpeId);

        if (updatedLineItem) {
          lineItemsActions.setSelectedLineItems((selectedLineItems) => {
            const selectedLineItemTitles = selectedLineItems.map((li) => li.fpe_title);
            if (selectedLineItemTitles.includes(updatedLineItem.fpe_title))
              return selectedLineItems;

            return [...selectedLineItems, updatedLineItem];
          });
        }

        return {
          ...data,
          lineItems: updatedLineItems,
        };
      });
      tableActions.setTableLoading(false);
      return updated;
    } catch (error) {
      tableActions.setTableLoading(false);
    }
  };

  const openFeatureQuickView = async (
    featureId: string,
    featureType: string,
    qgisFeatureId: string,
  ) => {
    try {
      tableActions.setTableLoading(true);
      let recordId = featureId;
      if (!recordId) {
        const result = await httpGet(
          `ProjectModule/v1.0/cst/Feature/${featureType.toLocaleLowerCase()}/${qgisFeatureId}`,
        );
        recordId = result.data.data.id;
      }
      const schema = await getSchema({ moduleName: 'ProjectModule', entityName: 'Feature' });
      const feature = await getRecordById({
        schema,
        recordId,
      });

      setFeatureQuickView({
        record: feature,
        visible: true,
      });
      tableActions.setTableLoading(false);
    } catch (err) {
      tableActions.setTableLoading(false);
    }
  };

  const exportCsv = async (tableData: DashboardLineItem[], fileName = 'exchange-data') => {
    const csvData = Papa.unparse(tableData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return {
    fetchExchanges,
    fetchExchangeLineItems,
    selectExchange: exchangesActions.setSelected,
    setLineItemFilter: lineItemsActions.setFilter,
    resetLineItemFilters: lineItemsActions.resetFilters,
    updateRejectionResponseComment: updateRejectionResponseCommentToResubmit,
    openFeatureQuickView,
    setSelectedLineItems: lineItemsActions.setSelectedLineItems,
    updateUnsubmittedLineItemByTitle: lineItemsActions.updateUnsubmittedLineItemByTitle,
    exportCsv,
    startSubmission: () => submissionActions.setStatus('submitting'),
    closeSubmissionDrawer: () =>
      submissionActions.setStatus((prevStatus) => {
        if (prevStatus === 'submitting') return 'submitting';

        return 'idle';
      }),
    setSubmissionStatus: (status: SubmissionStatus) => {
      submissionActions.setStatus(status);
    },
    setSelectedView: tableActions.setSelectedView,
  };
};
