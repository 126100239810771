import { Button, Dialog, DialogBody, DialogFooter, Icon, Tag, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Descriptions, Empty, Row, Skeleton, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddProduct from '../../../../../containers/ProductModule/AddProduct';
import ReplaceOrderItemProduct from '../../../../../containers/ProductModule/ReplaceOrderItemProduct';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { deleteRecordByIdRequest } from '../../../../../core/records/store/actions';
import {
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociationWithNestedEntities,
} from '../../../../../core/recordsAssociations/store/actions';
import {
  IOpenRecordDrawer,
  openProductReplacementDrawer,
  openRecordDrawer,
} from '../../../../../core/userInterface/store/actions';
import { IOpenProductReplaceDrawer } from '../../../../../core/userInterface/store/types';
import { getOdinSchemaByEntity } from '../../../../../shared/utilities/schemaHelpers';
import Typography from '../../../../../v2/shared/core/Typography';
import './styles.scss';
import dayjs from 'dayjs';

interface Props {
  record: DbRecordEntityTransform;
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) => void;
  openReplacementDrawer: (payload: IOpenProductReplaceDrawer) => void;
  deleteRecord: (payload: any, cb: any) => void;
  orderItemsUpdated?: any;
  sendConfirmation: (payload: any) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER, ORDER_ITEM, OFFER, PRODUCT, DISCOUNT } = SchemaModuleEntityTypeEnums;

const OrderItemsList: FC<Props> = (props: Props) => {
  const {
    record,
    getNestedAssociations,
    openReplacementDrawer,
    deleteRecord,
    orderItemsUpdated,
    sendConfirmation,
    openDrawer,
  } = props;

  const [isLoadingOrderItems, setIsLoadingOrderItems] = useState<boolean>(false);
  const [isRefreshingOrderItems, setIsRefreshingOrderItems] = useState<boolean>(false);
  const [orderItemsList, setOrderItemsList] = useState<DbRecordEntityTransform[]>([]);
  const [orderSchema, setOrderSchema] = useState<SchemaEntity | undefined>(undefined);
  const [orderItemSchema, setOrderItemSchema] = useState<SchemaEntity | undefined>(undefined);
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(true);
  const [recordToRemove, setRecordToRemove] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);

  // On component mount, get ORDER and ORDER_ITEM schema
  useEffect(() => {
    getSchemas();
  }, []);

  const getSchemas = async () => {
    const OSchema = await getOdinSchemaByEntity(ORDER_MODULE, ORDER);
    setOrderSchema(OSchema);

    const OISchema = await getOdinSchemaByEntity(ORDER_MODULE, ORDER_ITEM);
    setOrderItemSchema(OISchema);
  };

  // When order schema is available, fetch the Order items
  useEffect(() => {
    if (orderSchema && record) {
      setIsLoadingOrderItems(true);
      getOrderItems();
    }
  }, [orderSchema, record]);

  const getOrderItems = () => {
    if (record && orderSchema) {
      getNestedAssociations(
        {
          recordId: record?.id,
          key: ORDER,
          schema: orderSchema,
          entity: ORDER_ITEM,
          nestedEntities: [OFFER, PRODUCT, DISCOUNT],
        },
        (response: any) => {
          setIsLoadingOrderItems(false);
          setIsRefreshingOrderItems(false);

          const orderItems = response.results[ORDER_ITEM]?.dbRecords;

          if (orderItems?.length! > 0) {
            // Put the base products at the top of the list
            let sortedOrderItems = Object.assign(orderItems);

            sortedOrderItems.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
              const aProductType = getProperty(a, 'ProductType');
              const bProductType = getProperty(b, 'ProductType');
              if (aProductType === 'BASE_PRODUCT' && bProductType !== 'BASE_PRODUCT') {
                return -1;
              } else if (aProductType !== 'BASE_PRODUCT' && bProductType === 'BASE_PRODUCT') {
                return 1;
              } else {
                return 0;
              }
            });

            setOrderItemsList(sortedOrderItems);
          }
        },
      );
    }
  };

  const renderProductTrialAndDiscountInfo = (record: DbRecordEntityTransform) => {
    const trialLength = getProperty(record, 'TrialLength');

    const discount1Value = Number(getProperty(record, 'DiscountValue'));
    const discount1Type = getProperty(record, 'DiscountType');
    const discount1Length = getProperty(record, 'DiscountLength');

    const discount2Value = Number(getProperty(record, 'Discount2Value'));
    const discount2Type = getProperty(record, 'Discount2Type');
    const discount2Length = getProperty(record, 'Discount2Length');

    let trialDiscountText: string = '';

    // Free period available
    if (record && trialLength > 0) {
      trialDiscountText = `Product is free for ${trialLength} ${
        trialLength > 1 ? 'months' : 'month'
      }. `;
    }

    // Discount 1 available
    if (record && Number(discount1Value) > 0) {
      trialDiscountText += `Product is ${trialLength > 0 ? 'then ' : ''}discounted ${
        discount1Type === 'AMOUNT' ? '£' : ''
      }${discount1Value}${discount1Type === 'PERCENT' ? '%' : ''} for ${discount1Length} ${
        discount1Length > 1 ? 'months' : 'month'
      }.`;
    }

    // Discount 2 available
    if (record && Number(discount2Value) > 0) {
      trialDiscountText += `${
        trialLength === 0 && discount1Length === 0 ? 'Product is ' : ' After that, product is '
      }discounted ${discount2Type === 'AMOUNT' ? '£' : ''}${discount2Value}${
        discount2Type === 'PERCENT' ? '%' : ''
      } for ${discount2Length} ${discount2Length > 1 ? 'months' : 'month'}.`;
    }

    if (trialDiscountText.length > 0) {
      return (
        <Tooltip content={trialDiscountText} position="top">
          <Icon
            size={14}
            icon="info-sign"
            intent="primary"
            style={{ marginRight: 5, cursor: 'pointer' }}
          />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  const showReplacementDrawer = (
    order: DbRecordEntityTransform,
    orderItem: DbRecordEntityTransform,
  ) => {
    if (order && orderItem) {
      openReplacementDrawer({
        parentRecord: record,
        orderItemRecord: orderItem,
      });
    }
  };

  const deleteOrderItem = () => {
    if (recordToRemove && orderItemSchema) {
      deleteRecord(
        {
          schema: orderItemSchema,
          recordId: recordToRemove.id,
        },
        () => {
          if (notifyCustomer) {
            sendConfirmation(
              `OrderModule/v1.0/orders/${
                record ? record.id : null
              }/email/SENDGRID_ORDER_CONFIRMATION_V2`,
            );
          }
          closeRemoveModal();
          orderItemsUpdated && orderItemsUpdated();
          getOrderItems();
        },
      );
    }
  };

  const updateOrderItems = () => {
    setIsRefreshingOrderItems(true);
    getOrderItems();

    // Callback to parent component
    orderItemsUpdated && orderItemsUpdated();
  };

  const closeRemoveModal = () => {
    setRemoveModalVisible(false);
    setRecordToRemove(undefined);
    setNotifyCustomer(true);
  };

  const openRemoveModal = (record: DbRecordEntityTransform) => {
    setRecordToRemove(record);
    setRemoveModalVisible(true);
    setNotifyCustomer(true);
  };

  const renderOrderItems = () => {
    if (orderItemsList.length > 0) {
      return orderItemsList.map((orderItem: DbRecordEntityTransform, i: number) => {
        const offer = getFirstRelation(orderItem, OFFER);

        return (
          <>
            <Row className="orderItemsListItemRow" align="top">
              <Col span={3}>
                <Typography size="small">{orderItem.recordNumber}</Typography>
              </Col>
              <Col span={8}>
                <Typography stronger>
                  {/* Discount information */}
                  {renderProductTrialAndDiscountInfo(orderItem)}
                  {/* OI Title */}
                  {orderItem?.title}
                </Typography>
                <br />

                {/* Offer title */}
                <Typography size="small">{offer && offer.title}</Typography>

                {/* Recurring Billing Information */}
                {getProperty(orderItem, 'NextInvoiceDate') && (
                  <>
                    <br />
                    <Tag
                      intent="success"
                      minimal
                      style={{ marginTop: 1, padding: '1px 5px', borderRadius: 3 }}
                    >
                      Next invoice date:{' '}
                      {dayjs(getProperty(orderItem, 'NextInvoiceDate')).format('DD/MM/YYYY')}
                    </Tag>
                  </>
                )}
              </Col>
              <Col span={1} style={{ textAlign: 'center' }}>
                <Typography>{getProperty(orderItem, 'Quantity')}</Typography>
              </Col>
              <Col span={3} style={{ textAlign: 'center' }}>
                <Typography>£{getProperty(orderItem, 'UnitPrice')}</Typography>
              </Col>
              <Col span={3} style={{ textAlign: 'center' }}>
                <Typography>£{getProperty(orderItem, 'TotalDiscounts')}</Typography>
              </Col>
              <Col span={2} style={{ textAlign: 'center' }}>
                <Typography>£{getProperty(orderItem, 'TotalPrice')}</Typography>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <>
                  <Tooltip content="View product" hoverOpenDelay={800}>
                    <Button
                      style={{ marginRight: 6 }}
                      outlined
                      icon={<Icon icon="eye-open" />}
                      small
                      onClick={() =>
                        openDrawer({
                          recordId: orderItem?.id || '',
                          moduleName: ORDER_MODULE,
                          entityName: ORDER_ITEM,
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip content="Replace product" hoverOpenDelay={800}>
                    <Button
                      style={{ marginRight: 6 }}
                      outlined
                      small
                      icon={<Icon icon="changes" />}
                      onClick={() => showReplacementDrawer(record, orderItem)}
                    />
                  </Tooltip>
                  <Tooltip content="Remove product" hoverOpenDelay={800}>
                    <Button
                      intent="danger"
                      outlined
                      small
                      icon={<Icon icon="trash" />}
                      onClick={() => openRemoveModal(orderItem)}
                    />
                  </Tooltip>
                </>
              </Col>
            </Row>
          </>
        );
      });
    }
  };

  const renderOrderItemSummary = () => {
    return (
      <Col span={24} style={{ padding: 5 }}>
        <Descriptions
          bordered
          size="small"
          column={1}
          labelStyle={{ fontWeight: 500, color: 'black' }}
        >
          <Descriptions.Item label="Subtotal" labelStyle={{ fontWeight: 'normal', width: '70%' }}>
            {getProperty(record, 'Subtotal') || '0.00'}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Discounts"
            labelStyle={{ fontWeight: 'normal', width: '70%' }}
          >
            {getProperty(record, 'TotalDiscounts') || '0.00'}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Tax Amount"
            labelStyle={{ fontWeight: 'normal', width: '70%' }}
          >
            {getProperty(record, 'TotalTaxAmount') || '0.00'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={1}
          style={{ marginTop: 20 }}
          labelStyle={{ fontWeight: 700, color: 'black' }}
        >
          <Descriptions.Item
            label="Total Price"
            labelStyle={{ width: '70%' }}
            contentStyle={{ fontWeight: 700 }}
          >
            {getProperty(record, 'TotalPrice') || '0.00'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    );
  };

  return (
    <Row style={{ opacity: isRefreshingOrderItems ? 0.3 : 1 }}>
      <ReplaceOrderItemProduct onSuccess={updateOrderItems} />
      <AddProduct onSuccess={updateOrderItems} />

      {/* Loader */}
      {isLoadingOrderItems && (
        <Col span={24}>
          <Skeleton />
        </Col>
      )}

      {/* Empty list */}
      {!isLoadingOrderItems && orderItemsList && orderItemsList.length === 0 && (
        <Col span={24}>
          <Empty description="There are no products on this order" />
        </Col>
      )}

      {/* Order items list */}
      {!isLoadingOrderItems && orderItemsList && orderItemsList.length > 0 && (
        <Col span={24}>
          <Row className="orderItemsListHeader">
            <Col span={3}>
              <Typography stronger>#</Typography>
            </Col>
            <Col span={8}>
              <Typography stronger>Item</Typography>
            </Col>
            <Col span={1} style={{ textAlign: 'center' }}>
              <Typography stronger>Qty</Typography>
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
              <Typography stronger>Unit Price</Typography>
            </Col>
            <Col span={3} style={{ textAlign: 'center' }}>
              <Typography stronger>Discounts</Typography>
            </Col>
            <Col span={2} style={{ textAlign: 'center' }}>
              <Typography stronger>Total</Typography>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Typography stronger>Actions</Typography>
            </Col>
          </Row>
          {renderOrderItems()}
        </Col>
      )}

      {/* Remove Order Item Modal */}
      <Dialog
        title={`Removing ${recordToRemove?.title} Product`}
        isOpen={removeModalVisible}
        onClose={closeRemoveModal}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
      >
        <DialogBody>
          <Row>
            <Col span={24}>
              <p>
                You are about to remove a product from an order, this will not delete the product
                itself. If you want, you can choose not to send the new order confirmation email to
                the customer.
              </p>
            </Col>

            <Col span={24} style={{ marginTop: 5 }}>
              <Switch
                size="small"
                style={{ marginRight: 8 }}
                checked={notifyCustomer}
                onChange={() => setNotifyCustomer(!notifyCustomer)}
              />
              <span>Send an Email</span>
            </Col>
          </Row>
        </DialogBody>
        <DialogFooter
          actions={
            <>
              <Button text="Cancel" onClick={() => closeRemoveModal()} />
              <Button intent="danger" text="Delete" onClick={() => deleteOrderItem()} />
            </>
          }
        ></DialogFooter>
      </Dialog>

      {/* Discount */}
      {record && (
        <Col span={24} style={{ padding: 5 }}>
          <RecordCard
            record={record}
            showActionMenu
            showItemActionMenu
            propertyColumns={2}
            displayQuickView
            associatedRecordEntityName={'Discount'}
            associatedRecordModuleName={'ProductModule'}
            visibleProperties={['DiscountLength', 'DiscountType', 'DiscountValue', 'Description']}
          />
        </Col>
      )}

      {/* Order items list */}
      {!isLoadingOrderItems &&
        orderItemsList &&
        record &&
        orderItemsList.length > 0 &&
        renderOrderItemSummary()}
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
  openReplacementDrawer: (payload: IOpenProductReplaceDrawer) =>
    dispatch(openProductReplacementDrawer(payload)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(OrderItemsList);
