import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import dayjs from 'dayjs';
import { httpPost } from '../../../../../../../shared/http/requests';

export type UpsertSlotProperties = {
  AMQuantity: number;
  PMQuantity: number;
};

const getWeekDayProperty = (date: string) => {
  const weekDayKeys = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const weekDay = dayjs(date).day();
  const key = weekDayKeys[weekDay];

  return {
    [key]: true,
  };
};

export const upsertSlot = async (
  scheduleId: string,
  date: string,
  values: UpsertSlotProperties,
) => {
  const properties = {
    ...values,
    ScheduleId: scheduleId,
    StartDate: date,
    EndDate: date,
    ...getWeekDayProperty(date),
  };

  const slotToUpsert: DbRecordCreateUpdateDto = {
    entity: 'FieldServiceModule:ScheduleSlot',
    type: 'DEFAULT',
    title: 'Odin Managed',
    properties,
  };

  const response = await httpPost('FieldServiceModule/v1.0/db/bulk-upsert', {
    recordsToUpsert: [slotToUpsert],
  });

  return response.data.data;
};
