import { useSelector } from "react-redux";
import { Icon, InputGroup, Section, SectionCard } from "@blueprintjs/core"

import { SchemaAssociationEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity";

import { SchemaAssociationReducer } from "../../../../../../../core/schemasAssociations/store/reducer";
import { getSchemaAssociationsRequest } from "../../../../../../../core/schemasAssociations/store/actions";

import { useRequest } from "../../../../../../ProjectModule/hooks/useRequest";

import { SchemaDetailsProps } from "../types";
import { Empty } from "../Empty";
import { useEffect, useState } from "react";
import { ListView } from "./ListView";
import { DiagramView } from "./DiagramView";
import ManageLinkDialog from "./ManageLinkDialog";


export const LinksSection = ({ schema }: SchemaDetailsProps) => {
  const [viewMode, setViewMode] = useState<'list' | 'diagram'>('diagram');
  const [search, setSearch] = useState('');
  const [manageLinks, setManageLinks] = useState(false);

  const [selectedLink, setSelectedLink] = useState<SchemaAssociationEntity>();

  const schemaAssociations = useSelector<any, SchemaAssociationReducer>(
    (state: any) => state.schemaAssociationReducer
  );

  const getSchemaAssociations = useRequest(getSchemaAssociationsRequest);

  useEffect(() => {
    if (!schema?.id) return;

    getSchemaAssociations({ schemaId: schema.id })
  }, [schema?.id]);
  
  const { isRequesting, childAssociations, parentAssociations } = schemaAssociations;
  const associations = [...childAssociations, ...parentAssociations].filter(association => {
    const labelFilter = association.label.toLocaleLowerCase().includes(search);
    const parentEntityFilter = association.parentSchema?.entityName?.toLocaleLowerCase()?.includes(search);
    const childEntityFilter = association.childSchema?.entityName?.toLocaleLowerCase()?.includes(search);

    return labelFilter || parentEntityFilter || childEntityFilter;
  });

  const showLinkDialog = (link?: SchemaAssociationEntity) => {
    setSelectedLink(link);
    setManageLinks(true);
  }

  return (
    <>
      <Section
        className="links-section"
        title="Links"
        rightElement={
          <>
            {/* Search Links */}
            <InputGroup
              id="schema-properties"
              type="text"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            {/* List View Option */}
            <button
              className={`option-button ${viewMode === 'list' ? 'active' : ''}`}
              onClick={() => setViewMode('list')}
            >
              <Icon icon="list" />
            </button>

            {/* Diagram View Option */}
            <button
              className={`option-button ${viewMode === 'diagram' ? 'active' : ''}`}
              onClick={() => setViewMode('diagram')}
            >
              <Icon icon="many-to-one" />
            </button>

            {/* New Link Option */}
            <button
              className="link-button"
              onClick={() => showLinkDialog()}
            >
              <Icon icon="add" /> New
            </button>
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {isRequesting && <></>}
          {associations.length === 0 ? (
            <Empty icon="link" text="No links defined yet" />
          ) : (
            viewMode === 'list'
              ? <ListView links={associations} onLinkClick={showLinkDialog} />
              : <DiagramView schema={schema} links={associations} onLinkClick={showLinkDialog} />
          )}
        </SectionCard>
      </Section>
      {manageLinks && (
        <ManageLinkDialog
          schema={schema}
          link={selectedLink}
          isOpen={manageLinks}
          onClose={() => setManageLinks(false)}
        />
      )}
    </>
  );
}
