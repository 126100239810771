import { Button, EntityTitle, Icon, NonIdealState, Spinner, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../..';
import { MY_CASES_SET_SELECTED_CASE } from '../../store/constants';
import CommunicationsIcon from '../CommunicationsIcon';
import CommunicationsStatusTag from '../CommunicationsStatusTag';

interface Props {}

const sanitizeMessageTitle = (title: string) => {
  const maxLength = 14;
  if (title.length > maxLength) {
    return `${title.slice(0, maxLength)}...`;
  } else {
    return title;
  }
};

// During development we would use dummy data, so cases that were not OPEN we would render
// differently. This might differ when we have real data, so we would need to update this.
const CommunicationsHistory: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);

  const isCaseClosed = (c: DbRecordEntityTransform) => {
    return c.stage?.name === 'Closed' || !c.stage?.name;
  };

  const renderCases = () => {
    // Loading History
    if (state.isLoadingSelectedCaseContact_Cases) {
      return (
        <Col span={24} style={{ marginTop: 110 }}>
          <NonIdealState icon={<Spinner size={28} />} description="Loading History" />
        </Col>
      );
    }
    // No history found
    else if (
      !state.isLoadingSelectedCaseContact_Cases &&
      state.selectedCaseContact_Cases.length === 0
    ) {
      return (
        <Col span={24} style={{ marginTop: 110 }}>
          <NonIdealState icon={<Icon icon="search" size={28} />} description="No History" />
        </Col>
      );
    }
    // Render history
    else {
      return state.selectedCaseContact_Cases.map((c: DbRecordEntityTransform, i: number) => {
        return (
          <>
            <Col span={1} key={c.id + 1}>
              <Row align="middle" style={{ height: '100%' }} key={c.id + 2}>
                {/* Top Vertical Line */}
                <Col span={24} style={{ height: '39%', textAlign: 'center' }} key={c.id + 3}>
                  <div
                    key={c.id + 4}
                    style={{
                      borderLeft: i === 0 ? 'none' : '1px dashed #C0C4C7',
                      height: '100%',
                      marginLeft: 3,
                    }}
                  />
                </Col>

                {/* Bullet */}
                <Col span={24} style={{ height: '6%', textAlign: 'center' }} key={c.id + 5}>
                  <div
                    key={c.id + 6}
                    style={{
                      height: 7,
                      width: 7,
                      border: '1px solid',
                      borderColor: isCaseClosed(c) ? '#C0C4C7' : 'black',
                      background: isCaseClosed(c) ? 'white' : 'black',
                      borderRadius: 50,
                    }}
                  />
                </Col>

                {/* Bottom Vertical Line */}
                <Col
                  span={24}
                  key={c.id + 7}
                  style={{
                    height: '45%',
                    textAlign: 'center',
                  }}
                >
                  <div
                    key={c.id + 8}
                    style={{
                      marginLeft: 3,
                      borderLeft:
                        i === state.selectedCaseContact_Cases.length - 1
                          ? 'none'
                          : '1px dashed #C0C4C7',
                      height: '100%',
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              span={23}
              key={c.id + 9}
              style={{
                border: !isCaseClosed(c) ? '1px solid #E6E7E8' : '1px solid white',
                borderRadius: 10,
                marginBottom: i !== state.selectedCaseContact_Cases.length - 1 ? 10 : 0,
              }}
            >
              <Row key={c.id + 10}>
                <Col span={24} style={{ padding: 10 }} key={c.id + 11}>
                  <Row justify="space-between" key={c.id + 12}>
                    <Col span={14} key={c.id + 13}>
                      <EntityTitle
                        key={c.id + 14}
                        title={sanitizeMessageTitle(c.title || 'Unknown')}
                        subtitle={c.contact}
                        icon={<CommunicationsIcon small type={c.stage?.name || 'Unknown'} />}
                      />
                    </Col>
                    <Col key={c.id + 15}>
                      <CommunicationsStatusTag status={c.stage?.name} key={c.id + 16} />
                    </Col>
                  </Row>
                </Col>
                {!isCaseClosed(c) && (
                  <Col
                    span={24}
                    style={{ borderTop: '1px solid #E6E7E8', padding: 10 }}
                    key={c.id + 17}
                  >
                    <Row key={c.id + 18}>
                      <Col span={18} key={c.id + 19}>
                        <Row key={c.id + 20}>
                          <Col span={24} key={c.id + 21}>
                            <span key={c.id + 22} style={{ opacity: 0.5, fontSize: '0.9em' }}>
                              Assigned:
                            </span>{' '}
                            <span key={c.id + 23} style={{ fontSize: '0.9em' }}>
                              {getProperty(c, 'OwnerId')}
                            </span>
                          </Col>
                          <Col span={24} key={c.id + 24}>
                            <span key={c.id + 25} style={{ opacity: 0.5, fontSize: '0.9em' }}>
                              Category:
                            </span>{' '}
                            <span key={c.id + 26} style={{ fontSize: '0.9em' }}>
                              {getProperty(c, 'Category') || 'Unknown'}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6} style={{ textAlign: 'right' }} key={c.id + 27}>
                        <Tooltip
                          key={c.id + 28}
                          fill
                          disabled={c.id !== state.selectedCase?.id}
                          content="You are already viewing this case"
                          hoverOpenDelay={1000}
                        >
                          <Button
                            key={c.id + 29}
                            outlined
                            small
                            style={{ borderRadius: 50 }}
                            onClick={() => {
                              dispatch({
                                type: MY_CASES_SET_SELECTED_CASE,
                                payload: c,
                              });
                            }}
                            disabled={c.id === state.selectedCase?.id}
                          >
                            View
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </>
        );
      });
    }
  };

  return <Row style={{ paddingRight: 5 }}>{renderCases()}</Row>;
};
export default CommunicationsHistory;
