import { Button, TagInput, TextArea } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { MyCasesContext } from '../../..';
import { getBrowserPath } from '../../../../../../../../shared/utilities/recordHelpers';
import './styles.scss';
import { httpPost } from '../../../../../../../../shared/http/requests';
import { connect } from 'react-redux';
import { MY_CASES_ADD_REPLIES_TO_EMAIL } from '../../../store/constants';

dayjs.extend(relativeTime);

interface Props {
  email: DbRecordEntityTransform;
  isReply?: boolean;
  userReducer: any;
}

const MyCasesEmail: React.FC<Props> = (props: Props) => {
  const { email, isReply, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [isReplyingEmail, setIsReplyingEmail] = useState<boolean>(false);
  const [isSendingReply, setIsSendingReply] = useState<boolean>(false);
  const [CCEmails, setCCEmails] = useState<string[]>([]);
  const [isCcEnabled, setisCcEnabled] = useState<boolean>(false);
  const [replyBody, setReplyBody] = useState<string>('');

  const closeReply = () => {
    setReplyBody('');
    setIsReplyingEmail(false);
    setCCEmails([]);
    setisCcEnabled(false);
  };

  const sendReply = async () => {
    try {
      setIsSendingReply(true);

      // let payload: CreateSupportEmailDto = {
      let payload: any = {
        properties: {
          Subject: `Re: ${getProperty(email, 'Subject')}`,
          Message: replyBody,
          From: userReducer?.user?.email,
          To: getProperty(state.selectedCaseContact, 'EmailAddress'),
          Cc: CCEmails.length > 0 ? CCEmails.join(',') : undefined,
          Case: state.selectedCase?.id || '',
          ParentMessageId: email.id,
        },
      };
      const res = await httpPost(`NotificationModule/v1.0/email/support/send`, payload);
      setIsSendingReply(false);
      closeReply();

      const replyEmailRecord = res.data?.data;
      if (replyEmailRecord) {
        dispatch({
          type: MY_CASES_ADD_REPLIES_TO_EMAIL,
          payload: { emailId: email?.id, replies: [replyEmailRecord] },
        });
      }
    } catch (error) {
      setIsReplyingEmail(false);
    }
  };

  return (
    <Row justify="end" style={{ padding: isReply ? '5px 15px' : 15 }}>
      <Col span={24} className="myCasesUsersEmail">
        <Row>
          {/* Email Title & Toolbar */}
          <Col span={12}>
            <i className="bi bi-envelope" style={{ marginRight: 5 }} />
            <span style={{ fontWeight: 500 }}>Email {isReply && ' Reply'}</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Link to={getBrowserPath(email)} target="_blank">
              <Button small minimal icon={<i className="bi bi-box-arrow-up-right" />} />
            </Link>
            {!isReply && (
              <Button
                disabled={isReplyingEmail}
                small
                minimal
                icon={<i className="bi bi-reply" style={{ fontSize: '1.2em', color: '#5F6B7C' }} />}
                onClick={() => {
                  setIsReplyingEmail(true);
                }}
              />
            )}
          </Col>

          {/* From and Timestamp */}
          <Col span={16} style={{ marginTop: 5 }}>
            <span style={{ fontSize: '0.8em', fontWeight: 600 }}>{getProperty(email, 'From')}</span>
          </Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: 5 }}>
            <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
              {dayjs(email.createdAt).format('ddd, DD MMM')} ({dayjs(email.createdAt).fromNow()})
            </span>
          </Col>
          <Col span={24}>
            <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
              To: {getProperty(email, 'To')}
            </span>
            {getProperty(email, 'Cc') && (
              <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
                , Cc: {getProperty(email, 'Cc')}
              </span>
            )}
          </Col>

          {/* User / Timestamp */}
          <Col span={24} style={{ marginTop: 10 }}>
            <Col span={24}>
              <span style={{ fontWeight: 600 }}>{getProperty(email, 'Subject')}</span>
            </Col>
            <Col span={24} style={{ marginTop: 5 }}>
              <span>{getProperty(email, 'Message')}</span>
            </Col>
          </Col>
        </Row>

        {/* Reply Email */}
        {isReplyingEmail && (
          <Row
            style={{
              marginTop: 20,
              padding: 12,
              background: '#f4fcf9',
              border: '1px solid #d0dfda',
              borderRadius: 7,
            }}
          >
            {isCcEnabled && (
              <Col span={24}>
                <Row align="middle">
                  <Col span={1}>Cc:</Col>

                  <Col span={23} style={{ paddingLeft: 5 }}>
                    <TagInput
                      fill
                      addOnBlur
                      values={CCEmails}
                      tagProps={{ minimal: true, intent: 'none', style: { borderRadius: 5 } }}
                      className="myCasesUsersEmailTagInput"
                      onChange={(values: any[]) => {
                        setCCEmails(values);
                      }}
                      separator={','}
                      inputProps={{
                        autoFocus: true,
                        style: {
                          borderRadius: 5,
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={24} style={{ marginTop: 10 }}>
              <TextArea
                placeholder="Write Reply here"
                fill
                disabled={isSendingReply}
                autoFocus={true}
                style={{ borderRadius: 7 }}
                autoResize={true}
                value={replyBody}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setReplyBody(e.target.value);
                }}
              />
            </Col>
            <Col span={12} style={{ marginTop: 10 }}>
              <Button
                intent="primary"
                outlined={!isCcEnabled}
                text="Cc"
                disabled={isSendingReply}
                style={{ borderRadius: 5 }}
                onClick={() => {
                  if (isCcEnabled) {
                    setisCcEnabled(false);
                    setCCEmails([]);
                  } else {
                    setisCcEnabled(true);
                    setCCEmails([]);
                  }
                }}
              />
            </Col>

            <Col span={12} style={{ textAlign: 'right', marginTop: 10 }}>
              <Button
                style={{ borderRadius: 5, marginRight: 8 }}
                text="Cancel"
                disabled={isSendingReply}
                onClick={closeReply}
              />
              <Button
                style={{ borderRadius: 5 }}
                intent="primary"
                loading={isSendingReply}
                disabled={!replyBody.trim().length}
                text="Reply"
                onClick={sendReply}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesEmail);
