import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, Result } from 'antd';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { RecordDataTable } from '../../../../../core/records/components/DataTable';
import { httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';

type CreatePaymentNoticeProps = {
  record: DbRecordEntityTransform;
  workItems: DbRecordEntityTransform[];
  adjustments: DbRecordEntityTransform[];
  viewRecord?: (featureType: string, id: string) => void;
  disabled?: boolean;
};

const useNewAPI = true;

export const CreatePaymentNotice = ({
                                      record,
                                      workItems,
                                      adjustments,
                                      viewRecord,
                                      disabled = true
                                    }: CreatePaymentNoticeProps) => {
  const dispatch = useDispatch();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentNoticeData, setPaymentNoticeData] = useState<{
    id: string | undefined;
    isSuccess: boolean;
  }>({
    id: undefined,
    isSuccess: false
  });

  const missingItems = [...workItems, ...adjustments].filter((item) => {
    const isApproved = String(getProperty(item, 'IsApproved'));
    return !['true', 'false'].includes(isApproved);
  });

  const createPaymentNoticeClickHandler = () => {
    if (missingItems.length) {
      dispatch(displayMessage({
        body: 'Please make sure you set all items to approved or rejected before proceeding.',
        type: 'error'
      }));
      return;
    }

    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalLoading(true);

    let url = `ProjectModule/v1.0/WorkItems/PaymentNotice/${record?.id}`;
    let data = {};

    if (useNewAPI) {
      url = `ProjectModule/v1.0/PaymentNotice`;
      data = { afpId: record?.id };
    }

    await httpPost(url, data)
     .then((res) => {
       setIsModalLoading(false);
       setPaymentNoticeData({
         id: res.data.data.id,
         isSuccess: true
       });
     })
     .catch((err) => {
       console.error(err);
       setIsModalLoading(false);
       dispatch(displayMessage({
         body: `${err?.response?.data?.message ?? err.message}`,
         type: 'error'
       }));
       setPaymentNoticeData({
         id: undefined,
         isSuccess: false
       });
     });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPaymentNoticeData({
      id: undefined,
      isSuccess: false
    });
  };

  const getRowClassName = ({ rowRecord }: any, index?: number) => {
    const isApproved = getProperty(rowRecord, 'IsApproved') ?? '-';
    const className = ['true', 'false'].includes(String(isApproved))
     ? `row-afp-status-${String(
      isApproved?.replace(/\s/g, '')?.toLowerCase()
     )}`
     : '';
    return className;
  };

  // console.log('[DebugX] AFP/PN', { workItems, adjustments, missingItems })
  // if (missingItems.length) return null;

  return (
   <>
     <Button onClick={createPaymentNoticeClickHandler} disabled={disabled}>
      Create Payment Notice
    </Button>
     <Modal
      title="Confirm items for Payment Notice"
      width={900}
      open={isModalVisible}
      confirmLoading={isModalLoading}
      onOk={handleOk}
      onCancel={handleCancel}
     >
       {paymentNoticeData?.isSuccess ? (
        <Result
         status="success"
         title="Successfully created Payment Notice!"
         subTitle="click below to view your Payment Notice"
         extra={[
          <Link
            target="_blank"
            to={`/ProjectModule/PaymentNotice/${paymentNoticeData?.id}`}
          >
            View Payment Notice
          </Link>
         ]}
        />
       ) : (
        <div className="application-for-payment" style={{ marginBottom: 16 }}>
          <RecordDataTable
           title="Work Items"
           dataSource={workItems}
           rowClassName={getRowClassName}
          />
          <br />
          {adjustments.length > 0 && <RecordDataTable
           title="Adjustments"
           dataSource={adjustments}
           rowClassName={getRowClassName}
          />}
        </div>
       )}
     </Modal>
   </>
  );
};
