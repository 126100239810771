import { createRef, FC, useEffect, useLayoutEffect, useState } from 'react';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../../../shared/utilities/schemaHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../schemas/store/actions';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import './styles.scss';
import { getHostName } from '../../../../../../shared/http/helpers';
import FileFormField from '../../../Files/FileFormField';
import hash from 'object-hash';
import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import FormData from 'form-data';

interface Props {
  signatureFileIds: string[];
  parentSchemaId: string | undefined;
  getSchema: Function;
  schemaReducer: any;
  onUpdate?: Function;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FILE } = SchemaModuleEntityTypeEnums;

const SignatureField: FC<Props> = (props: Props) => {
  const { signatureFileIds, parentSchemaId, getSchema, schemaReducer, onUpdate } = props;
  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [fileSchema, setFileSchema] = useState<SchemaEntity | undefined>(undefined);
  const [signatureModalVisible, setSignatureModalVisible] = useState<boolean>(false);
  const [signatureIsModified, setSignatureIsModified] = useState<boolean>(false);
  const [signatureWidth, setSignatureWidth] = useState<number | undefined>(undefined);
  const [signatureHeight, setSignatureHeight] = useState<number | undefined>(undefined);
  const [exportedB64Signature, setExportedB64Signature] = useState<string | undefined>(undefined);
  const [createdFileId, setCreatedFileId] = useState<string | undefined>(undefined);
  const [existingFileIds, setExistingFileIds] = useState<string[]>([]);

  let signatureWrapper: any = createRef();
  let signatureContainer: any = createRef();

  // On component load, fetch existing signature files and set to state
  useEffect(() => {
    if (signatureFileIds.length > 0) {
      setExistingFileIds(signatureFileIds);
    }
  }, []);

  // Clear signature drawing and reset modified flag
  const clear = () => {
    if (signatureContainer) {
      signatureContainer?.clear();
    }
    setSignatureIsModified(false);
  };

  // Fetch FILE schema on component mount.
  useEffect(() => {
    if (!fileSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        SCHEMA_MODULE,
        FILE,
      );
      if (shortlistSchema) {
        setFileSchema(shortlistSchema);
      } else {
        getSchema({ moduleName: SCHEMA_MODULE, entityName: FILE }, (res: SchemaEntity) => {
          setFileSchema(res);
        });
      }
    }
  }, []);

  // When signature container ref is available, calculate canvas size
  useLayoutEffect(() => {
    if (signatureWrapper.current) {
    }
  }, [signatureWrapper.current, signatureModalVisible]);

  useLayoutEffect(() => {
    if (signatureModalVisible) {
      setTimeout(() => {
        setSignatureCanvasSize();
      }, 100);
    }
  }, [signatureModalVisible]);

  const setSignatureCanvasSize = () => {
    if (signatureWrapper.current) {
      const width = signatureWrapper.current?.clientWidth;
      const height = signatureWrapper.current?.clientHeight;
      setSignatureWidth(width);
      setSignatureHeight(height);
    } else {
      setSignatureHeight(120);
      setSignatureWidth(310);
    }
  };

  const createSignatureButton = () => {
    return (
      <div className="signatureUploadButton" onClick={() => setSignatureModalVisible(true)}>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>
          Add
          <br />
          Signature
        </div>
      </div>
    );
  };

  // Fetch parent schema once it's Id comes into the component
  useEffect(() => {
    if (parentSchemaId && !parentSchema) {
      const shortlistSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        parentSchemaId,
      );
      if (shortlistSchema) {
        setParentSchema(shortlistSchema);
      } else {
        getSchema({ schemaId: parentSchemaId }, (res: any) => {
          setParentSchema(res);
        });
      }
    }
  }, [parentSchemaId]);

  const closeSignatureModal = () => {
    setSignatureModalVisible(false);
    clear();
  };

  // On modal save button extract signature to Base64
  const onSave = () => {
    setExportedB64Signature(signatureContainer?.getTrimmedCanvas().toDataURL('image/png'));
    clear();
  };

  // Convert Base64 to Image Blob
  function b64toBlob(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  // Upload file
  const uploadFile = (formData: any) => {
    const token = localStorage.getItem(`token`);

    axios({
      method: 'POST',
      url: `${getHostName()}/SchemaModule/v1.0/s3/files/upload`,
      data: formData,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(async (res: any) => {
        if (onUpdate) {
          const fileIds = [res.data?.data?.id];
          onUpdate(fileIds);
        }
        setCreatedFileId(res.data?.data?.id);
        closeSignatureModal();
      })
      .catch((e: any) => {});
  };

  //  When signature is exported to PNG -> upload file and return the file record object
  useEffect(() => {
    if (exportedB64Signature) {
      const blob = b64toBlob(exportedB64Signature);
      const fileHash = hash(exportedB64Signature);
      let filename = `SIGNATURE-${fileHash}.jpg`;
      const file = new File([blob], filename, { type: 'image/jpeg' });
      let formData = new FormData();
      formData.append('file', file);
      uploadFile(formData);
    }
  }, [exportedB64Signature]);

  return (
    <Row>
      {/* When signature is created, show file manager for single file (signature) */}
      {createdFileId ? (
        <Col span={24}>
          <FileFormField
            mode="FILE_SINGLE"
            fileIds={[createdFileId]}
            parentSchemaId={parentSchemaId}
            onDelete={(elem: string) => {
              if (elem === createdFileId) {
                setCreatedFileId(undefined);
                props.onUpdate && props.onUpdate([]);
              }
            }}
          />
        </Col>
      ) : (
        <></>
      )}
      {/* In edit mode, there can be existing signatures, show this to manage. */}
      {existingFileIds.length > 0 ? (
        <Col span={24}>
          <FileFormField
            mode="FILE_SINGLE"
            fileIds={existingFileIds}
            parentSchemaId={parentSchemaId}
            onDelete={() => {
              setExistingFileIds([]);
              props.onUpdate && props.onUpdate([]);
            }}
          />
        </Col>
      ) : (
        <></>
      )}
      <div></div>
      <Dialog
        style={{ width: isMobile ? '100%' : '50%' }}
        title="Add Signature"
        isOpen={signatureModalVisible}
      >
        <DialogBody>
          <Row
            ref={signatureWrapper}
            style={{
              cursor: 'crosshair',
              border: '1px solid #dddbda',
              borderRadius: 5,
              overflowX: 'hidden',
              lineHeight: 0,
              height: 160,
            }}
          >
            {signatureWidth && signatureHeight ? (
              <SignatureCanvas
                penColor="black"
                onBegin={() => setSignatureIsModified(true)}
                canvasProps={{
                  width: signatureWidth,
                  height: signatureHeight,
                  className: 'sigCanvas',
                }}
                ref={(ref: any) => {
                  signatureContainer = ref;
                }}
              />
            ) : (
              <Col span={24} style={{ textAlign: 'center', paddingTop: 60 }}>
                <Spin />
              </Col>
            )}
          </Row>
        </DialogBody>
        <DialogFooter
          actions={[
            <Button text="Cancel" onClick={closeSignatureModal} />,
            <Button
              icon="refresh"
              intent="primary"
              outlined
              onClick={clear}
              disabled={!signatureIsModified}
              text="Try Again"
            />,
            <Button
              key="link"
              intent="primary"
              disabled={!signatureIsModified}
              onClick={onSave}
              text="Save"
            />,
          ]}
        />
      </Dialog>

      {!createdFileId && existingFileIds?.length === 0 ? createSignatureButton() : <></>}
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(SignatureField);
