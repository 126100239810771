import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Error403 from '../../../../shared/pages/403';
import { canUserSearchRecord } from '../../../../shared/permissions/rbacRules';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { generateFilterKey } from '../../../../shared/utilities/searchHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { resetRecordsList } from '../../store/actions';
import { IRecordReducer } from '../../store/reducer';
import DataTable from '../DynamicTable';
import {
  getQueryBuilderReducer,
  QueryBuilderReducer,
} from '../DynamicTable/QueryBuilder/store/reducer';
import { resetTableState, saveTableFilters } from '../DynamicTable/store/actions';
import RecordSearch from '../RecordSearch';
import ViewManager from './ViewManager';
import { AppstoreOutlined, MenuOutlined } from '@ant-design/icons';
import FileFeed from '../Files/FileFeed';
import { isMobile } from 'react-device-detect';
import './styles.scss';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { PageHeader } from '../../../../shared/components/PageHeader';
import { Button } from '@blueprintjs/core';
import ActionMenuListView from '../../../../v2/shared/components/ActionMenuListView';

type PathParams = {
  url: string;
  entityName: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  moduleName: string;
  entityName?: string;
  userReducer?: Object;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  queryBuilderReducer: QueryBuilderReducer;
  recordTableReducer: any;
  saveFilter: any;
  getSchema: Function;
  resetRecordState: any;
  resetTableReducer: any;
  pipelinesEnabled?: boolean;
  match: any;
};

interface State {
  entityName: string | undefined;
  schema: SchemaEntity | undefined;
  fileView: 'LIST' | 'FEED';
}

class FileListFeedView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      entityName: undefined,
      schema: undefined,
      fileView: 'LIST',
    };
  }

  // Load schema
  componentDidMount(): void {
    const { match, entityName } = this.props;

    this.loadSchema(this.props.entityName || match.params.entityName);
    this.props.resetRecordState();
    this.props.resetTableReducer();

    this.setState({
      entityName: match.params.entityName || entityName,
    });
  }

  componentDidUpdate(prevProps: Readonly<PropsType>) {
    const { moduleName, match } = this.props;
    const { entityName } = this.state;

    // Watch for entityName updates and update schema
    if (prevProps.entityName !== this.props.entityName && this.props.entityName) {
      this.setState({ entityName: this.props.entityName });
      this.loadSchema(this.props.entityName);
    }
    if (prevProps.match.params.entityName !== this.props.match.params.entityName) {
      this.setState({ entityName: this.props.match.params.entityName });
      this.loadSchema(this.props.match.params.entityName);
    }

    // User has logged back in
    if ((prevProps.userReducer as any)?.user?.id !== (this.props.userReducer as any)?.user?.id) {
      this.loadSchema(this.props.entityName || match.params.entityName);
    }

    if (prevProps.recordTableReducer.columns !== this.props.recordTableReducer.columns) {
      this.saveTableFilters();
    }

    if (prevProps.recordReducer.searchQuery !== this.props.recordReducer.searchQuery) {
      this.saveTableFilters();
    }

    const prevQbr = getQueryBuilderReducer(prevProps.queryBuilderReducer, moduleName, entityName);
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      moduleName,
      entityName,
    );
    if (prevQbr.queries !== queryBuilderReducer.queries) {
      this.saveTableFilters();
    }

    if (prevQbr.dateRangeFilters !== queryBuilderReducer.dateRangeFilters) {
      this.saveTableFilters();
    }

    if (prevQbr.formFields !== queryBuilderReducer.formFields) {
      this.saveTableFilters();
    }
  }

  loadSchema(entityName: string) {
    const { schemaReducer, moduleName, getSchema } = this.props;

    const shortListFileSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (shortListFileSchema) {
      this.setState({ schema: shortListFileSchema });
    } else {
      getSchema({ moduleName, entityName }, (response: SchemaEntity) => {
        if (response) {
          this.setState({ schema: response });
        }
      });
    }
  }
  private saveTableFilters() {
    const { moduleName, recordReducer, schemaReducer, recordTableReducer, saveFilter } = this.props;
    const { entityName } = this.state;

    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      moduleName,
      entityName,
    );

    if (!schemaReducer.isRequesting && !recordReducer.isSearching) {
      const schema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );

      if (schema) {
        const name = generateFilterKey(schema.moduleName, schema.entityName);
        saveFilter(name, {
          search: recordReducer.searchQuery,
          columns: recordTableReducer.columns,
          queryBuilder: queryBuilderReducer,
        });
      }
    }
  }

  fileViewSwitcher = () => {
    return this.state.entityName === 'File' ? (
      <Row style={{ margin: '8px 0' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button
            intent="primary"
            small
            outlined={this.state.fileView === 'FEED'}
            onClick={() => this.setState({ fileView: 'LIST' })}
            style={{ marginRight: 10 }}
          >
            <MenuOutlined />
          </Button>

          <Button
            small
            intent="primary"
            outlined={this.state.fileView === 'LIST'}
            onClick={() => this.setState({ fileView: 'FEED' })}
          >
            <AppstoreOutlined />
          </Button>
        </Col>
      </Row>
    ) : (
      <></>
    );
  };

  render() {
    const { pipelinesEnabled, moduleName, userReducer } = this.props;

    if (this.state.schema && userReducer && !canUserSearchRecord(userReducer, this.state.schema)) {
      return <Error403 />;
    } else {
      return (
        <Layout className="list-view" style={{ overflowY: 'auto' }}>
          <PageHeader
            style={{ border: '1px solid #dadada' }}
            className="page-header"
            title={this.state.entityName}
            subTitle={<ViewManager moduleName={moduleName} entityName={this.state.entityName} />}
            extra={[<ActionMenuListView schema={this.state.schema} />]}
          >
            <RecordSearch
              moduleName={moduleName}
              entityName={this.state.entityName}
              schema={this.state.schema}
            />
          </PageHeader>

          {/* FEED */}
          <div
            className="list-view-table"
            style={{
              display: this.state.fileView === 'FEED' ? 'block' : 'none',
              height: isMobile ? '50vh' : '',
            }}
          >
            {this.fileViewSwitcher()}

            <FileFeed
              displayed={this.state.fileView}
              schema={this.state.schema}
              moduleName={moduleName}
              entityName={this.state.entityName}
            />
          </div>

          {/* LIST */}
          <div
            className="list-view-table"
            style={{
              display: this.state.fileView === 'LIST' ? 'block' : 'none',
            }}
          >
            {this.fileViewSwitcher()}

            <DataTable
              schema={this.state.schema}
              moduleName={moduleName!}
              entityName={this.state.entityName}
              pipelinesEnabled={pipelinesEnabled}
            />
          </div>
        </Layout>
      );
    }
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
  queryBuilderReducer: state.queryBuilderReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  saveFilter: (name: string, params: any) => dispatch(saveTableFilters(name, params)),
  resetRecordState: () => dispatch(resetRecordsList()),
  resetTableReducer: () => dispatch(resetTableState()),
});

export default withRouter(connect(mapState, mapDispatch)(FileListFeedView));
