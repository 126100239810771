import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../core/navigation/ProtectedRoute';
import DefaultDetailView from '../../v2/shared/views/DetailView';
import RecordDetailView from '../../core/records/components/DetailViewContext';
import RecordListView from '../../core/records/components/ListView';

const { NOTIFICATION_MODULE } = SchemaModuleTypeEnums;

export const NotificationModuleRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`/${NOTIFICATION_MODULE}/:entityName`}
        moduleName={NOTIFICATION_MODULE}
        component={<RecordListView moduleName={NOTIFICATION_MODULE} />}
      />
      <ProtectedRoute
        exact
        path={`/${NOTIFICATION_MODULE}/:entityName/:recordId`}
        moduleName={NOTIFICATION_MODULE}
        component={
          <RecordDetailView moduleName={NOTIFICATION_MODULE}>
            <DefaultDetailView />
          </RecordDetailView>
        }
      />
    </Switch>
  );
};
