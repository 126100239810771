import { Col, Row } from 'antd';
import React from 'react';

interface Props {
  timestamp: string;
}

const MyCasesUserTimestamp: React.FC<Props> = (props: Props) => {
  const { timestamp } = props;
  return (
    <Col span={13} style={{ color: '#727c93' }}>
      <Row justify="end">
        <Col>
          <span>{timestamp}</span>
        </Col>
      </Row>
    </Col>
  );
};
export default MyCasesUserTimestamp;
