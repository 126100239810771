import React, { useContext } from 'react';
import { Card, Col, Empty, Row } from 'antd';
import { connect } from 'react-redux';
import { orderBuilderContext } from '..';
import DiscountPanel from '../DiscountPanel';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import PhoneTransferPanel from '../PhoneTransferPanel';
import OrderItemCard from './OrderItemCard';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { IUserInterfaceReducer } from '../../../../../core/userInterface/store/types';

interface Props {
  userInterfaceReducer: IUserInterfaceReducer;
}

const OrderItemsList: React.FC<Props> = (props: Props) => {
  const { userInterfaceReducer } = props;
  const { state } = useContext(orderBuilderContext);
  const { orderBuilderRecontractingId } = userInterfaceReducer;
  const isRecontracting: boolean = !!orderBuilderRecontractingId;

  const shouldShowPhoneTransfer = (): boolean => {
    // In recontracting mode, we show phone transfer only if there's no VOICE product among products in the initial
    // recontracting quote and the user added VOICE product manually.
    if (isRecontracting) {
      return (
        state.orderItems.length > 0 &&
        !state.recontractingQuote?.nextProducts?.some(
          (product: DbRecordEntityTransform) => getProperty(product, 'Category') === 'VOICE',
        ) &&
        state.orderItems.some(
          (product: DbRecordEntityTransform) => getProperty(product, 'Category') === 'VOICE',
        )
      );
    }
    // In standard mode, we check only order items for VOICE product
    else {
      return (
        state.orderItems.length > 0 &&
        state.orderItems.some(
          (product: DbRecordEntityTransform) => getProperty(product, 'Category') === 'VOICE',
        )
      );
    }
  };

  return (
    <>
      <Row>
        {state.orderItems?.length > 0 ? (
          state.orderItems?.map((orderItem: DbRecordEntityTransform) => (
            <OrderItemCard orderItem={orderItem} />
          ))
        ) : (
          <Col span={24} style={{ padding: 5 }}>
            <Card style={{ padding: '40px 0' }} bordered={false}>
              <Empty description={<span>Please add Products to the Order.</span>} />
            </Card>
          </Col>
        )}
      </Row>
      <Row style={{ paddingTop: 5 }}>
        {/* Show Discount if any */}
        {state.discount ? (
          <Col sm={24} lg={24}>
            <DiscountPanel />
          </Col>
        ) : (
          <></>
        )}
        {/* Phone Transfer Panel */}
        {state.orderItems?.length > 0 && shouldShowPhoneTransfer() ? (
          <Col span={24} style={{ padding: 10 }}>
            <PhoneTransferPanel />
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(OrderItemsList);
