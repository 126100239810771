import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

const { ORDER_MODULE, BILLING_MODULE } = SchemaModuleTypeEnums;
const { ORDER, INVOICE } = SchemaModuleEntityTypeEnums;

interface Props {}

const BillingAdjustmentDetailView: FC<Props> = (props: Props) => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators
        leftColumn={[
          <RecordCard
            borderless
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={ORDER_MODULE}
            associatedRecordEntityName={ORDER}
            visibleProperties={['ActiveDate']}
          />,
          <RecordCard
            borderless
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={BILLING_MODULE}
            associatedRecordEntityName={INVOICE}
            visibleProperties={['Status', 'Balance']}
          />,
        ]}
      />
    </Layout>
  );
};

export default BillingAdjustmentDetailView;
