import { Icon, Section } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Avatar, Col, Descriptions, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import GroupDetails from '../../../../shared/components/GroupDetails';
import { hasPermissions } from '../../../../shared/permissions/rbacRules';
import { getPastelColorForUsername } from '../../../../v2/shared/helpers/UIHelpers';
import { IdentityUserReducer } from '../../../identityUser/store/reducer';
import { logRecordViewedAuditEvent } from '../../../userAudit/helpers/userAuditHelpers';
import {
  createUserAuditEventsRequest,
  getUsersAuditEventsByRecordRequest,
  ICreateUserAuditEvents,
  IGetUsersAuditEventsByRecord,
} from '../../../userAudit/store/actions';
import { IUserAuditReducer } from '../../../userAudit/store/reducer';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  userReducer: IdentityUserReducer;
  userAuditReducer: IUserAuditReducer;
  getAuditByRecord: (params: IGetUsersAuditEventsByRecord, cb?: (resp: any) => void) => void;
  createAudit: (params: ICreateUserAuditEvents, cb?: any) => void;
}

const CollaboratorsCard: FC<Props> = (props: Props) => {
  const { record, userReducer, getAuditByRecord, userAuditReducer, createAudit } = props;
  const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(false);
  const [lastViewer, setLastViewer] = useState<any>(undefined);
  const [allViewers, setAllViewers] = useState<any[]>([]);

  // On component mount, log user record and fetch all audits for the record
  useEffect(() => {
    if (record) {
      logUserViewingTheRecord(record);
      fetchUserActivity(record);
    }
  }, [record]);

  const fetchUserActivity = (record: DbRecordEntityTransform) => {
    setIsLoadingEvents(true);

    if (record && hasPermissions(userReducer, ['logs.user.audit.get'])) {
      getAuditByRecord({ recordId: record.id }, (res: any) => {
        const events = res?.results?.data?.events || [];

        // Set last viewer if the last event was less than 5 minutes ago.
        if (events.length > 0 && dayjs().diff(dayjs(res?.respondedAt), 'minutes') < 5) {
          setIsLoadingEvents(false);
          setLastViewer(events[0]);
        } else {
          setIsLoadingEvents(false);
        }

        // Get all view events for the record
        if (events.length > 0) {
          let viewEvents = [];
          const lastViewer = events[0];
          // Get all view events
          viewEvents = events.filter((event: any) => event?.type === 'DB_RECORD_VIEWED');

          // remove last viewer from the list of all viewers by userId property
          if (lastViewer) {
            viewEvents = events.filter((event: any) => event?.userId !== lastViewer?.userId);
          }
          // get a unique list of events by userName property
          viewEvents = viewEvents.filter(
            (event: any, index: number, self: any) =>
              index === self.findIndex((e: any) => e.userName === event.userName),
          );

          // add color property to each event with a random pastel color
          viewEvents = viewEvents.map((event: any) => {
            return {
              ...event,
              color: getPastelColorForUsername(event.userName),
            };
          });

          setAllViewers(viewEvents);
        }
      });
    }
  };

  // Take the record id and log the user activity.
  const logUserViewingTheRecord = (record: DbRecordEntityTransform) => {
    logRecordViewedAuditEvent(
      {
        recordId: record.id,
        data: {
          recordNumber: record.recordNumber,
          title: record.title,
        },
      },
      userAuditReducer,
      createAudit,
    );
  };

  const getInitialsFromUserName = (userName: string) => {
    const splitUserName = userName.split(' ');
    const firstName = splitUserName[0]?.toUpperCase() || '-';
    const lastName = splitUserName[1]?.toUpperCase() || '-';
    return firstName[0] + lastName[0];
  };

  return (
    <Section title="Collaborators" icon={<Icon icon="people" />} compact>
      <Row style={{ opacity: isLoadingEvents ? 0.3 : 1, padding: 15 }}>
        <Col span={24}>
          <Descriptions
            className="collaboratorsCard"
            bordered
            size="small"
            labelStyle={{ color: 'black', fontSize: 12, padding: '2px 7px', background: '#F6F7F9' }}
            contentStyle={{ fontSize: 12, padding: '2px 7px' }}
            column={2}
          >
            {
              // Owner Information
              getProperty(record, 'OwnerName') && (
                <Descriptions.Item key="Owner" label="Owner" span={2}>
                  {getProperty(record, 'OwnerName')}
                </Descriptions.Item>
              )
            }

            {
              // Team Information
              getProperty(record, 'TeamName') && (
                <Descriptions.Item key="Team" label="Team" span={2}>
                  {getProperty(record, 'TeamName')}
                </Descriptions.Item>
              )
            }

            {/* Created By / At */}
            <Descriptions.Item key="CreatedBy" label="Created By" span={2}>
              <span>{record?.createdBy?.fullName || 'Unknown'}</span>
              <br />
              {DateTime.fromISO(record?.createdAt as any).toFormat('d/M/yyyy h:mm a ZZZZ')}
            </Descriptions.Item>

            {/* Updated By / At */}
            <Descriptions.Item key="CreatedBy" label="Updated By" span={2}>
              <span>{record?.lastModifiedBy?.fullName || 'Unknown'}</span>
              <br />
              {DateTime.fromISO(record?.updatedAt as any).toFormat('d/M/yyyy h:mm a ZZZZ')}
            </Descriptions.Item>

            {/* Record Audit */}
            <Descriptions.Item key="LastViewed" label="Last viewer" span={2}>
              {lastViewer ? lastViewer?.userName : 'No one has viewed this record yet.'}
              {lastViewer && (
                <>
                  <br />
                  {DateTime.fromISO(lastViewer?.createdAt as any).toFormat('d/M/yyyy h:mm a ZZZZ')}
                </>
              )}
            </Descriptions.Item>

            {/* Other Viewers */}
            <Descriptions.Item key="otherViewers" label="Other viewers" span={2}>
              {allViewers.length > 0 ? (
                <Avatar.Group
                  style={{ marginTop: 8 }}
                  size="small"
                  maxCount={3}
                  maxPopoverTrigger="click"
                  maxStyle={{ color: '#414141', backgroundColor: '#d6d6d6', cursor: 'pointer' }}
                >
                  {allViewers.map((viewer: any) => {
                    return (
                      <Tooltip key={viewer.userName} title={viewer.userName} mouseEnterDelay={0.8}>
                        <Avatar
                          size="small"
                          style={{
                            backgroundColor: viewer.color,
                            cursor: 'pointer',
                          }}
                        >
                          {getInitialsFromUserName(viewer.userName)}
                        </Avatar>
                      </Tooltip>
                    );
                  })}
                </Avatar.Group>
              ) : (
                'No other viewers.'
              )}
            </Descriptions.Item>

            {/* Groups */}
            <Descriptions.Item key="Groups" label="Groups" span={2}>
              <GroupDetails record={record} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Section>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  userAuditReducer: state.userAuditReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAuditByRecord: (params: IGetUsersAuditEventsByRecord, cb?: (resp: any) => void) =>
    dispatch(getUsersAuditEventsByRecordRequest(params, cb)),
  createAudit: (params: ICreateUserAuditEvents, cb?: (resp: any) => void) =>
    dispatch(createUserAuditEventsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(CollaboratorsCard);
