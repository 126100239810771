import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../../shared/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { searchString } from '../../../../../../shared/utilities/searchHelpers';
import { BlueprintNavigation } from '../../../../../../v2/shared/components/BlueprintPagination';
import { formFields } from '../FormFields';
import TeamDetails from '../TeamDetails';
import { getRecordIdFromHash } from '../../../../utils/hashNavigation';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
}

const uuid = uuidv4();

const TeamListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [teamList, setTeamList] = useState<any[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isTeamLoading, setIsTeamLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();
  useEffect(() => {
    const id = getRecordIdFromHash(window.location.hash);
    if (id) getTeamById(id);
  }, [window.location.hash]);


  useEffect(() => {
    getTeamsList();
  }, []);

  useEffect(() => {
    setSelectedRegions([]);
    setSelectedTeam(undefined);
  }, [currentPage]);

  const onSelect = (e: any) => {
    setSelectedRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (teamList.length > 0) {
      let tableData: ITableData[] = Object.assign([], teamList);

      tableData = teamList.map((g: any) => ({
        key: g.id,
        name: g.name,
        description: g.description,
      }));

      // Apply search
      tableData = tableData.filter((g: any) => {
        return searchString(g.name, searchKey) || searchString(g.description, searchKey);
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [teamList, currentPage, searchKey, pageSize]);

  // Get selected team details
  useEffect(() => {
    if (selectedRegions.length > 0 && tableSlice.length > 0) {
      const team: any = tableSlice[selectedRegions[0].rows[0]];
      if (team && !isTeamLoading) {
        getTeamById(team.key);
      }
    }
  }, [selectedRegions, tableSlice]);

  const getTeamById = (id: string) => {
    setIsTeamLoading(true);
    httpGet(`IdentityModule/v2.0/teams/${id}`)
      .then((res) => {
        setIsTeamLoading(false);
        setSelectedTeam(res.data.data);
        console.log('%cdebug: Selected team', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({ body: 'Could not retrieve team. ' + message, type: 'error' });
        setIsTeamLoading(false);
        setSelectedTeam(undefined);
        setSelectedRegions([]);
      });
  };

  const getTeamsList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v2.0/teams?size=10000`);
      const teams: any[] = res.data.data || [];
      setTeamList(teams);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve teams list. ' + message, type: 'error' });
      setTeamList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedTeam, tableRef, tableSlice]);

  const showCreateTeamForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Team',
      formFields: formFields,
      entityName: 'Team',
      isCreateReq: true,
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
      };
      createTeam({ body });
    }
  };

  const createTeam = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/teams', body);
      const newTeam = res.data.data;
      setTeamList([...teamList, newTeam]);
      getTeamById(newTeam.id);

      alertMessage({ body: 'Team Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create team. ' + message,
        type: 'error',
      });
    }
  };

  const onSearch = (e: any) => {
    setSelectedRegions([]);
    setSearchKey(e.target.value);
  };

  const onTeamUpdate = () => {
    if (selectedTeam) {
      getTeamById(selectedTeam.id);
    }
  };

  const onTeamDelete = () => {
    setSelectedRegions([]);

    const id = selectedTeam?.id;
    if (id) {
      setTeamList(teamList.filter((t) => t.id !== id));
      setSelectedTeam(undefined);
    }
  };

  const renderTeams = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Teams..."
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreateTeamForm}>
                  Create Team
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedTeam ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[getColumnWidthByPercentage(30), getColumnWidthByPercentage(70)]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Team Details */}
          {selectedTeam && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isTeamLoading ? 0.6 : 1 }}
            >
              <Section
                title="Team Details"
                compact
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedRegions([]);
                      setSelectedTeam(undefined);
                    }}
                  />
                }
              >
                <TeamDetails team={selectedTeam} onUpdate={onTeamUpdate} onDelete={onTeamDelete} />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={teamList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderTeams()}</div>;
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(TeamListView);
