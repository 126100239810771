import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultDetailView from '../../v2/shared/views/DetailView';
import { IRecordAssociationsReducer } from '../../core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '../../core/schemas/store/reducer';
import {
  getAllSchemaAssociationSchemas,
  getRecordRelatedFromShortListById,
} from '../../shared/utilities/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../shared/utilities/schemaHelpers';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  recordAssociationReducer: IRecordAssociationsReducer;
  schemaReducer: ISchemaReducer;
  match: any;
  excludeRelations: string[];
  visibleProperties?: string[];
};

const { NOTE } = SchemaModuleEntityTypeEnums;

class RelatedRecordDetailView extends React.Component<PropsType> {
  render() {
    const { schemaReducer, recordAssociationReducer, match, excludeRelations, visibleProperties } =
      this.props;

    const record = getRecordRelatedFromShortListById(
      recordAssociationReducer.shortList,
      match.params.dbRecordAssociationId,
      match.params.recordId,
    );
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, [
      NOTE,
      ...excludeRelations,
    ]);

    return <DefaultDetailView />;
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

export default withRouter(connect(mapState)(RelatedRecordDetailView));
