import { Section } from '@blueprintjs/core';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import ViewManager from '../../../../core/records/components/ListView/ViewManager';
import RecordSearch from '../../../../core/records/components/RecordSearch';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../core/schemas/store/actions';
import ModuleEntityIcon from '../../../../core/theme/ModuleEntityIcon';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import ActionMenuListView from '../ActionMenuListView';
import './styles.scss';

interface Props {
  schemaReducer: any;
  moduleName: string;
  entityName: string;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
}

const HeaderListView: React.FC<Props> = (props: Props) => {
  const { moduleName, entityName, schemaReducer, getSchema } = props;
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    fetchSchema();
  }, [entityName]);

  const fetchSchema = () => {
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (shortListSchema) {
      setSchema(shortListSchema);
    } else {
      getSchema({ moduleName, entityName }, (response: SchemaEntity) => {
        setSchema(response);
      });
    }
  };

  return (
    <Section
      className="headerListViewSection"
      style={{ paddingTop: isMobile ? 10 : 1 }}
      rightElement={<ActionMenuListView schema={schema} />}
      subtitle={<ViewManager moduleName={moduleName} entityName={entityName} />}
      icon={
        <ModuleEntityIcon
          iconContainerStyle={{ marginRight: 0, marginLeft: 3, padding: '5px 8px' }}
          moduleName={moduleName}
          entityName={entityName}
        />
      }
      title={<h3 style={{ fontWeight: 600, margin: 5, fontSize: '1.3em' }}>{entityName}</h3>}
    >
      <Row>
        <Col span={24} style={{ padding: '4px 10px 0 10px' }}>
          <RecordSearch moduleName={moduleName} entityName={entityName} schema={schema} />
        </Col>
      </Row>
    </Section>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(HeaderListView);
