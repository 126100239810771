import { Button } from '@blueprintjs/core';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import { connect } from 'react-redux';
import OrderList from '../../../../../containers/OrderModule/containers/Order/OrdersList';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import RecordCard from '../../../../../core/records/components/RecordCard';
import {
  ISetOrderBuilderDrawerRecordIds,
  openOrderBuilderDrawer,
  setOrderBuilderDrawerRecordIds,
} from '../../../../../core/userInterface/store/actions';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';
import InvoicePendingCallout from '../../../BillingModule/components/InvoicePendingCallout';
import WorkOrderPendingCallout from '../../../FieldServiceModule/components/WorkOrderPendingCallout';
import OrderBuilderDrawer from '../../../OrderModule/OrderBuilder/OrderBuilderDrawer';

interface Props {
  openDrawer: () => void;
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) => void;
  initializeForm: (params: any) => void;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { ADDRESS, CONTACT } = SchemaModuleEntityTypeEnums;

const AccountDetailView: FC<Props> = (props: Props) => {
  const { record } = useContext(DetailViewContext);

  const startOrderBuilder = () => {
    const { openDrawer, setOrderBuilderDrawerRecordIds } = props;
    setOrderBuilderDrawerRecordIds({
      accountId: record?.id,
    });
    openDrawer();
  };

  return (
    <Layout>
      <OrderBuilderDrawer />

      <DetailView
        showCollaborators
        headerExtras={[
          <Button intent="primary" outlined onClick={startOrderBuilder}>
            Create Order
          </Button>,
        ]}
        defaultTabKey={'Summary'}
        customTabs={[
          {
            key: 'Summary',
            tab: 'Summary',
          },
        ]}
        customTabBodies={{
          Summary: (
            <div>
              <div style={{ marginTop: 15 }}>
                <WorkOrderPendingCallout record={record} />
              </div>
              <div style={{ marginTop: 15, marginBottom: 5 }}>
                <InvoicePendingCallout record={record} />
              </div>
              <div style={{ marginTop: 10 }}>
                <OrderList record={record} />
              </div>
            </div>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            displayQuickView
            customTitle={<span>Contact</span>}
            visibleProperties={['EmailAddress', 'Phone']}
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={CONTACT}
            record={record}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            displayQuickView
            customTitle={<span>Address</span>}
            visibleProperties={['Type', 'SalesStatus']}
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ADDRESS}
            record={record}
          />,
        ]}
      />
    </Layout>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  openDrawer: () => dispatch(openOrderBuilderDrawer()),
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) =>
    dispatch(setOrderBuilderDrawerRecordIds(params)),
});

export default connect(mapState, mapDispatch)(AccountDetailView);
