import * as FetchState from '../../../../../../../../shared/fetcher/one';
import * as ArrayFetchState from '../../../../../../../../shared/fetcher/many';

import { AFPApprovalState } from '../types';

export const getInitialState = (): AFPApprovalState => ({
  records: {
    afp: FetchState.getIdleState(),
    allFpes: ArrayFetchState.getIdleState(),
    feature: FetchState.getIdleState(),
    featureFpes: ArrayFetchState.getIdleState(),
  },
  fpeSelection: {
    filters: {
      status: 'todo',
      text: '',
      featureType: [],
    },
    selectAll: false,
    selectedRecords: [],
  },
});
