import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { BlueprintNavigation } from '../../../../../../shared/components/BlueprintPagination';
import { Button, InputGroup, Spinner, Tooltip } from '@blueprintjs/core';
import OdinTable from '../../../../../../shared/components/OdinTable';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsDebounced,
} from '../../../../../../../core/records/store/actions';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import { getOdinSchemaByEntity } from '../../../../../../../shared/utilities/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../../../../../shared/utilities/recordHelpers';
import './styles.scss';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../core/userInterface/store/actions';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { httpGet } from '../../../../../../../shared/http/requests';
import { initializeRecordForm } from '../../../../../../../core/records/components/Forms/store/actions';
import CoreForm from '../../../../../../../core/records/components/Forms/CoreForm';
import { v4 as uuidv4 } from 'uuid';
import { UPDATE_DB_RECORD_BY_ID_REQUEST } from '../../../../../../../core/records/store/constants';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';
const uuid = uuidv4();

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
  initializeForm: (params: any) => void;
}

interface ITableDataItem {
  key: string;
  caseNumber: string;
  contact?: string;
  source: string;
  category: string;
  SLA: JSX.Element;
  agent: JSX.Element;
  action: JSX.Element;
}

const CaseManagementTemplates: FC<Props> = (props: Props) => {
  const { searchRecords, alertMessage, initializeForm, openDrawer } = props;

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  const [caseList, setCaseList] = useState<DbRecordEntityTransform[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [assignOwnerAction, setAssignOwnerAction] = useState<SchemaActionEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    getCaseSchema();
  }, []);

  useEffect(() => {
    if (caseSchema && !assignOwnerAction) {
      getAssignOwnerAction(caseSchema.id);
    }
  }, [caseSchema, assignOwnerAction]);

  useEffect(() => {
    if (caseSchema) {
      getCaseList(searchKey);
    }
  }, [currentPage, caseSchema, searchKey]);

  const getCaseSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, CASE);
      if (schema) {
        setCaseSchema(schema);
      }
    } catch (error) {}
  };

  const getAssignOwnerAction = async (schemaId: string) => {
    try {
      const res = await httpGet(`SchemaModule/v1.0/schemas-actions/schema/${schemaId}`);
      const action = res?.data?.data?.find(
        (item: SchemaActionEntity) => item.name === 'AssignCase',
      );
      if (action) {
        setAssignOwnerAction(action);
      }
    } catch (error) {}
  };

  const initializeAssignCaseForm = (record: DbRecordEntityTransform) => {
    if (assignOwnerAction && caseSchema) {
      initializeForm({
        hideRecordTypeField: true,
        formUUID: uuid,
        showFormModal: true,
        title: assignOwnerAction?.name || 'Form',
        showInitializing: false,
        isBatchCreateReq: false,
        schema: caseSchema,
        hideRecordFormFields: true,
        recordType: record.type || null,
        schemaActionId: assignOwnerAction.id,
        isCreateReq: false,
        isUpdateReq: true,
        selected: record,
      });
    }
  };

  const getCaseList = async (query: string) => {
    if (caseSchema) {
      setIsRefreshing(true);
      searchRecords(
        {
          schema: caseSchema,
          searchQuery: {
            terms: query,
            schemas: caseSchema.id,
            sort: [{ updatedAt: { order: 'desc' } }],
            pageable: {
              page: currentPage,
              size: pageSize,
            },
          },
        },
        (response: any) => {
          const data = response?.data?.data || [];
          console.log('debug: DATA', data);
          setIsRefreshing(false);
          setIsLoading(false);
          setTotalRecordsCount(response?.data?.pageable?.totalRecords || 0);
          setCaseList(data);
        },
      );
    }
  };

  const handleFormSubmit = (params: { event: string; results: any }) => {
    if (caseSchema) {
      if (params.event === UPDATE_DB_RECORD_BY_ID_REQUEST) {
        // console.log('debug: Record updated!', params);

        // Update the record in the list
        const record = params.results;
        updateRecordInCaseList(record);

        // In sandbox, certain properties would not be updated immediately, like TeamName
        // or OwnerName. Waiting for 1 second before fetching the record again did the job.
        // setTimeout(() => {
        //   getRecordById({ schema, recordId: params.results.id });
        // }, 2000);
      }
    }
  };

  const updateRecordInCaseList = (record: DbRecordEntityTransform) => {
    const updatedCaseList = caseList.map((item: DbRecordEntityTransform) => {
      if (item.id === record.id) {
        return {
          ...item,
          properties: {
            ...item.properties,
            OwnerId: record.properties.OwnerId,
          },
          links: record.links,
        };
      } else {
        return item;
      }
    });
    setCaseList(updatedCaseList);
  };

  const getUserNameFromRecordLinks = (record: DbRecordEntityTransform) => {
    const userId = getProperty(record, 'OwnerId');
    const user = record?.links?.find((link: any) => link.id === userId);
    if (user) {
      return user.title;
    } else {
      return 'Unknown User';
    }
  };

  const tableData: any[] = caseList.map((item: DbRecordEntityTransform) => {
    return {
      key: item.id,
      caseNumber: (
        <Link target="_blank" key={item.id} to={getBrowserPath(item)}>
          {item.recordNumber}
        </Link>
      ),
      contact: '-',
      source: '-',
      category: getProperty(item, 'Category') || '-',
      SLA: <span key={item.id}>SLA</span>,
      agent: (
        <Tooltip
          fill
          disabled={!!assignOwnerAction}
          hoverOpenDelay={800}
          content="Could not find 'AssignCase' schema action."
        >
          <Row align="middle" onClick={() => initializeAssignCaseForm(item)}>
            <Col
              span={24}
              style={{ textAlign: 'center' }}
              className={`caseAssignButton ${
                isRefreshing || !assignOwnerAction ? 'disabled' : 'active'
              }`}
            >
              {getProperty(item, 'OwnerId') ? (
                <>
                  <i
                    className="bi bi-circle-fill"
                    style={{
                      fontSize: '0.6em',
                      color: '#008949',
                      marginTop: 10,
                      marginRight: 8,
                      verticalAlign: 'middle',
                    }}
                  />
                  <span>{getUserNameFromRecordLinks(item)}</span>
                </>
              ) : (
                <span>Click to Assign</span>
              )}
            </Col>
          </Row>
        </Tooltip>
      ),
      action: (
        <Row align="middle" justify="center">
          <Col>
            <Button
              key={item.id}
              intent="primary"
              minimal
              onClick={() => {
                openDrawer({
                  recordId: item.id,
                  moduleName: SUPPORT_MODULE,
                  entityName: CASE,
                });
              }}
              icon="eye-open"
            />
          </Col>
        </Row>
      ),
    };
  });

  return (
    <>
      <Row>
        <Col span={20}>
          <h1 style={{ margin: 0 }}>Cases</h1>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <InputGroup
            disabled={isLoading}
            type="search"
            placeholder="Search Cases"
            onChange={(e: any) => setSearchKey(e.target.value)}
            intent={searchKey.length > 0 ? 'primary' : 'none'}
            leftIcon={isRefreshing && searchKey.length > 0 ? null : 'search'}
            leftElement={
              isRefreshing && searchKey.length > 0 ? (
                <Spinner size={20} style={{ padding: 5 }} />
              ) : undefined
            }
          />
        </Col>
      </Row>
      {/* Table */}
      <div style={{ marginTop: 30, overflowX: 'auto' }}>
        <OdinTable
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          height="calc(100vh - 225px)"
          data={tableData}
          columns={[
            {
              key: 'caseNumber',
              title: 'Case Number',
              width: 3,
              // filterable: true,
            },
            {
              key: 'contact',
              title: 'Contact',
              width: 5,
              // searchable: true,
            },
            {
              key: 'source',
              title: 'Source',
              width: 3,
              // filterable: true,
            },
            {
              key: 'category',
              title: 'Category',
              width: 3,
              // filterable: true,
            },
            {
              key: 'SLA',
              title: 'SLA',
              width: 4,
              align: 'center',
              // sortable: true,
            },
            {
              key: 'agent',
              title: 'Agent',
              width: 5,
              // searchable: true,
              align: 'center',
              hideDivider: true,
            },
            {
              key: 'action',
              title: '',
              width: 1,
            },
          ]}
        />
      </div>

      {/* Pagination */}
      <Row style={{ background: 'white' }}>
        <div style={{ padding: '10px 0' }}>
          <BlueprintNavigation
            totalCount={totalRecordsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginate={setCurrentPage}
            // disabled={caseList.length <= pageSize}
          />
        </div>
      </Row>

      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onCloseEvent={() => {}}
        onSubmitEvent={(params: { event: string; results: any }) => handleFormSubmit(params)}
      />
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsDebounced(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementTemplates);
