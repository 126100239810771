import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Badge, Button, Col, Divider, Dropdown, Menu, Row, Tooltip, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { initializeRecordForm } from '../../../../../../core/records/components/Forms/store/actions';
import {
  addRecordToShortList,
  IAddRecordToShortList,
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../../core/records/store/actions';
import ModuleEntityIcon from '../../../../../../core/theme/ModuleEntityIcon';
import { getBrowserPath } from '../../../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../../../../../shared/utilities/schemaHelpers';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '../../../../../../core/records/components/Forms/CoreForm';
import { toSentenceCase } from '../../../../../../shared/utilities/stringHelpers';
import Pipeline from '../../../../../../core/records/components/Pipeline/Pipeline';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../../core/recordsAssociations/store/actions';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { BulkApprovalModal } from '../BulkApprovalModal';
import { AFPIsApproved, AFPStatus } from './types';
import { getRecordStatus } from '../../helpers';
import { useAFPApprovalService, useAFPApprovalState } from '../../context';
import { FPEStatusFilter } from '../../context/hooks/useAFPApprovalReducer/types';
import { getSelectedRecords } from '../../context/helpers';

const getBadgeColorForRecordStatus = (status: string) => {
  switch (status) {
    case 'to_do':
      return 'grey';
    case 'approved':
      return 'green';
    case 'rejected':
      return 'red';
    default:
      return 'grey';
  }
};

interface Props {
  isLoading: boolean;
  record: DbRecordEntityTransform | undefined;
  shortListRecord: Function;
  initializeForm: Function;
  schemaReducer: any;
  userReducer: any;
  hideDivider?: boolean;
}

const uuid = uuidv4();

const allStatuses: FPEStatusFilter[] = ['todo', 'approved', 'rejected'];

const RecordViewHeader: FC<Props> = (props: Props) => {
  const afpApprovalState = useAFPApprovalState();
  const {
    records: { afp },
  } = afpApprovalState;
  const selectedRecords = getSelectedRecords(afpApprovalState);
  const fpesWithNoPaymentNotice = selectedRecords.filter((r) => !getProperty(r, 'PaymentNoticeId'));

  const { approveFpes, clearFpesStatus } = useAFPApprovalService();

  const { record, shortListRecord, initializeForm, schemaReducer, userReducer, hideDivider } =
    props;
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [recordStatus, setRecordStatus] = useState<string | undefined>(undefined);
  const [showModal, setShowModal] = useState<FPEStatusFilter | ''>('');

  const moduleName = record?.entity?.split(':')[0];
  const entityName = record?.entity?.split(':')[1];

  const isBulkApproval = selectedRecords.length > 1;

  // On component mount, set the record status to state
  useEffect(() => {
    if (record) {
      setRecordStatus(getRecordStatus(record));
    }
  }, [record]);

  const initializeUpdateForm = async (record: DbRecordEntityTransform) => {
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (schema) {
      // getUsers();
      // getPipelines({ schema: schema });

      initializeForm({
        formUUID: uuid,
        title: `Updating Related ${schema.entityName} Properties`,
        hasColumnMappings: false,
        //visibleFieldOverride: visibleProperties,
        //disabledFields: updateFormDisabledFields,
        //customValidations: updateFromCustomValidations,
        //additionalInputChangeHandler: updateFormAdditionalInputChangeHandler,
        showFormModal: true,
        isUpdateReq: true,
        schema: schema,
        selected: record,
        recordType: record.type,
        sections: [{ name: schema.name, schema: schema }],
        relatedEntityName: entityName,
      });
    }
  };

  const getBadgeColorForRecordStatus = (status: string) => {
    switch (status) {
      case 'to_do':
        return 'grey';
      case 'approved':
        return 'green';
      case 'rejected':
        return 'red';
      default:
        return 'grey';
    }
  };

  const updateRecordStatus = (
    record: DbRecordEntityTransform | undefined,
    status: FPEStatusFilter,
  ) => {
    if (!record || !afp.data) return;

    setIsUpdatingStatus(true);
    let updatePromise = Promise.resolve();
    switch (status) {
      case 'todo':
        updatePromise = clearFpesStatus([record], { ApplicationForPaymentId: afp.data.id });
        break;
      case 'approved':
        updatePromise = approveFpes([record], {
          ApprovedBy: userReducer.user?.id,
          ApplicationForPaymentId: afp.data.id,
        });
        break;
    }

    updatePromise.then(() => setIsUpdatingStatus(false)).catch(() => setIsUpdatingStatus(false));
  };

  const statusClickHandler = (
    record: DbRecordEntityTransform | undefined,
    status: FPEStatusFilter,
  ) => {
    if (isBulkApproval || status === 'rejected') {
      setShowModal(status);
      return;
    }

    updateRecordStatus(record, status);
  };

  return (
    <>
      {!!record && showModal && (
        <BulkApprovalModal
          records={fpesWithNoPaymentNotice}
          action={showModal}
          onClose={() => setShowModal('')}
        />
      )}
      <Row>
        {/* Left side */}
        <Col span={16}>
          <Row>
            <Col style={{ paddingTop: '8px' }}>
              <ModuleEntityIcon
                moduleName={moduleName!}
                entityName={entityName!}
                style={{ margin: '5px 3px', fontSize: '1.3em' }}
              />
            </Col>
            <Col>
              <Row>
                <Col span={24} style={{ marginTop: 1 }}>
                  <span style={{ fontSize: '0.87em' }}>{entityName!}</span>
                </Col>
                <Col span={24} style={{ fontWeight: 500 }}>
                  <Link to={getBrowserPath(record!)} target="_blank">
                    {record?.title || record?.recordNumber}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Right Side */}
        <Col span={8} style={{ textAlign: 'right', paddingTop: '8px' }}>
          <Dropdown
            trigger={['click']}
            disabled={fpesWithNoPaymentNotice.length === 0}
            overlay={
              <Menu>
                {allStatuses
                  .filter((status) => status !== recordStatus)
                  .map((status) => (
                    <Menu.Item
                      key={status}
                      onClick={() => statusClickHandler(record, status)}
                      disabled={isUpdatingStatus}
                    >
                      <Badge
                        color={getBadgeColorForRecordStatus(status)}
                        style={{ marginRight: '5px' }}
                      />
                      {toSentenceCase(status)}
                    </Menu.Item>
                  ))}
              </Menu>
            }
          >
            <Button style={{ marginRight: 10 }} disabled={isUpdatingStatus}>
              <Badge
                key={'orange'}
                color={getBadgeColorForRecordStatus(recordStatus!)}
                text={''}
                style={{ marginRight: 8 }}
              />
              {recordStatus ? toSentenceCase(recordStatus) : 'To do'}
            </Button>
          </Dropdown>

          <Button
            type="default"
            icon={<EyeOutlined />}
            disabled={selectedRecords.length === 0}
            style={{ verticalAlign: 'top', marginRight: 10 }}
            onClick={() =>
              shortListRecord({
                showPreview: true,
                record: record,
              })
            }
          />

          <Tooltip title="Update Record" mouseEnterDelay={0.5}>
            <Button
              type="default"
              disabled={selectedRecords.length === 0}
              style={{ marginRight: 8 }}
              icon={<EditOutlined />}
              onClick={() => initializeUpdateForm(record!)}
            />
          </Tooltip>
        </Col>
      </Row>

      {/* Pipelines */}
      {record?.stage ? (
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Pipeline record={record!} className="record-pipeline" flat />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {/* Divider */}
      {!hideDivider ?
        <Row>
          <Col span={24}>
            <Divider style={{ marginTop: 15, marginBottom: 10 }} />
          </Col>
        </Row>
      :<Row style={{marginBottom:10}}/>}

      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; results: any }) =>
          console.log('%cRecord updated!', 'color:lime', params)
        }
      />
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(RecordViewHeader);
