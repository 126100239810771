import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IUpdateRecordById, updateRecordByIdRequest } from '../../../../store/actions';
import { IUpdateTableRowPropertyById, updateTableRowProperty } from '../../store/actions';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import renderFormField, { FormField } from '../../../Forms/FormFields';
import { isSystemAdmin } from '../../../../../../shared/permissions/rbacRules';
import { Tooltip } from 'antd';
import './styles.scss';
import {
  IUpdateRelatedRecordAssociation,
  updateRecordAssociationRequest,
} from '../../../../../recordsAssociations/store/actions';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../../schemas/store/actions';
interface Props {
  children: any;
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  initialValue: any;
  propertyKey: string;
  updateRecord: (params: IUpdateRecordById, cb: any) => void;
  updateTableRow: (params: IUpdateTableRowPropertyById) => void;
  userReducer: any;
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => void;
  schemaReducer: any;
  // Column mapping control
  hasColumnMapping?: boolean;
  relatedEntityName?: string;
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) => void;
}

const EditableTableField: FC<Props> = (props: Props) => {
  const {
    children,
    record,
    schema,
    initialValue,
    propertyKey,
    userReducer,
    updateTableRow,
    getSchema,
    hasColumnMapping,
    relatedEntityName,
    schemaReducer,
    updateRecordAssociation,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updatedValue, setUpdatedValue] = useState<any>(undefined);
  const [isWarning, setIsWarning] = useState<boolean>(false);

  const handleFieldBlur = (): any => {
    const key = propertyKey.split('.')[1];
    const column = schema?.columns.find((col: SchemaColumnEntity) => col.name === key);

    if (
      updatedValue !== undefined &&
      updatedValue !== initialValue &&
      schema &&
      record &&
      column?.type !== 'ENUM'
    ) {
      let properties: any = {};
      const key = propertyKey.split('.')[1];
      properties[key as string] = updatedValue;
      updateRec(properties);
    } else {
      setIsEditing(false);
      setIsSaving(false);
      setUpdatedValue(undefined);
    }
  };

  useEffect(() => {
    if (isWarning) {
      setTimeout(() => {
        setIsWarning(false);
      }, 1500);
    }
  }, [isWarning]);

  // Only System Admins can edit inline 20-Feb-2024
  const handleDoubleClick = () => {
    if (isSystemAdmin(userReducer)) {
      setIsEditing(true);
    }
  };

  // Update record
  const updateRec = (properties: any) => {
    const { record, updateRecord } = props;
    // a) Updating regular record
    if (schema && record && properties && !hasColumnMapping) {
      setIsSaving(true);
      updateRecord(
        {
          schema: schema,
          recordId: record.id,
          createUpdate: {
            schemaId: schema?.id,
            entity: record.entity,
            properties,
          },
        },
        (res: any) => {
          setIsSaving(false);
          setIsEditing(false);
          setUpdatedValue(undefined);
          const key = propertyKey.split('.')[1];
          const column = schema?.columns.find((col: SchemaColumnEntity) => col.name === key);
          updateTableRow({
            recordId: record.id,
            key: propertyKey,
            value: column && column?.type === 'BOOLEAN' ? String(properties[key]) : properties[key],
          });
        },
      );
    }
    // b) Updating a record with column mapping
    else if (schema && record && properties && hasColumnMapping && relatedEntityName) {
      return updateRecordAssociation(
        {
          relatedEntityName: relatedEntityName,
          parentSchema: schema,
          recordId: record.id,
          dbRecordAssociationId: record.dbRecordAssociation!.id,
          createUpdate: {
            entity: record.entity || '',
            recordId: record.id,
            properties,
          },
        },
        (res: DbRecordEntityTransform) => {
          setIsSaving(false);
          setIsEditing(false);
          setUpdatedValue(undefined);
        },
      );
    }
  };

  const handleChange = (e: any) => {
    setUpdatedValue(e.value);
    const key = propertyKey.split('.')[1];
    const column = schema?.columns.find((col: SchemaColumnEntity) => col.name === key);
    if (column?.type === 'BOOLEAN' || column?.type === 'ENUM') {
      let properties: any = {};
      properties[key] = e.value;
      updateRec(properties);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleFieldBlur();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setIsSaving(false);
      setUpdatedValue(undefined);
    }
  };

  // Generate Form Field
  let field: FormField | undefined = undefined;

  if (schema) {
    const key = propertyKey.split('.')[1];
    const column = schema?.columns.find((col: SchemaColumnEntity) => col.name === key);
    const linkedSchemaAssociation = schema?.associations?.find(
      (s: SchemaAssociationEntity) => s.id === column?.linkedSchemaAssociationId,
    );
    const linkedSchemaTypesConstraint = linkedSchemaAssociation?.schemaTypesConstraints?.find(
      (c: any) => c.id === column?.linkedSchemaTypesConstraintId,
    );

    if (schema && column) {
      field = {
        id: column?.id,
        schemaId: schema?.id,
        entity: schema?.moduleName,
        type: column?.type === 'BOOLEAN' ? 'BOOLEAN_INLINE' : column?.type,
        name: column?.name,
        label: column?.label || column?.name || '',
        description: column?.description,
        defaultValue: initialValue,
        initialValue: initialValue,
        options: column?.options,
        validators: column?.validators,
        isHidden: false,
        customValidation: undefined,
        isDisabled: isSaving,
        handleInputChange: handleChange,
        linkedSchemaAssociation,
        linkedSchemaTypesConstraint,
        selected: record,
        overrideRequired: undefined,
        onBlur: handleFieldBlur,
        onKeyDown: onKeyDown,
        hideLabel: true,
        inline: true,
        autofocus: column?.type === 'LOOKUP',
      };
    }
  }

  return (
    <Tooltip title="Field is not editable" open={isWarning} color="red">
      <div style={{ cursor: 'pointer' }} onDoubleClick={handleDoubleClick}>
        {isEditing && field ? (
          renderFormField(field)
        ) : (
          <span className="hoverableTableCell">
            {children ? (
              <div style={{ display: 'inline-block', width: '100%' }}>{children}</div>
            ) : (
              <div style={{ display: 'inline-block', width: '80%' }} />
            )}
          </span>
        )}
      </div>
    </Tooltip>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  updateTableRow: (params: IUpdateTableRowPropertyById) => dispatch(updateTableRowProperty(params)),
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) =>
    dispatch(updateRecordAssociationRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

export default connect(mapState, mapDispatch)(EditableTableField);
