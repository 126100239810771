import { NonIdealState, Spinner, Tab, Tabs } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { MyCasesContext } from '../..';
import { MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL } from '../../store/constants';
import './styles.scss';
import MyCasesContactLookup from '../MyCasesContactDrawer/MyCasesContactLookup';
import MyCasesContactDetails from '../MyCasesContactDrawer/MyCasesContactDetails';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import RecordProperties from '../../../../../../../core/records/components/RecordProperties';
import GroupDetails from '../../../../../../../shared/components/GroupDetails';

interface Props {
  schema: SchemaEntity;
}

const MyCasesContactDrawer: React.FC<Props> = (props: Props) => {
  const { schema } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [selectedTab, setSelectedTab] = useState<'Contact' | 'Details'>('Contact');
  const { selectedCaseContact } = state;

  const togglePanel = () => {
    dispatch({ type: MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL, payload: null });
  };

  return (
    <Col span={7} className={`myCasesContactDetailsCol singleCase`}>
      <Row style={{ padding: 10 }}>
        <Col span={24}>
          <Tabs selectedTabId={selectedTab} onChange={(e: any) => setSelectedTab(e)}>
            <Tab
              id="Contact"
              title="Contact"
              panel={
                <Col
                  span={24}
                  style={{
                    height: `calc(100vh - 290px)`,
                    overflowY: 'auto',
                  }}
                >
                  {/* Initial Search for Associated Contact */}
                  {state.isSearchingForAssociatedContact && (
                    <div style={{ marginTop: 240 }}>
                      <NonIdealState icon={<Spinner size={40} />} title="Searching for Contact" />
                    </div>
                  )}

                  {/* Missing Contact */}
                  {!selectedCaseContact &&
                    !state.isContactPanelOpen &&
                    !state.isSearchingForAssociatedContact && <MyCasesContactLookup />}

                  {/* Existing Contact */}
                  {selectedCaseContact && !state.isSearchingForAssociatedContact && (
                    <MyCasesContactDetails contact={state.selectedCaseContact!} />
                  )}
                </Col>
              }
            />
            <Tab
              id="Details"
              title="Details"
              panel={
                <Col
                  span={24}
                  style={{
                    height: `calc(100vh - 290px)`,
                    overflowY: 'auto',
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginTop: 0, marginBottom: 15 }}>Record Details</h3>
                      <RecordProperties record={state.selectedCase!} schema={schema} />
                    </Col>
                    <Col span={24} style={{ marginTop: 20 }}>
                      <h3 style={{ marginTop: 0, marginBottom: 15 }}>Groups</h3>
                      <GroupDetails record={state.selectedCase} />
                    </Col>
                  </Row>
                </Col>
              }
            />
          </Tabs>
        </Col>
      </Row>
    </Col>
  );
};
export default MyCasesContactDrawer;
