import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Result, Row } from 'antd';
import history from '../utilities/browserHistory';
import { storeSelectedEntity, storeSelectedModule } from '../../core/navigation/store/actions';
import { Button } from '@blueprintjs/core';

interface Props {
  navigationReducer: any;
  storeSelectedEntity: any;
  storeSelectedModule: any;
  missingPermission?: string;
}

const Error403: FC<Props> = (props: Props) => {
  const { storeSelectedEntity, storeSelectedModule, navigationReducer, missingPermission } = props;

  useEffect(() => {
    storeSelectedModule({ selectedModule: 'Home' });
    storeSelectedEntity({ selectedEntity: '' });
  }, [navigationReducer.selectedModule]);

  return (
    <Result
      status="403"
      title="403"
      subTitle={
        <Row>
          <Col span={24} style={{ marginTop: 15 }}>
            Sorry, you are not authorized to access this page
          </Col>
          {missingPermission ? (
            <>
              <Col span={24} style={{ marginTop: 20 }}>
                <span>You are missing a permission</span>
                <span style={{ color: 'black' }}> {missingPermission}</span>
              </Col>
              <Col span={24} style={{ marginTop: 20, marginBottom: 10 }}>
                Please contact your administrator to request access.
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      }
      extra={
        <Button intent="primary" large onClick={() => history.push('/')}>
          Back Home
        </Button>
      }
    />
  );
};

const mapState = (state: any) => ({
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  storeSelectedModule: (params: { selectedModule: string }) =>
    dispatch(storeSelectedModule(params)),
  storeSelectedEntity: (params: { selectedEntity: string }) =>
    dispatch(storeSelectedEntity(params)),
});

export default connect(mapState, mapDispatch)(Error403);
