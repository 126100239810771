import { Alert, Button, Collapse, Divider, EntityTitle, Section, Spinner } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { httpDelete, httpGet } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { PermissionViewRolesDialog } from '../PermissionViewRolesDialog';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  permission: any;
  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

const PermissionDetails: FC<Props> = (props: Props) => {
  const { permission, onUpdate, initializeForm, alertMessage, onDelete, userReducer } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isDeletePermissionAlertVisible, setIsDeletePermissionAlertVisible] =
    useState<boolean>(false);
  const [copiedIdToClipboard, setCopiedIdToClipboard] = useState<boolean>(false);

  const [roles, setRoles] = useState<any[]>([]);
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(false);

  useEffect(() => {
    if (permission) {
      setRoles([]);
      getAssociatedRoles(permission.id);
    }
  }, [permission]);

  const getAssociatedRoles = async (roleId: string) => {
    setIsLoadingRoles(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/permissions/${roleId}/roles`);
      const roles = res.data?.data;
      if (roles) {
        setRoles(roles);
      }
      setIsLoadingRoles(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated roles. ' + message, type: 'error' });
      setIsLoadingRoles(false);
    }
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit Role') {
      // editRole(params.data);
    }
  };

  const deletePermission = async () => {
    try {
      await httpDelete(`IdentityModule/v1.0/rbac/permissions/${permission.id}`);
      alertMessage({ body: 'Permission deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Error removing message. ' + message, type: 'error' });
    }
  };

  const copyIdToClipboard = (id: any) => {
    navigator.clipboard.writeText(id);
    setCopiedIdToClipboard(true);
    setTimeout(() => {
      setCopiedIdToClipboard(false);
    }, 1500);
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        <SharedFormModal
          formUUID={permission.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5 }}>{permission.name}</h3>
              <span>{permission.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button icon="trash" intent="danger" text="Delete Permission" disabled fill />
            </Col>
            <Col span={12}>
              <Button text="Edit Permission" disabled fill icon="annotation" />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Roles */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Roles ${roles?.length ? `(${roles.length})` : ''}`}
            rightElement={
              roles?.length! > 3 ? (
                <PermissionViewRolesDialog permission={permission} roles={roles} />
              ) : (
                <></>
              )
            }
          >
            <Row>
              {/* Roles list */}
              {roles.slice(0, 3)?.map((role: any) => (
                <Col span={24} key={role.id}>
                  <EntityTitle key={role.id} icon="id-number" title={role.name} />
                </Col>
              ))}
              {/* Show how many more Roles  */}
              {roles.length > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${roles.length - 3} more`}</span>
                </Col>
              )}
              {!roles.length && (
                <Col span={24}>
                  <span>
                    {isLoadingRoles ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Permission ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Permission Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedIdToClipboard ? 'tick' : null}
                text={copiedIdToClipboard ? 'Copied' : 'Copy'}
                intent={copiedIdToClipboard ? 'success' : 'primary'}
                onClick={() => copyIdToClipboard(permission.id)}
              />
            }
          >
            <span>{permission.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Type */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        key="type"
                        title="Type"
                        subtitle={<span>{permission.type || 'None'}</span>}
                      />
                    </Col>

                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 10 }}>
                      <EntityTitle
                        key="updatedAt"
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(permission.updatedAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        key="createdAt"
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(permission.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Delete Role Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeletePermissionAlertVisible}
        onCancel={() => setIsDeletePermissionAlertVisible(false)}
        onClose={() => setIsDeletePermissionAlertVisible(false)}
        onConfirm={deletePermission}
      >
        <p>Are you sure you want to delete this role? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(PermissionDetails);
