import { SCHEDULING_DUMMY_DATA, TScheduleUser } from '../types';
import {
  CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER,
  CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG,
} from './constants';

export interface ICaseManagementSchedulingReducer {
  schedulingUsers: TScheduleUser[];
  isSchedulingUserDialogOpen: boolean;
  selectedSchedulingUser: TScheduleUser | undefined;
}

export const CaseManagementSchedulingReducerInitialState: ICaseManagementSchedulingReducer = {
  schedulingUsers: SCHEDULING_DUMMY_DATA,
  isSchedulingUserDialogOpen: false,
  selectedSchedulingUser: undefined,
};

export function caseManagementSchedulingReducer(
  state: ICaseManagementSchedulingReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG:
      return { ...state, isSchedulingUserDialogOpen: !state.isSchedulingUserDialogOpen };
    case CASE_MANAGEMENT_SCHEDULING_SET_SELECTED_USER:
      return { ...state, selectedSchedulingUser: action.payload };
    default:
      return state;
  }
}
