import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { DualPanelAssignment } from '../../../../../v2/shared/components/DualPanelAssignment';
import './styles.scss';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  template: any;
  groups: any[];
  alertMessage: (params: { body: string; type: string }) => void;
  onUpdate: () => void;
}

type TGroup = {
  id: string;
  name: string;
  description: string;
};

const ManageTemplateGroupsDialog: FC<Props> = (props: Props) => {
  const { template, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allGroups, setAllGroups] = useState<TGroup[]>([]);
  const [templateGroups, setTemplateGroups] = useState<TGroup[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllGroups([]);
    setTemplateGroups([]);
  };

  const openModal = () => {
    loadAllGroups();
    getAllGroupsOnTemplate();
  };

  const getAllGroupsOnTemplate = () => {
    if (props.groups) {
      const groups: TGroup[] =
        props.groups?.map((group: any) => ({
          id: group.id,
          name: group.name,
          description: group.description,
        })) || [];
      setTemplateGroups(groups);
    }
  };

  const loadAllGroups = async () => {
    setIsLoadingGroups(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/groups?size=10000');
      const groups: TGroup[] =
        res.data.data?.map((group: any) => ({
          id: group.id,
          name: group.name,
          description: group.description,
        })) || [];

      // Remove roles that the user already has
      const existingTemplateGroupsIds = props.groups?.map((group: any) => group.id) || [];
      const filteredGroups = groups.filter(
        (group) => !existingTemplateGroupsIds.includes(group.id),
      );

      setAllGroups(filteredGroups);
      setIsLoadingGroups(false);
      setIsDialogOpen(true);
    } catch (e: any) {
      setIsLoadingGroups(false);
    }
  };

  const addGroups = async () => {
    const groupIds =
      templateGroups
        .filter((g) => !props.groups?.map((tg) => tg.id).includes(g.id))
        ?.map((p: any) => p.id) || [];

    if (!groupIds.length || !template) return;
    // console.log('%cdebug: Adding Groups', 'color:limegreen', permissionIds);
    try {
      await httpPost(`IdentityModule/v2.0/onboarding-templates/${template.id}/groups`, {
        groupIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not add groups to a template. ' + message, type: 'error' });
    }
  };

  const removeGroups = async () => {
    const groupIds =
      props.groups
        ?.filter((g) => !templateGroups.map((tg) => tg.id).includes(g.id))
        ?.map((p: any) => p.id) || [];

    if (!groupIds.length || !template) return;
    // console.log('%cdebug: Removing Groups', 'color:salmon', permissionIds);
    try {
      await httpDelete(`IdentityModule/v2.0/onboarding-templates/${template.id}/groups`, {
        groupIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not remove groups from a template. ' + message, type: 'error' });
    }
  };

  const updateGroupsOnTemplate = async () => {
    setIsSaving(true);

    const addGroupsPromise = addGroups();
    const removeGroupsPromise = removeGroups();

    try {
      await Promise.all([addGroupsPromise, removeGroupsPromise]);
      alertMessage({ body: 'Groups updated successfully', type: 'success' });
      setIsSaving(false);
      closeModal();
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update groups on a template. ' + message,
        type: 'error',
      });
      setIsSaving(false);
    }
  };

  const isSaveButtonDisabled = () => {
    const existingTemplateGroupIds = props.groups?.map((group: any) => group.id) || [];
    const templateGroupsIds = templateGroups.map((group) => group.id);

    return JSON.stringify(existingTemplateGroupIds) === JSON.stringify(templateGroupsIds);
  };

  // Add group to user, remove from the groups list
  const onGroupPanelChange = (groupId: string) => {
    const group = allGroups.find((group) => group.id === groupId);
    if (group) {
      setTemplateGroups([...templateGroups, group]);
      setAllGroups(allGroups.filter((group) => group.id !== groupId));
    }
  };

  // Remove group from user, add to the group list
  const onUserPanelChange = (groupId: string) => {
    const group: TGroup | undefined = templateGroups?.find((group) => group.id === groupId);
    if (group) {
      setTemplateGroups(templateGroups.filter((group) => group.id !== groupId));
      setAllGroups([...allGroups, group]);
    }
  };

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingGroups}
        onClick={openModal}
      />
      <Dialog
        title="Manage Groups on Template"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody className="assignRolesDialog">
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Groups"
            leftPanelIcon="people"
            leftPanelSubtitle="All available groups"
            leftPanelData={allGroups}
            onLeftPanelChange={onGroupPanelChange}
            // Right Panel
            rightPanelTitle={template.name}
            rightPanelIcon="manual"
            rightPanelSubtitle="All groups with this template"
            rightPanelData={templateGroups}
            onRightPanelChange={onUserPanelChange}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updateGroupsOnTemplate}
              loading={isSaving}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManageTemplateGroupsDialog);
