import {
  Button,
  Callout,
  Card,
  DialogStep,
  Elevation,
  MultistepDialog,
  Section,
  SectionCard,
  Spinner,
} from '@blueprintjs/core';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';
import { getOdinSchemaByEntity } from '../../../../../shared/utilities/schemaHelpers';

type PropsType = {
  record: any;
  recordReducer: IRecordReducer;
  recordFormReducer: any;
  initializeForm: any;
  onClose?: any;
  onConfirm?: any;
};

const paymentMethodFormUUID = uuidv4();
const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { PAYMENT_METHOD } = SchemaModuleEntityTypeEnums;

const CreateGocardlessMandate: FunctionComponent<PropsType> = (props) => {
  const { record, recordFormReducer, initializeForm, onConfirm } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [paymentMethodSchema, setPaymentMethodRefundSchema] = React.useState<
    SchemaEntity | undefined
  >(undefined);
  const [dialogState, setDialogState] = React.useState<{
    stepOne: {
      address: DbRecordEntityTransform | undefined;
    };
    stepTwo: {
      formData: DbRecordCreateUpdateDto | undefined;
    };
  }>({
    stepOne: {
      address: undefined,
    },
    stepTwo: {
      formData: undefined,
    },
  });
  const [addresses, setAddresses] = React.useState<DbRecordEntityTransform[]>([]);
  const [stepError, setError] = React.useState<any>(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);
  const [schemaActions, setSchemaActions] = React.useState<SchemaActionEntity[]>([]);

  // Step 2: initialize your form schemas
  useEffect(() => {
    if (record) {
      getPaymentMethodRefundSchema();

      // Get the contacts addresses
      httpGet(
        `CrmModule/v1.0/db-associations/Contact/${record.id}/one-relation?entity=Address&withLinks=false`,
      )
        .then((res) => {
          setAddresses(res.data['Address']?.dbRecords || []);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(`SchemaModule/v1.0/schemas-actions`)
        .then((res) => {
          setSchemaActions(res.data.data);
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  }, [record]);

  const getPaymentMethodRefundSchema = async () => {
    const schema = await getOdinSchemaByEntity(BILLING_MODULE, PAYMENT_METHOD);
    setPaymentMethodRefundSchema(schema);
  };

  const paymentMethodCreateAction = schemaActions?.find((action: SchemaActionEntity) => {
    return action.name === 'CreatePaymentMethod';
  });

  function setupStep(newStep: string) {
    let stepNumber = 0;
    if (newStep === 'step-one') {
      stepNumber = 0;
    } else if (newStep === 'step-two') {
      stepNumber = 1;
    } else if (newStep === 'confirm') {
      stepNumber = 2;
    }

    switch (stepNumber) {
      case 0:
        // We might handle some validation here to prevent
        // Double refunds from happening.
        break;
      case 1:
        console.log('HANDLE CASE 1');
        // Save the modified data from step 1 locally
        initializeForm({
          formUUID: paymentMethodFormUUID,
          schemaActionId: paymentMethodCreateAction?.id,
          showFormModal: true,
          hideRecordFormFields: true,
          showInitializing: false,
          isCreateReq: true,
          isCloning: true,
          schema: paymentMethodSchema,
          modified: dialogState.stepTwo?.formData ? [dialogState.stepTwo?.formData] : [],
          sections: [
            {
              name: paymentMethodSchema?.name,
              schema: paymentMethodSchema,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                },
              ],
            },
          ],
        });
        break;
      case 2:
        console.log('HANDLE CASE 2');
        setDialogState({
          ...dialogState,
          stepTwo: {
            formData: {
              ...recordFormReducer.modified[0],
              associations: [
                ...recordFormReducer.modified[0]?.associations,
                ...recordFormReducer.sections[0]?.associations,
              ],
            },
          },
        });
        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    setError(undefined);
    setIsConfirmLoading(true);
    // Add additional data to the form data
    try {
      const newPaymentMethodRes = await httpPost(
        `BillingModule/v1.0/contact/${record.id}/payment-methods`,
        {
          identityName: 'GOCARDLESS',
          bankDetails: {
            accountNumber: dialogState?.stepTwo?.formData?.properties?._AccountNumber,
            branchCode: dialogState?.stepTwo?.formData?.properties?._BranchCode,
          },
          authorizedDirectDebit: true,
          addressId: dialogState.stepOne.address?.id,
        },
      );

      const newPaymentMethodId = newPaymentMethodRes.data?.data?.id;

      setIsConfirmLoading(false);
      setIsDialogOpen(false);

      if (onConfirm) {
        onConfirm(newPaymentMethodId);
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      setError(err?.response?.data?.message);
      console.error('Error:', err);
    }
  }

  return (
    <>
      <Button
        intent="primary"
        outlined
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        New Mandate
      </Button>
      <MultistepDialog
        isOpen={isDialogOpen}
        className="multistep-dialog-gocardless-mandate"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          setDialogState({
            stepOne: {
              address: undefined,
            },
            stepTwo: {
              formData: undefined,
            },
          });
          setIsDialogOpen(false);
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          onClick: () => handleFinalStepSubmit(),
          loading: isConfirmLoading,
          disabled: isConfirmLoading,
        }}
        title={'New Payment Method'}
      >
        <DialogStep
          id="step-one"
          title="Select payment method"
          panel={
            <Section>
              <SectionCard>
                <h3>Select address</h3>
                {addresses &&
                  addresses?.map((address: any) => (
                    <Card
                      interactive={true}
                      elevation={Elevation.TWO}
                      selected={dialogState?.stepOne?.address?.id === address?.id}
                      style={{ marginBottom: 10 }}
                    >
                      <h5 style={{ margin: '0 0 15px 0' }}>{address?.title}</h5>
                      <p>{getProperty(address, 'SalesStatus')}</p>
                      <Button
                        intent={
                          dialogState?.stepOne?.address?.id === address?.id ? 'primary' : 'none'
                        }
                        onClick={() => {
                          setDialogState({
                            ...dialogState,
                            stepOne: {
                              address: address,
                            },
                          });
                          setIsNextDisabled(false);
                        }}
                        disabled={!['ORDER'].includes(getProperty(address, 'SalesStatus'))}
                      >
                        Select
                      </Button>
                    </Card>
                  ))}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="step-two"
          title="Add bank details"
          panel={
            <Section>
              <SectionCard>
                {!paymentMethodCreateAction ? (
                  <Callout intent="danger">
                    <p>Could not locate schema action: CreatePaymentMethod</p>
                  </Callout>
                ) : (
                  <CoreForm
                    type="EMBEDDED"
                    formUUID={paymentMethodFormUUID}
                    isCreateRecord={true}
                    showFormActions={false}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                  />
                )}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              <Callout intent="primary">
                {isConfirmLoading ? (
                  <Spinner size={50} />
                ) : (
                  <div>
                    <p>Clicking submit will setup a new mandate in GoCardless.</p>
                  </div>
                )}
              </Callout>

              {stepError && (
                <SectionCard>
                  <Callout intent="danger">
                    <p>{stepError}</p>
                  </Callout>
                </SectionCard>
              )}
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(CreateGocardlessMandate);
