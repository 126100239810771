import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultCompany: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ICompany {
  name: string;
  id: string;
  disabled: boolean;
}

const UpdateCompanySelect: FC<Props> = (props: Props) => {
  const { entity, defaultCompany, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>();
  const [isLoadingCompanies, setIsLoadingCompanies] = useState<boolean>(false);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    loadAllCompanies();
  }, []);

  useEffect(() => {
    if (defaultCompany) {
      setSelectedCompany({
        name: defaultCompany?.name,
        id: defaultCompany?.id,
        disabled: false,
      });
    } else {
      setSelectedCompany(undefined);
    }
  }, [defaultCompany, sourceRecord]);

  const loadAllCompanies = async () => {
    setIsLoadingCompanies(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/companies?size=10000`);
      const companies = res.data?.data || [];
      setAllCompanies(companies);
      setIsLoadingCompanies(false);
    } catch (error) {
      setIsLoadingCompanies(false);
    }
  };

  // Update Company if different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultCompany?.id) {
      updateSourceRecord(item);
    }
  };

  const updateSourceRecord = async (selectedCompany: ICompany) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        companyId: selectedCompany.id === 'no-company' ? null : selectedCompany.id,
      });

      if (selectedCompany.id === 'no-company') {
        setSelectedCompany(undefined);
      } else {
        setSelectedCompany(selectedCompany);
      }

      setIsUpdating(false);
      onUpdate && onUpdate();
      alertMessage({
        body: `Company updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update company. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderCompany: ItemRenderer<ICompany> = (
    company,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (company.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more companies..."
        />
      );
    } else
      return (
        <MenuItem
          active={company.id === selectedCompany?.id}
          disabled={modifiers.disabled}
          key={company.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={company.name}
        />
      );
  };

  let COMPANIES: ICompany[] = allCompanies.map((c, index) => ({
    id: c.id,
    name: c.name,
    key: c.id,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    COMPANIES = COMPANIES.filter((c) => {
      return c.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  COMPANIES = COMPANIES.slice(0, 100);

  // Append the Search information if there are more than 100 companies
  if (COMPANIES.length === 100) {
    COMPANIES.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Company" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    COMPANIES.unshift({
      id: 'no-company',
      name: '(No Company)',
      disabled: false,
    });
  }

  return (
    <Select<ICompany>
      items={COMPANIES}
      disabled={isLoadingCompanies || allCompanies.length === 0}
      itemRenderer={renderCompany}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedCompany ? 'office' : null}
        alignText="left"
        disabled={isLoadingCompanies || allCompanies.length === 0 || isUpdating}
        loading={isLoadingCompanies}
        text={selectedCompany?.name || 'Select company'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateCompanySelect);
