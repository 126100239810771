import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Menu,
  MenuItem,
  Popover,
  Section,
} from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Editor, { EditorProps } from '@monaco-editor/react';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import Handlebars from 'handlebars';
import { updateRecordByIdRequest } from '../../../../core/records/store/actions';
import { text } from 'stream/consumers';

interface Props {
  sourceRecord: DbRecordEntityTransform;
  schemaReducer: any;
  schema: any;
  isEmailTemplate?: boolean;
  updateRecord: (params: any, cb: any) => void;
}

const HTMLTemplate: React.FC<Props> = (props: Props) => {
  const { sourceRecord, isEmailTemplate, schema, updateRecord } = props;
  const [HTMLEditorValue, setHTMLEditorValue] = useState<string>('');
  const [parsedPreview, setParsedPreview] = useState<string>('');
  const [parsingError, setParsingError] = useState<boolean>(false);
  const [isUpdatingRecord, setIsUpdatingRecord] = useState<boolean>(false);

  useEffect(() => {
    setHTMLEditorValue(getProperty(sourceRecord, 'Body'));
  }, [sourceRecord]);

  useEffect(() => {
    parseBodyWithHandlebars(HTMLEditorValue);
  }, [HTMLEditorValue]);

  const updateBody = () => {
    if (schema && sourceRecord) {
      setIsUpdatingRecord(true);
      updateRecord(
        {
          schema: schema,
          recordId: sourceRecord.id,
          createUpdate: {
            entity: `${schema.moduleName}:${schema.entityName}`,
            type: 'TUBE_FIBER',
            properties: {
              Body: HTMLEditorValue,
            },
          },
        },
        () => {
          setIsUpdatingRecord(false);
        },
      );
    }
  };

  const editorRef: any = useRef();

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
  }

  const insertTextInEditor = (text: string) => {
    var selection = editorRef?.current?.getSelection();
    var id = { major: 1, minor: 1 };
    var op = { identifier: id, range: selection, text: text, forceMoveMarkers: true };
    editorRef?.current?.executeEdits('my-source', [op]);
    console.log('debug: Inserting text into editor', text, editorRef?.current?.getSelection());
  };

  function setJSONEditorTheme(monaco: any) {
    monaco.editor.defineTheme('odinstyle', {
      base: 'vs',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic',
        },
      ],
      colors: {
        'editor.background': '#f5f5f5',
      },
    });
  }

  const isValueUpdated = () => {
    return JSON.stringify(getProperty(sourceRecord, 'Body')) !== JSON.stringify(HTMLEditorValue);
  };

  const parseBodyWithHandlebars = (value: string) => {
    try {
      const template = Handlebars.compile(value);
      const results = template(getProperty(sourceRecord, 'SampleData'));
      if (results) {
        setParsedPreview(results);
        setParsingError(false);
      }
    } catch (error) {
      setParsingError(true);
      setParsedPreview(HTMLEditorValue);
    }
  };

  const parseSubjectWithHandlebars = (value: string) => {
    try {
      const template = Handlebars.compile(value);
      const results = template(getProperty(sourceRecord, 'SampleData'));
      if (results) {
        return results;
      }
    } catch (error) {
      return 'Parsing Error';
    }
  };

  // The function should be able to handle nested objects and arrays of objects.
  function flattenObject(obj: any): any {
    const result: any = {};

    function flattenHelper(obj: any, prefix: string = '') {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          flattenHelper(obj[key], `${prefix}${key}.`);
        } else {
          result[`${prefix}${key}`] = obj[key];
        }
      }
    }
    flattenHelper(obj);
    return Object.keys(result);
    //.sort((a, b) => a.localeCompare(b));
  }

  const getSampleValues = () => {
    const values = flattenObject(getProperty(sourceRecord, 'SampleData'));
    return (
      <Menu>
        {values?.map((value: string) => (
          <MenuItem
            key={value}
            text={value}
            onClick={() => {
              insertTextInEditor(`{{${value}}}`);
            }}
          />
        ))}
      </Menu>
    );
  };

  return (
    <Row style={{ marginBottom: 15 }}>
      <Col span={24}>
        <Section
          icon="code"
          title="HTML Template"
          rightElement={
            <>
              <Popover
                content={getSampleValues()}
                fill={true}
                placement="bottom"
                disabled={
                  parsingError ||
                  isUpdatingRecord ||
                  flattenObject(getProperty(sourceRecord, 'SampleData')).length === 0
                }
              >
                <Button
                  alignText="left"
                  disabled={parsingError || isUpdatingRecord}
                  fill={true}
                  icon="cube-add"
                  rightIcon="caret-down"
                  text="Insert Value"
                />
              </Popover>
              <Button
                icon={parsingError ? 'error' : 'floppy-disk'}
                intent={parsingError ? 'danger' : 'primary'}
                text={parsingError ? 'Parsing Error' : 'Save'}
                loading={isUpdatingRecord}
                disabled={!isValueUpdated() || parsingError || isUpdatingRecord}
                onClick={updateBody}
              />
            </>
          }
        >
          <Row>
            <Col span={12} style={{ height: 600, padding: 15 }}>
              <div style={{ height: '100%' }}>
                <Editor
                  height="100%"
                  theme="odinstyle"
                  width="100%"
                  defaultLanguage="html"
                  value={HTMLEditorValue}
                  defaultValue={HTMLEditorValue}
                  beforeMount={setJSONEditorTheme}
                  onMount={handleEditorDidMount}
                  onChange={(value: any) => setHTMLEditorValue(value)}
                  options={{
                    wordWrap: 'on',
                    minimap: { enabled: false },
                    fontSize: 12,
                    padding: { top: 10, bottom: 10 },
                  }}
                />
              </div>
            </Col>
            <Col span={12} style={{ height: 600, padding: 15 }}>
              <Card
                elevation={3}
                style={{
                  borderRadius: 4,
                  height: '100%',
                  padding: 15,
                  backgroundColor: 'white',
                  border: parsingError ? '1px solid #e8a2a4' : 'transparent',
                  overflowY: 'auto',
                }}
              >
                <Row>
                  {isEmailTemplate && (
                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col>
                          <span style={{ fontWeight: 500, marginRight: 4 }}>Subject:</span>
                          <span>
                            {parseSubjectWithHandlebars(getProperty(sourceRecord, 'Subject'))}
                          </span>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                          <Divider style={{ margin: '8px 0', opacity: 0.5 }} />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col span={24}>
                    <div dangerouslySetInnerHTML={{ __html: parsedPreview }} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Section>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  updateRecord: (params: any, cb: any) => dispatch(updateRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(HTMLTemplate);
