import {
  Button,
  Callout,
  DialogStep,
  MultistepDialog,
  Section,
  SectionCard,
  Spinner,
} from '@blueprintjs/core';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Descriptions } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';
import WorkOrderPendingCallout from '../WorkOrderPendingCallout';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';
import { getOdinSchemaByEntity } from '../../../../../shared/utilities/schemaHelpers';

const stepOneFormUUID = uuidv4();
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

type PropsType = {
  record: any;
  recordReducer: IRecordReducer;
  recordFormReducer: any;
  initializeForm: any;
  onClose?: any;
  onConfirm?: any;
  alertMessage: (params: { body: string; type: string }) => void;
  isDisabled?: boolean;
};

const CreateLinkedWorkOrder: FunctionComponent<PropsType> = (props) => {
  const { record, recordFormReducer, initializeForm, onConfirm, alertMessage } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [workOrderSchema, setWorkOrderSchema] = React.useState<SchemaEntity | undefined>(undefined);
  const [dialogState, setDialogState] = React.useState<{
    stepOne: {
      paymentMethod: DbRecordEntityTransform | undefined;
    };
    stepTwo: {
      formData: DbRecordCreateUpdateDto | undefined;
    };
  }>({
    stepOne: {
      paymentMethod: undefined,
    },
    stepTwo: {
      formData: undefined,
    },
  });

  const [stepError, setError] = React.useState<any>(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);
  const [workOrder, setWorkOrder] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [address, setAddress] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [schemaActions, setSchemaActions] = React.useState<SchemaActionEntity[]>([]);

  useEffect(() => {
    if (record) {
      fetchData();
    }
  }, [record]);

  useEffect(() => {
    if (isDialogOpen) {
      fetchData();
    }
  }, [isDialogOpen]);

  const fetchData = async () => {
    if (record) {
      const schema = await getOdinSchemaByEntity(FIELD_SERVICE_MODULE, WORK_ORDER);
      setWorkOrderSchema(schema);

      // // Get the existing work orders
      httpGet(
        `FieldServiceModule/v1.0/db-associations/WorkOrder/${record.id}/one-relation?entity=Address`,
      )
        .then((res) => {
          const addresses = res.data['Address']?.dbRecords;
          if (addresses) {
            setAddress(addresses[0]);
          }
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(`SchemaModule/v1.0/schemas-actions`)
        .then((res) => {
          setSchemaActions(res.data.data);
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  };

  const createLinkedWorkOrder = schemaActions?.find((action: SchemaActionEntity) => {
    return action.name === 'CreateLinkedWorkOrder';
  });

  function setupStep(newStep: string) {
    let stepNumber = 0;
    if (newStep === 'step-one') {
      stepNumber = 0;
    } else if (newStep === 'step-two') {
      stepNumber = 1;
    } else if (newStep === 'confirm') {
      stepNumber = 2;
    }

    switch (stepNumber) {
      case 0:
        setIsNextDisabled(false);
        break;
      case 1:
        if (!createLinkedWorkOrder) {
          return;
        }
        // Save the modified data from step 1 locally
        initializeForm({
          formUUID: stepOneFormUUID,
          schemaActionId: createLinkedWorkOrder?.id,
          showFormModal: true,
          hideRecordFormFields: true,
          showInitializing: false,
          isCreateReq: true,
          schema: workOrderSchema,
          modified: dialogState.stepTwo?.formData ? [dialogState.stepTwo?.formData] : [],
          sections: [
            {
              name: workOrderSchema?.name,
              schema: workOrderSchema,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                  relationType: RelationTypeEnum.PARENT,
                },
                {
                  entity: address?.entity,
                  recordId: address?.id,
                },
              ],
            },
          ],
        });
        break;
      case 2:
        setDialogState({
          ...dialogState,
          stepTwo: {
            formData: {
              ...recordFormReducer.modified[0],
              title: record.title,
              associations: [
                ...recordFormReducer.modified[0]?.associations,
                ...recordFormReducer.sections[0]?.associations,
              ],
            },
          },
        });
        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    setError(undefined);
    setIsConfirmLoading(true);
    // Add additional data to the form data
    try {
      if (!workOrder) {
        console.log('recordsToUpsert', dialogState?.stepTwo?.formData);
        const createRes = await httpPost(`FieldServiceModule/v1.0/db/bulk-upsert`, {
          recordsToUpsert: [dialogState?.stepTwo?.formData],
        });

        const newWorkOrder = createRes.data?.data?.creates?.[0];

        const getWorkOrderRes = await httpGet(
          `FieldServiceModule/v1.0/db/WorkOrder/${newWorkOrder?.id}`,
        );
        setWorkOrder(getWorkOrderRes.data?.data);

        setIsConfirmLoading(false);
        setIsNextDisabled(true);
        // setIsDialogOpen(false);

        if (onConfirm) {
          onConfirm();
        }
        alertMessage({
          body: 'Successfully created a linked work order.',
          type: 'success',
        });

        if (onConfirm) {
          onConfirm();
        }
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      setError(err?.response?.data?.message);
      console.error('Error:', err);
    }
  }

  function resetState() {
    setIsNextDisabled(true);
    setWorkOrder(undefined);
    // setLinkedWorkOrders(undefined);
    setDialogState({
      stepOne: {
        paymentMethod: undefined,
      },
      stepTwo: {
        formData: undefined,
      },
    });
  }

  return (
    <>
      <Button
        intent="primary"
        outlined
        onClick={() => {
          setIsDialogOpen(true);
        }}
        disabled={props.isDisabled}
      >
        Create Linked Work Order
      </Button>
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        className="multistep-dialog-create-linked-work-order"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          resetState();
          setIsDialogOpen(false);
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
          // tooltipContent: this.state.value === undefined ? "Select an option to continue" : undefined,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading || !!workOrder,
          onClick: () => handleFinalStepSubmit(),
        }}
        title={'Linked Work Order'}
      >
        <DialogStep
          id="step-one"
          title="Check existing work orders"
          panel={
            <Section>
              <WorkOrderPendingCallout record={record} hideEmpty={true} />
            </Section>
          }
        />
        <DialogStep
          id="step-two"
          title="Create work order"
          panel={
            <Section>
              <SectionCard>
                {!createLinkedWorkOrder ? (
                  <Callout intent="danger">
                    <p>Could not locate schema action: CreateLinkedWorkOrder</p>
                  </Callout>
                ) : (
                  <CoreForm
                    type="EMBEDDED"
                    formUUID={stepOneFormUUID}
                    isCreateRecord={true}
                    showFormActions={false}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                  />
                )}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              {!workOrder && (
                <Callout intent="primary">
                  {isConfirmLoading ? (
                    <Spinner size={50} />
                  ) : (
                    <div>
                      <p>Please confirm that you want to create a new linked work order.</p>
                    </div>
                  )}
                </Callout>
              )}

              {stepError && (
                <SectionCard>
                  <Callout intent="danger">
                    <p>{stepError}</p>
                  </Callout>
                </SectionCard>
              )}

              {workOrder && (
                <>
                  <SectionCard>
                    <Descriptions>
                      <Descriptions.Item label="Reference #">
                        {workOrder?.recordNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Type">{workOrder.type}</Descriptions.Item>
                      <Descriptions.Item label="Description">
                        {getProperty(workOrder, 'Description')}
                      </Descriptions.Item>
                    </Descriptions>
                  </SectionCard>

                  <SectionCard>
                    <p>A link to the new work order is below.</p>
                    <Link target="_blank" to={getBrowserPath(workOrder)}>
                      <div>Go to work order</div>
                    </Link>
                  </SectionCard>
                </>
              )}
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CreateLinkedWorkOrder);
