import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';
import CreditNoteSendConfirmation from '../../components/CreditNoteSendConfirmation';
import RefundTransaction from '../../components/RefundTransaction';

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { CREDIT_NOTE, INVOICE, TRANSACTION } = SchemaModuleEntityTypeEnums;

const CreditNoteDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      headerExtras={[
        <CreditNoteSendConfirmation record={record!} />,
        <RefundTransaction record={record} refundSource={CREDIT_NOTE} />,
      ]}
      leftColumn={[
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={BILLING_MODULE}
          associatedRecordEntityName={INVOICE}
          visibleProperties={['Status', 'TotalDue', 'Balance']}
        />,
        <RecordCard
          showActionMenu
          showItemActionMenu
          showWithoutPagination
          record={record}
          displayQuickView
          associatedRecordModuleName={BILLING_MODULE}
          associatedRecordEntityName={TRANSACTION}
          visibleProperties={['Status', 'Amount', 'PaymentMethod']}
        />,
      ]}
    />
  );
};

export default CreditNoteDetailView;
