import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import ProtectedRoute from '../../core/navigation/ProtectedRoute';
import Home from './Home';
import { isExternalUser as checkExternalUser } from '../../shared/permissions/rbacRules';

interface Props {
  userReducer: any;
}

const HomeModuleRoutes = ({ userReducer }: Props) => {
  const isExternalUser = checkExternalUser(userReducer);
  return (
    <Switch>
      {!isExternalUser && (
        <ProtectedRoute
          path="/"
          moduleName={'OVERRIDE'}
          entityName="Dashboard"
          exact
          component={<Home />}
        />
      )}
    </Switch>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(HomeModuleRoutes);
