import { useMemo, useReducer } from 'react';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import {
  fetchEarlierAvailabilityError,
  fetchEarlierAvailabilityRequest,
  fetchEarlierAvailabilityResponse,
  fetchEngineersError,
  fetchEngineersRequest,
  fetchEngineersResponse,
  fetchSchedulesError,
  fetchSchedulesRequest,
  fetchSchedulesResponse,
  fetchUtilizationError,
  fetchUtilizationRequest,
  fetchUtilizationResponse,
  fetchWorkOrdersError,
  fetchWorkOrdersRequest,
  fetchWorkOrdersResponse,
  nextDateFilter,
  previousDateFilter,
  selectSchedule,
  setAppointmentsFilter,
  setDateFilter,
  setEngineersFilter,
  setScheduleFilter,
  setSelectedDate,
  updateWorkOrder,
} from '../store/actions';
import { getInitialState } from '../store/getInitialState';
import { CalendarReducer } from '../store/reducers';
import { AppointmentView, UtilizationData } from '../types';
import { useAction } from '../../../../../ProjectModule/hooks/useAction';
import {
  closeRecordDrawer,
  openRecordDrawer,
} from '../../../../../../core/userInterface/store/actions';
import { getModuleAndEntityNameFromRecord } from '../../../../../../shared/utilities/recordHelpers';

const useRecordDrawerQuickView = () => {
  const globalOpenRecordDrawer = useAction(openRecordDrawer);
  const globalCloseRecordDrawer = useAction(closeRecordDrawer);

  return {
    setQuickView: (record: DbRecordEntityTransform | undefined) => {
      if (record) {
        const { moduleName, entityName } = getModuleAndEntityNameFromRecord(record);
        globalOpenRecordDrawer({
          recordId: record.id,
          moduleName,
          entityName,
        });
      } else {
        globalCloseRecordDrawer(undefined);
      }
    },
  };
};

export const useCalendarReducer = () => {
  const [state, dispatch] = useReducer(CalendarReducer, getInitialState());
  const { setQuickView } = useRecordDrawerQuickView();

  const actions = useMemo(
    () => ({
      fetchSchedulesRequest: () => {
        dispatch(fetchSchedulesRequest());
      },

      fetchSchedulesResponse: (schedules: DbRecordEntityTransform[]) => {
        dispatch(fetchSchedulesResponse(schedules));
      },

      fetchSchedulesError: (error: { message: string }) => {
        dispatch(fetchSchedulesError(error));
      },

      selectSchedule: (recordIds: string[]) => {
        dispatch(selectSchedule(recordIds.filter(Boolean)));
      },

      setScheduleFilter: (filter: string) => {
        dispatch(setScheduleFilter(filter));
      },

      fetchWorkOrdersRequest: () => {
        dispatch(fetchWorkOrdersRequest());
      },

      fetchWorkOrdersResponse: (data: DbRecordEntityTransform[]) => {
        dispatch(fetchWorkOrdersResponse(data));
      },

      fetchWorkOrdersError: (err: { message: string }) => {
        dispatch(fetchWorkOrdersError(err));
      },

      fetchEarlierAvailabilityRequest: () => {
        dispatch(fetchEarlierAvailabilityRequest());
      },

      fetchEarlierAvailabilityResponse: (data: DbRecordEntityTransform[]) => {
        dispatch(fetchEarlierAvailabilityResponse(data));
      },

      fetchEarlierAvailabilityError: (err: { message: string }) => {
        dispatch(fetchEarlierAvailabilityError(err));
      },

      setAppointmentsFilter: (type: AppointmentView) => {
        dispatch(setAppointmentsFilter(type));
      },

      nextDateFilter: () => {
        dispatch(nextDateFilter());
      },

      previousDateFilter: () => {
        dispatch(previousDateFilter());
      },

      setDateFilter: (start: string) => {
        dispatch(setDateFilter(start));
      },

      showItemDetails: (record: DbRecordEntityTransform) => {
        // dispatch(setDetailsRecord(record));
        setQuickView(record);
      },

      hideItemDetails: () => {
        // dispatch(setDetailsRecord(undefined));
        setQuickView(undefined);
      },

      fetchUtilizationRequest: () => {
        dispatch(fetchUtilizationRequest());
      },

      fetchUtilizationResponse: (data: UtilizationData[]) => {
        dispatch(fetchUtilizationResponse(data));
      },

      fetchUtilizationError: (error: { message: string }) => {
        dispatch(fetchUtilizationError(error));
      },

      setSelectedDate: (date: string) => {
        dispatch(setSelectedDate(date));
      },

      fetchEngineersRequest: () => {
        dispatch(fetchEngineersRequest());
      },

      fetchEngineersResponse: (data: DbRecordEntityTransform[]) => {
        dispatch(fetchEngineersResponse(data));
      },

      fetchEngineersError: (error: { message: string }) => {
        dispatch(fetchEngineersError(error));
      },

      setEngineersFilter: (engineerIds: string[]) => {
        dispatch(setEngineersFilter(engineerIds));
      },

      updateWorkOrder: (workOrder: DbRecordEntityTransform, loading = false) => {
        dispatch(updateWorkOrder(workOrder, loading));
      },
    }),
    [],
  );

  return { state, actions };
};
