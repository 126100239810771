import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import {
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IGetRecordById,
} from '../../../records/store/actions';
import { IRecordReducer } from '../../../records/store/reducer';
import {
  getSchemaByIdRequest,
  getSchemaByModuleAndEntityRequest,
  ISchemaById,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  deleteRecordAssociationById,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociations,
} from '../../store/actions';
import { Alert, Button, Tooltip } from '@blueprintjs/core';
import { canUserDeleteRecord } from '../../../../shared/permissions/rbacRules';
import { checkRecordIsLocked } from '../../../../shared/utilities/recordHelpers';

interface Props {
  deleteRecord: any;
  deleteRecordAssociation: any;
  getAssociations: any;
  getSchema: any;
  getSchemaByEntity: (payload: ISchemaByModuleAndEntity, cb: any) => SchemaEntity;
  onListItemActionSuccess?: Function;
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  relatedRecord: DbRecordEntityTransform;
  relation: DbRecordAssociationRecordsTransform;
  schemaReducer: ISchemaReducer;
  userReducer: any;
}

const ListItemActionMenu: FC<Props> = (props: Props) => {
  const {
    onListItemActionSuccess,
    getSchema,
    deleteRecord,
    deleteRecordAssociation,
    relatedRecord,
    relation,
    userReducer,
    record,
    schemaReducer,
  } = props;

  const [confirmDeleteRecordDialogVisible, setConfirmDeleteRecordDialogVisible] =
    useState<boolean>(false);
  const [confirmDeleteRecordRelationDialogVisible, setConfirmDeleteRecordRelationDialogVisible] =
    useState<boolean>(false);

  const [isDeletingRecord, setIsDeletingRecord] = useState<boolean>(false);
  const [isDeletingRecordRelation, setIsDeletingRecordRelation] = useState<boolean>(false);

  const userCanDelete = (): boolean => {
    const { schemaAssociation } = relation;
    if (
      canUserDeleteRecord(userReducer, relation?.schema) &&
      !checkRecordIsLocked(record) &&
      schemaAssociation.isDeletable
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDisabledButtonTooltipContents = (): string => {
    const response: string[] = [];
    if (!canUserDeleteRecord(userReducer, relation?.schema)) {
      response.push('You do not have permission to delete this record');
    }
    if (checkRecordIsLocked(record)) {
      response.push('This record is locked');
    }
    if (!relation.schemaAssociation?.isDeletable) {
      response.push('This relation is not deletable');
    }
    return response.join(', ');
  };

  const renderDeleteRelationButton = (): JSX.Element => {
    return (
      <Tooltip disabled={userCanDelete()} content={getDisabledButtonTooltipContents()}>
        <Button
          icon="unlink"
          intent="danger"
          outlined
          small
          disabled={!userCanDelete()}
          onClick={() => setConfirmDeleteRecordRelationDialogVisible(true)}
        />
      </Tooltip>
    );
  };

  const renderDeleteRecordButton = (): JSX.Element => {
    return (
      <Tooltip disabled={userCanDelete()} content={getDisabledButtonTooltipContents()}>
        <Button
          icon="trash"
          intent="danger"
          outlined
          small
          disabled={!userCanDelete()}
          onClick={() => setConfirmDeleteRecordDialogVisible(true)}
        />
      </Tooltip>
    );
  };

  const deleteRecordAction = () => {
    setIsDeletingRecord(true);
    getSchema({ schemaId: relatedRecord.schemaId }, (result: SchemaEntity) => {
      deleteRecord(
        {
          schema: result,
          recordId: !!relatedRecord ? relatedRecord.id : null,
        },
        () => {
          setIsDeletingRecord(false);
          setConfirmDeleteRecordDialogVisible(false);
          onListItemActionSuccess && onListItemActionSuccess();
        },
      );
    });
  };

  const deleteRecordRelationAction = () => {
    const { schemaAssociation } = relation;
    setIsDeletingRecordRelation(true);
    getSchema({ schemaId: relatedRecord.schemaId }, (result: SchemaEntity) => {
      deleteRecordAssociation(
        {
          schema: result,
          schemaAssociation,
          dbRecordAssociationId:
            relatedRecord && relatedRecord.dbRecordAssociation
              ? relatedRecord.dbRecordAssociation.id
              : null,
        },
        () => {
          setIsDeletingRecordRelation(false);
          setConfirmDeleteRecordRelationDialogVisible(false);
          onListItemActionSuccess && onListItemActionSuccess();
        },
      );
    });
  };

  const renderActions = (): JSX.Element => {
    const { schemaAssociation } = relation;
    if (schemaAssociation.cascadeDeleteChildRecord) {
      return renderDeleteRecordButton();
    } else if (!schemaAssociation.cascadeDeleteChildRecord) {
      return renderDeleteRelationButton();
    } else {
      return <></>;
    }
  };

  return (
    <>
      {/* Delete Record Relation Dialog */}
      <Alert
        intent="danger"
        isOpen={confirmDeleteRecordRelationDialogVisible}
        onClose={() => setConfirmDeleteRecordRelationDialogVisible(false)}
        loading={isDeletingRecordRelation}
        canEscapeKeyCancel={!isDeletingRecordRelation}
        canOutsideClickCancel={!isDeletingRecordRelation}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={deleteRecordRelationAction}
      >
        <p>
          <span style={{ fontWeight: 600 }}>Deleting Relation</span>
        </p>
        <br />
        <p>Please confirm that you want to delete this relation</p>
      </Alert>

      {/* Delete Record Dialog */}
      <Alert
        intent="danger"
        isOpen={confirmDeleteRecordDialogVisible}
        onClose={() => setConfirmDeleteRecordDialogVisible(false)}
        loading={isDeletingRecord}
        canEscapeKeyCancel={!isDeletingRecord}
        canOutsideClickCancel={!isDeletingRecord}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={deleteRecordAction}
      >
        <p>
          <span style={{ fontWeight: 600 }}>Deleting Record</span>
        </p>
        <br />
        <p>Please confirm that you want to delete this record</p>
      </Alert>

      {/* Render Actions */}
      {renderActions()}
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  getSchemaByEntity: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
});

export default connect(mapState, mapDispatch)(ListItemActionMenu);
