import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet } from '../../../../../shared/http/requests';

interface Props {
  defaultValue: any;
  onChange: (value: any) => void;
}

const OrganizationUserLookup: React.FC<Props> = (props: Props) => {
  const { defaultValue, onChange } = props;
  const [isLoadingUserList, setIsLoadingUserList] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);

  // On Component mount, fetch list of users
  useEffect(() => {
    if (userList.length === 0) {
      setIsLoadingUserList(true);
      httpGet(`IdentityModule/v2.0/users?size=10000`).then((res: any) => {
        const users = res.data?.data || [];
        setUserList(users);
        setIsLoadingUserList(false);
      });
    }
  }, []);

  return (
    <Select
      allowClear
      disabled={isLoadingUserList || userList.length === 0}
      showSearch
      defaultValue={defaultValue}
      onChange={onChange}
      onClear={() => onChange(null)}
      filterOption={(input: string, option: any) =>
        option.label?.toLowerCase().indexOf(input?.toLowerCase()) > -1
      }
    >
      {userList.map((user: any) => {
        const username = `${user.firstname} ${user.lastname}`;
        return (
          <Select.Option key={user.id} value={username} label={username}>
            {username}
          </Select.Option>
        );
      })}
    </Select>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(OrganizationUserLookup);
