import { Callout } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';
import { getOdinSchemaByRecord } from '../../../../../shared/utilities/schemaHelpers';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform | undefined;
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  hideEmpty?: boolean;
}

const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';

const PaymentMethodRefundPendingCallout: FC<Props> = (props: Props) => {
  const { record, getAssociations, hideEmpty } = props;
  const [data, setData] = useState<DbRecordEntityTransform[]>([]);
  const [loadingAssociations, setLoadingAssociations] = useState<boolean>(true);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    if (record && schema) {
      loadPaymentMethodRefunds(schema, record);
    }
  }, [record]);

  useEffect(() => {
    if (record && !schema) {
      getSchemas(record.entity);
    }
  }, [record]);

  useEffect(() => {
    if (schema && record) {
      loadPaymentMethodRefunds(schema, record);
    }
  }, [schema]);

  const getSchemas = async (entity: string | undefined) => {
    if (entity && record) {
      const recordSchema = await getOdinSchemaByRecord(record);
      setSchema(recordSchema);
    }
  };

  const loadPaymentMethodRefunds = (schema: SchemaEntity, record: DbRecordEntityTransform) => {
    getAssociations(
      {
        recordId: record.id,
        key: PAYMENT_METHOD_REFUND,
        schema: schema,
        entities: [PAYMENT_METHOD_REFUND],
      },
      (res: any) => {
        if (res && res.results?.[PAYMENT_METHOD_REFUND]?.dbRecords?.length > 0) {
          // Filter out records where record.stage.key not WorkOrderStageCancelled or WorkOrderStageDone
          const filteredWorkOrderList = res.results?.[PAYMENT_METHOD_REFUND]?.dbRecords.filter(
            (elem: DbRecordEntityTransform) => elem.stage?.key !== 'PaymentMethodRefundStageDone',
          );
          setData(filteredWorkOrderList);
        }
        setLoadingAssociations(false);
      },
    );
  };

  return (
    <>
      {!(data?.length < 1 && hideEmpty) && !loadingAssociations ? (
        <Callout
          intent={data?.length > 0 ? 'warning' : 'success'}
          title={data?.length > 0 ? 'Pending refunds' : 'No pending refunds'}
        >
          <div style={{ marginTop: data.length > 0 ? 10 : 0 }}>
            {data?.map((record) => (
              <Link to={getBrowserPath(record)}>
                <div>
                  <div>
                    {record.recordNumber} {record.title} {getProperty(record, 'Amount')} [
                    {record?.stage?.name}]
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Callout>
      ) : (
        <> </>
      )}
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(PaymentMethodRefundPendingCallout);
