import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { FeaturePriceEntryOverride } from '../../../../../containers/ProjectModule/components/ProjectModuleWorkListDetailView/AFPDetails';
import { httpPost } from '../../../../../shared/http/requests';

import { WorkListSubmissionStatus } from './types';
import { DashboardLineItem } from '../hooks/types';

export const submitAdjustments = async (
  workListId: string,
  adjustments: DashboardLineItem[],
  setStatus: (
    workListId: string,
    status: 'queued' | 'submitting' | 'submitted' | 'failed',
    error?: { message: string },
    afp?: DbRecordEntityTransform,
  ) => void,
) => {
  setStatus(workListId, 'submitting');

  try {
    for (const adj of adjustments) {
      const workListId = adj.work_list_id;
      const sageIds = [adj.rate_code];
      const featureId = adj.feature_id;

      await httpPost('ProjectModule/v1.0/WorkList/adjustments', {
        workListId,
        sageIds,
        featureId,
      });
    }
  } catch (error: any) {
    const err = error.response ? error.response.data : error;
    setStatus(workListId, 'failed', err as { message: string }, undefined);
  }
};

export const submitWorkList = async (
  workListId: string,
  featuresToInclude: string[],
  adjustmentsToInclude: string[],
  fpeOverrides: FeaturePriceEntryOverride[],
  setStatus: (
    workListId: string,
    status: 'queued' | 'submitting' | 'submitted' | 'failed',
    error?: { message: string },
    afp?: DbRecordEntityTransform,
  ) => void,
) => {
  try {
    setStatus(workListId, 'submitting');
    const result = await httpPost('ProjectModule/v1.0/ApplicationForPayment/', {
      workListId,
      featuresToInclude,
      adjustmentsToInclude,
      featurePriceEntryOverrides: fpeOverrides,
    })
      .then((res) => res.data.data)
      .then((afp) => {
        if (afp.Report.summary.length) {
          const error = new Error(
            `Some issues found while processing ${afp.Report.summary.length} items:`,
          );
          error.cause = afp.Report.summary;
          throw error;
        }
        return afp;
      });

    setStatus(workListId, 'submitted', undefined, result);
  } catch (error: any) {
    // console.log('[DebugX]', error.response);
    const err = error.response ? error.response.data : error;
    setStatus(workListId, 'failed', err as { message: string }, undefined);
  }
};

export const getSubmissionStatuses = (
  workListIds: string[],
): Record<string, WorkListSubmissionStatus> => {
  return workListIds.reduce((result, workListId) => {
    return {
      ...result,
      [workListId]: { status: 'queued' },
    };
  }, {});
};

const statusesToArray = (statuses: Record<string, WorkListSubmissionStatus>) =>
  Object.values(statuses).map((workListData) => workListData.status);

export const statusesSubmitting = (statuses: Record<string, WorkListSubmissionStatus>): boolean => {
  const submitting = statusesToArray(statuses).some((status) =>
    ['queued', 'submitting'].includes(status),
  );
  return submitting;
};

export const statusesWithErrors = (statuses: Record<string, WorkListSubmissionStatus>): boolean => {
  const errors = statusesToArray(statuses).some((status) => status === 'failed');
  return errors;
};
