import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Button, Col, Divider, Drawer, Input, Row } from 'antd';
import { createContext, FC, useEffect, useReducer, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { httpPost } from '../../../../shared/http/requests';
import { displayMessage } from '../../../../shared/system/messages/store/reducers';
import { getAllSchemaAssociationSchemas } from '../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { closeImportFilesDrawer } from '../../../userInterface/store/actions';
import { IUserInterfaceReducer } from '../../../userInterface/store/types';
import { getRecordAssociationsRequest, IGetRecordAssociations } from '../../store/actions';
import DirectoryBreadcrumbs from './DirectoryBreadcrumbs';
import { DirectoryList } from './DirectoryList';
import FileBrowser from './FileBrowser';
import {
  CLEAR_FILE_SELECTION,
  SET_ALL_RELATED_RECORDS,
  SET_IS_IMPORTING_FILES,
  SET_LOADING_PARENT_ASSOCIATIONS,
  SET_PARENT_RECORD,
  SET_SEARCH_TERM,
  SET_SELECTED_RECORD,
} from './store/constants';
import {
  uploadFileFromAssociationInitialState,
  uploadFileFromAssociationReducer,
} from './store/reducer';
import './styles.scss';

interface Props {
  closeImportDrawer: Function;
  userInterfaceReducer: IUserInterfaceReducer;
  schemaReducer: any;
  getSchema: Function;
  getAssociations: Function;
  alertMessage: Function;
}

export const uploadFileFromAssociationContext = createContext<any>({});

const UploadFileFromAssociation: FC<Props> = (props: Props) => {
  const {
    closeImportDrawer,
    userInterfaceReducer,
    schemaReducer,
    getSchema,
    getAssociations,
    alertMessage,
  } = props;
  const { importFileDrawerVisible, importParentRecord, importFileType } =
    userInterfaceReducer.importFiles;

  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);

  const [state, dispatch] = useReducer(
    uploadFileFromAssociationReducer,
    uploadFileFromAssociationInitialState,
  );

  // When both parent and order item records are available, fetch schemas. Wait for when
  // drawer is visible so we don't overkill the API requests in the UI.
  useEffect(() => {
    if (importParentRecord && importFileDrawerVisible && !parentSchema) {
      getSchemas();
      dispatch({ type: SET_PARENT_RECORD, payload: importParentRecord });
    }
  }, [importFileDrawerVisible, schemaReducer.list]);

  const getSchemas = async () => {
    const parentModule = importParentRecord?.entity?.split(':')[0];
    const parentEntity = importParentRecord?.entity?.split(':')[1];
    const shortlistParentRecordSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      parentModule,
      parentEntity,
    );

    if (shortlistParentRecordSchema) {
      setParentSchema(shortlistParentRecordSchema);
    } else {
      getSchema(
        { moduleName: parentModule, entityName: parentEntity },
        (responseSchema: SchemaEntity) => {
          if (responseSchema) {
            setParentSchema(responseSchema);
          }
        },
      );
    }
  };

  // When parent schema is available, load all associations, go into each one and extract
  // list of all available records to the local reducer
  useEffect(() => {
    if (parentSchema && importParentRecord) {
      const allRelatedRecords: DbRecordEntityTransform[] = [];
      let relatedSchemas = getAllSchemaAssociationSchemas(parentSchema?.associations).map(
        (schema: SchemaEntity) => schema.entityName,
      );
      relatedSchemas = relatedSchemas.filter(
        (schema: any) => !['Note', 'File'].includes(schema.entityName),
      );

        dispatch({type: SET_LOADING_PARENT_ASSOCIATIONS, payload:true})

      getAssociations(
        {
          recordId: importParentRecord?.id,
          schema: parentSchema,
          entities: relatedSchemas,
        },
        (res: any) => {

          dispatch({type: SET_LOADING_PARENT_ASSOCIATIONS, payload:false})
          if (res && res.results) {
            Object.keys(res.results).map((key: any) => {
              if (res.results[key]?.dbRecords?.length! > 0) {
                allRelatedRecords.push(...res.results[key]?.dbRecords);
              }
            });
          }
          if (allRelatedRecords.length > 0) {
            dispatch({ type: SET_ALL_RELATED_RECORDS, payload: allRelatedRecords });
          }
        },
      );
    }
  }, [parentSchema]);

  const onClose = () => {
    closeImportDrawer();
    dispatch({ type: CLEAR_FILE_SELECTION, payload: true });
    dispatch({ type: SET_SELECTED_RECORD, payload: undefined });
  };

  const importFiles = async () => {
    let fileIds: string[] = [];

    for (const file of state.selectedFiles) {
      fileIds.push(file.id);
    }

    dispatch({type:SET_IS_IMPORTING_FILES, payload: true})

    await httpPost(
      `SchemaModule/v1.0/s3/files/${state.parentRecord?.entity}/${state.parentRecord?.id}/upload-internal-files`,
      {
        fileSchemaType: importFileType,
        fileRecordIds: fileIds,
      },
    )
      .then((res: any) => {
        onClose();
        alertMessage({ body: 'Successfully imported files.', type: 'success' });
        dispatch({type:SET_IS_IMPORTING_FILES, payload: false})
      })
      .catch((e: any) => {
        onClose();
        alertMessage({ body: 'There was an error importing the files..', type: 'error' });
        dispatch({type:SET_IS_IMPORTING_FILES, payload: false})
      });
  };

  return (
    <uploadFileFromAssociationContext.Provider value={{ state, dispatch }}>
      <Drawer
        className="uploadFileFromAssociationDrawer"
        width={isMobile ? '100%' : 600}
        title={`Import Files as ${importFileType}`}
        open={importFileDrawerVisible}
        onClose={() => onClose()}
        extra={
          <Button
            type="primary"
            loading={state.importingFiles}
            disabled={!state.selectedFiles.length || state.importingFiles}
            onClick={() => importFiles()}
          >
            {state.selectedFiles.length ? `Import (${state.selectedFiles.length})` : 'Import'}
          </Button>
        }
      >
        {/* Breadcrumbs and Search */}
        <Row style={{ padding: '0 5px' }}>
          <Col span={16}>
            <DirectoryBreadcrumbs />
          </Col>
          <Col span={8}>
            <Input.Search
              size="small"
              allowClear
              placeholder="Quick search..."
              value={state.searchTerm}
              onChange={(e: any) => {
                dispatch({ type: SET_SEARCH_TERM, payload: e.target.value });
              }}
            />
          </Col>
        </Row>

        {/* Divider */}
        <Row>
          <Col span={24}>
            <Divider style={{ marginTop: 10, marginBottom: 0 }} />
          </Col>
        </Row>

        {/* Directory Browser */}
        <DirectoryList />

        {/* File Browser */}
        <FileBrowser />
      </Drawer>
    </uploadFileFromAssociationContext.Provider>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  closeImportDrawer: () => dispatch(closeImportFilesDrawer()),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UploadFileFromAssociation);
