import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaColumnValidatorEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.entity';
import { SchemaColumnValidatorEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import Editor from '@monaco-editor/react';
import { Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import dayjs from 'dayjs';
import { sortByName } from '../../../../../shared/utilities/schemaHelpers';
import { setIsChecked } from '../../../../../shared/utilities/validateDataTypes';
import LookupInput from '../../../../../v2/shared/core/LookupInput';
import FileFormField from '../../Files/FileFormField';
import TagInput from '../../TagInput';
import SignatureField from './SignatureField';

const { TextArea } = Input;
const { Option } = Select;
const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FILE } = SchemaModuleEntityTypeEnums;

export interface InputChangeParams {
  formUUID?: string;
  id: string;
  entity: string;
  value: any;
  association?: DbRecordAssociationCreateUpdateDto;
}

export interface FormField {
  autofocus?: boolean;
  customValidation?: any;
  dateFormat?: string;
  defaultValue: string | number;
  description: string;
  entity: string | undefined;
  form?: FormInstance;
  handleInputChange: any;
  hideLabel?: boolean;
  id: string;
  initialValue: string | null;
  inline?: boolean;
  isDisabled: boolean;
  isHidden: boolean;
  label: string;
  linkedSchemaAssociation?: SchemaAssociationEntity;
  linkedSchemaTypesConstraint?: SchemaAssociationSchemaTypesConstraintEntity;
  name: string;
  onBlur?: Function;
  onKeyDown?: Function;
  options?: SchemaColumnOptionEntity[] | string[];
  overrideRequired?: boolean;
  schemaId: string | undefined;
  selected?: DbRecordEntityTransform;
  type: string;
  validators: SchemaColumnValidatorEntity[];
  customFilterValue?: string;
  customFilterEntity?: string;
}

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

const initialValueForMultiENUM = (value: any) => {
  if (value && value.indexOf(',') > -1) {
    return value.split(',');
  } else if (value && value.indexOf(',') === -1) {
    return [value];
  } else {
    return [];
  }
};

const isJsonValid = async (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const sortENUMFields = (options: any[], noStyling?: boolean) => {
  let sortedOptions = Object.assign(options);

  // If all options have position, sort by position
  if (options.every((option: any) => option.position)) {
    sortedOptions = sortedOptions.sort((a: any, b: any) => a.position - b.position);
  } else {
    // If not, sort by name
    sortedOptions = sortedOptions.sort(sortByName);
  }

  return sortedOptions.map((opt: any, key: any) =>
    noStyling ? (
      <Option value={opt.value} title={opt.label} label={opt.label}>
        <span>{opt.label}</span>
      </Option>
    ) : (
      <Option value={opt.value} title={opt.label} label={opt.label}>
        <span className="select-option-value">
          <b>{opt.label}</b>
        </span>
        <span className="select-option-description">
          <i>{opt.description}</i>
        </span>
      </Option>
    ),
  );
};

export default function renderFormField(field: FormField) {
  function setJSONEditorTheme(monaco: any) {
    monaco.editor.defineTheme('odinstyle', {
      base: 'vs',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic',
        },
        // { token: 'constant', foreground: '#e06c75' },
      ],
      colors: {
        'editor.background': '#f5f5f5',
      },
    });
  }

  if (!field.isHidden) {
    switch (field.type) {
      case SchemaColumnTypes.TEXT:
        return (
          <Form.Item
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            key={field.id}
            name={field.name}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={[
              {
                required:
                  field.validators
                    ?.map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) ||
                  field.overrideRequired ||
                  field.overrideRequired,
              },
            ]}
          >
            <Input
              type="text"
              disabled={field.isDisabled}
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              defaultValue={field.defaultValue}
              placeholder={field.description}
              onChange={(e) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: !!e.target.value ? e.target.value : null,
                })
              }
            />
          </Form.Item>
        );
      case SchemaColumnTypes.TEXT_LONG:
        return (
          <Form.Item
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            key={field.id}
            name={field.name}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) ||
                  field.overrideRequired ||
                  field.overrideRequired,
              },
            ]}
          >
            <TextArea
              rows={4}
              disabled={field.isDisabled}
              defaultValue={field.defaultValue}
              placeholder={field.description}
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              onChange={(e) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: e.target.value ?? null,
                })
              }
            />
          </Form.Item>
        );

      case SchemaColumnTypes.NUMBER:
      case SchemaColumnTypes.CURRENCY:
      case SchemaColumnTypes.PERCENT:
        const validationRules: Rule[] = [
          {
            required:
              field.validators
                .map((elem) => elem.type)
                .includes(SchemaColumnValidatorEnums.REQUIRED) ||
              field.overrideRequired ||
              field.overrideRequired,
          },
        ];
        if (field.customValidation?.numberRange?.min || field.customValidation?.numberRange?.max) {
          validationRules.push({
            validator(rule, value, callback) {
              if (!value) {
                return callback();
              } else {
                let valid =
                  !field.customValidation?.numberRange?.min ||
                  value >= field.customValidation?.numberRange?.min;
                valid =
                  valid &&
                  (!field.customValidation?.numberRange?.max ||
                    value <= field.customValidation?.numberRange?.max);
                if (!valid) {
                  return callback(field.customValidation?.numberRange?.message);
                } else {
                  return callback(undefined);
                }
              }
            },
          });
        }

        return (
          <Form.Item
            key={field.id ? field.id.toString() : field.name}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={validationRules}
          >
            <InputNumber
              // Remove decimals on NUMBER
              precision={field.type === SchemaColumnTypes.NUMBER ? 0 : undefined}
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              defaultValue={Number(field.defaultValue)}
              min={0}
              placeholder={field.description}
              onChange={(value) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: value ?? null,
                })
              }
            />
          </Form.Item>
        );
      case SchemaColumnTypes.DATE:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={
              !!field.initialValue
                ? dayjs(field.initialValue, field.dateFormat || dateFormat)
                : undefined
            }
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <DatePicker
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              key={field.id}
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              defaultValue={
                !!field.initialValue
                  ? dayjs(field.initialValue, field.dateFormat || dateFormat)
                  : undefined
              }
              format={field.dateFormat || dateFormat}
              onChange={(val: any) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: val ? dayjs(val).format(dateFormat) : null,
                })
              }
            />
          </Form.Item>
        );

      case SchemaColumnTypes.DATE_TIME:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={
              !!field.initialValue
                ? dayjs(field.initialValue, field.dateFormat || dateFormat)
                : undefined
            }
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <DatePicker
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              key={field.id}
              showTime={{
                format: 'HH:mm',
              }}
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              defaultValue={
                !!field.initialValue
                  ? dayjs(field.initialValue, field.dateFormat || dateFormat)
                  : undefined
              }
              format={field.dateFormat || dateTimeFormat}
              onChange={(val: any) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: val ? dayjs(val).format(dateTimeFormat) : null,
                })
              }
            />
          </Form.Item>
        );

      case SchemaColumnTypes.ENUM:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue ? field.initialValue : null}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <Select
              popupMatchSelectWidth={false}
              dropdownStyle={{
                zIndex: field.inline ? 99999 : undefined,
                position: field.inline ? 'fixed' : undefined,
              }}
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              allowClear
              key={field.id}
              virtual={false}
              placeholder="Select Option"
              defaultValue={field.defaultValue ? field.defaultValue : null}
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              onChange={(val, option) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: val ?? null,
                  label: (option as any)?.title,
                })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {field.options ? sortENUMFields(field.options) : <Option value="">no options</Option>}
            </Select>
          </Form.Item>
        );

      case SchemaColumnTypes.ENUM_MULTI_SELECT:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={initialValueForMultiENUM(field.initialValue)}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <Select
              autoFocus={field.onBlur && true}
              popupMatchSelectWidth={false}
              dropdownStyle={{
                zIndex: field.inline ? 99999 : undefined,
                position: field.inline ? 'fixed' : undefined,
              }}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              allowClear
              mode="multiple"
              key={field.id}
              defaultValue={field.defaultValue ? String(field.defaultValue).split(',') : []}
              virtual={false}
              placeholder="Select one or more Options"
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              filterOption={(input: any, option: any) => {
                return option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={(val, option) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: val ?? null,
                  label: (option as any)?.title,
                })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {field.options ? (
                sortENUMFields(field.options, true)
              ) : (
                <Option value="">no options</Option>
              )}
            </Select>
          </Form.Item>
        );

      case SchemaColumnTypes.FILE_MULTIPLE:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            hasFeedback
            label={field.label}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            labelCol={{ span: 24 }}
            initialValue={!!field.initialValue ? field.initialValue : undefined}
            rules={[
              {
                message: 'Please upload a file',
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <FileFormField
              mode="FILE_MULTIPLE"
              fileIds={field.initialValue ? field.initialValue.split(',') : []}
              parentSchemaId={field.schemaId}
              onUpdate={(elem: any[]) => {
                if (elem !== undefined && !elem?.includes(undefined)) {
                  field.form &&
                    field.form?.setFieldsValue({
                      [field.name]: elem,
                    });
                  field.handleInputChange({
                    id: `${field.schemaId}#${field.name}`,
                    entity: field.entity,
                    value: elem.length > 0 ? elem.join(',') : null,
                    association: elem.map((id: string) => {
                      if (id) {
                        return {
                          entity: `${SCHEMA_MODULE}:${FILE}`,
                          recordId: id,
                          fieldName: field.name,
                          linkType: field.linkedSchemaAssociation?.label,
                        };
                      }
                    }),
                  });
                }
              }}
            />
          </Form.Item>
        );

      case SchemaColumnTypes.FILE_SINGLE:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            label={field.label}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            hasFeedback
            labelCol={{ span: 24 }}
            initialValue={!!field.initialValue ? field.initialValue : undefined}
            rules={[
              {
                message: 'Please upload a file',
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <FileFormField
              mode="FILE_SINGLE"
              fileIds={field.initialValue ? field.initialValue.split(',') : []}
              parentSchemaId={field.schemaId}
              onUpdate={(elem: any[]) => {
                if (elem !== undefined && !elem?.includes(undefined)) {
                  field.form &&
                    field.form?.setFieldsValue({
                      [field.name]: elem,
                    });
                  field.handleInputChange({
                    id: `${field.schemaId}#${field.name}`,
                    entity: field.entity,
                    value: elem.length > 0 ? elem.join(',') : null,
                    association: elem.map((id: string) => {
                      if (id) {
                        return {
                          entity: `${SCHEMA_MODULE}:${FILE}`,
                          recordId: id,
                          fieldName: field.name,
                          linkType: field.linkedSchemaAssociation?.label,
                        };
                      }
                    }),
                  });
                }
              }}
            />
          </Form.Item>
        );

      case SchemaColumnTypes.SIGNATURE:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={!!field.initialValue ? field.initialValue : undefined}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <div>
              <SignatureField
                signatureFileIds={field.initialValue ? field.initialValue.split(',') : []}
                parentSchemaId={field.schemaId}
                onUpdate={(elem: any[]) => {
                  if (elem !== undefined && !elem?.includes(undefined)) {
                    field.form &&
                      field.form?.setFieldsValue({
                        [field.name]: elem,
                      });
                    field.handleInputChange({
                      id: `${field.schemaId}#${field.name}`,
                      entity: field.entity,
                      value: elem.length > 0 ? elem.join(',') : '',
                      association: elem.map((id: string) => {
                        if (id) {
                          return {
                            entity: `${SCHEMA_MODULE}:${FILE}`,
                            recordId: id,
                            fieldName: field.name,
                            linkType: field.linkedSchemaAssociation?.label,
                          };
                        }
                      }),
                    });
                  }
                }}
              />
            </div>
          </Form.Item>
        );

      case 'BOOLEAN_INLINE':
        return (
          <Form.Item
            key={field.id ? field.id.toString() : field.name}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={setIsChecked(field)}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <Select
              popupMatchSelectWidth={false}
              dropdownStyle={{
                zIndex: field.inline ? 99999 : undefined,
                position: field.inline ? 'fixed' : undefined,
              }}
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              allowClear
              key={field.id}
              virtual={false}
              placeholder="Select Option"
              defaultValue={!!field.defaultValue ? String(field.defaultValue).toUpperCase() : null}
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              onChange={(val, option) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: val ?? null,
                  label: (option as any)?.title,
                })
              }
            >
              <Option value={true}>TRUE</Option>
              <Option value={false}>FALSE</Option>
            </Select>
          </Form.Item>
        );

      case SchemaColumnTypes.BOOLEAN:
        return (
          <Form.Item
            key={field.id ? field.id.toString() : field.name}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={setIsChecked(field)}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <Checkbox
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              disabled={field.isDisabled}
              defaultChecked={setIsChecked(field)}
              onChange={(e) => {
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: e.target.checked,
                });
                field.onBlur && field.onBlur(e);
              }}
            />
          </Form.Item>
        );

      case SchemaColumnTypes.JSON:
        return (
          <Form.Item
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <div style={{ border: '1px solid #d9d9d9' }}>
              <Editor
                height="400px"
                beforeMount={setJSONEditorTheme}
                theme="odinstyle"
                options={{ minimap: { enabled: false } }}
                defaultLanguage="json"
                defaultValue={JSON.stringify(field.initialValue || field.defaultValue, null, '\t')}
                onChange={async (val: any) => {
                  const JSONValid = await isJsonValid(val);
                  if (JSONValid) {
                    field.handleInputChange({
                      id: `${field.schemaId}#${field.name}`,
                      entity: field.entity,
                      value: val ? JSON.parse(val) : null,
                    });
                  }
                }}
              />
            </div>
          </Form.Item>
        );

      case SchemaColumnTypes.TAG:
        return (
          <Form.Item
            key={field.id}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.label}
            labelCol={{ span: 24 }}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <TagInput
              defaultValue={
                // Tags are comma separated strings that need to be parsed as an array.
                field.initialValue?.length
                  ? field.initialValue.indexOf(',') > -1
                    ? field.initialValue.split(',')
                    : [field.initialValue]
                  : []
              }
              onChange={(val: Array<string>) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: val ? val : null,
                })
              }
            />
          </Form.Item>
        );

      case SchemaColumnTypes.LOOKUP:
        if (field.schemaId && field.linkedSchemaAssociation) {
          return (
            <Form.Item
              initialValue={field.initialValue ?? undefined}
              style={{ marginBottom: field.hideLabel ? 0 : undefined }}
              key={field.id}
              name={field.name}
              label={field.hideLabel ? undefined : field.label}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required:
                    field.validators
                      .map((elem) => elem.type)
                      .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
                },
              ]}
            >
              <LookupInput
                initialValue={field.initialValue ?? undefined}
                onKeyDown={field.onKeyDown}
                schemaId={field.schemaId}
                schemaAssociation={field.linkedSchemaAssociation}
                schemaTypesConstraint={field.linkedSchemaTypesConstraint}
                onBlur={field.onBlur}
                autoFocus={field.autofocus}
                parentRecord={field.selected}
                customFilterRecordId={field.customFilterValue}
                customFilterEntity={field.customFilterEntity}
                onChange={(val: any) => {
                  field.handleInputChange({
                    id: `${field.schemaId}#${field.name}`,
                    entity: field.entity,
                    value: val ?? null,
                  });
                }}
              />
            </Form.Item>
          );
        }
        break;

      default:
        return (
          <Form.Item
            key={field.id ? field.id.toString() : field.name}
            name={field.name}
            style={{ marginBottom: field.hideLabel ? 0 : undefined }}
            label={field.hideLabel ? undefined : field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={[
              {
                required:
                  field.validators
                    .map((elem) => elem.type)
                    .includes(SchemaColumnValidatorEnums.REQUIRED) || field.overrideRequired,
              },
            ]}
          >
            <Input
              type="text"
              autoFocus={field.onBlur && true}
              onKeyDown={(e: any) => field.onKeyDown && field.onKeyDown(e)}
              onBlur={(e: any) => field.onBlur && field.onBlur(e.target.value)}
              disabled={field.isDisabled}
              defaultValue={field.defaultValue}
              placeholder={field.description}
              onChange={(e) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: !!e.target.value ? e.target.value : null,
                })
              }
            />
          </Form.Item>
        );
    }
  }
}
