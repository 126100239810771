1;
import { Alert, Button, Section } from '@blueprintjs/core';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPipelinesByModuleAndEntity } from '../../../../../core/pipelines/store/actions';
import {
  getSchemaActionsList,
  ISchemaActionsList,
} from '../../../../../core/schemas/store/actions';
import { toggleViewConfigurationCreateEditDrawer } from '../../../../../core/userInterface/store/actions';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import {
  deleteViewConfiguration,
  getViewConfigurations,
} from '../../components/ViewConfigurations/api';
import ViewConfigurationUpdateModal from '../../components/ViewConfigurations/ViewConfigurationUpdateModal';

interface Props {
  schema: SchemaEntity | undefined;
  getActions: (payload: ISchemaActionsList, cb: any) => void;
  toggleDrawer: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  getPipeline: (payload: any, cb?: any) => void;
}

interface DataType {
  key: string;
  name: string;
  schemaType: string;
  stageId: string;
  action: SchemaActionEntity;
  description: string;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

const ViewConfigurationListView: FC<Props> = (props) => {
  const { schema, getActions, toggleDrawer, alertMessage, getPipeline } = props;

  const [configList, setConfigList] = useState<SchemaActionEntity[]>([]);
  const [isLoadingList, setIsLoadingList] = useState<boolean>(true);
  const [configToUpdate, setConfigToUpdate] = useState<SchemaActionEntity | undefined>(undefined);
  const [isDeletingConfig, setIsDeletingConfig] = useState<boolean>(false);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);
  const [deleteConfigAlertVisible, setDeleteConfigAlertVisible] = useState<boolean>(false);
  const [deleteViewConfigId, setDeleteViewConfigId] = useState<string | undefined>(undefined);

  const getSchemaTypeName = (schema: SchemaEntity, typeId: string) => {
    const target = schema.types.find((type: any) => type.id === typeId);
    if (target) {
      return target.name;
    } else {
      return '';
    }
  };

  // Fetch actions when Schema is provided to the component
  useEffect(() => {
    if (schema) {
      getListOfConfigsForListView(schema);
      getPipeline({ schema }, (data: any) => {
        if (data.length > 0) {
          setPipeline(data[0]);
        }
      });
    }
  }, [schema]);

  const onUpdateOrCreate = () => {
    if (schema) {
      getListOfConfigsForListView(schema);
    }
  };

  const getListOfConfigsForListView = (schema: SchemaEntity) => {
    getViewConfigurations(schema.id).then((res: any) => {
      setConfigList(res);
      setIsLoadingList(false);
    });
  };

  const deleteConfig = () => {
    setIsDeletingConfig(true);

    if (deleteViewConfigId) {
      deleteViewConfiguration(deleteViewConfigId).then((res: any) => {
        if (res) {
          setIsDeletingConfig(false);

          const newActionList = configList.filter(
            (action: any) => action.id !== deleteViewConfigId,
          );
          setConfigList(newActionList);

          alertMessage({
            body: 'View Configuration Deleted',
            type: 'success',
          });
          if (schema) {
            getListOfConfigsForListView(schema);
          }
          closeModal();
        } else {
          alertMessage({
            body: 'View Configuration could not be Deleted',
            type: 'error',
          });
          setIsDeletingConfig(false);
          closeModal();
        }
      });
    }
  };

  const setConfigForUpdate = (action: SchemaActionEntity) => {
    if (action) {
      setConfigToUpdate(action);
      toggleDrawer();
    }
  };

  const clearConfigForUpdate = () => {
    setConfigToUpdate(undefined);
  };

  // Table Columns
  const tableColumns: ColumnsType<any> = [
    {
      title: 'Configuration name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend' as 'descend' | 'ascend' | null,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => (
        <Link to={`/${SCHEMA_MODULE}/ViewConfiguration/${schema?.id}/${record.key}`}>{text}</Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      defaultSortOrder: 'ascend' as 'descend' | 'ascend' | null,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => text,
    },
    {
      title: 'Schema Type',
      dataIndex: 'schemaType',
      key: 'schemaType',
      width: '10%',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Stage',
      dataIndex: 'stageId',
      key: 'stageId',
      width: '10%',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '6%',
      key: 'actions',
      align: 'right',
      render: (text: any, record: any) => (
        <>
          <Button
            icon="edit"
            intent="primary"
            outlined
            onClick={() => setConfigForUpdate(record.action)}
            style={{ marginRight: 8 }}
            small
          />

          <Button
            icon="trash"
            intent="danger"
            outlined
            small
            onClick={() => {
              setDeleteConfigAlertVisible(true);
              setDeleteViewConfigId(record.key);
            }}
          />
        </>
      ),
    },
  ];

  const getPipelineNameById = (id: string) => {
    if (pipeline) {
      return pipeline.stages?.find((stage: any) => stage.id === id)?.name || '-';
    }
  };

  // Table Data
  let tableData: DataType[] = [];
  if (configList.length > 0 && schema) {
    tableData = configList.map((config: any) => {
      return {
        key: config.id,
        name: config.name,
        schemaType: getSchemaTypeName(schema, config.schemaTypeId) || 'DEFAULT',
        stageId: getPipelineNameById(config.stageId) || '-',
        action: config,
        description: config.description,
      };
    });
  }

  const closeModal = () => {
    setDeleteConfigAlertVisible(false);
    setDeleteViewConfigId(undefined);
  };

  return (
    <>
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={deleteConfigAlertVisible}
        onCancel={closeModal}
        onClose={closeModal}
        onConfirm={deleteConfig}
        loading={isDeletingConfig}
      >
        <p>
          Are you sure you want to delete this view configuration? This action cannot be undone.
        </p>
      </Alert>

      <Section
        title="View Configurations"
        rightElement={
          <Button intent="success" icon="plus" onClick={toggleDrawer}>
            Create View Configuration
          </Button>
        }
      >
        <Col span={24}>
          <Table
            size="small"
            bordered={false}
            loading={isLoadingList}
            columns={tableColumns as any}
            dataSource={tableData as any}
          />
        </Col>
        <ViewConfigurationUpdateModal
          isCreate={!configToUpdate}
          configToUpdate={configToUpdate}
          schemaId={schema?.id}
          onSuccess={onUpdateOrCreate}
          onCancel={clearConfigForUpdate}
          pipeline={pipeline}
        />
      </Section>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getActions: (payload: ISchemaActionsList, cb: any) => dispatch(getSchemaActionsList(payload, cb)),
  toggleDrawer: () => dispatch(toggleViewConfigurationCreateEditDrawer()),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getPipeline: (params: { schema: SchemaEntity }, cb: any) =>
    dispatch(getPipelinesByModuleAndEntity(params, cb)),
});

export default connect(mapState, mapDispatch)(ViewConfigurationListView);
