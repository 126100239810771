import React, { FunctionComponent, useState } from 'react';
import { Col, Modal, Row, Tag } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { cleanSchemaShortlist } from '../../core/schemas/store/actions';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';

interface OwnProps {
  isVisible: boolean;
  toggleOdinSettingsModal: any;
  cleanShortlist: Function;
}

type Props = OwnProps;

const OdinSettings: FunctionComponent<Props> = (props) => {
  const { isVisible, toggleOdinSettingsModal, cleanShortlist } = props;

  const [refreshing, setIsRefreshing] = useState<boolean>(false);

  const refreshCacheAndReload = async () => {
    cleanShortlist();
    setIsRefreshing(true);

    if (caches) {
      setTimeout(async () => {
        toggleOdinSettingsModal();
        const names = await caches.keys();
        await Promise.all(names.map((name) => caches.delete(name))).then(() => {
          window.location.reload();
        });
      }, 1000);
    }
  };

  return (
    <Modal
      open={isVisible}
      onOk={() => toggleOdinSettingsModal()}
      onCancel={() => toggleOdinSettingsModal()}
      cancelButtonProps={{ style: { display: 'none' } }}
      title="Odin Settings"
    >
      <Row style={{ textAlign: 'center' }}>
        <Col span={24} style={{ marginTop: 10 }}>
          Odin version:
          {import.meta.env.VITE_VERSION ? (
            <Tag style={{ marginLeft: 8 }}>{import.meta.env.VITE_VERSION}</Tag>
          ) : (
            <span>-</span>
          )}
        </Col>
        <Col span={24}></Col>

        <Col span={24}>
          <Button
            icon={<RedoOutlined />}
            intent="primary"
            disabled={refreshing}
            loading={refreshing}
            style={{ marginTop: 30, marginBottom: 20 }}
            onClick={() => refreshCacheAndReload()}
          >
            Refresh Application
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  cleanShortlist: () => dispatch(cleanSchemaShortlist()),
});

export default connect(mapState, mapDispatch)(OdinSettings);
