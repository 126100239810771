import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { Button, Callout, Tag } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Card, Col, Popconfirm, Row, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import {
  creteWorkOrderAndAppointmentRequest,
  ILoadAppointments,
  loadTimeSlotsRequest,
} from '../../../../../core/appointments/store/actions';
import { parseDateToLocalFormat } from '../../../../../shared/utilities/dateHelpers';

const { ORDER, ADDRESS } = SchemaModuleEntityTypeEnums;
const { ORDER_MODULE } = SchemaModuleTypeEnums;
interface Props {
  orderId: string; // Order
  workOrderType: 'INSTALL' | 'SERVICE' | 'COLLECTION';
  appointmentReason?: string;
  schemaReducer: any;
  getSchema: (params: ISchemaByModuleAndEntity, cb?: any) => void;
  getAssociations: (params: IGetRecordAssociations, cb?: any) => void;
  onSuccess?: (res: any) => void;
  getTimeSlots: (params: any, cb?: any) => void;
  createWOAndAppointment: (params: any, cb?: any) => void; // <- for orders
  orderItems?: DbRecordEntityTransform[];
  workOrderProperties?: any;
  bringBackDate?: boolean;
  handleOnSubmit?: (params: {
    slot: {
      Date: string;
      Config: DbRecordEntityTransform;
    };
    timeBlock: 'AM' | 'PM';
  }) => void;
  selectedAppointment?: any;
  appointmentReducer?: any;
}

const AppointmentScheduler: FC<Props> = (props: Props) => {
  const {
    orderId,
    schemaReducer,
    getSchema,
    getAssociations,
    onSuccess,
    getTimeSlots,
    createWOAndAppointment,
    orderItems,
    workOrderType,
    workOrderProperties,
    bringBackDate,
    handleOnSubmit,
    selectedAppointment,
    appointmentReason,
    appointmentReducer,
  } = props;

  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState<boolean>(true);
  const [isUpdatingTimeSlots, setIsUpdatingTimeSlots] = useState<boolean>(true);
  const [isCreatingAppointment, setIsCreatingAppointment] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [addressRecord, setAddressRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [selectedSchedule, setSelectedSchedule] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [error, setError] = useState<any>(undefined);

  // On Component mount -> fetch Parent record schema (Order or Work Order)
  useEffect(() => {
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      ORDER_MODULE,
      ORDER,
    );
    if (shortListSchema) {
      setParentSchema(shortListSchema);
    } else {
      getSchema(
        {
          moduleName: ORDER_MODULE,
          entityName: ORDER,
        },
        (res: SchemaEntity) => {
          if (res) {
            setParentSchema(res);
          }
        },
      );
    }
  }, []);

  // Fetch Address Record that is associated to the Parent record
  useEffect(() => {
    if (parentSchema && orderId) {
      getAssociations(
        {
          recordId: orderId,
          key: ORDER,
          schema: parentSchema,
          entities: [ADDRESS],
        },
        (res: any) => {
          const relatedAddress = getFirstRelation(res.results, ADDRESS);
          if (relatedAddress) {
            setAddressRecord(relatedAddress);
          } else {
            setIsLoadingTimeSlots(false);
          }
        },
      );
    }
  }, [parentSchema]);

  // Fetch first batch of appointments with the address id
  useEffect(() => {
    if (addressRecord) {
      setTimeSlots([]);
      getSlots(dayjs().format('YYYY-MM-DD'));
    }
  }, [addressRecord]);

  useEffect(() => {
    if (selectedSchedule) {
      setTimeSlots([]);
      getSlots(dayjs().format('YYYY-MM-DD'));
    }
  }, [selectedSchedule]);

  // Get time slots
  const getSlots = (startDate: string) => {
    setIsUpdatingTimeSlots(true);

    let contractor = null;
    let svcCallReason = appointmentReason;
    console.log('svcCallReason: ', svcCallReason);

    if (svcCallReason?.startsWith('YF_')) {
      contractor = {
        operator: 'eq',
        value: 'YOUFIBRE',
      };
    } else if (svcCallReason?.startsWith('NO_')) {
      contractor = {
        operator: 'notEq',
        value: 'YOUFIBRE',
      };
    }

    console.log('contractor: ', contractor);
    if (selectedSchedule) {
      console.log('selectedSchedule: ', selectedSchedule);
      contractor = getProperty(selectedSchedule, 'Contractor');
    }

    getTimeSlots(
      {
        start: startDate,
        end: dayjs(startDate).add(6, 'days').format('YYYY-MM-DD'),
        type: workOrderType || 'INSTALL',
        addressId: addressRecord?.id || null,
        exPolygonId: getProperty(addressRecord, 'exPolygonId'),
        scheduleId: selectedSchedule?.id || null,
        contractor,
      },
      (res: any) => {
        if (res?.data?.length > 0) {
          setTimeSlots(res.data);
          setStartDate(dayjs(res.data[0].Date).format('DD MMMM YYYY'));
          setEndDate(dayjs(res.data[res.data.length - 1].Date).format('DD MMMM YYYY'));
        }
        setIsLoadingTimeSlots(false);
        setIsUpdatingTimeSlots(false);
      },
    );
  };

  const createAppointment = (apt: 'AM' | 'PM', slot: any) => {
    if (handleOnSubmit) {
      return handleOnSubmit({
        slot,
        timeBlock: apt,
      });
    }

    setIsCreatingAppointment(true);

    // When parent record is Order, we use an endpoint that will create
    // both Work Order and the Appointment, using the order record id.
    createWOAndAppointment(
      {
        orderId: orderId,
        createUpdate: {
          Date: slot.Date,
          scheduleId: slot.Config?.id,
          optimizer: slot.Config?.optimizer,
          TimeBlock: apt,
          Type: workOrderType,
          skipCustomerNotification: true,
          orderItems: orderItems || null,
          properties: workOrderProperties || null,
        },
      },
      (res: any) => {
        if (res) {
          console.log('%cDebug -> Created Work Order & Appointment!', 'color: lime', res);

          if (onSuccess && !bringBackDate) {
            onSuccess(true);
          } else if (onSuccess && bringBackDate && res?.length > 0) {
            const woId = res[0]?.id || null;
            onSuccess({ date: `${slot.Date} (${apt})`, woId });
          }
        } else {
          console.log('%cDebug -> Work Order & Appointment Creation Failed!', 'color: red');
        }
        setIsCreatingAppointment(false);
      },
    );
  };

  const onScheduleSelect = (schedule: DbRecordEntityTransform) => {
    setSelectedSchedule(schedule);
  };

  const isInterfaceDisabled = () => {
    return isLoadingTimeSlots || isCreatingAppointment;
  };

  const renderButtonColor = (slot: any, block: 'AM' | 'PM') => {
    // AM
    if (block === 'AM') {
      if (
        slot.AM &&
        selectedAppointment?.slot?.Date === slot.Date &&
        selectedAppointment?.timeBlock === 'AM'
      ) {
        return '#248551';
      } else if (
        (slot.AM && selectedAppointment?.slot?.Date !== slot.Date) ||
        (slot.AM && selectedAppointment?.timeBlock !== 'AM')
      ) {
        return '#daf1dc';
      } else {
        return '#efefef';
      }
    }
    // PM
    else {
      if (
        slot.PM &&
        selectedAppointment?.slot?.Date === slot.Date &&
        selectedAppointment?.timeBlock === 'PM'
      ) {
        return '#248551';
      } else if (
        (slot.PM && selectedAppointment?.slot?.Date !== slot.Date) ||
        (slot.PM && selectedAppointment?.timeBlock !== 'PM')
      ) {
        return '#daf1dc';
      } else {
        return '#efefef';
      }
    }
  };

  const renderWeeklyTimeSlots = () => {
    if (timeSlots.length > 0) {
      return timeSlots.map((slot: any, index: number) => {
        return (
          <Col span={3} key={`col1-${slot.Date}${selectedSchedule?.id}`}>
            <Card
              key={`card-${slot.Date}${selectedSchedule?.id}`}
              title={
                <>
                  <span style={{ fontSize: '0.8em' }}>{dayjs(slot.Date).format('dddd')}</span>
                  <br />
                  <span style={{ fontWeight: 'normal', fontSize: '0.7em' }}>
                    {dayjs(slot.Date).format('DD/MM/YYYY')}
                  </span>
                </>
              }
              className={
                ['Saturday', 'Sunday'].includes(dayjs(slot.Date).format('dddd')) ? 'weekend' : ''
              }
            >
              <Row key={`row1-${slot.Date}${selectedSchedule?.id}`}>
                <Col
                  span={24}
                  style={{ textAlign: 'center' }}
                  key={`col2-${slot.Date}${selectedSchedule?.id}`}
                >
                  <Popconfirm
                    key={`popconfirm-am-${slot.Date}${selectedSchedule?.id}`}
                    disabled={isUpdatingTimeSlots || !slot.AM}
                    title={`Please confirm you want to schedule installation to ${dayjs(
                      slot.Date,
                    ).format('dddd, MMM DD')} (AM)`}
                    onConfirm={() => createAppointment('AM', slot)}
                    okText="Yes"
                    cancelText="No"
                    destroyTooltipOnHide
                  >
                    <Button
                      key={`button-am-${slot.Date}${selectedSchedule?.id}`}
                      disabled={isUpdatingTimeSlots || !slot.AM}
                      intent={
                        selectedAppointment?.slot?.Date === slot.Date &&
                        selectedAppointment.timeBlock === 'AM'
                          ? 'success'
                          : 'none'
                      }
                      rightIcon={
                        selectedAppointment?.slot?.Date === slot.Date &&
                        selectedAppointment.timeBlock === 'AM' &&
                        'tick'
                      }
                      large
                      style={{
                        width: '98%',
                        background: renderButtonColor(slot, 'AM'),
                      }}
                    >
                      AM
                    </Button>
                  </Popconfirm>
                </Col>
                <Col
                  span={24}
                  style={{ textAlign: 'center', marginTop: 10 }}
                  key={`col3-${slot.Date}${selectedSchedule?.id}`}
                >
                  <Popconfirm
                    key={`popconfirm-pm-${slot.Date}${selectedSchedule?.id}`}
                    disabled={isUpdatingTimeSlots || !slot.PM}
                    title={`Please confirm you want to schedule installation to ${dayjs(
                      slot.Date,
                    ).format('dddd, MMM DD')} (PM)`}
                    onConfirm={() => createAppointment('PM', slot)}
                    okText="Yes"
                    cancelText="No"
                    destroyTooltipOnHide
                  >
                    <Button
                      key={`button-pm-${slot.Date}${selectedSchedule?.id}`}
                      disabled={isUpdatingTimeSlots || !slot.PM}
                      intent={
                        selectedAppointment?.slot?.Date === slot.Date &&
                        selectedAppointment.timeBlock === 'PM'
                          ? 'success'
                          : 'none'
                      }
                      rightIcon={
                        selectedAppointment?.slot?.Date === slot.Date &&
                        selectedAppointment.timeBlock === 'PM' &&
                        'tick'
                      }
                      large
                      style={{
                        width: '98%',
                        background: renderButtonColor(slot, 'PM'),
                      }}
                    >
                      PM
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Card>
          </Col>
        );
      });
    }
  };

  return (
    <Row>
      <>
        <Col span={24}>
          {!error && (
            <Row style={{ marginTop: 20 }} align="top">
              {/* Date Span */}
              <Col span={15}>
                <Row>
                  <div>
                    <Tag style={{ margin: 2 }} intent={'primary'} minimal>
                      {appointmentReducer?.start
                        ? `${parseDateToLocalFormat(
                            appointmentReducer.start,
                          )} - ${parseDateToLocalFormat(appointmentReducer.end)}`
                        : ''}
                    </Tag>
                    <Tag style={{ margin: 2 }} intent={'primary'} minimal>
                      {appointmentReducer?.type ? appointmentReducer.type : ''}
                    </Tag>
                    <Tag style={{ margin: 2 }} intent={'primary'} minimal>
                      {appointmentReducer?.contractor
                        ? `${appointmentReducer.contractor?.operator} ${appointmentReducer.contractor?.value}`
                        : 'ALL'}
                    </Tag>
                  </div>
                </Row>
              </Col>
              <Col span={9} style={{ textAlign: 'right' }}>
                <Row>
                  {/*<Col span={24} style={{ marginBottom: 12 }}>*/}
                  {/*  {addressRecord && (*/}
                  {/*    <ScheduleSelect*/}
                  {/*      disabled={isInterfaceDisabled()}*/}
                  {/*      addressRecord={addressRecord}*/}
                  {/*      WORecordType={workOrderType}*/}
                  {/*      onScheduleSelect={onScheduleSelect}*/}
                  {/*      onError={setError}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</Col>*/}
                  <Col span={24}>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Button
                          disabled={isInterfaceDisabled()}
                          intent="primary"
                          onClick={() =>
                            getSlots(dayjs(startDate).subtract(6, 'days').format('YYYY-MM-DD'))
                          }
                          style={{ width: '100%' }}
                        >
                          <CaretLeftFilled />
                          <span>Previous Week</span>
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          disabled={isInterfaceDisabled()}
                          intent="primary"
                          onClick={() =>
                            getSlots(dayjs(startDate).add(6, 'days').format('YYYY-MM-DD'))
                          }
                          style={{ width: '100%' }}
                        >
                          <span>Next Week</span>
                          <CaretRightFilled />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={24} style={{ margin: '30px 0' }}>
          {/* Show Loader */}
          {(isLoadingTimeSlots && !error) ||
            (isCreatingAppointment && !error && (
              <Col span={24} style={{ minHeight: 250 }}>
                <Row style={{ textAlign: 'center', margin: '30px 0' }}>
                  <Col span={24}>
                    <Spin size="large" />
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                    <Typography.Title level={5}>
                      {isLoadingTimeSlots ? 'Loading Time Slots...' : 'Creating Appointment...'}
                    </Typography.Title>
                  </Col>
                </Row>
              </Col>
            ))}
          {/* Show Time Slots */}
          {!isLoadingTimeSlots && !error && !isCreatingAppointment && (
            <Row
              style={{ opacity: isLoadingTimeSlots || isUpdatingTimeSlots ? 0.3 : 1 }}
              justify="space-between"
            >
              {timeSlots.length > 0 ? renderWeeklyTimeSlots() : <></>}
            </Row>
          )}
          {/* Show Error */}

          {error && (
            <Row>
              <Col span={24}>
                <Callout title="Error Loading Appointments" intent="danger">
                  {error}
                </Callout>
              </Col>
            </Row>
          )}
        </Col>
      </>
    </Row>
  );
};

const mapState = (state: any) => ({
  appointmentReducer: state.appointmentReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getTimeSlots: (params: ILoadAppointments, cb: any) => dispatch(loadTimeSlotsRequest(params, cb)),
  createWOAndAppointment: (params: any, cb: () => {}) =>
    dispatch(creteWorkOrderAndAppointmentRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(AppointmentScheduler);
