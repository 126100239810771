import { Icon } from '@blueprintjs/core';
import React from 'react';
import './styles.scss';

interface Props {
  type: string;
  small?: boolean;
}

const CommunicationsIcon: React.FC<Props> = (props: Props) => {
  const { type, small } = props;
  const iconSize = small ? 12 : 16;

  const renderIcon = () => {
    switch (type) {
      case 'CALL':
        return <Icon size={iconSize} icon="phone" />;
      case 'SMS':
        return <Icon size={iconSize} icon="comment" />;
      case 'EMAIL':
        return <Icon size={iconSize} icon="envelope" />;
      default:
        return <Icon size={iconSize} icon="star" />;
    }
  };

  return <div className={`communicationsIcon ${type} ${small ? 'small' : ''}`}>{renderIcon()}</div>;
};
export default CommunicationsIcon;
