import { ReloadOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Card, Col, Descriptions, Row, Spin } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { getETF } from '../../../../../core/workflow/store/actions';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform | undefined; // Order record
  getETFWithOrderId: Function;
}

type ETFResponse = {
  paidInvoiceTotal: number;
  contractLength: number;
  terminationFee: number;
  contractTotal: number;
};

const OrderEarlyTerminationFeeCalculator: FC<Props> = (props: Props) => {
  const { record, getETFWithOrderId } = props;
  const [data, setData] = useState<ETFResponse | undefined>(undefined);
  const [isRequestingData, setIsRequestingData] = useState<boolean>(false);

  const getEarlyTerminationFee = () => {
    setIsRequestingData(true);
    getETFWithOrderId({ orderId: record?.id }, (response: ETFResponse) => {
      if (response) {
        setData(response);
      }
      setIsRequestingData(false);
    });
  };

  const renderCardBody = (data: ETFResponse | undefined) => {
    if (isRequestingData) {
      return (
       <Row>
         <Col span={24} style={{ textAlign: 'center', paddingTop: 25 }}>
           <Spin />
         </Col>
       </Row>
      );
    } else if (!isRequestingData && !data) {
      return (
       <Row>
         <Col
          span={24}
          style={{
            textAlign: 'center',
            padding: '25px 0',
            borderRadius: 5,
            border: '1px solid #efefef'
          }}
         >
           <Button
            type="primary"
            disabled={data !== undefined}
            loading={isRequestingData}
            onClick={() => getEarlyTerminationFee()}
           >
             Get ETF value
           </Button>
         </Col>
       </Row>
      );
    } else if (!isRequestingData && data) {
      return (
       <Row>
         <Col span={24}>
           <Descriptions bordered column={1} size="small">
             {/* Contract Length */}
             <Descriptions.Item label="Contract Length">
               {data?.contractLength !== null
                ? data?.contractLength + ' months'
                : '-'}
             </Descriptions.Item>

             {/* Contract Value Total */}
             <Descriptions.Item label="Contract Value Total">
               {data?.contractTotal !== null
                ? '£' + data?.contractTotal?.toFixed(2)
                : '-'}
             </Descriptions.Item>

             {/* Paid Invoice Total */}
             <Descriptions.Item label="Paid Invoice Total">
               {data?.paidInvoiceTotal !== null
                ? '£' + data?.paidInvoiceTotal?.toFixed(2)
                : '-'}
             </Descriptions.Item>

             {/* Termination Fee */}
             <Descriptions.Item label="Termination Fee">
               {data?.terminationFee !== null
                ? '£' + data?.terminationFee?.toFixed(2)
                : '-'}
             </Descriptions.Item>

           </Descriptions>
         </Col>
       </Row>
      );
    }
  };

  return record ? (
   <Row>
     <Col span={24} style={{ padding: 0 }}>
       <Card
        title="Early Termination Fee"
        className="early-termination-fee"
        extra={
          data !== undefined ? (
           <Button
            disabled={isRequestingData}
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => {
              getEarlyTerminationFee();
            }}
           />
          ) : (
           <></>
          )
        }
       >
         {renderCardBody(data)}
       </Card>
     </Col>
   </Row>
  ) : (
   <></>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getETFWithOrderId: (params: {
    orderId: string
  }, cb: any) => dispatch(getETF(params, cb))
});

export default connect(mapState, mapDispatch)(OrderEarlyTerminationFeeCalculator);
