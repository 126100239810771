import { Button, InputGroup, Section } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Checkbox, Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import Typography from '../../../../shared/core/Typography';

interface Props {
  schema: SchemaEntity | undefined;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ViewConfigurationRecordProperties: React.FC<Props> = (props: Props) => {
  const { schema, alertMessage } = props;
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const renderProperties = () => {
    if (schema) {
      return schema.columns.map((column: SchemaColumnEntity) => {
        if (column.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          return (
            <Col span={24}>
              <Checkbox
                checked={selectedProperties.includes(column.name)}
                value={column.name}
                onChange={handleCheckboxSelect as any}
              >
                <Typography>{column.name}</Typography>
              </Checkbox>
            </Col>
          );
        }
      });
    }
  };

  const handleCheckboxSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedProperties([...selectedProperties, value]);
    } else {
      setSelectedProperties(selectedProperties.filter((property: string) => property !== value));
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const copyPropertiesToClipboard = () => {
    const serializedSelectedProperties = JSON.stringify(selectedProperties);
    navigator.clipboard.writeText(serializedSelectedProperties);
    alertMessage({
      body: 'Selected properties copied to clipboard',
      type: 'success',
    });
  };

  return (
    <Section
      style={{ height: 750, overflowY: 'scroll' }}
      title="Properties"
      rightElement={
        <>
          <Button
            icon="duplicate"
            intent="primary"
            outlined
            disabled={selectedProperties.length === 0}
            onClick={copyPropertiesToClipboard}
          >
            Copy {selectedProperties.length > 0 ? `(${selectedProperties.length})` : ''}
          </Button>
          <Button
            intent="danger"
            outlined
            icon="trash"
            disabled={selectedProperties.length === 0}
            onClick={() => setSelectedProperties([])}
          />
        </>
      }
    >
      <Row style={{ padding: 10 }}>
        <InputGroup
          fill
          rightElement={
            <Button
              icon="delete"
              onClick={() => setSearchQuery('')}
              disabled={searchQuery.length === 0}
            />
          }
          id="text-input"
          value={searchQuery}
          placeholder="Search Properties"
          leftIcon="search"
          onChange={handleSearch}
        />
      </Row>
      <Row style={{ padding: 10 }}>{renderProperties()}</Row>
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ViewConfigurationRecordProperties);
