import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { FC, useContext } from 'react';
import BillingRequestDetailView from '../../../modules/BillingModule/views/BillingRequestDetailView';
import CreditNoteDetailView from '../../../modules/BillingModule/views/CreditNoteDetailView';
import InvoiceDetailView from '../../../modules/BillingModule/views/InvoiceDetailView';
import InvoiceItemDetailView from '../../../modules/BillingModule/views/InvoiceItemDetailView';
import PaymentMethodDetailView from '../../../modules/BillingModule/views/PaymentMethodDetailView';
import PaymentMethodRefundDetailView from '../../../modules/BillingModule/views/PaymentMethodRefundDetailView';
import TransactionDetailView from '../../../modules/BillingModule/views/TransactionDetailView';
import AccountDetailView from '../../../modules/CrmModule/views/AccountDetailView';
import AddressDetailView from '../../../modules/CrmModule/views/AddressDetailView';
import ContactDetailView from '../../../modules/CrmModule/views/ContactDetailView';
import ContactIdentityDetailView from '../../../modules/CrmModule/views/ContactIdentityDetailView';
import CrmDataSetDetailView from '../../../modules/CrmModule/views/CrmDataSetDetailView';
import LeadDetailView from '../../../modules/CrmModule/views/LeadDetailView';
import VisitDetailView from '../../../modules/CrmModule/views/VisitDetailView';
import ServiceAppointmentConfigDetailView from '../../../modules/FieldServiceModule/views/ServiceAppointmentConfigDetailView';
import WorkOrderDetailView from '../../../modules/FieldServiceModule/views/WorkOrderDetailView';
import FileDetailView from '../../../modules/FileModule/views/FileDetailView';
import BillingAdjustmentDetailView from '../../../modules/OrderModule/views/BillingAdjustmentDetailView';
import OrderDetailView from '../../../modules/OrderModule/views/OrderDetailView';
import ApplicationForPaymentDetailView from '../../../modules/ProjectModule/views/ApplicationForPaymentDetailView';
import ExchangeDetailView from '../../../modules/ProjectModule/views/ExchangeDetailView';
import FeatureDetailView from '../../../modules/ProjectModule/views/FeatureDetailView';
import FeaturePriceEntryDetailView from '../../../modules/ProjectModule/views/FeaturePriceEntryDetailView';
import PaymentNoticeDetailView from '../../../modules/ProjectModule/views/PaymentNoticeDetailView';
import ProgramDetailView from '../../../modules/ProjectModule/views/ProgramDetailView';
import ProjectDetailView from '../../../modules/ProjectModule/views/ProjectDetailView';
import RegionDetailView from '../../../modules/ProjectModule/views/RegionDetailView';
import WorkListDetailView from '../../../modules/ProjectModule/views/WorkListDetailView';
import CaseDetailView from '../../../modules/SupportModule/views/CaseDetailView';
import DetailView from '../../views/DetailView';
import { DetailViewContext } from '../DetailViewContextProvider';
import UserDetailView from '../../../modules/IdentityModule/views/UserDetailView';
import { DownloadRecordPdf } from '../../core/DownloadRecordPdf';

const {
  CONTACT,
  LEAD,
  ACCOUNT,
  ADDRESS,
  CONTACT_IDENTITY,
  VISIT,
  INVOICE,
  BILLING_ADJUSTMENT,
  ORDER,
  BILLING_REQUEST,
  CREDIT_NOTE,
  INVOICE_ITEM,
  PAYMENT_METHOD,
  TRANSACTION,
  SERVICE_APPOINTMENT_CONFIG,
  WORK_ORDER,
  FILE,
  EXCHANGE,
  PROGRAM,
  PROJECT,
  REGION,
  FEATURE,
} = SchemaModuleEntityTypeEnums;

const CASE = 'Case';
const USER = 'User';

const CRM_DATASET = 'CrmDataset';
const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';
const APPLICATION_FOR_PAYMENT = 'ApplicationForPayment';
const FEATURE_PRICE_ENTRY = 'FeaturePriceEntry';
const PAYMENT_NOTICE = 'PaymentNotice';
const WORK_LIST = 'WorkList';

const DetailViewRouter: FC = () => {
  const { schema } = useContext(DetailViewContext);

  const detailViewController = () => {
    if (schema) {
      const { entityName } = schema;

      switch (entityName) {
        // CRM MODULE
        case ACCOUNT:
          return <AccountDetailView />;
        case ADDRESS:
          return <AddressDetailView />;
        case CONTACT:
          return <ContactDetailView />;
        case CONTACT_IDENTITY:
          return <ContactIdentityDetailView />;
        case LEAD:
          return <LeadDetailView />;
        case VISIT:
          return <VisitDetailView />;
        case CRM_DATASET:
          return <CrmDataSetDetailView />;
        // BILLING MODULE
        case INVOICE:
          return <InvoiceDetailView />;
        case BILLING_REQUEST:
          return <BillingRequestDetailView />;
        case CREDIT_NOTE:
          return <CreditNoteDetailView />;
        case INVOICE_ITEM:
          return <InvoiceItemDetailView />;
        case PAYMENT_METHOD:
          return <PaymentMethodDetailView />;
        case PAYMENT_METHOD_REFUND:
          return <PaymentMethodRefundDetailView />;
        case TRANSACTION:
          return <TransactionDetailView />;
        // FIELD SERVICE MODULE
        case SERVICE_APPOINTMENT_CONFIG:
          return <ServiceAppointmentConfigDetailView />;
        case WORK_ORDER:
          return <WorkOrderDetailView isQuickView />;
        // ORDER MODULE
        case ORDER:
          return <OrderDetailView />;
        case BILLING_ADJUSTMENT:
          return <BillingAdjustmentDetailView />;
        // PROJECT MODULE
        case APPLICATION_FOR_PAYMENT:
          return <ApplicationForPaymentDetailView />;
        case EXCHANGE:
          return <ExchangeDetailView />;
        case FEATURE_PRICE_ENTRY:
          return <FeaturePriceEntryDetailView />;
        case PAYMENT_NOTICE:
          return <PaymentNoticeDetailView />;
        case PROGRAM:
          return <ProgramDetailView />;
        case PROJECT:
          return <ProjectDetailView />;
        case REGION:
          return <RegionDetailView />;
        case WORK_LIST:
          return <WorkListDetailView />;
        case FEATURE:
          return <FeatureDetailView />;
        // FILES
        case FILE:
          return <FileDetailView />;
        // IDENTITY MODULE
        case USER:
          return <UserDetailView />;
        // SUPPORT MODULE
        case CASE:
          return <CaseDetailView />;
        // DEFAULT DETAIL VIEW
        default:
          return <DetailView showCollaborators headerExtras={[<DownloadRecordPdf />]} />;
      }
    } else return <></>;
  };

  return detailViewController();
};
export default DetailViewRouter;
