import { EntityTitle, Section, SectionCard } from "@blueprintjs/core"
import { Col, Row } from "antd"
import { SchemaDetailsProps } from "../types"
import { SchemaTypesSelect } from "./SchemaTypesSelect";
import React from "react";
import { RecordNumbersForm } from "./RecordNumbersForm";

export const DetailsSection = ({ schema, rightElement }: SchemaDetailsProps & { rightElement?: React.ReactElement }) => {
  return (
    <Section
      title="Details"
      rightElement={rightElement}
    >
      <Row>
        <Col span={12}>
          <SectionCard className="auto">
            <Row>
              <Col span={12} style={{ marginTop: 5 }}>
                <EntityTitle
                  title="Name"
                  subtitle={<span>{schema.name}</span>}
                />
                <EntityTitle
                  title="Module Name"
                  subtitle={<span>{schema.moduleName}</span>}
                />
              </Col>

              <Col span={12} style={{ marginTop: 10 }}>
                <EntityTitle
                  title="Description"
                  subtitle={<span>{schema.description}</span>}
                />
                <EntityTitle
                  title="Entity Name"
                  subtitle={<span>{schema.entityName}</span>}
                />
              </Col>
            </Row>
          </SectionCard>
        </Col>
        <Col span={12} style={{ borderLeft: 'solid 1px rgba(17, 20, 24, 0.15)'}}>
          <SectionCard className="auto">
            <RecordNumbersForm schema={schema} />
          </SectionCard>
        </Col>
      </Row>
  </Section>
  )
}