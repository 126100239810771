import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { httpGet } from '../../../../shared/http/requests';
import { TMention } from '../../views/Inbox/store/types';

const { CRM_MODULE } = SchemaModuleTypeEnums;

export const getAppointmentsWithElastic = async (
  schemaId: string,
  userId: string,
): Promise<DbRecordEntityTransform[]> => {
  return httpGet(
    `${CRM_MODULE}/v1.0/db/Appointment/search?terms=${userId}&boolean={}&fields=createdBy.id&schemas=${schemaId}&page=0&size=800&sort=&legacyResponse=false`,
  )
    .then((res: any) => {
      return res?.data?.data || [];
    })
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};

export const getMentionsForUser = async (userId: string): Promise<TMention[]> => {
  return httpGet(`SupportModule/v1.0/Notes/mentions/${userId}`)
    .then((res: any) => res?.data?.data || [])
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};
