import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { FC, useState } from 'react';
import { Alert, Button } from '@blueprintjs/core';
import { httpPost } from '../../../../../shared/http/requests';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { connect } from 'react-redux';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getRecordByIdRequest, IGetRecordById } from '../../../../../core/records/store/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import history from '../../../../../shared/utilities/browserHistory';

interface Props {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  alertMessage: (params: { body: string; type: string }) => void;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
}

const { ORDER_MODULE } = SchemaModuleTypeEnums;

const OrderContractRollbackButton: FC<Props> = (props: Props) => {
  const { record, alertMessage, getRecordById, schema } = props;
  const [isRollingbackOrder, setIsRollingbackOrder] = useState<boolean>(false);
  const [isRollbackConfirmationDialogVisible, setIsRollbackConfirmationDialogVisible] =
    useState<boolean>(false);

  const isRecontracted: boolean = !!getProperty(record, 'PreviousOrderId') && !!record?.id;

  const rollBackOrder = () => {
    setIsRollingbackOrder(true);
    httpPost(`${ORDER_MODULE}/v1.0/orders/recontracting/rollback`, {
      currentOrderId: record?.id,
      previousOrderId: getProperty(record, 'PreviousOrderId'),
    })
      .then((res: any) => {
        setIsRollingbackOrder(false);
        setIsRollbackConfirmationDialogVisible(false);
        alertMessage({
          body: 'Order rolled back successfully.',
          type: 'success',
        });
        // Reload the source record
        // getRecordById({ schema: schema, recordId: record.id });
        history.push(`/${ORDER_MODULE}/Order/${getProperty(record, 'PreviousOrderId')}`);
      })
      .catch((err: any) => {
        setIsRollingbackOrder(false);
        setIsRollbackConfirmationDialogVisible(false);
        alertMessage({
          body: `There was a problem rolling back the order. ${JSON.stringify(
            err.response?.data?.message ?? err.message,
          )}`,
          type: 'error',
        });
        throw new Error(err.response?.data?.message ?? err.message);
      });
  };

  return isRecontracted ? (
    <>
      <Button
        text="Rollback"
        icon="undo"
        intent="primary"
        outlined
        onClick={() => setIsRollbackConfirmationDialogVisible(true)}
      />
      <Alert
        intent="primary"
        onCancel={() => setIsRollbackConfirmationDialogVisible(false)}
        isOpen={isRollbackConfirmationDialogVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        canEscapeKeyCancel={!isRollingbackOrder}
        canOutsideClickCancel={!isRollingbackOrder}
        onConfirm={rollBackOrder}
        loading={isRollingbackOrder}
      >
        <p>Please confirm that you want to rollback the order.</p>
      </Alert>
    </>
  ) : (
    <></>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(OrderContractRollbackButton);
