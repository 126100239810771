import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Divider, Drawer, Row, Select, Typography } from 'antd';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { ISearchRecords, searchRecordsRequest } from '../../core/records/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { CustomerServiceOutlined } from '@ant-design/icons';
import CaseEditor from './CaseEditor';
import CaseRecordList from './CaseRecordList';
import CaseViewer from './CaseViewer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../core/schemas/store/actions';
import { Button } from '@blueprintjs/core';

interface OwnProps {
  supportDrawerVisible: boolean;
  toggleSupportModal: any;
  recordReducer: any;
  schemaReducer: any;
  searchRecords: any;
  userReducer: any;
  getSchema: Function;
}

type SchemaType = {
  id: string;
  name: string;
  label: string;
  isDefault: boolean;
};

type Props = OwnProps;

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { Title } = Typography;

const SupportDrawer: FunctionComponent<Props> = (props) => {
  const { supportDrawerVisible, toggleSupportModal, getSchema } = props;

  // State Hooks
  const [supportArticleTypes, setSupportArticleTypes] = useState<any>([]);
  const [selectedArticleType, setSelectedArticleType] = useState<string>('');
  const [articleList, setArticleList] = useState<Array<any>>([]);
  const [isLoadingArticles, setIsLoadingArticles] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isViewingCase, setIsViewingCase] = useState<boolean>(false);
  const [viewedCaseRecord, setViewedCaseRecord] = useState<DbRecordEntityTransform | null>(null);

  // When the Drawer shows, get back schema and show Article types in select input.
  useEffect(() => {
    if (supportDrawerVisible) {
      setSupportArticleTypes([]);
      setSelectedArticleType('');
      setArticleList([]);
      setIsLoadingArticles(false);

      getSchema({ moduleName: SUPPORT_MODULE, entityName: 'Case' }, (response: SchemaEntity) => {
        if (response) {
          setSchema(response);

          response?.types
            .filter((type: SchemaType) => !['MASTER'].includes(type.name))
            .map((type: SchemaType) =>
              setSupportArticleTypes((prevTypes: any) => [
                ...prevTypes,
                {
                  id: type.id,
                  label: type.label,
                  name: type.name,
                  isDefault: type.isDefault,
                },
              ]),
            );
        }
      });
    }
  }, [supportDrawerVisible]);

  // Once we have schema and all Article types - set default Article type.
  useEffect(() => {
    if (schema && supportArticleTypes?.length > 0) {
      const defaultType = supportArticleTypes.find(
        (SchemaType: SchemaType) => SchemaType.isDefault,
      );

      if (defaultType) {
        setSelectedArticleType(defaultType.name);
      } else {
        setSelectedArticleType(supportArticleTypes[0].name);
      }
    }
  }, [supportArticleTypes, schema]);

  // When Case Type is set/changed, we want to render a list of Cases for that Type.
  useEffect(() => {
    if (selectedArticleType && schema && !isEditing) {
      searchCases();
    }
  }, [selectedArticleType, isEditing]);

  // Search Cases for 1.Type, 2.Author, 3.Stage!=Done and 3. Sort descending by CreatedAt date
  const searchCases = () => {
    const { searchRecords, userReducer } = props;

    if (schema) {
      setIsLoadingArticles(true);
      setIsEditing(false);
      searchRecords(
        {
          schema: schema,
          searchQuery: {
            terms: '',
            schemas: schema?.id,
            boolean: {
              must: [
                {
                  query_string: {
                    fields: ['type'],
                    query: selectedArticleType,
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
                {
                  query_string: {
                    fields: ['lastModifiedBy.id'],
                    query: userReducer.user.id,
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
              ],
              must_not: [
                {
                  query_string: {
                    fields: ['stage.name'],
                    query: 'Done',
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
              ],
            },
            sort: [{ createdAt: { order: 'desc' } }],
          },
        },
        (searchResults: any) => {
          setIsLoadingArticles(false);
          setArticleList(searchResults.data.data);
        },
      );
    } else {
      setIsLoadingArticles(false);
    }
  };

  const onTypeChange = (e: string) => setSelectedArticleType(e);

  return (
    <Drawer
      title={
        <div>
          <CustomerServiceOutlined style={{ marginRight: 10 }} />
          Support
        </div>
      }
      placement="right"
      width={isMobile ? '100%' : 450}
      onClose={() => toggleSupportModal()}
      open={supportDrawerVisible}
    >
      {/* Start Chat */}
      <Row>
        <Col span={12}>
          <Title level={4} style={{ marginTop: 0 }}>
            Chat with us
          </Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button
            disabled={!(window as any)!.fcWidget!.isInitialized()}
            intent="primary"
            onClick={() => {
              (window! as any)?.fcWidget?.open();
            }}
          >
            Start Chat
          </Button>
        </Col>
      </Row>
      <Divider />

      {isEditing || isViewingCase || supportArticleTypes?.length < 2 ? (
        <></>
      ) : (
        <div>
          {/* Select Request Type */}
          <Row style={{ marginBottom: 12 }}>
            <Col span={24}>
              <Title style={{ marginTop: 0 }} level={4}>
                Request Type
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Select
                style={{ width: '100%' }}
                disabled={!supportArticleTypes.length || isEditing || isLoadingArticles}
                loading={isLoadingArticles}
                value={selectedArticleType ? selectedArticleType : ''}
                onChange={(e: string) => onTypeChange(e)}
              >
                {supportArticleTypes.map((type: any) => (
                  <Select.Option value={type.name} key={type.label}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Divider style={{ marginTop: 30, marginBottom: 20 }} />
        </div>
      )}

      {/* Case Create Record Editor */}
      {isEditing ? (
        <CaseEditor
          schema={schema!}
          selectedArticleType={selectedArticleType}
          setIsEditing={setIsEditing}
        />
      ) : (
        <></>
      )}

      {/* Case Record List */}
      {isEditing || isViewingCase ? (
        <></>
      ) : (
        <CaseRecordList
          searchCases={searchCases}
          isLoading={isLoadingArticles}
          articleList={articleList}
          setIsEditing={setIsEditing}
          setViewedCaseRecord={setViewedCaseRecord}
        />
      )}

      {/* Case Viewer */}
      {isViewingCase || viewedCaseRecord ? (
        <CaseViewer
          record={viewedCaseRecord!}
          setIsViewingCase={setIsViewingCase}
          setViewedCaseRecord={setViewedCaseRecord}
        />
      ) : (
        <></>
      )}
    </Drawer>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

export default connect(mapState, mapDispatch)(SupportDrawer);
