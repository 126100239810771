import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../..';
import MyCasesUserTimestamp from '../MyCasesUserTimestamp';
import MyCasesClientMessage from './MyCasesClientMessage';
import MyCasesClientNameTimestamp from './MyCasesClientNameTimestamp';
import MyCasesUserMessage from './MyCasesUserMessage';

interface Props {
  message: any;
  userReducer: any;
}

const MyCasesMessage: React.FC<Props> = (props: Props) => {
  const { message, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  return (
    <Row
      key={message.hourSent}
      justify={`${getProperty(message, 'From') === userReducer.user.email ? 'end' : 'start'}`}
      style={{ padding: '18px 22px 18px 18px' }}
    >
      {getProperty(message, 'From') !== userReducer.user.email ? (
        <>
          <MyCasesClientMessage message={getProperty(message, 'Body')} />
          <MyCasesClientNameTimestamp
            name={getProperty(message, 'From') || 'New Account'}
            timestamp={dayjs(message.createdAt).format('h:mm:ss A')}
          />
        </>
      ) : (
        <>
          <MyCasesUserMessage message={getProperty(message, 'Body')} />
          <MyCasesUserTimestamp timestamp={dayjs(message.createdAt).format('h:mm:ss A')} />
        </>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesMessage);
