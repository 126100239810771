import React, { useContext, useEffect } from 'react';
import {
  Badge,
  Col,
  Divider,
  Empty,
  Form,
  Row,
  Select,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { ADD_INVOICE_ITEM, SET_INVOICE_ITEMS, SET_SELECTED_OFFER } from '../store/constants';
import { invoiceBuilderContext } from '..';
import { TProductQuota, productQuotas } from '../types';
import RecordCard from '../../../../../../core/records/components/RecordCard';
import { Button } from '@blueprintjs/core';

interface Props {
  form: any;
}

const ProductSelection: React.FC<Props> = (props: Props) => {
  const { form } = props;
  const { state, dispatch } = useContext(invoiceBuilderContext);

  const handleOfferSelection = (selectedOfferId: string) => {
    const selectedOffer = state.offerList?.find(
      (offer: DbRecordEntityTransform) => offer.id === selectedOfferId,
    );
    if (selectedOffer) {
      dispatch({ type: SET_SELECTED_OFFER, payload: selectedOffer });
    }
  };

  const isProductInTheInvoiceProductsList = (product: DbRecordEntityTransform | undefined) => {
    if (product) {
      return state.invoiceItems.find(
        (invoiceItem: DbRecordEntityTransform) => invoiceItem.id === product.id,
      )
        ? true
        : false;
    }
  };

  const addProductToInvoiceProductsList = (product: DbRecordEntityTransform | undefined) => {
    if (product && !isProductInTheInvoiceProductsList(product)) {
      let adjustedProduct: DbRecordEntityTransform = Object.assign(product);

      adjustedProduct.properties.Quantity = 1;

      let newInvoiceProductsList: DbRecordEntityTransform[] = [
        ...state.invoiceItems,
        adjustedProduct,
      ];

      dispatch({ type: SET_INVOICE_ITEMS, payload: newInvoiceProductsList });
    }
  };

  const getProductsByCategory = (
    category: 'BASE_PRODUCT' | 'ADD_ON_PRODUCT',
    products: DbRecordEntityTransform[],
  ) => {
    return products?.filter(
      (offerProduct: DbRecordEntityTransform) => getProperty(offerProduct, 'Type') === category,
    );
  };

  // We run fixed quotas for certain products. These quotas are stated in types.ts.
  // For an example, we might want to have only 2 ADD_ON_PRODUCT and 1 BASE_PRODUCT
  // This might be expanded in the future.
  const hasProductExceededQuota = (product: DbRecordEntityTransform) => {
    // Get all products or order items in the list that match the type passed to function
    const matchingProductTypes = state.invoiceItems.filter(
      (invoiceItem: DbRecordEntityTransform) =>
        getProperty(invoiceItem, 'ProductType') === getProperty(product, 'Type') ||
        getProperty(invoiceItem, 'Type') === getProperty(product, 'Type'),
    );

    const productQuota = productQuotas.find(
      (quota: TProductQuota) => quota.type === getProperty(product, 'Type'),
    );

    if (matchingProductTypes?.length > 0 && productQuota) {
      if (matchingProductTypes.length >= productQuota.maxAmount) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const canAddProductToTheInvoice = (product: DbRecordEntityTransform) => {
    if (isProductInTheInvoiceProductsList(product) || hasProductExceededQuota(product)) {
      return false;
    } else {
      return true;
    }
  };

  const renderProductButtonTitle = (product: DbRecordEntityTransform) => {
    if (isProductInTheInvoiceProductsList(product)) {
      return 'Added to Invoice';
    } else {
      return 'Add';
    }
  };

  const tooltipInformation = (product: DbRecordEntityTransform) => {
    const productQuota = productQuotas.find(
      (quota: TProductQuota) => quota.type === getProperty(product, 'Type'),
    );

    if (isProductInTheInvoiceProductsList(product)) {
      return 'Product already added to the invoice.';
    } else if (isProductInTheInvoiceProductsList(product) && hasProductExceededQuota(product)) {
      return `Quota exceeded for ${getProperty(
        product,
        'Type',
      )} type. Max allowed number of these products is ${productQuota?.maxAmount}.`;
    } else if (!isProductInTheInvoiceProductsList(product) && hasProductExceededQuota(product)) {
      return `Quota exceeded for ${getProperty(
        product,
        'Type',
      )} type. Max allowed number of these products is ${productQuota?.maxAmount}.`;
    } else {
      return 'Add product to the Invoice';
    }
  };

  const listProductsByCategory = (
    category: 'BASE_PRODUCT' | 'ADD_ON_PRODUCT',
    products: DbRecordEntityTransform[],
  ) => {
    const filteredProducts = getProductsByCategory(category, products);

    if (filteredProducts?.length > 0) {
      return filteredProducts?.map((offerProduct: DbRecordEntityTransform) => (
        <div style={{ marginBottom: 10 }} key={offerProduct.id}>
          <RecordCard
            openTitleLinkInNewTab
            hideIcon
            propertyColumns={2}
            record={offerProduct}
            visibleProperties={[
              'Type',
              'Category',
              'UnitPrice',
              'DiscountType',
              'DiscountValue',
              'TrialUnit',
              'TrialLength',
            ]}
            headerElement={
              <Tooltip mouseEnterDelay={1} title={tooltipInformation(offerProduct)}>
                <Button
                  intent="primary"
                  outlined
                  minimal={isProductInTheInvoiceProductsList(offerProduct)}
                  disabled={!canAddProductToTheInvoice(offerProduct)}
                  onClick={() => addProductToInvoiceProductsList(offerProduct)}
                >
                  {renderProductButtonTitle(offerProduct)}
                </Button>
              </Tooltip>
            }
          />
        </div>
      ));
    } else {
      return (
        <Row>
          <Col span={24}>
            <Empty
              style={{ margin: '50px 0' }}
              description={
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <span>
                      There are no{' '}
                      {category === 'BASE_PRODUCT' ? 'Base Products' : 'Add-on Products'}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span>in the selected offer.</span>
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      );
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      layout="vertical"
    >
      <Row style={{ padding: 10 }}>
        {/* Offer Select */}
        <Col span={24}>
          <Form.Item name="selectedOffer">
            <Select
              className="offerSelectInput"
              size="large"
              placeholder={state.isLoadingOffers ? 'Loading...' : 'Select Offer'}
              loading={state.isLoadingOffers}
              disabled={state.isLoadingOffers || state.offerList?.length === 0}
              filterOption={(input: string, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) > -1
              }
              defaultActiveFirstOption={false}
              onChange={handleOfferSelection}
              notFoundContent={null}
              showSearch
            >
              {state.offerList?.map((offer: DbRecordEntityTransform) => (
                <Select.Option
                  className="offerSelectInputItem"
                  value={offer?.id}
                  label={offer?.title}
                  key={`offer-${offer?.id}`}
                >
                  <Row>
                    <Col span={24} style={{ fontWeight: 500 }}>
                      {offer?.title}
                    </Col>
                    <Col span={24} style={{ color: '#8f8f8f', fontSize: '0.8em' }}>
                      <span>
                        {/* Contract Type */}
                        {getProperty(offer, 'ContractType') ? (
                          <Tag
                            style={{
                              fontSize: '0.8em',
                              padding: '0px 2px',
                              lineHeight: '15px',
                              borderRadius: 2,
                            }}
                          >
                            {getProperty(offer, 'ContractType') || '-'}
                          </Tag>
                        ) : (
                          <></>
                        )}
                        {/* Customer Type */}
                        {getProperty(offer, 'CustomerType') ? (
                          <Tag
                            style={{
                              fontSize: '0.8em',
                              padding: '0px 2px',
                              lineHeight: '15px',
                              borderRadius: 2,
                            }}
                          >
                            {getProperty(offer, 'CustomerType') || '-'}
                          </Tag>
                        ) : (
                          <></>
                        )}
                        {/* From, To, Code */}
                        From: {getProperty(offer, 'AvailableFrom')}, To:{' '}
                        {getProperty(offer, 'AvailableTo') || '-'}, Code:{' '}
                        {getProperty(offer, 'Code') || '-'}
                      </span>
                    </Col>
                  </Row>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          {state.selectedOffer ? (
            <Row>
              <Col span={24}>
                <Tabs centered>
                  <Tabs.TabPane
                    tab={
                      <>
                        <Badge
                          style={{ backgroundColor: '#f0f0f0', color: 'black', marginBottom: 2 }}
                          count={
                            getProductsByCategory('BASE_PRODUCT', state.offerProductList).length
                          }
                        />
                        <span style={{ marginLeft: 5, paddingTop: 2 }}>Base Products</span>
                      </>
                    }
                    key="base-products"
                    forceRender
                  >
                    {listProductsByCategory('BASE_PRODUCT', state.offerProductList)}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <>
                        <Badge
                          style={{ backgroundColor: '#f0f0f0', color: 'black', marginBottom: 2 }}
                          count={
                            getProductsByCategory('ADD_ON_PRODUCT', state.offerProductList).length
                          }
                        />
                        <span style={{ marginLeft: 5 }}>Add-on Products</span>
                      </>
                    }
                    key="add-on-products"
                    forceRender
                  >
                    {listProductsByCategory('ADD_ON_PRODUCT', state.offerProductList)}
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          ) : (
            <Row style={{ textAlign: 'center', color: '#cdcdcd', padding: 30 }}>
              <Col span={24} style={{ marginBottom: 10 }}>
                <Typography.Title level={5} style={{ color: '#cdcdcd' }}>
                  No Offer selected
                </Typography.Title>
              </Col>
              <Col span={24}>
                <span>
                  Please select an offer
                  <br />
                  to see the associated products.
                </span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default ProductSelection;
