import { httpGet } from '../../../../../shared/http/requests';
import { PreviewAfpParams } from './types';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

export const previewAFP = async ({ workList, todoOnly }: PreviewAfpParams) => {
  const exPolygonId = getProperty(workList, 'ExPolygonId');

  const linkedProject = await httpGet(
    `ProjectModule/v2.0/records/WorkList/${workList.id}/links?targetEntities=["Project"]`,
  );
  const project = getFirstRelation(linkedProject.data.data, 'Project');

  if (!project) {
    return [];
  }

  const queryStringParams = `exPolygonId=${exPolygonId}&projectId=${project.id}&filter=${
    todoOnly ? 'todo' : 'pending_submissions'
  }`;
  const result = await httpGet(
    `ProjectModule/v1.0/ApplicationForPayment/billing-overview?${queryStringParams}`,
  );
  return result.data.data.lineItems;
};

export const getTableColumns = () => {
  return [
    {
      title: 'Project',
      key: 'project_title',
      dataIndex: 'project_title',
      // render: (text: string) => stripProjectTitle(text, selectedExchange),
    },
    {
      title: 'Contractor',
      key: 'contractor',
      dataIndex: 'contractor',
      render: (text: string) => text.toUpperCase(),
    },
    {
      title: 'PO #',
      key: 'po_number',
      dataIndex: 'po_number',
    },
    {
      title: 'Feature Type',
      key: 'feature_type',
      dataIndex: 'feature_type',
    },
    {
      title: 'Feature Id',
      key: 'qgis_feature_id',
      dataIndex: 'qgis_feature_id',
    },
    {
      title: 'Rate Code',
      key: 'rate_code',
      dataIndex: 'rate_code',
    },
    {
      title: 'Type',
      key: 'fpe_type',
      dataIndex: 'fpe_type',
    },
    {
      title: 'QTY',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'Rejection Reason',
      key: 'rejection_reason',
      dataIndex: 'rejection_reason',
    },
    {
      title: 'Rejection Response',
      key: 'rejection_response_comment',
      dataIndex: 'rejection_response_comment',
    },
    {
      title: 'AFP',
      key: 'afp_record_number',
      dataIndex: 'afp_record_number',
    },
    {
      title: 'PN',
      key: 'pn_record_number',
      dataIndex: 'pn_record_number',
    },
  ];
};
