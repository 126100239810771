import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { OrganizationUserRbacPermissionEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/rbac/permission/organization.user.rbac.permission.entity';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { setAssignGroupsModalVisible } from '../../../../../../core/identityGroups/store/actions';
import {
  setRemoveAllGroupsModalVisible,
  setUserStatusModalVisible,
} from '../../../../../../core/identityUser/store/actions';
import { PageHeader } from '../../../../../../shared/components/PageHeader';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../../shared/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';
import { searchString } from '../../../../../../shared/utilities/searchHelpers';
import { BlueprintNavigation } from '../../../../../../v2/shared/components/BlueprintPagination';
import { formFields } from '../FormFields';
import PermissionDetails from '../PermissionDetails';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  setGroupModalVisible: Function;
  setStatusModalVisible: Function;
  setRemoveGroupsModalVisible: (visible: boolean) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string | undefined;
  name: string | undefined;
  description: string | undefined;
  type: string | undefined;
}

const uuid = uuidv4();

const PermissionListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<
    OrganizationUserRbacPermissionEntity | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [permissionsList, setPermissionsList] = useState<OrganizationUserRbacPermissionEntity[]>(
    [],
  );
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isPermissionLoading, setIsPermissionLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  useEffect(() => {
    getAllPermissionsList();
  }, []);

  useEffect(() => {
    setSelectedRegions([]);
    setSelectedPermission(undefined);
  }, [currentPage]);

  const onSelect = (e: any) => {
    setSelectedRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (permissionsList.length > 0) {
      let tableData: ITableData[] = Object.assign([], permissionsList);

      tableData = permissionsList.map((permission: OrganizationUserRbacPermissionEntity) => ({
        key: permission.id,
        name: permission.name,
        description: permission.description,
        type: permission.type,
      }));

      // Apply search
      tableData = tableData.filter((permission: any) => {
        return (
          searchString(permission.name, searchKey) ||
          searchString(permission.description, searchKey)
        );
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [permissionsList, currentPage, searchKey, pageSize]);

  // Get selected permission details
  useEffect(() => {
    if (selectedRegions.length > 0 && tableSlice.length > 0) {
      const user: any = tableSlice[selectedRegions[0].rows[0]];
      if (user && !isPermissionLoading) {
        getPermissionById(user.key);
      }
    }
  }, [selectedRegions, tableSlice]);

  const getPermissionById = (id: string) => {
    setIsPermissionLoading(true);
    httpGet(`IdentityModule/v2.0/permissions/${id}`)
      .then((res) => {
        setIsPermissionLoading(false);
        setSelectedPermission(res.data.data);
        console.log('%cdebug: Selected permission', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({ body: 'Could not retrieve permission. ' + message, type: 'error' });
        setIsPermissionLoading(false);
        setSelectedPermission(undefined);
        setSelectedRegions([]);
      });
  };

  const getAllPermissionsList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v2.0/permissions?size=10000`);
      const permissions: OrganizationUserRbacPermissionEntity[] = res.data.data || [];
      setPermissionsList(permissions);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not retrieve permissions list. ' + message,
        type: 'error',
      });
      setPermissionsList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedPermission, tableRef, tableSlice]);

  const showCreatePermissionForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Permission',
      formFields: formFields,
      entityName: 'Permission',
    });
  };

  const createNewPermission = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/permissions', body);
      const newPermission = res.data.data;
      setPermissionsList([...permissionsList, newPermission]);
      getPermissionById(newPermission.id);

      alertMessage({ body: 'Permission created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create permission. ' + message,
        type: 'error',
      });
    }
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
        type: params.data.type,
      };
      createNewPermission({ body });
    }
  };

  const onSearch = (e: any) => {
    setSelectedRegions([]);
    setSearchKey(e.target.value);
  };

  const onPermissionUpdate = () => {
    if (selectedPermission) {
      getPermissionById(selectedPermission.id);
    }
  };

  const renderPermissions = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={24} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Permissions"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreatePermissionForm}>
                  Create Permission
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        <Row className="listViewContainer">
          <Col span={selectedPermission ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[
                getColumnWidthByPercentage(30),
                getColumnWidthByPercentage(40),
                getColumnWidthByPercentage(30),
              ]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
              <Column
                key="type"
                name="Type"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].type}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Permission Details */}
          {selectedPermission && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isPermissionLoading ? 0.6 : 1 }}
            >
              <Section
                title="Permission Details"
                compact
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedRegions([]);
                      setSelectedPermission(undefined);
                    }}
                  />
                }
              >
                <PermissionDetails
                  permission={selectedPermission}
                  onUpdate={onPermissionUpdate}
                  onDelete={onPermissionUpdate}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={permissionsList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return (
    <Col span={24} style={{ background: 'white', padding: '0 15px' }}>
      {renderPermissions()}
    </Col>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  setGroupModalVisible: (visible: boolean) => dispatch(setAssignGroupsModalVisible(visible)),
  setStatusModalVisible: (visible: boolean) => dispatch(setUserStatusModalVisible(visible)),
  setRemoveGroupsModalVisible: (visible: boolean) =>
    dispatch(setRemoveAllGroupsModalVisible(visible)),
});

export default connect(mapState, mapDispatch)(PermissionListView);
