import { OrganizationEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/organization.entity';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  updateDbRecordCreatePropertiesInArray,
  updateObject,
} from '../../../../../shared/utilities/reducerHelpers';
import {
  arrayHasValues,
  itemExistsInArrayOfObjects,
} from '../../../../../shared/utilities/validateDataTypes';
import {
  ADD_ASSOCIATION_TO_FORM_SECTION,
  CLOSE_FORM_MODAL,
  CLOSE_SECONDARY_FORM_MODAL,
  INITIALIZE_FORM,
  UPDATE_FORM_INPUT,
  UPDATE_FORM_STATE,
} from './constants';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';

export class FormSectionEntity {
  public id?: string | null;
  public organization?: OrganizationEntity;
  public schema?: SchemaEntity;
  public schemaColumns?: SchemaColumnEntity[];
  public name?: string;
  public description?: string;
  public position?: number;
  public columns?: number;
}

export interface IFormReducer {
  additionalInputChangeHandler?: (props: any, params: any) => void;
  customValidations?: { [key: string]: any };
  disabledFields?: string[];
  formUUID: string;
  hideRecordFormFields: boolean;
  hideRecordStageField: boolean;
  hideRecordTypeField: boolean;
  isBulkUpdateReq: boolean;
  isCloning: boolean;
  isCreateReq: boolean;
  isCreating: boolean;
  isRequesting: boolean;
  isUpdateReq: boolean;
  isUpdating: boolean;
  list: any[];
  modified: DbRecordCreateUpdateDto[];
  nextStageId: string | null;
  payload: DbRecordCreateUpdateDto[];
  recordType?: string;
  relatedEntityName?: string | undefined;
  schema: SchemaEntity | null;
  schemaAssociation: SchemaAssociationEntity | null;
  sections: FormSectionEntity[];
  selected: DbRecordEntityTransform | null;
  selectedFlowRecord?: DbRecordEntityTransform | null;
  singleColumnForm: boolean;
  showFormModal: boolean;
  showInitializing: boolean;
  title: string;
  upsert: boolean;
  visibleFieldOverride?: string[];
  schemaActionId?: string | undefined;
  isSecondaryForm: boolean;
  // We can use custom object to pass custom
  // information to the form as we see fit.
  custom?: any;
}

export const initialFormReducerState: IFormReducer = {
  disabledFields: [],
  formUUID: '',
  hideRecordFormFields: false,
  hideRecordStageField: false,
  hideRecordTypeField: false,
  isBulkUpdateReq: false,
  isCloning: false,
  isCreateReq: false,
  isCreating: false,
  isRequesting: false,
  isUpdateReq: false,
  isUpdating: false,
  isSecondaryForm: false,
  list: [],
  modified: [],
  nextStageId: null,
  payload: [],
  recordType: undefined,
  relatedEntityName: undefined,
  schema: null,
  schemaActionId: undefined,
  schemaAssociation: null,
  sections: [],
  selected: null,
  selectedFlowRecord: null,
  showFormModal: false,
  showInitializing: false,
  singleColumnForm: false,
  title: 'Form',
  upsert: false,
  visibleFieldOverride: [],
};

function reducer(state = initialFormReducerState, action: any) {
  switch (action.type) {
    case INITIALIZE_FORM: {
      // If schema has types, find the default schema type
      let defaultSchemaType: SchemaTypeEntity | undefined = undefined;
      if (action.params.schema?.types?.length! > 0) {
        const schemaTypes = action.params.schema?.types;
        defaultSchemaType =
          schemaTypes.find((schemaType: SchemaTypeEntity) => schemaType.isDefault) || undefined;
      }

      // Record Create -> recordType is passed in the form reducer
      if (action.params.recordType && action.params.isCreateReq) {
        if (action.params.isSecondaryForm) {
          return {
            [action.params.formUUID]: {
              ...initialFormReducerState,
              ...action.params,
              recordType: action.params.recordType,
            },
            ...state,
          };
        } else {
          return {
            ...initialFormReducerState,
            ...action.params,
            recordType: action.params.recordType,
          };
        }
      }
      // Record Create -> No recordType passed but there is a default schema type
      else if (defaultSchemaType && action.params.isCreateReq) {
        if (action.params.isSecondaryForm) {
          return {
            [action.params.formUUID]: {
              ...initialFormReducerState,
              ...action.params,
              recordType: defaultSchemaType?.name,
            },
            ...state,
          };
        } else {
          return {
            ...initialFormReducerState,
            ...action.params,
            recordType: defaultSchemaType?.name,
          };
        }
      } else {
        if (action.params.isSecondaryForm) {
          return {
            [action.params.formUUID]: {
              ...initialFormReducerState,
              ...action.params,
            },
            ...state,
          };
        } else {
          return {
            ...initialFormReducerState,
            ...action.params,
          };
        }
      }
    }

    case UPDATE_FORM_STATE: {
      return {
        ...state,
        ...action.params,
      };
    }

    case CLOSE_FORM_MODAL: {
      return {
        ...initialFormReducerState,
      };
    }

    case CLOSE_SECONDARY_FORM_MODAL: {
      const newState: any = { ...state };
      delete newState[action.params.formUUID];
      return newState;
    }

    case UPDATE_FORM_INPUT: {
      const formUUID = action.params?.formUUID;
      const targetIdSplit = action.params.targetId.split('#');
      const targetSchemaId = targetIdSplit[0];
      const targetProperty = targetIdSplit[1];
      const targetValue = action.params.targetValue;
      const targetEntity = action.params.entity;
      const targetRecord = action.params.record;
      const association = action.params.association;

      // If formUUID is passed, we are updating a secondary form
      // @ts-ignore
      let State = formUUID ? state[formUUID] : state;

      const schemaActionId = State.schemaActionId;
      let recordType = State.recordType;
      let newModified = State.modified;

      // Whenever file fields are updated in the form, they will return back an array of file ids each.
      // We pass the field name so we can clean existing associations and use only new one.
      const checkAssoc = (itemAssociations: any, association: any) => {
        let newAssociations: { recordId: number }[] = Object.assign(itemAssociations);
        newAssociations = newAssociations.filter(
          (assoc: any) => !assoc?.fieldName || assoc.fieldName !== targetProperty,
        );
        if (association) {
          newAssociations = [...newAssociations, ...association];
        }
        return newAssociations;
      };

      if (targetProperty === 'recordType') {
        recordType = targetValue;
      }

      // Modified values exist
      if (arrayHasValues(State.modified)) {
        if (!itemExistsInArrayOfObjects(State.modified, targetSchemaId, 'schemaId')) {
          // Add the item to the array of existing modified values
          // a) secondary forms
          if (formUUID) {
            return {
              ...state,
              [formUUID]: {
                // @ts-ignore
                ...state[formUUID],
                recordType,
                modified: [
                  ...State.modified,
                  ...[
                    {
                      entity: `${State.schema?.moduleName}:${State.schema?.entityName}`,
                      schemaId: State.schema?.id,
                      [targetProperty]: targetValue,
                      title:
                        targetProperty === 'title'
                          ? targetValue
                          : targetRecord
                          ? targetRecord.title
                          : undefined,
                      ownerId: targetProperty === 'ownerId' ? targetValue : undefined,
                      type: targetProperty === 'recordType' ? targetValue : state.recordType,
                      stageId: targetProperty === 'stage' ? targetValue : undefined,
                      properties:
                        targetEntity !== 'Record' ? { [targetProperty]: targetValue } : {},
                      associations: association ? [association] : [],
                      schemaActionId: schemaActionId || null,
                    },
                  ],
                ],
              },
            };
          }
          // b) primary form
          else {
            return {
              ...state,
              recordType,
              modified: [
                ...state.modified,
                ...[
                  {
                    entity: `${state.schema?.moduleName}:${state.schema?.entityName}`,
                    schemaId: state.schema?.id,
                    [targetProperty]: targetValue,
                    title:
                      targetProperty === 'title'
                        ? targetValue
                        : targetRecord
                        ? targetRecord.title
                        : undefined,
                    ownerId: targetProperty === 'ownerId' ? targetValue : undefined,
                    type: targetProperty === 'recordType' ? targetValue : state.recordType,
                    stageId: targetProperty === 'stage' ? targetValue : undefined,
                    properties: targetEntity !== 'Record' ? { [targetProperty]: targetValue } : {},
                    associations: association ? [association] : [],
                    schemaActionId: schemaActionId || null,
                  },
                ],
              ],
            };
          }
        } else {
          // update the existing item in the array of modified values
          newModified = updateDbRecordCreatePropertiesInArray(
            State.modified,
            targetSchemaId,
            (item: any) => {
              return updateObject(item, {
                title: targetProperty === 'title' ? targetValue : item.title,
                ownerId: targetProperty === 'ownerId' ? targetValue : item.ownerId,
                stageId: targetProperty === 'stage' ? targetValue : item.stageId,
                type: targetProperty === 'recordType' ? targetValue : state.recordType,
                schemaActionId: schemaActionId || null,
                properties:
                  targetEntity !== 'Record'
                    ? updateObject(item.properties, {
                        [targetProperty]: targetValue,
                      })
                    : item.properties,
                associations: item.associations
                  ? // ? [...item.associations, ...association]
                    checkAssoc(item.associations, association)
                  : [],
              });
            },
          );
        }
      }

      // No modified values exist, add the first item
      else {
        // a) secondary forms
        if (formUUID) {
          return {
            ...state,
            [formUUID]: {
              // @ts-ignore
              ...state[formUUID],
              recordType,
              modified: [
                {
                  entity: `${State.schema?.moduleName}:${State.schema?.entityName}`,
                  schemaId: State.schema?.id,
                  type: targetProperty === 'type' ? targetValue : State.recordType,
                  title:
                    targetProperty === 'title'
                      ? targetValue
                      : targetRecord
                      ? targetRecord.title
                      : undefined,
                  ownerId: targetProperty === 'ownerId' ? targetValue : undefined,
                  stageId: targetProperty === 'stage' ? targetValue : undefined,
                  properties: targetEntity !== 'Record' ? { [targetProperty]: targetValue } : {},
                  associations: association ? [association] : [],
                  schemaActionId: schemaActionId || null,
                },
              ],
            },
          };
        }
        // primary form
        else {
          return {
            ...state,
            recordType,
            modified: [
              {
                entity: `${State.schema?.moduleName}:${State.schema?.entityName}`,
                schemaId: State.schema?.id,
                type: targetProperty === 'type' ? targetValue : State.recordType,
                title:
                  targetProperty === 'title'
                    ? targetValue
                    : targetRecord
                    ? targetRecord.title
                    : undefined,
                ownerId: targetProperty === 'ownerId' ? targetValue : undefined,
                stageId: targetProperty === 'stage' ? targetValue : undefined,
                properties: targetEntity !== 'Record' ? { [targetProperty]: targetValue } : {},
                associations: association ? [association] : [],
                schemaActionId: schemaActionId || null,
              },
            ],
          };
        }
      }

      // a) secondary forms
      if (formUUID) {
        return {
          ...state,
          [formUUID]: {
            // @ts-ignore
            ...state[formUUID],
            recordType,
            modified: newModified,
          },
        };
      }
      // b) primary form
      else {
        return {
          ...State,
          recordType,
          modified: newModified,
        };
      }
    }

    case ADD_ASSOCIATION_TO_FORM_SECTION: {
      const targetIdSplit = action.params.targetId.split('#');
      const targetSchemaId = targetIdSplit[0];
      const targetProperty = targetIdSplit[1];
      const association = action.params.association;

      if (arrayHasValues(state.modified)) {
        // Add the item to the array of existing modified values
        return {
          ...state,
          modified: [
            ...state.modified,
            ...[
              {
                associations: itemExistsInArrayOfObjects(
                  state.modified,
                  targetSchemaId,
                  targetProperty,
                )
                  ? updateDbRecordCreatePropertiesInArray(
                      state.modified,
                      targetSchemaId,
                      (item: any) => {
                        return updateObject(item, {
                          associations: [...item.associations, association],
                        });
                      },
                    )
                  : [association],
              },
            ],
          ],
        };
      } else {
        // No modified values exist, add the first item
        return {
          ...state,
          modified: [
            {
              schemaId: state.schema?.id,
              associations: [association],
            },
          ],
        };
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
}

export default reducer;
