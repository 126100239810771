import {
  Button,
  Callout,
  DialogStep,
  IconName,
  MultistepDialog,
  NonIdealState,
  Section,
  SectionCard,
} from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  closeRecordForm,
  initializeRecordForm,
} from '../../../../../core/records/components/Forms/store/actions';
import { updateRecordInShortList } from '../../../../../core/records/store/actions';
import { getSchemaByIdRequest } from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { httpDelete, httpGet, httpPost, httpPut } from '../../../../../shared/http/requests';
import { hasPermissions } from '../../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import { getSchemaActionsForSchemaActionFlows } from '../schemaActionFilters';
import SchemaActionFlowStep from '../SchemaActionFlowStep';

interface PropsType {
  record: DbRecordEntityTransform;
  recordFormReducer: any;
  initializeForm: any;
  getSchemaById: (payload: any, cb: any) => void;
  onClose?: any;
  onConfirm?: any;
  closeForm: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) => void;
  schemaReducer: ISchemaReducer;
  schemaActionId?: string;
  hideLauncher?: boolean;
  userReducer: any;
}

const SchemaUserActionFlow: FunctionComponent<PropsType> = (props) => {
  const {
    record,
    recordFormReducer,
    getSchemaById,
    updateRecordInReducer,
    onClose,
    onConfirm,
    alertMessage,
    closeForm,
    schemaReducer,
    schemaActionId,
    hideLauncher,
    userReducer,
  } = props;

  type TError = {
    title: string;
    message: string;
    icon?: IconName;
  };

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);
  const [flowFormData, setFlowFormData] = useState<any[]>([]);
  const [isLoadingSchemaAction, setIsLoadingSchemaAction] = useState<boolean>(false);
  const [recordSchema, setRecordSchema] = React.useState<SchemaEntity | undefined>(undefined);
  const [schemaActionFlow, setSchemaActionFlow] = React.useState<SchemaActionEntity | undefined>(
    undefined,
  );
  const [error, setError] = useState<TError | undefined>();

  function resetState() {
    setIsNextDisabled(true);
    setIsConfirmLoading(false);
  }

  useEffect(() => {
    if (record && !isDialogOpen) {
      closeForm();
      resetState();
      fetchSchema();
    }
  }, [record]);

  const fetchSchema = () => {
    const shortlistSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record.schemaId,
    );
    if (shortlistSchema) {
      setRecordSchema(shortlistSchema);
    } else {
      getSchemaById({ schemaId: record.schemaId }, (schema: any) => {
        setRecordSchema(schema);
      });
    }
  };

  useEffect(() => {
    if (recordSchema && !isLoadingSchemaAction) {
      fetchSchemaActionFlows();
    }
  }, [recordSchema]);

  useEffect(() => {
    if (schemaActionId) {
      fetchSchemaActionFlows();
    }
  }, [schemaActionId]);

  // Fetch all schema actions and apply a rule set
  function fetchSchemaActionFlows() {
    if (record) {
      setIsLoadingSchemaAction(true);
      httpGet(`SchemaModule/v1.0/schemas-actions`)
        .then((res) => {
          setIsLoadingSchemaAction(false);

          let filteredSchemaActionFlows: SchemaActionEntity[] = [];

          if (!schemaActionId) {
            // Filter schema actions dynamically
            filteredSchemaActionFlows = getSchemaActionsForSchemaActionFlows(
              res.data?.data,
              record,
              recordSchema!,
            );

            if (filteredSchemaActionFlows.length > 0) {
              const permissions: string[] =
                filteredSchemaActionFlows[0]?.permissions?.map((perm: any) => perm.name) || [];

              if (permissions.length > 0 && !hasPermissions(userReducer, permissions)) {
                setError({
                  title: 'Permission Denied',
                  message:
                    'You do not have the required permissions to perform this action. Please contact your administrator',
                  icon: 'lock',
                });
              } else {
                console.log(
                  '%cdebug: Filtered schema action flows!',
                  'color:limegreen',
                  filteredSchemaActionFlows,
                );
                setSchemaActionFlow(filteredSchemaActionFlows[0]);
              }
            }
          } else {
            const schemaAction = res.data?.data.find(
              (action: SchemaActionEntity) => action.id === schemaActionId,
            );
            if (schemaAction) {
              const permissions: string[] =
                schemaAction?.permissions?.map((perm: any) => perm.name) || [];

              if (permissions.length > 0 && !hasPermissions(userReducer, permissions)) {
                setError({
                  title: 'Permission Denied',
                  message:
                    'You do not have the required permissions to perform this action. Please contact your administrator',
                  icon: 'lock',
                });
              } else {
                setSchemaActionFlow(schemaAction);
                setIsDialogOpen(true);
              }
            }
          }
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  }

  function setupStep(newStep: any) {
    if (newStep === 0) {
      setFlowFormData([]);
      closeForm();
    } else if (newStep > 0 && newStep <= schemaActionFlow?.definition?.dialogSteps?.length) {
      let newFlowFormData: any[] = flowFormData.slice(0, Number(newStep) - 1);
      newFlowFormData.push(recordFormReducer);
      setFlowFormData(newFlowFormData);
      closeForm();
    }
  }

  // Debug
  // useEffect(() => {
  //   console.log('%cdebug: flowData updated', 'color:royalblue', flowFormData);
  // }, [flowFormData]);

  // Some schema action flows can pass custom URLs to be executed after the flow is completed.
  // We pass the creates and updates to the custom URL endpoint.
  const handleCustomURLs = async (creates: any[], updates: any[]) => {
    const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;

    if (schemaActionFlow && onSubmitUrl) {
      try {
        let URL = onSubmitUrl.url;

        // Replace source record id if asked in schema configuration
        if (URL && URL.includes('{source_record_id}')) {
          URL = URL.replace('{source_record_id}', record.id);
        }

        // Support POST, PUT, DELETE methods but fallback to POST
        if (onSubmitUrl.method === 'post') {
          await httpPost(URL, {
            creates: creates,
            updates: updates,
          });
        } else if (onSubmitUrl.method === 'put') {
          await httpPut(URL, {
            creates: creates,
            updates: updates,
          });
        } else if (onSubmitUrl.method === 'delete') {
          await httpDelete(URL);
        } else {
          await httpPost(URL, {
            creates: creates,
            updates: updates,
          });
        }
      } catch (err: any) {
        alertMessage({
          body: 'Could not execute custom URL endpoint',
          type: 'error',
        });
        return true;
      }
    }
  };

  async function handleFinalStepSubmit() {
    setIsConfirmLoading(true);

    let createPayload: any[] = [];
    let updatePayload: any[] = [];
    const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;

    try {
      if (schemaActionFlow) {
        // Group update and create steps
        let updateSteps = flowFormData.filter(
          (formReducerSnapshot: any) => formReducerSnapshot.isUpdateReq,
        );
        let createSteps = flowFormData.filter(
          (formReducerSnapshot: any) => formReducerSnapshot.isCreateReq,
        );

        // Extract data from update steps and pass it to the update endpoint
        if (updateSteps.length > 0) {
          updatePayload = updateSteps.map((formReducerSnapshot: any) => {
            return {
              id: record.id,
              entity: record.entity,
              type: record.type,
              schemaTypeId: formReducerSnapshot?.custom?.schemaAction?.schemaTypeId || null,
              properties: formReducerSnapshot.modified[0].properties,
            };
          });
          console.log('debug: UPDATE PAYLOAD', updatePayload);

          if (!onSubmitUrl) {
            await httpPut(`SchemaModule/v1.0/db/bulk-update`, {
              recordsToUpdate: updatePayload,
            });
            const updatedRecord = await httpGet(
              `${recordSchema?.moduleName}/v1.0/db/${recordSchema?.entityName}/${record.id}`,
            );
            updateRecordInReducer({ record: updatedRecord.data.data });
          }
        }

        // 2. Extract data from create steps and pass it to the upsert endpoint
        if (createSteps.length > 0) {
          createPayload = createSteps.map((formReducerSnapshot: any) => {
            return {
              entity: `${formReducerSnapshot.schema?.moduleName}:${formReducerSnapshot.schema?.entityName}`,
              type: formReducerSnapshot?.recordType,
              properties: formReducerSnapshot.modified[0]?.properties,
              title:
                formReducerSnapshot.modified[0]?.title ||
                formReducerSnapshot.selected?.title ||
                null,
              schemaId: formReducerSnapshot.schema?.id,
              schemaActionId: formReducerSnapshot.schemaActionId,
              schemaTypeId: formReducerSnapshot?.custom?.schemaAction?.schemaTypeId || null,
              associations: [
                {
                  entity: record?.entity,
                  recordId: record?.id,
                  linkType: formReducerSnapshot?.custom?.linkType,
                },
              ],
            };
          });

          console.log('debug: CREATE PAYLOAD', createPayload);
          if (!onSubmitUrl) {
            await httpPost(`SchemaModule/v1.0/db/bulk-upsert`, {
              recordsToUpsert: createPayload,
              options: {
                linkRelatedRecordsAfterUpsert: true,
              },
            });
          }
        }

        // 3. Handle custom URL endpoints
        if (onSubmitUrl) {
          handleCustomURLs(createPayload, updatePayload);
        }
      }

      setIsDialogOpen(false);
      resetState();
      setIsConfirmLoading(false);

      alertMessage({
        body: 'Record stage updated successfully',
        type: 'success',
      });

      if (onConfirm) {
        onConfirm();
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      console.log('debug: error', err);
      alertMessage({
        body: err?.response?.data?.message,
        type: 'error',
      });
    }
  }

  // Render each step as a DialogStep
  const renderSchemaActionSteps = () => {
    const steps = schemaActionFlow?.definition?.dialogSteps || [];
    if (steps.length > 0) {
      return steps.map((step: any, index: number) => {
        return (
          <DialogStep
            title={step.name}
            id={index}
            key={index}
            panel={
              <Section style={{ overflowY: 'auto' }}>
                <SectionCard>
                  <SchemaActionFlowStep
                    step={step}
                    sourceRecord={record}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                    key={index}
                  />
                </SectionCard>
              </Section>
            }
          />
        );
      });
    } else {
      return <Callout intent="danger">No schema action flow steps defined.</Callout>;
    }
  };

  return (
    <>
      {schemaActionFlow && !hideLauncher && (
        <Button
          outlined
          intent="primary"
          text={schemaActionFlow?.definition?.settings?.actionLabel || 'Launch Flow'}
          onClick={() => setIsDialogOpen(true)}
        />
      )}
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        icon="flow-linear"
        navigationPosition="top"
        initialStepIndex={0}
        onClose={() => {
          onClose && onClose();
          resetState();
          setIsDialogOpen(false);
          setFlowFormData([]);
          setSchemaActionFlow(undefined);
        }}
        usePortal={true}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading,
          onClick: () => handleFinalStepSubmit(),
        }}
        title={schemaActionFlow?.name || 'Schema Action Flow'}
        lazy
      >
        {/* Render dynamic schema action steps */}
        {renderSchemaActionSteps()}

        {/* Error ? */}
        {error && (
          <DialogStep
            title="Error"
            id="error"
            key="error"
            panel={
              <div style={{ padding: 30 }}>
                <NonIdealState
                  icon={error?.icon || 'error'}
                  title={error?.title || 'Error'}
                  description={error?.message || 'An error occurred'}
                />
              </div>
            }
          />
        )}

        {/* Confirmation step */}
        <DialogStep
          title="Confirmation"
          id={schemaActionFlow?.definition?.dialogSteps?.length}
          key="confirmation"
          panel={
            <Section style={{ overflowY: 'auto' }}>
              <SectionCard>
                <Callout intent="primary" icon={null}>
                  <span>
                    <span>Please confirm that you want to finish the flow.</span>
                  </span>
                </Callout>
              </SectionCard>
            </Section>
          }
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordFormReducer: state.recordFormReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) =>
    dispatch(updateRecordInShortList(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(SchemaUserActionFlow);
