import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../../../../core/records/store/actions';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import CaseManagementDashboardStatCard from './CaseManagementDashboardStatCard';
import CaseManagementDashboardChart from './CaseManagementDashboardChart';
import { DUMMY_DASHBOARD_PROPERTIES } from './types';

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const CaseManagementDashboard: FC<Props> = (props: Props) => {
  return (
    <>
      {/* Header */}
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Dashboard</h1>
        </Col>
      </Row>
      {/* Dashboard */}
      <Row
        style={{
          marginTop: 30,
          height: 'calc(100vh - 140px)',
          overflowY: 'auto',
          alignContent: 'flex-start',
        }}
      >
        {/* SLA Score Chart */}
        <CaseManagementDashboardChart />

        {/* Tickets Stat */}

        {/* Calls Stat */}
        <CaseManagementDashboardStatCard
          title="Calls"
          topStats={[
            { title: 'Waiting', value: 3 },
            { title: 'Answered', value: 22 },
            { title: 'Missed', value: 1 },
          ]}
          properties={DUMMY_DASHBOARD_PROPERTIES}
        />

        {/* Chats Stat */}
        <CaseManagementDashboardStatCard
          title="Chats"
          topStats={[
            { title: 'Waiting', value: 6 },
            { title: 'Answered', value: 29 },
            { title: 'Missed', value: 10 },
          ]}
          properties={DUMMY_DASHBOARD_PROPERTIES}
        />

        {/* Email Stat */}
        <CaseManagementDashboardStatCard
          title="Email"
          topStats={[
            { title: 'Inbound', value: 10 },
            { title: 'Solved', value: 38 },
            { title: 'Replies', value: 12 },
          ]}
          properties={DUMMY_DASHBOARD_PROPERTIES}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="Social Media"
          topStats={[
            { title: 'Waiting', value: 3 },
            { title: 'Answered', value: 22 },
            { title: 'Missed', value: 1 },
          ]}
          properties={DUMMY_DASHBOARD_PROPERTIES}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="TrustPilot"
          topStats={[
            { title: 'Inbound', value: 10 },
            { title: 'Solved', value: 38 },
            { title: 'Replies', value: 12 },
          ]}
          properties={DUMMY_DASHBOARD_PROPERTIES}
        />
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementDashboard);
