import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Alert, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSchemaByModuleAndEntityRequest } from '../../../../../../core/schemas/store/actions';
import { updateAutosplicingReducerState } from '../../store/actions';
import { IAutosplicingReducer } from '../../store/types';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '../../../../../../core/records/components/Forms/store/actions';
import CoreForm from '../../../../../../core/records/components/Forms/CoreForm';
import CoreLegacyEmbeddedForm from '../../../../../../core/records/components/Forms/CoreLegacyEmbeddedForm';

interface Props {
  getSchemaByModuleAndEntity: Function;
  updateAutosplicingReducer: Function;
  initializeForm: Function;
  autosplicingReducer: IAutosplicingReducer;
}

const uuid = uuidv4();

const SplicingRequestForm: React.FC<Props> = (props: Props) => {
  const {
    getSchemaByModuleAndEntity,
    updateAutosplicingReducer,
    initializeForm,
    autosplicingReducer,
  } = props;

  const [SRFCSchema, setSRFCSchema] = useState<SchemaEntity | undefined>(undefined);

  // On component mount fetch SplicingRequestForChange schema
  useEffect(() => {
    getSchemaByModuleAndEntity(
      {
        moduleName: 'ProjectModule',
        entityName: 'SplicingRequestForChange',
      },
      (response: SchemaEntity) => {
        if (response) {
          setSRFCSchema(response);
        }
      },
    );
  }, []);

  return SRFCSchema && autosplicingReducer.tracedClosureRecordId ? (
    <div
      style={{
        height: '82vh',
        paddingLeft: 7,
        paddingRight: 7,
        paddingTop: 10,
        overflowY: 'auto',
      }}
    >
      <Row>
        <Col span={24}>
          <Alert
            message={<span style={{ fontWeight: 500 }}>Splicing request for change</span>}
            description={
              <div style={{ marginTop: 10 }}>
                Please fill in the details below with a description, RFC (JIRA ticket) number and
                who requested this process to be completed.
              </div>
            }
            type="info"
          />
        </Col>
        <Col span={24}>
          <CoreLegacyEmbeddedForm
            hideCancelButton
            formDirection="vertical"
            showFormActions
            formUUID={uuid}
            hideStageFormField
            onSubmitEvent={() =>
              updateAutosplicingReducer({
                showSplicingRequestForChangeForm: false,
              })
            }
            initializeCb={() => {
              initializeForm({
                hideRecordTypeField: true,
                formUUID: uuid,
                showFormModal: false,
                showInitializing: false,
                isBatchCreateReq: false,
                isCreateReq: true,
                schema: SRFCSchema,
                modified: [
                  {
                    associations: [
                      {
                        entity: `ProjectModule:Feature`,
                        recordId: autosplicingReducer.tracedClosureRecordId,
                      },
                    ],
                    schemaId: SRFCSchema?.id,
                  },
                ],
                sections: [
                  {
                    name: SRFCSchema?.name,
                    schema: SRFCSchema,
                  },
                ],
              });
            }}
          />
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  );
};

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  getSchemaByModuleAndEntity: (params: any, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(SplicingRequestForm);
