import {
  Alert,
  Button,
  CompoundTag,
  Icon,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Tooltip,
} from '@blueprintjs/core';
import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Avatar, Col, Row } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { NoteFeedContext } from '..';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import {
  createRecordsRequest,
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  ICreateRecords,
  IDeleteRecordById,
  IGetRecordById,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../core/userInterface/store/actions';
import { isSystemAdmin } from '../../../../../shared/permissions/rbacRules';
import { parseDateForNoteFeed } from '../../../../../shared/utilities/dateHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import Typography from '../../../core/Typography';
import { getInitialsFromName, getPastelColorForUsername } from '../../../helpers/UIHelpers';
import {
  emptyNoteBody,
  isNoteOlderThanSevenDays,
  isRecordInInternalNoteGroup,
  isUserAuthorOfNote,
  removeReactionOnNote,
  updateReactionsOnNote,
} from '../helpers';
import NoteAttachments from '../NoteAttachments';
import NoteEditor from '../NoteEditor';
import NoteReactionsList from '../NoteReactionsList';
import NoteReactionsPicker from '../NoteReactionsPicker';
import NoteTask from '../NoteTask';
import ReplyBody from '../ReplyBody';
import { INTERNAL_NOTE_GROUP_NAME, SUPPORT_TASK } from '../types';
import './styles.scss';

interface Props {
  sourceRecord: DbRecordEntityTransform;
  noteRecord: DbRecordEntityTransform;
  noteAttachments: DbRecordEntityTransform[];
  noteTasks: DbRecordEntityTransform[];
  userReducer: any;
  schemaReducer: ISchemaReducer;
  createRecord: (params: ICreateRecords, cb: any) => void;
  deleteRecord: (payload: IDeleteRecordById, cb: any) => void;
  updateRecord: (params: any, cb: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  previewMode?: boolean;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  initializeForm: (params: any) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

dayjs.extend(relativeTime);
const { SUPPORT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { NOTE, FILE } = SchemaModuleEntityTypeEnums;

const NoteBody: React.FC<Props> = (props: Props) => {
  const {
    noteRecord,
    userReducer,
    schemaReducer,
    deleteRecord,
    updateRecord,
    createRecord,
    noteAttachments,
    noteTasks,
    getRecordById,
    previewMode,
    getSchema,
    initializeForm,
    sourceRecord,
    openDrawer,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);
  const [newNoteBody, setNewNoteBody] = useState<any>(undefined);
  const [newReplyBody, setNewReplyBody] = useState<any>(undefined);
  const [isAddingReply, setIsAddingReply] = useState<boolean>(false);
  const replyContainerRef = React.useRef<HTMLDivElement>(null);
  const [taskSchema, setTaskSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isPrivateNote, setIsPrivateNote] = useState<boolean>(
    isRecordInInternalNoteGroup(noteRecord),
  );

  // This is the list of file ids that are attached to the new reply
  const [attachedReplyFileIds, setAttachedFileIds] = useState<string[]>([]);

  const { updateNote, deleteNote, addReply, updateReaction, addTask } = useContext(NoteFeedContext);
  const noteSchema = getSchemaFromShortListByModuleAndEntity(
    schemaReducer.shortList,
    SUPPORT_MODULE,
    NOTE,
  );

  useEffect(() => {
    if (!taskSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        SUPPORT_MODULE,
        SUPPORT_TASK,
      );
      if (shortlistSchema) {
        setTaskSchema(shortlistSchema);
      } else {
        getSchema({ moduleName: SUPPORT_MODULE, entityName: SUPPORT_TASK }, (res: any) => {
          setTaskSchema(res);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isReplying && !isMobile) {
      // wait for 100ms
      setTimeout(() => {
        window.scrollTo({
          top: replyContainerRef.current?.offsetTop! + 100,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [isReplying]);

  useEffect(() => {
    setIsPrivateNote(isRecordInInternalNoteGroup(noteRecord));
  }, [noteRecord]);

  const noteReplies = noteRecord?.[NOTE]?.dbRecords || [];

  const isRichTextNote = !!getProperty(noteRecord, 'JSONContent');

  const isRecordNote = (record: DbRecordEntityTransform) => {
    return record.entity === `${SUPPORT_MODULE}:${NOTE}`;
  };

  const isRecordTask = (record: DbRecordEntityTransform) => {
    return record.entity === `${SUPPORT_MODULE}:${SUPPORT_TASK}`;
  };

  let mergedRepliesAndTasks = [...noteReplies, ...noteTasks];

  mergedRepliesAndTasks = mergedRepliesAndTasks.sort(
    (a: DbRecordEntityTransform, b: DbRecordEntityTransform) =>
      dayjs(a.createdAt).diff(dayjs(b.createdAt)),
  );

  const deleteNoteRequest = () => {
    if (noteSchema) {
      setIsDeleting(true);
      deleteRecord(
        {
          schema: noteSchema,
          recordId: noteRecord.id,
        },
        () => {
          deleteNote(noteRecord.id);
          setIsDeleting(false);
          setIsDeleteDialogVisible(false);
        },
      );
    }
  };

  const updateNoteRequest = () => {
    if (noteSchema) {
      setIsSaving(true);

      // Is note private
      let privateGroupId: string | null = null;
      if (isPrivateNote && userReducer) {
        const groups = userReducer.user.groups || [];
        const privateGroup = groups.find(
          (group: OrganizationUserGroupEntity) => group.name === INTERNAL_NOTE_GROUP_NAME,
        );
        if (privateGroup) {
          privateGroupId = privateGroup.id;
        }
      }

      return updateRecord(
        {
          schema: noteSchema,
          recordId: noteRecord.id,
          schemaAssociation: undefined,
          createUpdate: {
            entity: `${SUPPORT_MODULE}:${NOTE}`,
            properties: {
              JSONContent: newNoteBody,
            },
            groups: privateGroupId ? [privateGroupId] : [],
          },
        },
        () => {
          // Update strategy here, we need to bring back the entire note record back
          const groups = isPrivateNote
            ? [{ name: INTERNAL_NOTE_GROUP_NAME, id: privateGroupId }]
            : [];

          updateNote(noteRecord.id, newNoteBody, groups);
          setIsSaving(false);
          setIsEditing(false);
          setNewNoteBody(undefined);
        },
      );
    }
  };

  const addReactionRequest = (emojiId: string, userId: string) => {
    if (noteSchema) {
      const newReactions = updateReactionsOnNote(noteRecord.properties.Reactions, emojiId, userId);
      return updateRecord(
        {
          schema: noteSchema,
          recordId: noteRecord.id,
          schemaAssociation: undefined,
          createUpdate: {
            entity: `${SUPPORT_MODULE}:${NOTE}`,
            properties: {
              Reactions: newReactions,
            },
          },
        },
        () => {
          updateReaction(noteRecord.id, newReactions);
          setIsSaving(false);
          setIsEditing(false);
          setNewNoteBody(undefined);
        },
      );
    }
  };

  const removeReactionRequest = () => {
    if (noteSchema) {
      const newReactions = removeReactionOnNote(
        noteRecord.properties.Reactions,
        userReducer.user?.id,
      );

      return updateRecord(
        {
          schema: noteSchema,
          recordId: noteRecord.id,
          schemaAssociation: undefined,
          createUpdate: {
            entity: `${SUPPORT_MODULE}:${NOTE}`,
            properties: {
              Reactions: newReactions,
            },
          },
        },
        () => {
          updateReaction(noteRecord.id, newReactions);
          setIsSaving(false);
          setIsEditing(false);
          setNewNoteBody(undefined);
        },
      );
    }
  };

  const addReplyRequest = () => {
    if (noteSchema) {
      setIsAddingReply(true);
      createRecord(
        {
          schema: noteSchema,
          createUpdate: [
            {
              title: 'Reply to note',
              schemaId: noteSchema.id,
              entity: `${SUPPORT_MODULE}:${NOTE}`,
              properties: {
                JSONContent: newReplyBody,
                ParentNoteId: noteRecord.id,
              },
              associations: [
                {
                  entity: noteRecord.entity!,
                  recordId: noteRecord.id,
                  relationType: RelationTypeEnum.CHILD,
                },
                // Attach files to the note
                ...attachedReplyFileIds.map((fileId: string) => ({
                  entity: `${SCHEMA_MODULE}:${FILE}`,
                  recordId: fileId,
                  relationType: RelationTypeEnum.CHILD,
                })),
              ],
            },
          ],
        },
        (res: any) => {
          // Fetch note by id and set it to reducer
          getRecordById({ schema: noteSchema, recordId: res.id }, (res: any) => {
            addReply(noteRecord.id, res);
            setIsReplying(false);
            setIsAddingReply(false);
          });

          setAttachedFileIds([]);
          setNewReplyBody(undefined);
        },
      );
    }
  };

  const canUserEditNote = () => {
    if (isSystemAdmin(userReducer)) {
      return true;
    } else {
      return isUserAuthorOfNote(noteRecord, userReducer) && isNoteOlderThanSevenDays(noteRecord);
    }
  };

  const onReactionAdded = (emojiId: string) => {
    const userId = userReducer.user?.id;
    addReactionRequest(emojiId, userId);
  };

  const onTaskCreate = (params: { event: string; results: any }) => {
    if (taskSchema && params) {
      getRecordById({ schema: taskSchema, recordId: params.results.id }, (res: any) => {
        addTask(noteRecord.id, res);
      });
    }
  };

  // Initialize Support Task create form, and add source record and note to associations
  const initializeTaskForm = () => {
    if (taskSchema) {
      initializeForm({
        title: 'Create Task',
        formUUID: noteRecord.id,
        showFormModal: true,
        showInitializing: false,
        isCreateReq: true,
        isUpdateReq: false,
        schema: taskSchema,
        sections: [
          {
            name: taskSchema?.name,
            schema: taskSchema,
          },
        ],
        modified: [
          {
            associations: [
              // Associate the note
              {
                entity: noteRecord.entity,
                recordId: noteRecord.id,
                relationType: RelationTypeEnum.CHILD,
              },
              // Associate the source record
              {
                entity: sourceRecord.entity,
                recordId: sourceRecord.id,
                relationType: RelationTypeEnum.CHILD,
              },
            ],
            schemaId: taskSchema?.id,
          },
        ],
      });
    }
  };

  const renderNoteBody = () => {
    return (
      <div
        key={`${noteRecord?.updatedAt}viewerDiv`}
        style={{
          background: '#F5F5F5',
          width: '100%',
          padding: '6px 12px',
          borderRadius: 8,
        }}
      >
        <div className="noteBody">
          <Col span={24}>
            {!isEditing && (
              <Row align="middle" style={{ marginBottom: 10 }}>
                <Col xs={12} md={17}>
                  {/* Full Name */}
                  <Typography size="default" style={{ fontWeight: 500, display: 'inline-block' }}>
                    {noteRecord?.lastModifiedBy?.fullName || ''}{' '}
                  </Typography>
                  {/* Created Date */}
                  <Typography
                    size="small"
                    style={{ opacity: 0.6, paddingLeft: 5, display: 'inline-block' }}
                  >
                    <Tooltip
                      content={parseDateForNoteFeed(noteRecord.createdAt as any)}
                      hoverOpenDelay={1200}
                      position="top"
                      fill
                    >
                      <div>
                        <span>{dayjs(noteRecord.createdAt).fromNow()} </span>
                      </div>
                    </Tooltip>
                  </Typography>
                </Col>
                <Col
                  xs={12}
                  md={7}
                  style={{ textAlign: 'right' }}
                  className="noteBodyActionsToolbar"
                >
                  {isSystemAdmin(userReducer) && (
                    <Button
                      className="noteBodyActions"
                      icon="eye-open"
                      small={isMobile}
                      minimal
                      onClick={() =>
                        openDrawer({
                          recordId: noteRecord?.id,
                          moduleName: SUPPORT_MODULE,
                          entityName: NOTE,
                        })
                      }
                    />
                  )}
                  {/* Edit */}
                  <Button
                    className="noteBodyActions"
                    icon="edit"
                    small={isMobile}
                    disabled={!canUserEditNote() || isReplying}
                    minimal
                    onClick={initializeEditor}
                  />
                  {/* Reply */}
                  <Button
                    className="noteBodyActions"
                    icon={<i className="bi bi-reply-fill" style={{ fontSize: 18, opacity: 0.7 }} />}
                    disabled={isEditing}
                    minimal
                    small={isMobile}
                    onClick={() => setIsReplying(!isReplying)}
                  />

                  {/* More */}
                  <Popover content={menuItems()} placement="bottom" className="noteBodyActions">
                    <Button small minimal alignText="left" rightIcon="more" />
                  </Popover>
                </Col>
              </Row>
            )}
          </Col>

          {/* NoteBody */}
          <Col span={24} style={{ marginBottom: noteAttachments.length > 0 ? 0 : 5 }}>
            {isRichTextNote ? (
              <NoteEditor
                key={`${noteRecord?.updatedAt}ViewModeEditor`}
                isViewMode={true}
                value={getProperty(noteRecord, 'JSONContent')}
              />
            ) : (
              <NoteEditor
                key={`${noteRecord?.updatedAt}ViewModeEditor`}
                isViewMode={true}
                value={[
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: getProperty(noteRecord, 'Body')
                          ? getProperty(noteRecord, 'Body')
                          : '',
                      },
                    ],
                  },
                ]}
              />
            )}
          </Col>

          {noteAttachments.length > 0 && (
            <Col span={24}>
              <NoteAttachments
                key={noteRecord.id}
                viewMode={!isEditing}
                fileIds={noteAttachments?.map((file: DbRecordEntityTransform) => file.id)}
                parentNote={noteRecord}
              />
            </Col>
          )}

          {/* Reactions */}
          <Row align="middle" justify="space-between" style={{ marginTop: 15, marginBottom: 3 }}>
            {/* Left Column */}
            <Col>
              {/* Private */}
              {isRecordInInternalNoteGroup(noteRecord) && (
                <CompoundTag
                  leftContent={<Icon icon="eye-off" size={13} />}
                  round
                  intent="warning"
                  minimal
                >
                  Private
                </CompoundTag>
              )}
            </Col>
            {/* Right Column */}

            <Col>
              <Row justify="end">
                <NoteReactionsList
                  noteRecord={noteRecord}
                  key={noteRecord.id}
                  reactions={noteRecord.properties.Reactions}
                  onReactionRemoved={removeReactionRequest}
                />
                <NoteReactionsPicker
                  key={noteRecord.properties.Reactions}
                  disabled={isReplying}
                  noteRecord={noteRecord}
                  onReactionAdded={onReactionAdded}
                />
              </Row>
            </Col>
          </Row>
        </div>

        {/* Merged Replies and Tasks */}
        {mergedRepliesAndTasks?.length! > 0 && (
          <Col span={24} style={{ marginTop: 10 }}>
            {mergedRepliesAndTasks?.map((record: DbRecordEntityTransform, i: number) => (
              <Row style={{ borderTop: '1px solid #e5e5e5' }}>
                <Col span={24} key={i + 1}>
                  {
                    // If a record is note, return ReplyBody, else return NoteTask
                    isRecordNote(record) ? (
                      <ReplyBody
                        key={i}
                        noteRecord={record}
                        noteAttachments={record[FILE]?.dbRecords || []}
                      />
                    ) : isRecordTask(record) ? (
                      <NoteTask taskRecord={record} />
                    ) : null
                  }
                </Col>
              </Row>
            ))}
          </Col>
        )}
      </div>
    );
  };

  const initializeEditor = () => {
    if (isRichTextNote) {
      setNewNoteBody(getProperty(noteRecord, 'JSONContent'));
    } else {
      setNewNoteBody([
        {
          type: 'paragraph',
          children: [
            { text: getProperty(noteRecord, 'Body') ? getProperty(noteRecord, 'Body') : '' },
          ],
        },
      ]);
    }
    setIsEditing(true);
  };

  const renderNoteUpdateForm = () => {
    return (
      <Row
        style={{
          height: '100%',
          padding: 10,
          border: '1px solid #2D72D2',
          borderRadius: 8,
        }}
      >
        <Col span={24} style={{ opacity: isSaving ? 0.4 : 1 }}>
          <Row>
            <Col span={24}>
              {/* Editor */}
              <NoteEditor
                key={`${noteRecord?.updatedAt}NoteEditor`}
                onChange={(e: any) => setNewNoteBody(e)}
                value={newNoteBody}
                isViewMode={isSaving}
              />
            </Col>
          </Row>
        </Col>
        {isEditing && (
          <Col span={24}>
            <NoteAttachments
              viewMode={false}
              fileIds={noteAttachments?.map((file: DbRecordEntityTransform) => file.id)}
              parentNote={noteRecord}
              key={noteAttachments?.length}
            />
          </Col>
        )}
        <Col span={12} style={{ marginTop: 8 }}>
          <Switch
            label="Private Note"
            inline
            style={{ margin: 0, paddingTop: 6 }}
            checked={isPrivateNote}
            onChange={() => setIsPrivateNote(!isPrivateNote)}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right', marginTop: 8 }}>
          <Button
            key={`${noteRecord?.id}CancelButton`}
            text="Cancel"
            disabled={isSaving}
            onClick={() => setIsEditing(false)}
            style={{ marginRight: 10 }}
          />
          <Button
            key={`${noteRecord?.id}SaveButton`}
            text="Save"
            disabled={isSaving}
            intent="primary"
            onClick={updateNoteRequest}
          />
        </Col>
      </Row>
    );
  };

  const addFileToAttachments = (id: string) => {
    setAttachedFileIds([...attachedReplyFileIds, id]);
  };

  const removeFileFromAttachments = (id: string) => {
    setAttachedFileIds(attachedReplyFileIds.filter((fileId: string) => fileId !== id));
  };

  const isNoteFormEmpty = JSON.stringify(newReplyBody) === JSON.stringify(emptyNoteBody);

  const menuItems = () => {
    return (
      <Menu>
        {/* 30-APR-2024 Removing this feature until backend is ready */}
        <MenuItem text="Create Task" icon="plus" onClick={initializeTaskForm} />

        {/* Delete Note */}
        <Tooltip
          fill
          hoverOpenDelay={800}
          disabled={!isNoteOlderThanSevenDays(noteRecord)}
          content="Only notes older than 7 days can be deleted."
        >
          <MenuItem
            icon="trash"
            intent="danger"
            text={'Delete Note'}
            key="menuItemDelete"
            disabled={!canUserEditNote() || isReplying}
            onClick={() => {
              setIsDeleteDialogVisible(true);
            }}
          />
        </Tooltip>
      </Menu>
    );
  };

  return (
    <Row>
      <CoreForm
        type="MODAL"
        formUUID={noteRecord.id}
        onSubmitEvent={(params: { event: string; results: any }) => {
          onTaskCreate(params);
        }}
      />
      <Col
        xs={2}
        md={2}
        lg={previewMode ? 2 : 1}
        style={{ paddingTop: 20, paddingRight: 8, textAlign: 'right' }}
      >
        <Avatar
          size="small"
          style={{
            backgroundColor: getPastelColorForUsername(
              noteRecord?.lastModifiedBy?.fullName || 'Unknown',
            ),
          }}
        >
          {getInitialsFromName(noteRecord?.lastModifiedBy?.fullName || '')}
        </Avatar>
      </Col>
      <Col xs={22} md={22} lg={previewMode ? 22 : 23}>
        <div
          className="noteSection"
          key={`${noteRecord?.id}NoteSection`}
          style={{ marginTop: 10, width: '100%' }}
        >
          {/* Note Body / Update form */}
          <Row key={noteRecord?.id}>
            <Col span={24} key={noteRecord?.id}>
              {isEditing ? renderNoteUpdateForm() : renderNoteBody()}
            </Col>
          </Row>

          {/* Reply Editor */}
          {isReplying && !isEditing && (
            <Row ref={replyContainerRef}>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Row
                  style={{
                    border: '1px solid #D4D5D8',
                    borderRadius: 4,
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <Col span={24}>
                    <NoteEditor
                      key={`ReplyNoteEditor`}
                      onChange={(e: any) => setNewReplyBody(e)}
                      value={[
                        {
                          type: 'paragraph',
                          children: [
                            {
                              text: getProperty(noteRecord, 'Body')
                                ? getProperty(noteRecord, 'Body')
                                : '',
                            },
                          ],
                        },
                      ]}
                      isViewMode={isSaving}
                    />
                  </Col>
                  <Col span={24}>
                    <NoteAttachments
                      viewMode={false}
                      fileIds={attachedReplyFileIds}
                      isEditing={true}
                      onFileAdded={addFileToAttachments}
                      onFileRemoved={removeFileFromAttachments}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ textAlign: 'right', padding: '12px 0px', marginBottom: 8 }}>
                <Button
                  text="Cancel"
                  disabled={isAddingReply}
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    setNewReplyBody(undefined), setIsReplying(false), setAttachedFileIds([]);
                  }}
                />
                <Button
                  intent="primary"
                  text="Reply"
                  disabled={isNoteFormEmpty || isAddingReply}
                  onClick={addReplyRequest}
                  loading={isAddingReply}
                />
              </Col>
            </Row>
          )}
        </div>
      </Col>

      <Alert
        intent="danger"
        onCancel={() => setIsDeleteDialogVisible(false)}
        isOpen={isDeleteDialogVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        canEscapeKeyCancel={!isDeleting}
        canOutsideClickCancel={!isDeleting}
        onConfirm={deleteNoteRequest}
        loading={isDeleting}
      >
        <p>Are you sure you want to delete this note?</p>
      </Alert>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateRecord: (params: any, cb: any) => dispatch(updateRecordByIdRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  deleteRecord: (payload: IDeleteRecordById, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(NoteBody);
