import { useState, useEffect } from "react";
import { Button, Spinner, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";

import { SchemaEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity";

import { httpGet } from "../../../../../../../../../shared/http/requests";
import { displayMessage } from "../../../../../../../../../shared/system/messages/store/reducers";
import { useAction } from "../../../../../../../../ProjectModule/hooks/useAction";
import { getErrorMessage } from "../../../../../../../utils/errors";

import { TPanelDataItem } from "./types";

type SchemaDropdownProps = {
  items?: TPanelDataItem[];
  disabled?: boolean;
  selectedItem: TPanelDataItem | null;
  setSelectedItem: (item: TPanelDataItem | null) => void;
};

const renderItem: ItemRenderer<TPanelDataItem> = (item, { handleClick, handleFocus, modifiers }) => {
  return (
    <MenuItem
      key={item.id}
      text={item.name}
      roleStructure='listoption'
      active={modifiers.active}
      onClick={handleClick}
      onFocus={handleFocus}
    />
  );
}

const filterItems = (items: TPanelDataItem[], query: string) => {
  if (!query) return items;

  return items.filter(i => i.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
}

export const parseSchema = (schema: SchemaEntity): TPanelDataItem => ({
  id: schema.id,
  name: schema.entityName,
  description: schema.description,
});

const parseSchemas = (schemas: SchemaEntity[]): TPanelDataItem[] => {
  return schemas.map(parseSchema);
};

export const SchemaDropdown = ({ items = [], disabled, selectedItem, setSelectedItem }: SchemaDropdownProps) => {
  const [search, setSearch] = useState<string>('');
  const [isLoadingSchemas, setIsLoadingSchemas] = useState(false);
  const [allSchemas, setAllSchemas] = useState<TPanelDataItem[]>([]);

  const alertMessage = useAction(displayMessage);

  // Load Schemas from API
  useEffect(() => {
    // Don't load schemas if options are provided
    if (items.length) return;

    let mounted = true;

    setIsLoadingSchemas(true);

    httpGet('SchemaModule/v1.0/schemas')
      .then(res => {
        if (!mounted) return;

        const schemas: TPanelDataItem[] = parseSchemas(res.data.data || []);

        schemas.sort(
          (a: TPanelDataItem, b: TPanelDataItem) => a.name.localeCompare(b.name)
        );

        setAllSchemas(schemas);
        setIsLoadingSchemas(false);
      })
      .catch((e: any) => {
        if (!mounted) return;

        const message = getErrorMessage(e);
        alertMessage({
          body: 'Could not load schemas. ' + message,
          type: 'error',
        });
        setIsLoadingSchemas(false);
      });

    return () => {
      mounted = false;
    };
  }, []);

  // Load Schemas from Props
  useEffect(() => {
    if (!items.length) return;

    setAllSchemas(items);
  }, [items.length]);

  return (
    <Select<TPanelDataItem>
      disabled={isLoadingSchemas || disabled}
      // Items
      items={filterItems(allSchemas, search)}
      itemRenderer={renderItem}
      // Search
      query={search}
      onQueryChange={setSearch}
      // Selection
      activeItem={selectedItem}
      onItemSelect={setSelectedItem}
    >
      <Button
        text={selectedItem ? selectedItem.name : "Select Schema"}
        rightIcon={isLoadingSchemas ? <Spinner size={16} /> : "caret-down"}
        fill
        disabled={isLoadingSchemas || disabled}
        className="dropdown__button"
      />
    </Select>
  );
};
