import { ReloadOutlined } from '@ant-design/icons';
import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Empty, List, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import { FunctionComponent, SetStateAction } from 'react';
import StageNameTag from '../../shared/components/StageNameTag';

interface OwnProps {
  isLoading: boolean;
  setIsEditing: SetStateAction<any>;
  setViewedCaseRecord: SetStateAction<any>;
  articleList: Array<any>;
  searchCases: any;
}

type Props = OwnProps;
const { Title } = Typography;

const CaseRecordList: FunctionComponent<Props> = (props) => {
  const { isLoading, articleList, setIsEditing, searchCases, setViewedCaseRecord } = props;

  const truncate = (str: any) => {
    return str?.length > 10 ? str.substring(0, 30) + '...' : str;
  };

  const renderArticleList = () => {
    if (articleList?.length > 0) {
      return (
        <div>
          <List
            itemLayout="horizontal"
            dataSource={articleList}
            renderItem={(article: DbRecordEntityTransform) => (
              <List.Item
                actions={[
                  <StageNameTag
                    record={article}
                    text={article.stage ? article.stage.name : 'No Stage'}
                    size="small"
                  >
                    Stage
                  </StageNameTag>,
                ]}
              >
                <List.Item.Meta
                  title={
                    article.title ? (
                      <span
                        onClick={() => setViewedCaseRecord(article)}
                        style={{
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          color: '#1890ff',
                        }}
                      >
                        {article.title}
                      </span>
                    ) : (
                      'No Title'
                    )
                  }
                  description={
                    <Row>
                      <Col span={24}>{truncate(getProperty(article, 'Question'))}</Col>
                      <Col span={24} style={{ fontSize: '0.8em', marginTop: 4 }}>
                        {moment(article.createdAt).format('DD/MM/YYYY' + ' -' + ' HH:mm:ss')}
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      );
    } else {
      return <Empty description="No Feature Requests found" style={{ margin: 60 }} />;
    }
  };

  return isLoading ? (
    <Row style={{ textAlign: 'center' }}>
      <Col span={24} style={{ padding: 15, marginBottom: 20 }}>
        <span>Loading Support Articles ...</span>
      </Col>
      <Col span={24}>
        <Spin size="large" />
      </Col>
    </Row>
  ) : (
    <>
      <Row>
        <Col span={10}>
          <Title level={4} style={{ marginTop: 0 }}>
            Feature Requests
          </Title>
        </Col>
        <Col span={14} style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 12 }}
            icon={<ReloadOutlined />}
            onClick={() => searchCases()}
          />
          <Button intent="primary" disabled={isLoading} onClick={() => setIsEditing(true)}>
            Create New
          </Button>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            padding: '0 17px',
            border: '1px solid #e3e3e3',
            marginTop: 15,
            borderRadius: 5,
          }}
        >
          {renderArticleList()}
        </Col>
      </Row>
    </>
  );
};

export default CaseRecordList;
