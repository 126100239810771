import { httpPatch, httpPost } from '../../../../../shared/http/requests';

export const submitNAOrder = async (recordId: string, alertMessage: Function) => {
  if (recordId) {
    await httpPost(`ProjectModule/v1.0/openreach/networkadjustment/orders/${recordId}`, {})
      .then((res) => {
        alertMessage({
          body: 'Successfully submitted NetworkAdjustment Order.',
          type: 'success',
        });
      })
      .catch((err) => {
        alertMessage({
          body: `Failed to submit NetworkAdjustment Order. ${err.response?.data?.message}`,
          type: 'error',
        });
      });
  }
};

export const cancelNAOrder = async (recordId: string, alertMessage: Function) => {
  if (recordId) {
    await httpPatch(`ProjectModule/v1.0/openreach/networkadjustment/orders/${recordId}/cancel`, {})
      .then((res) => {
        alertMessage({
          body: 'Successfully cancelled NetworkAdjustment Order.',
          type: 'success',
        });
      })
      .catch((err) => {
        alertMessage({
          body: `Failed to cancel NetworkAdjustment Order. ${err.response?.data?.message}`,
          type: 'error',
        });
      });
  }
};

export const submitA55 = async (recordId: string, alertMessage: Function) => {
  if (recordId) {
    await httpPost(`ProjectModule/v1.0/openreach/networkadjustment/orders/${recordId}/a55`, {})
      .then((res) => {
        alertMessage({
          body: 'Successfully submitted NetworkAdjustment A55.',
          type: 'success',
        });
      })
      .catch((err) => {
        alertMessage({
          body: `Failed to submit NetworkAdjustment A55. ${err.response?.data?.message}`,
          type: 'error',
        });
      });
  }
};

export const submitProblemEvidence = async (recordId: string, alertMessage: Function) => {
  if (recordId) {
    await httpPost(
      `ProjectModule/v1.0/openreach/networkadjustment/lines/${recordId}/problem-evidence`,
      {},
    )
      .then((res) => {
        alertMessage({
          body: 'Successfully submitted Problem evidence.',
          type: 'success',
        });
      })
      .catch((err) => {
        alertMessage({
          body: `Failed to submit Problem evidence. ${err.response?.data?.message}`,
          type: 'error',
        });
      });
  }
};

export const submitSolutionEvidence = async (recordId: string, alertMessage: Function) => {
  if (recordId) {
    await httpPost(
      `ProjectModule/v1.0/openreach/networkadjustment/lines/${recordId}/solution-evidence`,
      {},
    )
      .then((res: any) => {
        alertMessage({
          body: 'Successfully submitted Solution evidence.',
          type: 'success',
        });
      })
      .catch((err) => {
        alertMessage({
          body: `Failed to submit Solution evidence. ${err.response?.data?.message}`,
          type: 'error',
        });
      });
  }
};

export const submitSPOCompletion = async (recordId: string, alertMessage: Function) => {
  if (recordId) {
    await httpPost(`ProjectModule/v1.0/openreach/networkadjustment/lines/${recordId}/complete`, {})
      .then((res) => {
        alertMessage({
          body: 'cSuccessfully submitted SPO completion.',
          type: 'success',
        });
      })
      .catch((err) => {
        alertMessage({
          body: `Failed to submit SPO completion. ${err.response?.data?.message}`,
          type: 'error',
        });
      });
  }
};
