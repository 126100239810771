import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { INVOICE } = SchemaModuleEntityTypeEnums;

const InvoiceItemDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      leftColumn={[
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={BILLING_MODULE}
          associatedRecordEntityName={INVOICE}
          visibleProperties={['Status', 'TotalDue', 'Balance']}
        />,
      ]}
    />
  );
};

export default InvoiceItemDetailView;
