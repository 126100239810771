import { Section } from '@blueprintjs/core';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Divider, Layout, Popconfirm, Row, Tooltip } from 'antd';
import React, { FC, useContext, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import OfferProductSelector from '../../../../../containers/OrderModule/containers/Order/AddProducts/OfferProductSelector';
import OrderNetworkItems from '../../../../../containers/OrderModule/containers/Order/OrderNetworkItems';
import PaymentScheduleList from '../../../../../containers/OrderModule/containers/Order/PaymentScheduleList';
import AlertPanel from '../../../../../core/records/components/AlertPanel';
import RecordCard from '../../../../../core/records/components/RecordCard';
import { getRecordByIdRequest, IGetRecordById } from '../../../../../core/records/store/actions';
import AssociationDataTable from '../../../../../core/recordsAssociations/components/AssociationDataTable';
import {
  IOrderAmendWorkflowParams,
  orderAmendWorkflow,
} from '../../../../../core/workflow/store/actions';
import { httpGet } from '../../../../../shared/http/requests';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';
import InvoicePendingCallout from '../../../BillingModule/components/InvoicePendingCallout';
import PaymentMethodRefundPendingCallout from '../../../BillingModule/components/PaymentMethodRefundPendingCallout';
import WorkOrderPendingCallout from '../../../FieldServiceModule/components/WorkOrderPendingCallout';
import CreateCollectionWorkOrder from '../../components/CreateCollectionWorkOrder';
import CreateInstallWorkOrder from '../../components/CreateInstallWorkOrder';
import CreateServiceWorkOrder from '../../components/CreateServiceWorkOrder';
import OrderContractBuyOut from '../../components/OrderContractBuyout';
import OrderContractRenewal from '../../components/OrderContractRenewal';
import OrderEarlyTerminationFeeCalculator from '../../components/OrderEarlyTerminateFeeCalculator';
import OrderEmailActionButton from '../../components/OrderEmailActionButton';
import OrderItemsList from '../../components/OrderItemsList';
import CasesPendingCallout from '../../../SupportModule/components/CasesPendingCallout';
import './styles.scss';
import OrderBuilderDrawer from '../../OrderBuilder/OrderBuilderDrawer';
import OrderContractRollbackButton from '../../components/OrderContractRollbackButton';
import { IUserInterfaceReducer } from '../../../../../core/userInterface/store/types';
import { usePrevious } from '../../../../../shared/utilities/reactHelpers';
import CreateOneTouchSwitchOrder from '../../components/CreateOneTouchSwitchOrder';
import { hasPermissions } from '../../../../../shared/permissions/rbacRules';

const { CRM_MODULE, FIELD_SERVICE_MODULE, BILLING_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { ACCOUNT, BILLING_ADJUSTMENT, INVOICE, CONTACT, ADDRESS, WORK_ORDER } =
  SchemaModuleEntityTypeEnums;

const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';

interface Props {
  userReducer: any;
  orderAmendWorkflow: (params: IOrderAmendWorkflowParams) => void;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
  userInterfaceReducer: IUserInterfaceReducer;
}

const OrderDetailView: FC<Props> = (props: Props) => {
  const { getRecordById, orderAmendWorkflow, userInterfaceReducer, userReducer } = props;
  const { record, schema } = useContext(DetailViewContext);
  const [orderItems, setOrderItemData] = React.useState<
    DbRecordAssociationRecordsTransform | undefined
  >(undefined);

  const orderBuilderDrawerIsOpen = userInterfaceReducer.orderBuilderDrawerVisible;

  const previousOrderBuilderDrawerState = usePrevious(orderBuilderDrawerIsOpen);

  const initRecontract = () => {
    orderAmendWorkflow({
      init: true,
      orderId: record?.id,
      isAmendOrderVisible: true,
    });
  };

  // When Order builder is closed, fetch back the record
  useLayoutEffect(() => {
    if (!userInterfaceReducer.orderBuilderDrawerVisible && previousOrderBuilderDrawerState) {
      getRecordById({ schema, recordId: record.id });
    }
  }, [userInterfaceReducer.orderBuilderDrawerVisible]);

  useEffect(() => {
    if (record) {
      getAllOrderItems();
    }
  }, [record]);

  const getAllOrderItems = () => {
    if (record) {
      httpGet(
        `OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=OrderItem&nestedEntities=["Product"]&withLinks=false`,
      )
        .then((res) => {
          setOrderItemData(res.data['OrderItem'] || []);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });
    }
  };

  const refreshOrderRecord = () => {
    if (record && schema) {
      // wait one second before fetching the record again
      setTimeout(() => {
        getRecordById(
          {
            schema: schema,
            recordId: record.id,
          },
          () => {
            console.log('debug: record is fetched again - WITHOUT RECALCULATION');
          },
        );
      }, 4000);
    }
  };

  return (
    <Layout>
      <OrderBuilderDrawer />
      <DetailView
        showCollaborators
        showCommunicationHistory={true}
        middleColumnTop={
          getProperty(record, 'AlertMessage') && (
            <div style={{ marginBottom: 15 }}>
              <AlertPanel record={record} />
            </div>
          )
        }
        banner={<CasesPendingCallout record={record} hideEmpty={true} />}
        headerExtras={[
          <OrderContractBuyOut
            record={record}
            isDisabled={record?.stage?.key === 'OrderStageCancelled'}
          />,
          hasPermissions(userReducer, ['ordermodule.onetouchswitch.orderjourney.enabled']) && (
            <CreateOneTouchSwitchOrder record={record!} />
          ),
          <Tooltip
            placement="top"
            mouseEnterDelay={0.8}
            title={
              record?.stage?.key !== 'OrderStageActive'
                ? 'The order should be in Active stage'
                : 'Upgrade/downgrade/remove items'
            }
          >
            <Popconfirm
              title="Do you want to amend the Order?"
              disabled={record?.stage?.key !== 'OrderStageActive'}
              onConfirm={() => initRecontract()}
              okText="Yes"
              cancelText="No"
            >
              <OrderContractRenewal record={record} />
            </Popconfirm>
          </Tooltip>,
          <OrderContractRollbackButton record={record!} schema={schema!} />,
          // Email Actions Button
          <OrderEmailActionButton record={record} />,
        ]}
        defaultTabKey={'Summary'}
        customTabs={[
          {
            key: 'Summary',
            tab: 'Summary',
          },
          {
            key: 'PaymentSchedule',
            tab: 'Payment Schedule',
          },
          {
            key: 'Devices',
            tab: 'Devices',
          },
          {
            key: 'WorkOrders',
            tab: 'Work Orders',
          },
          {
            key: 'Billing',
            tab: 'Billing',
          },
        ]}
        customTabBodies={{
          Billing: (
            <div>
              {record?.stage?.name &&
              ['Active', 'Deactivated', 'Cancelled'].includes(record?.stage?.name) ? (
                <div style={{ marginBottom: 15 }}>
                  <OrderEarlyTerminationFeeCalculator record={record} />
                </div>
              ) : (
                <></>
              )}

              <AssociationDataTable
                title={INVOICE}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={INVOICE}
                collapsedByDefault
                showAllRecords
              />

              <AssociationDataTable
                title={BILLING_ADJUSTMENT}
                record={record}
                moduleName={ORDER_MODULE}
                entityName={BILLING_ADJUSTMENT}
                collapsedByDefault
                showAllRecords
              />

              <AssociationDataTable
                title={'Refunds'}
                record={record}
                moduleName={BILLING_MODULE}
                entityName={PAYMENT_METHOD_REFUND}
                collapsedByDefault
                showAllRecords
              />
            </div>
          ),
          Summary: (
            <Row>
              <Col span={24}>
                <div>
                  <div style={{ marginTop: 15 }}>
                    <WorkOrderPendingCallout record={record} />
                  </div>
                  <div style={{ marginTop: 15 }}>
                    <PaymentMethodRefundPendingCallout record={record} />
                  </div>
                  <div style={{ marginTop: 15, marginBottom: 5 }}>
                    <InvoicePendingCallout record={record} />
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <Section
                  compact
                  className="orderItemsListSection"
                  title={'Order Items'}
                  rightElement={
                    <div>
                      <OfferProductSelector
                        buttonType="primary"
                        record={record!}
                        relation={orderItems!}
                        onSuccess={() => {}}
                      />
                    </div>
                  }
                  style={{ marginTop: 15, padding: 5 }}
                >
                  <OrderItemsList record={record!} orderItemsUpdated={refreshOrderRecord} />
                </Section>
              </Col>
            </Row>
          ),
          PaymentSchedule: <PaymentScheduleList record={record} />,
          Devices: <OrderNetworkItems record={record} />,
          WorkOrders: (
            <Row>
              <Col span={24} style={{ marginTop: 0, marginBottom: 10, textAlign: 'right' }}>
                <CreateServiceWorkOrder order={record} />
                <CreateInstallWorkOrder order={record} triggerButtonStyle={{ marginLeft: '8px' }} />
                <CreateCollectionWorkOrder
                  order={record}
                  triggerButtonStyle={{ marginLeft: '8px' }}
                />
              </Col>
              <Col span={24}>
                <Divider style={{ marginTop: 5, marginBottom: 15 }} />
              </Col>
              <Col span={24}>
                <RecordCard
                  record={record}
                  showActionMenu={false}
                  showItemActionMenu={false}
                  displayQuickView
                  showWithoutPagination
                  associatedRecordModuleName={FIELD_SERVICE_MODULE}
                  associatedRecordEntityName={WORK_ORDER}
                  shouldPollData
                  showRecordStage
                  visibleProperties={['Type']}
                />
              </Col>
            </Row>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ACCOUNT}
            visibleProperties={['Status', 'Type']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={CONTACT}
            visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ADDRESS}
            visibleProperties={[
              'Type',
              'SalesStatus',
              'ExPolygonId',
              'L1PolygonId',
              'L2PolygonId',
              'L4PolygonId',
              'L4ClosureId',
              'SubClassification',
            ]}
          />,
        ]}
      />
    </Layout>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  orderAmendWorkflow: (params: IOrderAmendWorkflowParams) => dispatch(orderAmendWorkflow(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(OrderDetailView);
