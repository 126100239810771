import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import Editor from '@monaco-editor/react';
import { Button, Col, Layout, Row, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DetailPanelLeft from '../../../../../core/records/components/DetailPanelLeft';
import RecordProperties from '../../../../../core/records/components/RecordProperties';
import {
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import { getSchemaByIdRequest, ISchemaById } from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { OdinSyntaxCompletionItemProvider } from '../../../../../core/workflowEngine/helpers/odin.syntax.completion.items.provider';
import {
  getWActivityJsonSchema,
  getWFunctionJsonSchema,
  getWRecordsSelectorFuncJsonSchema,
  wActivityActionJsonSchema,
  wActivityIfElseJsonSchema,
  wActivitySequenceJsonSchema,
} from '../../../../../core/workflowEngine/types/workflows.json.schemas';
import CardWithTabs from '../../../../../shared/components/CardWithTabs';
import { renderCreateUpdateDetails } from '../../../../../shared/components/RecordCreateUpdateDetails';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import {
  getAllSchemaAssociationSchemas,
  getRecordFromShortListById,
  getRecordRelatedFromShortListById,
} from '../../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import { format } from 'sql-formatter';
import { IsJsonString } from '../../../../../shared/utilities/validateDataTypes';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  match: any;
  hasColumnMappings?: boolean;
  visibleProperties?: string[];
  schemaReducer: ISchemaReducer;
  getSchema: (payload: ISchemaById, cb: any) => void;
  updateRecord: (params: IUpdateRecordById, cb?: (resp: any) => void) => void;
  alertMessage: (params: { body: string; type: string }) => void;
};

interface State {
  isEditingRecordSelectorFunc: boolean;
  isEditingActivity: boolean;
}

class WorkflowDetailView extends React.Component<PropsType, State> {
  state = {
    isEditingRecordSelectorFunc: false,
    isEditingActivity: false,
  };

  private editorsRefs: {
    selectorFunc?: any;
    rawSql?: any;
    activity?: any;
  } = {};

  private handleSelectorFuncEditorWillMount(monaco: any) {
    const { schemaReducer } = this.props;
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [getWRecordsSelectorFuncJsonSchema(monaco, schemaReducer.list)],
    });
  }

  private handleRawSqlEditorWillMount(monaco: any) {
    monaco.languages.registerDocumentFormattingEditProvider('pgsql', {
      provideDocumentFormattingEdits(model: any, options: any) {
        var formatted = format(model.getValue(), {
          indent: ' '.repeat(options.tabSize),
        });
        return [
          {
            range: model.getFullModelRange(),
            text: formatted,
          },
        ];
      },
    });
  }

  private handleActivityEditorWillMount(monaco: any) {
    const { schemaReducer, getSchema } = this.props;

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        getWRecordsSelectorFuncJsonSchema(monaco, schemaReducer.list),
        getWActivityJsonSchema(monaco),
        wActivityActionJsonSchema,
        getWFunctionJsonSchema(schemaReducer.list),
        wActivityIfElseJsonSchema,
        wActivitySequenceJsonSchema,
      ],
    });

    monaco.languages.registerCompletionItemProvider(
      'json',
      new OdinSyntaxCompletionItemProvider(schemaReducer.list, schemaReducer.shortList, getSchema),
    );
  }

  private getRawSqlValue(record: DbRecordEntityTransform) {
    if (!record) return '';

    const recordSelectorFuncJson = getProperty(record, 'RecordSelectorFunc');
    const rawSql = recordSelectorFuncJson?.params?.rawSql;

    return rawSql ?? '';
  }
  private setRawSqlToRecordSelectorFuncEditor() {
    const rawSqlStr = this.editorsRefs.rawSql?.getValue();
    const rawSqlValue = rawSqlStr?.trim()?.replace(/(\r\n|\r|\n|\s+)/gm, ' ');

    const emptyRecordSelectorFuncJson: any = {
      fn: 'dbQuery',
      params: {},
    };

    let recordSelectorFunc = this.editorsRefs.selectorFunc?.getValue();

    if (!recordSelectorFunc) {
      recordSelectorFunc = emptyRecordSelectorFuncJson;
    } else if (typeof recordSelectorFunc === 'string') {
      try {
        recordSelectorFunc = JSON.parse(recordSelectorFunc);
      } catch (e) {
        recordSelectorFunc = emptyRecordSelectorFuncJson;
      }
    }
    if (!recordSelectorFunc.params) {
      recordSelectorFunc.params = {};
    }
    recordSelectorFunc.params['rawSql'] = rawSqlValue || undefined;

    this.editorsRefs.selectorFunc?.setValue(JSON.stringify(recordSelectorFunc, undefined, '  '));
  }

  private updateRecordProperty(propertyName: string, value: any, onError?: (resp: any) => void) {
    const { match, recordReducer, schemaReducer, updateRecord, alertMessage } = this.props;

    if (!value) return;

    const record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);
    if (!record) return;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (!schema) return;

    const updateDto: DbRecordCreateUpdateDto = {
      schemaId: record.schemaId as any,
      properties: {
        [propertyName]: value,
      },
    };

    updateRecord(
      {
        schema,
        recordId: record.id,
        createUpdate: updateDto,
      },
      (resp: any) => {
        if (!resp) {
          alertMessage({
            body: `error updating ${propertyName}`,
            type: 'error',
          });
          if (onError) {
            onError(resp);
          }
        } else {
          alertMessage({
            body: `${propertyName} was updated`,
            type: 'success',
          });
        }
      },
    );
  }

  render() {
    const {
      schemaReducer,
      recordAssociationReducer,
      hasColumnMappings,
      recordReducer,
      match,
      visibleProperties,
    } = this.props;
    const { alertMessage } = this.props;

    let record: any;

    if (hasColumnMappings) {
      record = getRecordRelatedFromShortListById(
        recordAssociationReducer.shortList,
        match.params.dbRecordAssociationId,
        match.params.recordId,
      );
    } else {
      record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);
    }

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, ['Note']);

    return (
      <>
        <Layout className="record-detail-view">
          <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <div className="record-detail-left-panel">
                <DetailPanelLeft
                  record={record}
                  hasColumnMappings={hasColumnMappings}
                  visibleProperties={visibleProperties}
                  hideAuditInfo
                >
                  <RecordProperties record={record} columns={1} size="small" />
                  {renderCreateUpdateDetails(record)}
                </DetailPanelLeft>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12}>
              <div className="record-detail-center-panel">
                <div hidden={getProperty(record, 'RecordSelectorType') !== 'FUNC'}>
                  <Row>
                    <Col span={12}>
                      <h2>Record Selector Function</h2>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                      {this.state.isEditingRecordSelectorFunc ? (
                        <>
                          <Button
                            type="primary"
                            style={{ marginRight: 10, minWidth: 150 }}
                            onClick={() => {
                              const val = this.editorsRefs.selectorFunc?.getValue();
                              // ODN-2424 check for the valid JSON string
                              if (!val || IsJsonString(val)) {
                                this.setState({
                                  isEditingRecordSelectorFunc: false,
                                });
                                this.updateRecordProperty(
                                  'RecordSelectorFunc',
                                  val,
                                  (resp: any) => {
                                    this.editorsRefs.selectorFunc
                                      ?.getModel()
                                      .setValue(
                                        JSON.stringify(
                                          getProperty(record, 'RecordSelectorFunc'),
                                          undefined,
                                          '  ',
                                        ),
                                      );
                                  },
                                );
                              } else {
                                alertMessage({
                                  body: 'Function should be a valid JSON',
                                  type: 'error',
                                });
                              }
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            type="default"
                            style={{ marginRight: '10%', minWidth: 100 }}
                            onClick={() => {
                              this.setState({
                                isEditingRecordSelectorFunc: false,
                              });
                              this.editorsRefs.selectorFunc
                                ?.getModel()
                                .setValue(
                                  JSON.stringify(
                                    getProperty(record, 'RecordSelectorFunc'),
                                    undefined,
                                    '  ',
                                  ),
                                );
                              this.editorsRefs.rawSql?.setValue('');
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          type="primary"
                          style={{ marginRight: '10%', minWidth: 150 }}
                          onClick={() => this.setState({ isEditingRecordSelectorFunc: true })}
                          disabled={
                            this.state.isEditingRecordSelectorFunc || this.state.isEditingActivity
                          }
                        >
                          Edit Function
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <div>
                    <Spin spinning={recordReducer.isUpdating}>
                      <Editor
                        height="30vh"
                        path="//RecordSelectorFunc"
                        language="json"
                        value={JSON.stringify(
                          getProperty(record, 'RecordSelectorFunc'),
                          undefined,
                          '  ',
                        )}
                        beforeMount={(m) => this.handleSelectorFuncEditorWillMount(m)}
                        onMount={(e, m) => {
                          this.editorsRefs.selectorFunc = e;
                        }}
                        options={{
                          wordWrap: 'on',
                          readOnly: !this.state.isEditingRecordSelectorFunc,
                        }}
                      />
                    </Spin>
                  </div>
                  <div hidden={!this.state.isEditingRecordSelectorFunc}>
                    <Row>
                      <Col span={12}>
                        <h3>rawSql</h3>
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        {this.state.isEditingRecordSelectorFunc ? (
                          <>
                            <Button
                              type="primary"
                              style={{ margin: 10, minWidth: 150 }}
                              onClick={() => {
                                this.setRawSqlToRecordSelectorFuncEditor();
                              }}
                            >
                              Set
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Spin spinning={recordReducer.isUpdating}>
                      <Editor
                        height="30vh"
                        path="//rawSql"
                        language="pgsql"
                        value={this.getRawSqlValue(record)}
                        beforeMount={(m) => this.handleRawSqlEditorWillMount(m)}
                        onMount={(e, m) => {
                          this.editorsRefs.rawSql = e;
                        }}
                        options={{
                          wordWrap: 'on',
                          readOnly: !this.state.isEditingRecordSelectorFunc,
                        }}
                      />
                    </Spin>
                  </div>
                </div>

                <Row style={{ marginTop: 16 }}>
                  <Col span={12}>
                    <h2 style={{ marginTop: 0 }}>Workflow Definition</h2>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    {this.state.isEditingActivity ? (
                      <>
                        <Button
                          type="primary"
                          style={{ marginRight: 10, minWidth: 150 }}
                          onClick={() => {
                            const val = this.editorsRefs.activity?.getValue();
                            // ODN-2424 check for the valid JSON string
                            if (!val || IsJsonString(val)) {
                              this.setState({ isEditingActivity: false });
                              this.updateRecordProperty('Activity', val, (resp: any) => {
                                this.editorsRefs.activity
                                  ?.getModel()
                                  .setValue(
                                    JSON.stringify(
                                      getProperty(record, 'Activity'),
                                      undefined,
                                      '  ',
                                    ),
                                  );
                              });
                            } else {
                              alertMessage({
                                body: 'Activity should be a valid JSON',
                                type: 'error',
                              });
                            }
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          type="default"
                          onClick={() => {
                            this.setState({ isEditingActivity: false });
                            this.editorsRefs.activity
                              ?.getModel()
                              .setValue(
                                JSON.stringify(getProperty(record, 'Activity'), undefined, '  '),
                              );
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => this.setState({ isEditingActivity: true })}
                        disabled={
                          this.state.isEditingRecordSelectorFunc || this.state.isEditingActivity
                        }
                      >
                        Edit Activity
                      </Button>
                    )}
                  </Col>
                </Row>
                <div>
                  <Spin spinning={recordReducer.isUpdating}>
                    <Editor
                      height="60vh"
                      path="//Activity"
                      language="json"
                      value={JSON.stringify(getProperty(record, 'Activity'), undefined, '  ')}
                      beforeMount={(m) => this.handleActivityEditorWillMount(m)}
                      onMount={(e, m) => {
                        this.editorsRefs.activity = e;
                      }}
                      options={{
                        wordWrap: 'on',
                        readOnly: !this.state.isEditingActivity,
                      }}
                    />
                  </Spin>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={6}>
              <div className="record-detail-right-panel">
                <CardWithTabs title="Updates" defaultTabKey="Notes" tabList={[]} tabContents={{}} />
              </div>
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default withRouter(connect(mapState, mapDispatch)(WorkflowDetailView));
