import dayjs from 'dayjs';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getAllRelations } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import {
  getRecordByIdRequest,
  updateRecordByIdRequest,
} from '../../../../../../core/records/store/actions';
import { getRecordAssociationWithNestedEntitiesRequest } from '../../../../../../core/recordsAssociations/store/actions';
import { getSchemaByModuleAndEntityRequest } from '../../../../../../core/schemas/store/actions';

import { useRequest } from '../../../../hooks/useRequest';
import { AFPApprovalActions } from './useAFPApprovalReducer/actions';
import { UpdatableFPEProperties } from '../types';

type FPEApprovalParams = {
  ApprovedBy: string;
  ApplicationForPaymentId: string;
};

type FPERejectionParams = {
  RejectionReason: string;
  RejectionComment: string;
  RejectedBy: string;
  ApplicationForPaymentId: string;
};

type FPEClearStatusParams = {
  ApplicationForPaymentId: string;
};

export const useCreateAFPApprovalService = (actions: AFPApprovalActions) => {
  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);
  const getRecordById = useRequest(getRecordByIdRequest);
  const getRecordAssociations = useRequest(getRecordAssociationWithNestedEntitiesRequest);
  const updateRecordById = useRequest(updateRecordByIdRequest);

  const loadApplicationForPayment = async (afpId: string) => {
    try {
      actions.loadAFPRequest();
      const afpSchema = await getSchema({
        moduleName: 'ProjectModule',
        entityName: 'ApplicationForPayment',
      });

      const afp = await getRecordById({
        schema: afpSchema,
        recordId: afpId,
      });

      actions.loadAFPResponse(afp);
      return afp;
    } catch (err) {
      actions.loadAFPError(err);
    }
  };

  const loadFeaturePriceEntries = async (afpId: string) => {
    try {
      actions.loadAllFpesRequest();
      const afpSchema = await getSchema({
        moduleName: 'ProjectModule',
        entityName: 'ApplicationForPayment',
      });

      const { results = {} } = await getRecordAssociations({
        schema: afpSchema,
        recordId: afpId,
        entity: 'FeaturePriceEntry',
        nestedEntities: [],
        withLinks: true,
      });

      const allFpes = getAllRelations(results, 'FeaturePriceEntry') ?? [];
      actions.loadAllFpesResponse(allFpes);
    } catch (err) {
      actions.loadAllFpesError(err);
    }
  };

  const loadFeature = async (featureId: string) => {
    try {
      actions.loadFeatureRequest();
      const featureSchema = await getSchema({ moduleName: 'ProjectModule', entityName: 'Feature' });

      const feature = await getRecordById({
        schema: featureSchema,
        recordId: featureId,
      });
      actions.loadFeatureResponse(feature);
    } catch (err) {
      actions.loadFeatureError(err);
    }
  };

  const updateFpeProperties = async (
    fpes: DbRecordEntityTransform[],
    properties: UpdatableFPEProperties,
  ) => {
    const fpeSchema = await getSchema({
      moduleName: 'ProjectModule',
      entityName: 'FeaturePriceEntry',
    });

    await fpes.reduce((promise, fpe) => {
      return promise.then(() =>
        updateRecordById({
          schema: fpeSchema,
          recordId: fpe.id,
          createUpdate: {
            entity: fpe.entity,
            type: fpe.type,
            properties,
          },
        }),
      );
    }, Promise.resolve());

    await Promise.all([
      loadApplicationForPayment(properties.ApplicationForPaymentId),
      loadFeaturePriceEntries(properties.ApplicationForPaymentId),
    ]);
  };

  const approveFpes = (
    fpes: DbRecordEntityTransform[],
    { ApprovedBy, ApplicationForPaymentId }: FPEApprovalParams,
  ) =>
    updateFpeProperties(fpes, {
      IsApproved: 'true',
      ApprovedBy,
      ApprovedDate: dayjs().format('YYYY-MM-DD'),
      RejectionComment: '',
      RejectionReason: '',
      RejectedBy: '',
      RejectedDate: '',
      ApplicationForPaymentId,
    });

  const rejectFpes = (
    fpes: DbRecordEntityTransform[],
    { RejectionReason, RejectionComment, RejectedBy, ApplicationForPaymentId }: FPERejectionParams,
  ) =>
    updateFpeProperties(fpes, {
      IsApproved: 'false',
      RejectionReason,
      RejectionComment,
      RejectedBy,
      RejectedDate: dayjs().format('YYYY-MM-DD'),
      ApprovedBy: '',
      ApprovedDate: '',
      ApplicationForPaymentId,
    });

  const clearFpesStatus = (
    fpes: DbRecordEntityTransform[],
    { ApplicationForPaymentId }: FPEClearStatusParams,
  ) =>
    updateFpeProperties(fpes, {
      IsApproved: '',
      ApprovedBy: '',
      ApprovedDate: '',
      RejectionComment: '',
      RejectionReason: '',
      RejectedBy: '',
      RejectedDate: '',
      ApplicationForPaymentId,
    });

  return {
    ...actions,
    loadApplicationForPayment,
    loadFeaturePriceEntries,
    loadFeature,
    approveFpes,
    rejectFpes,
    clearFpesStatus,
  };
};
