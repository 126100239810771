import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../core/userInterface/store/actions';
import { connect } from 'react-redux';
import { Icon, Tooltip } from '@blueprintjs/core';
import { HourlyViewContext } from '..';
import '../styles.scss';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SET_WORK_ORDERS_TO_ASSIGN } from '../store/constants';

interface Props {
  workOrder: any;
  openDrawer: (params: IOpenRecordDrawer) => void;
  shownInModal?: boolean;
}

const HourlyViewUnassignedWorkOrder: React.FC<Props> = (props: Props) => {
  const { workOrder, shownInModal } = props;
  const { state, dispatch } = useContext(HourlyViewContext);

  const address = workOrder?.Address?.dbRecords[0] || [];
  const SA = workOrder?.ServiceAppointment?.dbRecords[0] || [];

  const addRemoveFromAssignedWOList = (WO: DbRecordEntityTransform) => {
    if (!shownInModal) {
      if (state.workOrdersToAssign.some((wo: DbRecordEntityTransform) => wo.id === WO.id)) {
        const newAssigningWorkOrderList = state.workOrdersToAssign.filter(
          (wo: any) => wo.id !== workOrder.id,
        );
        dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: newAssigningWorkOrderList });
      } else {
        const newAssigningWorkOrderList = state.workOrdersToAssign.concat(workOrder);
        dispatch({ type: SET_WORK_ORDERS_TO_ASSIGN, payload: newAssigningWorkOrderList });
      }
    }
  };

  const isWOInAssignedList = (workOrderId: string) => {
    return state.workOrdersToAssign.some((wo: any) => wo.id === workOrderId);
  };

  const constructAddressLine = (): string => {
    if (address) {
      return `${address.properties?.AddressLine1}, ${address.properties?.PostalCode}`;
    } else {
      return '';
    }
  };

  if (SA) {
    const hasTimeBlock = SA.properties?.TimeBlock;

    return (
      <Tooltip
        disabled={workOrder.sla?.status !== 'RED'}
        content={workOrder?.sla?.reason}
        fill
        hoverOpenDelay={1200}
        key={'hourlyViewWOMasterTooltip' + workOrder.id}
      >
        <Col
          key={'hourlyViewWOMasterCol' + workOrder.id}
          className={`hourlyViewWorkOrderBlock white unassigned ${
            isWOInAssignedList(workOrder.id) && !shownInModal ? 'selected' : ''
          }`}
          style={{ width: '100%', border: '1px solid #c4c4c4' }}
          onClick={() => addRemoveFromAssignedWOList(workOrder)}
        >
          <Row key={'hourlyViewWORow' + workOrder.id}>
            {/* Record Number + Star */}
            <Col span={16} key={'hourlyViewWOCol1' + workOrder.id}>
              <span
                key={'hourlyViewSpan1' + workOrder.id}
                style={{ fontSize: 10, fontWeight: 600 }}
              >
                {workOrder.recordNumber}
                {workOrder?.properties?.IsVIP && (
                  <Icon
                    icon="star"
                    color="orange"
                    size={11}
                    style={{ paddingLeft: 6, paddingBottom: 1 }}
                  />
                )}
              </span>
            </Col>

            {/* Time slot */}
            <Col span={8} style={{ textAlign: 'right' }}>
              {hasTimeBlock && (
                <span style={{ fontSize: 10, fontWeight: 600, marginLeft: 4 }}>
                  {SA.properties?.TimeBlock}
                </span>
              )}
            </Col>

            <Col span={24} style={{ marginTop: 0 }}>
              {/* Type */}
              <Row style={{ lineHeight: '12px', width: '100%' }}>
                <Col>
                  <span style={{ fontWeight: 600, fontSize: 8, marginRight: 5 }}>Type:</span>
                </Col>
                <Col>
                  <span style={{ fontSize: 8 }}>{workOrder?.type}</span>
                </Col>
              </Row>

              {/* Exchange */}
              <Row style={{ lineHeight: '12px', width: '100%' }}>
                <Col>
                  <span style={{ fontWeight: 600, fontSize: 8, marginRight: 5 }}>Exchange: </span>
                </Col>
                <Col>
                  <span style={{ fontSize: 8 }}>
                    {address?.properties?.ExchangeName || 'Unknown Exchange'}
                  </span>
                </Col>
              </Row>
            </Col>

            {/* Address */}
            <Row style={{ lineHeight: '12px', width: '100%' }}>
              <Col>
                <span style={{ fontWeight: 600, fontSize: 8, marginRight: 5 }}>Address: </span>
              </Col>
              <Col>
                <span style={{ fontSize: 8 }}>{constructAddressLine()}</span>
              </Col>
            </Row>
          </Row>
        </Col>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});
export default connect(mapState, mapDispatch)(HourlyViewUnassignedWorkOrder);
