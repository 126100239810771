import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { BooleanQuery } from '@d19n/temp-fe-d19n-models/dist/search/search.query.boolean.interfaces';
import { Geolocation } from 'ol';
import { Coordinate } from 'ol/coordinate';
import Map from 'ol/Map';
import {
  RESET_MAP_SEARCH_QUERY,
  RESET_WORK_ITEM_FORM,
  RUN_WFS_QUERY_ERROR,
  RUN_WFS_QUERY_REQUEST,
  RUN_WFS_QUERY_SUCCESS,
  SEARCH_BY_NOI_REF_ERROR,
  SEARCH_BY_NOI_REF_REQUEST,
  SEARCH_BY_NOI_REF_SUCCESS,
  SEARCH_BY_POSTCODE_ERROR,
  SEARCH_BY_POSTCODE_REQUEST,
  SEARCH_BY_POSTCODE_SUCCESS,
  SET_MAP_SEARCH_QUERY,
  SET_WORK_ITEM_QUICKVIEW,
  UPDATE_MAP_COORDINATES_STATE,
  UPDATE_MAP_STATE,
  UPDATE_WORK_ITEMS,
} from './constants';
import { Feature } from '../../../com.netomnia/modules/ProjectModule/Map/interfaces/feature.interface';
import { FEATURE_NAMES } from '../../../com.netomnia/modules/ProjectModule/Map/constants';

export type selectionFiltering = 'ALL' | 'L3' | 'L4' | 'Access' | 'Feed';
export type activeSidebarTab = 'LAYERS' | 'FEATURES' | 'WORK_ITEMS';

export interface MapReducer {
  map: Map | undefined;
  mapSidebarVisible: boolean;
  mapSidebarSection: activeSidebarTab;
  mapSidebarFilteringActive: boolean;
  isFilter: boolean;
  selectedFeature: any;
  isRequesting: boolean;
  mapCenter: Coordinate | undefined;
  coordinates: number[][];
  query: string | undefined;
  drawEnabled: boolean;
  addEnabled: boolean;
  addPointFlow: boolean;
  addLineFlow: boolean;
  featureSelectModal: boolean;
  features: Feature[];
  feature: Feature | undefined;
  featureId: number | undefined;
  buildStatusId: number;
  featureName?: FEATURE_NAMES;
  shouldUpdate: boolean;
  showCreateModal: boolean;
  showUpdateModal: boolean;
  isLoadingView: boolean;
  isCreatingRFC: boolean;
  isRFCModalVisible: boolean;
  recordId: string | undefined;
  entityName: 'Feature' | 'Project' | undefined;
  isLocating: boolean;
  geoEnabled: boolean;
  geolocation: Geolocation | undefined;
  record: DbRecordEntityTransform | undefined;
  queryLayer: string | undefined;
  addLineEnabled: boolean;
  zoomLevel: number | undefined;
  searchTerms: string;
  infoPopupVisible: boolean;
  isAddingHazard: boolean;
  isSearchingMap: boolean;
  addPointConfirmVisible: boolean;
  queries: {
    must: [];
    must_not: [];
    should: [];
    filter: [];
  };
  createLinked?: {
    fromType: FEATURE_NAMES | undefined;
    toType: FEATURE_NAMES | undefined;
    fromId: number | undefined;
  };
  inputFieldFeatureID: string;
  inputFieldPostcode: string;
  inputFieldNOIRef: string;
  showOnHoldItems: boolean;
  greyscaleMap: boolean;
  mapSelectionFiltering: selectionFiltering;
  bulkUpdateEnabled: boolean;
  bulkUpdateDrawerVisible: boolean;
  creatingRFCFromIds: string[];
  isRFCSelectFeatureTypeModalVisible: boolean;
  workItems: {
    quickViewRecord: DbRecordEntityTransform | undefined;
    quickViewVisible: boolean;
    workLists: DbRecordEntityTransform[]; //workItemsList
    selectedWorkList: DbRecordEntityTransform | undefined; // selectedWorkItemsRecord
    selectedProject: DbRecordEntityTransform | undefined;
    workItems: DbRecordEntityTransform[]; //featureList
    workItemsForm: any;
    creatingBlockageFromId: string | undefined;
  };
}

export const initialState: MapReducer = {
  map: undefined,
  mapSidebarVisible: false,
  mapSidebarSection: 'LAYERS',
  mapSidebarFilteringActive: false,
  isFilter: false,
  selectedFeature: undefined,
  isRequesting: false,
  mapCenter: undefined,
  coordinates: [],
  query: undefined,
  features: [],
  feature: undefined,
  drawEnabled: false,
  addEnabled: false,
  addPointFlow: false,
  addLineFlow: false,
  featureSelectModal: false,
  shouldUpdate: true,
  featureId: undefined,
  featureName: undefined,
  buildStatusId: 1,
  showCreateModal: false,
  isAddingHazard: false,
  showUpdateModal: false,
  isCreatingRFC: false,
  isSearchingMap: false,
  isRFCModalVisible: false,
  creatingRFCFromIds: [],
  isLoadingView: false,
  recordId: undefined,
  entityName: undefined,
  isLocating: false,
  geoEnabled: false,
  geolocation: undefined,
  record: undefined,
  queryLayer: undefined,
  addLineEnabled: false,
  zoomLevel: undefined,
  searchTerms: '',
  infoPopupVisible: false,
  addPointConfirmVisible: false,
  queries: {
    must: [],
    must_not: [],
    should: [],
    filter: [],
  },
  createLinked: {
    fromType: undefined,
    toType: undefined,
    fromId: undefined,
  },
  inputFieldFeatureID: '',
  inputFieldPostcode: '',
  inputFieldNOIRef: '',
  showOnHoldItems: false,
  greyscaleMap: true,
  mapSelectionFiltering: 'ALL',
  bulkUpdateEnabled: false,
  bulkUpdateDrawerVisible: false,
  isRFCSelectFeatureTypeModalVisible: false,
  workItems: {
    quickViewRecord: undefined,
    quickViewVisible: false,
    workLists: [],
    selectedWorkList: undefined,
    selectedProject: undefined,
    workItems: [],
    workItemsForm: undefined,
    creatingBlockageFromId: undefined,
  },
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_MAP_STATE: {
      return {
        ...state,
        ...action.params,
      };
    }

    case UPDATE_MAP_COORDINATES_STATE: {
      return {
        ...state,
        coordinates: [...state.coordinates, ...action.params.coordinates],
      };
    }

    case RESET_MAP_SEARCH_QUERY: {
      return {
        ...state,
        queries: initialState.queries,
      };
    }

    case SET_MAP_SEARCH_QUERY:
      let ids: any[] = [];

      ids = action.params.featureIds.split(',');
      ids = ids
        .map((id: string) => id.trim())
        .filter((id: string) => !['', null, undefined].includes(id));

      const newQueries: BooleanQuery = {
        must: [
          {
            query_string: {
              fields: ['type'],
              query: state.queryLayer,
              default_operator: 'AND',
              lenient: true,
            },
          },
          {
            terms: {
              'properties.ExternalRef': ids,
            },
          },
        ],
        must_not: [],
        should: [],
        filter: [],
      };
      return {
        ...state,
        searchTerms: ids.join(),
        queries: newQueries,
      };

    // Work items

    case SET_WORK_ITEM_QUICKVIEW:
      return {
        ...state,
        workItems: {
          ...state.workItems,
          quickViewRecord: action.params.record,
          quickViewVisible: action.params.visible,
        },
      };

    case UPDATE_WORK_ITEMS: {
      return {
        ...state,
        workItems: {
          ...state.workItems,
          ...action.params,
        },
      };
    }

    case RESET_WORK_ITEM_FORM:
      if (state.workItems.workItemsForm) {
        state.workItems.workItemsForm.resetFields();
      }

      return {
        ...state,
        workItems: {
          ...state.workItems,
          quickViewRecord: undefined,
          quickViewVisible: false,
          workLists: [],
          selectedWorkList: undefined,
          selectedProject: undefined,
          workItems: [],
          creatingBlockageFromId: undefined,
        },
      };

    // WFS REQUESTS
    case RUN_WFS_QUERY_REQUEST:
      return {
        ...state,
        isSearchingMap: true,
      };
    case RUN_WFS_QUERY_SUCCESS:
      return {
        ...state,
        isSearchingMap: false,
      };
    case RUN_WFS_QUERY_ERROR:
      return {
        ...state,
        isSearchingMap: false,
      };

    // POSTCODE REQUESTS
    case SEARCH_BY_POSTCODE_REQUEST:
      return {
        ...state,
        isSearchingMap: true,
      };
    case SEARCH_BY_POSTCODE_SUCCESS:
      return {
        ...state,
        isSearchingMap: false,
      };
    case SEARCH_BY_POSTCODE_ERROR:
      return {
        ...state,
        isSearchingMap: false,
      };

    // NOI REF REQUESTS
    case SEARCH_BY_NOI_REF_REQUEST:
      return {
        ...state,
        isSearchingMap: true,
      };
    case SEARCH_BY_NOI_REF_SUCCESS:
      return {
        ...state,
        isSearchingMap: false,
      };
    case SEARCH_BY_NOI_REF_ERROR:
      return {
        ...state,
        isSearchingMap: false,
      };

    default:
      return state;
  }
}

export default reducer;
