import React from 'react';

interface Props {
  unread: boolean;
}

const CommunicationsBadge: React.FC<Props> = (props: Props) => {
  const {} = props;
  return (
    <div
      style={{
        width: 12,
        height: '100%',
        display: 'flex',
        alignItems: 'center', // Center vertically
      }}
    >
      <div
        style={{
          height: 7,
          width: 7,
          backgroundColor: props.unread ? 'green' : 'white',
          borderRadius: '50%',
        }}
      />
    </div>
  );
};
export default CommunicationsBadge;
