import { PlusCircleOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getProperty,
  getAllRelations,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Checkbox, List, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { httpPost } from '../../../../../shared/http/requests';
import { canUserCreateRecord } from '../../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../../shared/utilities/schemaHelpers';

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER, ORDER_ITEM } = SchemaModuleEntityTypeEnums;

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  hidden?: string[];
  getAssociations: any;
  alertMessage: any;
  userReducer: any;
}

class SplitOrder extends React.Component<Props> {
  state = {
    visible: false,
    isLoading: false,
    selected: [] as { entity?: string; recordId: string }[],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.getOrderItems();
  };

  addRemoveItem = (item: DbRecordEntityTransform) => {
    if (this.state.selected.find((elem) => elem.recordId === item.id)) {
      // remove the item
      this.setState({
        selected: this.state.selected.filter((elem) => elem.recordId !== item.id),
      });
    } else {
      this.setState((prevState: any) => ({
        selected: [
          ...prevState.selected,
          {
            entity: item.entity,
            recordId: item.id,
          },
        ],
      }));
    }
  };

  handleOk = async (e: any) => {
    const { schemaReducer, record, alertMessage } = this.props;
    this.setState({
      isLoading: true,
    });

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (record && schema) {
      const body = this.state.selected.map((elem) => ({
        entity: elem.entity,
        recordId: elem.recordId,
      }));

      await httpPost(`${ORDER_MODULE}/v1.0/orders/${record.id}/split`, body)
        .then((res) => {
          this.getRecordAssociations();
          alertMessage({ body: 'order successfully split', type: 'success' });
        })
        .catch((err) => {
          const error = err.response ? err.response.data : undefined;
          alertMessage({
            body: (error && error.message) || 'error generating work order',
            type: 'error',
          });
        });

      this.setState({
        visible: false,
        isLoading: false,
      });
    }
  };

  handleCancel = (e: any) => {
    this.setState({
      visible: false,
      isLoading: false,
    });
  };

  private getOrderItems() {
    const { getAssociations, record, schemaReducer } = this.props;

    const orderSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      ORDER_MODULE,
      ORDER,
    );

    if (record && orderSchema) {
      getAssociations({
        recordId: record.id,
        key: ORDER_ITEM,
        schema: orderSchema,
        entities: [ORDER_ITEM],
      });
    }
    return;
  }

  private getRecordAssociations() {
    const { getAssociations, record, schemaReducer } = this.props;

    const orderSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      ORDER_MODULE,
      ORDER,
    );

    if (record && orderSchema) {
      getAssociations({
        recordId: record.id,
        key: 'SplitOrder',
        schema: orderSchema,
        entities: ['SplitOrder'],
      });
    }
    return;
  }

  render() {
    const { record, recordAssociationReducer, schemaReducer, userReducer } = this.props;
    const associationKey = `${record?.id}_${ORDER_ITEM}`;
    const associationObj: any = recordAssociationReducer.shortList[associationKey];
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    return (
      <div>
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          onClick={this.showModal}
          disabled={schema ? !canUserCreateRecord(userReducer, schema) : false}
        >
          Split Order
        </Button>
        <Modal
          title="Split Order"
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          confirmLoading={this.state.isLoading}
        >
          <List
            size="small"
            header={<div>Items</div>}
            bordered
            dataSource={getAllRelations(associationObj, ORDER_ITEM) ?? []}
            renderItem={(item: DbRecordEntityTransform) => (
              <List.Item
                actions={[<Checkbox onChange={() => this.addRemoveItem(item)}>Add</Checkbox>]}
              >
                <List.Item.Meta title={item.title} description={getProperty(item, 'Description')} />
                <div>{getProperty(item, 'TotalPrice')}</div>
              </List.Item>
            )}
          />
        </Modal>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SplitOrder);
