import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../core/navigation/ProtectedRoute';
import MergeRecords from './index';

export const MergeModuleRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/merge/:moduleName/:entityName"
        moduleName={'OVERRIDE'}
        component={<MergeRecords />}
      />
    </Switch>
  );
};
