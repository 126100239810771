import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Empty, Row, Spin } from 'antd';
import { FC, useContext } from 'react';
import { DataSetWorkContext } from '../..';
import RecordList from '../../../../../../../core/records/components/RecordList';
import { SET_SELECTED_RECORD } from '../../store/constants';
interface Props {
  statusFilter: string;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { ADDRESS } = SchemaModuleEntityTypeEnums;

const DataSetSearchResults: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(DataSetWorkContext);

  const isRecordSelected = (record: DbRecordEntityTransform) => {
    return state.selectedRecord?.id === record.id;
  };

  const addOrRemoveDataSetFromSelection = (record: DbRecordEntityTransform) => {
    if (isRecordSelected(record)) {
      dispatch({ type: SET_SELECTED_RECORD, payload: undefined });
    } else {
      dispatch({ type: SET_SELECTED_RECORD, payload: record });
    }
  };

  let filteredResults = Object.assign(state.associatedRecords!);

  // Filter dataset records with quick search text
  if (state.dataSetSearchTerm?.length! > 0) {
    filteredResults = filteredResults.filter(
      (rec: DbRecordEntityTransform) =>
        rec.title!.toLowerCase().indexOf(state.dataSetSearchTerm?.toLowerCase()) > -1,
    );
  }

  // Filter dataset records with status group
  filteredResults = filteredResults.filter(
    (rec: DbRecordEntityTransform) =>
      (state.activeStatusTab === 'TO_DO' && !getProperty(rec, 'DS_Status')) ||
      getProperty(rec, 'DS_Status') === state.activeStatusTab,
  );

  // If dataset records are addresses, sort them by PostalCode
  if (
    filteredResults.length > 0 &&
    filteredResults?.every(
      (rec: DbRecordEntityTransform) => rec.entity === `${CRM_MODULE}:${ADDRESS}`,
    )
  ) {
    filteredResults = filteredResults.sort((a: any, b: any) =>
      getProperty(a, 'PostalCode') > getProperty(b, 'PostalCode')
        ? 1
        : getProperty(b, 'PostalCode') > getProperty(a, 'PostalCode')
        ? -1
        : 0,
    );
  }

  return (
    <>
      {state.isSearchingDataSetRecords ? (
        <Row style={{ height: '60vh', paddingTop: '22vh' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin size="large" style={{ marginBottom: 10 }} />
            <br />
            <span>Searching ...</span>
          </Col>
        </Row>
      ) : (
        <>
          <Row
            style={{
              height: '49vh',
              overflowY: 'scroll',
              opacity: state.isSearchingDataSetRecords ? 0.2 : 1,
            }}
          >
            <Col span={24}>
              {state.associatedRecords?.length > 0 ? (
                <div style={{ paddingLeft: 0, paddingRight: 10 }}>
                  <RecordList
                    selectedRecords={state.selectedRecord ? [state.selectedRecord] : []}
                    disabledRecords={[]}
                    allRecords={filteredResults}
                    onRecordSelect={addOrRemoveDataSetFromSelection}
                    highlightSelectedRecord
                    showRecordStageTag={state.dataSetRecord?.type === 'LEAD'}
                  />
                </div>
              ) : (
                <Empty style={{ marginTop: '20vh' }} description="No associated records found" />
              )}
            </Col>
          </Row>

          {/* Search Results */}
          <Row
            style={{
              fontSize: '0.8em',
              padding: 3,
              marginTop: 5,
              opacity: state.associatedRecords?.length > 0 ? 1 : 0,
            }}
          >
            <Col span={12} style={{ textAlign: 'left', color: '#888888' }}>
              <span>Total Records: </span>
              <span>{state.associatedRecords?.length}</span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DataSetSearchResults;
