import React, { FC, useContext, useEffect, useState } from 'react';
import { CaseManagementSchedulingContext } from '../index';
import { Button, Dialog, DialogBody, DialogFooter, MenuItem } from '@blueprintjs/core';
import { CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG } from '../store/constants';
import { TIME_FRAMES, TScheduleUser } from '../types';
import { Col, Row } from 'antd';
import { getInitialsFromName } from '../../../../../../../shared/helpers/UIHelpers';
import { ItemRenderer, Select } from '@blueprintjs/select';
import dayjs from 'dayjs';

interface Props {}

type TShift = {
  type: string | undefined;
  startTime: string;
  endTime: string;
  focus: string | undefined;
};

const INITIAL_SHIFT: TShift[] = [
  {
    type: '',
    startTime: '00:00',
    endTime: '00:00',
    focus: '',
  },
];

const SchedulingUserDialog: FC = (props: Props) => {
  const { state, dispatch } = useContext(CaseManagementSchedulingContext);

  const user: TScheduleUser | undefined = state.selectedSchedulingUser;
  const [shifts, setShifts] = useState<TShift[]>(INITIAL_SHIFT);

  const toggleDialog = () => {
    dispatch({ type: CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG, payload: null });
  };

  useEffect(() => {
    if (state.isSchedulingUserDialogOpen && state.selectedSchedulingUser) {
      const user = state.selectedSchedulingUser;

      if (user.schedules && user.schedules.length > 0) {
        const shifts = user.schedules.map((schedule: any) => {
          return {
            type: schedule.type,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            focus: schedule.focus,
          };
        });
        setShifts(shifts);
      } else {
        setShifts(INITIAL_SHIFT);
      }
    }
  }, [state.isSchedulingUserDialogOpen]);

  const getFirstStartDate = () => {
    return shifts[0].startTime;
  };

  const getLastEndDate = () => {
    return shifts[shifts.length - 1].endTime;
  };

  let TYPES: any[] = ['TR', 'SM', 'T', 'RF', 'B', 'C']
    .sort((a: string, b: string) => a.localeCompare(b))
    .map((type: string, index: number) => ({
      id: type,
      name: type,
      key: type,
      disabled: false,
    }));

  let TIME: any[] = TIME_FRAMES.map((time: string) => ({
    id: time,
    name: time,
    key: time,
    disabled: false,
  }));

  const isTimeSlotDisabled = (time: string) => {
    // using dayjs, find a shift where the time is between startTime and endTime
    // if found, return true, else false
    return !!shifts.find((shift) => {
      const startTime = dayjs(shift.startTime, 'HH:mm');
      const endTime = dayjs(shift.endTime, 'HH:mm');
      const currentTime = dayjs(time, 'HH:mm');
      return currentTime.isBetween(startTime, endTime) || currentTime.isSame(startTime);
    });
  };

  const renderSelectItems: ItemRenderer<any> = (item, { handleClick, handleFocus, modifiers }) => {
    return !isTimeSlotDisabled(item.name) ? (
      <MenuItem
        active={modifiers.active}
        // disabled={isTimeSlotDisabled(item.name)}
        key={item.key}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="menuitem"
        text={item.name}
      />
    ) : (
      <></>
    );
  };

  return (
    <Dialog
      isOpen={state.isSchedulingUserDialogOpen}
      onClose={toggleDialog}
      canEscapeKeyClose={true}
      style={{ width: 650, minHeight: 600, background: 'white' }}
      canOutsideClickClose={true}
      isCloseButtonShown={true}
      usePortal={true}
    >
      <DialogBody>
        {/* Header */}
        <Row>
          <Col span={20}>
            <Row>
              <Col>
                <div
                  style={{
                    background: '#E6E7E8',
                    padding: '6px 5px',
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    textAlign: 'center',
                  }}
                >
                  {getInitialsFromName(`${user?.firstName} ${user?.lastName}`)}
                </div>
              </Col>
              <Col>
                <h2 style={{ margin: 0, marginLeft: 10 }}>
                  {user?.firstName} {user?.lastName}
                </h2>
              </Col>
            </Row>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button minimal icon="share" intent="primary" />
            <Button minimal icon="cross" onClick={() => toggleDialog()} />
          </Col>
        </Row>

        {/*  User Info */}
        <Row style={{ marginTop: 20 }}>
          <Col span={8}>
            <span style={{ color: '#666666' }}>Scheduled</span>
          </Col>
          <Col span={16}>
            <span style={{ color: '#666666' }}>Specialties</span>
          </Col>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <Col span={8}>
            <span>
              {getFirstStartDate()} - {getLastEndDate()}
            </span>
          </Col>
          <Col span={16}>
            <span>Billing, Connection Issues</span>
          </Col>
        </Row>

        {/* Shifts */}
        <Row style={{ marginTop: 20 }}>
          <Col span={8}>
            <span style={{ fontWeight: 600 }}>Type</span>
          </Col>
          <Col span={4}>
            <span style={{ fontWeight: 600 }}>Start</span>
          </Col>
          <Col span={4}>
            <span style={{ fontWeight: 600 }}>End</span>
          </Col>
          <Col span={7}>
            <span style={{ fontWeight: 600 }}>Focus</span>
          </Col>
          <Col span={1}>
            <span style={{ fontWeight: 600 }}></span>
          </Col>
        </Row>

        {/* List all shifts */}
        {shifts.map((shift: TShift, i: number) => {
          return (
            <Row style={{ marginTop: 10 }} key={`Row1${i}`}>
              <Col span={8} key={`Col1${i}`} style={{ paddingRight: 5 }}>
                <Select
                  activeItem={TYPES.find((type) => type.name === shift.type)}
                  key={i}
                  items={TYPES}
                  itemRenderer={renderSelectItems}
                  onItemSelect={() => {}}
                  filterable={false}
                >
                  <Button
                    alignText="left"
                    fill
                    text={shift.type || 'Select Type'}
                    rightIcon="caret-down"
                  />
                </Select>
              </Col>
              <Col span={4} key={`Col2${i}`} style={{ paddingRight: 5 }}>
                <Select
                  activeItem={TIME.find((t) => t.name === shift.startTime)}
                  key={i}
                  items={TIME}
                  itemRenderer={renderSelectItems}
                  onItemSelect={() => {}}
                  filterable={false}
                >
                  <Button alignText="left" fill text={shift.startTime} rightIcon="caret-down" />
                </Select>
              </Col>
              <Col span={4} key={`Col3${i}`} style={{ paddingRight: 5 }}>
                <Select
                  activeItem={TIME.find((t) => t.name === shift.endTime)}
                  key={i}
                  items={TIME}
                  itemRenderer={renderSelectItems}
                  onItemSelect={() => {}}
                  filterable={false}
                >
                  <Button alignText="left" fill text={shift.endTime} rightIcon="caret-down" />
                </Select>
              </Col>
              <Col span={7} key={`Col4${i}`} style={{ paddingRight: 5 }}>
                <Select items={[]} itemRenderer={() => <></>} onItemSelect={() => {}}>
                  <Button
                    alignText="left"
                    fill
                    text={shift.focus || 'Select Focus'}
                    rightIcon="caret-down"
                    disabled={shift.focus === 'N/A'}
                  />
                </Select>
              </Col>
              <Col span={1} key={`Col5${i}`}>
                {i > 0 && (
                  <Button
                    icon="remove"
                    minimal
                    onClick={() => {
                      const newShifts = [...shifts];
                      newShifts.splice(i, 1);
                      setShifts(newShifts);
                    }}
                  />
                )}
              </Col>
            </Row>
          );
        })}

        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <Button
              text="Add"
              icon="plus"
              fill
              intent="primary"
              minimal
              style={{ background: '#F3F5FD', borderRadius: 4 }}
              onClick={() => {
                setShifts([
                  ...shifts,
                  {
                    type: undefined,
                    startTime: '00:00',
                    endTime: '10:00',
                    focus: undefined,
                  },
                ]);
              }}
            />
          </Col>
        </Row>
      </DialogBody>
      <DialogFooter
        actions={[
          <Button text="Close" key="close" onClick={() => toggleDialog()} />,
          <Button text="Save Schedule" intent="primary" key="save" />,
        ]}
      />
    </Dialog>
  );
};

export default SchedulingUserDialog;
