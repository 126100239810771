import { Tab, Tabs } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { FC, useState } from 'react';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import SchemaActionPageHeader from '../../../../../../shared/components/SchemaActions/SchemaActionPageHeader';

import { RecordActivities } from './RecordActivities';
import { RecordDetails } from './RecordDetails';
import { RecordFiles } from './RecordFiles';

interface Props {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
}

export const FeatureRecordDetails: FC<Props> = (props: Props) => {
  const { record, schema } = props;

  const [flowVisible, setFlowVisible] = useState<boolean>(false);

  // PIA Order - Utility Functions
  const toggleFlowVisibility = () => {
    setFlowVisible(!flowVisible);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {schema && record && (
            <SchemaActionPageHeader
              moduleName={schema.moduleName}
              entityName={schema.entityName}
              record={record}
              launcherType="SECTION"
              sectionTitle="Quick Actions"
            />
          )}
        </Col>
        <Col span={24}>
          <Tabs>
            {/* Details */}
            <Tab
              id="DETAILS"
              title="Details"
              panel={
                <RecordDetails
                  record={record}
                  schema={schema}
                  setFlowVisible={(value) => setFlowVisible(value)}
                />
              }
            />
            <Tab
              id="ACTIVITY"
              title="Activity"
              panel={<RecordActivities record={record} schema={schema} />}
            />
            <Tab
              id="FILES"
              title="Files"
              panel={
                <RecordFiles
                  record={record}
                  flowVisible={flowVisible}
                  toggleFlowVisibility={toggleFlowVisibility}
                />
              }
            />
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
