import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Calendar, Col, Layout, Menu, Row, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isMobile } from 'react-device-detect';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ISchemaReducer } from '../../../../core/schemas/store/reducer';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../core/schemas/store/actions';
import {
  Button,
  ContextMenu,
  Icon,
  MenuDivider,
  MenuItem,
  Tag,
  Tooltip,
  Alert,
} from '@blueprintjs/core';
import './styles.scss';
import { initializeRecordForm } from '../../../../core/records/components/Forms/store/actions';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '../../../../core/records/components/Forms/CoreForm';
import { httpGet } from '../../../../shared/http/requests';
import { IDeleteRecordById, deleteRecordByIdRequest } from '../../../../core/records/store/actions';
import { set } from 'lodash';

interface Props {
  record: DbRecordEntityTransform; // User record
  schemaReducer: ISchemaReducer;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  initializeForm: (params: any) => void;
  deleteRecord: (payload: IDeleteRecordById, cb: any) => void;
  userReducer: any;
}

const uuid = uuidv4();
const TIME_SLOT = 'TimeSlot';
const IDENTITY_MODULE = 'IdentityModule';
const USER = 'User';

const visibleFields = [
  'PMQuantity',
  'AMQuantity',
  'StartDate',
  'EndDate',
  'MgmtComment',
  'StartDate',
  'EndDate',
];

const UserCalendar: FC<Props> = (props: Props) => {
  const { record, schemaReducer, getSchema, initializeForm, deleteRecord, userReducer } = props;
  const [timeSlotSchema, setTimeSlotSchema] = useState<SchemaEntity | undefined>(undefined);
  const [userSchema, setUserSchema] = useState<SchemaEntity | undefined>(undefined);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());

  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<DbRecordEntityTransform[]>([]);
  const [isViewingComment, setIsViewingComment] = useState<boolean>(false);
  const [confirmDeleteRecordDialogVisible, setConfirmDeleteRecordDialogVisible] =
    useState<boolean>(false);
  const [isDeletingRecord, setIsDeletingRecord] = useState<boolean>(false);
  const [recordToDelete, setRecordToDelete] = useState<DbRecordEntityTransform | null>(null);

  // Antd Calendar component adds a title attribute to each cell, which causes
  // tooltip to appear on hover. We hold this action off for 500ms until the
  // calendar is fully rendered on each month update.
  useEffect(() => {
    setTimeout(() => {
      removeTitleAttributes();
    }, 500);
  }, [currentMonth]);
  const removeTitleAttributes = () => {
    const cells = document.querySelectorAll('.ant-picker-cell');
    cells.forEach((cell) => {
      cell.removeAttribute('title');
    });
  };

  // On Component mount, fetch TimeSlot schema
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      IDENTITY_MODULE,
      TIME_SLOT,
    );
    if (shortlistSchema) {
      setTimeSlotSchema(shortlistSchema);
    } else {
      getSchema({ moduleName: IDENTITY_MODULE, entityName: TIME_SLOT }, (schema: SchemaEntity) => {
        setTimeSlotSchema(schema);
      });
    }
  }, []);

  // On Component mount, fetch User schema
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      IDENTITY_MODULE,
      USER,
    );
    if (shortlistSchema) {
      setUserSchema(shortlistSchema);
    } else {
      getSchema({ moduleName: IDENTITY_MODULE, entityName: USER }, (schema: SchemaEntity) => {
        setUserSchema(schema);
      });
    }
  }, []);

  // When date is updated, fetch TimeSlots
  useEffect(() => {
    fetchTimeSlots();
  }, [currentMonth]);

  const initializeCreateTimeSlotForm = (date: string) => {
    if (timeSlotSchema && record) {
      initializeForm({
        hideRecordTypeField: true,
        formUUID: uuid,
        showFormModal: true,
        showInitializing: false,
        hideRecordFormFields: true,
        isBatchCreateReq: false,
        isCreateReq: true,
        schema: timeSlotSchema,
        title: `Create Time Slot`,
        visibleFieldOverride: visibleFields,
        modified: [
          {
            title: 'Odin Managed',
            properties: {
              StartDate: date,
              CalendarId: record.id,
            },
            associations: [
              {
                entity: `${IDENTITY_MODULE}:${USER}`,
                recordId: record?.id,
              },
            ],
            schemaId: timeSlotSchema?.id,
          },
        ],
        sections: [
          {
            name: timeSlotSchema?.name,
            schema: timeSlotSchema,
          },
        ],
      });
    }
  };

  const initializeUpdateTimeSlotForm = (timeSlotRecord: DbRecordEntityTransform) => {
    if (timeSlotSchema && timeSlotRecord) {
      initializeForm({
        hideRecordTypeField: true,
        hideRecordFormFields: true,
        formUUID: uuid,
        showFormModal: true,
        showInitializing: false,
        isBatchCreateReq: false,
        isCreateReq: false,
        isUpdateReq: true,
        selected: timeSlotRecord,
        visibleFieldOverride: visibleFields,
        schema: timeSlotSchema,
        sections: [
          {
            name: timeSlotSchema?.name,
            schema: timeSlotSchema,
          },
        ],
      });
    }
  };

  const deleteTimeSlot = () => {
    if (timeSlotSchema && recordToDelete) {
      setIsDeletingRecord(true);
      deleteRecord(
        {
          schema: timeSlotSchema,
          recordId: recordToDelete.id,
        },
        () => {
          setRecordToDelete(null);
          setIsDeletingRecord(false);
          setConfirmDeleteRecordDialogVisible(false);
          fetchTimeSlots();
        },
      );
    }
  };

  const fetchTimeSlots = () => {
    if (userReducer && record) {
      const startDate = dayjs(currentMonth).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(currentMonth).endOf('month').format('YYYY-MM-DD');

      setIsLoadingTimeSlots(true);
      httpGet(
        `${IDENTITY_MODULE}/v1.0/Schedules/time-slots/${record.id}?start=${startDate}&end=${endDate}`,
      )
        .then((res: any) => {
          setIsLoadingTimeSlots(false);
          // console.log('debug: time slots fetched', res.data.data);
          setTimeSlots(res?.data?.data || []);
        })
        .catch((err: any) => {
          setIsLoadingTimeSlots(false);
          console.log('%cdebug: time slots fetch error', 'color:limegreen', err);
        });
    }
  };

  const addMonth = () => {
    setCurrentMonth(dayjs(currentMonth).add(1, 'month'));
  };

  const subtractMonth = () => {
    setCurrentMonth(dayjs(currentMonth).subtract(1, 'month'));
  };

  const renderCalendarHeader = () => {
    return (
      <Row align="middle">
        <Col span={12}>
          <span className="userCalendarBigDate">{dayjs(currentMonth).format('MMMM YYYY')}</span>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button
            intent="primary"
            style={{ marginRight: 5, width: 160 }}
            onClick={subtractMonth}
            disabled={isLoadingTimeSlots}
          >
            <Icon icon="caret-left" />
            <span>Previous Month</span>
          </Button>
          <Button
            intent="primary"
            icon="locate"
            disabled={dayjs().format('YYYY-MM') === currentMonth.format('YYYY-MM')}
            style={{ marginRight: 5 }}
            onClick={() => setCurrentMonth(dayjs())}
          />
          <Button
            intent="primary"
            style={{ width: 160 }}
            onClick={addMonth}
            disabled={isLoadingTimeSlots}
          >
            <span>Next Month</span>
            <Icon icon="caret-right" />
          </Button>
        </Col>
      </Row>
    );
  };

  const isDayWithinCurrentMonth = (date: Dayjs) => {
    return (
      dayjs(date).isAfter(dayjs(currentMonth).startOf('month')) &&
      dayjs(date).isBefore(dayjs(currentMonth).endOf('month'))
    );
  };

  const getColorForTimeSlotCount = (count: number) => {
    if (count < 1) {
      return '#8f8f8f'; // grey
    } else if (count >= 1) {
      return '#215db0'; // blue;
    } else {
      return '#e6e7e9'; // grey
    }
  };

  const renderDay = (date: Dayjs) => {
    const convertedDate = dayjs(date).format('YYYY-MM-DD');
    const timeSlot = timeSlots.find((slot: any) => slot.Date === convertedDate);
    const timeSlotRecord = timeSlot?.TimeSlot || undefined;

    if (timeSlot && isDayWithinCurrentMonth(date)) {
      return (
        <Tooltip
          content="Double click to create new time slot, or use right click to access context menu."
          hoverOpenDelay={1500}
          disabled={isViewingComment}
          fill
        >
          <ContextMenu
            content={
              <Menu style={{ padding: 5 }}>
                <MenuDivider title={dayjs(date).format('DD MMMM YYYY')} />
                <MenuDivider />

                {/* Add Time Slot */}
                {!timeSlotRecord && (
                  <MenuItem
                    icon="plus"
                    text="Create Time Slot"
                    onClick={() => initializeCreateTimeSlotForm(dayjs(date).format('YYYY-MM-DD'))}
                  />
                )}

                {/* Edit Time Slot */}
                {timeSlotRecord && (
                  <MenuItem
                    icon="annotation"
                    text="Edit Time Slot"
                    onClick={() => initializeUpdateTimeSlotForm(timeSlotRecord)}
                  />
                )}

                {/* Delete Time Slot */}
                {timeSlotRecord && (
                  <MenuItem
                    intent="danger"
                    icon="trash"
                    text="Delete Time Slot"
                    onClick={() => {
                      setRecordToDelete(timeSlotRecord);
                      setConfirmDeleteRecordDialogVisible(true);
                    }}
                  />
                )}
              </Menu>
            }
          >
            <Row
              style={{ paddingTop: 10 }}
              align="middle"
              justify="center"
              onDoubleClick={() => {
                if (timeSlotRecord) {
                  initializeUpdateTimeSlotForm(timeSlotRecord);
                } else {
                  initializeCreateTimeSlotForm(dayjs(date).format('YYYY-MM-DD'));
                }
              }}
            >
              <Col span={20}>
                <Tag
                  intent="primary"
                  minimal
                  fill
                  style={{ padding: 4, background: '#E6E7E9' }}
                  rightIcon={
                    <span
                      style={{
                        fontWeight: 700,
                        background: getColorForTimeSlotCount(timeSlot.AMCount),
                        color: 'white',
                        padding: '1px 4px',
                        borderRadius: 3,
                      }}
                    >
                      {timeSlot.AMCount || 0}
                    </span>
                  }
                >
                  <span style={{ fontWeight: 600, color: 'black' }}>AM</span>
                </Tag>
              </Col>
              <Col span={20} style={{ marginTop: 3 }}>
                <Tag
                  minimal
                  intent="primary"
                  fill
                  style={{
                    padding: 4,
                    backgroundColor: '#E6E7E9',
                  }}
                  rightIcon={
                    <span
                      style={{
                        fontWeight: 700,
                        background: getColorForTimeSlotCount(timeSlot.PMCount),
                        color: 'white',
                        padding: '1px 4px',
                        borderRadius: 3,
                      }}
                    >
                      {timeSlot.PMCount || 0}
                    </span>
                  }
                >
                  <span style={{ fontWeight: 600, color: 'black' }}>PM</span>
                </Tag>
              </Col>
            </Row>
          </ContextMenu>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Row style={{ borderRadius: 10, padding: '10px 0 20px 0' }} align="middle">
        {/* Date Indicator */}
        <Col xs={24} md={24} style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <Spin spinning={isLoadingTimeSlots}>
            <Calendar
              className="userCalendar"
              headerRender={renderCalendarHeader}
              cellRender={renderDay}
              validRange={[
                dayjs(currentMonth).startOf('month'),
                dayjs(currentMonth).endOf('month'),
              ]}
              mode="month"
              value={currentMonth}
            />
          </Spin>
        </Col>
      </Row>

      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; results: any }) => fetchTimeSlots()}
      />
      <Alert
        intent="danger"
        isOpen={confirmDeleteRecordDialogVisible}
        onClose={() => setConfirmDeleteRecordDialogVisible(false)}
        loading={isDeletingRecord}
        canEscapeKeyCancel={!isDeletingRecord}
        canOutsideClickCancel={!isDeletingRecord}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={deleteTimeSlot}
      >
        <p>
          <span style={{ fontWeight: 600 }}>Deleting Time Slot</span>
        </p>
        <br />
        <p>Please confirm that you want to delete this time slot</p>
      </Alert>
    </Layout>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteRecord: (payload: IDeleteRecordById, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(UserCalendar);
