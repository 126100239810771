import { Section, SectionCard } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Layout, Row } from 'antd';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';

const FileDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  const renderImagePreview = () => {
    const isImage = getProperty(record, 'Mimetype')
      ? getProperty(record, 'Mimetype').includes('image')
      : false;
    return (
      <Section title="File Preview" style={{ marginBottom: 10 }}>
        <SectionCard>
          <Row
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <Col span={12}>
              {isImage ? (
                <img
                  style={{
                    width: 1000,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                  src={getProperty(record, 'Url')}
                />
              ) : (
                <Link target="_blank" to={{ pathname: getProperty(record, 'Url') }}>
                  {record?.title}
                </Link>
              )}
            </Col>
          </Row>
        </SectionCard>
      </Section>
    );
  };

  return (
    <Layout>
      <DetailView showCollaborators leftColumn={[<div>{renderImagePreview()}</div>]} />
    </Layout>
  );
};

export default FileDetailView;
