import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row, Skeleton, Table, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { connect } from 'react-redux';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getOdinSchemaByRecord } from '../../../../../shared/utilities/schemaHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { ColumnsType } from 'antd/lib/table';
import './styles.scss';
import { usePrevious } from '../../../../../shared/utilities/reactHelpers';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Section, Icon, Button } from '@blueprintjs/core';
import {
  addRecordToShortList,
  IAddRecordToShortList,
} from '../../../../../core/records/store/actions';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../core/userInterface/store/actions';

interface Props {
  record: DbRecordEntityTransform | undefined;
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  shortListRecord: (params: IAddRecordToShortList) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

interface DataType {
  key: string;
  recordNumber: string;
  createdAt: string;
  recordStage: string;
  record: DbRecordEntityTransform;
}

const MAX_NUM_INITIALLY_SHOWN_RECORDS = 5;

const { ORDER } = SchemaModuleEntityTypeEnums;

const OrderList: FC<Props> = (props: Props) => {
  const { record, getAssociations, openDrawer } = props;
  const [data, setData] = useState<DbRecordEntityTransform[]>([]);
  const [loadingAssociations, setLoadingAssociations] = useState<boolean>(true);
  const [updatingAssociations, setUpdatingAssociations] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

  //   Watch for record change while the component is mounted
  const recordId = record?.id;
  const previousRecordId = usePrevious(recordId);
  useEffect(() => {
    if (previousRecordId && recordId !== previousRecordId && !loadingAssociations) {
      if (record && schema) {
        loadData(schema, record);
      }
    }
  }, [recordId]);

  useEffect(() => {
    if (record && !schema) {
      getSchemas(record.entity);
    }
  }, [record]);

  useEffect(() => {
    if (schema && record) {
      loadData(schema, record);
    }
  }, [schema]);

  const tableColumns: ColumnsType<DataType> = [
    {
      title: 'OR #',
      dataIndex: 'recordNumber',
      key: 'name',
      render: (text: any, record: any) => <Link to={getBrowserPath(record.record)}>{text}</Link>,
    },
    {
      title: 'Stage',
      dataIndex: 'recordStage',
      key: 'recordStage',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any, record: any) =>
        String(DateTime.fromISO(text).toFormat('M/d/yyyy h:mm a ZZZZ')),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'right',
      render: (text: any, record: any) => (
        <Tooltip title="Quick View" mouseEnterDelay={1.2}>
          <Button
            small
            icon={<Icon icon="eye-open" />}
            onClick={() =>
              openDrawer({
                recordId: record?.record?.id,
                moduleName: record?.record?.entity?.split(':')[0],
                entityName: record?.record?.entity?.split(':')[1],
              })
            }
          />
        </Tooltip>
      ),
    },
  ];

  let tableData = data.map((order: DbRecordEntityTransform) => {
    return {
      key: order.id,
      recordNumber: order.recordNumber || '-',
      recordStage: order.stage?.name || '-',
      createdAt: order.createdAt || '-',
      record: order,
    };
  });

  if (data?.length > MAX_NUM_INITIALLY_SHOWN_RECORDS && !isShowingMore) {
    tableData = tableData.splice(0, MAX_NUM_INITIALLY_SHOWN_RECORDS);
  }

  const getSchemas = async (entity: string | undefined) => {
    if (entity && record) {
      const recordSchema = await getOdinSchemaByRecord(record);
      setSchema(recordSchema);
    }
  };

  const loadData = (schema: SchemaEntity, record: DbRecordEntityTransform) => {
    setUpdatingAssociations(true);
    getAssociations(
      {
        recordId: record.id,
        key: ORDER,
        schema: schema,
        entities: [ORDER],
      },
      (res: any) => {
        setLoadingAssociations(false);
        setUpdatingAssociations(false);
        if (res && res.results?.[ORDER]?.dbRecords?.length > 0) {
          let tableData = res.results?.[ORDER]?.dbRecords;
          setData(tableData);
        }
      },
    );
  };

  return (
    <Section title="Orders" className="orderListCard" compact>
      <Skeleton loading={loadingAssociations}>
        {data.length > 0 && !loadingAssociations && (
          <Row>
            <Col span={24}>
              <Table
                style={{ opacity: updatingAssociations ? 0.3 : 1 }}
                size="small"
                bordered={false}
                loading={loadingAssociations}
                columns={tableColumns}
                dataSource={tableData as any}
                pagination={false}
              />
            </Col>
            {data.length > MAX_NUM_INITIALLY_SHOWN_RECORDS && (
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button
                  minimal
                  intent="primary"
                  text={isShowingMore ? 'Show Less' : 'Show More'}
                  onClick={() => setIsShowingMore(!isShowingMore)}
                />
              </Col>
            )}
          </Row>
        )}
        {data.length === 0 && !loadingAssociations && (
          <Row style={{ padding: 10 }}>
            <Col span={24}>
              <Icon icon="tick-circle" style={{ color: '#4BBC52', marginRight: 8 }} />
              <span>There are no Orders</span>
            </Col>
          </Row>
        )}
      </Skeleton>
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(OrderList);
