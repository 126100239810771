import { FC, useContext } from 'react';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import RemoveCustomer from '../../../../../containers/CrmModule/containers/ContactIdentity/RemoveCustomer';
import SipwiseDetail from '../../../../../containers/CrmModule/containers/ContactIdentity/Sipwise';
import DetailView from '../../../../shared/views/DetailView';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import AssociationDataTable from '../../../../../core/recordsAssociations/components/AssociationDataTable';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';

const { ORDER_MODULE, BILLING_MODULE, CRM_MODULE } = SchemaModuleTypeEnums;
const { ORDER, PAYMENT_METHOD, CONTACT } = SchemaModuleEntityTypeEnums;

const ContactIdentityDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  const renderIdentityDetails = (record: DbRecordEntityTransform) => {
    if (record) {
      switch (record.title) {
        case 'SIPWISE':
          return <SipwiseDetail record={record} />;
        default:
          return <div>No Profile Detail</div>;
      }
    }
  };

  const renderSummaryTab = () => {
    return (
      <>
        <AssociationDataTable
          title={'Order'}
          record={record}
          moduleName={ORDER_MODULE}
          entityName={ORDER}
        />
        <AssociationDataTable
          title={'Payment Method'}
          record={record}
          moduleName={BILLING_MODULE}
          entityName={PAYMENT_METHOD}
        />
        <AssociationDataTable
          title={'Contact'}
          record={record}
          moduleName={CRM_MODULE}
          entityName={CONTACT}
        />
      </>
    );
  };

  return (
    <DetailView
      showCollaborators
      defaultTabKey="Summary"
      customTabs={[
        {
          key: 'Summary',
          tab: 'Summary',
        },
        {
          key: 'Profile',
          tab: 'Profile',
        },
      ]}
      customTabBodies={{
        Profile: renderIdentityDetails(record),
        Summary: renderSummaryTab(),
      }}
      headerExtras={[record?.title === 'GOCARDLESS' ? <RemoveCustomer record={record} /> : <></>]}
    />
  );
};

export default ContactIdentityDetailView;
