import { FC, useContext, useEffect, useState } from 'react';
import { Card, Col, Empty, Row } from 'antd';
import { connect } from 'react-redux';
import { DataSetWorkContext } from '..';
import { getSchemaByModuleAndEntityRequest, ISchemaByModuleAndEntity } from '../../../../../../core/schemas/store/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getAllSchemaAssociationSchemas } from '../../../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListByModuleAndEntityOrAPI, getSchemaFromShortListBySchemaId } from '../../../../../../shared/utilities/schemaHelpers';
import RecordView from './RecordView';
import Pipeline from '../../../../../../core/records/components/Pipeline/Pipeline';
import { DataSetLeadDetailView } from './DetailViews/DataSetLeadDetailView';
import { DataSetAddressDetailView } from './DetailViews/DataSetAddressDetailView';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { IUpdateRelatedRecordAssociation, updateRecordAssociationRequest } from '../../../../../../core/recordsAssociations/store/actions';

interface Props {
  schemaReducer: any;
  getSchema: Function;
  updateRecordAssociation: Function;
  onRecordUpdate: (record: DbRecordEntityTransform) => void;
}

const RecordViewPanel: FC<Props> = (props) => {
  const { state, dispatch } = useContext(DataSetWorkContext);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [relatedSchemas, setRelatedSchemas] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  const { schemaReducer, getSchema, updateRecordAssociation, onRecordUpdate } = props;

  // On component mount, set the selected record to local state
  useEffect(() => {
    if (state.selectedRecord) {
      setSelectedRecord(state.selectedRecord);
    }
  }, [state.selectedRecord]);

  const getSelectedRecordSchema = async () => {
    if (selectedRecord) {
      const moduleName = selectedRecord?.entity?.split(':')[0] || '';
      const entityName = selectedRecord?.entity?.split(':')[1] || '';

      try {
        const selectedRecordSchema = await getSchemaFromShortListByModuleAndEntityOrAPI(
          schemaReducer.shortList,
          moduleName,
          entityName,
          getSchema,
        );
        setSchema(selectedRecordSchema);
        const allRelatedSchemas = getAllSchemaAssociationSchemas(
          selectedRecordSchema?.associations,
          ['Note'],
        );

        if (allRelatedSchemas?.length > 0) {
          setRelatedSchemas(allRelatedSchemas);
        }
      } catch (error: any) {}
    }
  };

  const handleStageUpdate = (record: any) => {
    // Stage update: Lead -> Won || Lead -> Lost
    if (record?.stage?.key === 'LeadStageWon' || record?.stage?.key === 'LeadStageLost') {
      updateRecordStatus('DONE');
    }
    // Stage update: Lead -> Contacted || Lead -> Qualified
    else if (
      record?.stage?.key === 'LeadStageQualified' ||
      record?.stage?.key === 'LeadStageContacted'
    ) {
      updateRecordStatus('IN_PROGRESS');
    } else {
      onRecordUpdate(record);
    }
  };

  const updateRecordStatus = (status: string) => {
    if (state.selectedRecord) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, state.selectedRecord?.schemaId);

      return updateRecordAssociation(
        {
          relatedEntityName: 'CrmDataset',
          parentSchema: schema,
          recordId: state.selectedRecord?.id,
          dbRecordAssociationId: state.selectedRecord?.dbRecordAssociation?.id,
          createUpdate: {
            entity: state.selectedRecord?.entity,
            schemaId: state.selectedRecord?.schemaId,
            properties: {
              DS_Status: status,
            },
          },
        },
        (res: DbRecordEntityTransform) => {
          if (res) {
            setTimeout(() => {
              onRecordUpdate(state.selectedRecord);
            }, 300);
          }
        },
      );
    }
  };

  useEffect(() => {
    if (!schema && state.selectedRecord) {
      setSelectedRecord(state.selectedRecord);
      getSelectedRecordSchema();
    }
  }, [state.selectedRecord?.id]);

  // const getRelatedTables = () => {
  //   if (schema && state.selectedRecord && relatedSchemas?.length > 0) {
  //     const tables = renderDynamicAssociationsAsTables(state.selectedRecord, relatedSchemas);
  //     return tables;
  //   } else {
  //     return <></>;
  //   }
  // };

  return (
    <Card className="DataSetBuilderPanelRow">
      <Row style={{ height: '77vh', overflow: 'auto' }}>
        {!selectedRecord ? (
          <Col span={24} style={{ textAlign: 'center' }}>
            <Empty style={{ marginTop: '20vh' }} description="No Record Selected" />
          </Col>
        ) : (
          <Col span={24} style={{ padding: 10 }}>
            <Row>
              {/* Record Header */}
              <Col span={24}>
                <RecordView record={selectedRecord} />
              </Col>

              <Col span={24}>
                {/* Pipelines */}
                {selectedRecord?.stage ? (
                  <Row style={{ marginTop: 0 }}>
                    <Col span={24}>
                      <Pipeline
                        record={selectedRecord!}
                        className="record-pipeline"
                        flat
                        onSuccess={handleStageUpdate}
                      />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}

                {/* Details */}
                <Row style={{ padding: 0, marginTop: 20 }}>
                  <Col span={24}>
                    {/* Lead Details */}

                    <DataSetLeadDetailView selectedRecord={selectedRecord!} schema={schema!} />


                    {/* Address Details */}
                    {selectedRecord && state.dataSetRecord?.type === 'ADDRESS' && schema && (
                      <DataSetAddressDetailView selectedRecord={selectedRecord} schema={schema} />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) =>
    dispatch(updateRecordAssociationRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(RecordViewPanel);
