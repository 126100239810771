import React from 'react';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getJSONColumnsFromSchema } from '../../../../../shared/utilities/schemaHelpers';
import { Card, Col, Empty, Row } from 'antd';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import RawDataContainer from '../RawDataContainer';

interface Props {
    record: DbRecordEntityTransform,
    schema: SchemaEntity
}

const RawDataRecordProperties: React.FC<Props> = (props: Props) => {

    const { record, schema } = props

    let JSONProperties: Object[] = []
    const JSONColumns: SchemaColumnEntity[] = getJSONColumnsFromSchema(schema)

    const renderRawDataTable = (JSONProperties: any[]) => {

        if (JSONProperties?.length > 0) {
            return (
                <Col span={24} style={{ marginBottom: JSONColumns.length > 1 ? 15 : 0 }}>
                    <Card title={JSONProperties[0][0]}>
                        <RawDataContainer
                            type="CARD"
                            data={JSONProperties[0][1]}
                        />
                    </Card>
                </Col>
            )
        }

    }

    if (JSONColumns.length > 0 && record) {
        JSONColumns.map((column: SchemaColumnEntity) => {
            Object.entries(record.properties).map((entry: any) => {
                if (entry[0] === column.name) {
                    JSONProperties.push(entry)
                }
            })
        })
    }

    return (
        JSONProperties.length > 0
            ? <Row>{renderRawDataTable(JSONProperties)}</Row>
            : <Empty description="No JSON properties found." style={{ margin: '200px 0' }} />
    );
};

export default RawDataRecordProperties;